import React from "react";
import { Flex, Tooltip, Box } from "@chakra-ui/react";

import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";

import {
	TMinimalGridListItem,
	TMinimalGridListRow,
	TMinimalGridListStyle
} from "_react/shared/ui/presentation/components/MinimalGridList/_types";
import {
	ListSectionVStack,
	ListSectionLabelHStack,
	ListSectionLabelBox,
	ListRowHStack,
	ListItemVStack,
	ListItemLabelHStack,
	ListItemValueFlex
} from "_react/shared/ui/presentation/components/MinimalGridList/_styles";

type MinimalGridListProps = {
	label?: string;
	icon?: React.ReactNode;
	data: Array<TMinimalGridListRow>;
	style?: TMinimalGridListStyle;
};

const MinimalGridList = ({ label, icon, data, style }: MinimalGridListProps) => {
	return (
		<ListSectionVStack sx={style?.container}>
			{label !== undefined && (
				<ListSectionLabelHStack sx={style?.header}>
					{icon}
					<ListSectionLabelBox sx={style?.label}>{label}</ListSectionLabelBox>
				</ListSectionLabelHStack>
			)}
			{data.map((row: TMinimalGridListRow, index: number) => (
				<ListRowHStack key={`row-${index}`}>
					{row.items.map((item: TMinimalGridListItem) => (
						<ListItemVStack key={item.label}>
							<ListItemLabelHStack>
								<Box>{item.label}</Box>
								{item.tooltip !== undefined && (
									<Tooltip hasArrow placement="top" label={item.tooltip} whiteSpace="pre-wrap">
										<Flex align="center">
											<OutlineInfo boxSize="4" />
										</Flex>
									</Tooltip>
								)}
							</ListItemLabelHStack>
							<ListItemValueFlex>{item.value}</ListItemValueFlex>
						</ListItemVStack>
					))}
				</ListRowHStack>
			))}
		</ListSectionVStack>
	);
};

export default MinimalGridList;
