import React from "react";
import { VStack, Text, Tooltip, SystemStyleObject } from "@chakra-ui/react";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { TProModelPlayer } from "_react/promodel/leaderboard/_types";
import { TSimpleHitterProjectionsPlayer } from "_react/shared/data_models/projections/_types";
import { getPosition } from "_react/shared/_helpers/position_groups";

type TPlayerPositionTooltipTextProps = {
	player?: TPlayerPageCombinedPlayer | TProModelPlayer | TSimpleHitterProjectionsPlayer | null;
	nullFillerText?: string | null;
};

const PlayerPositionTooltipText = ({ player, nullFillerText = "" }: TPlayerPositionTooltipTextProps) => {
	return (
		<VStack align="start">
			<Text>{`Projected Position: ${player?.proProfile?.projectedPositionRelationship?.label ??
				nullFillerText}`}</Text>
			<Text>{`Projected Position Group: ${player?.proProfile?.projectedPositionGroupRelationship?.label ??
				nullFillerText}`}</Text>
			<Text>{`Position: ${player?.position ?? nullFillerText}`}</Text>
		</VStack>
	);
};

type TPlayerPositionProps = {
	player?: TPlayerPageCombinedPlayer | TProModelPlayer | TSimpleHitterProjectionsPlayer | null;
	nullFillerText?: string | null;
	isTooltipDisabled?: boolean;
	style?: SystemStyleObject;
};

const PlayerPosition = ({ player, nullFillerText = "", isTooltipDisabled = true, style }: TPlayerPositionProps) => {
	return (
		<Tooltip
			label={<PlayerPositionTooltipText player={player} nullFillerText={nullFillerText} />}
			isDisabled={isTooltipDisabled}
			hasArrow
			sx={style}
		>
			{getPosition(player, true) ?? nullFillerText}
		</Tooltip>
	);
};
export default PlayerPosition;
