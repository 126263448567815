import React, { FC, useContext, useState, useEffect } from "react";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";

import axios from "_redux/_utils/_axios";
import { $TSFixMe } from "utils/tsutils";
import { getQueryStringValue, useFetch } from "utils/url_helpers";
import { useUserId, useIsGroupMember } from "_react/app/AppContents";
import { VIEW_BIO } from "_react/shared/_constants/query_strings";
import { Button } from "_react/shared/legacy/ui/Button";
import { defaultColorScheme, ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";
import Modal from "_react/shared/legacy/ui/Modal";
import { PlayerSearch, TPlayerSearchOption } from "_react/shared/searches/PlayerSearch";
import SwapVertical from "_react/shared/legacy/ui/icons/SwapVertical";
import { IconButton } from "_react/shared/legacy/ui/IconButton";
import CircularProgress from "_react/shared/legacy/ui/CircularProgress";
import { EvalProvider } from "_react/evals/shared/_context";
import { EVAL_TYPE_TO_GENERATE } from "_react/evals/shared/_helpers";
import { AMA, INTL, PRO } from "_react/playerpage/_constants";
import { PlayerPageContext } from "_react/playerpage/_context";
import { getPlayerPhilId, MERGE_PLAYER_ADMIN_IAM_GROUP } from "_react/playerpage/_helpers";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import {
	StyledFooter,
	FlexDiv,
	ModalContainer,
	ModalContent,
	PlayersContainer,
	PlayerRow,
	MergeLabel,
	PlayerLabel,
	LinkStyle,
	HiddenStyle,
	FooterProIntlButtonStyle,
	FooterAmaButtonStyle,
	FooterAmaBioButtonStyle,
	FooterIntlBioButtonStyle,
	FooterUnsavedChangesSpanStyle,
	FooterBioDivStyle,
	FooterMergePlayerPrimaryStyle,
	FooterMergePlayerSecondaryStyle,
	FooterModalStyle,
	TooltipStyle,
	HideStyle,
	StyledLink
} from "_react/playerpage/_styles";
import { Tooltip } from "_react/shared/legacy/ui/Tooltip";

type FooterProps = {
	colorSchemeGroup: ColorSchemeGroup;
	history?: $TSFixMe;
	player: TPlayerPageCombinedPlayer;
	location?: $TSFixMe;
};

const FooterComp: FC<FooterProps> = ({ colorSchemeGroup, player }) => {
	const history = useHistory();
	const { enqueueSnackbar } = useSnackbar();
	const view = getQueryStringValue("view");
	const [generating, setGenerating] = useState("none");
	const [isShowMergeModal, setIsShowMergeModal] = useState<boolean>(false);
	const [mergePlayer, setMergePlayer] = useState<TPlayerSearchOption>();
	const [isMergePlayerPrimary, setIsMergePlayerPrimary] = useState<boolean>(false);
	const [isMerging, setIsMerging] = useState<boolean>(false);
	const userId = useUserId();

	const isAdmin = useIsGroupMember(MERGE_PLAYER_ADMIN_IAM_GROUP);

	const {
		canEditAmaBio,
		canEditIntlBio,
		viewClassification,
		setBioDisplayOnly,
		bioDisplayOnly,
		isBioSaving,
		submitAmaBioUpdate,
		submitIntlBioUpdate,
		missingIntlAgent,
		unsavedChanges
	} = useContext(PlayerPageContext);

	const [isProReportUpdate, setIsProReportUpdate] = useState(false);
	const [isIntlReportUpdate, setIsIntlReportUpdate] = useState(false);
	const [existingUpdateProId, setExistingUpdateProId] = useState<string>();
	const [existingUpdateIntlId, setExistingUpdateIntlId] = useState<string>();
	const playerClassification = player.playerClassification?.toLowerCase() ?? null;
	const playerPhilId = getPlayerPhilId(player, playerClassification);
	const { responseData: mostRecentEvalsRaw, setPath, isInProgress } = useFetch(
		playerPhilId
			? `/evals?philId=${playerPhilId}&combinedPlayerEvals=True&beginSeenDate=${dayjs().year()}-01-01`
			: "",
		[]
	);
	useEffect(() => {
		if (playerPhilId)
			setPath(`/evals?philId=${playerPhilId}&combinedPlayerEvals=True&beginSeenDate=${dayjs().year()}-01-01`);
	}, [playerPhilId, setPath]);

	useEffect(() => {
		// Overall eval editing logic:
		// User can edit their most recent unpublished intl and pro eval(s)
		// If the user's most recent pro eval was published in the last year,
		// make it look like that eval is being updated (but actually just copy the info into a new eval)
		const mostRecentEvals = mostRecentEvalsRaw
			.filter((e: $TSFixMe) => e.eval_create_user_id === userId)
			.sort((a: $TSFixMe, b: $TSFixMe) => {
				const aDate = a.eval_create_date;
				const bDate = b.eval_create_date;
				return dayjs(aDate).isBefore(dayjs(bDate)) ? 1 : -1;
			});
		const mostRecentProEval =
			mostRecentEvals && mostRecentEvals.length > 0
				? mostRecentEvals.find((e: $TSFixMe) => e.eval_type.includes("PRO"))
				: null;
		const mostRecentIntlEval =
			mostRecentEvals && mostRecentEvals.length > 0
				? mostRecentEvals.find((e: $TSFixMe) => e.eval_type.includes("INTL"))
				: null;
		if (mostRecentProEval) {
			if (mostRecentProEval.publish_date != null) {
				// Published eval, check if it was this year
				const year = dayjs(mostRecentProEval.seen_date).year();
				// Per Ondo's 2021 request, this makes it look like the pro eval is being updated
				// But a new eval is actually being created that contains some relevant info from the last published eval
				if (year === dayjs().year()) setIsProReportUpdate(true);
			} else {
				// Unpublished pro eval, set id
				setExistingUpdateProId(mostRecentProEval.eval_id);
				setIsProReportUpdate(true);
			}
		}
		if (mostRecentIntlEval && mostRecentIntlEval.publish_date == null) {
			// Unpublished intl eval, set id
			setExistingUpdateIntlId(mostRecentIntlEval.eval_id);
			setIsIntlReportUpdate(true);
		}
	}, [mostRecentEvalsRaw, userId]);

	const generateEval = (philId: number, reportType: string, amaReportType = "") => {
		if (existingUpdateProId != null && reportType === PRO) {
			setGenerating("none");
			history.push(`/eval?id=${existingUpdateProId}`);
		}
		if (existingUpdateIntlId != null && reportType === INTL) {
			setGenerating("none");
			history.push(`/eval?id=${existingUpdateIntlId}`);
		} else {
			axios.get(`/player/${philId}`).then((response: $TSFixMe) => {
				const player = response.data;
				const playerPosition = ["RHS", "LHS", "RHR", "LHR", "P", "RHP", "LHP", "SP", "RP"].includes(
					player.position
				)
					? "PITCHER"
					: "BATTER";
				axios
					.get(`/evals/recent_create/${player.phil_id}/${reportType}/${playerPosition}`)
					.then((response: $TSFixMe) => {
						const evals = response.data;
						// Select the most recent eval
						let mostRecentEval = null;
						// Loop through evals and select correct report type
						for (let i = 0; i < evals.length; i++) {
							// Select the first (most recent report), but as you loop through the evals check if
							//   the specific report type you're creating is present, and if so use that
							if (mostRecentEval == null || amaReportType === evals[i]["eval_report_type"]) {
								mostRecentEval = evals[i];
								if (i > 0) break;
							}
						}
						const generateEvalFunction = EVAL_TYPE_TO_GENERATE[reportType];
						const newEval = generateEvalFunction(
							player,
							mostRecentEval ? mostRecentEval : {},
							amaReportType
						);
						const level = newEval.eval_type.split("_")[0];
						const type = newEval.eval_type.split("_")[1];
						if (amaReportType === "AVR") newEval.ofp = null;
						axios.post(`/eval/${level.toLowerCase()}/${type.toLowerCase()}`, newEval).then(() => {
							setGenerating("none");
							history.push(`/eval?id=${newEval.eval_id}`);
						});
					});
			});
		}
	};

	const handleMergePlayers = () => {
		const requestPlayers = {
			primary_phil_id: mergePlayer ? (isMergePlayerPrimary ? mergePlayer.philId : playerPhilId) : undefined,
			primary_player_classification: mergePlayer
				? isMergePlayerPrimary
					? mergePlayer.playerClassification
					: playerClassification
				: undefined,
			secondary_phil_id: mergePlayer ? (!isMergePlayerPrimary ? mergePlayer.philId : playerPhilId) : undefined,
			secondary_player_classification: mergePlayer
				? !isMergePlayerPrimary
					? mergePlayer.playerClassification
					: playerClassification
				: undefined
		};

		if (requestPlayers.primary_phil_id === requestPlayers.secondary_phil_id) {
			enqueueSnackbar("Cannot merge the same player into itself.", { variant: "error" });
			return;
		}
		setIsMerging(true);
		axios
			.post("/players/merge", requestPlayers)
			.then(() => {
				axios.get("/search/refresh?token=DeepDishPizza").then(() => {
					setIsMerging(false);
					setIsShowMergeModal(false);
					if (isMergePlayerPrimary) history.push(`player?philId=${requestPlayers.primary_phil_id}`);
					else window.location.reload();
				});
			})
			.catch(() => {
				enqueueSnackbar("Error merging player.", { variant: "error" });
				setIsMerging(false);
			});
	};

	return (
		<StyledFooter colorSchemeGroup={colorSchemeGroup}>
			<FlexDiv>
				{viewClassification === INTL && view === VIEW_BIO && canEditIntlBio && (
					<div style={FooterBioDivStyle}>
						<Tooltip title={"Must select an Agent"} style={missingIntlAgent ? TooltipStyle : HideStyle}>
							<Button
								disabled={isBioSaving || missingIntlAgent}
								title={bioDisplayOnly ? "Edit Bio" : "Save Bio"}
								onClick={() => {
									if (!bioDisplayOnly && !isBioSaving)
										submitIntlBioUpdate().then(() => {
											setBioDisplayOnly(true);
										});
									else setBioDisplayOnly(false);
								}}
								style={FooterIntlBioButtonStyle}
							/>
						</Tooltip>
						{unsavedChanges && <span style={FooterUnsavedChangesSpanStyle}>Unsaved Changes</span>}
					</div>
				)}
				{(viewClassification === PRO || viewClassification === INTL) &&
					playerPhilId && [
						<Button
							disabled={generating === PRO || isInProgress}
							key={PRO}
							onClick={() => {
								setGenerating(PRO);
								// Generating logic
								generateEval(playerPhilId, PRO);
							}}
							style={isAdmin ? FooterProIntlButtonStyle : undefined}
							colorScheme={isProReportUpdate ? defaultColorScheme.secondary : defaultColorScheme.primary}
							title={
								generating === PRO || isInProgress
									? "Loading..."
									: isProReportUpdate
									? "Update Pro Report"
									: "Create Pro Report"
							}
						/>,
						<Button
							disabled={generating === INTL || isInProgress}
							key={INTL}
							onClick={() => {
								setGenerating(INTL);
								// Generating logic
								generateEval(playerPhilId, INTL);
							}}
							style={{ ...(isAdmin ? FooterProIntlButtonStyle : undefined), marginLeft: "10px" }}
							colorScheme={isIntlReportUpdate ? defaultColorScheme.secondary : defaultColorScheme.primary}
							title={
								generating === INTL || isInProgress
									? "Loading..."
									: isIntlReportUpdate
									? "Update Intl Report"
									: "Create Intl Report"
							}
						/>
					]}
				{viewClassification === AMA && view === VIEW_BIO && canEditAmaBio && (
					<div style={FooterBioDivStyle}>
						<Button
							disabled={isBioSaving}
							title={bioDisplayOnly ? "Edit Bio" : "Save Bio"}
							onClick={() => {
								if (!bioDisplayOnly && !isBioSaving)
									submitAmaBioUpdate().then(() => {
										setBioDisplayOnly(true);
									});
								else setBioDisplayOnly(false);
							}}
							style={FooterAmaBioButtonStyle}
						/>
						{unsavedChanges && <span style={FooterUnsavedChangesSpanStyle}>Unsaved Changes</span>}
					</div>
				)}
				{viewClassification === AMA &&
					playerPhilId && [
						<Button
							disabled={generating === "follow"}
							key={"follow"}
							onClick={() => {
								setGenerating("follow");
								// Generating logic
								generateEval(playerPhilId, AMA, "FOL");
							}}
							style={FooterAmaButtonStyle}
							title={generating === "follow" ? "Creating Follow Report..." : "Create Follow Report"}
						/>,
						<Button
							disabled={generating === "full"}
							key={"full"}
							onClick={() => {
								setGenerating("full");
								// Generating logic
								generateEval(playerPhilId, AMA, "FRE");
							}}
							style={{ ...FooterAmaButtonStyle, marginLeft: "10px" }}
							title={generating === "full" ? "Creating Full Report..." : "Create Full Report"}
						/>,
						<Button
							colorScheme={defaultColorScheme.secondary}
							disabled={generating === "lvr"}
							key={"lvr"}
							onClick={() => {
								setGenerating("lvr");
								// Generating logic
								generateEval(playerPhilId, AMA, "LVR");
							}}
							style={{ ...FooterAmaButtonStyle, marginLeft: "10px" }}
							title={
								generating === "lvr" ? "Creating Limited View Report..." : "Create Limited View Report"
							}
						/>,
						<Button
							colorScheme={defaultColorScheme.secondary}
							disabled={generating === "avr"}
							key={"avr"}
							onClick={() => {
								setGenerating("avr");
								// Generating logic
								generateEval(playerPhilId, AMA, "AVR");
							}}
							style={{ ...FooterAmaButtonStyle, marginLeft: "10px" }}
							title={generating === "avr" ? "Creating Video Report..." : "Create Video Report"}
						/>
					]}
			</FlexDiv>
			{isAdmin && (
				<Button
					colorScheme={defaultColorScheme.secondary}
					key={"merge"}
					onClick={() => setIsShowMergeModal(true)}
					style={{
						...(playerClassification === AMA ? FooterAmaButtonStyle : FooterProIntlButtonStyle),
						marginLeft: "10px"
					}}
					title={"Merge Player"}
				/>
			)}
			<Modal showX isOpen={isAdmin && isShowMergeModal} onRequestClose={() => setIsShowMergeModal(false)}>
				<ModalContainer>
					<ModalContent>
						<IconButton
							onClick={() => setIsMergePlayerPrimary(!isMergePlayerPrimary)}
							colorScheme={colorSchemeGroup.secondary}
							style={FooterModalStyle}
						>
							<SwapVertical fill="white" />
						</IconButton>
						<PlayersContainer>
							<PlayerRow>
								<MergeLabel>
									{isMergePlayerPrimary ? (
										<span style={FooterMergePlayerSecondaryStyle}>Delete</span>
									) : (
										<span style={FooterMergePlayerPrimaryStyle}>Keep</span>
									)}
								</MergeLabel>
								<PlayerLabel>{`${player.firstName ?? player.firstNameLegal} ${
									player.lastName
								}`}</PlayerLabel>
								<div style={LinkStyle}>Current Page</div>
							</PlayerRow>
							<PlayerRow>
								<MergeLabel>
									{isMergePlayerPrimary ? (
										<span style={FooterMergePlayerPrimaryStyle}>Keep</span>
									) : (
										<span style={FooterMergePlayerSecondaryStyle}>Delete</span>
									)}{" "}
								</MergeLabel>
								<PlayerLabel>
									<PlayerSearch
										isStyledSelectStyles={true}
										isClearable={true}
										isMulti={false}
										onSelect={(p: TPlayerSearchOption) => setMergePlayer(p)}
										placeholder={"Search for a player to merge with..."}
										value={mergePlayer}
									/>
								</PlayerLabel>

								<StyledLink
									href={`/player?philId=${mergePlayer?.philId}`}
									target="_blank"
									rel="noopener noreferrer"
									style={mergePlayer ? LinkStyle : HiddenStyle}
								>
									View Player Page
								</StyledLink>
							</PlayerRow>
						</PlayersContainer>
					</ModalContent>
					{!isMerging && (
						<Button
							onClick={handleMergePlayers}
							colorScheme={defaultColorScheme.secondary}
							title="Merge Players"
							disabled={!mergePlayer}
						/>
					)}
					{isMerging && <CircularProgress center={false} />}
				</ModalContainer>
			</Modal>
		</StyledFooter>
	);
};

export const Footer: FC<FooterProps> = props => (
	<EvalProvider>
		<FooterComp {...props} />
	</EvalProvider>
);
