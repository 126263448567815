export type TAgentRowsType = { key: string; label: string }[];
export type TAgencyRowsType = { key: string; label: string }[];

export const INFO_ROWS: { agent: TAgentRowsType; agency: TAgencyRowsType } = {
	agent: [
		{ key: "area", label: "Area" },
		{ key: "address1", label: "Address" },
		{ key: "address2", label: "Second address" },
		{ key: "address3", label: "Third address" },
		{ key: "city", label: "City" },
		{ key: "state", label: "State" },
		{ key: "country", label: "Country" },
		{ key: "zip", label: "Zip" },
		{ key: "phone", label: "Phone" },
		{ key: "phoneAlt", label: "Alternate phone" },
		{ key: "cell", label: "Cell" },
		{ key: "fax", label: "Fax" },
		{ key: "email", label: "Email" }
	],
	agency: [
		{ key: "address1", label: "Address" },
		{ key: "address2", label: "Second address" },
		{ key: "address3", label: "Third address" },
		{ key: "city", label: "City" },
		{ key: "state", label: "State" },
		{ key: "country", label: "Country" },
		{ key: "zip", label: "Zip" },
		{ key: "phone", label: "Phone" }
	]
};

export const invalidAgentOrAgency = (infoId: string | number) => {
	return infoId == null || infoId === 0 || infoId === "0";
};

export const NO_INFO_DISPLAY = (infoId: string | number) => ({
	agent: invalidAgentOrAgency(infoId) ? "Agent Unknown" : "No contact info",
	agency: invalidAgentOrAgency(infoId) ? "Agency Unknown" : "No info on this agency"
});

export const FAILED_INFO_DISPLAY = (infoId: string | number) => ({
	agent: invalidAgentOrAgency(infoId) ? "Agent Unknown" : "Failed retrieving contact info",
	agency: invalidAgentOrAgency(infoId) ? "Agency Unknown" : "Failed retrieving agency info"
});

export type TPlayerType = "pro" | "intl" | "ama";

export type TPlayerInfoType = {
	firstName: string;
	lastName: string;
	philId: number;
	id?: number;
	active?: number;
	eligibleYear?: number;
};
