export const BATS_OVERALL = "OVR"; // Data does not contain OVR
export const BATS_L = "L";
export const BATS_R = "R";

export const THROWS_OVERALL = "OVR"; // Data does not contain OVR
export const THROWS_L = "L";
export const THROWS_R = "R";

export const COUNT_SPLIT_2K = "2K";
export const COUNT_SPLIT_P2K = "P2K";

// TODO revisit if we should pull these from `mesa.arsenal_scores_v3_params`
// These are the overall 2024 min and max values (at the pitch level) for +-3.5 standard deviations
export const MIN_INTRINSIC_RUNS_VALUE = -0.07;
export const MAX_INTRINSIC_RUNS_VALUE = 0.07;

export const MIN_INTRINSIC_RA9 = -5.76;
export const MAX_INTRINSIC_RA9 = 14.76;

export const MIN_INTRINSIC_GRADE = 15;
export const MAX_INTRINSIC_GRADE = 85;
