import React from "react";
import { VStack, HStack } from "@chakra-ui/react";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import Section from "_react/shared/ui/presentation/components/Section/Section";
import {
	PITCH_USAGE_METRIC_GROUP,
	STUFF_METRIC_GROUP,
	PITCH_VELOCITY_METRIC_GROUP
} from "_react/shared/data_models/metric/_constants";
import { TPlayingLevel } from "_react/shared/data_models/hitter_grades/_types";
import PlayerPitchTypeLabels, {
	TPlayerPitchTypeLabelsData
} from "_react/shared/ui/data/other/PlayerPitchTypeLabels/PlayerPitchTypeLabels";
import { MAX_PITCH_VELOCITY, MIN_PITCH_VELOCITY } from "_react/shared/_constants/pitch_types";

import { SectionStyleObject } from "_react/playerpage/pro/shared/_styles";
import PitcherMetricContourPlot, {
	TPitcherMetricContourSecondaryPlotData
} from "_react/shared/ui/data/plots/PitcherMetricContourPlot/PitcherMetricContourPlot";
import PitcherMetricOverTimePlot, {
	TPitcherMetricOverTimeSecondaryPlotData
} from "_react/shared/ui/data/plots/PitcherMetricOverTimePlot/PitcherMetricOverTimePlot";
import PitcherMetricOverTimeStreamGraph from "_react/shared/ui/data/plots/PitcherMetricOverTimeStreamGraph/PitcherMetricOverTimeStreamGraph";
import PitcherMetricRidgelinePlot, {
	TPitcherMetricRidgelineSecondaryPlotData
} from "_react/shared/ui/data/plots/PitcherMetricRidgelinePlot/PitcherMetricRidgelinePlot";

type TArsenalTrendsSectionProps = {
	player: TPlayerPageCombinedPlayer;
	playingLevel: TPlayingLevel;
	seasonFilter: number;
	batsFilter: string;
	throwsFilter?: string;
	shouldFetchPlayerPitchTypeLabelsData?: boolean;
	playerPitchTypeLabelsData?: TPlayerPitchTypeLabelsData;
	shouldFetchPitcherMetricOverTimeSecondaryPlotData?: boolean;
	pitcherMetricOverTimeSecondaryPlotData?: TPitcherMetricOverTimeSecondaryPlotData;
	shouldFetchPitcherMetricRidgelineSecondaryPlotData?: boolean;
	pitcherMetricRidgelineSecondaryPlotData?: TPitcherMetricRidgelineSecondaryPlotData;
	shouldFetchPitcherMetricContourSecondaryPlotData?: boolean;
	pitcherMetricContourSecondaryPlotData?: TPitcherMetricContourSecondaryPlotData;
};

const ArsenalTrendsSection = ({
	player,
	playingLevel,
	seasonFilter,
	batsFilter,
	throwsFilter,
	shouldFetchPlayerPitchTypeLabelsData = true,
	playerPitchTypeLabelsData,
	shouldFetchPitcherMetricOverTimeSecondaryPlotData = true,
	pitcherMetricOverTimeSecondaryPlotData,
	shouldFetchPitcherMetricRidgelineSecondaryPlotData = true,
	pitcherMetricRidgelineSecondaryPlotData,
	shouldFetchPitcherMetricContourSecondaryPlotData = true,
	pitcherMetricContourSecondaryPlotData
}: TArsenalTrendsSectionProps) => {
	return (
		<Section label="Arsenal Trends" placement="start" style={SectionStyleObject}>
			<VStack alignItems="start">
				<PlayerPitchTypeLabels
					playerId={player.id}
					playingLevel={playingLevel}
					seasonFilter={seasonFilter}
					style={{ container: { marginBottom: "10" } }}
					shouldFetchData={shouldFetchPlayerPitchTypeLabelsData}
					data={playerPitchTypeLabelsData}
				/>
				<HStack gap="6" flexWrap="wrap">
					<PitcherMetricOverTimePlot
						title="Stuff over time"
						playerId={player.id}
						playingLevel={playingLevel}
						metricGroup={STUFF_METRIC_GROUP}
						batsFilter={batsFilter}
						throwsFilter={throwsFilter}
						seasonFilter={seasonFilter}
						shouldFetchSecondaryData={shouldFetchPitcherMetricOverTimeSecondaryPlotData}
						secondaryData={pitcherMetricOverTimeSecondaryPlotData}
					/>
					<PitcherMetricOverTimeStreamGraph
						title="Pitch usage over time"
						playerId={player.id}
						playingLevel={playingLevel}
						metricGroup={PITCH_USAGE_METRIC_GROUP}
						batsFilter={batsFilter}
						throwsFilter={throwsFilter}
						seasonFilter={seasonFilter}
					/>
					<PitcherMetricRidgelinePlot
						title="Pitch velocity"
						playerId={player.id}
						playingLevel={playingLevel}
						metricGroup={PITCH_VELOCITY_METRIC_GROUP}
						batsFilter={batsFilter}
						throwsFilter={throwsFilter}
						seasonFilter={seasonFilter}
						xAxisExtrema={{
							min: MIN_PITCH_VELOCITY,
							max: MAX_PITCH_VELOCITY,
							isOverrideDistributionExtrema: true
						}}
						shouldFetchSecondaryData={shouldFetchPitcherMetricRidgelineSecondaryPlotData}
						secondaryData={pitcherMetricRidgelineSecondaryPlotData}
					/>
					<PitcherMetricContourPlot
						title="Pitch movement"
						playerId={player.id}
						playingLevel={playingLevel}
						metricGroup="pitch_movement"
						batsFilter={batsFilter}
						throwsFilter={throwsFilter}
						seasonFilter={seasonFilter}
						shouldFetchSecondaryData={shouldFetchPitcherMetricContourSecondaryPlotData}
						secondaryData={pitcherMetricContourSecondaryPlotData}
					/>
					<PitcherMetricContourPlot
						title="Release point"
						playerId={player.id}
						playingLevel={playingLevel}
						metricGroup="release_point"
						batsFilter={batsFilter}
						throwsFilter={throwsFilter}
						seasonFilter={seasonFilter}
						shouldFetchSecondaryData={shouldFetchPitcherMetricContourSecondaryPlotData}
						secondaryData={pitcherMetricContourSecondaryPlotData}
					/>
				</HStack>
			</VStack>
		</Section>
	);
};

export default ArsenalTrendsSection;
