export const EYEWEARS = [
	{ value: "N", text: "None" },
	{ value: "G", text: "Glasses" },
	{ value: "C", text: "Contacts" },
	{ value: "B", text: "Both" }
];

export const STATES = [
	{ value: "AK", text: "AK" },
	{ value: "AL", text: "AL" },
	{ value: "AR", text: "AR" },
	{ value: "CA", text: "CA" },
	{ value: "CO", text: "CO" },
	{ value: "CT", text: "CT" },
	{ value: "DC", text: "DC" },
	{ value: "DE", text: "DE" },
	{ value: "AZ", text: "AZ" },
	{ value: "FL", text: "FL" },
	{ value: "GA", text: "GA" },
	{ value: "HI", text: "HI" },
	{ value: "IA", text: "IA" },
	{ value: "ID", text: "ID" },
	{ value: "IL", text: "IL" },
	{ value: "KS", text: "KS" },
	{ value: "KY", text: "KY" },
	{ value: "LA", text: "LA" },
	{ value: "MA", text: "MA" },
	{ value: "MD", text: "MD" },
	{ value: "ME", text: "ME" },
	{ value: "MI", text: "MI" },
	{ value: "IN", text: "IN" },
	{ value: "MN", text: "MN" },
	{ value: "MO", text: "MO" },
	{ value: "MS", text: "MS" },
	{ value: "MT", text: "MT" },
	{ value: "NC", text: "NC" },
	{ value: "ND", text: "ND" },
	{ value: "NE", text: "NE" },
	{ value: "NH", text: "NH" },
	{ value: "NJ", text: "NJ" },
	{ value: "NM", text: "NM" },
	{ value: "NV", text: "NV" },
	{ value: "NY", text: "NY" },
	{ value: "OH", text: "OH" },
	{ value: "OK", text: "OK" },
	{ value: "OR", text: "OR" },
	{ value: "PA", text: "PA" },
	{ value: "RI", text: "RI" },
	{ value: "SC", text: "SC" },
	{ value: "SD", text: "SD" },
	{ value: "TN", text: "TN" },
	{ value: "TX", text: "TX" },
	{ value: "UT", text: "UT" },
	{ value: "VA", text: "VA" },
	{ value: "VT", text: "VT" },
	{ value: "WA", text: "WA" },
	{ value: "WI", text: "WI" },
	{ value: "WV", text: "WV" },
	{ value: "WY", text: "WY" },
	{ value: "PR", text: "PR" },
	{ value: "AB", text: "AB" },
	{ value: "BC", text: "BC" },
	{ value: "NB", text: "NB" },
	{ value: "MB", text: "MB" },
	{ value: "NF", text: "NF" },
	{ value: "NS", text: "NS" },
	{ value: "ON", text: "ON" },
	{ value: "PE", text: "PE" },
	{ value: "QC", text: "QC" },
	{ value: "SK", text: "SK" },
	{ value: "NU", text: "NU" },
	{ value: "NT", text: "NT" },
	{ value: "YT", text: "YT" }
];

export const COUNTRIES = {
	two_letter_abbreviations: [
		{ value: "AD", text: "Andorra" },
		{ value: "AE", text: "United Arab Emirates" },
		{ value: "AF", text: "Afghanistan" },
		{ value: "AG", text: "Antigua and Barbuda" },
		{ value: "AI", text: "Anguilla" },
		{ value: "AL", text: "Albania" },
		{ value: "AM", text: "Armenia" },
		{ value: "AN", text: "Netherlands Antilles" },
		{ value: "AO", text: "Angola" },
		{ value: "AR", text: "Argentina" },
		{ value: "AT", text: "Austria" },
		{ value: "AU", text: "Australia" },
		{ value: "AW", text: "Aruba" },
		{ value: "AZ", text: "Azerbaijan" },
		{ value: "BA", text: "Bosnia and Herzegovina" },
		{ value: "BB", text: "Barbados" },
		{ value: "BD", text: "Bangladesh" },
		{ value: "BE", text: "Belgium" },
		{ value: "BF", text: "Burkina Faso" },
		{ value: "BG", text: "Bulgaria" },
		{ value: "BH", text: "Bahrain" },
		{ value: "BI", text: "Burundi" },
		{ value: "BJ", text: "Benin" },
		{ value: "BM", text: "Bermuda" },
		{ value: "BN", text: "Brunei Darussalam" },
		{ value: "BO", text: "Bolivia" },
		{ value: "BR", text: "Brazil" },
		{ value: "BS", text: "Bahamas" },
		{ value: "BT", text: "Bhutan" },
		{ value: "BW", text: "Botswana" },
		{ value: "BY", text: "Belarus" },
		{ value: "BZ", text: "Belize" },
		{ value: "CA", text: "Canada" },
		{ value: "CC", text: "Cocos (Keeling) Islands" },
		{ value: "CD", text: "Democratic Republic of the Congo" },
		{ value: "CF", text: "Central African Republic" },
		{ value: "CG", text: "Congo" },
		{ value: "CH", text: "Switzerland" },
		{ value: "CI", text: "Cote D'Ivoire (Ivory Coast)" },
		{ value: "CK", text: "Cook Islands" },
		{ value: "CL", text: "Chile" },
		{ value: "CM", text: "Cameroon" },
		{ value: "CN", text: "China" },
		{ value: "CO", text: "Colombia" },
		{ value: "CR", text: "Costa Rica" },
		{ value: "CU", text: "Cuba" },
		{ value: "CV", text: "Cape Verde" },
		{ value: "CW", text: "Curacao" },
		{ value: "CX", text: "Christmas Island" },
		{ value: "CY", text: "Cyprus" },
		{ value: "CZ", text: "Czech Republic" },
		{ value: "DE", text: "Germany" },
		{ value: "DJ", text: "Djibouti" },
		{ value: "DK", text: "Denmark" },
		{ value: "DM", text: "Dominica" },
		{ value: "DO", text: "Dominican Republic" },
		{ value: "DZ", text: "Algeria" },
		{ value: "EC", text: "Ecuador" },
		{ value: "EE", text: "Estonia" },
		{ value: "EG", text: "Egypt" },
		{ value: "EH", text: "Western Sahara" },
		{ value: "ER", text: "Eritrea" },
		{ value: "ES", text: "Spain" },
		{ value: "ET", text: "Ethiopia" },
		{ value: "FI", text: "Finland" },
		{ value: "FJ", text: "Fiji" },
		{ value: "FK", text: "Falkland Islands (Malvinas)" },
		{ value: "FM", text: "Federated States of Micronesia" },
		{ value: "FO", text: "Faroe Islands" },
		{ value: "FR", text: "France" },
		{ value: "GA", text: "Gabon" },
		{ value: "GB", text: "Great Britain (UK)" },
		{ value: "GD", text: "Grenada" },
		{ value: "GE", text: "Georgia" },
		{ value: "GF", text: "French Guiana" },
		{ value: "GG", text: "Guernsey" },
		{ value: "GH", text: "Ghana" },
		{ value: "GI", text: "Gibraltar" },
		{ value: "GL", text: "Greenland" },
		{ value: "GM", text: "Gambia" },
		{ value: "GN", text: "Guinea" },
		{ value: "GP", text: "Guadeloupe" },
		{ value: "GQ", text: "Equatorial Guinea" },
		{ value: "GR", text: "Greece" },
		{ value: "GS", text: "S. Georgia and S. Sandwich Islands" },
		{ value: "GT", text: "Guatemala" },
		{ value: "GW", text: "Guinea-Bissau" },
		{ value: "GY", text: "Guyana" },
		{ value: "HK", text: "Hong Kong" },
		{ value: "HN", text: "Honduras" },
		{ value: "HR", text: "Croatia (Hrvatska)" },
		{ value: "HT", text: "Haiti" },
		{ value: "HU", text: "Hungary" },
		{ value: "ID", text: "Indonesia" },
		{ value: "IE", text: "Ireland" },
		{ value: "IL", text: "Israel" },
		{ value: "IN", text: "India" },
		{ value: "IQ", text: "Iraq" },
		{ value: "IR", text: "Iran" },
		{ value: "IS", text: "Iceland" },
		{ value: "IT", text: "Italy" },
		{ value: "JM", text: "Jamaica" },
		{ value: "JO", text: "Jordan" },
		{ value: "JP", text: "Japan" },
		{ value: "KE", text: "Kenya" },
		{ value: "KG", text: "Kyrgyzstan" },
		{ value: "KH", text: "Cambodia" },
		{ value: "KI", text: "Kiribati" },
		{ value: "KM", text: "Comoros" },
		{ value: "KN", text: "Saint Kitts and Nevis" },
		{ value: "KP", text: "Korea (North)" },
		{ value: "KR", text: "Korea (South)" },
		{ value: "KW", text: "Kuwait" },
		{ value: "KY", text: "Cayman Islands" },
		{ value: "KZ", text: "Kazakhstan" },
		{ value: "LA", text: "Laos" },
		{ value: "LB", text: "Lebanon" },
		{ value: "LC", text: "Saint Lucia" },
		{ value: "LI", text: "Liechtenstein" },
		{ value: "LK", text: "Sri Lanka" },
		{ value: "LR", text: "Liberia" },
		{ value: "LS", text: "Lesotho" },
		{ value: "LT", text: "Lithuania" },
		{ value: "LU", text: "Luxembourg" },
		{ value: "LV", text: "Latvia" },
		{ value: "LY", text: "Libya" },
		{ value: "MA", text: "Morocco" },
		{ value: "MC", text: "Monaco" },
		{ value: "MD", text: "Moldova" },
		{ value: "MF", text: "Saint Martin" },
		{ value: "MG", text: "Madagascar" },
		{ value: "MH", text: "Marshall Islands" },
		{ value: "MK", text: "Macedonia" },
		{ value: "ML", text: "Mali" },
		{ value: "MM", text: "Myanmar" },
		{ value: "MN", text: "Mongolia" },
		{ value: "MO", text: "Macao" },
		{ value: "MP", text: "Northern Mariana Islands" },
		{ value: "MQ", text: "Martinique" },
		{ value: "MR", text: "Mauritania" },
		{ value: "MS", text: "Montserrat" },
		{ value: "MT", text: "Malta" },
		{ value: "MU", text: "Mauritius" },
		{ value: "MV", text: "Maldives" },
		{ value: "MW", text: "Malawi" },
		{ value: "MX", text: "Mexico" },
		{ value: "MY", text: "Malaysia" },
		{ value: "MZ", text: "Mozambique" },
		{ value: "NA", text: "Namibia" },
		{ value: "NC", text: "New Caledonia" },
		{ value: "NE", text: "Niger" },
		{ value: "NF", text: "Norfolk Island" },
		{ value: "NG", text: "Nigeria" },
		{ value: "NI", text: "Nicaragua" },
		{ value: "NL", text: "Netherlands" },
		{ value: "NO", text: "Norway" },
		{ value: "NP", text: "Nepal" },
		{ value: "NR", text: "Nauru" },
		{ value: "NU", text: "Niue" },
		{ value: "NZ", text: "New Zealand (Aotearoa)" },
		{ value: "OM", text: "Oman" },
		{ value: "PA", text: "Panama" },
		{ value: "PE", text: "Peru" },
		{ value: "PF", text: "French Polynesia" },
		{ value: "PG", text: "Papua New Guinea" },
		{ value: "PH", text: "Philippines" },
		{ value: "PK", text: "Pakistan" },
		{ value: "PL", text: "Poland" },
		{ value: "PM", text: "Saint Pierre and Miquelon" },
		{ value: "PN", text: "Pitcairn" },
		{ value: "PR", text: "Puerto Rico" },
		{ value: "PS", text: "Palestinian Territory" },
		{ value: "PT", text: "Portugal" },
		{ value: "PW", text: "Palau" },
		{ value: "PY", text: "Paraguay" },
		{ value: "QA", text: "Qatar" },
		{ value: "RE", text: "Reunion" },
		{ value: "RO", text: "Romania" },
		{ value: "RU", text: "Russian Federation" },
		{ value: "RW", text: "Rwanda" },
		{ value: "SA", text: "Saudi Arabia" },
		{ value: "SB", text: "Solomon Islands" },
		{ value: "SC", text: "Seychelles" },
		{ value: "SD", text: "Sudan" },
		{ value: "SE", text: "Sweden" },
		{ value: "SG", text: "Singapore" },
		{ value: "SH", text: "Saint Helena" },
		{ value: "SI", text: "Slovenia" },
		{ value: "SJ", text: "Svalbard and Jan Mayen" },
		{ value: "SK", text: "Slovakia" },
		{ value: "SL", text: "Sierra Leone" },
		{ value: "SM", text: "San Marino" },
		{ value: "SN", text: "Senegal" },
		{ value: "SO", text: "Somalia" },
		{ value: "SR", text: "Suriname" },
		{ value: "ST", text: "Sao Tome and Principe" },
		{ value: "SV", text: "El Salvador" },
		{ value: "SY", text: "Syria" },
		{ value: "SZ", text: "Swaziland" },
		{ value: "TC", text: "Turks and Caicos Islands" },
		{ value: "TD", text: "Chad" },
		{ value: "TF", text: "French Southern Territories" },
		{ value: "TG", text: "Togo" },
		{ value: "TH", text: "Thailand" },
		{ value: "TJ", text: "Tajikistan" },
		{ value: "TK", text: "Tokelau" },
		{ value: "TM", text: "Turkmenistan" },
		{ value: "TN", text: "Tunisia" },
		{ value: "TO", text: "Tonga" },
		{ value: "TR", text: "Turkey" },
		{ value: "TT", text: "Trinidad and Tobago" },
		{ value: "TV", text: "Tuvalu" },
		{ value: "TW", text: "Taiwan" },
		{ value: "TZ", text: "Tanzania" },
		{ value: "UA", text: "Ukraine" },
		{ value: "UG", text: "Uganda" },
		{ value: "UM", text: "United States Minor Outlying Islands" },
		{ value: "US", text: "United States" },
		{ value: "UY", text: "Uruguay" },
		{ value: "UZ", text: "Uzbekistan" },
		{ value: "VC", text: "Saint Vincent and the Grenadines" },
		{ value: "VE", text: "Venezuela" },
		{ value: "VG", text: "Virgin Islands (British)" },
		{ value: "VI", text: "Virgin Islands (U.S.)" },
		{ value: "VN", text: "Viet Nam" },
		{ value: "VU", text: "Vanuatu" },
		{ value: "WF", text: "Wallis and Futuna" },
		{ value: "WS", text: "Samoa" },
		{ value: "YE", text: "Yemen" },
		{ value: "YT", text: "Mayotte" },
		{ value: "ZA", text: "South Africa" },
		{ value: "ZM", text: "Zambia" },
		{ value: "ZR", text: "Zaire (former)" },
		{ value: "ZW", text: "Zimbabwe" }
	],
	three_letter_abbreviations: [
		{ value: "USA", text: "United States" },
		{ value: "AFG", text: "Afghanistan" },
		{ value: "ALA", text: "Åland Islands" },
		{ value: "ALB", text: "Albania" },
		{ value: "DZA", text: "Algeria" },
		{ value: "ASM", text: "American Samoa" },
		{ value: "AND", text: "Andorra" },
		{ value: "AGO", text: "Angola" },
		{ value: "AIA", text: "Anguilla" },
		{ value: "ATA", text: "Antarctica" },
		{ value: "ATG", text: "Antigua and Barbuda" },
		{ value: "ARG", text: "Argentina" },
		{ value: "ARM", text: "Armenia" },
		{ value: "ABW", text: "Aruba" },
		{ value: "AUS", text: "Australia" },
		{ value: "AUT", text: "Austria" },
		{ value: "AZE", text: "Azerbaijan" },
		{ value: "BHS", text: "Bahamas" },
		{ value: "BHR", text: "Bahrain" },
		{ value: "BGD", text: "Bangladesh" },
		{ value: "BRB", text: "Barbados" },
		{ value: "BLR", text: "Belarus" },
		{ value: "BEL", text: "Belgium" },
		{ value: "BLZ", text: "Belize" },
		{ value: "BEN", text: "Benin" },
		{ value: "BMU", text: "Bermuda" },
		{ value: "BTN", text: "Bhutan" },
		{ value: "BOL", text: "Bolivia, Plurinational State of" },
		{ value: "BES", text: "Bonaire, Sint Eustatius and Saba" },
		{ value: "BIH", text: "Bosnia and Herzegovina" },
		{ value: "BWA", text: "Botswana" },
		{ value: "BVT", text: "Bouvet Island" },
		{ value: "BRA", text: "Brazil" },
		{ value: "IOT", text: "British Indian Ocean Territory" },
		{ value: "BRN", text: "Brunei Darussalam" },
		{ value: "BGR", text: "Bulgaria" },
		{ value: "BFA", text: "Burkina Faso" },
		{ value: "BDI", text: "Burundi" },
		{ value: "KHM", text: "Cambodia" },
		{ value: "CMR", text: "Cameroon" },
		{ value: "CAN", text: "Canada" },
		{ value: "CPV", text: "Cape Verde" },
		{ value: "CYM", text: "Cayman Islands" },
		{ value: "CAF", text: "Central African Republic" },
		{ value: "TCD", text: "Chad" },
		{ value: "CHL", text: "Chile" },
		{ value: "CHN", text: "China" },
		{ value: "CXR", text: "Christmas Island" },
		{ value: "CCK", text: "Cocos (Keeling) Islands" },
		{ value: "COL", text: "Colombia" },
		{ value: "COM", text: "Comoros" },
		{ value: "COG", text: "Congo" },
		{ value: "COD", text: "Congo, the Democratic Republic of the" },
		{ value: "COK", text: "Cook Islands" },
		{ value: "CRI", text: "Costa Rica" },
		{ value: "CIV", text: "Côte d'Ivoire" },
		{ value: "HRV", text: "Croatia" },
		{ value: "CUB", text: "Cuba" },
		{ value: "CUW", text: "Curaçao" },
		{ value: "CYP", text: "Cyprus" },
		{ value: "CZE", text: "Czech Republic" },
		{ value: "DNK", text: "Denmark" },
		{ value: "DJI", text: "Djibouti" },
		{ value: "DMA", text: "Dominica" },
		{ value: "DOM", text: "Dominican Republic" },
		{ value: "ECU", text: "Ecuador" },
		{ value: "EGY", text: "Egypt" },
		{ value: "SLV", text: "El Salvador" },
		{ value: "GNQ", text: "Equatorial Guinea" },
		{ value: "ERI", text: "Eritrea" },
		{ value: "EST", text: "Estonia" },
		{ value: "ETH", text: "Ethiopia" },
		{ value: "FLK", text: "Falkland Islands (Malvinas)" },
		{ value: "FRO", text: "Faroe Islands" },
		{ value: "FJI", text: "Fiji" },
		{ value: "FIN", text: "Finland" },
		{ value: "FRA", text: "France" },
		{ value: "GUF", text: "French Guiana" },
		{ value: "PYF", text: "French Polynesia" },
		{ value: "ATF", text: "French Southern Territories" },
		{ value: "GAB", text: "Gabon" },
		{ value: "GMB", text: "Gambia" },
		{ value: "GEO", text: "Georgia" },
		{ value: "DEU", text: "Germany" },
		{ value: "GHA", text: "Ghana" },
		{ value: "GIB", text: "Gibraltar" },
		{ value: "GRC", text: "Greece" },
		{ value: "GRL", text: "Greenland" },
		{ value: "GRD", text: "Grenada" },
		{ value: "GLP", text: "Guadeloupe" },
		{ value: "GUM", text: "Guam" },
		{ value: "GTM", text: "Guatemala" },
		{ value: "GGY", text: "Guernsey" },
		{ value: "GIN", text: "Guinea" },
		{ value: "GNB", text: "Guinea-Bissau" },
		{ value: "GUY", text: "Guyana" },
		{ value: "HTI", text: "Haiti" },
		{ value: "HMD", text: "Heard Island and McDonald Islands" },
		{ value: "VAT", text: "Holy See (Vatican City State)" },
		{ value: "HND", text: "Honduras" },
		{ value: "HKG", text: "Hong Kong" },
		{ value: "HUN", text: "Hungary" },
		{ value: "ISL", text: "Iceland" },
		{ value: "IND", text: "India" },
		{ value: "IDN", text: "Indonesia" },
		{ value: "IRN", text: "Iran, Islamic Republic of" },
		{ value: "IRQ", text: "Iraq" },
		{ value: "IRL", text: "Ireland" },
		{ value: "IMN", text: "Isle of Man" },
		{ value: "ISR", text: "Israel" },
		{ value: "ITA", text: "Italy" },
		{ value: "JAM", text: "Jamaica" },
		{ value: "JPN", text: "Japan" },
		{ value: "JEY", text: "Jersey" },
		{ value: "JOR", text: "Jordan" },
		{ value: "KAZ", text: "Kazakhstan" },
		{ value: "KEN", text: "Kenya" },
		{ value: "KIR", text: "Kiribati" },
		{ value: "PRK", text: "Korea, Democratic People's Republic of" },
		{ value: "KOR", text: "Korea, Republic of" },
		{ value: "KWT", text: "Kuwait" },
		{ value: "KGZ", text: "Kyrgyzstan" },
		{ value: "LAO", text: "Lao People's Democratic Republic" },
		{ value: "LVA", text: "Latvia" },
		{ value: "LBN", text: "Lebanon" },
		{ value: "LSO", text: "Lesotho" },
		{ value: "LBR", text: "Liberia" },
		{ value: "LBY", text: "Libya" },
		{ value: "LIE", text: "Liechtenstein" },
		{ value: "LTU", text: "Lithuania" },
		{ value: "LUX", text: "Luxembourg" },
		{ value: "MAC", text: "Macao" },
		{ value: "MKD", text: "Macedonia, the former Yugoslav Republic of" },
		{ value: "MDG", text: "Madagascar" },
		{ value: "MWI", text: "Malawi" },
		{ value: "MYS", text: "Malaysia" },
		{ value: "MDV", text: "Maldives" },
		{ value: "MLI", text: "Mali" },
		{ value: "MLT", text: "Malta" },
		{ value: "MHL", text: "Marshall Islands" },
		{ value: "MTQ", text: "Martinique" },
		{ value: "MRT", text: "Mauritania" },
		{ value: "MUS", text: "Mauritius" },
		{ value: "MYT", text: "Mayotte" },
		{ value: "MEX", text: "Mexico" },
		{ value: "FSM", text: "Micronesia, Federated States of" },
		{ value: "MDA", text: "Moldova, Republic of" },
		{ value: "MCO", text: "Monaco" },
		{ value: "MNG", text: "Mongolia" },
		{ value: "MNE", text: "Montenegro" },
		{ value: "MSR", text: "Montserrat" },
		{ value: "MAR", text: "Morocco" },
		{ value: "MOZ", text: "Mozambique" },
		{ value: "MMR", text: "Myanmar" },
		{ value: "NAM", text: "Namibia" },
		{ value: "NRU", text: "Nauru" },
		{ value: "NPL", text: "Nepal" },
		{ value: "NLD", text: "Netherlands" },
		{ value: "NCL", text: "New Caledonia" },
		{ value: "NZL", text: "New Zealand" },
		{ value: "NIC", text: "Nicaragua" },
		{ value: "NER", text: "Niger" },
		{ value: "NGA", text: "Nigeria" },
		{ value: "NIU", text: "Niue" },
		{ value: "NFK", text: "Norfolk Island" },
		{ value: "MNP", text: "Northern Mariana Islands" },
		{ value: "NOR", text: "Norway" },
		{ value: "OMN", text: "Oman" },
		{ value: "PAK", text: "Pakistan" },
		{ value: "PLW", text: "Palau" },
		{ value: "PSE", text: "Palestinian Territory, Occupied" },
		{ value: "PAN", text: "Panama" },
		{ value: "PCZ", text: "Panama Canal Zone" },
		{ value: "PNG", text: "Papua New Guinea" },
		{ value: "PRY", text: "Paraguay" },
		{ value: "PER", text: "Peru" },
		{ value: "PHL", text: "Philippines" },
		{ value: "PCN", text: "Pitcairn" },
		{ value: "POL", text: "Poland" },
		{ value: "PRT", text: "Portugal" },
		{ value: "PRI", text: "Puerto Rico" },
		{ value: "QAT", text: "Qatar" },
		{ value: "REU", text: "Réunion" },
		{ value: "ROU", text: "Romania" },
		{ value: "RUS", text: "Russian Federation" },
		{ value: "RWA", text: "Rwanda" },
		{ value: "BLM", text: "Saint Barthélemy" },
		{ value: "SHN", text: "Saint Helena, Ascension and Tristan da Cunha" },
		{ value: "KNA", text: "Saint Kitts and Nevis" },
		{ value: "LCA", text: "Saint Lucia" },
		{ value: "MAF", text: "Saint Martin (French part)" },
		{ value: "SPM", text: "Saint Pierre and Miquelon" },
		{ value: "VCT", text: "Saint Vincent and the Grenadines" },
		{ value: "WSM", text: "Samoa" },
		{ value: "SMR", text: "San Marino" },
		{ value: "STP", text: "Sao Tome and Principe" },
		{ value: "SAU", text: "Saudi Arabia" },
		{ value: "SEN", text: "Senegal" },
		{ value: "SRB", text: "Serbia" },
		{ value: "SYC", text: "Seychelles" },
		{ value: "SLE", text: "Sierra Leone" },
		{ value: "SGP", text: "Singapore" },
		{ value: "SXM", text: "Sint Maarten (Dutch part)" },
		{ value: "SVK", text: "Slovakia" },
		{ value: "SVN", text: "Slovenia" },
		{ value: "SLB", text: "Solomon Islands" },
		{ value: "SOM", text: "Somalia" },
		{ value: "ZAF", text: "South Africa" },
		{ value: "SGS", text: "South Georgia and the South Sandwich Islands" },
		{ value: "SSD", text: "South Sudan" },
		{ value: "ESP", text: "Spain" },
		{ value: "LKA", text: "Sri Lanka" },
		{ value: "SDN", text: "Sudan" },
		{ value: "SUR", text: "Suriname" },
		{ value: "SJM", text: "Svalbard and Jan Mayen" },
		{ value: "SWZ", text: "Swaziland" },
		{ value: "SWE", text: "Sweden" },
		{ value: "CHE", text: "Switzerland" },
		{ value: "SYR", text: "Syrian Arab Republic" },
		{ value: "TWN", text: "Taiwan, Province of China" },
		{ value: "TJK", text: "Tajikistan" },
		{ value: "TZA", text: "Tanzania, United Republic of" },
		{ value: "THA", text: "Thailand" },
		{ value: "TLS", text: "Timor-Leste" },
		{ value: "TGO", text: "Togo" },
		{ value: "TKL", text: "Tokelau" },
		{ value: "TON", text: "Tonga" },
		{ value: "TTO", text: "Trinidad and Tobago" },
		{ value: "TUN", text: "Tunisia" },
		{ value: "TUR", text: "Turkey" },
		{ value: "TKM", text: "Turkmenistan" },
		{ value: "TCA", text: "Turks and Caicos Islands" },
		{ value: "TUV", text: "Tuvalu" },
		{ value: "UGA", text: "Uganda" },
		{ value: "UKR", text: "Ukraine" },
		{ value: "SOV", text: "Union of Soviet Socialist Republics" },
		{ value: "ARE", text: "United Arab Emirates" },
		{ value: "GBR", text: "United Kingdom" },
		{ value: "UMI", text: "United States Minor Outlying Islands" },
		{ value: "URY", text: "Uruguay" },
		{ value: "UZB", text: "Uzbekistan" },
		{ value: "VUT", text: "Vanuatu" },
		{ value: "VEN", text: "Venezuela" },
		{ value: "VNM", text: "Viet Nam" },
		{ value: "VGB", text: "Virgin Islands, British" },
		{ value: "VIR", text: "Virgin Islands, U.S." },
		{ value: "WLF", text: "Wallis and Futuna" },
		{ value: "ESH", text: "Western Sahara" },
		{ value: "YEM", text: "Yemen" },
		{ value: "ZMB", text: "Zambia" },
		{ value: "ZWE", text: "Zimbabwe" }
	]
};

export const YESNONUM = [
	{ text: "Yes", value: 1 },
	{ text: "No", value: 0 }
];
