export const getLocationThresholdTooltip = (sampleSize?: number | null, threshold?: number): string | undefined => {
	if (sampleSize && threshold) {
		return `This location grade contains ${sampleSize} pitches. \n\nLocation grades are not reliable when there are less than ${threshold} pitches in the sample.`;
	}
	return undefined;
};

export const getStuffThresholdTooltip = (sampleSize?: number | null, threshold?: number): string | undefined => {
	if (sampleSize && threshold) {
		return `This stuff grade contains ${sampleSize} pitches. \n\nStuff grades are not reliable when there are less than ${threshold} pitches in the sample.`;
	}
	return undefined;
};

export const getAttackAngleThresholdTooltip = (sampleSize?: number | null, threshold?: number): string | undefined => {
	if (sampleSize && threshold) {
		return `This sample contains ${sampleSize} BIP. \n\nAttack angle is not reliable when there are less than ${threshold} balls in play (BIP) in the sample.`;
	}
	return undefined;
};

export const getBatSpeedThresholdTooltip = (sampleSize?: number | null, threshold?: number): string | undefined => {
	if (sampleSize && threshold) {
		return `This sample contains ${sampleSize} BIP. \n\nBat speed is not reliable when there are less than ${threshold} balls in play (BIP) in the sample.`;
	}
	return undefined;
};

export const getBatToBallThresholdTooltip = (sampleSize?: number | null, threshold?: number): string | undefined => {
	if (sampleSize && threshold) {
		return `This sample contains ${sampleSize} swings. \n\nBat to ball is not reliable when there are less than ${threshold} swings in the sample.`;
	}
	return undefined;
};

export const getSwingDecisionThresholdTooltip = (
	sampleSize?: number | null,
	threshold?: number
): string | undefined => {
	if (sampleSize && threshold) {
		return `This sample contains ${sampleSize} pitches. \n\nSwing decisions are not reliable when there are less than ${threshold} pitches in the sample.`;
	}
	return undefined;
};

export const getManualPitchTypeMapBySeasonTooltip = (pitchTypeFrom?: string): string | undefined => {
	if (pitchTypeFrom) {
		return `Includes pitches originally tagged as ${pitchTypeFrom}`;
	}
};
