import { CancelToken } from "axios";

import axios from "_redux/_utils/_axios";
import { handleAxiosError } from "_react/shared/_helpers/axios";

import { TEval, TPlayersResponse } from "_react/evals/list/_types";

export const fetchEvalData = (evalIds: Array<string>, cancelToken?: CancelToken) => {
	// console.log("fetchEvalData");
	const path = `/evals`;
	const body = { eval_ids: evalIds };
	return axios
		.post(path, body, { cancelToken })
		.then(response => response.data as Array<TEval>)
		.catch(handleAxiosError);
};

export const fetchPlayerData = (philIds: Array<number>, cancelToken?: CancelToken) => {
	const uniquePhilIds = philIds.filter((v, i, a) => a.indexOf(v) === i);
	const path = `/players`;
	const body = { phil_ids: uniquePhilIds };
	return axios
		.post(path, body, { cancelToken })
		.then(response => response.data as TPlayersResponse)
		.catch(handleAxiosError);
};
