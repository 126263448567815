import dayjs, { Dayjs } from "dayjs";

import { round10 } from "_react/shared/_helpers/numbers";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { IPlayerSeasonArsenalScoresSchema } from "_react/shared/data_models/arsenal_scores/_types";
import { PLAYER_TYPE_BATTER, PLAYER_TYPE_PITCHER } from "_react/shared/data_models/phred/_constants";
import { IIntlProspectValue } from "_react/shared/data_models/phred/_types";
// import { PLAYER_CLASSIFICATION_PRO } from "_react/shared/data_models/team/_constants";
import { $TSFixMeEval } from "_react/evals/shared/_types";
import {
	PITCHER_POSITIONS,
	POSITION_GROUP_PITCHER,
	POSITION_GROUP_POSITION_PLAYER
} from "_react/shared/_constants/position_groups";
import { IPlayerSeasonHitterGrades } from "_react/shared/data_models/hitter_grades/_types";

export const getProjectionsIntlSeason = (player: TPlayerPageCombinedPlayer): number => {
	// For INTL players, return eligible year or current year
	return player.intlProfile?.eligibleYear ?? dayjs().year();
};

export const formatIntlSurplusValue = (value: IIntlProspectValue | undefined) => {
	return value ? round10(value?.xsurplusMillions, -1) : undefined;
};

export const getHasTwoWayProjections = (intlProspectValues: Array<IIntlProspectValue> | undefined): boolean => {
	// Filter for player's R4 Year
	const currentIntlProspectValues = intlProspectValues;

	// Check for batting projection
	const hasBattingProjection = Boolean(
		currentIntlProspectValues?.find((pv: IIntlProspectValue) => pv.playerType === PLAYER_TYPE_BATTER)
	);

	// Check for pitching projection
	const hasPitchingProjection = Boolean(
		currentIntlProspectValues?.find((pv: IIntlProspectValue) => pv.playerType === PLAYER_TYPE_PITCHER)
	);

	// If both exist
	return hasBattingProjection && hasPitchingProjection;
};

export const getIsTwoWayPlayer = (
	intlProspectValues: Array<IIntlProspectValue> | undefined,
	evals: $TSFixMeEval,
	player: TPlayerPageCombinedPlayer
): boolean => {
	// Check for two way projections
	if (getHasTwoWayProjections(intlProspectValues)) {
		return true;
	}

	// Check for both pitch and hit evals in the past two years
	const evalTypes = evals?.map((evaluation: $TSFixMeEval) => evaluation.eval_type.split("_")[1]) ?? [];
	// Two way if both types exist
	if (evalTypes.includes("HIT") && evalTypes.includes("PITCH")) return true;

	// Two way if either AMA or INTL profile position doesn't match combined position
	const combinedPositionGroup = player.isPitcher ? POSITION_GROUP_PITCHER : POSITION_GROUP_POSITION_PLAYER;
	const intlPositionGroup = player.intlProfile?.position
		? PITCHER_POSITIONS.includes(player.intlProfile.position)
			? POSITION_GROUP_PITCHER
			: POSITION_GROUP_POSITION_PLAYER
		: null;
	const amaPositionGroup = player.amaProfile?.position
		? PITCHER_POSITIONS.includes(player.amaProfile.position)
			? POSITION_GROUP_PITCHER
			: POSITION_GROUP_POSITION_PLAYER
		: null;
	if (
		(intlPositionGroup && combinedPositionGroup !== intlPositionGroup) ||
		(amaPositionGroup && combinedPositionGroup !== amaPositionGroup)
	)
		return true;

	return false;
};

export const getObservedIntlSeason = (
	player: TPlayerPageCombinedPlayer,
	playerType: string,
	playerSeasonArsenalScores?: Array<IPlayerSeasonArsenalScoresSchema> | null,
	playerSeasonHitterGrades?: Array<IPlayerSeasonHitterGrades> | null
): number => {
	// Removing this for now but keeping it because we may go back to it
	// // For pro players, get the year from the first pro contract and use the year before if the contract was signed on Jan. 15 or earlier of that year
	// // otherwise use the first pro contract year
	// if (player?.playerClassification === PLAYER_CLASSIFICATION_PRO) {
	// 	const firstProContractYear = parseInt(player?.proProfile?.firstProContractDate?.substring(0, 4) ?? "");
	// 	if (!isNaN(firstProContractYear)) {
	// 		return dayjs(player?.proProfile?.firstProContractDate).isAfter(
	// 			dayjs(`${firstProContractYear}-01-15`),
	// 			"date"
	// 		)
	// 			? firstProContractYear
	// 			: firstProContractYear - 1;
	// 	}
	// }

	// Most recent INTL season if player has a pro profile
	if (player.proProfile && playerType === PLAYER_TYPE_PITCHER && playerSeasonArsenalScores?.length) {
		return Math.max(...playerSeasonArsenalScores.map((score: IPlayerSeasonArsenalScoresSchema) => score.season));
	}
	if (player.proProfile && playerType === PLAYER_TYPE_BATTER && playerSeasonHitterGrades?.length) {
		return Math.max(...playerSeasonHitterGrades.map((score: IPlayerSeasonHitterGrades) => score.season));
	}
	// otherwise this is just the current year
	const today = dayjs();
	const thisYear = today.year();
	return thisYear;
};

export const getIntlDraftDate = (eligibleYear?: number | null): Dayjs => {
	return eligibleYear ? dayjs(`${eligibleYear}-08-31`) : dayjs(`${dayjs().year()}-08-31`);
};
