import React, { useState } from "react";

import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import ExpandMore from "_react/shared/legacy/ui/icons/ExpandMore";
import ExpandLess from "_react/shared/legacy/ui/icons/ExpandLess";

import {
	GoalContainer,
	GoalSectionHeader,
	GoalSectionLeftDiv,
	GoalSectionRightDiv
} from "_react/playerplan/shared/_styles";
import { TPlayerPlanGoal } from "_react/playerplan/shared/_types";
import { PlayerPlanGoal, TPlayerPlanGoalStyle } from "_react/playerplan/shared/PlayerPlanGoal";

export type TCollapsibleGoalSectionStyle = {
	playerPlanGoalStyle?: TPlayerPlanGoalStyle;
};

export type TCollapsibleGoalSectionProps = {
	title: string;
	goals: Array<TPlayerPlanGoal>;
	isGoalActionsDisabled?: boolean;
	updateGoal: (goal: TPlayerPlanGoal, updateApprovalDate?: boolean) => void;
	isDisabled: boolean;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TCollapsibleGoalSectionStyle;
};

export const CollapsibleGoalSection = ({
	title,
	goals,
	isGoalActionsDisabled = false,
	updateGoal,
	isDisabled = false,
	colorSchemeGroup = defaultColorScheme,
	style
}: TCollapsibleGoalSectionProps) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);

	return (
		<GoalContainer>
			{goals.length > 0 && (
				<>
					<GoalSectionHeader onClick={() => setIsExpanded(!isExpanded)}>
						<GoalSectionLeftDiv>
							{!isExpanded && <ExpandMore fill={"black"} />}
							{isExpanded && <ExpandLess fill={"black"} />}
						</GoalSectionLeftDiv>
						<div>
							{title} ({goals.length})
						</div>
						<GoalSectionRightDiv />
					</GoalSectionHeader>
					{isExpanded &&
						goals.map((goal: TPlayerPlanGoal) => (
							<PlayerPlanGoal
								key={goal.id}
								goal={goal}
								isGoalActionsDisabled={isGoalActionsDisabled}
								updateGoal={updateGoal}
								colorSchemeGroup={colorSchemeGroup}
								isDisabled={isDisabled}
								style={style?.playerPlanGoalStyle}
							/>
						))}
				</>
			)}
		</GoalContainer>
	);
};
