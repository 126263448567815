import React from "react";
import { Badge, SystemStyleObject } from "@chakra-ui/react";

import { TEAM_COLORS } from "_react/shared/legacy/ui/Colors";

type TTeamBadgeProps = {
	teamAbbreviation?: string | null;
	nullFillerText?: string;
	style?: SystemStyleObject;
};

const TeamBadge = ({ teamAbbreviation, nullFillerText = "", style }: TTeamBadgeProps) => {
	// TODO convert this legacy TEAM_COLORS constant into chakra theme tokens
	const colorScheme = teamAbbreviation
		? TEAM_COLORS[teamAbbreviation.toLowerCase()]
		: { secondary: { color: "gray.700", text: "white" } };
	return (
		<Badge
			variant="solid"
			borderRadius="lg"
			bg={colorScheme?.secondary?.color}
			color={colorScheme?.secondary?.text}
			sx={style}
		>
			{teamAbbreviation ?? nullFillerText}
		</Badge>
	);
};

export default TeamBadge;
