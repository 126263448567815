import { createMachine, assign, AnyEventObject, StateFrom } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";
import dayjs, { Dayjs } from "dayjs";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { fetchAmaProspectValue } from "_react/shared/data_models/phred/_network";
import { IAmaProspectValue } from "_react/shared/data_models/phred/_types";
import { MODEL_NAME_V2 } from "_react/shared/data_models/phred/_constants";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";

const AMA_PROSPECT_VALUES_CANCEL_SOURCE = "amaProspectValues";

export type TPlayerPageAmaCancelSource = {
	[AMA_PROSPECT_VALUES_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TPlayerPageAmaContext = {
	playerId?: number;
	lastPlayerId?: number; // Not currently used
	projectionsSeasonData?: [number, number, boolean];
	currentSeasonData?: { currentSeason: number | undefined; isFetching: boolean | undefined };
	currentDraftDate?: Dayjs;
	currentDraftYear?: number;
	shouldFetchData?: boolean;
	amaProspectValues?: Array<IAmaProspectValue>;
	cancelSources: TPlayerPageAmaCancelSource;
	toast?: CreateToastFnReturn;
};

export const FETCHING_AMA_PROSPECT_VALUES = { initialized: { amaProspectValues: "fetching" } };

const FETCH_AMA_PROSPECT_VALUES_DONE = "done.invoke.fetchingAmaProspectValues:invocation[0]";
type TFetchAmaProspectValuesEvent = { type: typeof FETCH_AMA_PROSPECT_VALUES_DONE; data?: Array<IAmaProspectValue> };

export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PROJECTIONS_SEASON_DATA = "SET_PROJECTIONS_SEASON_DATA";
export const SET_CURRENT_SEASON_DATA = "SET_CURRENT_SEASON_DATA";
export const SET_CURRENT_DRAFT_DATE = "SET_CURRENT_DRAFT_DATE";
type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value?: number };
type TSetProjectionsSeasonDataEvent = { type: typeof SET_PROJECTIONS_SEASON_DATA; value?: [number, number, boolean] };
type TSetCurrentSeasonData = {
	type: typeof SET_CURRENT_SEASON_DATA;
	value?: { currentSeason: number | undefined; isFetching: boolean | undefined };
};
type TSetCurrentDraftDate = { type: typeof SET_CURRENT_DRAFT_DATE; value?: Dayjs };

export type TPlayerPageAmaEvent =
	| TFetchAmaProspectValuesEvent
	| TSetPlayerIdEvent
	| TSetProjectionsSeasonDataEvent
	| TSetCurrentSeasonData
	| TSetCurrentDraftDate;

const PlayerPageAmaMachine = (
	playerIdProp?: number,
	projectionsSeasonDataProp?: [number, number, boolean],
	currentSeasonDataProp?: { currentSeason: number | undefined; isFetching: boolean | undefined },
	currentDraftDateProp?: Dayjs,
	shouldFetchData = true,
	toastProp?: CreateToastFnReturn
) =>
	createMachine<TPlayerPageAmaContext, TPlayerPageAmaEvent>(
		{
			id: "playerPageAma",
			predictableActionArguments: true,
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				projectionsSeasonData: projectionsSeasonDataProp,
				currentSeasonData: currentSeasonDataProp,
				currentDraftDate: currentDraftDateProp,
				currentDraftYear: undefined,
				shouldFetchData: shouldFetchData,
				amaProspectValues: undefined,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					initial: "idle",
					states: {
						idle: {
							always: "#playerPageAma.initialized"
						}
					}
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PLAYER_ID]: {
							actions: ["setPlayerId", "clearAmaProspectValues"],
							cond: "shouldSetPlayerId"
						},
						[SET_PROJECTIONS_SEASON_DATA]: { actions: "setProjectionsSeasonData" },
						[SET_CURRENT_SEASON_DATA]: { actions: "setCurrentSeasonData" },
						[SET_CURRENT_DRAFT_DATE]: { actions: "setCurrentDraftDate" }
					},
					states: {
						amaProspectValues: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingAmaProspectValues",
												cond: "shouldFetchAmaProspectValues"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingAmaProspectValues",
									entry: ["refreshAmaProspectValuesCancelSource"],
									invoke: {
										src: "fetchAmaProspectValues",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchAmaProspectValuesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchAmaProspectValuesErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (context: TPlayerPageAmaContext, event: TPlayerPageAmaEvent) =>
					event.type === SET_PLAYER_ID && context.playerId !== event.value,
				shouldFetchAmaProspectValues: (context: TPlayerPageAmaContext, _event: TPlayerPageAmaEvent) =>
					context.amaProspectValues === undefined && shouldFetchData && context.playerId !== undefined
			},
			actions: {
				clearAmaProspectValues: assign<TPlayerPageAmaContext, TPlayerPageAmaEvent>({
					amaProspectValues: (_context: TPlayerPageAmaContext, _event: TPlayerPageAmaEvent) => undefined,
					cancelSources: (context: TPlayerPageAmaContext, _event: TPlayerPageAmaEvent) => {
						const { cancelSources } = context;
						cancelSources[AMA_PROSPECT_VALUES_CANCEL_SOURCE]?.cancel();
						cancelSources[AMA_PROSPECT_VALUES_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setPlayerId: assign<TPlayerPageAmaContext, TPlayerPageAmaEvent>({
					playerId: (context: TPlayerPageAmaContext, event: TPlayerPageAmaEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					}
				}),
				setProjectionsSeasonData: assign<TPlayerPageAmaContext, TPlayerPageAmaEvent>({
					projectionsSeasonData: (context: TPlayerPageAmaContext, event: TPlayerPageAmaEvent) => {
						if (event.type !== SET_PROJECTIONS_SEASON_DATA) return context.projectionsSeasonData;
						return event.value;
					}
				}),
				setCurrentSeasonData: assign<TPlayerPageAmaContext, TPlayerPageAmaEvent>({
					currentSeasonData: (context: TPlayerPageAmaContext, event: TPlayerPageAmaEvent) => {
						if (event.type !== SET_CURRENT_SEASON_DATA) return context.currentSeasonData;
						return event.value;
					}
				}),
				setCurrentDraftDate: assign<TPlayerPageAmaContext, TPlayerPageAmaEvent>({
					currentDraftDate: (context: TPlayerPageAmaContext, event: TPlayerPageAmaEvent) => {
						if (event.type !== SET_CURRENT_DRAFT_DATE) return context.currentDraftDate;
						return event.value;
					},
					currentDraftYear: (context: TPlayerPageAmaContext, event: TPlayerPageAmaEvent) => {
						if (event.type !== SET_CURRENT_DRAFT_DATE) return context.currentDraftYear;
						return event.value
							? dayjs() > event.value.add(7, "day")
								? event.value.year() + 1
								: event.value.year()
							: undefined;
					}
				}),
				// Cancel Source Actions
				refreshAmaProspectValuesCancelSource: assign<TPlayerPageAmaContext, TPlayerPageAmaEvent>({
					cancelSources: (context: TPlayerPageAmaContext, _event: TPlayerPageAmaEvent) => {
						if (context.cancelSources[AMA_PROSPECT_VALUES_CANCEL_SOURCE] != null)
							context.cancelSources[AMA_PROSPECT_VALUES_CANCEL_SOURCE].cancel();
						context.cancelSources[AMA_PROSPECT_VALUES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchAmaProspectValuesSuccess: assign<TPlayerPageAmaContext, TPlayerPageAmaEvent>({
					amaProspectValues: (context: TPlayerPageAmaContext, event: TPlayerPageAmaEvent) => {
						if (event.type !== FETCH_AMA_PROSPECT_VALUES_DONE) return context.amaProspectValues;
						return event.data;
					}
				}),
				// Fetch Errored Actions
				handleFetchAmaProspectValuesErrored: (context: TPlayerPageAmaContext, _event: TPlayerPageAmaEvent) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Surplus Value",
							description: "Error fetching surplus value data."
						});
				}
			},
			services: {
				fetchAmaProspectValues: (context: TPlayerPageAmaContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchAmaProspectValue(
							{
								playerId: context.playerId,
								modelName: MODEL_NAME_V2,
								isUseCache: true
							},
							context.cancelSources[AMA_PROSPECT_VALUES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export type TPlayerPageAmaState = StateFrom<typeof PlayerPageAmaMachine>;

export default PlayerPageAmaMachine;
