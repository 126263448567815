import React, { FunctionComponent } from "react";

import { Form } from "_react/shared/forms";

import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { intlBioPlugin } from "_react/playerpage/bio/_helpers";
import { TIntlBioDocument } from "_react/playerpage/bio/_types";

type TIntlBioProps = {
	colorScheme?: ColorSchemeGroup;
	bioDisplayOnly: boolean;
	intlBioDocument: TIntlBioDocument | null;
	setIntlBioDocument: (newDocument: TIntlBioDocument) => void;
};

export const IntlBio: FunctionComponent<TIntlBioProps> = ({
	colorScheme = defaultColorScheme,
	bioDisplayOnly,
	intlBioDocument,
	setIntlBioDocument
}) => {
	if (intlBioDocument == null) return <div />;
	return (
		<Form<TIntlBioDocument>
			colorScheme={colorScheme}
			document={intlBioDocument}
			plugin={intlBioPlugin}
			onDocumentUpdate={setIntlBioDocument}
			readOnly={bioDisplayOnly}
			showFooter={false}
			floatingFooter={false}
		/>
	);
};

export default IntlBio;
