import dayjs from "dayjs";

import {
	IIntlProspectValuePlayer,
	IIntlProspectValueSurplusValuePresentValueByseason
} from "_react/shared/data_models/phred/_types";
import { TSvData } from "_react/shared/ui/data/tables/IntlSurplusValueSimilarPlayersTable/_types";
import { NUMBER_OF_SEASONS } from "_react/shared/ui/data/tables/IntlSurplusValueSimilarPlayersTable/_constants";

export const getAllSvData = (row: IIntlProspectValuePlayer, signedYear: number | undefined): TSvData[] | undefined => {
	if (signedYear === undefined) return undefined;

	const plotData: TSvData[] = [];
	if (row.xsurplusMillions !== null) {
		plotData.push({
			sv: row.xsurplusMillions,
			season: signedYear,
			date: dayjs(`${signedYear}-01-02`).toDate(),
			totalDifference: 0
		});
		row?.player?.surplusValuePresentValueByseason?.forEach(
			(sv: IIntlProspectValueSurplusValuePresentValueByseason) => {
				if (
					sv.surplusValue !== null &&
					// TODO: Change this to the blended model when that value is available
					sv.predType === "pro_model_xsv" &&
					row.xsurplusMillions !== null &&
					dayjs(sv.date).year() >= signedYear &&
					dayjs(sv.date).isBefore(`${signedYear + 3}-01-02`)
				) {
					plotData.push({
						sv: sv.surplusValue,
						season: dayjs(sv.date).year(),
						date: new Date(sv.date),
						totalDifference: sv.surplusValue - row.xsurplusMillions
					});
				}
			}
		);
	}

	// Don't return any plot data if there is only one point
	if (plotData.length === 1) return undefined;

	plotData.sort((a: TSvData, b: TSvData) => {
		return dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1;
	});

	return plotData;
};

export const parseSimilarPlayersData = (
	similarPlayers: Array<IIntlProspectValuePlayer> | undefined
): Array<IIntlProspectValuePlayer> | undefined => {
	return similarPlayers?.map((playerData: IIntlProspectValuePlayer) => {
		const signedYear: number | undefined =
			playerData.player?.proProfile?.firstProContractDate != null
				? dayjs(playerData.player.proProfile.firstProContractDate).year()
				: undefined;
		const allSvData = getAllSvData(playerData, signedYear);
		return { parsedSignedYear: signedYear, parsedPlotData: allSvData, ...playerData };
	});
};

// Get earliest entry from a given season
export const getSurplusValueToDisplay = (allSvData: TSvData[] | null | undefined, season: number): TSvData | null => {
	if (!allSvData?.length) return null;

	const processedSurplusValues: TSvData[] = allSvData
		.filter((sv: TSvData) => {
			return sv.season === season;
		})
		.sort((a: TSvData, b: TSvData) => {
			return dayjs(a.date).isAfter(dayjs(b.date)) ? 1 : -1;
		});
	if (!processedSurplusValues.length) return null;
	return processedSurplusValues[0];
};

export const getPlotDataForPoints = (
	allPlotData: TSvData[] | undefined,
	signedYear: number | undefined
): TSvData[] | undefined => {
	if (signedYear === undefined) return undefined;

	const pointPlotData: Array<TSvData> = [];

	for (let i = 0; i < NUMBER_OF_SEASONS; i++) {
		const season: number = signedYear + i;
		const svToDisplay = getSurplusValueToDisplay(allPlotData, season);
		if (svToDisplay !== null) {
			pointPlotData.push(svToDisplay);
		}
	}

	return pointPlotData;
};

export const getXAxisLabels = (parsedPlotData: TSvData[] | undefined): Array<{ label: string; value: Date }> => {
	// Only compute xAxisLabels if there is at least 1 point in the plot data
	if (parsedPlotData === undefined || parsedPlotData.length <= 0) return [];

	const minSeason: TSvData = parsedPlotData[0];
	const maxSeason: TSvData | undefined =
		parsedPlotData.length > 1 ? parsedPlotData[parsedPlotData.length - 1] : undefined;

	return maxSeason
		? [
				{ label: `${minSeason.season}`, value: minSeason.date },
				{ label: `${maxSeason.season}`, value: maxSeason.date }
		  ]
		: [{ label: `${minSeason.season}`, value: minSeason.date }];
};
