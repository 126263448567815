import React from "react";
import { Box, VStack } from "@chakra-ui/react";

import { convertToDecimal } from "_react/shared/ui/data/tables/PitcherValueTable/_helpers";
import {
	NULL_FILLER_TEXT,
	NULL_FILLER_TEXT_COMPONENTS
} from "_react/shared/ui/data/tables/PitcherValueTable/_constants";

type TExpandableRaaProps = {
	totalRaa?: number | null;
	componentRaas?: Array<number | null | undefined>;
	isExpanded?: boolean;
};

const ExpandableRaa = ({ totalRaa, componentRaas, isExpanded }: TExpandableRaaProps) => {
	return (
		<VStack align="start" gap="0">
			<Box width="100%">{totalRaa != null ? convertToDecimal(totalRaa) : NULL_FILLER_TEXT}</Box>
			{totalRaa != null && isExpanded && (
				<Box width="100%" fontSize="xs" color="gray.600" fontFamily="body">
					{componentRaas?.map((componentRaa: number | null | undefined, index: number) => {
						const value =
							componentRaa != null ? convertToDecimal(componentRaa) : NULL_FILLER_TEXT_COMPONENTS;
						const spacer = index !== componentRaas.length - 1 ? " | " : "";
						return (
							<>
								{value}
								<Box as="span" color="gray.400">
									{spacer}
								</Box>
							</>
						);
					})}
				</Box>
			)}
		</VStack>
	);
};

export default ExpandableRaa;
