export const SP = "sp";
export const RP = "rp";
export type IRAAPitcherRole = typeof SP | typeof RP;

export interface IRAAPitcher {
	playerId: number;
	season: number;
	playingLevel: string;
	gameType: string;
	role: IRAAPitcherRole;
	tbf: number | null;
	pitchingRaa: number | null;
	rangeRaa: number | null;
	damageRaa: number | null;
	pitcherSbRaa: number | null;
	pickoffRaa: number | null;
	passedPitchRaa: number | null;
	totalRaa: number;
	totalRar: number | null;
}

export interface IRAAPitcherLkLevel {
	value: string;
	label: string | null;
	sortOrder: number | null;
}

export interface IRAAPitcherParentTeamBam {
	abbreviation: string | null;
}

export interface IRAAPitcherTeamBam {
	name: string | null;
	level: string | null;
	organization?: IRAAPitcherParentTeamBam;
	levelRel?: IRAAPitcherLkLevel;
}

export interface IRAAPositionPlayerTeam {
	id: number;
}

export interface IRAAPitcherByTeam extends IRAAPitcher {
	// Additional Primary Keys
	teamId: number;

	// Nested data
	teamBam?: IRAAPitcherTeamBam;
	team?: IRAAPositionPlayerTeam;
}

export interface IRAAPositionPlayer {
	playerId: number;
	season: number;
	playingLevel: string;
	gameType: string;
	pa: number | null;
	battingRaa: number | null;
	battingRaaVl: number | null;
	battingRaaVr: number | null;
	baserunningRaa: number | null;
	stolenBaseRaa: number | null;
	rangeRaa: number | null;
	receivingRaa: number | null;
	damageRaa: number | null;
	framingRaa: number | null;
	blockingRaa: number | null;
	sbThrowingRaa: number | null;
	posAdj: number | null;
	totalFieldingRaa: number | null;
	totalRaa: number;
	totalRar: number | null;
}

export interface IRAAPositionPlayerLkLevel {
	value: string;
	label: string | null;
	sortOrder: number | null;
}

export interface IRAAPositionPlayerParentTeamBam {
	abbreviation: string | null;
}

export interface IRAAPositionPlayerTeamBam {
	name: string | null;
	level: string | null;
	organization?: IRAAPositionPlayerParentTeamBam;
	levelRel?: IRAAPositionPlayerLkLevel;
}

export interface IRAAPositionPlayerByTeam extends IRAAPositionPlayer {
	// Additional Primary Keys
	teamId: number;

	// Nested data
	teamBam?: IRAAPositionPlayerTeamBam;
	team?: IRAAPositionPlayerTeam;
}
