import React, { useState, useEffect, useMemo } from "react";
import {
	Tabs,
	TabList,
	Tab,
	Flex,
	Box,
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	Stack,
	TabPanels,
	TabPanel,
	Tooltip,
	useMediaQuery
} from "@chakra-ui/react";

import { useBreakpointValue } from "_react/shared/_helpers/chakra";
import { MEDIA_QUERY } from "_react/shared/ui/presentation/layouts/TabsWithSidebar/_constants";
import { HEIGHT_BREAKPOINT_PX } from "_react/shared/ui/presentation/layouts/TabsWithSidebar/_constants";
import { TTab, TTabsWithSidebarStyle } from "_react/shared/ui/presentation/layouts/TabsWithSidebar/_types";
import TabsWithSidebarLoading from "_react/shared/ui/presentation/layouts/TabsWithSidebar/TabsWithSidebarLoading";
import TabContainer from "_react/shared/ui/presentation/layouts/TabsWithSidebar/TabContainer";
import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";

export type TabsWithSidebarProps = {
	tabsHeaderChildren: React.ReactNode;
	tabs: Array<TTab>;
	sidebarChildren: React.ReactNode;
	tabIndex?: number;
	handleSetTabIndex?: (index: number) => void;
	numberVisibleTabs?: number;
	isLoading?: boolean;
	style?: TTabsWithSidebarStyle;
};

const TabsWithSidebar = ({
	tabsHeaderChildren,
	tabs,
	sidebarChildren,
	tabIndex: tabIndexProp,
	handleSetTabIndex,
	numberVisibleTabs,
	isLoading = false,
	style
}: TabsWithSidebarProps) => {
	const [isLargerThanHeightBreakpoint] = useMediaQuery(`(min-height: ${HEIGHT_BREAKPOINT_PX}px)`);
	const [tabIndex, setTabIndex] = useState(tabIndexProp ?? 0);

	useEffect(() => {
		if (tabIndexProp !== undefined) setTabIndex(tabIndexProp);
	}, [tabIndexProp]);

	const handleTabsChange = (index: number) => {
		setTabIndex(index);
		if (handleSetTabIndex) handleSetTabIndex(index);
	};

	const numberVisibleTabsBreakpointValue =
		numberVisibleTabs ??
		useBreakpointValue({
			base: 1,
			md: 2,
			lg: 4,
			xl: 6,
			"2xl": 9
		});

	// All of the tabs, sometimes show a `More..` option based on the number of tabs and breakpoint
	const tabContent =
		numberVisibleTabsBreakpointValue != null ? (
			<>
				{tabs.slice(0, numberVisibleTabsBreakpointValue).map((tab: TTab) => (
					<Tab key={tab.label} whiteSpace="nowrap" fontSize="lg">
						{tab.label}
						{tab.tooltip !== undefined && (
							<Tooltip hasArrow placement="top" label={tab.tooltip} whiteSpace="pre-wrap">
								<Flex align="center" marginLeft="1">
									{tab.tooltipIcon ?? <OutlineInfo boxSize="4" />}
								</Flex>
							</Tooltip>
						)}
					</Tab>
				))}
				{tabs.length > numberVisibleTabsBreakpointValue && (
					<Menu>
						<MenuButton
							fontWeight="semibold"
							paddingTop="2"
							paddingBottom="2"
							paddingLeft="1px"
							paddingInlineEnd="4"
							fontSize="lg"
							sx={
								tabIndex >= numberVisibleTabsBreakpointValue
									? {
											color: "maroon",
											marginBottom: "-2px",
											borderBottom: "2px solid",
											borderColor: "currentColor"
									  }
									: {}
							}
						>
							More...
						</MenuButton>
						<MenuList zIndex={2}>
							{tabs.slice(numberVisibleTabsBreakpointValue).map((tab: TTab, index: number) => (
								<MenuItem
									key={tab.label}
									onClick={() => handleTabsChange(index + numberVisibleTabsBreakpointValue)}
									color={index === tabIndex - numberVisibleTabsBreakpointValue ? "maroon" : undefined}
									fontWeight="semibold"
								>
									{tab.label}
								</MenuItem>
							))}
						</MenuList>
					</Menu>
				)}
			</>
		) : (
			undefined
		);

	const stack = useMemo(
		() => (
			<Stack
				align="start"
				h="100%"
				direction={{ base: "column", md: "row" }}
				overflowY={{ md: "hidden" }}
				sx={{ [MEDIA_QUERY]: { display: "flex" } }}
			>
				<TabPanels
					paddingRight="12"
					paddingLeft="12"
					paddingTop={{ base: "12", md: "0" }}
					h={{ md: "100%" }}
					overflowY={{ md: "scroll" }}
				>
					{tabs.map((tab: TTab) => (
						<TabPanel key={tab.label}>
							<TabContainer>{tab.content}</TabContainer>
						</TabPanel>
					))}
				</TabPanels>
				<Box
					w="lg"
					h="100%"
					overflowY="scroll"
					bg="gray.50"
					marginInlineStart="0 !important"
					display={{ base: "none", md: "block" }}
				>
					{sidebarChildren}
				</Box>
			</Stack>
		),
		[tabs, sidebarChildren]
	);

	return (
		<>
			{isLoading && <TabsWithSidebarLoading />}
			{!isLoading && (
				<Tabs
					display="flex"
					flexDirection="column"
					h={style?.tabs?.height ? undefined : { md: "100%" }}
					sx={style?.tabs}
					index={tabIndex}
					onChange={handleTabsChange}
					isLazy
					lazyBehavior="keepMounted"
				>
					<Box w="100%" bg="gray.50" display={{ base: "block", md: "none" }}>
						{sidebarChildren}
					</Box>
					{tabsHeaderChildren && (
						<Flex justify="center" paddingTop="4" display={{ base: "flex", md: "none" }}>
							{tabsHeaderChildren}
						</Flex>
					)}
					<Flex
						justify="center"
						align="center"
						bg="white"
						borderBottom="2px solid"
						borderColor="gray.200"
						paddingTop={{ base: "4", md: "0" }}
						gap={{ md: "2" }}
					>
						<TabList
							position="relative"
							bottom="-2px"
							alignItems="end"
							width={{ md: "100%" }}
							paddingLeft={{ md: "12" }}
							paddingRight={{ md: "6" }}
							paddingTop={{ md: "6" }}
						>
							{tabContent}
						</TabList>
						{tabsHeaderChildren && (
							<Flex
								justify="end"
								height="100%"
								width="lg"
								backgroundColor="gray.50"
								display={{ base: "none", md: "flex" }}
							>
								{tabsHeaderChildren}
							</Flex>
						)}
					</Flex>
					{/* Does not freeze header on scroll */}
					{!isLargerThanHeightBreakpoint && <Box>{stack}</Box>}
					{/* Freezes the header on scroll */}
					{isLargerThanHeightBreakpoint && stack}
				</Tabs>
			)}
		</>
	);
};

export default TabsWithSidebar;
