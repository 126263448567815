import React, { CSSProperties } from "react";
import { DraggableProvided } from "react-beautiful-dnd";

import { useUserId } from "_react/app/AppContents";
import { ColorSchemeGroup, RED_SCHEME, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { Checkbox } from "_react/shared/legacy/ui/Checkbox";
import { IconButton } from "_react/shared/legacy/ui/IconButton";
import { Tooltip } from "_react/shared/legacy/ui/Tooltip";
import Check from "_react/shared/legacy/ui/icons/Check";
import Edit from "_react/shared/legacy/ui/icons/Edit";
import Close from "_react/shared/legacy/ui/icons/Close";
import PriorityHigh from "_react/shared/legacy/ui/icons/PriorityHigh";

import {
	APPROVE_GOAL_MODAL_TYPE,
	EDIT_GOAL_MODAL_TYPE,
	DECLINE_GOAL_MODAL_TYPE,
	DRAFT_MASKED_LABEL
} from "_react/playerplan/shared/_constants";
import {
	GoalContainer,
	GoalHeader,
	GoalSectionLeftDiv,
	GoalSectionRightDiv,
	MetricTypeDiv,
	CheckboxRightStyle,
	ActionButtonStyle,
	GreenColorScheme,
	GrayColorScheme,
	GoalSectionCenterDiv,
	TranslateRightTooltipStyle
} from "_react/playerplan/shared/_styles";
import { TPlayerPlanGoal } from "_react/playerplan/shared/_types";
import {
	createGoalStatement,
	getGoalSeasons,
	getGoalSeasonsStatement,
	getMostRecentActiveTarget
} from "_react/playerplan/shared/_helpers";

export type TPlayerPlanGoalDraftStyle = {
	checkbox?: CSSProperties;
};

export type TPlayerPlanGoalDraftProps = {
	goal: TPlayerPlanGoal;
	isGoalActionsDisabled?: boolean;
	isGroup1?: boolean;
	isGroup2?: boolean;
	updateGoal: (goal: TPlayerPlanGoal, updateApprovalDate?: boolean) => void;
	openModal: Function;
	isChildGoal?: boolean;
	provided?: DraggableProvided;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TPlayerPlanGoalDraftStyle;
};

export const PlayerPlanGoalDraft = ({
	goal,
	isGoalActionsDisabled = false,
	isGroup1 = false,
	isGroup2 = false,
	updateGoal,
	openModal,
	isChildGoal = false,
	provided,
	colorSchemeGroup = defaultColorScheme,
	style
}: TPlayerPlanGoalDraftProps) => {
	const userId = useUserId();
	// Group 1 can approve draft goals
	const isApprovable = isGroup1;
	// Group 1 and the goal's creator can decline draft goals
	const isDeclinable = isApprovable || goal.createUserId === userId;
	// Group 1, Group 2, and the goal's creator can edit draft goals
	const isEditable = isDeclinable || isGroup2;

	const activeTarget = getMostRecentActiveTarget(goal.playerPlanTargets);
	const goalStatement = createGoalStatement(
		goal.playerPlanMetric?.label,
		goal.playerPlanMetric?.metricSubtype?.label,
		goal.playerPlanSplits,
		activeTarget?.direction?.label,
		activeTarget?.value,
		goal.playerPlanMetric?.format
	);
	const goalSeasonsStatement = getGoalSeasonsStatement(getGoalSeasons(goal));

	return (
		<GoalContainer>
			<div ref={provided?.innerRef} {...provided?.draggableProps} {...provided?.dragHandleProps}>
				<GoalHeader isLowPriority={true} isForNextYear={false}>
					<GoalSectionLeftDiv>
						<Tooltip
							title="Priority Goal?"
							colorScheme={colorSchemeGroup.primary}
							style={TranslateRightTooltipStyle}
							placement={"right"}
						>
							<Checkbox
								style={{ ...CheckboxRightStyle, ...style?.checkbox }}
								checked={isChildGoal ? goal.isLead : goal.isPriority}
								disabled={isGoalActionsDisabled || !isEditable}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
									if (isChildGoal) updateGoal({ ...goal, isLead: Boolean(e) });
									else updateGoal({ ...goal, isPriority: Boolean(e) });
								}}
								colorScheme={colorSchemeGroup.tertiary}
							/>
						</Tooltip>

						<MetricTypeDiv>{goal.playerPlanMetric.metricType.abbreviation}</MetricTypeDiv>
						{!isChildGoal && goal.isPriority && (
							<Tooltip title="Priority Goal" colorScheme={colorSchemeGroup.primary} placement={"right"}>
								<PriorityHigh fill={colorSchemeGroup.secondary.color} />
							</Tooltip>
						)}
						{isChildGoal && goal.isLead && (
							<Tooltip
								title="Lead Supporting Goal"
								colorScheme={colorSchemeGroup.primary}
								placement={"right"}
							>
								<PriorityHigh fill={colorSchemeGroup.primary.color} />
							</Tooltip>
						)}
					</GoalSectionLeftDiv>
					<GoalSectionCenterDiv>{goalStatement}</GoalSectionCenterDiv>
					<GoalSectionRightDiv>
						<div>
							{goal.createUserId !== userId && (
								<div>{`(${goal.createUser?.firstName} ${goal.createUser?.lastName})`}</div>
							)}
							<div>
								{isApprovable ? `${goalSeasonsStatement} ${goal.status.label}` : DRAFT_MASKED_LABEL}
							</div>
						</div>
						{isApprovable && (
							<IconButton
								onClick={() => openModal(APPROVE_GOAL_MODAL_TYPE, goal)}
								name="approve-action"
								colorScheme={GreenColorScheme}
								style={ActionButtonStyle}
							>
								<Check fill="white" />
							</IconButton>
						)}
						{isEditable && (
							<IconButton
								onClick={() => openModal(EDIT_GOAL_MODAL_TYPE, goal)}
								name="edit-action"
								colorScheme={GrayColorScheme}
								style={ActionButtonStyle}
							>
								<Edit fill="white" />
							</IconButton>
						)}
						{isDeclinable && (
							<IconButton
								onClick={() => openModal(DECLINE_GOAL_MODAL_TYPE, goal)}
								name="decline-action"
								colorScheme={RED_SCHEME}
								style={ActionButtonStyle}
							>
								<Close fill="white" />
							</IconButton>
						)}
					</GoalSectionRightDiv>
				</GoalHeader>
			</div>
		</GoalContainer>
	);
};
