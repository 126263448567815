// SystemStyleObject objects

export const PitchTypeLabelContainerStyle = {
	width: "100%",
	justifyContent: "space-between",
	marginBottom: "7",
	paddingBottom: "1",
	borderBottom: "1px solid",
	borderColor: "gray.700"
};

export const PitchTypeLabelStyle = {
	container: {
		paddingBottom: "1"
	}
};
