import React, { createContext, useState, useCallback, useEffect, useMemo, useRef } from "react";
import { useSnackbar } from "notistack";

import { useDebounce } from "_react/_hooks";

import { VALIDATION_DICTIONARY } from "_react/shared/forms/_validation";
import {
	TFormValue,
	TDocumentBase,
	TFormPlugin,
	TFormSaveState,
	TFormValidationError,
	TSaveFunction,
	DEBOUNCE,
	INSTANT,
	TFormComponent,
	ENTIRE_FORM,
	TLkProps
} from "_react/shared/forms/_types";
import { ADD_VALIDATION_ERROR, REMOVE_VALIDATION_ERROR } from "_react/shared/forms/_machine";
import { updateValueUsingKey } from "_react/shared/forms/_helpers";

import { $TSFixMe } from "utils/tsutils";

/* Context */
interface IFormContext<T extends TDocumentBase, S = T> {
	save: Function;
	relaySaveFunction: (key: string, saveFunction: TSaveFunction<T>) => void;
	document: T;
	plugin: TFormPlugin<T, S>;
	validationErrors: TFormValidationError[];
	submitUpdate: (
		key: keyof T,
		value: TFormValue,
		document: T,
		component?: TFormComponent,
		batch?: boolean
	) => T | void;
	readOnly: boolean;
	lkProps: TLkProps;
}

const FormContext = createContext<IFormContext<TDocumentBase>>({
	save: () => Promise.resolve(),
	relaySaveFunction: (_key: string, _saveFunction: TSaveFunction<TDocumentBase>) => console.log(),
	document: {},
	plugin: {
		formId: "",
		saveOptions: {
			mode: "INSTANT"
		},
		customComponents: {},
		customComponentsIsSelectBased: {}
	},
	validationErrors: [],
	submitUpdate: (
		_key: keyof TDocumentBase,
		_value: TFormValue,
		_document: TDocumentBase,
		_component?: TFormComponent,
		_batch?: boolean
	) => console.log(),
	readOnly: false,
	lkProps: {}
});

/* Provider */
type TFormProviderProps<T extends TDocumentBase, S> = {
	children: $TSFixMe;
	plugin: TFormPlugin<T, S>;
	document: T;
	send: Function;
	validationErrors: TFormValidationError[];
	readOnly: boolean;
	// TODO: Remove
	onDocumentUpdate?: Function;
	savingState: TFormSaveState;
	setSavingState: Function;
	initialLkProps?: TLkProps;
};

const FormProvider = <T extends TDocumentBase, S = T>({
	children,
	plugin,
	document: documentProp,
	send,
	validationErrors,
	readOnly,
	onDocumentUpdate,
	savingState,
	setSavingState,
	initialLkProps
}: TFormProviderProps<T, S>) => {
	// Hooks
	const { enqueueSnackbar } = useSnackbar();

	// State
	const [saveFunctions, setSaveFunctions] = useState<{ [key: string]: TSaveFunction<T> }>({});
	const [document, setDocument] = useState<T>(documentProp);
	useEffect(() => {
		setDocument(documentProp);
	}, [documentProp]); // TODO: Need to eslint ignore/
	const [lkProps, setLkProps] = useState<TLkProps>(initialLkProps ?? {});

	/* Saving Logic */
	const saveDocument = useCallback(
		(newDocument: T) => {
			let formattedDocument: T | S = newDocument;
			if (plugin.formatForSaving != null) {
				formattedDocument = plugin.formatForSaving(newDocument);
			} else if (plugin.saveOptions.saveEntireDocument === false) {
				// TODO: Trim down to just identifying fields and changed fields (Need a way to track changed fields)
			}
			setSavingState({ ...savingState, saving: true });
			// TODO: Axios call to save the document
			// TODO: Remove this
			if (onDocumentUpdate != null) onDocumentUpdate(formattedDocument);
		},
		[plugin, setSavingState, savingState, onDocumentUpdate]
	);

	// Saving with debounce
	const debouncedDocument = useDebounce(document, plugin.saveOptions.timeout ?? 600, plugin.debouncingStateChanged);
	const manuallySaved = useRef(false);
	useEffect(() => {
		if (plugin.saveOptions.mode === DEBOUNCE && !manuallySaved.current) {
			saveDocument(debouncedDocument);
		} else if (manuallySaved.current) {
			manuallySaved.current = false;
		}
	}, [debouncedDocument, plugin.saveOptions.mode, manuallySaved, saveDocument]);

	/* Document Verification */

	// Function to process the document (processing field side effects on the document)
	const processDocument = (newDocument: T, key: keyof T, value: TFormValue) => {
		// Post-Process the update
		const processedDocument = plugin.processUpdate ? plugin.processUpdate(newDocument, key, value) : newDocument;

		return processedDocument;
	};

	// Function to validate the updated field and overall document
	const validateDocument = (newDocument: T, component?: TFormComponent, key?: keyof T, value?: TFormValue) => {
		// Validate field
		if (value && component) {
			// Only validate the field if there is input
			const fieldValidation = component.validation?.split(",") ?? [];
			for (let i = 0; i < fieldValidation.length; i++) {
				const validationRule = VALIDATION_DICTIONARY[fieldValidation[i]];
				if (validationRule && !validationRule.validate(value)) {
					// Validation Failure
					console.log("Validation Failure");
					console.log(validationRule.message);

					// Enqueue Snackbar
					/*enqueueSnackbar(`Error: ${component.label ?? "Document"} - ${validationRule.message}`, {
						variant: "error"
						});
						*/
					// Highlight Field
					send({ type: ADD_VALIDATION_ERROR, payload: { component, message: validationRule.message } });

					return false;
				}
			}
		}

		// Field Validation Successful, Remove
		if (component) send({ type: REMOVE_VALIDATION_ERROR, payload: component });

		// Validate document
		if (plugin.validate != null && component) {
			const validationResult = plugin.validate(newDocument, key, value);
			if (!validationResult.success) {
				// Snackbar
				for (let i = 0; i < validationResult.messages.length; i++) {
					enqueueSnackbar(`Error: ${component.label ?? "Document"} - ${validationResult.messages[i]}`, {
						variant: "error"
					});
					if (i === 0) {
						send({
							type: ADD_VALIDATION_ERROR,
							payload: { component: ENTIRE_FORM, message: validationResult.messages[i] }
						});
					}
				}
				return false;
			} else {
				send({ type: REMOVE_VALIDATION_ERROR, payload: ENTIRE_FORM });
			}
		}

		return true;
	};

	/* Initial LkParameter Update (mainly for static things referenced from the document) */
	const initialLkPropsRef = useRef({
		document,
		lkProps,
		setLkProps
	});
	useEffect(() => {
		if (plugin.updateLkProps)
			plugin.updateLkProps(
				initialLkPropsRef.current.document,
				initialLkPropsRef.current.lkProps,
				"initial",
				undefined,
				initialLkPropsRef.current.setLkProps
			);
	}, [initialLkPropsRef, plugin.updateLkProps, plugin]);

	/* Public Functions */

	// Callback to pass down to form components
	const submitUpdate = (key: keyof T, value: TFormValue, document: T, component?: TFormComponent, batch = false) => {
		// Update a value in the document
		let newDocument = updateValueUsingKey(key as string, document, value) as T;
		// let newDocument = { ...document, [keyUsed]: newValue };
		// Validate the document
		if (!validateDocument(newDocument, component, key, value)) return undefined;

		// Post-Process the update
		newDocument = processDocument(newDocument, key, value);

		// If this is a batch saving of all fields, return the document object (to prevent unnecessary re-renders)
		if (batch) return newDocument;

		// Only save if value changed
		if (document[key] === value) return undefined;

		// TODO: SEND saving state update
		// if (setSavingState) setSavingState({ ...savingState, unsavedChanges: true }); // Mark unsaved changes

		// Update LkProps
		if (plugin.updateLkProps) plugin.updateLkProps(newDocument, lkProps, key, value, setLkProps, component);

		// Save the document
		setDocument(newDocument);
		if (plugin.saveOptions.mode === INSTANT) saveDocument(newDocument);
		else setSavingState({ ...savingState, unsavedChanges: true });
		return undefined;
	};

	const save = () => {
		// First save all the fields (to ensure to grab the latest value from any debounce fields, etc.)
		const updatedDocument = Object.values(saveFunctions).reduce((newDocument: T | void, saveFunction) => {
			if (newDocument) saveFunction(newDocument);
			return newDocument;
		}, document);

		// Validation Error Occured
		if (updatedDocument == null) return;

		// Save the document
		saveDocument(updatedDocument);
		setDocument(updatedDocument);
		manuallySaved.current = true;
	};

	const relaySaveFunction = useCallback(
		(key: string, saveFunction: TSaveFunction<T>) => setSaveFunctions({ ...saveFunctions, [key]: saveFunction }),
		[setSaveFunctions, saveFunctions]
	);

	const Context = useMemo(() => (FormContext as unknown) as React.Context<IFormContext<T, S>>, []);
	// TODO: Save Function relayed must be a wrapper for submitUpdate with batch = true
	return (
		<Context.Provider
			value={{ save, relaySaveFunction, submitUpdate, validationErrors, readOnly, plugin, document, lkProps }}
		>
			{children}
		</Context.Provider>
	);
};

function useFormContext<T extends TDocumentBase, S = T>() {
	const context = React.useContext<IFormContext<T, S>>((FormContext as unknown) as React.Context<IFormContext<T, S>>);
	if (!context) {
		throw new Error("useFormContext must be used under FormProvider");
	}
	return context;
}

export { FormContext, FormProvider, useFormContext };
