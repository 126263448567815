import React, { useMemo, useState, useContext } from "react";
import { useSelector } from "@xstate/react";
import { Box, HStack } from "@chakra-ui/react";

import { PLAYER_TYPE_BATTER, PLAYER_TYPE_PITCHER } from "_react/shared/data_models/phred/_constants";
import { IAmaProspectValue } from "_react/shared/data_models/phred/_types";
import ToggleButton from "_react/shared/ui/presentation/components/ToggleButton/ToggleButton";
import Baseball from "_react/shared/ui/icons/Baseball";
import BaseballBat from "_react/shared/ui/icons/BaseballBat";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { TOGGLE_STYLE } from "_react/playerpage/_styles";
import { TPlayerPageAmaState } from "_react/playerpage/ama/_machine";
import AmaProspectValueSection from "_react/playerpage/ama/content/projections/AmaProspectValueSection";
import { PlayerPageAmaContext } from "_react/playerpage/ama/PlayerPageAmaProvider";
import { getHasTwoWayProjections, getProjectionsAmaSeason } from "_react/playerpage/ama/shared/_helpers";
import { BaseballBatIconStyle, BaseballIconStyle } from "_react/playerpage/ama/shared/_styles";

type TProjectionsTabProps = {
	player: TPlayerPageCombinedPlayer;
};

const ProjectionsTab = ({ player }: TProjectionsTabProps) => {
	const playerPageAmaContext = useContext(PlayerPageAmaContext);
	const amaProspectValues: Array<IAmaProspectValue> | undefined = useSelector(
		playerPageAmaContext.playerPageAmaService,
		(state: TPlayerPageAmaState) => state.context.amaProspectValues
	);

	const hasTwoWayProjections = useMemo(() => getHasTwoWayProjections(amaProspectValues, player), [
		player,
		amaProspectValues
	]);

	const [playerType, setPlayerType] = useState(player.isPitcher === 1 ? PLAYER_TYPE_PITCHER : PLAYER_TYPE_BATTER);

	const setPlayerTypeToggle = (playerType: string | number) => {
		if (typeof playerType !== "string") return;
		setPlayerType(playerType);
	};

	return (
		<Box position="relative" top={hasTwoWayProjections ? "-8" : undefined}>
			{hasTwoWayProjections && (
				<HStack
					top={{ base: undefined, md: "4px" }}
					position={{ base: undefined, md: "sticky" }}
					justifyContent={{ base: "start", md: "end" }}
					gap={{ base: "2", sm: "5" }}
					// This is in place due to issues we've seen with UI libraries overlaying this floating button (Highcharts). Ok to remove if necessary, as long as you can confirm content isn't above this (unless it is designed to be)
					zIndex={1}
				>
					<ToggleButton
						toggleOptions={[
							{
								value: PLAYER_TYPE_BATTER,
								label: "Position Player",
								icon: <BaseballBat sx={BaseballBatIconStyle} />
							},
							{
								value: PLAYER_TYPE_PITCHER,
								label: "Pitcher",
								icon: <Baseball sx={BaseballIconStyle} />
							}
						]}
						value={playerType}
						onSelect={setPlayerTypeToggle}
						style={TOGGLE_STYLE}
					/>
				</HStack>
			)}
			<Box paddingTop={{ base: hasTwoWayProjections ? "12" : undefined, lg: undefined }}>
				<AmaProspectValueSection
					player={player}
					playerType={playerType}
					year={getProjectionsAmaSeason(player)}
				/>
			</Box>
		</Box>
	);
};

export default ProjectionsTab;
