import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import {
	fetchArsenalScoresThreshold,
	fetchPlayerSeasonArsenalScores
} from "_react/shared/data_models/arsenal_scores/_network";
import { fetchTwentyEightyGradeDistribution } from "_react/shared/data_models/dataviz/_network";
import { fetchPlayerMetricProbabilityDensities } from "_react/shared/data_models/metric/_network";
import { ITwentyEightyGradeDistribution } from "_react/shared/data_models/dataviz/_types";
import { IMetricProbabilityDensities } from "_react/shared/data_models/metric/_types";
import { fetchPlayer } from "_react/shared/data_models/player/_network";
import { TPlayingLevel } from "_react/shared/data_models/hitter_grades/_types";
import { getCancelSource } from "utils/url_helpers";
import {
	IArsenalScoresThresholdApiResponse,
	IPlayerSeasonArsenalScoresSchema
} from "_react/shared/data_models/arsenal_scores/_types";
import { GAME_TYPE_OVERALL, COUNT_SPLIT_OVERALL } from "_react/shared/data_models/arsenal_scores/_constants";

import { TArsenalStuffDistributionsPlayer } from "_react/shared/ui/data/plots/ArsenalStuffDistributions/_types";
import {
	TArsenalStuffDistributionsPrimaryData,
	TArsenalStuffDistributionsSecondaryData,
	TArsenalStuffDistributionsTertiaryData
} from "_react/shared/ui/data/plots/ArsenalStuffDistributions/ArsenalStuffDistributions";
import { BATS_OVERALL } from "_react/shared/data_models/seasonal_grades/_constants";

const PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE = "playerSeasonArsenalScores";
const TWENTY_EIGHTY_GRADE_DISTRIBUTION_CANCEL_SOURCE = "twentyEightyGradeDistribution";
const ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE = "arsenalScoresThreshold";
const ARSENAL_STUFF_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE = "arsenalStuffDistributionsPlayer";
const STUFF_PROBABILITY_DENSITIES_CANCEL_SOURCE = "stuffProbabilityDensities";

export type TArsenalStuffDistributionsCancelSource = {
	[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE]?: CancelTokenSource;
	[TWENTY_EIGHTY_GRADE_DISTRIBUTION_CANCEL_SOURCE]?: CancelTokenSource;
	[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE]?: CancelTokenSource;
	[ARSENAL_STUFF_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE]?: CancelTokenSource;
	[STUFF_PROBABILITY_DENSITIES_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TProbabilityDensities = Record<string, Array<IMetricProbabilityDensities> | null>;

export type TArsenalStuffDistributionsContext = {
	playerId?: number;
	lastPlayerId?: number;
	playingLevel?: TPlayingLevel;
	lastPlayingLevel?: TPlayingLevel;
	seasonFilter: number;
	lastSeasonFilter: number;
	batsFilter: string;
	lastBatsFilter: string;
	throwsFilter?: string;
	lastThrowsFilter?: string;
	shouldFetchPrimaryData?: boolean;
	shouldFetchSecondaryData?: boolean;
	shouldFetchTertiaryData?: boolean;
	playerSeasonArsenalScores?: Array<IPlayerSeasonArsenalScoresSchema> | null;
	twentyEightyGradeDistribution?: Array<ITwentyEightyGradeDistribution> | null;
	arsenalScoresThreshold?: Array<IArsenalScoresThresholdApiResponse> | null;
	arsenalStuffDistributionsPlayer?: TArsenalStuffDistributionsPlayer;
	stuffProbabilityDensities: TProbabilityDensities;
	cancelSources: TArsenalStuffDistributionsCancelSource;
	toast?: CreateToastFnReturn;
};

interface IArsenalStuffDistributionsStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId prop changes
				playerIdRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches all player season arsenal scores data
				playerSeasonArsenalScores: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all twenty eighty grade distribution data
				twentyEightyGradeDistribution: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches arsenal scores thresholds data
				arsenalScoresThreshold: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches additional player data
				arsenalStuffDistributionsPlayer: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches stuff probability densities for the player
				stuffProbabilityDensities: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_PLAYER_SEASON_ARSENAL_SCORES = "SET_PLAYER_SEASON_ARSENAL_SCORES";
export const SET_TWENTY_EIGHTY_GRADE_DISTRIBUTION = "SET_TWENTY_EIGHTY_GRADE_DISTRIBUTION";
export const SET_ARSENAL_SCORES_THRESHOLD = "SET_ARSENAL_SCORES_THRESHOLD";
export const SET_ARSENAL_STUFF_DISTRIBUTIONS_PLAYER = "SET_ARSENAL_STUFF_DISTRIBUTIONS_PLAYER";
export const SET_STUFF_PROBABILITY_DENSITIES = "SET_STUFF_PROBABILITY_DENSITIES";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PLAYING_LEVEL = "SET_PLAYING_LEVEL";
export const SET_SEASON_FILTER = "SET_SEASON_FILTER";
export const SET_BATS_FILTER = "SET_BATS_FILTER";
export const SET_THROWS_FILTER = "SET_THROWS_FILTER";

export const FETCHING_PLAYER_SEASON_ARSENAL_SCORES = { initialized: { playerSeasonArsenalScores: "fetching" } };
export const FETCHING_TWENTY_EIGHTY_GRADE_DISTRIBUTION = { initialized: { twentyEightyGradeDistribution: "fetching" } };
export const FETCHING_ARSENAL_SCORES_THRESHOLD = { initialized: { arsenalScoresThreshold: "fetching" } };
export const FETCHING_ARSENAL_STUFF_DISTRIBUTIONS_PLAYER = {
	initialized: { arsenalStuffDistributionsPlayer: "fetching" }
};
export const FETCHING_STUFF_PROBABILITY_DENSITIES = { initialized: { stuffProbabilityDensities: "fetching" } };

const FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE = "done.invoke.fetchingPlayerSeasonArsenalScores:invocation[0]";
const FETCH_TWENTY_EIGHTY_GRADE_DISTRIBUTION_DONE = "done.invoke.fetchingTwentyEightyGradeDistribution:invocation[0]";
const FETCH_ARSENAL_SCORES_THRESHOLD_DONE = "done.invoke.fetchingArsenalScoresThreshold:invocation[0]";
const FETCH_ARSENAL_STUFF_DISTRIBUTIONS_PLAYER_DONE =
	"done.invoke.fetchingArsenalStuffDistributionsPlayer:invocation[0]";
const FETCH_STUFF_PROBABILITY_DENSITIES_DONE = "done.invoke.fetchingStuffProbabilityDensities:invocation[0]";

const FETCH_PLAYER_SEASON_ARSENAL_SCORES_ERROR = "error.platform.fetchingPlayerSeasonArsenalScores:invocation[0]";
const FETCH_TWENTY_EIGHTY_GRADE_DISTRIBUTION_ERROR =
	"error.platform.fetchingTwentyEightyGradeDistribution:invocation[0]";
const FETCH_ARSENAL_SCORES_THRESHOLD_ERROR = "error.platform.fetchingArsenalScoresThreshold:invocation[0]";
const FETCH_ARSENAL_STUFF_DISTRIBUTIONS_PLAYER_ERROR =
	"error.platform.fetchingArsenalStuffDistributionsPlayer:invocation[0]";
const FETCH_STUFF_PROBABILITY_DENSITIES_ERROR = "error.platform.fetchingStuffProbabilityDensities:invocation[0]";

type TPlayerSeasonArsenalScoresEvent = {
	type: typeof SET_PLAYER_SEASON_ARSENAL_SCORES;
	data: Array<IPlayerSeasonArsenalScoresSchema> | null | undefined;
};
type TSetTwentyEightyGradeDistributionEvent = {
	type: typeof SET_TWENTY_EIGHTY_GRADE_DISTRIBUTION;
	data: Array<ITwentyEightyGradeDistribution> | null | undefined;
};
type TArsenalScoresThresholdEvent = {
	type: typeof SET_ARSENAL_SCORES_THRESHOLD;
	data: Array<IArsenalScoresThresholdApiResponse> | null | undefined;
};
type TSetStuffProbabilityDensitiesEvent = {
	type: typeof SET_STUFF_PROBABILITY_DENSITIES;
	data: TProbabilityDensities;
};
type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number | undefined;
};
type TSetPlayingLevelEvent = {
	type: typeof SET_PLAYING_LEVEL;
	data: TPlayingLevel | undefined;
};
type TSetSeasonFilterEvent = {
	type: typeof SET_SEASON_FILTER;
	data: number;
};
type TSetBatsFilterEvent = {
	type: typeof SET_BATS_FILTER;
	data: string;
};
type TSetThrowsFilterEvent = {
	type: typeof SET_THROWS_FILTER;
	data: string | undefined;
};
type TArsenalStuffDistributionsPlayerEvent = {
	type: typeof SET_ARSENAL_STUFF_DISTRIBUTIONS_PLAYER;
	data: TArsenalStuffDistributionsPlayer | undefined;
};
type TFetchPlayerSeasonArsenalScoresEvent = {
	type: typeof FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE;
	data?: Array<IPlayerSeasonArsenalScoresSchema>;
};
type TFetchTwentyEightyGradeDistributionEvent = {
	type: typeof FETCH_TWENTY_EIGHTY_GRADE_DISTRIBUTION_DONE;
	data: Array<ITwentyEightyGradeDistribution> | undefined;
};
type TFetchArsenalScoresThresholdEvent = {
	type: typeof FETCH_ARSENAL_SCORES_THRESHOLD_DONE;
	data?: Array<IArsenalScoresThresholdApiResponse>;
};
type TFetchArsenalStuffDistributionsPlayerEvent = {
	type: typeof FETCH_ARSENAL_STUFF_DISTRIBUTIONS_PLAYER_DONE;
	data?: TArsenalStuffDistributionsPlayer;
};
type TFetchStuffProbabilityDensitiesEvent = {
	type: typeof FETCH_STUFF_PROBABILITY_DENSITIES_DONE;
	data?: Array<IMetricProbabilityDensities>;
};
type TFetchPlayerSeasonArsenalScoresErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_ARSENAL_SCORES_ERROR;
	data?: AxiosError | Error;
};
type TFetchTwentyEightyGradeDistributionErrorEvent = {
	type: typeof FETCH_TWENTY_EIGHTY_GRADE_DISTRIBUTION_ERROR;
	data?: AxiosError | Error;
};
type TFetchArsenalScoresThresholdErrorEvent = {
	type: typeof FETCH_ARSENAL_SCORES_THRESHOLD_ERROR;
	data?: AxiosError | Error;
};
type TFetchArsenalStuffDistributionsPlayerErrorEvent = {
	type: typeof FETCH_ARSENAL_STUFF_DISTRIBUTIONS_PLAYER_ERROR;
	data?: AxiosError | Error;
};
type TFetchStuffProbabilityDensitiesErrorEvent = {
	type: typeof FETCH_STUFF_PROBABILITY_DENSITIES_ERROR;
	data?: AxiosError | Error;
};

type TArsenalStuffDistributionsEvent =
	| TPlayerSeasonArsenalScoresEvent
	| TSetTwentyEightyGradeDistributionEvent
	| TArsenalScoresThresholdEvent
	| TSetStuffProbabilityDensitiesEvent
	| TSetPlayerIdEvent
	| TSetPlayingLevelEvent
	| TSetSeasonFilterEvent
	| TSetBatsFilterEvent
	| TSetThrowsFilterEvent
	| TArsenalStuffDistributionsPlayerEvent
	| TFetchPlayerSeasonArsenalScoresEvent
	| TFetchTwentyEightyGradeDistributionEvent
	| TFetchArsenalScoresThresholdEvent
	| TFetchArsenalStuffDistributionsPlayerEvent
	| TFetchStuffProbabilityDensitiesEvent
	| TFetchPlayerSeasonArsenalScoresErrorEvent
	| TFetchTwentyEightyGradeDistributionErrorEvent
	| TFetchArsenalScoresThresholdErrorEvent
	| TFetchArsenalStuffDistributionsPlayerErrorEvent
	| TFetchStuffProbabilityDensitiesErrorEvent;

export type TArsenalStuffDistributionsSend = Interpreter<
	TArsenalStuffDistributionsContext,
	IArsenalStuffDistributionsStateSchema,
	TArsenalStuffDistributionsEvent
>["send"];

const ArsenalStuffDistributionsMachine = (
	seasonFilterProp: number,
	batsFilterProp: string,
	throwsFilterProp?: string,
	playerIdProp?: number,
	playingLevelProp?: TPlayingLevel,
	shouldFetchPrimaryData = true,
	primaryData?: TArsenalStuffDistributionsPrimaryData,
	shouldFetchSecondaryData = true,
	secondaryData?: TArsenalStuffDistributionsSecondaryData,
	shouldFetchTertiaryData = true,
	tertiaryData?: TArsenalStuffDistributionsTertiaryData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TArsenalStuffDistributionsContext, IArsenalStuffDistributionsStateSchema, TArsenalStuffDistributionsEvent>(
		{
			id: "arsenalStuffDistributions",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				playingLevel: playingLevelProp,
				lastPlayingLevel: playingLevelProp,
				seasonFilter: seasonFilterProp,
				lastSeasonFilter: seasonFilterProp,
				batsFilter: batsFilterProp,
				lastBatsFilter: batsFilterProp,
				throwsFilter: throwsFilterProp,
				lastThrowsFilter: throwsFilterProp,
				shouldFetchPrimaryData: shouldFetchPrimaryData,
				shouldFetchSecondaryData: shouldFetchSecondaryData,
				shouldFetchTertiaryData: shouldFetchTertiaryData,
				playerSeasonArsenalScores: primaryData?.playerSeasonArsenalScores,
				twentyEightyGradeDistribution: primaryData?.twentyEightyGradeDistribution,
				arsenalScoresThreshold: secondaryData?.arsenalScoresThreshold,
				stuffProbabilityDensities: tertiaryData?.stuffProbabilityDensities ?? {},
				arsenalStuffDistributionsPlayer: secondaryData?.arsenalStuffDistributionsPlayer,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PLAYER_SEASON_ARSENAL_SCORES]: { actions: "setPlayerSeasonArsenalScores" },
						[SET_TWENTY_EIGHTY_GRADE_DISTRIBUTION]: { actions: "setTwentyEightyGradeDistribution" },
						[SET_ARSENAL_SCORES_THRESHOLD]: { actions: "setArsenalScoresThreshold" },
						[SET_STUFF_PROBABILITY_DENSITIES]: { actions: "setStuffProbabilityDensities" },
						[SET_PLAYER_ID]: { actions: "setPlayerId" },
						[SET_PLAYING_LEVEL]: { actions: "setPlayingLevel" },
						[SET_SEASON_FILTER]: { actions: "setSeasonFilter" },
						[SET_BATS_FILTER]: { actions: "setBatsFilter" },
						[SET_THROWS_FILTER]: { actions: "setThrowsFilter" },
						[SET_ARSENAL_STUFF_DISTRIBUTIONS_PLAYER]: { actions: "setArsenalStuffDistributionsPlayer" }
					},
					states: {
						playerIdRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						playerSeasonArsenalScores: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingPlayerSeasonArsenalScores",
												cond: "shouldFetchPlayerSeasonArsenalScores"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingPlayerSeasonArsenalScores",
									entry: ["refreshPlayerSeasonArsenalScoresCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonArsenalScores",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonArsenalScoresSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonArsenalScoresErrored"
										}
									}
								}
							}
						},
						twentyEightyGradeDistribution: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingTwentyEightyGradeDistribution",
												cond: "shouldFetchTwentyEightyGradeDistribution"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingTwentyEightyGradeDistribution",
									entry: ["refreshTwentyEightyGradeDistributionCancelSource"],
									invoke: {
										src: "fetchTwentyEightyGradeDistribution",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchTwentyEightyGradeDistributionSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchTwentyEightyGradeDistributionErrored"
										}
									}
								}
							}
						},
						arsenalScoresThreshold: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingArsenalScoresThreshold",
												cond: "shouldFetchArsenalScoresThreshold"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingArsenalScoresThreshold",
									entry: ["refreshArsenalScoresThresholdCancelSource"],
									invoke: {
										src: "fetchArsenalScoresThreshold",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchArsenalScoresThresholdSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchArsenalScoresThresholdErrored"
										}
									}
								}
							}
						},
						arsenalStuffDistributionsPlayer: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingArsenalStuffDistributionsPlayer",
												cond: "shouldFetchArsenalStuffDistributionsPlayer"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingArsenalStuffDistributionsPlayer",
									entry: ["refreshArsenalStuffDistributionsPlayerCancelSource"],
									invoke: {
										src: "fetchArsenalStuffDistributionsPlayer",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchArsenalStuffDistributionsPlayerSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchArsenalStuffDistributionsPlayerErrored"
										}
									}
								}
							}
						},
						stuffProbabilityDensities: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingStuffProbabilityDensities",
												cond: "shouldFetchStuffProbabilityDensities"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingStuffProbabilityDensities",
									entry: ["refreshStuffProbabilityDensitiesCancelSource"],
									invoke: {
										src: "fetchStuffProbabilityDensities",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchStuffProbabilityDensitiesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchStuffProbabilityDensitiesErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (
					context: TArsenalStuffDistributionsContext,
					_event: TArsenalStuffDistributionsEvent
				) =>
					context.playerId !== context.lastPlayerId ||
					context.playingLevel !== context.lastPlayingLevel ||
					context.seasonFilter !== context.lastSeasonFilter,
				shouldFetchPlayerSeasonArsenalScores: (
					context: TArsenalStuffDistributionsContext,
					_event: TArsenalStuffDistributionsEvent
				) =>
					context.playerSeasonArsenalScores === undefined &&
					shouldFetchPrimaryData &&
					context.playerId !== undefined,
				shouldFetchTwentyEightyGradeDistribution: (
					context: TArsenalStuffDistributionsContext,
					_event: TArsenalStuffDistributionsEvent
				) =>
					context.twentyEightyGradeDistribution === undefined &&
					shouldFetchPrimaryData &&
					context.playerId !== undefined,
				shouldFetchArsenalScoresThreshold: (
					context: TArsenalStuffDistributionsContext,
					_event: TArsenalStuffDistributionsEvent
				) => context.arsenalScoresThreshold === undefined && shouldFetchSecondaryData,
				shouldFetchArsenalStuffDistributionsPlayer: (
					context: TArsenalStuffDistributionsContext,
					_event: TArsenalStuffDistributionsEvent
				) =>
					context.arsenalStuffDistributionsPlayer === undefined &&
					shouldFetchSecondaryData &&
					context.playerId !== undefined,
				shouldFetchStuffProbabilityDensities: (
					context: TArsenalStuffDistributionsContext,
					_event: TArsenalStuffDistributionsEvent
				) =>
					context.stuffProbabilityDensities[`${context.batsFilter}-${context.throwsFilter}`] === undefined &&
					shouldFetchTertiaryData
			},
			actions: {
				setPlayerSeasonArsenalScores: assign<
					TArsenalStuffDistributionsContext,
					TArsenalStuffDistributionsEvent
				>({
					playerSeasonArsenalScores: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES) return context.playerSeasonArsenalScores;
						return event.data;
					},
					cancelSources: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES) return context.cancelSources;
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setTwentyEightyGradeDistribution: assign<
					TArsenalStuffDistributionsContext,
					TArsenalStuffDistributionsEvent
				>({
					twentyEightyGradeDistribution: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== SET_TWENTY_EIGHTY_GRADE_DISTRIBUTION)
							return context.twentyEightyGradeDistribution;
						return event.data;
					},
					cancelSources: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== SET_TWENTY_EIGHTY_GRADE_DISTRIBUTION) return context.cancelSources;
						if (context.cancelSources[TWENTY_EIGHTY_GRADE_DISTRIBUTION_CANCEL_SOURCE] != null)
							context.cancelSources[TWENTY_EIGHTY_GRADE_DISTRIBUTION_CANCEL_SOURCE].cancel();
						delete context.cancelSources[TWENTY_EIGHTY_GRADE_DISTRIBUTION_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setArsenalScoresThreshold: assign<TArsenalStuffDistributionsContext, TArsenalStuffDistributionsEvent>({
					arsenalScoresThreshold: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== SET_ARSENAL_SCORES_THRESHOLD) return context.arsenalScoresThreshold;
						return event.data;
					},
					cancelSources: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== SET_ARSENAL_SCORES_THRESHOLD) return context.cancelSources;
						if (context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE].cancel();
						delete context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setStuffProbabilityDensities: assign<
					TArsenalStuffDistributionsContext,
					TArsenalStuffDistributionsEvent
				>({
					stuffProbabilityDensities: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== SET_STUFF_PROBABILITY_DENSITIES) return context.stuffProbabilityDensities;
						return event.data;
					},
					cancelSources: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== SET_STUFF_PROBABILITY_DENSITIES) return context.cancelSources;
						if (context.cancelSources[STUFF_PROBABILITY_DENSITIES_CANCEL_SOURCE] != null)
							context.cancelSources[STUFF_PROBABILITY_DENSITIES_CANCEL_SOURCE].cancel();
						delete context.cancelSources[STUFF_PROBABILITY_DENSITIES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),

				setPlayerId: assign<TArsenalStuffDistributionsContext, TArsenalStuffDistributionsEvent>({
					playerId: (context: TArsenalStuffDistributionsContext, event: TArsenalStuffDistributionsEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				setPlayingLevel: assign<TArsenalStuffDistributionsContext, TArsenalStuffDistributionsEvent>({
					playingLevel: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== SET_PLAYING_LEVEL) return context.playingLevel;
						return event.data;
					}
				}),
				setSeasonFilter: assign<TArsenalStuffDistributionsContext, TArsenalStuffDistributionsEvent>({
					seasonFilter: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== SET_SEASON_FILTER) return context.seasonFilter;
						return event.data;
					}
				}),
				setBatsFilter: assign<TArsenalStuffDistributionsContext, TArsenalStuffDistributionsEvent>({
					batsFilter: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== SET_BATS_FILTER) return context.batsFilter;
						return event.data;
					}
				}),
				setThrowsFilter: assign<TArsenalStuffDistributionsContext, TArsenalStuffDistributionsEvent>({
					throwsFilter: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== SET_THROWS_FILTER) return context.throwsFilter;
						return event.data;
					}
				}),
				setArsenalStuffDistributionsPlayer: assign<
					TArsenalStuffDistributionsContext,
					TArsenalStuffDistributionsEvent
				>({
					arsenalStuffDistributionsPlayer: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== SET_ARSENAL_STUFF_DISTRIBUTIONS_PLAYER)
							return context.arsenalStuffDistributionsPlayer;
						return event.data;
					},
					cancelSources: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== SET_ARSENAL_STUFF_DISTRIBUTIONS_PLAYER) return context.cancelSources;
						if (context.cancelSources[ARSENAL_STUFF_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_STUFF_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE].cancel();
						delete context.cancelSources[ARSENAL_STUFF_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				clearContext: assign<TArsenalStuffDistributionsContext, TArsenalStuffDistributionsEvent>({
					lastPlayerId: (
						context: TArsenalStuffDistributionsContext,
						_event: TArsenalStuffDistributionsEvent
					) => context.playerId,
					lastSeasonFilter: (
						context: TArsenalStuffDistributionsContext,
						_event: TArsenalStuffDistributionsEvent
					) => context.seasonFilter,
					lastBatsFilter: (
						context: TArsenalStuffDistributionsContext,
						_event: TArsenalStuffDistributionsEvent
					) => context.batsFilter,
					playerSeasonArsenalScores: (
						_context: TArsenalStuffDistributionsContext,
						_event: TArsenalStuffDistributionsEvent
					) => undefined,
					arsenalStuffDistributionsPlayer: (
						_context: TArsenalStuffDistributionsContext,
						_event: TArsenalStuffDistributionsEvent
					) => undefined,
					stuffProbabilityDensities: (
						_context: TArsenalStuffDistributionsContext,
						_event: TArsenalStuffDistributionsEvent
					) => {
						return {} as TProbabilityDensities;
					},

					cancelSources: (
						context: TArsenalStuffDistributionsContext,
						_event: TArsenalStuffDistributionsEvent
					) => {
						Object.values(context.cancelSources).forEach((tokenSource: CancelTokenSource) =>
							tokenSource.cancel()
						);
						return {};
					}
				}),
				// Cancel Source Actions
				refreshPlayerSeasonArsenalScoresCancelSource: assign<
					TArsenalStuffDistributionsContext,
					TArsenalStuffDistributionsEvent
				>({
					cancelSources: (
						context: TArsenalStuffDistributionsContext,
						_event: TArsenalStuffDistributionsEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshTwentyEightyGradeDistributionCancelSource: assign<
					TArsenalStuffDistributionsContext,
					TArsenalStuffDistributionsEvent
				>({
					cancelSources: (
						context: TArsenalStuffDistributionsContext,
						_event: TArsenalStuffDistributionsEvent
					) => {
						if (context.cancelSources[TWENTY_EIGHTY_GRADE_DISTRIBUTION_CANCEL_SOURCE] != null)
							context.cancelSources[TWENTY_EIGHTY_GRADE_DISTRIBUTION_CANCEL_SOURCE].cancel();
						context.cancelSources[TWENTY_EIGHTY_GRADE_DISTRIBUTION_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshArsenalScoresThresholdCancelSource: assign<
					TArsenalStuffDistributionsContext,
					TArsenalStuffDistributionsEvent
				>({
					cancelSources: (
						context: TArsenalStuffDistributionsContext,
						_event: TArsenalStuffDistributionsEvent
					) => {
						if (context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE].cancel();
						context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshArsenalStuffDistributionsPlayerCancelSource: assign<
					TArsenalStuffDistributionsContext,
					TArsenalStuffDistributionsEvent
				>({
					cancelSources: (
						context: TArsenalStuffDistributionsContext,
						_event: TArsenalStuffDistributionsEvent
					) => {
						if (context.cancelSources[ARSENAL_STUFF_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_STUFF_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE].cancel();
						context.cancelSources[ARSENAL_STUFF_DISTRIBUTIONS_PLAYER_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshStuffProbabilityDensitiesCancelSource: assign<
					TArsenalStuffDistributionsContext,
					TArsenalStuffDistributionsEvent
				>({
					cancelSources: (
						context: TArsenalStuffDistributionsContext,
						_event: TArsenalStuffDistributionsEvent
					) => {
						if (context.cancelSources[STUFF_PROBABILITY_DENSITIES_CANCEL_SOURCE] != null)
							context.cancelSources[STUFF_PROBABILITY_DENSITIES_CANCEL_SOURCE].cancel();
						context.cancelSources[STUFF_PROBABILITY_DENSITIES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),

				// Fetch Success Actions
				handleFetchPlayerSeasonArsenalScoresSuccess: assign<
					TArsenalStuffDistributionsContext,
					TArsenalStuffDistributionsEvent
				>({
					playerSeasonArsenalScores: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE)
							return context.playerSeasonArsenalScores;
						return event.data;
					}
				}),
				handleFetchTwentyEightyGradeDistributionSuccess: assign<
					TArsenalStuffDistributionsContext,
					TArsenalStuffDistributionsEvent
				>({
					twentyEightyGradeDistribution: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== FETCH_TWENTY_EIGHTY_GRADE_DISTRIBUTION_DONE)
							return context.twentyEightyGradeDistribution;
						return event.data;
					}
				}),
				handleFetchArsenalScoresThresholdSuccess: assign<
					TArsenalStuffDistributionsContext,
					TArsenalStuffDistributionsEvent
				>({
					arsenalScoresThreshold: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== FETCH_ARSENAL_SCORES_THRESHOLD_DONE) return context.arsenalScoresThreshold;
						return event.data;
					}
				}),
				handleFetchStuffProbabilityDensitiesSuccess: assign<
					TArsenalStuffDistributionsContext,
					TArsenalStuffDistributionsEvent
				>({
					stuffProbabilityDensities: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== FETCH_STUFF_PROBABILITY_DENSITIES_DONE || event.data === undefined)
							return context.stuffProbabilityDensities;
						// Use the bats filter of the returned data to make sure we have the correct data
						// otherwise default to the context's bats filter
						const bats = event.data?.length
							? event.data[0].requestArgs.bats ?? BATS_OVERALL
							: context.batsFilter;
						// Repeat the process with the throws filter
						const throws = event.data?.length
							? event.data[0].requestArgs.throws ?? context.throwsFilter
							: context.throwsFilter;
						return {
							...context.stuffProbabilityDensities,
							[`${bats}-${throws}`]: event.data
						};
					}
				}),
				handleFetchArsenalStuffDistributionsPlayerSuccess: assign<
					TArsenalStuffDistributionsContext,
					TArsenalStuffDistributionsEvent
				>({
					arsenalStuffDistributionsPlayer: (
						context: TArsenalStuffDistributionsContext,
						event: TArsenalStuffDistributionsEvent
					) => {
						if (event.type !== FETCH_ARSENAL_STUFF_DISTRIBUTIONS_PLAYER_DONE)
							return context.arsenalStuffDistributionsPlayer;
						return event.data;
					}
				}),

				// Fetch Errored Actions
				handleFetchPlayerSeasonArsenalScoresErrored: (
					context: TArsenalStuffDistributionsContext,
					event: TArsenalStuffDistributionsEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_ARSENAL_SCORES_ERROR ? event.data : undefined,
						context.toast,
						"Seasonal Arsenal Scores Overall",
						"Error fetching seasonal arsenal scores overall data."
					);
				},
				handleFetchTwentyEightyGradeDistributionErrored: (
					context: TArsenalStuffDistributionsContext,
					event: TArsenalStuffDistributionsEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_TWENTY_EIGHTY_GRADE_DISTRIBUTION_ERROR ? event.data : undefined,
						context.toast,
						"Twenty Eighty Grade Distribution",
						"Error fetching twenty eighty grade distribution data."
					);
				},
				handleFetchArsenalScoresThresholdErrored: (
					context: TArsenalStuffDistributionsContext,
					event: TArsenalStuffDistributionsEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_ARSENAL_SCORES_THRESHOLD_ERROR ? event.data : undefined,
						context.toast,
						"Arsenal Scores Thresholds",
						"Error fetching arsenal scores thresholds data."
					);
				},
				handleFetchStuffProbabilityDensitiesErrored: (
					context: TArsenalStuffDistributionsContext,
					event: TArsenalStuffDistributionsEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_STUFF_PROBABILITY_DENSITIES_ERROR ? event.data : undefined,
						context.toast,
						"Stuff Probability Densities",
						"Error fetching stuff probability densities data."
					);
				},
				handleFetchArsenalStuffDistributionsPlayerErrored: (
					context: TArsenalStuffDistributionsContext,
					event: TArsenalStuffDistributionsEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_ARSENAL_STUFF_DISTRIBUTIONS_PLAYER_ERROR ? event.data : undefined,
						context.toast,
						"Player Data - Arsenal Stuff Distributions",
						"Error fetching player data for arsenal stuff distributions."
					);
				}
			},
			services: {
				fetchPlayerSeasonArsenalScores: (
					context: TArsenalStuffDistributionsContext,
					_event: AnyEventObject
				) => {
					const fetchFunc = () =>
						fetchPlayerSeasonArsenalScores(
							{
								playerId: context.playerId,
								season: context.seasonFilter,
								playingLevel: context.playingLevel,
								gameType: GAME_TYPE_OVERALL,
								countSplit: COUNT_SPLIT_OVERALL,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchTwentyEightyGradeDistribution: (
					context: TArsenalStuffDistributionsContext,
					_event: AnyEventObject
				) => {
					const fetchFunc = () =>
						fetchTwentyEightyGradeDistribution(
							context.cancelSources[TWENTY_EIGHTY_GRADE_DISTRIBUTION_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchArsenalScoresThreshold: (context: TArsenalStuffDistributionsContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchArsenalScoresThreshold(
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchArsenalStuffDistributionsPlayer: (
					context: TArsenalStuffDistributionsContext,
					_event: AnyEventObject
				) => {
					if (context.playerId === undefined) return Promise.resolve(undefined);
					const fetchFunc = () =>
						fetchPlayer<TArsenalStuffDistributionsPlayer>({
							id: context.playerId as number, // We know this is a number based on the if statement above
							isUseCombinedId: true,
							fields: "throws"
						});
					return promiseWRetry(fetchFunc);
				},
				fetchStuffProbabilityDensities: (
					context: TArsenalStuffDistributionsContext,
					_event: AnyEventObject
				) => {
					const {
						playerId,
						playingLevel,
						seasonFilter,
						batsFilter,
						throwsFilter,
						stuffProbabilityDensities
					} = context;
					const existingData = stuffProbabilityDensities[`${batsFilter}-${throwsFilter}`];
					if (existingData) return Promise.resolve(existingData);
					if (playerId === undefined) return Promise.resolve(undefined);
					const fetchFunc = () =>
						fetchPlayerMetricProbabilityDensities(
							{
								playerId: playerId,
								playingLevel: playingLevel,
								metricGroup: "stuff_grade",
								season: seasonFilter,
								bats: batsFilter !== BATS_OVERALL ? batsFilter : undefined,
								throws: throwsFilter,
								minPrediction: 20,
								maxPrediction: 80,
								isUseCache: true
							},
							context.cancelSources[STUFF_PROBABILITY_DENSITIES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default ArsenalStuffDistributionsMachine;
