import { MultiValue, SingleValue } from "react-select";

import { TOption } from "_react/inputs";
import { IGumboAppearance } from "_react/shared/_types/schema/gumbo_game";
import { IPlayer } from "_react/shared/data_models/player/_types";
import {
	ICommandLeaderBoardPitcher,
	ICommandAllPitchesData,
	ICommandAppearanceTagged
} from "_react/shared/_types/schema/command_chart";

import { TLeaderBoardMetrics } from "_react/command/_types";
import { PITCH_TYPE_OPTIONS } from "_react/command/_constants";

// Parse pitcher select options
export const parsePhilliesPitchers = (rawData: Array<IPlayer> | undefined | null) => {
	const pitcherOptions: Array<{ value: string; label: string }> = [];
	if (rawData) {
		rawData.forEach(pitcher => {
			if (pitcher.bamId && pitcher.bamId != null && pitcher.firstName && pitcher.lastName) {
				const bamId = pitcher.bamId as number;
				const pitcherSelectOption = {
					value: bamId.toString(),
					label: `${pitcher.firstName} ${pitcher.lastName}`
				};
				pitcherOptions.push(pitcherSelectOption);
			}
		});
	}
	return pitcherOptions;
};

// Parse appearance date select options
export const parseAppearanceDates = (rawData: IGumboAppearance[] | undefined | null) => {
	const dates: Array<string> = [];
	if (rawData) {
		rawData.forEach(appearance => {
			if (appearance.date) {
				dates.push(appearance.date);
			}
		});
	}
	return dates;
};

// Parse tagged appearance dates
export const parseAppearanceDatesTagged = (rawData: ICommandAppearanceTagged[] | undefined | null) => {
	const dates: Array<string> = [];
	if (rawData) {
		rawData.forEach(appearance => {
			if (appearance.date) {
				dates.push(appearance.date);
			}
		});
	}
	return dates;
};

// Parse game select options
export const parseGameOptions = (
	selectedDate: string | undefined | null,
	rawData: IGumboAppearance[] | undefined | null
) => {
	const gamesFiltered: IGumboAppearance[] = [];
	const gameOptions: Array<{ value: number; label: string }> = [];
	if (rawData && selectedDate) {
		rawData.forEach(appearance => {
			if (appearance.date && appearance.date === selectedDate) {
				gamesFiltered.push(appearance);
			}
		});
		gamesFiltered.forEach(game => {
			const gameName = `${game.homeTeamNameAbbrev} vs. ${game.awayTeamNameAbbrev} (${game.gameNumber ?? 1})`;
			const gameOption = {
				label: gameName,
				value: game.gamePk
			};
			gameOptions.push(gameOption);
		});
	}
	return gameOptions;
};

/////////////// LEADERBOARD FUNCTIONS /////////////////
export const filterLeaderboardMetrics = (
	rawData: TLeaderBoardMetrics | undefined,
	minPitches: number,
	pitchOptions?: MultiValue<TOption<string>> | SingleValue<TOption<string>> | null | undefined
): TLeaderBoardMetrics | undefined => {
	if (rawData) {
		const filteredMetrics = rawData.filter(
			pitcherPitchType => pitcherPitchType.numPitches && pitcherPitchType.numPitches >= minPitches
		);
		if (pitchOptions && Array.isArray(pitchOptions) && pitchOptions.length < PITCH_TYPE_OPTIONS.length) {
			const selectedPitchTypes: Array<string> = [];
			pitchOptions.forEach(option => selectedPitchTypes.push(option.value));
			const filteredMetricsPitchType = filteredMetrics.filter(pitcherPitchType =>
				selectedPitchTypes.includes(pitcherPitchType.pitchType)
			);
			return filteredMetricsPitchType;
		}
		return filteredMetrics;
	}
	return undefined;
};

export const getPitcherName = (row: ICommandLeaderBoardPitcher | ICommandAllPitchesData): string => {
	const fullName = `${row.pitcherFirstName} ${row.pitcherLastName}`;
	return fullName;
};

export const getKeyFromLeaderboardData = (row: ICommandLeaderBoardPitcher) => `${row.pitcherId}-${row.pitchType}`;
