import React, { useEffect, useMemo } from "react";
import { useMachine } from "@xstate/react";
import { VStack, HStack, Box, useToast, SystemStyleObject } from "@chakra-ui/react";
import dayjs from "dayjs";

import { TPlayingLevel } from "_react/shared/data_models/hitter_grades/_types";
import { BATS_OVERALL } from "_react/shared/data_models/seasonal_grades/_constants";
import { getMetricRollingAverageRowNumber, getMetricGroupPitchType } from "_react/shared/data_models/metric/_helpers";
import {
	IMetricRollingAverages,
	IMetricRollingAverage,
	IMetricRollingAverageWithPitchType,
	TMetricGroup
} from "_react/shared/data_models/metric/_types";
import { PitchMetricStreamGraphPlot } from "_react/shared/ui/presentation/plots/PitchMetricStreamGraphPlot/PitchMetricStreamGraphPlot";

import { MONTHS } from "_react/shared/ui/data/plots/PitcherMetricOverTimeStreamGraph/_constants";
import createPitcherMetricOverTimeStreamGraphMachine, {
	TPitcherMetricOverTimeStreamGraphContext,
	TRollingAverages,
	SET_ROLLING_AVERAGES,
	SET_PLAYER_ID,
	SET_PLAYING_LEVEL,
	SET_METRIC_GROUP,
	SET_SEASON_FILTER,
	FETCHING_ROLLING_AVERAGES,
	SET_BATS_FILTER,
	SET_THROWS_FILTER
} from "_react/shared/ui/data/plots/PitcherMetricOverTimeStreamGraph/_machine";

export type TPitcherMetricOverTimeStreamGraphData = {
	rollingAverages: TRollingAverages;
	metricGroup: TMetricGroup;
	isLoading?: boolean;
};

type TPitcherMetricOverTimeStreamGraphStyle = {
	container?: SystemStyleObject;
};

type TPitcherMetricOverTimeStreamGraphProps = {
	title?: string;
	playerId?: number;
	playingLevel?: TPlayingLevel;
	// currently only works for pitch_usage
	metricGroup: TMetricGroup;
	seasonFilter?: number;
	data?: TPitcherMetricOverTimeStreamGraphData;
	shouldFetchData?: boolean;
	batsFilter?: string;
	throwsFilter?: string;
	width?: number;
	style?: TPitcherMetricOverTimeStreamGraphStyle;
};

const PitcherMetricOverTimeStreamGraph = ({
	title,
	playerId,
	playingLevel,
	metricGroup,
	seasonFilter = dayjs().year(),
	data,
	shouldFetchData = true,
	batsFilter = BATS_OVERALL,
	throwsFilter,
	width,
	style
}: TPitcherMetricOverTimeStreamGraphProps) => {
	const toast = useToast();
	const [current, send] = useMachine(
		createPitcherMetricOverTimeStreamGraphMachine(
			batsFilter,
			throwsFilter,
			seasonFilter,
			playerId,
			playingLevel,
			metricGroup,
			shouldFetchData,
			data,
			toast
		)
	);
	const { rollingAverages } = current.context as TPitcherMetricOverTimeStreamGraphContext;

	const fetchingRollingAverages = current.matches(FETCHING_ROLLING_AVERAGES);
	const isLoading = shouldFetchData ? fetchingRollingAverages : data?.isLoading;

	// Update machine context when data prop changes
	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		send({ type: SET_PLAYING_LEVEL, data: playingLevel });
	}, [playingLevel, send]);

	useEffect(() => {
		send({ type: SET_METRIC_GROUP, data: metricGroup });
	}, [metricGroup, send]);

	useEffect(() => {
		send({ type: SET_SEASON_FILTER, data: seasonFilter });
	}, [seasonFilter, send]);

	useEffect(() => {
		send({ type: SET_BATS_FILTER, data: batsFilter });
	}, [batsFilter, send]);

	useEffect(() => {
		send({ type: SET_THROWS_FILTER, data: throwsFilter });
	}, [throwsFilter, send]);

	useEffect(() => {
		if (data?.rollingAverages !== rollingAverages && !shouldFetchData) {
			send({ type: SET_ROLLING_AVERAGES, data: data?.rollingAverages ?? {} });
		}
	}, [data?.rollingAverages, rollingAverages, shouldFetchData, send]);

	const pitchDataStacked:
		| {
				combinedPitchData: Array<IMetricRollingAverageWithPitchType>;
				stackedPitchData: { [index: number]: Array<number> };
				pitchTypeKeys: Array<string>;
		  }
		| undefined = useMemo(() => {
		if (rollingAverages[`${batsFilter}-${throwsFilter}`] == null) return undefined;

		// Reformat data
		const combinedRollingAverages = rollingAverages[`${batsFilter}-${throwsFilter}`]?.reduce(
			(
				combinedRollingAverages: {
					// An array of all rolling averages
					combinedPitchData: Array<IMetricRollingAverageWithPitchType>;
					// The top-level key-value pair is rowNumber: object
					// In this object is the key-value pair pitchType: rollingAverage
					// This allows us to reformat into the array of arrays that's needed to create the streamgraph
					pitchDataDict: { [index: number]: { [index: string]: number } };
					// the order of the pitch types will be in in the stacked data
					pitchTypeKeys: Array<string>;
				},
				metricRollingAverages: IMetricRollingAverages
			) => {
				// Filter out any averages below the rolling average period
				const rollingAverages = metricRollingAverages.rollingAverages.filter(
					(metricRollingAverage: IMetricRollingAverage) =>
						metricRollingAverage.rowNumber >= metricRollingAverages.rollingAveragePeriod
				);

				// Get the pitch type and add to the keys - the order of the keys will determine how we order the stacked data
				const pitchType = getMetricGroupPitchType(metricRollingAverages) ?? "UN";
				combinedRollingAverages.pitchTypeKeys.push(pitchType);

				// Transform the data into the formats we need for plotting
				rollingAverages.forEach((metricRollingAverage: IMetricRollingAverage) => {
					// Get the overall row number (for all pitches)
					const rowNumber = getMetricRollingAverageRowNumber(metricRollingAverage);
					// Get the rolling average - default to 0 if it does not exist
					const rollingAverage = metricRollingAverage.rollingAverage ?? 0;
					// Add this to the combined data which has all rolling averages for all pitch types in one array
					combinedRollingAverages.combinedPitchData.push({
						...metricRollingAverage,
						rowNumber: rowNumber,
						pitchType: pitchType
					} as IMetricRollingAverageWithPitchType);
					// Add the overall row number to the dict if it isn't already there
					if (!combinedRollingAverages.pitchDataDict.hasOwnProperty(rowNumber)) {
						combinedRollingAverages.pitchDataDict[rowNumber] = {};
					}
					// Add the pitch type : rolling average to that row
					combinedRollingAverages.pitchDataDict[rowNumber][pitchType] = rollingAverage;
				});

				return combinedRollingAverages;
			},
			{ combinedPitchData: [], pitchDataDict: {}, pitchTypeKeys: [] }
		);

		if (combinedRollingAverages) {
			const stackedRollingAverages: { [index: string]: Array<number> } = {};
			// For each rowNumber, create an array in the format [x, y1, y2, y3, y4, etc]
			// If some pitch types are not included for a specific row number, fill with 0 instead
			// This ensures that the data doesn't end up out of order if there are missing values
			// TODO: to make this work for stuff, we need to fill with the previous rolling average for that pitch type rather than 0
			Object.entries(combinedRollingAverages.pitchDataDict).forEach(([rowNumber, rollingAverages]) => {
				const stackArray = [parseInt(rowNumber)];
				// Looping through the keys ensures that the data is added to the array in the expected order
				combinedRollingAverages.pitchTypeKeys.forEach((pitchType: string) => {
					const rollingAverage = rollingAverages.hasOwnProperty(pitchType) ? rollingAverages[pitchType] : 0;
					stackArray.push(rollingAverage);
				});
				// Add this stack row to the stacked data
				stackedRollingAverages[rowNumber] = stackArray;
			});
			const combinedRollingAveragesWithStackData: {
				combinedPitchData: Array<IMetricRollingAverageWithPitchType>;
				stackedPitchData: { [index: number]: Array<number> };
				// DO NOT SORT THIS ARRAY - it is used to indicate the order of data in stackedPitchData
				pitchTypeKeys: Array<string>;
			} = {
				combinedPitchData: combinedRollingAverages.combinedPitchData,
				pitchTypeKeys: combinedRollingAverages.pitchTypeKeys,
				stackedPitchData: stackedRollingAverages
			};
			return combinedRollingAveragesWithStackData;
		}
		return undefined;
	}, [rollingAverages, batsFilter, throwsFilter]);

	const xTickLabels: undefined | Array<{ label: string; value: number }> = useMemo(() => {
		const yearsInDataset = pitchDataStacked?.combinedPitchData?.map(
			(rollingAverage: IMetricRollingAverageWithPitchType) => new Date(rollingAverage.date).getFullYear()
		);
		const uniqueYearsInDataset = yearsInDataset ? new Set<number>([...yearsInDataset]) : undefined;
		const labels: Array<{ label: string; value: number }> = [];
		uniqueYearsInDataset?.forEach((year: number) => {
			const averagesFilteredByYear = pitchDataStacked?.combinedPitchData?.filter(
				(rollingAverage: IMetricRollingAverageWithPitchType) => dayjs(rollingAverage.date).year() === year
			);

			MONTHS.forEach((month: number) => {
				const averagesFilteredByMonth = averagesFilteredByYear?.filter(
					(rollingAverage: IMetricRollingAverageWithPitchType) =>
						dayjs(rollingAverage.date).month() === month - 1
				);

				const minDate = averagesFilteredByMonth?.length
					? averagesFilteredByMonth.reduce(
							(prev: IMetricRollingAverageWithPitchType, curr: IMetricRollingAverageWithPitchType) =>
								prev.rowNumber < curr.rowNumber ? prev : curr
					  )
					: undefined;

				if (minDate) {
					labels.push({
						label:
							uniqueYearsInDataset?.size > 1
								? `${new Date(minDate.date).toLocaleString("default", {
										timeZone: "UTC",
										month: "long"
								  })} ${year}`
								: new Date(minDate.date).toLocaleString("default", { timeZone: "UTC", month: "long" }),
						value: minDate.rowNumber
					});
				}
			});
		});
		return labels.length ? labels : undefined;
	}, [pitchDataStacked?.combinedPitchData]);

	return (
		<>
			{isLoading && <Box className="loading-item" height="xs" width="lg" sx={style?.container} />}
			{!isLoading && (
				<VStack align="start" sx={style?.container}>
					<HStack w="100%" justify="start">
						{title && (
							<Box fontFamily="heading" fontSize="md" fontWeight="bold">
								{title}
							</Box>
						)}
					</HStack>
					<PitchMetricStreamGraphPlot<IMetricRollingAverage>
						pitchData={pitchDataStacked}
						xValue="rowNumber"
						xLabel={`${
							batsFilter === BATS_OVERALL ? "Overall" : `v${batsFilter}HB`
						} Pitch Count (Current Season)`}
						xTickLabels={xTickLabels}
						tooltipValues={{ xValue: "date", pitchType: "pitchType", yValue: "rollingAverage" }}
						yExtrema={{ min: 0, max: 1 }}
						stack={{ order: "descending", offset: "none" }}
						width={width}
					/>
				</VStack>
			)}
		</>
	);
};

export default PitcherMetricOverTimeStreamGraph;
