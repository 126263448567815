import React from "react";

import { Flex, Tooltip } from "@chakra-ui/react";

import Tag from "_react/shared/ui/icons/Tag";

export type TPitchRetagIconProps = {
	tooltipLabel?: string;
};

const PitchTypeRetagIcon = ({ tooltipLabel }: TPitchRetagIconProps) => {
	return (
		<Tooltip hasArrow placement="top" label={tooltipLabel} whiteSpace="pre-wrap">
			<Flex color="gray.500">
				<Tag boxSize={4} />
			</Flex>
		</Tooltip>
	);
};

export default PitchTypeRetagIcon;
