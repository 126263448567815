import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";
import { PLAYING_LEVEL_AMA } from "_react/shared/data_models/metric/_constants";
import {
	IMetricRollingAverages,
	IMetricProbabilityDensities,
	IMetricJointProbabilityDensities,
	TMetricGroup,
	IMetricRollingAveragesAma,
	IMetricJointProbabilityDensitiesAma
} from "_react/shared/data_models/metric/_types";

export type TMetricRollingAveragesGetQueryParams = {
	metricId?: number;
	"metricId[in]"?: string;
	playerId?: number;
	playingLevel?: string;
	bamId?: number;
	trackmanId?: number;
	season?: number;
	date?: number;
	"date[gte]"?: number;
	"date[lte]"?: number;
	bats?: string;
	throws?: string;
	pitchType?: string;
	"pitchType[in]"?: string;
	isUseCache?: boolean;
	limit?: number;
	offset?: number;
	versionArsenalScores?: string;
};

export type TPlayerMetricRollingAveragesGetQueryParams = {
	playerId: number;
	playingLevel?: string;
	metricGroup: TMetricGroup;
	bats?: string;
	throws?: string;
	season?: number;
	date?: number;
	"date[gte]"?: number;
	"date[lte]"?: number;
	isUseCache?: boolean;
	limit?: number;
	offset?: number;
	versionArsenalScores?: string;
};

export type TPlayerMetricJointProbabilityDensitiesGetQueryParams = {
	playerId: number;
	playingLevel?: string;
	metricGroup: TMetricGroup;
	pitchType?: string;
	bats?: string;
	throws?: string;
	season?: number;
	date?: number;
	"date[gte]"?: number;
	"date[lte]"?: number;
	isIncludeJointProbabilityDensityPitches?: boolean;
	isUseCache?: boolean;
	limit?: number;
	offset?: number;
	numPoints?: number;
	versionArsenalScores?: string;
};

export type TMetricProbabilityDensitiesGetQueryParams = {
	metricId?: number;
	"metricId[in]"?: string;
	playerId?: number;
	playingLevel?: string;
	bamId?: number;
	trackmanId?: number;
	season?: number;
	date?: number;
	"date[gte]"?: number;
	"date[lte]"?: number;
	bats?: string;
	throws?: string;
	pitchType?: string;
	"pitchType[in]"?: string;
	isUseCache?: boolean;
	limit?: number;
	offset?: number;
	numPoints?: number;
	maxPrediction?: number;
	minPrediction?: number;
	versionArsenalScores?: string;
};

export type TPlayerMetricProbabilityDensitiesGetQueryParams = {
	playerId: number;
	playingLevel?: string;
	metricGroup: TMetricGroup;
	bats?: string;
	throws?: string;
	season?: number;
	date?: number;
	"date[gte]"?: number;
	"date[lte]"?: number;
	isUseCache?: boolean;
	limit?: number;
	offset?: number;
	numPoints?: number;
	maxPrediction?: number;
	minPrediction?: number;
	versionArsenalScores?: string;
};

export const fetchMetricRollingAverages = (
	queryParams: TMetricRollingAveragesGetQueryParams,
	cancelToken?: CancelToken
) => {
	const path = `rolling_average`;
	if (queryParams?.playingLevel === PLAYING_LEVEL_AMA)
		return fetchResource<TMetricRollingAveragesGetQueryParams, Array<IMetricRollingAveragesAma>>(
			path,
			queryParams,
			cancelToken
		);
	return fetchResource<TMetricRollingAveragesGetQueryParams, Array<IMetricRollingAverages>>(
		path,
		queryParams,
		cancelToken
	);
};

export const fetchPlayerMetricRollingAverages = (
	queryParams: TPlayerMetricRollingAveragesGetQueryParams,
	cancelToken?: CancelToken
) => {
	const path = `rolling_average/player`;
	if (queryParams?.playingLevel === PLAYING_LEVEL_AMA)
		return fetchResource<TPlayerMetricRollingAveragesGetQueryParams, Array<IMetricRollingAveragesAma>>(
			path,
			queryParams,
			cancelToken
		);
	return fetchResource<TPlayerMetricRollingAveragesGetQueryParams, Array<IMetricRollingAverages>>(
		path,
		queryParams,
		cancelToken
	);
};

export const fetchMetricProbabilityDensities = (
	queryParams: TMetricProbabilityDensitiesGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TMetricProbabilityDensitiesGetQueryParams, Array<IMetricProbabilityDensities>>(
		"probability_density",
		queryParams,
		cancelToken
	);

export const fetchPlayerMetricProbabilityDensities = (
	queryParams: TPlayerMetricProbabilityDensitiesGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TPlayerMetricProbabilityDensitiesGetQueryParams, Array<IMetricProbabilityDensities>>(
		"probability_density/player",
		queryParams,
		cancelToken
	);

export const fetchPlayerMetricJointProbabilityDensities = (
	queryParams: TPlayerMetricJointProbabilityDensitiesGetQueryParams,
	cancelToken?: CancelToken
) => {
	const path = `joint_probability_density/player`;
	if (queryParams?.playingLevel === PLAYING_LEVEL_AMA)
		return fetchResource<TPlayerMetricJointProbabilityDensitiesGetQueryParams, IMetricJointProbabilityDensitiesAma>(
			path,
			queryParams,
			cancelToken
		);
	return fetchResource<TPlayerMetricJointProbabilityDensitiesGetQueryParams, IMetricJointProbabilityDensities>(
		path,
		queryParams,
		cancelToken
	);
};
