import React from "react";
import { VStack } from "@chakra-ui/react";

import { PRO } from "utils/tsutils";
import { useIsGroupMember } from "_react/app/AppContents";
import { RND_IAM_GROUP } from "_react/shared/data_models/iam_new/_constants";

import PitcherValueTable from "_react/shared/ui/data/tables/PitcherValueTable/PitcherValueTable";
import PitcherFoundationalSkillsTable from "_react/shared/ui/data/tables/PitcherFoundationalSkillsTable/PitcherFoundationalSkillsTable";
import PitcherPerformanceTable from "_react/shared/ui/data/tables/PitcherPerformanceTable/PitcherPerformanceTable";
import PitcherPaOutcomesTable from "_react/shared/ui/data/tables/PitcherPaOutcomesTable/PitcherPaOutcomesTable";
import PitcherPitchOutcomesTable from "_react/shared/ui/data/tables/PitcherPitchOutcomesTable/PitcherPitchOutcomesTable";

type TPitcherStatisticsTablesProps = {
	playerId: number;
};

const PitcherStatisticsTables = ({ playerId }: TPitcherStatisticsTablesProps) => {
	const canViewPitcherValueTable = useIsGroupMember([RND_IAM_GROUP]);
	return (
		<VStack marginBottom="10" gap="10">
			{canViewPitcherValueTable && (
				<PitcherValueTable
					title="Value"
					playerId={playerId}
					style={{
						container: { width: "100%" },
						tableContainer: { width: "100%", overflowX: "scroll" }
					}}
				/>
			)}
			<PitcherFoundationalSkillsTable
				title="Foundational Skills"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
			<PitcherPerformanceTable
				title="Performance"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
			<PitcherPaOutcomesTable
				title="PA Outcomes"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
			<PitcherPitchOutcomesTable
				title="Pitch Outcomes"
				playerId={playerId}
				playingLevel={PRO}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
		</VStack>
	);
};

export default PitcherStatisticsTables;
