import React, { FunctionComponent, useContext } from "react";
import { $TSFixMe } from "utils/tsutils";
import CircularProgress from "_react/shared/legacy/ui/CircularProgress";
import { ColorSchemeGroup } from "_react/shared/legacy/ui/Colors";

import { AMA, INTL, PRO } from "_react/playerpage/_constants";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import IntlBio from "_react/playerpage/bio/IntlBio";
import AmaBio from "_react/playerpage/bio/AmaBio";
import ProBio from "_react/playerpage/bio/ProBio";
import { PlayerPageContext } from "_react/playerpage/_context";

type BioProps = {
	colorScheme?: ColorSchemeGroup;
	layout?: $TSFixMe;
	player: TPlayerPageCombinedPlayer;
	viewClassification: string | null;
	style?: $TSFixMe;
};

export const BioComp: FunctionComponent<BioProps> = ({ player, viewClassification }) => {
	const { bioDisplayOnly, amaBioDocument, setAmaBioDocument, intlBioDocument, setIntlBioDocument } = useContext(
		PlayerPageContext
	);

	if (player && viewClassification === AMA)
		return (
			<AmaBio
				bioDisplayOnly={bioDisplayOnly}
				amaBioDocument={amaBioDocument}
				setAmaBioDocument={setAmaBioDocument}
			/>
		);
	if (player && viewClassification === INTL)
		return (
			<IntlBio
				bioDisplayOnly={bioDisplayOnly}
				intlBioDocument={intlBioDocument}
				setIntlBioDocument={setIntlBioDocument}
			/>
		);
	if (player && viewClassification === PRO) return <ProBio player={player} />;
	return (
		<div>
			<CircularProgress center style={{ backgroundColor: "white", padding: "10px", borderRadius: "3px" }} />
		</div>
	);
};

export const Bio: FunctionComponent<BioProps> = props => <BioComp {...props} />;
