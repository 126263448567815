import React from "react";
import dayjs from "dayjs";
import { Text, VStack, HStack, Tooltip, Stat, StatNumber, StatArrow, StatHelpText, Box } from "@chakra-ui/react";

import { round10 } from "_react/shared/_helpers/numbers";
import { INTL } from "_react/playerpage/_constants";
import { POSITION_GROUPS_POSITION_PLAYER, POSITION_GROUPS_PITCHER } from "_react/shared/_constants/position_groups";
import { getPositionGroupLabel } from "_react/shared/_helpers/position_groups";
import { SV_COLOR_GRADIENT_FUNCTION } from "_react/playerpage/_constants";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import { TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { getAgeFromBirthDate } from "utils/helpers";
import { getPlayerFullNameFromPlayerProps } from "_react/shared/data_models/player/_helpers";
import {
	TSvData,
	TIntlSurplusValueSimilarPlayer
} from "_react/shared/ui/data/tables/IntlSurplusValueSimilarPlayersTable/_types";
import { convertToCurrency } from "_react/shared/ui/data/tables/SeasonalSurplusValuesTable/_helpers";
import OutlineWarning from "_react/shared/ui/icons/OutlineWarning";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";
import {
	getPlotDataForPoints,
	getXAxisLabels
} from "_react/shared/ui/data/tables/IntlSurplusValueSimilarPlayersTable/_helpers";
import SparklinePlot from "_react/shared/ui/presentation/plots/SparklinePlot/SparklinePlot";

export const NULL_VALUE = null;
export const NULL_FILLER_TEXT = "-";

const MIN_SV = 0;
const MAX_SV = 125;

export const NUMBER_OF_SEASONS = 4;

// We want to sort first by draft year, then by overall pick, but the shared table sort function only takes one value.
// As a workaround, define an upper bound on the number of players that could potentially be drafted in a given year.
// We generate a decimal by dividing overall pick by this upper bound, and add it to the year.
// This allows us to sort based on the year and break ties based on the overall pick.
export const PLAYERS_DRAFTED_UPPER_BOUND = 100000;

// TODO: These tooltips need to be updated
export const TOOLTIP_INTL_SURPLUS_VALUE =
	"International Surplus Value (INTL SV) is a forecast of the player's expected production at the Major League level\
	relative to expected compensation over his team control years. Due to the long and uncertain time-horizon for international players to realize surplus value,\
	the international model predicts an expectation of production in units of wins above replacement over the full controllable period (xWAR). A separate mapping\
	approximates expected compensation by converting xWAR to INTL SV.";
export const TOOLTIP_INTL_SV_OVER_TIME =
	"SV Over Time illustrates how a player's surplus value evolved from their\
	platform year as an international (left-most point) through their third season in domestic, professional baseball (right-most point).";
export const TOOLTIP_INTL_SV_CHANGE =
	"SV CHNG shows the player's current surplus value, along with an arrow depicting the direction of change for the player's\
	surplus value relative to their platform year as an international (a blue, downward-facing arrow indicates a decrease, while a green, upward-facing arrow indicates\
	an increase). The magnitude of the surplus value change is shown to the right of the arrow.";
export const TOOLTIP_HIGHEST_LEVEL =
	"This column shows the highest level of domestic, professional baseball the corresponding player has reached.";

export const POSITION_GROUPS_POSITION_PLAYER_OPTIONS = POSITION_GROUPS_POSITION_PLAYER.map((positionGroup: string) => {
	return {
		label: getPositionGroupLabel(positionGroup),
		value: positionGroup
	};
});

export const POSITION_GROUPS_PITCHER_OPTIONS = POSITION_GROUPS_PITCHER.map((positionGroup: string) => {
	return { label: positionGroup, value: positionGroup };
});

export const BATS_THROWS_COLUMN = {
	value: "bt",
	label: "B/T",
	valueType: VALUE_TYPE_STRING as TValueType,
	width: 16,
	isMobile: false,
	getValueFunction: (row: TIntlSurplusValueSimilarPlayer) => {
		return `${row.player?.bats ?? NULL_FILLER_TEXT}/${row.player?.throws ?? NULL_FILLER_TEXT}`;
	}
};

export const SV_COLUMN = {
	value: "sv",
	label: "SV",
	tooltip: TOOLTIP_INTL_SURPLUS_VALUE,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true,
	width: 20,
	getValueFunction: (row: TIntlSurplusValueSimilarPlayer) =>
		row.xsurplusMillions !== null ? convertToCurrency(row.xsurplusMillions) : NULL_FILLER_TEXT,
	getSortValueFunction: (row: TIntlSurplusValueSimilarPlayer) => row.xsurplusMillions,
	getColorGradientValueFunction: (row: TIntlSurplusValueSimilarPlayer) => row.xsurplusMillions,
	colorGradientFunction: SV_COLOR_GRADIENT_FUNCTION
};

const PLAYER_COLUMN = {
	value: "player",
	label: "Player",
	width: 44,
	isMobile: true,
	getValueFunction: (row: TIntlSurplusValueSimilarPlayer) => {
		return (
			<Tooltip
				hasArrow
				placement="top"
				isDisabled={row.player?.intlProfile != null}
				label={`This player has no player page in Rocky (Combined ID: ${row?.playerId ?? "-"}, Source: ${row
					?.player?.source ?? "-"})`}
			>
				<VStack align="start" gap="0">
					<HStack align="start" sx={{ alignItems: "center" }}>
						<Text fontSize="md" fontWeight="semibold">
							{getPlayerFullNameFromPlayerProps(
								row?.player?.firstName,
								row?.player?.firstNameLegal,
								row?.player?.lastName,
								row?.player?.lastNameExtended,
								row.player?.intlProfile?.firstName,
								row?.player?.intlProfile?.lastName,
								row?.player?.trackmanPlayer?.name,
								false,
								INTL
							)}
						</Text>
						{row.player?.intlProfile == null && <OutlineWarning color="gray.500" />}
					</HStack>
					<HStack marginTop="0 !important" fontSize="sm" color="gray.500">
						<>
							<Text sx={{ textWrap: "pretty" }}>
								{`${row.player?.intlProfile?.birthCountry ?? NULL_FILLER_TEXT}`}
								{" | "}
								{row.player?.intlProfile?.dateOfBirth || row.player?.birthDate
									? getAgeFromBirthDate(
											row.player?.intlProfile?.dateOfBirth ?? row.player?.birthDate
									  ) ?? NULL_FILLER_TEXT
									: NULL_FILLER_TEXT}
							</Text>
						</>
					</HStack>
				</VStack>
			</Tooltip>
		);
	},
	getSortValueFunction: (row: TIntlSurplusValueSimilarPlayer) =>
		row?.player?.intlProfile?.lastName ?? row?.player?.lastName ?? row?.player?.lastNameExtended ?? NULL_VALUE,
	onClickFunction: (row: TIntlSurplusValueSimilarPlayer) => {
		if (row?.player?.playerIntlId != null)
			window.open(`../player?philId=${row?.player?.playerIntlId}&view=summary&viewClassification=intl`, "_blank");
	}
};

export const SV_OVER_TIME_COLUMN = {
	value: "sv_over_time",
	label: "SV OVER TIME",
	tooltip: TOOLTIP_INTL_SV_OVER_TIME,
	width: 44,
	isMobile: false,
	getValueFunction: (row: TIntlSurplusValueSimilarPlayer) => {
		const plotDataForPoints = getPlotDataForPoints(row?.parsedPlotData, row?.parsedSignedYear);
		const xAxisLabels = getXAxisLabels(plotDataForPoints);

		return (
			<SparklinePlot<TSvData>
				xValue="date"
				yValue="sv"
				getYValueFormattedFunction={(y: number) => `$${round10(y, -1)}M`}
				xAxisExtrema={{
					min: dayjs(`${row?.parsedSignedYear}-01-01`).toDate(),
					max: row?.parsedSignedYear ? dayjs(`${row.parsedSignedYear + 3}-01-01`).toDate() : undefined
				}}
				yAxisExtrema={{ min: MIN_SV, max: MAX_SV }}
				xTickLabels={xAxisLabels}
				plotData={row?.parsedPlotData}
				pointsOnLine={{ showPoints: true, pointData: plotDataForPoints }}
				// Use baseline data from the first point of the plot data
				baselineData={
					row?.parsedPlotData?.length && row?.parsedPlotData.length > 1
						? { y: row?.parsedPlotData[0].sv, includeDifferenceFill: true }
						: undefined
				}
				width={150}
				height={45}
			/>
		);
	},
	getSortValueFunction: (row: TIntlSurplusValueSimilarPlayer) => row.xsurplusMillions
};

export const SV_CHANGE_COLUMN = {
	value: "sv_change",
	label: "SV Chng",
	tooltip: TOOLTIP_INTL_SV_CHANGE,
	key: "sv_change",
	valueType: VALUE_TYPE_NUMBER as TValueType,
	width: 20,
	isMobile: false,
	getValueFunction: (row: TIntlSurplusValueSimilarPlayer) => {
		const finalChangeDatum = row?.parsedPlotData?.length
			? row.parsedPlotData[row.parsedPlotData.length - 1]
			: undefined;
		const finalChangeFormatted = finalChangeDatum?.totalDifference
			? `${round10(Math.abs(finalChangeDatum.totalDifference), -1)}`
			: undefined;
		return (
			<Box marginTop={1}>
				{row?.parsedSignedYear != null && finalChangeDatum?.sv != null && (
					<Stat size="xs">
						<StatNumber fontWeight="normal">{`$${round10(finalChangeDatum.sv, -1)}M`}</StatNumber>
						{finalChangeFormatted && (
							<>
								<StatHelpText>
									{finalChangeDatum.totalDifference && finalChangeDatum.totalDifference !== 0 && (
										<StatArrow
											type={finalChangeDatum?.totalDifference > 0 ? "increase" : "decrease"}
											color={finalChangeDatum?.totalDifference > 0 ? "green.500" : "blue.400"}
										/>
									)}
									{finalChangeFormatted}
								</StatHelpText>
							</>
						)}
					</Stat>
				)}
			</Box>
		);
	},
	getSortValueFunction: (row: TIntlSurplusValueSimilarPlayer) => {
		const finalSv = row?.parsedPlotData?.length ? row.parsedPlotData[row.parsedPlotData.length - 1].sv : null;
		return finalSv;
	}
};

export const HIGHEST_LEVEL_COLUMN = {
	value: "highest_lvl",
	label: "Highest Lvl",
	tooltip: TOOLTIP_HIGHEST_LEVEL,
	width: 20,
	isMobile: true,
	getValueFunction: (row: TIntlSurplusValueSimilarPlayer) => (
		<TeamLevelBadge style={{ fontSize: "l" }} level={row.player?.proProfile?.highestLevelAppearance} />
	),
	getSortValueFunction: (row: TIntlSurplusValueSimilarPlayer) =>
		row.player?.proProfile?.highestLevelAppearance ?? NULL_VALUE
};

export const SIGNED_YEAR_COLUMN = {
	value: "signed_year",
	label: "Signed",
	width: 20,
	isMobile: false,
	getValueFunction: (row: TIntlSurplusValueSimilarPlayer) => {
		const signedYear: number | undefined =
			row.player?.proProfile?.firstProContractDate != null
				? dayjs(row.player.proProfile.firstProContractDate).year()
				: undefined;
		return (
			<HStack>
				<Text>{signedYear ?? NULL_FILLER_TEXT}</Text>
			</HStack>
		);
	},
	getSortValueFunction: (row: TIntlSurplusValueSimilarPlayer) => {
		const signedYear: number | undefined =
			row.player?.proProfile?.firstProContractDate != null
				? dayjs(row.player.proProfile.firstProContractDate).year()
				: undefined;
		return signedYear ?? NULL_VALUE;
	}
};

export const INTL_SURPLUS_VALUE_SIMILAR_PLAYERS_COLUMNS = [
	PLAYER_COLUMN,
	BATS_THROWS_COLUMN,
	SV_COLUMN,
	SIGNED_YEAR_COLUMN,
	SV_OVER_TIME_COLUMN,
	SV_CHANGE_COLUMN,
	HIGHEST_LEVEL_COLUMN
];
