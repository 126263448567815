import React from "react";
import { VStack } from "@chakra-ui/react";

import { useIsGroupMember } from "_react/app/AppContents";
import { RND_IAM_GROUP } from "_react/shared/data_models/iam_new/_constants";

import PositionPlayerFoundationalSkillsTable from "_react/shared/ui/data/tables/PositionPlayerFoundationalSkillsTable/PositionPlayerFoundationalSkillsTable";
import PositionPlayerPerformanceTable from "_react/shared/ui/data/tables/PositionPlayerPerformanceTable/PositionPlayerPerformanceTable";
import PositionPlayerValueTable from "_react/shared/ui/data/tables/PositionPlayerValueTable/PositionPlayerValueTable";
import SwingMetricsTable from "_react/shared/ui/data/tables/SwingMetricsTable/SwingMetricsTable";

type TPositionPlayerStatisticsTablesProps = {
	playerId: number;
};

const PositionPlayerStatisticsTables = ({ playerId }: TPositionPlayerStatisticsTablesProps) => {
	const canViewPositionPlayerValueTable = useIsGroupMember([RND_IAM_GROUP]);
	return (
		<VStack marginBottom="10" gap="10">
			{canViewPositionPlayerValueTable && (
				<PositionPlayerValueTable
					title="Value"
					playerId={playerId}
					style={{
						container: { width: "100%" },
						tableContainer: { width: "100%", overflowX: "scroll" }
					}}
				/>
			)}
			<PositionPlayerFoundationalSkillsTable
				title="Foundational Skills"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
			<PositionPlayerPerformanceTable
				title="Performance"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
			<SwingMetricsTable
				title="Swing Metrics"
				playerId={playerId}
				style={{
					container: { width: "100%" },
					tableContainer: { width: "100%", overflowX: "scroll" }
				}}
			/>
		</VStack>
	);
};

export default PositionPlayerStatisticsTables;
