import React, { useState, useEffect, CSSProperties } from "react";

import { Button } from "_react/shared/legacy/ui/Button";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";

import { TPlayerPlanGoal } from "_react/playerplan/shared/_types";
import {
	AreYouSureDiv,
	CheckboxRightStyle,
	GoalStatusDiv,
	LeftButtonStyle,
	StatusCheckboxDiv,
	WrapperStyle,
	YesNoDiv
} from "_react/playerplan/shared/_styles";
import { Checkbox } from "_react/shared/legacy/ui/Checkbox";
import { CANCELED, COMPLETE, IN_PROGRESS } from "_react/playerplan/shared/_constants";
import { MinorSection } from "_react/shared/legacy/ui/SectionWrapper";

export type TGoalStatusCheckboxesStyle = {
	checkbox?: CSSProperties;
};

export type TGoalStatusCheckboxesProps = {
	userId: number;
	goal: TPlayerPlanGoal;
	isPHP: boolean;
	isGoalActionsDisabled?: boolean;
	updateGoal: (goal: TPlayerPlanGoal, updateApprovalDate?: boolean) => void;
	isGroup1?: boolean;
	isGroup2?: boolean;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TGoalStatusCheckboxesStyle;
};

export const GoalStatusCheckboxes = ({
	userId,
	goal,
	isPHP,
	isGoalActionsDisabled = false,
	updateGoal,
	isGroup1 = false,
	isGroup2 = false,
	colorSchemeGroup = defaultColorScheme,
	style
}: TGoalStatusCheckboxesProps) => {
	const [newGoalStatus, setNewGoalStatus] = useState<string>();

	// When goal changes, reset status to initial state
	useEffect(() => {
		setNewGoalStatus(undefined);
	}, [goal]);

	// Check whether any status options will be available to the user before showing the Goal Status section
	const showStatusDiv = isGroup1 || isGroup2 || (userId === goal.createUserId && (goal.isStrength || isPHP));

	return showStatusDiv ? (
		<MinorSection title={"Goal Status?"} style={WrapperStyle}>
			{!newGoalStatus && (
				<GoalStatusDiv>
					{(isGroup1 || isGroup2 || userId === goal.createUserId) && (goal.isStrength || isPHP) && (
						<StatusCheckboxDiv>
							<Checkbox
								style={{ ...CheckboxRightStyle, ...style?.checkbox }}
								checked={goal.status.value === CANCELED}
								disabled={isGoalActionsDisabled}
								onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
									setNewGoalStatus(e ? CANCELED : IN_PROGRESS)
								}
								colorScheme={colorSchemeGroup.tertiary}
							/>
							Canceled
						</StatusCheckboxDiv>
					)}
					{(isGroup1 || isGroup2) && !goal.isStrength && !isPHP && (
						<>
							<StatusCheckboxDiv>
								<Checkbox
									style={{ ...CheckboxRightStyle, ...style?.checkbox }}
									checked={goal.isPriority}
									disabled={
										isGoalActionsDisabled ||
										goal.status.value === COMPLETE ||
										goal.status.value === CANCELED
									}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										updateGoal({ ...goal, isPriority: Boolean(e) })
									}
									colorScheme={colorSchemeGroup.tertiary}
								/>
								Priority
							</StatusCheckboxDiv>
							<StatusCheckboxDiv>
								<Checkbox
									style={{ ...CheckboxRightStyle, ...style?.checkbox }}
									checked={goal.isInactive}
									disabled={
										isGoalActionsDisabled ||
										goal.status.value === COMPLETE ||
										goal.status.value === CANCELED
									}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										updateGoal({ ...goal, isInactive: Boolean(e) })
									}
									colorScheme={colorSchemeGroup.tertiary}
								/>
								Inactive
							</StatusCheckboxDiv>
							<StatusCheckboxDiv>
								<Checkbox
									style={{ ...CheckboxRightStyle, ...style?.checkbox }}
									checked={goal.status.value === CANCELED}
									disabled={isGoalActionsDisabled || goal.status.value === COMPLETE}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setNewGoalStatus(e ? CANCELED : IN_PROGRESS)
									}
									colorScheme={colorSchemeGroup.tertiary}
								/>
								Canceled
							</StatusCheckboxDiv>
							<StatusCheckboxDiv>
								<Checkbox
									style={{ ...CheckboxRightStyle, ...style?.checkbox }}
									checked={goal.isNeedsReview}
									disabled={
										isGoalActionsDisabled ||
										goal.status.value === COMPLETE ||
										goal.status.value === CANCELED
									}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										updateGoal({ ...goal, isNeedsReview: Boolean(e) })
									}
									colorScheme={colorSchemeGroup.tertiary}
								/>
								Needs Review
							</StatusCheckboxDiv>
							<StatusCheckboxDiv>
								<Checkbox
									style={{ ...CheckboxRightStyle, ...style?.checkbox }}
									checked={goal.status.value === COMPLETE}
									disabled={isGoalActionsDisabled || goal.status.value === CANCELED}
									onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
										setNewGoalStatus(e ? COMPLETE : IN_PROGRESS)
									}
									colorScheme={colorSchemeGroup.tertiary}
								/>
								Complete
							</StatusCheckboxDiv>
						</>
					)}
				</GoalStatusDiv>
			)}
			{newGoalStatus && (
				<GoalStatusDiv>
					<YesNoDiv>
						{`Are you sure you want to mark this goal as ${newGoalStatus}?`}
						<br></br>This action cannot be undone.
					</YesNoDiv>
					<AreYouSureDiv>
						<Button
							disabled={false}
							style={LeftButtonStyle}
							onClick={() => setNewGoalStatus(undefined)}
							title="No"
							colorScheme={colorSchemeGroup.primary}
						/>
						<Button
							disabled={false}
							onClick={() => {
								setNewGoalStatus(undefined);
								updateGoal({
									...goal,
									status: {
										...goal.status,
										value: newGoalStatus
									}
								});
							}}
							title="Yes"
							colorScheme={colorSchemeGroup.secondary}
						/>
					</AreYouSureDiv>
				</GoalStatusDiv>
			)}
		</MinorSection>
	) : (
		<></>
	);
};
