import React from "react";

import { round10 } from "_react/shared/_helpers/numbers";
import { getLevelDisplayFromLevels } from "_react/shared/_helpers/stats";
import ExpandableRaaLabel from "_react/shared/ui/data/tables/shared/ExpandableRaaLabel";
import ExpandableRaa from "_react/shared/ui/data/tables/shared/ExpandableRaa";
import TeamColumn from "_react/shared/ui/data/tables/shared/TeamColumn";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import { TColumn, TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import {
	TOOLTIP_PRO_POSITION_PLAYER_VALE_RAR,
	TOOLTIP_PRO_POSITION_PLAYER_VALE_HIT_RAA,
	TOOLTIP_PRO_POSITION_PLAYER_VALE_RUN_RAA,
	TOOLTIP_PRO_POSITION_PLAYER_VALE_RUN_RAA_COMPONENT,
	TOOLTIP_PRO_POSITION_PLAYER_VALE_FIELD_RAA,
	TOOLTIP_PRO_POSITION_PLAYER_VALE_FIELD_RAA_COMPONENT,
	TOOLTIP_PRO_POSITION_PLAYER_VALE_POS_ADJ
} from "_react/shared/_constants/tooltips";

import { TPositionPlayerValueRow } from "_react/shared/ui/data/tables/PositionPlayerValueTable/_types";
import { getLevelsFromRow } from "_react/shared/ui/data/tables/PositionPlayerValueTable/_helpers";

// Non-Column Constants

export const NUM_DISPLAY_SEASONS = 3;

// Table Columns

const NULL_FILLER_TEXT = "";

const SEASON_COLUMN = {
	id: "season",
	value: "season",
	label: "Season",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerValueRow) => row.combinedPositionPlayerValueData.season
};

const TEAM_COLUMN = {
	id: "team",
	value: "team",
	label: "Team",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TPositionPlayerValueRow) => {
		if ("team" in row.combinedPositionPlayerValueData)
			return (
				<TeamColumn
					teamBam={row.combinedPositionPlayerValueData.teamBam}
					childRowCount={row.childData?.length}
				/>
			);
		return <TeamColumn childRowCount={row.childData?.length} />;
	}
};

const LEVEL_COLUMN = {
	id: "level",
	value: "level",
	label: "Level",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TPositionPlayerValueRow) =>
		getLevelDisplayFromLevels(getLevelsFromRow(row), NULL_FILLER_TEXT),
	getSortValueFunction: (row: TPositionPlayerValueRow) => {
		// Child Rows
		if ("teamBam" in row.combinedPositionPlayerValueData)
			return row.combinedPositionPlayerValueData.teamBam?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER;
		// Parent Rows with nested data - highest level if multiple levels
		if (row.childData && row.childData.length > 1)
			return Math.min(
				...row.childData.map((childRow: TPositionPlayerValueRow) => {
					if ("teamBam" in childRow.combinedPositionPlayerValueData)
						return (
							childRow.combinedPositionPlayerValueData.teamBam?.levelRel?.sortOrder ??
							Number.MAX_SAFE_INTEGER
						);
					return Number.MAX_SAFE_INTEGER;
				})
			);
		return NULL_FILLER_TEXT;
	}
};

const PA_COLUMN = {
	id: "pa",
	value: "pa",
	label: "PA",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerValueRow) => row.combinedPositionPlayerValueData.pa
};

const HIT_RAA_COLUMN = {
	id: "hit_raa",
	value: "hit_raa",
	label: "Hit RAA",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerValueRow) => round10(row.combinedPositionPlayerValueData.battingRaa, -1),
	getSortValueFunction: (row: TPositionPlayerValueRow) => row.combinedPositionPlayerValueData.battingRaa,
	tooltip: TOOLTIP_PRO_POSITION_PLAYER_VALE_HIT_RAA
};

const HIT_RAA_COMPONENT_COLUMN = {
	id: "hit_raa_components",
	value: "hit_raa_components",
	label: <ExpandableRaaLabel totalRaaLabel="Hit RAA" componentRaaLabels={["vL", "vR"]} isExpanded />,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerValueRow) => {
		return (
			<ExpandableRaa
				totalRaa={row.combinedPositionPlayerValueData.battingRaa}
				componentRaas={[
					row.combinedPositionPlayerValueData.battingRaaVl,
					row.combinedPositionPlayerValueData.battingRaaVr
				]}
				isExpanded
			/>
		);
	},
	getSortValueFunction: (row: TPositionPlayerValueRow) => row.combinedPositionPlayerValueData.battingRaa,
	tooltip: TOOLTIP_PRO_POSITION_PLAYER_VALE_HIT_RAA
};

const RUN_RAA_COLUMN = {
	id: "run_raa",
	value: "run_raa",
	label: "Run RAA",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerValueRow) =>
		round10(
			row.combinedPositionPlayerValueData.baserunningRaa || row.combinedPositionPlayerValueData.stolenBaseRaa
				? (row.combinedPositionPlayerValueData.baserunningRaa ?? 0.0) +
						(row.combinedPositionPlayerValueData.stolenBaseRaa ?? 0.0)
				: null,
			-1
		),
	getSortValueFunction: (row: TPositionPlayerValueRow) =>
		row.combinedPositionPlayerValueData.baserunningRaa || row.combinedPositionPlayerValueData.stolenBaseRaa
			? (row.combinedPositionPlayerValueData.baserunningRaa ?? 0.0) +
			  (row.combinedPositionPlayerValueData.stolenBaseRaa ?? 0.0)
			: null,
	tooltip: TOOLTIP_PRO_POSITION_PLAYER_VALE_RUN_RAA
};

const RUN_RAA_COMPONENT_COLUMN = {
	id: "run_raa_components",
	value: "run_raa_components",
	label: <ExpandableRaaLabel totalRaaLabel="Run RAA" componentRaaLabels={["Baserunning", "SB"]} isExpanded />,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobile: true,
	getValueFunction: (row: TPositionPlayerValueRow) => {
		return (
			<ExpandableRaa
				totalRaa={
					row.combinedPositionPlayerValueData.baserunningRaa ||
					row.combinedPositionPlayerValueData.stolenBaseRaa
						? (row.combinedPositionPlayerValueData.baserunningRaa ?? 0.0) +
						  (row.combinedPositionPlayerValueData.stolenBaseRaa ?? 0.0)
						: undefined
				}
				componentRaas={[
					row.combinedPositionPlayerValueData.baserunningRaa,
					row.combinedPositionPlayerValueData.stolenBaseRaa
				]}
				isExpanded
			/>
		);
	},
	getSortValueFunction: (row: TPositionPlayerValueRow) => {
		return row.combinedPositionPlayerValueData.baserunningRaa || row.combinedPositionPlayerValueData.stolenBaseRaa
			? (row.combinedPositionPlayerValueData.baserunningRaa ?? 0.0) +
					(row.combinedPositionPlayerValueData.stolenBaseRaa ?? 0.0)
			: null;
	},
	tooltip: TOOLTIP_PRO_POSITION_PLAYER_VALE_RUN_RAA_COMPONENT
};

const FIELD_RAA_COLUMN = {
	id: "field_raa",
	value: "field_raa",
	label: "Field RAA",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerValueRow) =>
		round10(row.combinedPositionPlayerValueData.totalFieldingRaa, -1),
	getSortValueFunction: (row: TPositionPlayerValueRow) => row.combinedPositionPlayerValueData.totalFieldingRaa,
	tooltip: TOOLTIP_PRO_POSITION_PLAYER_VALE_FIELD_RAA
};

const FIELD_RAA_COMPONENT_COLUMN = {
	id: "field_raa_components",
	value: "field_raa_components",
	label: (
		<ExpandableRaaLabel
			totalRaaLabel="Fielding RAA"
			componentRaaLabels={["RNG", "DMG", "REC", "FRM", "THR", "BL"]}
			isExpanded
		/>
	),
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerValueRow) => {
		return (
			<ExpandableRaa
				totalRaa={row.combinedPositionPlayerValueData.totalFieldingRaa}
				componentRaas={[
					row.combinedPositionPlayerValueData.rangeRaa,
					row.combinedPositionPlayerValueData.damageRaa,
					row.combinedPositionPlayerValueData.receivingRaa,
					row.combinedPositionPlayerValueData.framingRaa,
					row.combinedPositionPlayerValueData.sbThrowingRaa,
					row.combinedPositionPlayerValueData.blockingRaa
				]}
				isExpanded
			/>
		);
	},
	getSortValueFunction: (row: TPositionPlayerValueRow) => row.combinedPositionPlayerValueData.totalFieldingRaa,
	tooltip: TOOLTIP_PRO_POSITION_PLAYER_VALE_FIELD_RAA_COMPONENT
};

const POS_ADJ_COLUMN = {
	id: "pos_adj",
	value: "pos_adj",
	label: "Pos Adj",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerValueRow) => round10(row.combinedPositionPlayerValueData.posAdj, -1),
	getSortValueFunction: (row: TPositionPlayerValueRow) => row.combinedPositionPlayerValueData.posAdj,
	tooltip: TOOLTIP_PRO_POSITION_PLAYER_VALE_POS_ADJ
};

const RAR_COLUMN = {
	id: "rar",
	value: "rar",
	label: "RAR",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPositionPlayerValueRow) => round10(row.combinedPositionPlayerValueData.totalRar, -1),
	getSortValueFunction: (row: TPositionPlayerValueRow) => row.combinedPositionPlayerValueData.totalRar,
	tooltip: TOOLTIP_PRO_POSITION_PLAYER_VALE_RAR
};

export const POSITION_PLAYER_VALUE_COLUMNS = (
	isComponentRaa: boolean
): Array<TColumn<TPositionPlayerValueRow, keyof TPositionPlayerValueRow>> => {
	return [
		SEASON_COLUMN,
		TEAM_COLUMN,
		LEVEL_COLUMN,
		PA_COLUMN,
		RAR_COLUMN,
		isComponentRaa ? HIT_RAA_COMPONENT_COLUMN : HIT_RAA_COLUMN,
		isComponentRaa ? FIELD_RAA_COMPONENT_COLUMN : FIELD_RAA_COLUMN,
		isComponentRaa ? RUN_RAA_COMPONENT_COLUMN : RUN_RAA_COLUMN,
		POS_ADJ_COLUMN
	];
};
