import React, { createContext, useEffect } from "react";
import { Interpreter, TypegenDisabled } from "xstate";
import { useInterpret } from "@xstate/react";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { $TSAnyRequired } from "utils/tsutils";

import PlayerPageIntlMachine, {
	SET_PLAYER_ID,
	SET_PROJECTIONS_SEASON_DATA,
	SET_CURRENT_SEASON_DATA,
	TPlayerPageIntlContext,
	TPlayerPageIntlEvent
} from "_react/playerpage/intl/_machine";

// xState says to use the `InterpreterFrom<typeof yourMachine>` type (which translates to Interpreter<...>), but its' a type mismatch
// Type 'TypegenDisabled' is not assignable to type 'ResolveTypegenMeta<TypegenDisabled, TPlayerPageAmaEvent, BaseActionObject, ServiceMap>'
// https://xstate.js.org/docs/recipes/react.html#global-state-react-context
// So using a workaround which explicitly creates the `Interpreter` type we expect
// https://github.com/statelyai/xstate/discussions/3130
export const PlayerPageIntlContext = createContext({
	playerPageIntlService: {} as Interpreter<
		TPlayerPageIntlContext,
		$TSAnyRequired,
		TPlayerPageIntlEvent,
		$TSAnyRequired,
		TypegenDisabled
	>
});

type PlayerPageIntlProviderProps = {
	playerId?: number;
	projectionsSeasonData?: [number, number, boolean];
	currentSeasonData?: { currentSeason: number | undefined; isFetching: boolean | undefined };
	shouldFetchData?: boolean;
	toast?: CreateToastFnReturn;
	children?: React.ReactNode;
};

const PlayerPageIntlProvider = ({
	playerId,
	projectionsSeasonData,
	currentSeasonData,
	shouldFetchData = true,
	toast,
	children
}: PlayerPageIntlProviderProps) => {
	const playerPageIntlService = useInterpret(
		PlayerPageIntlMachine(playerId, projectionsSeasonData, currentSeasonData, shouldFetchData, toast)
	);

	// Update the machine's context when prop changes
	useEffect(() => {
		playerPageIntlService.send({ type: SET_PLAYER_ID, value: playerId });
	}, [playerPageIntlService, playerId]);

	useEffect(() => {
		playerPageIntlService.send({ type: SET_PROJECTIONS_SEASON_DATA, value: projectionsSeasonData });
	}, [playerPageIntlService, projectionsSeasonData]);

	useEffect(() => {
		playerPageIntlService.send({ type: SET_CURRENT_SEASON_DATA, value: currentSeasonData });
	}, [playerPageIntlService, currentSeasonData]);

	return (
		<PlayerPageIntlContext.Provider value={{ playerPageIntlService }}>{children}</PlayerPageIntlContext.Provider>
	);
};

export default PlayerPageIntlProvider;
