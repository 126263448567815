import React, { useEffect } from "react";
import { SystemStyleObject, useToast } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";
import {
	Box,
	IconButton,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverCloseButton,
	PopoverHeader,
	PopoverBody,
	Stack,
	Text,
	useDisclosure
} from "@chakra-ui/react";

import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";

import createPlayerDupeIdMachine, {
	TPlayerDupeIdPopoverContext,
	TPlayerDupeIdPopoverData,
	SET_TARGET_ID,
	SET_PLAYER_DUPE_IDS,
	FETCHING_PLAYER_DUPE_IDS
} from "_react/shared/ui/data/modals/PlayerDupeIdPopover/_machine";
import { IPlayerDupeId } from "_react/shared/data_models/player/_types";
import { titleCase } from "utils/helpers";

type TPlayerDupeIdPopoverStyle = {
	iconButton?: SystemStyleObject;
};

type TPlayerDupeIdPopoverProps = {
	idType: string;
	targetId?: number;
	shouldFetchData?: boolean;
	data?: TPlayerDupeIdPopoverData;
	style?: TPlayerDupeIdPopoverStyle;
};

const PlayerDupeIdPopover = ({ targetId, idType, shouldFetchData, data, style }: TPlayerDupeIdPopoverProps) => {
	const { onOpen, onClose, isOpen } = useDisclosure();

	const toast = useToast();

	const [current, send] = useMachine(createPlayerDupeIdMachine(idType, targetId, shouldFetchData, data, toast));
	const { playerDupeIds } = current.context as TPlayerDupeIdPopoverContext;
	const fetchingPlayerDupeIds = current.matches(FETCHING_PLAYER_DUPE_IDS);

	useEffect(() => {
		send({ type: SET_TARGET_ID, data: targetId });
	}, [targetId, send]);

	useEffect(() => {
		if (data?.playerDupeIds !== playerDupeIds && !shouldFetchData) {
			send({ type: SET_PLAYER_DUPE_IDS, data: data?.playerDupeIds });
		}
	}, [data?.playerDupeIds, shouldFetchData, send, playerDupeIds]);

	return (
		<Popover placement="left" isOpen={isOpen} onOpen={onOpen} onClose={onClose} isLazy lazyBehavior="keepMounted">
			<PopoverTrigger>
				<IconButton
					aria-label={`Show Dupe IDs`}
					variant="ghost"
					h="fit-content"
					minWidth="fit-content"
					icon={<OutlineInfo />}
					sx={style?.iconButton}
				/>
			</PopoverTrigger>
			{/*
				Rocky header is zIndex 1000
			 */}
			<Box zIndex="modal">
				<PopoverContent>
					<PopoverArrow />
					<PopoverCloseButton />
					<PopoverHeader textAlign="left">{`Dupe ${titleCase(idType)} IDs for ${targetId}`}</PopoverHeader>
					<PopoverBody fontSize="xs" sx={{ maxHeight: "450px", overflow: "scroll" }}>
						<Stack spacing={3} direction="column">
							{fetchingPlayerDupeIds && <Text>Loading dupe ids...</Text>}
							{!fetchingPlayerDupeIds && !playerDupeIds?.length && <Text>No dupe ids available</Text>}
							{!fetchingPlayerDupeIds &&
								playerDupeIds?.map((dupeId: IPlayerDupeId) => (
									<Text key={dupeId.dupeId}>{dupeId.dupeId}</Text>
								))}
						</Stack>
					</PopoverBody>
				</PopoverContent>
			</Box>
		</Popover>
	);
};

export default PlayerDupeIdPopover;
