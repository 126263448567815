import styled from "@emotion/styled";

export const PitchTypeSelectContainer = styled.div({
	minWidth: "100px",
	maxWidth: "150px"
});

export const SeasonSelectContainer = styled.div({
	maxWidth: "230px",
	minWidth: "100px"
});
