import React, { useState } from "react";
import dayjs from "dayjs";

import { TOption } from "_react/inputs";
import { StyledSelect } from "_react/shared/selects";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { IconButton } from "_react/shared/legacy/ui/IconButton";
import Modal from "_react/shared/legacy/ui/Modal";
import Edit from "_react/shared/legacy/ui/icons/Edit";
import { Tooltip } from "_react/shared/legacy/ui/Tooltip";

import { getGoalSeasons } from "_react/playerplan/shared/_helpers";
import {
	GoalDetailsTable,
	GoalDetailsTr,
	GoalDetailsTd,
	GoalDetailsWhitespace,
	GoalDetailsCenteredContainer,
	GoalSeasonSelectContainer,
	MaxWidthStyledSelectStyle,
	GrayColorScheme,
	ActionButtonStyle
} from "_react/playerplan/shared/_styles";
import { TPlayerPlanGoal, TPlayerPlanTarget, TPlayerPlanGoalSplit, TTargetForm } from "_react/playerplan/shared/_types";
import { AddTargetSeasonsModal, TAddSeasonsModalStyle } from "_react/playerplan/shared/AddTargetSeasonsModal";
import { GoalDetailsSection } from "_react/playerplan/shared/GoalDetailsSection";

export type TGoalDetailsStyle = {
	addSeasonsModalStyle?: TAddSeasonsModalStyle;
};

export type TGoalDetailsProps = {
	goal: TPlayerPlanGoal;
	selectedGoalSeason: number;
	setSelectedGoalSeason?: (selectedGoalSeason: number) => void;
	secondaryGoal?: TPlayerPlanGoal;
	isSeasonValue: boolean;
	colorSchemeGroup?: ColorSchemeGroup;
	canAddTierTwo?: boolean;
	setIsSecondaryGoalModalOpen: (value: boolean) => void;
	setModalPrimaryGoal: (goal: TPlayerPlanGoal | null) => void;
	addTarget?: (targetForm: TTargetForm) => void;
	isAddingTarget?: boolean;
	isGoalActionsDisabled?: boolean;
	isEditable?: boolean;
	isSeasonsEditable?: boolean;
	canAddTierTwoStrength?: boolean;
};

export const GoalDetails = ({
	goal,
	selectedGoalSeason,
	setSelectedGoalSeason,
	secondaryGoal,
	isSeasonValue,
	colorSchemeGroup = defaultColorScheme,
	canAddTierTwo = false,
	setIsSecondaryGoalModalOpen,
	setModalPrimaryGoal,
	addTarget,
	isAddingTarget = false,
	isGoalActionsDisabled = true,
	isEditable = true,
	isSeasonsEditable = false,
	canAddTierTwoStrength = false
}: TGoalDetailsProps) => {
	const [isSeasonsModalOpen, setIsSeasonsModalOpen] = useState<boolean>(false);
	const activeTarget = goal.playerPlanTargets.find(
		(t: TPlayerPlanTarget) => t.isActive && t.season === selectedGoalSeason
	);
	const activeSecondaryTarget = secondaryGoal?.playerPlanTargets.find(
		(t: TPlayerPlanTarget) => t.isActive && t.season === selectedGoalSeason
	);
	const filteredSplits = goal?.playerPlanSplits?.filter((goalSplit: TPlayerPlanGoalSplit) => !goalSplit.isDeleted);
	const goalSeasons: number[] | null = getGoalSeasons(goal);
	const goalSeasonOpts: Array<TOption<number>> | undefined = goalSeasons?.map((goalSeason: number) => {
		return { label: `${goalSeason}`, value: goalSeason };
	});

	const additionalSeasonOpts = [dayjs().year(), dayjs().year() + 1].filter(
		(season: number) => goalSeasons == null || !goalSeasons.includes(season)
	);

	return (
		<>
			<GoalDetailsTable>
				<tbody>
					<GoalDetailsTr>
						<GoalSeasonSelectContainer>
							<StyledSelect
								isDisabled={!goalSeasonOpts?.length || goalSeasonOpts.length <= 1}
								styles={MaxWidthStyledSelectStyle}
								options={goalSeasonOpts}
								value={{ value: selectedGoalSeason, label: selectedGoalSeason }}
								onChange={(opt: TOption<number>) => {
									if (setSelectedGoalSeason) setSelectedGoalSeason(opt.value);
								}}
								placeholder="Select season..."
							></StyledSelect>
							{isSeasonsEditable && additionalSeasonOpts.length > 0 && (
								<Tooltip title={`Add Additional Season`} colorScheme={colorSchemeGroup.primary}>
									<IconButton
										onClick={() => {
											setIsSeasonsModalOpen(true);
										}}
										name="edit-action"
										colorScheme={GrayColorScheme}
										style={ActionButtonStyle}
									>
										<Edit fill="white" />
									</IconButton>
								</Tooltip>
							)}
						</GoalSeasonSelectContainer>
					</GoalDetailsTr>
					<GoalDetailsSection
						goal={goal}
						goalSeason={selectedGoalSeason}
						target={activeTarget}
						tier={1}
						isSeasonValue={isSeasonValue}
						colorSchemeGroup={colorSchemeGroup}
						isGoalActionsDisabled={isGoalActionsDisabled}
						isEditable={false}
					/>

					<GoalDetailsTr>
						<GoalDetailsTd>
							<GoalDetailsWhitespace></GoalDetailsWhitespace>
						</GoalDetailsTd>
					</GoalDetailsTr>

					<GoalDetailsSection
						goal={secondaryGoal}
						goalSeason={selectedGoalSeason}
						target={activeSecondaryTarget}
						tier={2}
						isSeasonValue={isSeasonValue}
						colorSchemeGroup={colorSchemeGroup}
						canAddTierTwo={canAddTierTwo}
						setIsSecondaryGoalModalOpen={setIsSecondaryGoalModalOpen}
						setModalPrimaryGoal={setModalPrimaryGoal}
						isGoalActionsDisabled={isGoalActionsDisabled}
						isEditable={isEditable}
						primaryGoal={goal}
						canAddTierTwoStrength={canAddTierTwoStrength}
					/>
					{filteredSplits?.length ? (
						<>
							<GoalDetailsTr>
								<GoalDetailsTd>
									<GoalDetailsWhitespace></GoalDetailsWhitespace>
								</GoalDetailsTd>
							</GoalDetailsTr>

							<GoalDetailsTr>
								<GoalDetailsTd colSpan={2}>
									<GoalDetailsCenteredContainer>
										<b>Splits:</b>{" "}
										{` ${filteredSplits
											.map((goalSplit: TPlayerPlanGoalSplit) => goalSplit.playerPlanSplit?.label)
											.join(", ")}`}
									</GoalDetailsCenteredContainer>
								</GoalDetailsTd>
							</GoalDetailsTr>
						</>
					) : (
						undefined
					)}
				</tbody>
			</GoalDetailsTable>
			<Modal
				isOpen={isSeasonsModalOpen}
				onRequestClose={() => {
					setIsSeasonsModalOpen(false);
				}}
				showX
			>
				{addTarget && (
					<AddTargetSeasonsModal
						goal={goal}
						secondaryGoal={secondaryGoal}
						additionalSeasonOpts={additionalSeasonOpts}
						addTarget={addTarget}
						isAddingTargets={isAddingTarget}
						isGoalActionsDisabled={isGoalActionsDisabled}
						setIsSeasonsModalOpen={setIsSeasonsModalOpen}
						colorSchemeGroup={colorSchemeGroup}
					/>
				)}
			</Modal>
		</>
	);
};
