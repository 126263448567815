import dayjs from "dayjs";

import { IManualPitchTypeMapBySeasonPitchType } from "_react/shared/data_models/manual_pitch_type_map_byseason/_types";

export const parsePitchTypeLabel = (value: IManualPitchTypeMapBySeasonPitchType): string => {
	return value.label ?? value.value;
};

export const getSeasonOptions = (
	maxSeason: number | undefined = dayjs().year(),
	minSeason: number | undefined = dayjs().year() - 2
) => {
	// Default to at least showing the current year and previous 2 years
	if (minSeason > maxSeason) minSeason = maxSeason - 2;
	return Array.from({ length: maxSeason - minSeason + 1 }, (_, index) => {
		const season = maxSeason - index;
		return { label: `${season}`, value: season };
	});
};
