import React from "react";

import { round10 } from "_react/shared/_helpers/numbers";
import { getLevelDisplayFromLevels } from "_react/shared/_helpers/stats";
import { TColumn, TParentColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { VALUE_TYPE_NUMBER, VALUE_TYPE_STRING } from "_react/shared/ui/presentation/components/Table/_constants";
import { TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import ExpandableRaa from "_react/shared/ui/data/tables/shared/ExpandableRaa";
import ExpandableRaaLabel from "_react/shared/ui/data/tables/shared/ExpandableRaaLabel";
import TeamColumn from "_react/shared/ui/data/tables/shared/TeamColumn";
import {
	TOOLTIP_PRO_PITCHER_VALUE_RAR,
	TOOLTIP_PRO_PITCHER_VALUE_PITCHING_RAA,
	TOOLTIP_PRO_PITCHER_VALUE_FIELDING_RAA,
	TOOLTIP_PRO_PITCHER_VALUE_RUN_GAME_RAA
} from "_react/shared/_constants/tooltips";

import { IRAAPitcherRole, SP, RP } from "_react/shared/data_models/raa/_types";
import { getLevelsFromRow } from "_react/shared/ui/data/tables/PitcherValueTable/_helpers";
import { TPitcherValueRow } from "_react/shared/ui/data/tables/PitcherValueTable/_types";

// Non-Column Constants

export const DEFAULT_NUM_SEASONS = 3;

// Table Columns

export const NULL_FILLER_TEXT = "";
export const NULL_FILLER_TEXT_COMPONENTS = "-";

const SEASON_COLUMN = {
	id: "season",
	value: "season",
	label: "Season",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherValueRow) => row.pitcherValue.season
};

const TEAM_COLUMN = {
	id: "team",
	value: "team",
	label: "Team",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TPitcherValueRow) => {
		if ("teamBam" in row.pitcherValue)
			return <TeamColumn teamBam={row.pitcherValue.teamBam} childRowCount={row.childData?.length} />;
		return <TeamColumn childRowCount={row.childData?.length} />;
	}
};

const LEVEL_COLUMN = {
	id: "level",
	value: "level",
	label: "Level",
	isMobile: true,
	valueType: VALUE_TYPE_STRING as TValueType,
	getValueFunction: (row: TPitcherValueRow) => getLevelDisplayFromLevels(getLevelsFromRow(row), NULL_FILLER_TEXT),
	getSortValueFunction: (row: TPitcherValueRow) => {
		// Child Rows
		if ("teamBam" in row.pitcherValue)
			return row.pitcherValue.teamBam?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER;
		// Parent Rows with nested data - highest level if multiple levels
		if (row.childData && row.childData.length > 1)
			return Math.min(
				...row.childData.map((childRow: TPitcherValueRow) => {
					if ("teamBam" in childRow.pitcherValue)
						return childRow.pitcherValue.teamBam?.levelRel?.sortOrder ?? Number.MAX_SAFE_INTEGER;
					return Number.MAX_SAFE_INTEGER;
				})
			);
		return NULL_FILLER_TEXT;
	}
};

const TOTAL_BATTERS_FACED_COLUMN = {
	id: "tbf",
	value: "tbf",
	label: "TBF",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherValueRow) => (row.pitcherValue.rp?.tbf ?? 0) + (row.pitcherValue.sp?.tbf ?? 0)
};

const RAR_COLUMN = (role: IRAAPitcherRole, isExpanded: boolean) => ({
	id: `${role}Rar`,
	value: `${role}Rar`,
	label: `${isExpanded ? "" : `${role.toUpperCase()} `}RAR`,
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherValueRow) => round10(row.pitcherValue[role]?.totalRar, -1),
	getSortValueFunction: (row: TPitcherValueRow) => row.pitcherValue[role]?.totalRar ?? null,
	tooltip: TOOLTIP_PRO_PITCHER_VALUE_RAR
});

const PITCHING_RAA_COLUMN = (role: IRAAPitcherRole) => ({
	id: `${role}PitchingRaa`,
	value: `${role}PitchingRaa`,
	label: "Pitching RAA",
	isMobile: true,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	getValueFunction: (row: TPitcherValueRow) => round10(row.pitcherValue[role]?.pitchingRaa, -1),
	getSortValueFunction: (row: TPitcherValueRow) => row.pitcherValue[role]?.pitchingRaa ?? null,
	tooltip: TOOLTIP_PRO_PITCHER_VALUE_PITCHING_RAA
});

const FIELDING_RAA_COLUMN = (role: IRAAPitcherRole) => ({
	id: `${role}FieldingRaa`,
	value: `${role}FieldingRaa`,
	label: <ExpandableRaaLabel totalRaaLabel="Fielding RAA" componentRaaLabels={["DMG", "RNG"]} isExpanded />,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TPitcherValueRow>) => {
		return (
			data.find((row: TPitcherValueRow) => {
				return row.pitcherValue[role]?.rangeRaa || row.pitcherValue[role]?.damageRaa;
			}) !== undefined
		);
	},
	getValueFunction: (row: TPitcherValueRow) => {
		return (
			<ExpandableRaa
				totalRaa={
					row.pitcherValue[role]?.rangeRaa || row.pitcherValue[role]?.damageRaa
						? (row.pitcherValue[role]?.rangeRaa ?? 0.0) + (row.pitcherValue[role]?.damageRaa ?? 0.0)
						: undefined
				}
				componentRaas={[row.pitcherValue[role]?.damageRaa ?? 0, row.pitcherValue[role]?.rangeRaa ?? 0]}
				isExpanded
			/>
		);
	},
	getSortValueFunction: (row: TPitcherValueRow) => {
		if (row.pitcherValue[role]?.rangeRaa || row.pitcherValue[role]?.damageRaa)
			return (row.pitcherValue[role]?.rangeRaa ?? 0.0) + (row.pitcherValue[role]?.damageRaa ?? 0.0);
		return null;
	},
	tooltip: TOOLTIP_PRO_PITCHER_VALUE_FIELDING_RAA
});

const RUN_GAME_RAA_COLUMN = (role: IRAAPitcherRole) => ({
	id: `${role}RunGameRaa`,
	value: `${role}RunGameRaa`,
	label: <ExpandableRaaLabel totalRaaLabel="Run Game RAA" componentRaaLabels={["SB", "PO", "PP"]} isExpanded />,
	valueType: VALUE_TYPE_NUMBER as TValueType,
	isMobileFunction: (data: Array<TPitcherValueRow>) => {
		return (
			data.find((row: TPitcherValueRow) => {
				return (
					row.pitcherValue[role]?.pitcherSbRaa ||
					row.pitcherValue[role]?.pickoffRaa ||
					row.pitcherValue[role]?.passedPitchRaa
				);
			}) !== undefined
		);
	},
	getValueFunction: (row: TPitcherValueRow) => {
		return (
			<ExpandableRaa
				totalRaa={
					row.pitcherValue[role]?.pitcherSbRaa ||
					row.pitcherValue[role]?.pickoffRaa ||
					row.pitcherValue[role]?.passedPitchRaa
						? (row.pitcherValue[role]?.pitcherSbRaa ?? 0.0) +
						  (row.pitcherValue[role]?.pickoffRaa ?? 0.0) +
						  (row.pitcherValue[role]?.passedPitchRaa ?? 0.0)
						: undefined
				}
				componentRaas={[
					row.pitcherValue[role]?.pitcherSbRaa ?? 0,
					row.pitcherValue[role]?.pickoffRaa ?? 0,
					row.pitcherValue[role]?.passedPitchRaa ?? 0
				]}
				isExpanded
			/>
		);
	},
	getSortValueFunction: (row: TPitcherValueRow) => {
		if (
			row.pitcherValue[role]?.pitcherSbRaa ||
			row.pitcherValue[role]?.pickoffRaa ||
			row.pitcherValue[role]?.passedPitchRaa
		)
			return (
				(row.pitcherValue[role]?.pitcherSbRaa ?? 0.0) +
				(row.pitcherValue[role]?.pickoffRaa ?? 0.0) +
				(row.pitcherValue[role]?.passedPitchRaa ?? 0.0)
			);
		return null;
	},
	tooltip: TOOLTIP_PRO_PITCHER_VALUE_RUN_GAME_RAA
});

export const PARENT_COLUMNS: Array<TParentColumn> = [
	{ label: "", id: "parentColumns", childColumnIds: ["season", "team", "level", "tbf"] },
	{ label: "SP", id: SP, childColumnIds: [`${SP}Rar`, `${SP}PitchingRaa`, `${SP}FieldingRaa`, `${SP}RunGameRaa`] },
	{ label: "RP", id: RP, childColumnIds: [`${RP}Rar`, `${RP}PitchingRaa`, `${RP}FieldingRaa`, `${RP}RunGameRaa`] }
];

export const PITCHER_VALUE_COLUMNS = (
	isExpanded: boolean
): Array<TColumn<TPitcherValueRow, keyof TPitcherValueRow>> => {
	if (isExpanded)
		return [
			SEASON_COLUMN,
			TEAM_COLUMN,
			LEVEL_COLUMN,
			TOTAL_BATTERS_FACED_COLUMN,
			RAR_COLUMN(SP, isExpanded),
			PITCHING_RAA_COLUMN(SP),
			FIELDING_RAA_COLUMN(SP),
			RUN_GAME_RAA_COLUMN(SP),
			RAR_COLUMN(RP, isExpanded),
			PITCHING_RAA_COLUMN(RP),
			FIELDING_RAA_COLUMN(RP),
			RUN_GAME_RAA_COLUMN(RP)
		];
	return [
		SEASON_COLUMN,
		TEAM_COLUMN,
		LEVEL_COLUMN,
		TOTAL_BATTERS_FACED_COLUMN,
		RAR_COLUMN(SP, isExpanded),
		RAR_COLUMN(RP, isExpanded)
	];
};
