import React, { useEffect, useMemo } from "react";
import { Box, HStack, VStack, useToast, useBreakpointValue } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import {
	TIntlProspectValueSimilarPlayersModelName,
	TIntlProspectValueSimilarPlayersPlayerType
} from "_react/shared/data_models/phred/_network";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { INTL_SURPLUS_VALUE_SIMILAR_PLAYERS_COLUMNS } from "_react/shared/ui/data/tables/IntlSurplusValueSimilarPlayersTable/_constants";
import IntlSurplusValueSimilarPlayersTableMachine, {
	FETCHING_SIMILAR_PLAYERS,
	SET_MODEL_NAME,
	SET_PLAYER_ID,
	SET_PLAYER_TYPE,
	SET_POSITION_GROUP
} from "_react/shared/ui/data/tables/IntlSurplusValueSimilarPlayersTable/_machine";
import {
	TIntlSurplusValueSimilarPlayersTableData,
	TIntlSurplusValueSimilarPlayer
} from "_react/shared/ui/data/tables/IntlSurplusValueSimilarPlayersTable/_types";
import { parseSimilarPlayersData } from "_react/shared/ui/data/tables/IntlSurplusValueSimilarPlayersTable/_helpers";

type TIntlSurplusValueSimilarPlayersTableProps = {
	title?: string;
	playerId?: number;
	playerType?: TIntlProspectValueSimilarPlayersPlayerType;
	modelName?: TIntlProspectValueSimilarPlayersModelName;
	positionGroup?: string;
	data?: TIntlSurplusValueSimilarPlayersTableData;
	shouldFetchData?: boolean;
	columns?: Array<string>;
	tableProps?: TTableProps<TIntlSurplusValueSimilarPlayer, keyof TIntlSurplusValueSimilarPlayer>;
};

const IntlSurplusValueSimilarPlayersTable = ({
	title,
	playerId,
	playerType,
	modelName,
	positionGroup,
	data,
	shouldFetchData = true,
	columns,
	tableProps
}: TIntlSurplusValueSimilarPlayersTableProps) => {
	const toast = useToast();
	const [current, send] = useMachine(
		IntlSurplusValueSimilarPlayersTableMachine(
			playerId,
			playerType,
			modelName,
			positionGroup,
			data,
			shouldFetchData,
			toast
		)
	);
	const context = current.context;
	const { similarPlayers } = context;
	const isFetchingSimilarPlayers = current.matches(FETCHING_SIMILAR_PLAYERS);
	const isLoading = shouldFetchData ? isFetchingSimilarPlayers : data?.isLoading;

	useEffect(() => {
		send({ type: SET_PLAYER_ID, value: playerId });
	}, [playerId, send]);

	useEffect(() => {
		send({ type: SET_PLAYER_TYPE, value: playerType });
	}, [playerType, send]);

	useEffect(() => {
		send({ type: SET_POSITION_GROUP, value: positionGroup });
	}, [positionGroup, send]);

	useEffect(() => {
		send({ type: SET_MODEL_NAME, value: modelName });
	}, [modelName, send]);

	const parsedSimilarPlayers: Array<TIntlSurplusValueSimilarPlayer> | undefined = useMemo(() => {
		return parseSimilarPlayersData(similarPlayers);
	}, [similarPlayers]);

	const filteredColumns = useMemo(() => {
		if (!columns) return INTL_SURPLUS_VALUE_SIMILAR_PLAYERS_COLUMNS;
		return INTL_SURPLUS_VALUE_SIMILAR_PLAYERS_COLUMNS.filter(
			(col: TColumn<TIntlSurplusValueSimilarPlayer, keyof TIntlSurplusValueSimilarPlayer>) =>
				columns.includes(col.value)
		);
	}, [columns]);

	const isMobile = useBreakpointValue({
		base: true,
		xl: false
	});

	return (
		<VStack alignItems="start">
			<HStack w="100%" justify="space-between">
				<HStack>
					{title && (
						<Box fontFamily="heading" fontSize="md" fontWeight="bold">
							{title}
						</Box>
					)}
				</HStack>
			</HStack>
			<Table<TIntlSurplusValueSimilarPlayer, keyof TIntlSurplusValueSimilarPlayer>
				columns={filteredColumns}
				data={parsedSimilarPlayers}
				isLoadingData={isLoading}
				isMobile={isMobile}
				emptyDataDisplayText={"No Similar Players"}
				highlightIndexFunction={(data: Array<TIntlSurplusValueSimilarPlayer>) => {
					return data.findIndex((player: TIntlSurplusValueSimilarPlayer) => player?.playerId === playerId);
				}}
				style={{
					table: { tableLayout: "fixed" }
				}}
				{...tableProps}
			/>
		</VStack>
	);
};

export default IntlSurplusValueSimilarPlayersTable;
