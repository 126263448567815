import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import { GAME_TYPE_REGULAR_SEASON, PLAYING_LEVEL_PRO } from "_react/shared/data_models/raa/_constants";
import {
	fetchPlayerSeasonRAARatePositionPlayer,
	fetchPlayerSeasonRAARatePositionPlayerByTeam,
	fetchPlayerSeasonRAAPositionPlayer,
	fetchPlayerSeasonRAAPositionPlayerByTeam
} from "_react/shared/data_models/raa/_network";
import { IRAAPositionPlayer, IRAAPositionPlayerByTeam } from "_react/shared/data_models/raa/_types";

import {
	TPositionPlayerValueTableData,
	TPositionPlayerValueTableDataSource,
	RATE
} from "_react/shared/ui/data/tables/PositionPlayerValueTable/_types";

export const PLAYER_SEASON_RAA_POSITION_PLAYER_CANCEL_SOURCE = "playerSeasonRaaPositionPlayer";
export const PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE = "playerSeasonRaaPositionPlayerByTeam";
export const PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_CANCEL_SOURCE = "playerSeasonRaaRatePositionPlayer";
export const PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE = "playerSeasonRaaRatePositionPlayerByTeam";

export type TPositionPlayerValueTableCancelSource = {
	[PLAYER_SEASON_RAA_POSITION_PLAYER_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TPositionPlayerValueTableFilters = {
	levels?: Array<string>;
	maxSeason?: number;
	minSeason?: number;
};

export type TPositionPlayerValueTableContext = {
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	filters: TPositionPlayerValueTableFilters;
	playerSeasonRaaPositionPlayer?: Array<IRAAPositionPlayer>;
	playerSeasonRaaPositionPlayerByTeam?: Array<IRAAPositionPlayerByTeam>;
	playerSeasonRaaRatePositionPlayer?: Array<IRAAPositionPlayer>;
	playerSeasonRaaRatePositionPlayerByTeam?: Array<IRAAPositionPlayerByTeam>;
	dataSource: TPositionPlayerValueTableDataSource;
	cancelSources: TPositionPlayerValueTableCancelSource;
	isComponentRaa: boolean;
	toast?: CreateToastFnReturn;
};

interface IPositionPlayerValueTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches all Position Player RAA by player season
				playerSeasonRaaPositionPlayer: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all Position Player RAA by player season team
				playerSeasonRaaPositionPlayerByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all Position Player Rate RAA by player season
				playerSeasonRaaRatePositionPlayer: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all Position Player Rate RAA by player season team
				playerSeasonRaaRatePositionPlayerByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PLAYER_SEASON_RAA_POSITION_PLAYER = "SET_PLAYER_SEASON_RAA_POSITION_PLAYER";
export const SET_PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM = "SET_PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM";
export const SET_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER = "SET_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER";
export const SET_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM = "SET_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM";
export const SET_IS_COMPONENT_RAA = "SET_IS_COMPONENT_RAA";
export const SET_DATA_SOURCE = "SET_DATA_SOURCE";
export const SET_FILTERS = "SET_FILTERS";

export const FETCHING_PLAYER_SEASON_RAA_POSITION_PLAYER = {
	initialized: { playerSeasonRaaPositionPlayer: "fetching" }
};
export const FETCHING_PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM = {
	initialized: { playerSeasonRaaPositionPlayerByTeam: "fetching" }
};
export const FETCHING_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER = {
	initialized: { playerSeasonRaaRatePositionPlayer: "fetching" }
};
export const FETCHING_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM = {
	initialized: { playerSeasonRaaRatePositionPlayerByTeam: "fetching" }
};
const FETCH_PLAYER_SEASON_RAA_POSITION_PLAYER_DONE = "done.invoke.fetchPlayerSeasonRaaPositionPlayer:invocation[0]";
const FETCH_PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM_DONE =
	"done.invoke.fetchPlayerSeasonRaaPositionPlayerByTeam:invocation[0]";
const FETCH_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_DONE =
	"done.invoke.fetchPlayerSeasonRaaRatePositionPlayer:invocation[0]";
const FETCH_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM_DONE =
	"done.invoke.fetchPlayerSeasonRaaRatePositionPlayerByTeam:invocation[0]";

const FETCH_PLAYER_SEASON_RAA_POSITION_PLAYER_ERROR = "error.platform.fetchPlayerSeasonRaaPositionPlayer:invocation[0]";
const FETCH_PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM_ERROR =
	"error.platform.fetchPlayerSeasonRaaPositionPlayerByTeam:invocation[0]";
const FETCH_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_ERROR =
	"error.platform.fetchPlayerSeasonRaaRatePositionPlayer:invocation[0]";
const FETCH_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM_ERROR =
	"error.platform.fetchPlayerSeasonRaaRatePositionPlayerByTeam:invocation[0]";

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; data: number | undefined };
type TSetPlayerSeasonRaaPositionPlayerEvent = {
	type: typeof SET_PLAYER_SEASON_RAA_POSITION_PLAYER;
	data: Array<IRAAPositionPlayer> | undefined;
};
type TSetPlayerSeasonRaaPositionPlayerByTeamEvent = {
	type: typeof SET_PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM;
	data: Array<IRAAPositionPlayerByTeam> | undefined;
};
type TSetPlayerSeasonRaaRatePositionPlayerEvent = {
	type: typeof SET_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER;
	data: Array<IRAAPositionPlayer> | undefined;
};
type TSetPlayerSeasonRaaRatePositionPlayerByTeamEvent = {
	type: typeof SET_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM;
	data: Array<IRAAPositionPlayerByTeam> | undefined;
};
type TSetFiltersEvent = {
	type: typeof SET_FILTERS;
	data: TPositionPlayerValueTableFilters;
};
type TSetIsComponentRaaEvent = {
	type: typeof SET_IS_COMPONENT_RAA;
	data: boolean;
};
type TSetDataSourceEvent = {
	type: typeof SET_DATA_SOURCE;
	data: TPositionPlayerValueTableDataSource;
};

type TFetchPlayerSeasonRaaPositionPlayerEvent = {
	type: typeof FETCH_PLAYER_SEASON_RAA_POSITION_PLAYER_DONE;
	data: Array<IRAAPositionPlayer> | undefined;
};
type TFetchPlayerSeasonRaaPositionPlayerByTeamEvent = {
	type: typeof FETCH_PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM_DONE;
	data: Array<IRAAPositionPlayerByTeam> | undefined;
};
type TFetchPlayerSeasonRaaRatePositionPlayerEvent = {
	type: typeof FETCH_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_DONE;
	data: Array<IRAAPositionPlayer> | undefined;
};
type TFetchPlayerSeasonRaaRatePositionPlayerByTeamEvent = {
	type: typeof FETCH_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM_DONE;
	data: Array<IRAAPositionPlayerByTeam> | undefined;
};

type TFetchPlayerSeasonRaaPositionPlayerErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_RAA_POSITION_PLAYER_ERROR;
	data?: AxiosError | Error;
};
type TFetchPlayerSeasonRaaPositionPlayerByTeamErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM_ERROR;
	data?: AxiosError | Error;
};
type TFetchPlayerSeasonRaaRatePositionPlayerErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_ERROR;
	data?: AxiosError | Error;
};
type TFetchPlayerSeasonRaaRatePositionPlayerByTeamErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM_ERROR;
	data?: AxiosError | Error;
};

type TPositionPlayerValueTableEvent =
	| TSetPlayerIdEvent
	| TSetPlayerSeasonRaaPositionPlayerEvent
	| TSetPlayerSeasonRaaPositionPlayerByTeamEvent
	| TSetPlayerSeasonRaaRatePositionPlayerEvent
	| TSetPlayerSeasonRaaRatePositionPlayerByTeamEvent
	| TSetFiltersEvent
	| TSetIsComponentRaaEvent
	| TSetDataSourceEvent
	| TFetchPlayerSeasonRaaPositionPlayerEvent
	| TFetchPlayerSeasonRaaPositionPlayerByTeamEvent
	| TFetchPlayerSeasonRaaRatePositionPlayerEvent
	| TFetchPlayerSeasonRaaRatePositionPlayerByTeamEvent
	| TFetchPlayerSeasonRaaPositionPlayerErrorEvent
	| TFetchPlayerSeasonRaaPositionPlayerByTeamErrorEvent
	| TFetchPlayerSeasonRaaRatePositionPlayerErrorEvent
	| TFetchPlayerSeasonRaaRatePositionPlayerByTeamErrorEvent;

export type TPositionPlayerValueTableSend = Interpreter<
	TPositionPlayerValueTableContext,
	IPositionPlayerValueTableStateSchema,
	TPositionPlayerValueTableEvent
>["send"];

const PositionPlayerValueTableMachine = (
	playerIdProp?: number,
	data?: TPositionPlayerValueTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TPositionPlayerValueTableContext, IPositionPlayerValueTableStateSchema, TPositionPlayerValueTableEvent>(
		{
			id: "positionPlayerValueTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchDataProp,
				filters: {
					levels: undefined
				},
				playerSeasonRaaPositionPlayer: data?.playerSeasonRaaPositionPlayer,
				playerSeasonRaaPositionPlayerByTeam: data?.playerSeasonRaaPositionPlayerByTeam,
				playerSeasonRaaRatePositionPlayer: data?.playerSeasonRaaRatePositionPlayer,
				playerSeasonRaaRatePositionPlayerByTeam: data?.playerSeasonRaaRatePositionPlayerByTeam,
				dataSource: RATE,
				cancelSources: {},
				isComponentRaa: false,
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PLAYER_ID]: {
							actions: [
								"setPlayerId",
								"clearPlayerSeasonRaaPositionPlayer",
								"clearPlayerSeasonRaaPositionPlayerByTeam",
								"clearPlayerSeasonRaaRatePositionPlayer",
								"clearPlayerSeasonRaaRatePositionPlayerByTeam"
							],
							cond: "shouldSetPlayerId"
						},
						[SET_PLAYER_SEASON_RAA_POSITION_PLAYER]: { actions: "setPlayerSeasonRaaPositionPlayer" },
						[SET_PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM]: {
							actions: "setPlayerSeasonRaaPositionPlayerByTeam"
						},
						[SET_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER]: {
							actions: "setPlayerSeasonRaaRatePositionPlayer"
						},
						[SET_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM]: {
							actions: "setPlayerSeasonRaaRatePositionPlayerByTeam"
						},
						[SET_FILTERS]: { actions: "setFilters" },
						[SET_DATA_SOURCE]: { actions: "setDataSource" },
						[SET_IS_COMPONENT_RAA]: { actions: "setIsComponentRaa" }
					},
					states: {
						playerSeasonRaaPositionPlayer: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonRaaPositionPlayer",
												cond: "shouldFetchPlayerSeasonRaaPositionPlayer"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonRaaPositionPlayer",
									entry: ["refreshPlayerSeasonRaaPositionPlayerCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonRaaPositionPlayer",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonRaaPositionPlayerSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonRaaPositionPlayerErrored"
										}
									}
								}
							}
						},
						playerSeasonRaaPositionPlayerByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonRaaPositionPlayerByTeam",
												cond: "shouldFetchPlayerSeasonRaaPositionPlayerByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonRaaPositionPlayerByTeam",
									entry: ["refreshPlayerSeasonRaaPositionPlayerByTeamCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonRaaPositionPlayerByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonRaaPositionPlayerByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonRaaPositionPlayerByTeamErrored"
										}
									}
								}
							}
						},
						playerSeasonRaaRatePositionPlayer: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonRaaRatePositionPlayer",
												cond: "shouldFetchPlayerSeasonRaaRatePositionPlayer"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonRaaRatePositionPlayer",
									entry: ["refreshPlayerSeasonRaaRatePositionPlayerCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonRaaRatePositionPlayer",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonRaaRatePositionPlayerSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonRaaRatePositionPlayerErrored"
										}
									}
								}
							}
						},
						playerSeasonRaaRatePositionPlayerByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonRaaRatePositionPlayerByTeam",
												cond: "shouldFetchPlayerSeasonRaaRatePositionPlayerByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonRaaRatePositionPlayerByTeam",
									entry: ["refreshPlayerSeasonRaaRatePositionPlayerByTeamCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonRaaRatePositionPlayerByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonRaaRatePositionPlayerByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonRaaRatePositionPlayerByTeamErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (context: TPositionPlayerValueTableContext, event: TPositionPlayerValueTableEvent) =>
					event.type === SET_PLAYER_ID && context.playerId !== event.data,
				shouldFetchPlayerSeasonRaaPositionPlayer: (
					context: TPositionPlayerValueTableContext,
					_event: TPositionPlayerValueTableEvent
				) =>
					context.playerSeasonRaaPositionPlayer === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPlayerSeasonRaaPositionPlayerByTeam: (
					context: TPositionPlayerValueTableContext,
					_event: TPositionPlayerValueTableEvent
				) =>
					context.playerSeasonRaaPositionPlayerByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPlayerSeasonRaaRatePositionPlayer: (
					context: TPositionPlayerValueTableContext,
					_event: TPositionPlayerValueTableEvent
				) =>
					context.playerSeasonRaaRatePositionPlayer === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPlayerSeasonRaaRatePositionPlayerByTeam: (
					context: TPositionPlayerValueTableContext,
					_event: TPositionPlayerValueTableEvent
				) =>
					context.playerSeasonRaaRatePositionPlayerByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true
			},
			actions: {
				clearPlayerSeasonRaaPositionPlayer: assign<
					TPositionPlayerValueTableContext,
					TPositionPlayerValueTableEvent
				>({
					playerSeasonRaaPositionPlayer: (
						_context: TPositionPlayerValueTableContext,
						_event: TPositionPlayerValueTableEvent
					) => undefined,
					cancelSources: (
						context: TPositionPlayerValueTableContext,
						_event: TPositionPlayerValueTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				clearPlayerSeasonRaaPositionPlayerByTeam: assign<
					TPositionPlayerValueTableContext,
					TPositionPlayerValueTableEvent
				>({
					playerSeasonRaaPositionPlayerByTeam: (
						_context: TPositionPlayerValueTableContext,
						_event: TPositionPlayerValueTableEvent
					) => undefined,
					cancelSources: (
						context: TPositionPlayerValueTableContext,
						_event: TPositionPlayerValueTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				clearPlayerSeasonRaaRatePositionPlayer: assign<
					TPositionPlayerValueTableContext,
					TPositionPlayerValueTableEvent
				>({
					playerSeasonRaaRatePositionPlayer: (
						_context: TPositionPlayerValueTableContext,
						_event: TPositionPlayerValueTableEvent
					) => undefined,
					cancelSources: (
						context: TPositionPlayerValueTableContext,
						_event: TPositionPlayerValueTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				clearPlayerSeasonRaaRatePositionPlayerByTeam: assign<
					TPositionPlayerValueTableContext,
					TPositionPlayerValueTableEvent
				>({
					playerSeasonRaaRatePositionPlayerByTeam: (
						_context: TPositionPlayerValueTableContext,
						_event: TPositionPlayerValueTableEvent
					) => undefined,
					cancelSources: (
						context: TPositionPlayerValueTableContext,
						_event: TPositionPlayerValueTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				// Set Context Actions
				setPlayerId: assign<TPositionPlayerValueTableContext, TPositionPlayerValueTableEvent>({
					playerId: (context: TPositionPlayerValueTableContext, event: TPositionPlayerValueTableEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data ?? undefined;
					}
				}),
				setPlayerSeasonRaaPositionPlayer: assign<
					TPositionPlayerValueTableContext,
					TPositionPlayerValueTableEvent
				>({
					playerSeasonRaaPositionPlayer: (
						context: TPositionPlayerValueTableContext,
						event: TPositionPlayerValueTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_RAA_POSITION_PLAYER)
							return context.playerSeasonRaaPositionPlayer;
						return event.data;
					},
					cancelSources: (
						context: TPositionPlayerValueTableContext,
						event: TPositionPlayerValueTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_RAA_POSITION_PLAYER) return context.cancelSources;
						if (context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerSeasonRaaPositionPlayerByTeam: assign<
					TPositionPlayerValueTableContext,
					TPositionPlayerValueTableEvent
				>({
					playerSeasonRaaPositionPlayerByTeam: (
						context: TPositionPlayerValueTableContext,
						event: TPositionPlayerValueTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM)
							return context.playerSeasonRaaPositionPlayerByTeam;
						return event.data;
					},
					cancelSources: (
						context: TPositionPlayerValueTableContext,
						event: TPositionPlayerValueTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM) return context.cancelSources;
						if (context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerSeasonRaaRatePositionPlayer: assign<
					TPositionPlayerValueTableContext,
					TPositionPlayerValueTableEvent
				>({
					playerSeasonRaaRatePositionPlayer: (
						context: TPositionPlayerValueTableContext,
						event: TPositionPlayerValueTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER)
							return context.playerSeasonRaaRatePositionPlayer;
						return event.data;
					},
					cancelSources: (
						context: TPositionPlayerValueTableContext,
						event: TPositionPlayerValueTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER) return context.cancelSources;
						if (context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerSeasonRaaRatePositionPlayerByTeam: assign<
					TPositionPlayerValueTableContext,
					TPositionPlayerValueTableEvent
				>({
					playerSeasonRaaRatePositionPlayerByTeam: (
						context: TPositionPlayerValueTableContext,
						event: TPositionPlayerValueTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM)
							return context.playerSeasonRaaRatePositionPlayerByTeam;
						return event.data;
					},
					cancelSources: (
						context: TPositionPlayerValueTableContext,
						event: TPositionPlayerValueTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM)
							return context.cancelSources;
						if (context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setFilters: assign<TPositionPlayerValueTableContext, TPositionPlayerValueTableEvent>({
					filters: (context: TPositionPlayerValueTableContext, event: TPositionPlayerValueTableEvent) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return event.data;
					}
				}),
				setIsComponentRaa: assign<TPositionPlayerValueTableContext, TPositionPlayerValueTableEvent>({
					isComponentRaa: (
						context: TPositionPlayerValueTableContext,
						event: TPositionPlayerValueTableEvent
					) => {
						if (event.type !== SET_IS_COMPONENT_RAA) return context.isComponentRaa;
						return event.data;
					}
				}),
				setDataSource: assign<TPositionPlayerValueTableContext, TPositionPlayerValueTableEvent>({
					dataSource: (context: TPositionPlayerValueTableContext, event: TPositionPlayerValueTableEvent) => {
						if (event.type !== SET_DATA_SOURCE) return context.dataSource;
						return event.data;
					}
				}),
				// Cancel Source Actions
				refreshPlayerSeasonRaaPositionPlayerCancelSource: assign<
					TPositionPlayerValueTableContext,
					TPositionPlayerValueTableEvent
				>({
					cancelSources: (
						context: TPositionPlayerValueTableContext,
						_event: TPositionPlayerValueTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonRaaPositionPlayerByTeamCancelSource: assign<
					TPositionPlayerValueTableContext,
					TPositionPlayerValueTableEvent
				>({
					cancelSources: (
						context: TPositionPlayerValueTableContext,
						_event: TPositionPlayerValueTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[
							PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE
						] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonRaaRatePositionPlayerCancelSource: assign<
					TPositionPlayerValueTableContext,
					TPositionPlayerValueTableEvent
				>({
					cancelSources: (
						context: TPositionPlayerValueTableContext,
						_event: TPositionPlayerValueTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonRaaRatePositionPlayerByTeamCancelSource: assign<
					TPositionPlayerValueTableContext,
					TPositionPlayerValueTableEvent
				>({
					cancelSources: (
						context: TPositionPlayerValueTableContext,
						_event: TPositionPlayerValueTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[
							PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE
						] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchPlayerSeasonRaaPositionPlayerSuccess: assign<
					TPositionPlayerValueTableContext,
					TPositionPlayerValueTableEvent
				>({
					playerSeasonRaaPositionPlayer: (
						context: TPositionPlayerValueTableContext,
						event: TPositionPlayerValueTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_RAA_POSITION_PLAYER_DONE)
							return context.playerSeasonRaaPositionPlayer;
						return event.data;
					}
				}),
				handleFetchPlayerSeasonRaaPositionPlayerByTeamSuccess: assign<
					TPositionPlayerValueTableContext,
					TPositionPlayerValueTableEvent
				>({
					playerSeasonRaaPositionPlayerByTeam: (
						context: TPositionPlayerValueTableContext,
						event: TPositionPlayerValueTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM_DONE)
							return context.playerSeasonRaaPositionPlayerByTeam;
						return event.data;
					}
				}),
				handleFetchPlayerSeasonRaaRatePositionPlayerSuccess: assign<
					TPositionPlayerValueTableContext,
					TPositionPlayerValueTableEvent
				>({
					playerSeasonRaaRatePositionPlayer: (
						context: TPositionPlayerValueTableContext,
						event: TPositionPlayerValueTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_DONE)
							return context.playerSeasonRaaRatePositionPlayer;
						return event.data;
					}
				}),
				handleFetchPlayerSeasonRaaRatePositionPlayerByTeamSuccess: assign<
					TPositionPlayerValueTableContext,
					TPositionPlayerValueTableEvent
				>({
					playerSeasonRaaRatePositionPlayerByTeam: (
						context: TPositionPlayerValueTableContext,
						event: TPositionPlayerValueTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM_DONE)
							return context.playerSeasonRaaRatePositionPlayerByTeam;
						return event.data;
					}
				}),
				// Fetch Error Actions
				handleFetchPlayerSeasonRaaPositionPlayerErrored: (
					context: TPositionPlayerValueTableContext,
					event: TPositionPlayerValueTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_RAA_POSITION_PLAYER_ERROR ? event.data : undefined,
						context.toast,
						"Position Player Value - RAA",
						"Error fetching RAA by player and season."
					);
				},
				handleFetchPlayerSeasonRaaPositionPlayerByTeamErrored: (
					context: TPositionPlayerValueTableContext,
					event: TPositionPlayerValueTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM_ERROR ? event.data : undefined,
						context.toast,
						"Position Player Value - RAA by Team",
						"Error fetching RAA by player, season, and team"
					);
				},
				handleFetchPlayerSeasonRaaRatePositionPlayerErrored: (
					context: TPositionPlayerValueTableContext,
					event: TPositionPlayerValueTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_ERROR ? event.data : undefined,
						context.toast,
						"Position Player Value - Rate RAA",
						"Error fetching Rate RAA by player and season."
					);
				},
				handleFetchPlayerSeasonRaaRatePositionPlayerByTeamErrored: (
					context: TPositionPlayerValueTableContext,
					event: TPositionPlayerValueTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM_ERROR
							? event.data
							: undefined,
						context.toast,
						"Position Player Value - Rate RAA",
						"Error fetching Rate RAA by player, season, and team"
					);
				}
			},
			services: {
				fetchPlayerSeasonRaaPositionPlayer: (
					context: TPositionPlayerValueTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonRAAPositionPlayer(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_PRO,
								gameType: GAME_TYPE_REGULAR_SEASON,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonRaaPositionPlayerByTeam: (
					context: TPositionPlayerValueTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonRAAPositionPlayerByTeam(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_PRO,
								gameType: GAME_TYPE_REGULAR_SEASON,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonRaaRatePositionPlayer: (
					context: TPositionPlayerValueTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonRAARatePositionPlayer(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_PRO,
								gameType: GAME_TYPE_REGULAR_SEASON,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonRaaRatePositionPlayerByTeam: (
					context: TPositionPlayerValueTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonRAARatePositionPlayerByTeam(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_PRO,
								gameType: GAME_TYPE_REGULAR_SEASON,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PositionPlayerValueTableMachine;
