import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import {
	BATS_OVERALL,
	GAME_TYPE_POSTSEASON,
	GAME_TYPE_REGULAR_SEASON,
	MODEL_EV,
	MODEL_EV_LA,
	MODEL_EV_LA_HLA,
	PLAYING_LEVEL_PRO,
	THROWS_L,
	THROWS_R
} from "_react/shared/data_models/baseline_hit_probs/_constants";
import {
	fetchPlayerSeasonXwoba,
	fetchPlayerSeasonXwobaByTeam
} from "_react/shared/data_models/baseline_hit_probs/_network";
import { IPlayerSeasonXwoba, IPlayerSeasonXwobaByTeam } from "_react/shared/data_models/baseline_hit_probs/_types";
import { fetchPlayer } from "_react/shared/data_models/player/_network";
import { SOURCE_GUMBO, SOURCE_STATSAPI, VALID_GAME_TYPES } from "_react/shared/data_models/stats/_constants";
import {
	fetchAverageAges,
	fetchStatsPlayerBatting,
	fetchStatsPlayerBattingByTeam
} from "_react/shared/data_models/stats/_network";
import { IAverageAge, IStatsPlayerBatting, IStatsPlayerBattingByTeam } from "_react/shared/data_models/stats/_types";

import {
	TPositionPlayerPerformancePlayer,
	TPositionPlayerPerformanceTableData
} from "_react/shared/ui/data/tables/PositionPlayerPerformanceTable/_types";

export const PLAYER_SEASON_XWOBA_CANCEL_SOURCE = "playerSeasonXwoba";
export const PLAYER_SEASON_XWOBA_BYTEAM_CANCEL_SOURCE = "playerSeasonXwobaByTeam";
export const STATS_PLAYER_BATTING_CANCEL_SOURCE = "statsPlayerBatting";
export const STATS_PLAYER_BATTING_BYTEAM_CANCEL_SOURCE = "statsPlayerBattingByTeam";
export const AVERAGE_AGES_CANCEL_SOURCE = "averageAges";
export const POSITION_PLAYER_PERFORMANCE_PLAYER_CANCEL_SOURCE = "positionPlayerPerformancePlayer";

export type TPositionPlayerPerformanceTableCancelSource = {
	[PLAYER_SEASON_XWOBA_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_XWOBA_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
	[STATS_PLAYER_BATTING_CANCEL_SOURCE]?: CancelTokenSource;
	[STATS_PLAYER_BATTING_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
	[AVERAGE_AGES_CANCEL_SOURCE]?: CancelTokenSource;
	[POSITION_PLAYER_PERFORMANCE_PLAYER_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TPositionPlayerPerformanceTableFilters = {
	gameTypes: Array<string>;
	throws: Array<string>;
	levels?: Array<string>;
	maxSeason?: number;
	minSeason?: number;
};

export type TPositionPlayerPerformanceTableContext = {
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	filters: TPositionPlayerPerformanceTableFilters;
	playerSeasonXwoba?: Array<IPlayerSeasonXwoba>;
	playerSeasonXwobaByTeam?: Array<IPlayerSeasonXwobaByTeam>;
	statsPlayerBatting?: Array<IStatsPlayerBatting>;
	statsPlayerBattingByTeam?: Array<IStatsPlayerBattingByTeam>;
	averageAges?: Array<IAverageAge>;
	positionPlayerPerformancePlayer?: TPositionPlayerPerformancePlayer;
	cancelSources: TPositionPlayerPerformanceTableCancelSource;
	toast?: CreateToastFnReturn;
};

interface IPositionPlayerPerformanceTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId prop changes
				contextRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches all xwOBA by player season
				playerSeasonXwoba: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all xwOBA by player season team
				playerSeasonXwobaByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all StatsPlayerBatting by player season
				statsPlayerBatting: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all StatsPlayerBatting by player season team
				statsPlayerBattingByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches average ages
				averageAges: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches player info
				positionPlayerPerformancePlayer: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_PLAYER_SEASON_XWOBA = { initialized: { playerSeasonXwoba: "fetching" } };
export const FETCHING_PLAYER_SEASON_XWOBA_BYTEAM = {
	initialized: { playerSeasonXwobaByTeam: "fetching" }
};
export const FETCHING_STATS_PLAYER_BATTING = { initialized: { statsPlayerBatting: "fetching" } };
export const FETCHING_STATS_PLAYER_BATTING_BYTEAM = { initialized: { statsPlayerBattingByTeam: "fetching" } };
export const FETCHING_AVERAGE_AGES = { initialized: { averageAges: "fetching" } };
export const FETCHING_POSITION_PLAYER_PERFORMANCE_PLAYER = {
	initialized: { positionPlayerPerformancePlayer: "fetching" }
};

const FETCH_PLAYER_SEASON_XWOBA_DONE = "done.invoke.fetchPlayerSeasonXwoba:invocation[0]";
const FETCH_PLAYER_SEASON_XWOBA_BYTEAM_DONE = "done.invoke.fetchPlayerSeasonXwobaByTeam:invocation[0]";
const FETCH_STATS_PLAYER_BATTING_DONE = "done.invoke.fetchStatsPlayerBatting:invocation[0]";
const FETCH_STATS_PLAYER_BATTING_BYTEAM_DONE = "done.invoke.fetchStatsPlayerBattingByTeam:invocation[0]";
const FETCH_AVERAGE_AGES_DONE = "done.invoke.fetchAverageAges:invocation[0]";
const FETCH_POSITION_PLAYER_PERFORMANCE_PLAYER_DONE = "done.invoke.fetchPositionPlayerPerformancePlayer:invocation[0]";
const FETCH_PLAYER_SEASON_XWOBA_ERROR = "error.platform.fetchPlayerSeasonXwoba:invocation[0]";
const FETCH_PLAYER_SEASON_XWOBA_BYTEAM_ERROR = "error.platform.fetchPlayerSeasonXwobaByTeam:invocation[0]";
const FETCH_STATS_PLAYER_BATTING_ERROR = "error.platform.fetchStatsPlayerBatting:invocation[0]";
const FETCH_STATS_PLAYER_BATTING_BYTEAM_ERROR = "error.platform.fetchStatsPlayerBattingByTeam:invocation[0]";
const FETCH_AVERAGE_AGES_ERROR = "error.platform.fetchAverageAges:invocation[0]";
const FETCH_POSITION_PLAYER_PERFORMANCE_PLAYER_ERROR =
	"error.platform.fetchPositionPlayerPerformancePlayer:invocation[0]";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PLAYER_SEASON_XWOBA = "SET_PLAYER_SEASON_XWOBA";
export const SET_PLAYER_SEASON_XWOBA_BYTEAM = "SET_PLAYER_SEASON_XWOBA_BYTEAM";
export const SET_STATS_PLAYER_BATTING = "SET_STATS_PLAYER_BATTING";
export const SET_STATS_PLAYER_BATTING_BYTEAM = "SET_STATS_PLAYER_BATTING_BYTEAM";
export const SET_AVERAGE_AGES = "SET_AVERAGE_AGES";
export const SET_POSITION_PLAYER_PERFORMANCE_PLAYER = "SET_POSITION_PLAYER_PERFORMANCE_PLAYER";
export const SET_FILTERS = "SET_FILTERS";

type TFetchPlayerSeasonXwobaEvent = {
	type: typeof FETCH_PLAYER_SEASON_XWOBA_DONE;
	data: Array<IPlayerSeasonXwoba> | undefined;
};
type TFetchPlayerSeasonXwobaByTeamEvent = {
	type: typeof FETCH_PLAYER_SEASON_XWOBA_BYTEAM_DONE;
	data: Array<IPlayerSeasonXwobaByTeam> | undefined;
};
type TFetchStatsPlayerBattingEvent = {
	type: typeof FETCH_STATS_PLAYER_BATTING_DONE;
	data: Array<IStatsPlayerBatting> | undefined;
};
type TFetchStatsPlayerBattingByTeamEvent = {
	type: typeof FETCH_STATS_PLAYER_BATTING_BYTEAM_DONE;
	data: Array<IStatsPlayerBattingByTeam> | undefined;
};
type TFetchAverageAgesEvent = {
	type: typeof FETCH_AVERAGE_AGES_DONE;
	data: Array<IAverageAge> | undefined;
};
type TFetchPositionPlayerPerformancePlayerEvent = {
	type: typeof FETCH_POSITION_PLAYER_PERFORMANCE_PLAYER_DONE;
	data: TPositionPlayerPerformancePlayer | undefined;
};

type TFetchPlayerSeasonXwobaErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_XWOBA_ERROR;
	data?: AxiosError | Error;
};
type TFetchPlayerSeasonXwobaByTeamErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_XWOBA_BYTEAM_ERROR;
	data?: AxiosError | Error;
};
type TFetchStatsPlayerBattingErrorEvent = {
	type: typeof FETCH_STATS_PLAYER_BATTING_ERROR;
	data?: AxiosError | Error;
};
type TFetchStatsPlayerBattingByTeamErrorEvent = {
	type: typeof FETCH_STATS_PLAYER_BATTING_BYTEAM_ERROR;
	data?: AxiosError | Error;
};
type TFetchAverageAgesErrorEvent = {
	type: typeof FETCH_AVERAGE_AGES_ERROR;
	data?: AxiosError | Error;
};
type TFetchPositionPlayerPerformancePlayerErrorEvent = {
	type: typeof FETCH_POSITION_PLAYER_PERFORMANCE_PLAYER_ERROR;
	data?: AxiosError | Error;
};

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value: number | undefined };
type TSetPlayerSeasonXwobaEvent = {
	type: typeof SET_PLAYER_SEASON_XWOBA;
	value: Array<IPlayerSeasonXwoba> | undefined;
};
type TSetPlayerSeasonXwobaByTeamEvent = {
	type: typeof SET_PLAYER_SEASON_XWOBA_BYTEAM;
	value: Array<IPlayerSeasonXwobaByTeam> | undefined;
};
type TSetStatsPlayerBattingEvent = {
	type: typeof SET_STATS_PLAYER_BATTING;
	value: Array<IStatsPlayerBatting> | undefined;
};
type TSetStatsPlayerBattingByTeamEvent = {
	type: typeof SET_STATS_PLAYER_BATTING_BYTEAM;
	value: Array<IStatsPlayerBattingByTeam> | undefined;
};
type TSetAverageAgesEvent = {
	type: typeof SET_AVERAGE_AGES;
	value: Array<IAverageAge> | undefined;
};
type TSetPositionPlayerPerformancePlayerEvent = {
	type: typeof SET_POSITION_PLAYER_PERFORMANCE_PLAYER;
	value: TPositionPlayerPerformancePlayer | undefined;
};
type TSetFiltersEvent = {
	type: typeof SET_FILTERS;
	value: TPositionPlayerPerformanceTableFilters;
};

type TPositionPlayerPerformanceTableEvent =
	| TFetchPlayerSeasonXwobaEvent
	| TFetchPlayerSeasonXwobaByTeamEvent
	| TFetchStatsPlayerBattingEvent
	| TFetchStatsPlayerBattingByTeamEvent
	| TFetchAverageAgesEvent
	| TFetchPositionPlayerPerformancePlayerEvent
	| TFetchPlayerSeasonXwobaErrorEvent
	| TFetchPlayerSeasonXwobaByTeamErrorEvent
	| TFetchStatsPlayerBattingErrorEvent
	| TFetchStatsPlayerBattingByTeamErrorEvent
	| TFetchAverageAgesErrorEvent
	| TFetchPositionPlayerPerformancePlayerErrorEvent
	| TSetPlayerIdEvent
	| TSetPlayerSeasonXwobaEvent
	| TSetPlayerSeasonXwobaByTeamEvent
	| TSetStatsPlayerBattingEvent
	| TSetStatsPlayerBattingByTeamEvent
	| TSetAverageAgesEvent
	| TSetPositionPlayerPerformancePlayerEvent
	| TSetFiltersEvent;

export type TPositionPlayerPerformanceTableSend = Interpreter<
	TPositionPlayerPerformanceTableContext,
	IPositionPlayerPerformanceTableStateSchema,
	TPositionPlayerPerformanceTableEvent
>["send"];

const PositionPlayerPerformanceTableMachine = (
	playerIdProp?: number,
	data?: TPositionPlayerPerformanceTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<
		TPositionPlayerPerformanceTableContext,
		IPositionPlayerPerformanceTableStateSchema,
		TPositionPlayerPerformanceTableEvent
	>(
		{
			id: "positionPlayerPerformanceTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchDataProp,
				filters: {
					gameTypes: [GAME_TYPE_REGULAR_SEASON, GAME_TYPE_POSTSEASON],
					throws: [THROWS_L, THROWS_R],
					levels: undefined
				},
				playerSeasonXwoba: data?.playerSeasonXwoba,
				playerSeasonXwobaByTeam: data?.playerSeasonXwobaByTeam,
				statsPlayerBatting: data?.statsPlayerBatting,
				statsPlayerBattingByTeam: data?.statsPlayerBattingByTeam,
				averageAges: data?.averageAges,
				positionPlayerPerformancePlayer: data?.positionPlayerPerformancePlayer,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						SET_PLAYER_ID: { actions: "setPlayerId" },
						SET_PLAYER_SEASON_XWOBA: { actions: "setPlayerSeasonXwoba" },
						SET_PLAYER_SEASON_XWOBA_BYTEAM: { actions: "setPlayerSeasonXwobaByTeam" },
						SET_STATS_PLAYER_BATTING: { actions: "setStatsPlayerBatting" },
						SET_STATS_PLAYER_BATTING_BYTEAM: { actions: "setStatsPlayerBattingByTeam" },
						SET_AVERAGE_AGES: { actions: "setAverageAges" },
						SET_POSITION_PLAYER_PERFORMANCE_PLAYER: { actions: "setPositionPlayerPerformancePlayer" },
						SET_FILTERS: { actions: "setFilters" }
					},
					states: {
						contextRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						playerSeasonXwoba: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonXwoba",
												cond: "shouldFetchPlayerSeasonXwoba"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonXwoba",
									entry: ["refreshPlayerSeasonXwobaCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonXwoba",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonXwobaSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonXwobaErrored"
										}
									}
								}
							}
						},
						playerSeasonXwobaByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonXwobaByTeam",
												cond: "shouldFetchPlayerSeasonXwobaByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonXwobaByTeam",
									entry: ["refreshPlayerSeasonXwobaByTeamCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonXwobaByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonXwobaByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonXwobaByTeamErrored"
										}
									}
								}
							}
						},
						statsPlayerBatting: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchStatsPlayerBatting",
												cond: "shouldFetchStatsPlayerBatting"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchStatsPlayerBatting",
									entry: ["refreshStatsPlayerBattingCancelSource"],
									invoke: {
										src: "fetchStatsPlayerBatting",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchStatsPlayerBattingSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchStatsPlayerBattingErrored"
										}
									}
								}
							}
						},
						statsPlayerBattingByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchStatsPlayerBattingByTeam",
												cond: "shouldFetchStatsPlayerBattingByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchStatsPlayerBattingByTeam",
									entry: ["refreshStatsPlayerBattingByTeamCancelSource"],
									invoke: {
										src: "fetchStatsPlayerBattingByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchStatsPlayerBattingByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchStatsPlayerBattingByTeamErrored"
										}
									}
								}
							}
						},
						averageAges: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchAverageAges",
												cond: "shouldFetchAverageAges"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchAverageAges",
									entry: ["refreshAverageAgesCancelSource"],
									invoke: {
										src: "fetchAverageAges",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchAverageAgesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchAverageAgesErrored"
										}
									}
								}
							}
						},
						positionPlayerPerformancePlayer: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPositionPlayerPerformancePlayer",
												cond: "shouldFetchPositionPlayerPerformancePlayer"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPositionPlayerPerformancePlayer",
									entry: ["refreshPositionPlayerPerformancePlayerCancelSource"],
									invoke: {
										src: "fetchPositionPlayerPerformancePlayer",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPositionPlayerPerformancePlayerSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPositionPlayerPerformancePlayerErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (
					context: TPositionPlayerPerformanceTableContext,
					_event: TPositionPlayerPerformanceTableEvent
				) => context.playerId !== context.lastPlayerId,
				shouldFetchPlayerSeasonXwoba: (
					context: TPositionPlayerPerformanceTableContext,
					_event: TPositionPlayerPerformanceTableEvent
				) =>
					context.playerSeasonXwoba === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPlayerSeasonXwobaByTeam: (
					context: TPositionPlayerPerformanceTableContext,
					_event: TPositionPlayerPerformanceTableEvent
				) =>
					context.playerSeasonXwobaByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchStatsPlayerBatting: (
					context: TPositionPlayerPerformanceTableContext,
					_event: TPositionPlayerPerformanceTableEvent
				) =>
					context.statsPlayerBatting === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchStatsPlayerBattingByTeam: (
					context: TPositionPlayerPerformanceTableContext,
					_event: TPositionPlayerPerformanceTableEvent
				) =>
					context.statsPlayerBattingByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchAverageAges: (
					context: TPositionPlayerPerformanceTableContext,
					_event: TPositionPlayerPerformanceTableEvent
				) =>
					context.averageAges === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPositionPlayerPerformancePlayer: (
					context: TPositionPlayerPerformanceTableContext,
					_event: TPositionPlayerPerformanceTableEvent
				) =>
					context.positionPlayerPerformancePlayer === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true
			},
			actions: {
				clearContext: assign<TPositionPlayerPerformanceTableContext, TPositionPlayerPerformanceTableEvent>({
					lastPlayerId: (
						context: TPositionPlayerPerformanceTableContext,
						_event: TPositionPlayerPerformanceTableEvent
					) => context.playerId,
					playerSeasonXwoba: (
						_context: TPositionPlayerPerformanceTableContext,
						_event: TPositionPlayerPerformanceTableEvent
					) => undefined,
					playerSeasonXwobaByTeam: (
						_context: TPositionPlayerPerformanceTableContext,
						_event: TPositionPlayerPerformanceTableEvent
					) => undefined,
					statsPlayerBatting: (
						_context: TPositionPlayerPerformanceTableContext,
						_event: TPositionPlayerPerformanceTableEvent
					) => undefined,
					statsPlayerBattingByTeam: (
						_context: TPositionPlayerPerformanceTableContext,
						_event: TPositionPlayerPerformanceTableEvent
					) => undefined,
					averageAges: (
						_context: TPositionPlayerPerformanceTableContext,
						_event: TPositionPlayerPerformanceTableEvent
					) => undefined,
					positionPlayerPerformancePlayer: (
						_context: TPositionPlayerPerformanceTableContext,
						_event: TPositionPlayerPerformanceTableEvent
					) => undefined,
					cancelSources: (
						context: TPositionPlayerPerformanceTableContext,
						_event: TPositionPlayerPerformanceTableEvent
					) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				// Set Context Actions
				setPlayerId: assign<TPositionPlayerPerformanceTableContext, TPositionPlayerPerformanceTableEvent>({
					playerId: (
						context: TPositionPlayerPerformanceTableContext,
						event: TPositionPlayerPerformanceTableEvent
					) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					},
					cancelSources: (
						context: TPositionPlayerPerformanceTableContext,
						_event: TPositionPlayerPerformanceTableEvent
					) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				setPlayerSeasonXwoba: assign<
					TPositionPlayerPerformanceTableContext,
					TPositionPlayerPerformanceTableEvent
				>({
					playerSeasonXwoba: (
						context: TPositionPlayerPerformanceTableContext,
						event: TPositionPlayerPerformanceTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_XWOBA) return context.playerSeasonXwoba;
						return event.value;
					},
					cancelSources: (
						context: TPositionPlayerPerformanceTableContext,
						event: TPositionPlayerPerformanceTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_XWOBA) return cancelSources;
						cancelSources[PLAYER_SEASON_XWOBA_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_XWOBA_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setPlayerSeasonXwobaByTeam: assign<
					TPositionPlayerPerformanceTableContext,
					TPositionPlayerPerformanceTableEvent
				>({
					playerSeasonXwobaByTeam: (
						context: TPositionPlayerPerformanceTableContext,
						event: TPositionPlayerPerformanceTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_XWOBA_BYTEAM) return context.playerSeasonXwobaByTeam;
						return event.value;
					},
					cancelSources: (
						context: TPositionPlayerPerformanceTableContext,
						event: TPositionPlayerPerformanceTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_XWOBA_BYTEAM) return cancelSources;
						cancelSources[PLAYER_SEASON_XWOBA_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_XWOBA_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setStatsPlayerBatting: assign<
					TPositionPlayerPerformanceTableContext,
					TPositionPlayerPerformanceTableEvent
				>({
					statsPlayerBatting: (
						context: TPositionPlayerPerformanceTableContext,
						event: TPositionPlayerPerformanceTableEvent
					) => {
						if (event.type !== SET_STATS_PLAYER_BATTING) return context.statsPlayerBatting;
						return event.value;
					},
					cancelSources: (
						context: TPositionPlayerPerformanceTableContext,
						event: TPositionPlayerPerformanceTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_STATS_PLAYER_BATTING) return cancelSources;
						cancelSources[STATS_PLAYER_BATTING_CANCEL_SOURCE]?.cancel();
						cancelSources[STATS_PLAYER_BATTING_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setAverageAges: assign<TPositionPlayerPerformanceTableContext, TPositionPlayerPerformanceTableEvent>({
					averageAges: (
						context: TPositionPlayerPerformanceTableContext,
						event: TPositionPlayerPerformanceTableEvent
					) => {
						if (event.type !== SET_AVERAGE_AGES) return context.averageAges;
						return event.value;
					},
					cancelSources: (
						context: TPositionPlayerPerformanceTableContext,
						event: TPositionPlayerPerformanceTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_AVERAGE_AGES) return cancelSources;
						cancelSources[AVERAGE_AGES_CANCEL_SOURCE]?.cancel();
						cancelSources[AVERAGE_AGES_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setPositionPlayerPerformancePlayer: assign<
					TPositionPlayerPerformanceTableContext,
					TPositionPlayerPerformanceTableEvent
				>({
					positionPlayerPerformancePlayer: (
						context: TPositionPlayerPerformanceTableContext,
						event: TPositionPlayerPerformanceTableEvent
					) => {
						if (event.type !== SET_POSITION_PLAYER_PERFORMANCE_PLAYER)
							return context.positionPlayerPerformancePlayer;
						return event.value;
					},
					cancelSources: (
						context: TPositionPlayerPerformanceTableContext,
						event: TPositionPlayerPerformanceTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_POSITION_PLAYER_PERFORMANCE_PLAYER) return cancelSources;
						cancelSources[POSITION_PLAYER_PERFORMANCE_PLAYER_CANCEL_SOURCE]?.cancel();
						cancelSources[POSITION_PLAYER_PERFORMANCE_PLAYER_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setFilters: assign<TPositionPlayerPerformanceTableContext, TPositionPlayerPerformanceTableEvent>({
					filters: (
						context: TPositionPlayerPerformanceTableContext,
						event: TPositionPlayerPerformanceTableEvent
					) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return event.value;
					}
				}),
				// Cancel Source Actions
				refreshPlayerSeasonXwobaCancelSource: assign<
					TPositionPlayerPerformanceTableContext,
					TPositionPlayerPerformanceTableEvent
				>({
					cancelSources: (
						context: TPositionPlayerPerformanceTableContext,
						_event: TPositionPlayerPerformanceTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_XWOBA_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_XWOBA_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_XWOBA_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonXwobaByTeamCancelSource: assign<
					TPositionPlayerPerformanceTableContext,
					TPositionPlayerPerformanceTableEvent
				>({
					cancelSources: (
						context: TPositionPlayerPerformanceTableContext,
						_event: TPositionPlayerPerformanceTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_XWOBA_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_XWOBA_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_XWOBA_BYTEAM_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshStatsPlayerBattingCancelSource: assign<
					TPositionPlayerPerformanceTableContext,
					TPositionPlayerPerformanceTableEvent
				>({
					cancelSources: (
						context: TPositionPlayerPerformanceTableContext,
						_event: TPositionPlayerPerformanceTableEvent
					) => {
						if (context.cancelSources[STATS_PLAYER_BATTING_CANCEL_SOURCE] != null)
							context.cancelSources[STATS_PLAYER_BATTING_CANCEL_SOURCE].cancel();
						context.cancelSources[STATS_PLAYER_BATTING_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshStatsPlayerBattingByTeamCancelSource: assign<
					TPositionPlayerPerformanceTableContext,
					TPositionPlayerPerformanceTableEvent
				>({
					cancelSources: (
						context: TPositionPlayerPerformanceTableContext,
						_event: TPositionPlayerPerformanceTableEvent
					) => {
						if (context.cancelSources[STATS_PLAYER_BATTING_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[STATS_PLAYER_BATTING_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[STATS_PLAYER_BATTING_BYTEAM_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshAverageAgesCancelSource: assign<
					TPositionPlayerPerformanceTableContext,
					TPositionPlayerPerformanceTableEvent
				>({
					cancelSources: (
						context: TPositionPlayerPerformanceTableContext,
						_event: TPositionPlayerPerformanceTableEvent
					) => {
						if (context.cancelSources[AVERAGE_AGES_CANCEL_SOURCE] != null)
							context.cancelSources[AVERAGE_AGES_CANCEL_SOURCE].cancel();
						context.cancelSources[AVERAGE_AGES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPositionPlayerPerformancePlayerCancelSource: assign<
					TPositionPlayerPerformanceTableContext,
					TPositionPlayerPerformanceTableEvent
				>({
					cancelSources: (
						context: TPositionPlayerPerformanceTableContext,
						_event: TPositionPlayerPerformanceTableEvent
					) => {
						if (context.cancelSources[POSITION_PLAYER_PERFORMANCE_PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[POSITION_PLAYER_PERFORMANCE_PLAYER_CANCEL_SOURCE].cancel();
						context.cancelSources[POSITION_PLAYER_PERFORMANCE_PLAYER_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchPlayerSeasonXwobaSuccess: assign<
					TPositionPlayerPerformanceTableContext,
					TPositionPlayerPerformanceTableEvent
				>({
					playerSeasonXwoba: (
						context: TPositionPlayerPerformanceTableContext,
						event: TPositionPlayerPerformanceTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_XWOBA_DONE) return context.playerSeasonXwoba;
						return event.data;
					}
				}),
				handleFetchPlayerSeasonXwobaByTeamSuccess: assign<
					TPositionPlayerPerformanceTableContext,
					TPositionPlayerPerformanceTableEvent
				>({
					playerSeasonXwobaByTeam: (
						context: TPositionPlayerPerformanceTableContext,
						event: TPositionPlayerPerformanceTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_XWOBA_BYTEAM_DONE)
							return context.playerSeasonXwobaByTeam;
						return event.data;
					}
				}),
				handleFetchStatsPlayerBattingSuccess: assign<
					TPositionPlayerPerformanceTableContext,
					TPositionPlayerPerformanceTableEvent
				>({
					statsPlayerBatting: (
						context: TPositionPlayerPerformanceTableContext,
						event: TPositionPlayerPerformanceTableEvent
					) => {
						if (event.type !== FETCH_STATS_PLAYER_BATTING_DONE) return context.statsPlayerBatting;
						return event.data;
					}
				}),
				handleFetchStatsPlayerBattingByTeamSuccess: assign<
					TPositionPlayerPerformanceTableContext,
					TPositionPlayerPerformanceTableEvent
				>({
					statsPlayerBattingByTeam: (
						context: TPositionPlayerPerformanceTableContext,
						event: TPositionPlayerPerformanceTableEvent
					) => {
						if (event.type !== FETCH_STATS_PLAYER_BATTING_BYTEAM_DONE)
							return context.statsPlayerBattingByTeam;
						return event.data;
					}
				}),
				handleFetchAverageAgesSuccess: assign<
					TPositionPlayerPerformanceTableContext,
					TPositionPlayerPerformanceTableEvent
				>({
					averageAges: (
						context: TPositionPlayerPerformanceTableContext,
						event: TPositionPlayerPerformanceTableEvent
					) => {
						if (event.type !== FETCH_AVERAGE_AGES_DONE) return context.averageAges;
						return event.data;
					}
				}),
				handleFetchPositionPlayerPerformancePlayerSuccess: assign<
					TPositionPlayerPerformanceTableContext,
					TPositionPlayerPerformanceTableEvent
				>({
					positionPlayerPerformancePlayer: (
						context: TPositionPlayerPerformanceTableContext,
						event: TPositionPlayerPerformanceTableEvent
					) => {
						if (event.type !== FETCH_POSITION_PLAYER_PERFORMANCE_PLAYER_DONE)
							return context.positionPlayerPerformancePlayer;
						return event.data;
					}
				}),
				// Fetch Error Actions
				handleFetchPlayerSeasonXwobaErrored: (
					context: TPositionPlayerPerformanceTableContext,
					event: TPositionPlayerPerformanceTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_XWOBA_ERROR ? event.data : undefined,
						context.toast,
						"Position Player Performance - xwOBA",
						"Error fetching xwOBA by player and season."
					);
				},
				handleFetchPlayerSeasonXwobaByTeamErrored: (
					context: TPositionPlayerPerformanceTableContext,
					event: TPositionPlayerPerformanceTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_XWOBA_BYTEAM_ERROR ? event.data : undefined,
						context.toast,
						"Position Player Performance - xwOBA by Team",
						"Error fetching xwOBA by player, season, and team"
					);
				},
				handleFetchStatsPlayerBattingErrored: (
					context: TPositionPlayerPerformanceTableContext,
					event: TPositionPlayerPerformanceTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_STATS_PLAYER_BATTING_ERROR ? event.data : undefined,
						context.toast,
						"Position Player Performance - Stats Player Batting",
						"Error fetching player batting stats by player and season"
					);
				},
				handleFetchStatsPlayerBattingByTeamErrored: (
					context: TPositionPlayerPerformanceTableContext,
					event: TPositionPlayerPerformanceTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_STATS_PLAYER_BATTING_BYTEAM_ERROR ? event.data : undefined,
						context.toast,
						"Position Player Performance - Stats Player Batting by Team",
						"Error fetching player batting stats by player, season, and team"
					);
				},
				handleFetchAverageAgesErrored: (
					context: TPositionPlayerPerformanceTableContext,
					event: TPositionPlayerPerformanceTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_AVERAGE_AGES_ERROR ? event.data : undefined,
						context.toast,
						"Position Player Performance - Average Ages",
						"Error fetching average ages"
					);
				},
				handleFetchPositionPlayerPerformancePlayerErrored: (
					context: TPositionPlayerPerformanceTableContext,
					event: TPositionPlayerPerformanceTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_POSITION_PLAYER_PERFORMANCE_PLAYER_ERROR ? event.data : undefined,
						context.toast,
						"Position Player Performance - Player Data",
						"Error fetching player data"
					);
				}
			},
			services: {
				fetchPlayerSeasonXwoba: (context: TPositionPlayerPerformanceTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonXwoba(
							{
								playerId: playerId,
								isHitter: true,
								playingLevel: PLAYING_LEVEL_PRO,
								bats: BATS_OVERALL,
								"model[in]": [MODEL_EV, MODEL_EV_LA, MODEL_EV_LA_HLA].join(","),
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_XWOBA_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonXwobaByTeam: (
					context: TPositionPlayerPerformanceTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonXwobaByTeam(
							{
								playerId: playerId,
								isHitter: true,
								playingLevel: PLAYING_LEVEL_PRO,
								bats: BATS_OVERALL,
								"model[in]": [MODEL_EV, MODEL_EV_LA, MODEL_EV_LA_HLA].join(","),
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_XWOBA_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchStatsPlayerBatting: (context: TPositionPlayerPerformanceTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchStatsPlayerBatting(
							{
								playerId: playerId,
								"source[in]": [SOURCE_GUMBO, SOURCE_STATSAPI].join(","),
								"gameType[in]": [VALID_GAME_TYPES].join(","),
								isUseCache: true
							},
							context.cancelSources[STATS_PLAYER_BATTING_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchStatsPlayerBattingByTeam: (
					context: TPositionPlayerPerformanceTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchStatsPlayerBattingByTeam(
							{
								playerId: playerId,
								"source[in]": [SOURCE_GUMBO, SOURCE_STATSAPI].join(","),
								"gameType[in]": [VALID_GAME_TYPES].join(","),
								isUseCache: true
							},
							context.cancelSources[STATS_PLAYER_BATTING_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchAverageAges: (context: TPositionPlayerPerformanceTableContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchAverageAges({}, context.cancelSources[AVERAGE_AGES_CANCEL_SOURCE]?.token);
					return promiseWRetry(fetchFunc);
				},
				fetchPositionPlayerPerformancePlayer: (
					context: TPositionPlayerPerformanceTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayer<TPositionPlayerPerformancePlayer>(
							{
								id: playerId,
								isUseCombinedId: true,
								fields: "birthDate"
							},
							context.cancelSources[POSITION_PLAYER_PERFORMANCE_PLAYER_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PositionPlayerPerformanceTableMachine;
