import React, { useContext } from "react";
import { useSelector } from "@xstate/react";
import { SimpleGrid, GridItem } from "@chakra-ui/react";

import { MODEL_NAME_V2, PLAYER_TYPE_BATTER } from "_react/shared/data_models/phred/_constants";
import Section from "_react/shared/ui/presentation/components/Section/Section";
import { TIntlProspectValueSimilarPlayersPlayerType } from "_react/shared/data_models/phred/_network";
import { IIntlProspectValue } from "_react/shared/data_models/phred/_types";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { TPlayerPageIntlState, FETCHING_INTL_PROSPECT_VALUES } from "_react/playerpage/intl/_machine";
import { PlayerPageIntlContext } from "_react/playerpage/intl/PlayerPageIntlProvider";
import { SectionStyleObject } from "_react/playerpage/intl/shared/_styles";
import IntlProspectValuesStatCard from "_react/shared/ui/data/cards/IntlProspectValuesStatCard/IntlProspectValuesStatCard";
import IntlSurplusValueSimilarPlayersTable from "_react/shared/ui/data/tables/IntlSurplusValueSimilarPlayersTable/IntlSurplusValueSimilarPlayersTable";

type TIntlProspectValueSectionProps = {
	player: TPlayerPageCombinedPlayer;
	playerType: string;
};

const IntlProspectValueSection = ({ player, playerType }: TIntlProspectValueSectionProps) => {
	const playerPageIntlContext = useContext(PlayerPageIntlContext);

	const intlProspectValues: Array<IIntlProspectValue> | null | undefined = useSelector(
		playerPageIntlContext.playerPageIntlService,
		(state: TPlayerPageIntlState) => state.context.intlProspectValues
	);
	const isFetchingIntlProspectValues: boolean = useSelector(
		playerPageIntlContext.playerPageIntlService,
		(state: TPlayerPageIntlState) => state.matches(FETCHING_INTL_PROSPECT_VALUES)
	);

	const selectedIntlProspectValue = intlProspectValues?.find(
		prospectValue => prospectValue.playerType === playerType
	);

	// Only need margin top when there is a table with a title next to the StatCard
	const statCardStyling = { marginTop: { "2xl": 10 } };

	return (
		<Section label="Prospect Value" style={SectionStyleObject}>
			<SimpleGrid
				columns={3}
				minChildWidth={{
					md: "sm"
				}}
				spacing={6}
			>
				<GridItem>
					<IntlProspectValuesStatCard
						playerId={player.id}
						playerType={playerType}
						data={{ intlProspectValues: intlProspectValues, isLoading: isFetchingIntlProspectValues }}
						shouldFetchData={false}
						style={statCardStyling}
					/>
				</GridItem>
				<GridItem colSpan={2}>
					<IntlSurplusValueSimilarPlayersTable
						title={`Similar Players${
							selectedIntlProspectValue
								? ` (${
										selectedIntlProspectValue.positionGroup === "UN"
											? selectedIntlProspectValue.playerType === PLAYER_TYPE_BATTER
												? "Pos. Player"
												: "Pitcher"
											: selectedIntlProspectValue.positionGroup
								  })`
								: ""
						}`}
						playerId={player.id}
						playerType={playerType as TIntlProspectValueSimilarPlayersPlayerType}
						positionGroup={selectedIntlProspectValue?.positionGroup}
						modelName={MODEL_NAME_V2}
					/>
				</GridItem>
			</SimpleGrid>
		</Section>
	);
};

export default IntlProspectValueSection;
