import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import { fetchPlayer } from "_react/shared/data_models/player/_network";
import { SOURCE_GUMBO, SOURCE_STATSAPI, VALID_GAME_TYPES } from "_react/shared/data_models/stats/_constants";
import {
	fetchAverageAges,
	fetchStatsPlayerPitching,
	fetchStatsPlayerPitchingByTeam
} from "_react/shared/data_models/stats/_network";
import { IAverageAge, IStatsPlayerPitching, IStatsPlayerPitchingByTeam } from "_react/shared/data_models/stats/_types";

import {
	GAME_TYPE_POSTSEASON,
	GAME_TYPE_REGULAR_SEASON
} from "_react/shared/ui/data/tables/PitcherPerformanceTable/_constants";
import {
	TPitcherPerformancePlayer,
	TPitcherPerformanceTableData
} from "_react/shared/ui/data/tables/PitcherPerformanceTable/_types";

export const STATS_PLAYER_PITCHING_CANCEL_SOURCE = "statsPlayerPitching";
export const STATS_PLAYER_PITCHING_BYTEAM_CANCEL_SOURCE = "statsPlayerPitchingByTeam";
export const AVERAGE_AGES_CANCEL_SOURCE = "averageAges";
export const PITCHER_PERFORMANCE_PLAYER_CANCEL_SOURCE = "pitcherPerformancePlayer";

export type TPitcherPerformanceTableCancelSource = {
	[STATS_PLAYER_PITCHING_CANCEL_SOURCE]?: CancelTokenSource;
	[STATS_PLAYER_PITCHING_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
	[AVERAGE_AGES_CANCEL_SOURCE]?: CancelTokenSource;
	[PITCHER_PERFORMANCE_PLAYER_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TPitcherPerformanceTableFilters = {
	gameTypes: Array<string>;
	levels?: Array<string>;
	maxSeason?: number;
	minSeason?: number;
};

export type TPitcherPerformanceTableContext = {
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	filters: TPitcherPerformanceTableFilters;
	statsPlayerPitching?: Array<IStatsPlayerPitching>;
	statsPlayerPitchingByTeam?: Array<IStatsPlayerPitchingByTeam>;
	averageAges?: Array<IAverageAge>;
	pitcherPerformancePlayer?: TPitcherPerformancePlayer;
	cancelSources: TPitcherPerformanceTableCancelSource;
	toast?: CreateToastFnReturn;
};

interface IPitcherPerformanceTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId prop changes
				contextRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches all StatsPlayerPitching by player season
				statsPlayerPitching: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all StatsPlayerPitching by player season team
				statsPlayerPitchingByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches average ages
				averageAges: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches player info
				pitcherPerformancePlayer: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_STATS_PLAYER_PITCHING = { initialized: { statsPlayerPitching: "fetching" } };
export const FETCHING_STATS_PLAYER_PITCHING_BYTEAM = { initialized: { statsPlayerPitchingByTeam: "fetching" } };
export const FETCHING_AVERAGE_AGES = { initialized: { averageAges: "fetching" } };
export const FETCHING_PITCHER_PERFORMANCE_PLAYER = {
	initialized: { pitcherPerformancePlayer: "fetching" }
};

const FETCH_STATS_PLAYER_PITCHING_DONE = "done.invoke.fetchStatsPlayerPitching:invocation[0]";
const FETCH_STATS_PLAYER_PITCHING_BYTEAM_DONE = "done.invoke.fetchStatsPlayerPitchingByTeam:invocation[0]";
const FETCH_AVERAGE_AGES_DONE = "done.invoke.fetchAverageAges:invocation[0]";
const FETCH_PITCHER_PERFORMANCE_PLAYER_DONE = "done.invoke.fetchPitcherPerformancePlayer:invocation[0]";
const FETCH_STATS_PLAYER_PITCHING_ERROR = "error.platform.fetchStatsPlayerPitching:invocation[0]";
const FETCH_STATS_PLAYER_PITCHING_BYTEAM_ERROR = "error.platform.fetchStatsPlayerPitchingByTeam:invocation[0]";
const FETCH_AVERAGE_AGES_ERROR = "error.platform.fetchAverageAges:invocation[0]";
const FETCH_PITCHER_PERFORMANCE_PLAYER_ERROR = "error.platform.fetchPitcherPerformancePlayer:invocation[0]";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_STATS_PLAYER_PITCHING = "SET_STATS_PLAYER_PITCHING";
export const SET_STATS_PLAYER_PITCHING_BYTEAM = "SET_STATS_PLAYER_PITCHING_BYTEAM";
export const SET_AVERAGE_AGES = "SET_AVERAGE_AGES";
export const SET_PITCHER_PERFORMANCE_PLAYER = "SET_PITCHER_PERFORMANCE_PLAYER";
export const SET_FILTERS = "SET_FILTERS";

type TFetchStatsPlayerPitchingEvent = {
	type: typeof FETCH_STATS_PLAYER_PITCHING_DONE;
	data: Array<IStatsPlayerPitching> | undefined;
};
type TFetchStatsPlayerPitchingByTeamEvent = {
	type: typeof FETCH_STATS_PLAYER_PITCHING_BYTEAM_DONE;
	data: Array<IStatsPlayerPitchingByTeam> | undefined;
};
type TFetchAverageAgesEvent = {
	type: typeof FETCH_AVERAGE_AGES_DONE;
	data: Array<IAverageAge> | undefined;
};
type TFetchPitcherPerformancePlayerEvent = {
	type: typeof FETCH_PITCHER_PERFORMANCE_PLAYER_DONE;
	data: TPitcherPerformancePlayer | undefined;
};

type TFetchStatsPlayerPitchingErrorEvent = {
	type: typeof FETCH_STATS_PLAYER_PITCHING_ERROR;
	data?: AxiosError | Error;
};
type TFetchStatsPlayerPitchingByTeamErrorEvent = {
	type: typeof FETCH_STATS_PLAYER_PITCHING_BYTEAM_ERROR;
	data?: AxiosError | Error;
};
type TFetchAverageAgesErrorEvent = {
	type: typeof FETCH_AVERAGE_AGES_ERROR;
	data?: AxiosError | Error;
};
type TFetchPitcherPerformancePlayerErrorEvent = {
	type: typeof FETCH_PITCHER_PERFORMANCE_PLAYER_ERROR;
	data?: AxiosError | Error;
};

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value: number | undefined };
type TSetStatsPlayerPitchingEvent = {
	type: typeof SET_STATS_PLAYER_PITCHING;
	value: Array<IStatsPlayerPitching> | undefined;
};
type TSetStatsPlayerPitchingByTeamEvent = {
	type: typeof SET_STATS_PLAYER_PITCHING_BYTEAM;
	value: Array<IStatsPlayerPitchingByTeam> | undefined;
};
type TSetAverageAgesEvent = {
	type: typeof SET_AVERAGE_AGES;
	value: Array<IAverageAge> | undefined;
};
type TSetPitcherPerformancePlayerEvent = {
	type: typeof SET_PITCHER_PERFORMANCE_PLAYER;
	value: TPitcherPerformancePlayer | undefined;
};
type TSetFiltersEvent = {
	type: typeof SET_FILTERS;
	value: TPitcherPerformanceTableFilters;
};

type TPitcherPerformanceTableEvent =
	| TFetchStatsPlayerPitchingEvent
	| TFetchStatsPlayerPitchingByTeamEvent
	| TFetchAverageAgesEvent
	| TFetchPitcherPerformancePlayerEvent
	| TFetchStatsPlayerPitchingErrorEvent
	| TFetchStatsPlayerPitchingByTeamErrorEvent
	| TFetchAverageAgesErrorEvent
	| TFetchPitcherPerformancePlayerErrorEvent
	| TSetPlayerIdEvent
	| TSetStatsPlayerPitchingEvent
	| TSetStatsPlayerPitchingByTeamEvent
	| TSetAverageAgesEvent
	| TSetPitcherPerformancePlayerEvent
	| TSetFiltersEvent;

export type TPitcherPerformanceTableSend = Interpreter<
	TPitcherPerformanceTableContext,
	IPitcherPerformanceTableStateSchema,
	TPitcherPerformanceTableEvent
>["send"];

const PitcherPerformanceTableMachine = (
	playerIdProp?: number,
	data?: TPitcherPerformanceTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TPitcherPerformanceTableContext, IPitcherPerformanceTableStateSchema, TPitcherPerformanceTableEvent>(
		{
			id: "pitcherPerformanceTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchDataProp,
				filters: {
					gameTypes: [GAME_TYPE_REGULAR_SEASON, GAME_TYPE_POSTSEASON],
					levels: undefined
				},
				statsPlayerPitching: data?.statsPlayerPitching,
				statsPlayerPitchingByTeam: data?.statsPlayerPitchingByTeam,
				averageAges: data?.averageAges,
				pitcherPerformancePlayer: data?.pitcherPerformancePlayer,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						SET_PLAYER_ID: { actions: "setPlayerId" },
						SET_STATS_PLAYER_PITCHING: { actions: "setStatsPlayerPitching" },
						SET_STATS_PLAYER_PITCHING_BYTEAM: { actions: "setStatsPlayerPitchingByTeam" },
						SET_AVERAGE_AGES: { actions: "setAverageAges" },
						SET_PITCHER_PERFORMANCE_PLAYER: { actions: "setPitcherPerformancePlayer" },
						SET_FILTERS: { actions: "setFilters" }
					},
					states: {
						contextRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						statsPlayerPitching: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchStatsPlayerPitching",
												cond: "shouldFetchStatsPlayerPitching"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchStatsPlayerPitching",
									entry: ["refreshStatsPlayerPitchingCancelSource"],
									invoke: {
										src: "fetchStatsPlayerPitching",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchStatsPlayerPitchingSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchStatsPlayerPitchingErrored"
										}
									}
								}
							}
						},
						statsPlayerPitchingByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchStatsPlayerPitchingByTeam",
												cond: "shouldFetchStatsPlayerPitchingByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchStatsPlayerPitchingByTeam",
									entry: ["refreshStatsPlayerPitchingByTeamCancelSource"],
									invoke: {
										src: "fetchStatsPlayerPitchingByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchStatsPlayerPitchingByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchStatsPlayerPitchingByTeamErrored"
										}
									}
								}
							}
						},
						averageAges: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchAverageAges",
												cond: "shouldFetchAverageAges"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchAverageAges",
									entry: ["refreshAverageAgesCancelSource"],
									invoke: {
										src: "fetchAverageAges",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchAverageAgesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchAverageAgesErrored"
										}
									}
								}
							}
						},
						pitcherPerformancePlayer: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPitcherPerformancePlayer",
												cond: "shouldFetchPitcherPerformancePlayer"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPitcherPerformancePlayer",
									entry: ["refreshPitcherPerformancePlayerCancelSource"],
									invoke: {
										src: "fetchPitcherPerformancePlayer",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPitcherPerformancePlayerSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPitcherPerformancePlayerErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (context: TPitcherPerformanceTableContext, _event: TPitcherPerformanceTableEvent) =>
					context.playerId !== context.lastPlayerId,
				shouldFetchStatsPlayerPitching: (
					context: TPitcherPerformanceTableContext,
					_event: TPitcherPerformanceTableEvent
				) =>
					context.statsPlayerPitching === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchStatsPlayerPitchingByTeam: (
					context: TPitcherPerformanceTableContext,
					_event: TPitcherPerformanceTableEvent
				) =>
					context.statsPlayerPitchingByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchAverageAges: (
					context: TPitcherPerformanceTableContext,
					_event: TPitcherPerformanceTableEvent
				) =>
					context.averageAges === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPitcherPerformancePlayer: (
					context: TPitcherPerformanceTableContext,
					_event: TPitcherPerformanceTableEvent
				) =>
					context.pitcherPerformancePlayer === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true
			},
			actions: {
				clearContext: assign<TPitcherPerformanceTableContext, TPitcherPerformanceTableEvent>({
					lastPlayerId: (context: TPitcherPerformanceTableContext, _event: TPitcherPerformanceTableEvent) =>
						context.playerId,
					statsPlayerPitching: (
						_context: TPitcherPerformanceTableContext,
						_event: TPitcherPerformanceTableEvent
					) => undefined,
					statsPlayerPitchingByTeam: (
						_context: TPitcherPerformanceTableContext,
						_event: TPitcherPerformanceTableEvent
					) => undefined,
					averageAges: (_context: TPitcherPerformanceTableContext, _event: TPitcherPerformanceTableEvent) =>
						undefined,
					pitcherPerformancePlayer: (
						_context: TPitcherPerformanceTableContext,
						_event: TPitcherPerformanceTableEvent
					) => undefined,
					cancelSources: (
						context: TPitcherPerformanceTableContext,
						_event: TPitcherPerformanceTableEvent
					) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				// Set Context Actions
				setPlayerId: assign<TPitcherPerformanceTableContext, TPitcherPerformanceTableEvent>({
					playerId: (context: TPitcherPerformanceTableContext, event: TPitcherPerformanceTableEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					},
					cancelSources: (
						context: TPitcherPerformanceTableContext,
						_event: TPitcherPerformanceTableEvent
					) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				setStatsPlayerPitching: assign<TPitcherPerformanceTableContext, TPitcherPerformanceTableEvent>({
					statsPlayerPitching: (
						context: TPitcherPerformanceTableContext,
						event: TPitcherPerformanceTableEvent
					) => {
						if (event.type !== SET_STATS_PLAYER_PITCHING) return context.statsPlayerPitching;
						return event.value;
					},
					cancelSources: (context: TPitcherPerformanceTableContext, event: TPitcherPerformanceTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_STATS_PLAYER_PITCHING) return cancelSources;
						cancelSources[STATS_PLAYER_PITCHING_CANCEL_SOURCE]?.cancel();
						cancelSources[STATS_PLAYER_PITCHING_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setAverageAges: assign<TPitcherPerformanceTableContext, TPitcherPerformanceTableEvent>({
					averageAges: (context: TPitcherPerformanceTableContext, event: TPitcherPerformanceTableEvent) => {
						if (event.type !== SET_AVERAGE_AGES) return context.averageAges;
						return event.value;
					},
					cancelSources: (context: TPitcherPerformanceTableContext, event: TPitcherPerformanceTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_AVERAGE_AGES) return cancelSources;
						cancelSources[AVERAGE_AGES_CANCEL_SOURCE]?.cancel();
						cancelSources[AVERAGE_AGES_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setPitcherPerformancePlayer: assign<TPitcherPerformanceTableContext, TPitcherPerformanceTableEvent>({
					pitcherPerformancePlayer: (
						context: TPitcherPerformanceTableContext,
						event: TPitcherPerformanceTableEvent
					) => {
						if (event.type !== SET_PITCHER_PERFORMANCE_PLAYER) return context.pitcherPerformancePlayer;
						return event.value;
					},
					cancelSources: (context: TPitcherPerformanceTableContext, event: TPitcherPerformanceTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_PITCHER_PERFORMANCE_PLAYER) return cancelSources;
						cancelSources[PITCHER_PERFORMANCE_PLAYER_CANCEL_SOURCE]?.cancel();
						cancelSources[PITCHER_PERFORMANCE_PLAYER_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setFilters: assign<TPitcherPerformanceTableContext, TPitcherPerformanceTableEvent>({
					filters: (context: TPitcherPerformanceTableContext, event: TPitcherPerformanceTableEvent) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return event.value;
					}
				}),
				// Cancel Source Actions
				refreshStatsPlayerPitchingCancelSource: assign<
					TPitcherPerformanceTableContext,
					TPitcherPerformanceTableEvent
				>({
					cancelSources: (
						context: TPitcherPerformanceTableContext,
						_event: TPitcherPerformanceTableEvent
					) => {
						if (context.cancelSources[STATS_PLAYER_PITCHING_CANCEL_SOURCE] != null)
							context.cancelSources[STATS_PLAYER_PITCHING_CANCEL_SOURCE].cancel();
						context.cancelSources[STATS_PLAYER_PITCHING_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshStatsPlayerPitchingByTeamCancelSource: assign<
					TPitcherPerformanceTableContext,
					TPitcherPerformanceTableEvent
				>({
					cancelSources: (
						context: TPitcherPerformanceTableContext,
						_event: TPitcherPerformanceTableEvent
					) => {
						if (context.cancelSources[STATS_PLAYER_PITCHING_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[STATS_PLAYER_PITCHING_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[STATS_PLAYER_PITCHING_BYTEAM_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshAverageAgesCancelSource: assign<TPitcherPerformanceTableContext, TPitcherPerformanceTableEvent>({
					cancelSources: (
						context: TPitcherPerformanceTableContext,
						_event: TPitcherPerformanceTableEvent
					) => {
						if (context.cancelSources[AVERAGE_AGES_CANCEL_SOURCE] != null)
							context.cancelSources[AVERAGE_AGES_CANCEL_SOURCE].cancel();
						context.cancelSources[AVERAGE_AGES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPitcherPerformancePlayerCancelSource: assign<
					TPitcherPerformanceTableContext,
					TPitcherPerformanceTableEvent
				>({
					cancelSources: (
						context: TPitcherPerformanceTableContext,
						_event: TPitcherPerformanceTableEvent
					) => {
						if (context.cancelSources[PITCHER_PERFORMANCE_PLAYER_CANCEL_SOURCE] != null)
							context.cancelSources[PITCHER_PERFORMANCE_PLAYER_CANCEL_SOURCE].cancel();
						context.cancelSources[PITCHER_PERFORMANCE_PLAYER_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchStatsPlayerPitchingSuccess: assign<
					TPitcherPerformanceTableContext,
					TPitcherPerformanceTableEvent
				>({
					statsPlayerPitching: (
						context: TPitcherPerformanceTableContext,
						event: TPitcherPerformanceTableEvent
					) => {
						if (event.type !== FETCH_STATS_PLAYER_PITCHING_DONE) return context.statsPlayerPitching;
						return event.data;
					}
				}),
				handleFetchStatsPlayerPitchingByTeamSuccess: assign<
					TPitcherPerformanceTableContext,
					TPitcherPerformanceTableEvent
				>({
					statsPlayerPitchingByTeam: (
						context: TPitcherPerformanceTableContext,
						event: TPitcherPerformanceTableEvent
					) => {
						if (event.type !== FETCH_STATS_PLAYER_PITCHING_BYTEAM_DONE)
							return context.statsPlayerPitchingByTeam;
						return event.data;
					}
				}),
				handleFetchAverageAgesSuccess: assign<TPitcherPerformanceTableContext, TPitcherPerformanceTableEvent>({
					averageAges: (context: TPitcherPerformanceTableContext, event: TPitcherPerformanceTableEvent) => {
						if (event.type !== FETCH_AVERAGE_AGES_DONE) return context.averageAges;
						return event.data;
					}
				}),
				handleFetchPitcherPerformancePlayerSuccess: assign<
					TPitcherPerformanceTableContext,
					TPitcherPerformanceTableEvent
				>({
					pitcherPerformancePlayer: (
						context: TPitcherPerformanceTableContext,
						event: TPitcherPerformanceTableEvent
					) => {
						if (event.type !== FETCH_PITCHER_PERFORMANCE_PLAYER_DONE)
							return context.pitcherPerformancePlayer;
						return event.data;
					}
				}),
				// Fetch Error Actions
				handleFetchStatsPlayerPitchingErrored: (
					context: TPitcherPerformanceTableContext,
					event: TPitcherPerformanceTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_STATS_PLAYER_PITCHING_ERROR ? event.data : undefined,
						context.toast,
						"Pitcher Performance - Stats Player Pitching",
						"Error fetching player pitching stats by player and season"
					);
				},
				handleFetchStatsPlayerPitchingByTeamErrored: (
					context: TPitcherPerformanceTableContext,
					event: TPitcherPerformanceTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_STATS_PLAYER_PITCHING_BYTEAM_ERROR ? event.data : undefined,
						context.toast,
						"Pitcher Performance - Stats Player Pitching by Team",
						"Error fetching player pitching stats by player, season, and team"
					);
				},
				handleFetchAverageAgesErrored: (
					context: TPitcherPerformanceTableContext,
					event: TPitcherPerformanceTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_AVERAGE_AGES_ERROR ? event.data : undefined,
						context.toast,
						"Pitcher Performance - Average Ages",
						"Error fetching average ages"
					);
				},
				handleFetchPitcherPerformancePlayerErrored: (
					context: TPitcherPerformanceTableContext,
					event: TPitcherPerformanceTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PITCHER_PERFORMANCE_PLAYER_ERROR ? event.data : undefined,
						context.toast,
						"Pitcher Performance - Player Data",
						"Error fetching player data"
					);
				}
			},
			services: {
				fetchStatsPlayerPitching: (context: TPitcherPerformanceTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchStatsPlayerPitching(
							{
								playerId: playerId,
								"source[in]": [SOURCE_GUMBO, SOURCE_STATSAPI].join(","),
								"gameType[in]": [VALID_GAME_TYPES].join(","),
								isUseCache: true
							},
							context.cancelSources[STATS_PLAYER_PITCHING_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchStatsPlayerPitchingByTeam: (context: TPitcherPerformanceTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchStatsPlayerPitchingByTeam(
							{
								playerId: playerId,
								"source[in]": [SOURCE_GUMBO, SOURCE_STATSAPI].join(","),
								"gameType[in]": [VALID_GAME_TYPES].join(","),
								isUseCache: true
							},
							context.cancelSources[STATS_PLAYER_PITCHING_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchAverageAges: (context: TPitcherPerformanceTableContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchAverageAges({}, context.cancelSources[AVERAGE_AGES_CANCEL_SOURCE]?.token);
					return promiseWRetry(fetchFunc);
				},
				fetchPitcherPerformancePlayer: (context: TPitcherPerformanceTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayer<TPitcherPerformancePlayer>(
							{
								id: playerId,
								isUseCombinedId: true,
								fields: "birthDate"
							},
							context.cancelSources[PITCHER_PERFORMANCE_PLAYER_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PitcherPerformanceTableMachine;
