import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useMachine, useSelector } from "@xstate/react";
import { Box, HStack, useToast } from "@chakra-ui/react";

import { POSITION_GROUP_POSITION_PLAYER } from "_react/shared/_constants/position_groups";
import { PITCH_TYPES } from "_react/shared/_constants/pitch_types";
import { TPitchTypes } from "_react/shared/_types/pitch_types";
import { getPositionGroupFromPosition } from "_react/shared/_helpers/position_groups";
import { IPlayerSeasonArsenalScoresSchema } from "_react/shared/data_models/arsenal_scores/_types";
import { THROWS_S } from "_react/shared/data_models/player/_constants";
import {
	BATS_OVERALL,
	THROWS_OVERALL,
	THROWS_L,
	THROWS_R,
	PLAYING_LEVEL_AMA
} from "_react/shared/data_models/seasonal_grades/_constants";
import { LEVEL_HS } from "_react/shared/data_models/team/_constants";
import Frog from "_react/shared/ui/icons/Frog";
import ToggleButton from "_react/shared/ui/presentation/components/ToggleButton/ToggleButton";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import {
	ToggleTopBreakpointValue,
	TogglePositionBreakpointValue,
	ToggleJustifyContentBreakpointValue,
	ToggleGapBreakpointValue,
	ContentPaddingTopBreakpointValue,
	TOGGLE_STYLE
} from "_react/playerpage/_styles";
import { PlayerPageAmaContext } from "_react/playerpage/ama/PlayerPageAmaProvider";
import { SET_PLAYER_ID, TPlayerPageAmaState } from "_react/playerpage/ama/_machine";
import { BatterHandednessToggleOptions, PitcherHandednessToggleOptions } from "_react/playerpage/_constants";
import ArsenalTrendsSection from "_react/playerpage/shared/content/observed/ArsenalTrendsSection";
import HittingSection from "_react/playerpage/shared/content/observed/HittingSection";
import StuffSection from "_react/playerpage/shared/content/observed/StuffSection";
import LocationsSection from "_react/playerpage/shared/content/observed/LocationsSection";
import { getObservedAmaSeason } from "_react/playerpage/ama/shared/_helpers";
import ObservedTabMachine, {
	FETCHING_ARSENAL_SCORES_THRESHOLD,
	FETCHING_PLAYER_SEASON_ARSENAL_SCORES,
	SET_ARSENAL_SCORES_THRESHOLD,
	SET_PLAYER_SEASON_ARSENAL_SCORES,
	SET_SEASON_FILTER,
	TObservedTabContext
} from "_react/playerpage/ama/content/observed/_machine";
import { TObservedTabData } from "_react/playerpage/ama/content/observed/_types";
import FieldingSection from "_react/playerpage/ama/content/observed/FieldingSection";
import { PLAYER_TYPE_BATTER, PLAYER_TYPE_PITCHER } from "_react/shared/data_models/phred/_constants";
import Baseball from "_react/shared/ui/icons/Baseball";
import BaseballBat from "_react/shared/ui/icons/BaseballBat";
import { BaseballBatIconStyle, BaseballIconStyle } from "_react/playerpage/ama/shared/_styles";

type TObservedTabProps = {
	player: TPlayerPageCombinedPlayer;
	isTwoWayPlayer: boolean;
	isSelected?: boolean;
	data?: TObservedTabData;
	shouldFetchData?: boolean;
};

const ObservedTab = ({
	player,
	isTwoWayPlayer,
	isSelected = true,
	data,
	shouldFetchData = true
}: TObservedTabProps) => {
	const toast = useToast();
	const [current, send] = useMachine(ObservedTabMachine(player?.id, shouldFetchData, data, toast));
	const { arsenalScoresThreshold, playerSeasonArsenalScores, seasonFilter } = current.context as TObservedTabContext;
	const throws = player?.amaProfile?.throws ?? player?.throws;

	const isFetchingArsenalScoresThreshold = shouldFetchData
		? current.matches(FETCHING_ARSENAL_SCORES_THRESHOLD)
		: data?.isLoading;
	const isFetchingPlayerSeasonArsenalScores = shouldFetchData
		? current.matches(FETCHING_PLAYER_SEASON_ARSENAL_SCORES)
		: data?.isLoading;

	// Toggles for handedness
	const [batsFilter, setBatsFilter] = useState<string>(BATS_OVERALL);
	const [throwsFilter, setThrowsFilter] = useState<string>(THROWS_OVERALL);
	// Season toggle -> default is current season as defined by mesa.important_date
	const setSeasonFilter = useCallback(
		(seasonFilter: number | undefined) => {
			if (seasonFilter !== undefined) send({ type: SET_SEASON_FILTER, data: seasonFilter });
		},
		[send]
	);

	const playerPageAmaContext = useContext(PlayerPageAmaContext);

	const isFetchingCurrentSeason: boolean | undefined = useSelector(
		playerPageAmaContext.playerPageAmaService,
		(state: TPlayerPageAmaState) => state.context.currentSeasonData?.isFetching
	);

	const currentSeason: number | undefined = getObservedAmaSeason(player);

	const positionGroup = getPositionGroupFromPosition(player?.position, false);

	const [playerType, setPlayerType] = useState(
		positionGroup !== POSITION_GROUP_POSITION_PLAYER ? PLAYER_TYPE_PITCHER : PLAYER_TYPE_BATTER
	);

	const isSwitchPitcher = Boolean(throws && throws === THROWS_S && playerType === PLAYER_TYPE_PITCHER);
	const [pitcherThrowsFilter, setPitcherThrowsFilter] = useState<string | undefined>(
		isSwitchPitcher ? THROWS_L : undefined
	);

	const setPlayerTypeToggle = (playerType: string | number) => {
		if (typeof playerType !== "string") return;
		setPlayerType(playerType);
	};

	const setPitcherThrowsToggle = (playerType: string | number) => {
		if (typeof playerType !== "string") return;
		setPitcherThrowsFilter(playerType);
	};

	useEffect(() => {
		const defaultSelectedSeason = player?.amaProfile?.team?.level === LEVEL_HS ? currentSeason - 1 : currentSeason;
		setSeasonFilter(defaultSelectedSeason);
	}, [player?.amaProfile?.team?.level, currentSeason, setSeasonFilter]);

	// Update machine context when data prop changes
	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: player?.id });
	}, [player?.id, send]);
	useEffect(() => {
		if (data?.playerSeasonArsenalScores !== playerSeasonArsenalScores && !shouldFetchData) {
			send({ type: SET_PLAYER_SEASON_ARSENAL_SCORES, data: data?.playerSeasonArsenalScores });
		}
	}, [data?.playerSeasonArsenalScores, playerSeasonArsenalScores, shouldFetchData, send]);
	useEffect(() => {
		if (data?.arsenalScoresThreshold !== arsenalScoresThreshold && !shouldFetchData) {
			send({ type: SET_ARSENAL_SCORES_THRESHOLD, data: data?.arsenalScoresThreshold });
		}
	}, [data?.arsenalScoresThreshold, arsenalScoresThreshold, shouldFetchData, send]);

	// Season options should be the current season, current season - 1 and current season - 2
	const seasonOptions = useMemo(() => {
		if (isFetchingCurrentSeason) {
			return [{ label: "Current Season", value: 0 }];
		}
		if (currentSeason) {
			const offsets = [0, 1, 2];
			return offsets.map(offset => {
				const season = currentSeason - offset;
				return { label: `${season}`, value: season };
			});
		}
		return [];
	}, [isFetchingCurrentSeason, currentSeason]);

	// Get pitch types from playerSeasonArsenalScores to display in the ArsenalTrendsSection
	const pitchTypes: TPitchTypes[] | undefined = useMemo(
		() =>
			playerSeasonArsenalScores?.reduce((acc: Array<TPitchTypes>, curr: IPlayerSeasonArsenalScoresSchema) => {
				const pitchType = curr.pitchType as TPitchTypes;
				if (PITCH_TYPES.includes(pitchType) && !acc.includes(pitchType))
					acc.push(curr.pitchType as TPitchTypes);
				return acc;
			}, []),
		[playerSeasonArsenalScores]
	);

	return (
		<Box position="relative" top={"-8"}>
			<HStack
				top={ToggleTopBreakpointValue}
				position={TogglePositionBreakpointValue}
				justifyContent={ToggleJustifyContentBreakpointValue}
				gap={ToggleGapBreakpointValue}
				zIndex={1}
			>
				{isSwitchPitcher && (
					<ToggleButton
						toggleOptions={[
							{
								value: THROWS_L,
								label: "L",
								icon:
									pitcherThrowsFilter === THROWS_L ? (
										<Frog sx={{ color: "gray.500", marginRight: "1" }} />
									) : (
										undefined
									)
							},
							{
								value: THROWS_R,
								label: "R",
								icon:
									pitcherThrowsFilter === THROWS_R ? (
										<Frog sx={{ color: "gray.500", marginRight: "1" }} />
									) : (
										undefined
									)
							}
						]}
						value={pitcherThrowsFilter}
						onSelect={setPitcherThrowsToggle}
						style={TOGGLE_STYLE}
					/>
				)}
				{isTwoWayPlayer && (
					<ToggleButton
						toggleOptions={[
							{
								value: PLAYER_TYPE_BATTER,
								label: "Position Player",
								icon: <BaseballBat sx={BaseballBatIconStyle} />
							},
							{
								value: PLAYER_TYPE_PITCHER,
								label: "Pitcher",
								icon: <Baseball sx={BaseballIconStyle} />
							}
						]}
						value={playerType}
						onSelect={setPlayerTypeToggle}
						style={TOGGLE_STYLE}
					/>
				)}
				<ToggleButton
					toggleOptions={seasonOptions}
					value={seasonFilter}
					onSelect={(value: string | number) =>
						typeof value === "string" ? setSeasonFilter(parseInt(value)) : setSeasonFilter(value)
					}
					isLoading={isFetchingCurrentSeason}
					style={TOGGLE_STYLE}
				/>
				{playerType === PLAYER_TYPE_BATTER && (
					<ToggleButton
						toggleOptions={PitcherHandednessToggleOptions}
						value={throwsFilter}
						onSelect={(value: string | number) =>
							typeof value === "number" ? setThrowsFilter(value.toString()) : setThrowsFilter(value)
						}
						style={TOGGLE_STYLE}
					/>
				)}
				{playerType === PLAYER_TYPE_PITCHER && (
					<ToggleButton
						toggleOptions={BatterHandednessToggleOptions}
						value={batsFilter}
						onSelect={(value: string | number) =>
							typeof value === "number" ? setBatsFilter(value.toString()) : setBatsFilter(value)
						}
						style={TOGGLE_STYLE}
					/>
				)}
			</HStack>

			<Box paddingTop={ContentPaddingTopBreakpointValue}>
				{isFetchingCurrentSeason && !seasonFilter && <Box className="loading-item" height="md" width="100%" />}
				{playerType === PLAYER_TYPE_PITCHER && seasonFilter && (
					<>
						<StuffSection
							player={player}
							playingLevel={PLAYING_LEVEL_AMA}
							seasonFilter={seasonFilter}
							batsFilter={batsFilter}
							throwsFilter={pitcherThrowsFilter}
							shouldFetchArsenalStuffDistributionsSecondaryData={false}
							arsenalStuffDistributionsSecondaryData={{
								arsenalScoresThreshold: arsenalScoresThreshold,
								arsenalStuffDistributionsPlayer: {
									throws: player.throws
								},
								isLoading: isFetchingArsenalScoresThreshold
							}}
						/>
						{/* Not rendering the Locations section when other tabs are selected due to the IV heatmaps */}
						{isSelected && (
							<LocationsSection
								player={player}
								seasonFilter={seasonFilter}
								batsFilter={batsFilter}
								throwsFilter={pitcherThrowsFilter}
								useCountSplits={false}
								playingLevel={PLAYING_LEVEL_AMA}
								shouldFetchArsenalLocationTilesSecondaryData={false}
								arsenalLocationTilesSecondaryData={{
									arsenalScoresThreshold: arsenalScoresThreshold,
									arsenalLocationTilesPlayer: {
										throws: player.throws
									},
									isLoading: isFetchingArsenalScoresThreshold
								}}
							/>
						)}
						<ArsenalTrendsSection
							player={player}
							playingLevel={PLAYING_LEVEL_AMA}
							seasonFilter={seasonFilter}
							batsFilter={batsFilter}
							throwsFilter={pitcherThrowsFilter}
							shouldFetchPlayerPitchTypeLabelsData={false}
							playerPitchTypeLabelsData={{
								pitchTypes: pitchTypes,
								isLoading: isFetchingPlayerSeasonArsenalScores
							}}
							shouldFetchPitcherMetricOverTimeSecondaryPlotData={false}
							pitcherMetricOverTimeSecondaryPlotData={{
								arsenalScoresThreshold: arsenalScoresThreshold,
								playerSeasonArsenalScores: playerSeasonArsenalScores,
								isLoading: isFetchingArsenalScoresThreshold || isFetchingPlayerSeasonArsenalScores
							}}
							shouldFetchPitcherMetricRidgelineSecondaryPlotData={false}
							pitcherMetricRidgelineSecondaryPlotData={{
								arsenalScoresThreshold: arsenalScoresThreshold,
								playerSeasonArsenalScores: playerSeasonArsenalScores,
								isLoading: isFetchingArsenalScoresThreshold || isFetchingPlayerSeasonArsenalScores
							}}
							shouldFetchPitcherMetricContourSecondaryPlotData={false}
							pitcherMetricContourSecondaryPlotData={{
								arsenalScoresThreshold: arsenalScoresThreshold,
								playerSeasonArsenalScores: playerSeasonArsenalScores,
								playerHeight: { playerId: player.id, height: player.height },
								isLoading: isFetchingArsenalScoresThreshold || isFetchingPlayerSeasonArsenalScores
							}}
						/>
					</>
				)}
				{playerType === PLAYER_TYPE_BATTER && seasonFilter && (
					<>
						<HittingSection
							player={player}
							playingLevel={PLAYING_LEVEL_AMA}
							seasonFilter={seasonFilter}
							throwsFilter={throwsFilter}
						/>
						<FieldingSection player={player} />
					</>
				)}
			</Box>
		</Box>
	);
};

export default ObservedTab;
