import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import {
	GAME_TYPE_OVERALL,
	PITCH_TYPE_OVERALL,
	PLAYING_LEVEL_AMA,
	PLAYING_LEVEL_INTL,
	PLAYING_LEVEL_PRO
} from "_react/shared/data_models/arsenal_scores/_constants";
import { fetchArmSlotsMonthly } from "_react/shared/data_models/arm_slots/_network";
import { TPlayingLevel } from "_react/shared/data_models/hitter_grades/_types";
import { fetchPlayerMetricJointProbabilityDensities } from "_react/shared/data_models/metric/_network";
import { fetchPlayer } from "_react/shared/data_models/player/_network";
import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { IArmSlotsMonthly } from "_react/shared/data_models/arm_slots/_types";
import {
	IArsenalScoresThresholdApiResponse,
	IPlayerSeasonArsenalScoresSchema
} from "_react/shared/data_models/arsenal_scores/_types";
import {
	fetchArsenalScoresThreshold,
	fetchPlayerSeasonArsenalScores
} from "_react/shared/data_models/arsenal_scores/_network";
import { IMetricJointProbabilityDensities, TMetricGroup } from "_react/shared/data_models/metric/_types";
import { BATS_OVERALL, COUNT_SPLIT_OVERALL } from "_react/shared/data_models/seasonal_grades/_constants";

import {
	TPitcherMetricContourPrimaryPlotData,
	TPitcherMetricContourSecondaryPlotData
} from "_react/shared/ui/data/plots/PitcherMetricContourPlot/PitcherMetricContourPlot";
import { PITCH_MOVEMENT_GROUP, RELEASE_POINT_GROUP } from "_react/shared/data_models/metric/_constants";

const JOINT_PROBABILITY_DENSITIES_CANCEL_SOURCE = "jointProbabilityDensities";
const ARM_SLOTS_MONTHLY_CANCEL_SOURCE = "armSlotsMonthly";
const PLAYER_HEIGHT_CANCEL_SOURCE = "playerHeight";
const ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE = "arsenalScoresThreshold";
const PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE = "playerSeasonArsenalScores";

export type TPitcherMetricContourPlotCancelSource = {
	[ARM_SLOTS_MONTHLY_CANCEL_SOURCE]?: CancelTokenSource;
	[JOINT_PROBABILITY_DENSITIES_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_HEIGHT_CANCEL_SOURCE]?: CancelTokenSource;
	[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TJointProbabilityDensities = Record<string, Array<IMetricJointProbabilityDensities> | null>;
export type TPlayerHeight = { playerId: number; height: number | null };

export type TPitcherMetricContourPlotContext = {
	batsFilter: string;
	throwsFilter?: string;
	seasonFilter: number;
	lastSeasonFilter: number;
	playerId?: number;
	lastPlayerId?: number;
	playingLevel?: TPlayingLevel;
	lastPlayingLevel?: string;
	metricGroup?: TMetricGroup;
	lastMetricGroup?: TMetricGroup;
	shouldFetchPrimaryData?: boolean;
	shouldFetchSecondaryData?: boolean;
	jointProbabilityDensities: TJointProbabilityDensities;
	armSlotsMonthly: Array<IArmSlotsMonthly> | undefined;
	playerHeight: TPlayerHeight | undefined;
	playerSeasonArsenalScores: Array<IPlayerSeasonArsenalScoresSchema> | undefined;
	arsenalScoresThreshold?: Array<IArsenalScoresThresholdApiResponse> | null;
	cancelSources: TPitcherMetricContourPlotCancelSource;
	toast?: CreateToastFnReturn;
};

interface IPitcherMetricContourPlotStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId prop changes
				playerIdRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				armSlotsMonthly: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches joint probability densities data for a player and metric group
				jointProbabilityDensities: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				playerHeight: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches arsenal scores thresholds data
				arsenalScoresThreshold: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches player's seasonal arsenal scores
				playerSeasonArsenalScores: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_ARM_SLOTS_MONTHLY = "SET_ARM_SLOTS_MONTHLY";
export const SET_JOINT_PROBABILITY_DENSITIES = "SET_JOINT_PROBABILITY_DENSITIES";
export const SET_PLAYER_HEIGHT = "SET_PLAYER_HEIGHT";
export const SET_ARSENAL_SCORES_THRESHOLD = "SET_ARSENAL_SCORES_THRESHOLD";
export const SET_PLAYER_SEASON_ARSENAL_SCORES = "SET_PLAYER_SEASON_ARSENAL_SCORES";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_METRIC_GROUP = "SET_METRIC_GROUP";
export const SET_SEASON_FILTER = "SET_SEASON_FILTER";
export const SET_BATS_FILTER = "SET_BATS_FILTER";
export const SET_THROWS_FILTER = "SET_THROWS_FILTER";
export const FETCHING_ARM_SLOTS_MONTHLY = {
	initialized: { armSlotsMonthly: "fetching" }
};
export const FETCHING_JOINT_PROBABILITY_DENSITIES = {
	initialized: { jointProbabilityDensities: "fetching" }
};
export const FETCHING_PLAYER_HEIGHT = {
	initialized: { playerHeight: "fetching" }
};
export const FETCHING_ARSENAL_SCORES_THRESHOLD = { initialized: { arsenalScoresThreshold: "fetching" } };
export const FETCHING_PLAYER_SEASON_ARSENAL_SCORES = {
	initialized: { playerSeasonArsenalScores: "fetching" }
};

const FETCH_ARM_SLOTS_MONTHLY_DONE = "done.invoke.fetchingArmSlotsMonthly:invocation[0]";
const FETCH_PROBABILITY_DENSITIES_DONE = "done.invoke.fetchingJointProbabilityDensities:invocation[0]";
const FETCH_PLAYER_HEIGHT_DONE = "done.invoke.fetchingPlayerHeight:invocation[0]";
const FETCH_ARSENAL_SCORES_THRESHOLD_DONE = "done.invoke.fetchingArsenalScoresThreshold:invocation[0]";
const FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE = "done.invoke.fetchingPlayerSeasonArsenalScores:invocation[0]";

const FETCH_ARM_SLOTS_MONTHLY_ERROR = "error.platform.fetchingArmSlotsMonthly:invocation[0]";
const FETCH_PROBABILITY_DENSITIES_ERROR = "error.platform.fetchingJointProbabilityDensities:invocation[0]";
const FETCH_PLAYER_HEIGHT_ERROR = "error.platform.fetchingPlayerHeight:invocation[0]";
const FETCH_ARSENAL_SCORES_THRESHOLD_ERROR = "error.platform.fetchingArsenalScoresThreshold:invocation[0]";
const FETCH_PLAYER_SEASON_ARSENAL_SCORES_ERROR = "error.platform.fetchingPlayerSeasonArsenalScores:invocation[0]";

type TSetArmSlotsMonthlyEvent = {
	type: typeof SET_ARM_SLOTS_MONTHLY;
	data: Array<IArmSlotsMonthly>;
};
type TSetJointProbabilityDensitiesEvent = {
	type: typeof SET_JOINT_PROBABILITY_DENSITIES;
	data: TJointProbabilityDensities;
};
type TSetPlayerHeightEvent = {
	type: typeof SET_PLAYER_HEIGHT;
	data: TPlayerHeight | undefined;
};
type TSetArsenalScoresThresholdEvent = {
	type: typeof SET_ARSENAL_SCORES_THRESHOLD;
	data: Array<IArsenalScoresThresholdApiResponse> | null | undefined;
};
type TSetPlayerSeasonArsenalScoresEvent = {
	type: typeof SET_PLAYER_SEASON_ARSENAL_SCORES;
	data: Array<IPlayerSeasonArsenalScoresSchema> | undefined;
};
type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number | undefined;
};
type TSetMetricGroupEvent = {
	type: typeof SET_METRIC_GROUP;
	data: TMetricGroup | undefined;
};
type TSetSeasonFilterEvent = {
	type: typeof SET_SEASON_FILTER;
	data: number;
};
type TSetBatsFilterEvent = {
	type: typeof SET_BATS_FILTER;
	data: string;
};
type TSetThrowsFilterEvent = {
	type: typeof SET_THROWS_FILTER;
	data: string | undefined;
};
type TFetchArmSlotsMonthlyEvent = {
	type: typeof FETCH_ARM_SLOTS_MONTHLY_DONE;
	data?: Array<IArmSlotsMonthly>;
};
type TFetchJointProbabilityDensitiesEvent = {
	type: typeof FETCH_PROBABILITY_DENSITIES_DONE;
	data?: Array<IMetricJointProbabilityDensities>;
};
type TFetchPlayerHeightEvent = {
	type: typeof FETCH_PLAYER_HEIGHT_DONE;
	data?: TPlayerHeight;
};
type TFetchArsenalScoresThresholdEvent = {
	type: typeof FETCH_ARSENAL_SCORES_THRESHOLD_DONE;
	data?: Array<IArsenalScoresThresholdApiResponse>;
};
type TFetchPlayerSeasonArsenalScoresEvent = {
	type: typeof FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE;
	data?: Array<IPlayerSeasonArsenalScoresSchema>;
};
type TFetchArmSlotsMonthlyErrorEvent = {
	type: typeof FETCH_ARM_SLOTS_MONTHLY_ERROR;
	data?: AxiosError | Error;
};
type TFetchJointProbabilityDensitiesErrorEvent = {
	type: typeof FETCH_PROBABILITY_DENSITIES_ERROR;
	data?: AxiosError | Error;
};
type TFetchPlayerHeightErrorEvent = {
	type: typeof FETCH_PLAYER_HEIGHT_ERROR;
	data?: AxiosError | Error;
};
type TFetchArsenalScoresThresholdErrorEvent = {
	type: typeof FETCH_ARSENAL_SCORES_THRESHOLD_ERROR;
	data?: AxiosError | Error;
};
type TFetchPlayerSeasonArsenalScoresErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_ARSENAL_SCORES_ERROR;
	data?: AxiosError | Error;
};

type TPitcherMetricContourPlotEvent =
	| TSetArmSlotsMonthlyEvent
	| TSetJointProbabilityDensitiesEvent
	| TSetPlayerHeightEvent
	| TSetArsenalScoresThresholdEvent
	| TSetPlayerSeasonArsenalScoresEvent
	| TSetPlayerIdEvent
	| TSetMetricGroupEvent
	| TSetSeasonFilterEvent
	| TSetBatsFilterEvent
	| TSetThrowsFilterEvent
	| TFetchArmSlotsMonthlyEvent
	| TFetchJointProbabilityDensitiesEvent
	| TFetchPlayerHeightEvent
	| TFetchArsenalScoresThresholdEvent
	| TFetchPlayerSeasonArsenalScoresEvent
	| TFetchArmSlotsMonthlyErrorEvent
	| TFetchJointProbabilityDensitiesErrorEvent
	| TFetchPlayerHeightErrorEvent
	| TFetchArsenalScoresThresholdErrorEvent
	| TFetchPlayerSeasonArsenalScoresErrorEvent;

export type TPitcherMetricContourPlotSend = Interpreter<
	TPitcherMetricContourPlotContext,
	IPitcherMetricContourPlotStateSchema,
	TPitcherMetricContourPlotEvent
>["send"];

const PitcherMetricContourPlotMachine = (
	batsFilterProp: string,
	seasonFilterProp: number,
	throwsFilterProp?: string,
	playerIdProp?: number,
	playingLevelProp?: TPlayingLevel,
	metricGroupProp?: TMetricGroup,
	shouldFetchPrimaryData = true,
	primaryData?: TPitcherMetricContourPrimaryPlotData,
	shouldFetchSecondaryData = true,
	secondaryData?: TPitcherMetricContourSecondaryPlotData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TPitcherMetricContourPlotContext, IPitcherMetricContourPlotStateSchema, TPitcherMetricContourPlotEvent>(
		{
			id: "PitcherMetricContourPlot",
			initial: "initializing",
			context: {
				batsFilter: batsFilterProp,
				throwsFilter: throwsFilterProp,
				seasonFilter: seasonFilterProp,
				lastSeasonFilter: seasonFilterProp,
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				playingLevel: playingLevelProp,
				lastPlayingLevel: playingLevelProp,
				metricGroup: metricGroupProp,
				lastMetricGroup: metricGroupProp,
				shouldFetchPrimaryData: shouldFetchPrimaryData,
				shouldFetchSecondaryData: shouldFetchSecondaryData,
				armSlotsMonthly: primaryData?.armSlotsMonthly,
				jointProbabilityDensities: primaryData?.jointProbabilityDensities ?? {},
				playerSeasonArsenalScores: secondaryData?.playerSeasonArsenalScores,
				playerHeight: secondaryData?.playerHeight,
				arsenalScoresThreshold: secondaryData?.arsenalScoresThreshold,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: {
						target: "initialized"
					}
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_ARM_SLOTS_MONTHLY]: { actions: "setArmSlotsMonthly" },
						[SET_JOINT_PROBABILITY_DENSITIES]: { actions: "setJointProbabilityDensities" },
						[SET_PLAYER_HEIGHT]: { actions: "setPlayerHeight" },
						[SET_ARSENAL_SCORES_THRESHOLD]: { actions: "setArsenalScoresThreshold" },
						[SET_PLAYER_SEASON_ARSENAL_SCORES]: { actions: "setPlayerSeasonArsenalScores" },
						[SET_PLAYER_ID]: { actions: "setPlayerId" },
						[SET_METRIC_GROUP]: { actions: "setMetricGroup" },
						[SET_SEASON_FILTER]: { actions: "setSeasonFilter" },
						[SET_BATS_FILTER]: { actions: "setBatsFilter" },
						[SET_THROWS_FILTER]: { actions: "setThrowsFilter" }
					},
					states: {
						playerIdRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						armSlotsMonthly: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingArmSlotsMonthly",
												cond: "shouldFetchArmSlotsMonthly"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingArmSlotsMonthly",
									entry: ["refreshArmSlotsMonthlyCancelSource"],
									invoke: {
										src: "fetchArmSlotsMonthly",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchArmSlotsMonthlySuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchArmSlotsMonthlyErrored"
										}
									}
								}
							}
						},
						jointProbabilityDensities: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingJointProbabilityDensities",
												cond: "shouldFetchJointProbabilityDensities"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingJointProbabilityDensities",
									entry: ["refreshJointProbabilityDensitiesCancelSource"],
									invoke: {
										src: "fetchJointProbabilityDensities",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchJointProbabilityDensitiesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchJointProbabilityDensitiesErrored"
										}
									}
								}
							}
						},
						playerHeight: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingPlayerHeight",
												cond: "shouldFetchPlayerHeight"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingPlayerHeight",
									entry: ["refreshPlayerHeightCancelSource"],
									invoke: {
										src: "fetchPlayerHeight",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerHeightSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerHeightErrored"
										}
									}
								}
							}
						},
						arsenalScoresThreshold: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingArsenalScoresThreshold",
												cond: "shouldFetchArsenalScoresThreshold"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingArsenalScoresThreshold",
									entry: ["refreshArsenalScoresThresholdCancelSource"],
									invoke: {
										src: "fetchArsenalScoresThreshold",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchArsenalScoresThresholdSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchArsenalScoresThresholdErrored"
										}
									}
								}
							}
						},
						playerSeasonArsenalScores: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingPlayerSeasonArsenalScores",
												cond: "shouldFetchPlayerSeasonArsenalScores"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingPlayerSeasonArsenalScores",
									entry: ["refreshPlayerSeasonArsenalScoresCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonArsenalScores",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonArsenalScoresSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonArsenalScoresErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (
					context: TPitcherMetricContourPlotContext,
					_event: TPitcherMetricContourPlotEvent
				) =>
					context.playerId !== context.lastPlayerId ||
					context.playingLevel !== context.lastPlayingLevel ||
					context.metricGroup !== context.lastMetricGroup ||
					context.seasonFilter !== context.lastSeasonFilter,
				shouldFetchArmSlotsMonthly: (
					context: TPitcherMetricContourPlotContext,
					_event: TPitcherMetricContourPlotEvent
				) => {
					const { armSlotsMonthly, playerId, playingLevel, metricGroup } = context;
					return (
						armSlotsMonthly === undefined &&
						shouldFetchPrimaryData &&
						playerId !== undefined &&
						playingLevel === PLAYING_LEVEL_PRO &&
						metricGroup === PITCH_MOVEMENT_GROUP
					);
				},
				shouldFetchJointProbabilityDensities: (
					context: TPitcherMetricContourPlotContext,
					_event: TPitcherMetricContourPlotEvent
				) => {
					const { jointProbabilityDensities, batsFilter, throwsFilter, playerId } = context;
					return (
						jointProbabilityDensities[`${batsFilter}-${throwsFilter}`] === undefined &&
						shouldFetchPrimaryData &&
						playerId !== undefined
					);
				},
				shouldFetchPlayerHeight: (
					context: TPitcherMetricContourPlotContext,
					_event: TPitcherMetricContourPlotEvent
				) => {
					const { playerHeight, playerId, metricGroup } = context;
					return (
						playerHeight === undefined &&
						shouldFetchSecondaryData &&
						playerId !== undefined &&
						metricGroup === RELEASE_POINT_GROUP
					);
				},
				shouldFetchArsenalScoresThreshold: (
					context: TPitcherMetricContourPlotContext,
					_event: TPitcherMetricContourPlotEvent
				) =>
					context.arsenalScoresThreshold === undefined &&
					(context.playingLevel === PLAYING_LEVEL_AMA || context.playingLevel === PLAYING_LEVEL_INTL) &&
					shouldFetchSecondaryData,
				shouldFetchPlayerSeasonArsenalScores: (
					context: TPitcherMetricContourPlotContext,
					_event: TPitcherMetricContourPlotEvent
				) => {
					const { playerSeasonArsenalScores, playerId, playingLevel } = context;
					return (
						playerSeasonArsenalScores === undefined &&
						shouldFetchSecondaryData &&
						playerId !== undefined &&
						(playingLevel === PLAYING_LEVEL_AMA || playingLevel === PLAYING_LEVEL_INTL)
					);
				}
			},
			actions: {
				setArmSlotsMonthly: assign<TPitcherMetricContourPlotContext, TPitcherMetricContourPlotEvent>({
					armSlotsMonthly: (
						context: TPitcherMetricContourPlotContext,
						event: TPitcherMetricContourPlotEvent
					) => {
						if (event.type !== SET_ARM_SLOTS_MONTHLY) return context.armSlotsMonthly;
						return event.data;
					},
					cancelSources: (
						context: TPitcherMetricContourPlotContext,
						event: TPitcherMetricContourPlotEvent
					) => {
						if (event.type !== SET_ARM_SLOTS_MONTHLY) return context.cancelSources;
						if (context.cancelSources[ARM_SLOTS_MONTHLY_CANCEL_SOURCE] != null)
							context.cancelSources[ARM_SLOTS_MONTHLY_CANCEL_SOURCE].cancel();
						delete context.cancelSources[ARM_SLOTS_MONTHLY_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setJointProbabilityDensities: assign<TPitcherMetricContourPlotContext, TPitcherMetricContourPlotEvent>({
					jointProbabilityDensities: (
						context: TPitcherMetricContourPlotContext,
						event: TPitcherMetricContourPlotEvent
					) => {
						if (event.type !== SET_JOINT_PROBABILITY_DENSITIES) return context.jointProbabilityDensities;
						return event.data;
					},
					cancelSources: (
						context: TPitcherMetricContourPlotContext,
						event: TPitcherMetricContourPlotEvent
					) => {
						if (event.type !== SET_JOINT_PROBABILITY_DENSITIES) return context.cancelSources;
						if (context.cancelSources[JOINT_PROBABILITY_DENSITIES_CANCEL_SOURCE] != null)
							context.cancelSources[JOINT_PROBABILITY_DENSITIES_CANCEL_SOURCE].cancel();
						delete context.cancelSources[JOINT_PROBABILITY_DENSITIES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerHeight: assign<TPitcherMetricContourPlotContext, TPitcherMetricContourPlotEvent>({
					playerHeight: (
						context: TPitcherMetricContourPlotContext,
						event: TPitcherMetricContourPlotEvent
					) => {
						if (event.type !== SET_PLAYER_HEIGHT) return context.playerHeight;
						return event.data;
					},
					cancelSources: (
						context: TPitcherMetricContourPlotContext,
						event: TPitcherMetricContourPlotEvent
					) => {
						if (event.type !== SET_PLAYER_HEIGHT) return context.cancelSources;
						if (context.cancelSources[PLAYER_HEIGHT_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_HEIGHT_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_HEIGHT_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setArsenalScoresThreshold: assign<TPitcherMetricContourPlotContext, TPitcherMetricContourPlotEvent>({
					arsenalScoresThreshold: (
						context: TPitcherMetricContourPlotContext,
						event: TPitcherMetricContourPlotEvent
					) => {
						if (event.type !== SET_ARSENAL_SCORES_THRESHOLD) return context.arsenalScoresThreshold;
						return event.data;
					},
					cancelSources: (
						context: TPitcherMetricContourPlotContext,
						event: TPitcherMetricContourPlotEvent
					) => {
						if (event.type !== SET_ARSENAL_SCORES_THRESHOLD) return context.cancelSources;
						if (context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE].cancel();
						delete context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerSeasonArsenalScores: assign<TPitcherMetricContourPlotContext, TPitcherMetricContourPlotEvent>({
					playerSeasonArsenalScores: (
						context: TPitcherMetricContourPlotContext,
						event: TPitcherMetricContourPlotEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES) return context.playerSeasonArsenalScores;
						return event.data;
					},
					cancelSources: (
						context: TPitcherMetricContourPlotContext,
						event: TPitcherMetricContourPlotEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES) return context.cancelSources;
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerId: assign<TPitcherMetricContourPlotContext, TPitcherMetricContourPlotEvent>({
					playerId: (context: TPitcherMetricContourPlotContext, event: TPitcherMetricContourPlotEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				setMetricGroup: assign<TPitcherMetricContourPlotContext, TPitcherMetricContourPlotEvent>({
					metricGroup: (context: TPitcherMetricContourPlotContext, event: TPitcherMetricContourPlotEvent) => {
						if (event.type !== SET_METRIC_GROUP) return context.metricGroup;
						return event.data;
					}
				}),
				setBatsFilter: assign<TPitcherMetricContourPlotContext, TPitcherMetricContourPlotEvent>({
					batsFilter: (context: TPitcherMetricContourPlotContext, event: TPitcherMetricContourPlotEvent) => {
						if (event.type !== SET_BATS_FILTER) return context.batsFilter;
						return event.data;
					}
				}),
				setThrowsFilter: assign<TPitcherMetricContourPlotContext, TPitcherMetricContourPlotEvent>({
					throwsFilter: (
						context: TPitcherMetricContourPlotContext,
						event: TPitcherMetricContourPlotEvent
					) => {
						if (event.type !== SET_THROWS_FILTER) return context.throwsFilter;
						return event.data;
					}
				}),
				setSeasonFilter: assign<TPitcherMetricContourPlotContext, TPitcherMetricContourPlotEvent>({
					seasonFilter: (
						context: TPitcherMetricContourPlotContext,
						event: TPitcherMetricContourPlotEvent
					) => {
						if (event.type !== SET_SEASON_FILTER) return context.seasonFilter;
						return event.data;
					}
				}),
				clearContext: assign<TPitcherMetricContourPlotContext, TPitcherMetricContourPlotEvent>({
					lastPlayerId: (context: TPitcherMetricContourPlotContext, _event: TPitcherMetricContourPlotEvent) =>
						context.playerId,
					lastPlayingLevel: (
						context: TPitcherMetricContourPlotContext,
						_event: TPitcherMetricContourPlotEvent
					) => context.lastPlayingLevel,
					lastMetricGroup: (
						context: TPitcherMetricContourPlotContext,
						_event: TPitcherMetricContourPlotEvent
					) => context.metricGroup,
					lastSeasonFilter: (
						context: TPitcherMetricContourPlotContext,
						_event: TPitcherMetricContourPlotEvent
					) => context.seasonFilter,
					armSlotsMonthly: (
						_context: TPitcherMetricContourPlotContext,
						_event: TPitcherMetricContourPlotEvent
					) => {
						return undefined;
					},
					jointProbabilityDensities: (
						_context: TPitcherMetricContourPlotContext,
						_event: TPitcherMetricContourPlotEvent
					) => {
						return {};
					},
					playerHeight: (
						_context: TPitcherMetricContourPlotContext,
						_event: TPitcherMetricContourPlotEvent
					) => {
						return undefined;
					},
					playerSeasonArsenalScores: (
						_context: TPitcherMetricContourPlotContext,
						_event: TPitcherMetricContourPlotEvent
					) => {
						return undefined;
					},
					cancelSources: (
						context: TPitcherMetricContourPlotContext,
						_event: TPitcherMetricContourPlotEvent
					) => {
						Object.values(context.cancelSources).forEach((tokenSource: CancelTokenSource) =>
							tokenSource.cancel()
						);
						return {};
					}
				}),
				// Cancel Source Actions
				refreshArmSlotsMonthlyCancelSource: assign<
					TPitcherMetricContourPlotContext,
					TPitcherMetricContourPlotEvent
				>({
					cancelSources: (
						context: TPitcherMetricContourPlotContext,
						_event: TPitcherMetricContourPlotEvent
					) => {
						if (context.cancelSources[ARM_SLOTS_MONTHLY_CANCEL_SOURCE] != null)
							context.cancelSources[ARM_SLOTS_MONTHLY_CANCEL_SOURCE].cancel();
						context.cancelSources[ARM_SLOTS_MONTHLY_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshJointProbabilityDensitiesCancelSource: assign<
					TPitcherMetricContourPlotContext,
					TPitcherMetricContourPlotEvent
				>({
					cancelSources: (
						context: TPitcherMetricContourPlotContext,
						_event: TPitcherMetricContourPlotEvent
					) => {
						if (context.cancelSources[JOINT_PROBABILITY_DENSITIES_CANCEL_SOURCE] != null)
							context.cancelSources[JOINT_PROBABILITY_DENSITIES_CANCEL_SOURCE].cancel();
						context.cancelSources[JOINT_PROBABILITY_DENSITIES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerHeightCancelSource: assign<
					TPitcherMetricContourPlotContext,
					TPitcherMetricContourPlotEvent
				>({
					cancelSources: (
						context: TPitcherMetricContourPlotContext,
						_event: TPitcherMetricContourPlotEvent
					) => {
						if (context.cancelSources[PLAYER_HEIGHT_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_HEIGHT_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_HEIGHT_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshArsenalScoresThresholdCancelSource: assign<
					TPitcherMetricContourPlotContext,
					TPitcherMetricContourPlotEvent
				>({
					cancelSources: (
						context: TPitcherMetricContourPlotContext,
						_event: TPitcherMetricContourPlotEvent
					) => {
						if (context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE].cancel();
						context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonArsenalScoresCancelSource: assign<
					TPitcherMetricContourPlotContext,
					TPitcherMetricContourPlotEvent
				>({
					cancelSources: (
						context: TPitcherMetricContourPlotContext,
						_event: TPitcherMetricContourPlotEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchArmSlotsMonthlySuccess: assign<
					TPitcherMetricContourPlotContext,
					TPitcherMetricContourPlotEvent
				>({
					armSlotsMonthly: (
						context: TPitcherMetricContourPlotContext,
						event: TPitcherMetricContourPlotEvent
					) => {
						if (event.type !== FETCH_ARM_SLOTS_MONTHLY_DONE) return context.armSlotsMonthly;
						return event.data;
					}
				}),
				handleFetchJointProbabilityDensitiesSuccess: assign<
					TPitcherMetricContourPlotContext,
					TPitcherMetricContourPlotEvent
				>({
					jointProbabilityDensities: (
						context: TPitcherMetricContourPlotContext,
						event: TPitcherMetricContourPlotEvent
					) => {
						const { jointProbabilityDensities, batsFilter, throwsFilter } = context;
						if (event.type !== FETCH_PROBABILITY_DENSITIES_DONE || event.data === undefined)
							return jointProbabilityDensities;
						// Use the bats filter of the returned data to make sure we have the correct data
						// otherwise default to the context's bats filter
						const bats = event.data?.length ? event.data[0].requestArgs.bats ?? BATS_OVERALL : batsFilter;
						// Repeat the process with the throws filter
						const throws = event.data?.length
							? event.data[0].requestArgs.throws ?? throwsFilter
							: throwsFilter;
						return {
							...jointProbabilityDensities,
							[`${bats}-${throws}`]: event.data
						};
					}
				}),
				handleFetchPlayerHeightSuccess: assign<
					TPitcherMetricContourPlotContext,
					TPitcherMetricContourPlotEvent
				>({
					playerHeight: (
						context: TPitcherMetricContourPlotContext,
						event: TPitcherMetricContourPlotEvent
					) => {
						if (event.type !== FETCH_PLAYER_HEIGHT_DONE) return context.playerHeight;
						return event.data;
					}
				}),
				handleFetchArsenalScoresThresholdSuccess: assign<
					TPitcherMetricContourPlotContext,
					TPitcherMetricContourPlotEvent
				>({
					arsenalScoresThreshold: (
						context: TPitcherMetricContourPlotContext,
						event: TPitcherMetricContourPlotEvent
					) => {
						if (event.type !== FETCH_ARSENAL_SCORES_THRESHOLD_DONE) return context.arsenalScoresThreshold;
						return event.data;
					}
				}),
				handleFetchPlayerSeasonArsenalScoresSuccess: assign<
					TPitcherMetricContourPlotContext,
					TPitcherMetricContourPlotEvent
				>({
					playerSeasonArsenalScores: (
						context: TPitcherMetricContourPlotContext,
						event: TPitcherMetricContourPlotEvent
					) => {
						const { playerSeasonArsenalScores } = context;
						if (event.type !== FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE) return playerSeasonArsenalScores;
						return event.data;
					}
				}),
				// Fetch Errored Actions
				handleFetchArmSlotsMonthlyErrored: (
					context: TPitcherMetricContourPlotContext,
					event: TPitcherMetricContourPlotEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_ARM_SLOTS_MONTHLY_ERROR ? event.data : undefined,
						context.toast,
						"Arm Slots Monthly",
						"Error fetching arm slots monthly data."
					);
				},
				handleFetchJointProbabilityDensitiesErrored: (
					context: TPitcherMetricContourPlotContext,
					event: TPitcherMetricContourPlotEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PROBABILITY_DENSITIES_ERROR ? event.data : undefined,
						context.toast,
						"Joint Probability Densities",
						"Error fetching joint probability densities data."
					);
				},
				handleFetchPlayerHeightErrored: (
					context: TPitcherMetricContourPlotContext,
					event: TPitcherMetricContourPlotEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_HEIGHT_ERROR ? event.data : undefined,
						context.toast,
						"Player Height",
						"Error fetching player height data."
					);
				},
				handleFetchArsenalScoresThresholdErrored: (
					context: TPitcherMetricContourPlotContext,
					event: TPitcherMetricContourPlotEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_ARSENAL_SCORES_THRESHOLD_ERROR ? event.data : undefined,
						context.toast,
						"Arsenal Scores Thresholds",
						"Error fetching arsenal scores thresholds data."
					);
				},
				handleFetchPlayerSeasonArsenalScoresErrored: (
					context: TPitcherMetricContourPlotContext,
					event: TPitcherMetricContourPlotEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_ARSENAL_SCORES_ERROR ? event.data : undefined,
						context.toast,
						"Seasonal Arsenal Scores Pitch Type",
						"Error fetching seasonal arsenal scores pitch type."
					);
				}
			},
			services: {
				fetchArmSlotsMonthly: (context: TPitcherMetricContourPlotContext, _event: AnyEventObject) => {
					const { playerId, seasonFilter } = context;
					if (!playerId) return Promise.resolve(undefined);
					const fetchFunc = () =>
						fetchArmSlotsMonthly(
							{
								playerId: playerId,
								season: seasonFilter,
								"month[gte]": 4,
								"month[lte]": 10,
								isUseCache: true
							},
							context.cancelSources[ARM_SLOTS_MONTHLY_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchJointProbabilityDensities: (context: TPitcherMetricContourPlotContext, _event: AnyEventObject) => {
					const {
						playerId,
						playingLevel,
						metricGroup,
						batsFilter,
						throwsFilter,
						seasonFilter,
						jointProbabilityDensities
					} = context;
					const existingData = jointProbabilityDensities[`${batsFilter}-${throwsFilter}`];
					if (existingData) return Promise.resolve(existingData);
					if (!playerId || !metricGroup) return Promise.resolve(undefined);
					const fetchFunc = () =>
						fetchPlayerMetricJointProbabilityDensities(
							{
								playerId: playerId,
								playingLevel: playingLevel,
								// BATS_OVERALL is equivalent to having no `bats` filter
								bats: batsFilter !== BATS_OVERALL ? batsFilter : undefined,
								throws: throwsFilter,
								metricGroup: metricGroup,
								season: seasonFilter,
								isUseCache: true,
								isIncludeJointProbabilityDensityPitches: true
							},
							context.cancelSources[JOINT_PROBABILITY_DENSITIES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerHeight: (context: TPitcherMetricContourPlotContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(undefined);
					const fetchFunc = () =>
						fetchPlayer<TPlayerHeight>(
							{
								id: playerId,
								isUseCombinedId: true,
								fields: "height"
							},
							context.cancelSources[PLAYER_HEIGHT_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchArsenalScoresThreshold: (context: TPitcherMetricContourPlotContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchArsenalScoresThreshold(
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonArsenalScores: (context: TPitcherMetricContourPlotContext, _event: AnyEventObject) => {
					const { playerId, playingLevel, seasonFilter } = context;
					if (!playerId || !playingLevel) return Promise.resolve(undefined);
					const fetchFunc = () =>
						fetchPlayerSeasonArsenalScores(
							{
								playerId: playerId,
								playingLevel: playingLevel,
								season: seasonFilter,
								gameType: GAME_TYPE_OVERALL,
								"pitchType[neq]": PITCH_TYPE_OVERALL,
								countSplit: COUNT_SPLIT_OVERALL,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PitcherMetricContourPlotMachine;
