import { TRockyNote } from "_react/shared/data_models/notes/_types";

export const SORT_DATE_COLUMNS: Array<{ label: string; key: keyof TRockyNote }> = [
	{ label: "Create Date", key: "createDate" },
	{ label: "Last Updated Date", key: "lastChangeDate" }
];

// Used to apply datepicker styles
export const DATEPICKER_WRAPPER_CLASS_NAME = "rocky-note-date-picker";

// Pre-Populate Static Prefix
export const PRE_POPULATE_STATIC_PREFIX = "STATIC:";
