import React from "react";
import { useToken, VStack, HStack, SystemStyleObject } from "@chakra-ui/react";

import { convertRemTokenToPixelInt } from "_react/shared/_helpers/chakra";
import { formatRa9, format2080Grade } from "_react/shared/data_models/seasonal_grades/_helpers";
import { PITCH_TYPE_LABEL_MAP } from "_react/shared/_constants/pitch_types";
import { getManualPitchTypeMapBySeasonTooltip, getStuffThresholdTooltip } from "_react/shared/_helpers/tooltips";
import { TPitchTypes } from "_react/shared/_types/pitch_types";
import { IPlayerSeasonArsenalScoresSchema } from "_react/shared/data_models/arsenal_scores/_types";
import { getThresholdLevel } from "_react/shared/data_models/arsenal_scores/_helpers";
import { ITwentyEightyGradeDistribution } from "_react/shared/data_models/dataviz/_types";
import { IMetricProbabilityDensities } from "_react/shared/data_models/metric/_types";
import { ICON_CIRCLE } from "_react/shared/ui/presentation/components/PitchTypeLabel/_constants";
import GradeDistributionPlot from "_react/shared/ui/presentation/plots/GradeDistributionPlot/GradeDistributionPlot";
import PlotStatLabel from "_react/shared/ui/presentation/components/PlotStatLabel/PlotStatLabel";
import { ICON_DANGEROUS } from "_react/shared/ui/presentation/components/GradeThresholdIcon/_constants";
import PitchTypeLabel from "_react/shared/ui/presentation/components/PitchTypeLabel/PitchTypeLabel";
import PitchUsageDistributionPlot from "_react/shared/ui/presentation/plots/PitchUsageDistributionPlot/PitchUsageDistributionPlot";

import {
	GRADE_DISTRIBUTION_PLOT_PROPS,
	PLOT_TYPE_AVERAGE,
	PLOT_TYPE_PROBABILITY_DENSITY
} from "_react/shared/ui/data/plots/ArsenalStuffDistributions/_constants";
import {
	PitchTypeLabelStyle,
	PitchTypeLabelContainerStyle
} from "_react/shared/ui/data/plots/ArsenalStuffDistributions/_styles";
import { TDistributionPlotType } from "_react/shared/ui/data/plots/ArsenalStuffDistributions/_types";

type TPitchDistributionPlotStyle = {
	container: SystemStyleObject;
};

type TPitchDistributionPlotProps = {
	playerSeasonArsenalScoresPitchType: IPlayerSeasonArsenalScoresSchema;
	playerSeasonArsenalScoresSplitsPitchType?: IPlayerSeasonArsenalScoresSchema[] | null;
	twentyEightyGradeDistribution?: Array<ITwentyEightyGradeDistribution> | null;
	stuffProbabilityDensitiesPitchType?: IMetricProbabilityDensities | null;
	showProbabilityDensities?: boolean;
	threshold?: number;
	plotType?: TDistributionPlotType;
	originalPitchTypeTag?: string | null;
	style?: TPitchDistributionPlotStyle;
};

const PitchDistributionPlot = ({
	playerSeasonArsenalScoresPitchType,
	playerSeasonArsenalScoresSplitsPitchType,
	twentyEightyGradeDistribution,
	stuffProbabilityDensitiesPitchType,
	showProbabilityDensities = true,
	threshold,
	plotType = PLOT_TYPE_AVERAGE,
	originalPitchTypeTag,
	style
}: TPitchDistributionPlotProps) => {
	// `GradeDistributionPlot` needs Chakra tokens converted to pixel values
	const [size8, size12, size20, size44] = useToken("sizes", [8, 12, 20, 44]);

	const thresholdIconShape = getThresholdLevel(playerSeasonArsenalScoresPitchType?.total, threshold);
	const stuffThresholdIconTooltip = getStuffThresholdTooltip(playerSeasonArsenalScoresPitchType?.total, threshold);
	const manualPitchTypeMapBySeasonTooltip = originalPitchTypeTag
		? getManualPitchTypeMapBySeasonTooltip(originalPitchTypeTag)
		: null;

	return (
		<VStack alignItems="start" gap="0" sx={style?.container}>
			<HStack sx={PitchTypeLabelContainerStyle}>
				<PitchTypeLabel
					label={PITCH_TYPE_LABEL_MAP[playerSeasonArsenalScoresPitchType.pitchType as TPitchTypes]}
					abbreviation={playerSeasonArsenalScoresPitchType.pitchType}
					shape={ICON_CIRCLE}
					thresholdIcon={
						thresholdIconShape
							? { shape: thresholdIconShape, tooltipLabel: stuffThresholdIconTooltip }
							: undefined
					}
					pitchTypeRetagTooltip={manualPitchTypeMapBySeasonTooltip}
					isBold
					style={PitchTypeLabelStyle}
				/>
				{playerSeasonArsenalScoresSplitsPitchType && showProbabilityDensities && (
					<PitchUsageDistributionPlot<IPlayerSeasonArsenalScoresSchema>
						pitchData={playerSeasonArsenalScoresSplitsPitchType}
						xValue="bats"
						yValue="total"
						height={convertRemTokenToPixelInt(size8)}
						width={convertRemTokenToPixelInt(size12)}
						orientation="horizontal"
					/>
				)}
			</HStack>
			<VStack alignItems="start" gap="0" border="1px solid" borderColor="gray.300" borderRadius="lg" padding="6">
				<PlotStatLabel
					value={format2080Grade(
						thresholdIconShape === ICON_DANGEROUS ? null : playerSeasonArsenalScoresPitchType?.stuffGrade
					)}
					secondaryValue={formatRa9(
						thresholdIconShape === ICON_DANGEROUS ? null : playerSeasonArsenalScoresPitchType?.stuffRa9,
						"-"
					)}
					style={{ container: { marginBottom: "2" } }}
				/>
				<GradeDistributionPlot<ITwentyEightyGradeDistribution>
					{...GRADE_DISTRIBUTION_PLOT_PROPS}
					distributionData={twentyEightyGradeDistribution ?? undefined}
					playerValue={
						plotType === PLOT_TYPE_AVERAGE && thresholdIconShape !== ICON_DANGEROUS
							? playerSeasonArsenalScoresPitchType.stuffGrade
							: null
					}
					playerDistributionData={
						plotType === PLOT_TYPE_PROBABILITY_DENSITY && thresholdIconShape !== ICON_DANGEROUS
							? stuffProbabilityDensitiesPitchType?.probabilityDensities
							: undefined
					}
					width={convertRemTokenToPixelInt(size44)}
					height={convertRemTokenToPixelInt(size20)}
				/>
			</VStack>
		</VStack>
	);
};

export default PitchDistributionPlot;
