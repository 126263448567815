import React, { useEffect, useMemo } from "react";
import dayjs, { Dayjs } from "dayjs";
import { SystemStyleObject, useToast } from "@chakra-ui/react";
import { useMachine } from "@xstate/react";
import {
	Box,
	IconButton,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverCloseButton,
	PopoverHeader,
	PopoverBody,
	Stack,
	HStack,
	Text,
	Button,
	useDisclosure,
	Tooltip
} from "@chakra-ui/react";

import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";
import ExternalLinkIcon from "_react/shared/ui/icons/ExternalLinkIcon";
import UnreadDot from "_react/shared/ui/presentation/components/UnreadDot/UnreadDot";

import DPLQuestionnaireYearGroup from "_react/playerpage/ama/profile/DPLQuestionnaire/DPLQuestionnaireYearGroup";
import DPLQuestionnairePreviousYearSurveys from "_react/playerpage/ama/profile/DPLQuestionnaire/DPLQuestionnairePreviousYearSurveys";
import { TDPLQuestionnairePopoverData, IDPLSurveyCleaned } from "_react/playerpage/ama/profile/DPLQuestionnaire/_types";
import {
	transformCurrentYearSurveys,
	transformPreviousYearSurveys
} from "_react/playerpage/ama/profile/DPLQuestionnaire/_helpers";
import createDPLQuestionnaireMachine, {
	TDPLQuestionnairePopoverContext,
	SET_PLAYER_ID,
	SET_CURRENT_YEAR_DPL_SURVEYS,
	SET_PROSPECT_DPL_SURVEYS
} from "_react/playerpage/ama/profile/DPLQuestionnaire/_machine";

type TDPLQuestionnairePopoverStyle = {
	iconButton?: SystemStyleObject;
};
type TDPLQuestionnairePopoverProps = {
	playerId: number;
	dplId: number | null;
	shouldFetchData?: boolean;
	data?: TDPLQuestionnairePopoverData;
	style?: TDPLQuestionnairePopoverStyle;
	unreadDotConfig?: TDPLQuestionnairePopoverUnreadConfig;
};
type TDPLQuestionnairePopoverUnreadConfig = {
	referenceDate: string;
	showForMedicalSurveysOnly?: boolean;
};

const DPLQuestionnairePopover = ({
	playerId,
	dplId,
	shouldFetchData,
	data,
	style,
	unreadDotConfig
}: TDPLQuestionnairePopoverProps) => {
	const { onOpen, onClose, isOpen } = useDisclosure();
	// In order to prevent the DPL popover from closing when opening a questionnaire response modal (while also retaining the popover's close on blur functionality) the questionnaire modal's disclosure has to be accessible here, and passed to the child components.
	const { onOpen: onOpenModal, onClose: onCloseModal, isOpen: isOpenModal } = useDisclosure();

	const currentYear = dayjs().year();
	const toast = useToast();

	const [current, send] = useMachine(createDPLQuestionnaireMachine(playerId, shouldFetchData, data, toast));
	const { currentYearDplSurveys, prospectDplSurveys } = current.context as TDPLQuestionnairePopoverContext;

	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		if (data?.currentYearDplSurveys !== currentYearDplSurveys && !shouldFetchData) {
			send({ type: SET_CURRENT_YEAR_DPL_SURVEYS, data: data?.currentYearDplSurveys });
		}
	}, [data?.currentYearDplSurveys, shouldFetchData, send, currentYearDplSurveys]);

	useEffect(() => {
		if (data?.prospectDplSurveys !== prospectDplSurveys && !shouldFetchData) {
			send({ type: SET_PROSPECT_DPL_SURVEYS, data: data?.prospectDplSurveys });
		}
	}, [data?.prospectDplSurveys, shouldFetchData, send, prospectDplSurveys]);

	const transformedCurrentYearSurveys = useMemo(
		() => transformCurrentYearSurveys(currentYear, currentYearDplSurveys, prospectDplSurveys),
		[currentYearDplSurveys, prospectDplSurveys, currentYear]
	);

	const transformedPreviousYearSurveys = useMemo(
		() => transformPreviousYearSurveys(currentYear, prospectDplSurveys),
		[prospectDplSurveys, currentYear]
	);

	const lastUpdated: Dayjs | undefined = useMemo(() => {
		if (unreadDotConfig?.referenceDate == null) return;
		// Filter surveys if necessary, map to last updated dates
		const surveyDates = prospectDplSurveys
			?.filter(
				(survey: IDPLSurveyCleaned) =>
					unreadDotConfig?.showForMedicalSurveysOnly !== true || survey.name.toLowerCase().includes("medical")
			)
			.map((survey: IDPLSurveyCleaned) => dayjs(survey.lastChangeDate));
		// Determine most recent updated date
		return surveyDates?.reduce((mostRecentDate: Dayjs | undefined, surveyDate: Dayjs) => {
			if (mostRecentDate == null) return surveyDate;
			return mostRecentDate.isBefore(surveyDate) ? surveyDate : mostRecentDate;
		}, undefined);
	}, [prospectDplSurveys, unreadDotConfig?.referenceDate, unreadDotConfig?.showForMedicalSurveysOnly]);

	const showUnreadCircle: boolean = useMemo(() => {
		if (lastUpdated != null && unreadDotConfig?.referenceDate != null) {
			return dayjs(unreadDotConfig.referenceDate).isBefore(lastUpdated);
		}
		return false;
	}, [unreadDotConfig?.referenceDate, lastUpdated]);

	return (
		<Popover
			placement="left"
			isOpen={isOpen}
			onOpen={onOpen}
			onClose={() => {
				if (!isOpenModal) {
					// Only close the popover if the modal isn't being displayed
					onClose();
				}
			}}
			isLazy
			lazyBehavior="keepMounted"
		>
			<PopoverTrigger>
				<IconButton
					aria-label={`Show DPL Questionnaires`}
					variant="ghost"
					h="fit-content"
					minWidth="fit-content"
					icon={<OutlineInfo />}
					sx={style?.iconButton}
				/>
			</PopoverTrigger>
			{showUnreadCircle && (
				<Tooltip label={lastUpdated ? `Last Changed: ${lastUpdated.format("M/D/YY")}` : "Test"} placement="top">
					<Text>
						<UnreadDot />
					</Text>
				</Tooltip>
			)}
			{/*
				Rocky header is zIndex 1000
			 */}
			<Box zIndex="modal">
				<PopoverContent>
					<PopoverArrow />
					<PopoverCloseButton />
					<PopoverHeader textAlign="left">DPL Questionnaires</PopoverHeader>
					<PopoverBody fontSize="xs" sx={{ maxHeight: "450px", overflow: "scroll" }}>
						<Stack spacing={3} direction="column">
							<DPLQuestionnaireYearGroup
								year={transformedCurrentYearSurveys}
								dplId={dplId}
								onOpenModal={onOpenModal}
								onCloseModal={onCloseModal}
								isOpenModal={isOpenModal}
							/>
							{transformedPreviousYearSurveys != null && (
								<DPLQuestionnairePreviousYearSurveys
									previousYearSurveys={transformedPreviousYearSurveys}
									dplId={dplId}
									onOpenModal={onOpenModal}
									onCloseModal={onCloseModal}
									isOpenModal={isOpenModal}
								/>
							)}
							<Button
								variant="outline"
								size="xs"
								onClick={() => window.open(`https://dpl.mlb.com/prospect/${dplId}`, "_blank")}
							>
								<HStack spacing={1}>
									<Text>DPL Profile</Text>
									<ExternalLinkIcon sx={{ strokeWidth: 4 }} />
								</HStack>
							</Button>
						</Stack>
					</PopoverBody>
				</PopoverContent>
			</Box>
		</Popover>
	);
};

export default DPLQuestionnairePopover;
