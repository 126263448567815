import React, { useMemo, useCallback } from "react";

import { scaleSequential, interpolateMagma } from "d3";
import { hexbin } from "d3-hexbin";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { Circle } from "@visx/shape";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";

import { HOME_PLATE_PATH_FT, PLATE_HALF, SZ_BOTTOM, SZ_TOP } from "_react/shared/_constants/strikezone";
import { round10 } from "_react/shared/_helpers/numbers";
import { KeysOfValue } from "_react/shared/_types/generics";
import { getExtent } from "_react/shared/dataviz/_helpers";
import { DEFAULT_OPACITY } from "_react/shared/dataviz/_constants";
import { getSameScalePlotDimensions } from "_react/shared/dataviz/_helpers";
import { useDataVizColors } from "_react/shared/dataviz/_hooks";
import { TAxisExtrema, TMarginsProps } from "_react/shared/dataviz/_types";

type THexbinData<T> = {
	center: { x: number; y: number };
	includedPitchData: Array<T> | undefined;
	colorScaleValueToUse: number | undefined;
	hoverValue: string | number | undefined;
};

export type TAxisExtremaProps = TAxisExtrema<number> & {
	isOverrideDistributionExtrema?: boolean;
};

type TXAxisProps = {
	xLabel?: string;
	extrema?: TAxisExtremaProps;
};

type TYAxisProps = {
	yLabel?: string;
	extrema?: TAxisExtremaProps;
};

type TPitchMetricHexbinPlotProps<T extends object> = {
	plotType?: "hexbin" | "scatter";
	pitchData?: Array<T>;
	xValue: KeysOfValue<T, number> | string;
	getXValueFunction?: (obj: T) => number;
	yValue: KeysOfValue<T, number> | string;
	getYValueFunction?: (obj: T) => number;
	colorScaleValue?: KeysOfValue<T, number> | string;
	getColorScaleValueFunction?: (obj: T) => number;
	colorScale?: { maxValue: number; minValue: number; isOverrideDistributionExtrema?: boolean };
	customColorScaleFunction?: (value: number) => string;
	getHoverValue?: (data: T[] | undefined) => string | number | undefined;
	showStrikeZone?: boolean;
	showHomeplate?: boolean;
	xAxis?: TXAxisProps;
	yAxis?: TYAxisProps;
	// TODO: Decide if we want to add this prop or change the background color on our plots
	backgroundColor?: string;
	size?: { value: number; dimension: "width" | "height" };
	hexbinRadius?: number;
	margins?: TMarginsProps;
};

export const PitchMetricHexbinPlot = <T extends object>({
	plotType = "hexbin",
	pitchData,
	xValue,
	getXValueFunction,
	yValue,
	getYValueFunction,
	colorScaleValue,
	getColorScaleValueFunction,
	colorScale: colorScaleProps,
	customColorScaleFunction,
	getHoverValue,
	showStrikeZone,
	showHomeplate,
	xAxis,
	yAxis,
	backgroundColor: backgroundColorProp,
	size,
	hexbinRadius = 12,
	margins
}: TPitchMetricHexbinPlotProps<T>) => {
	const { tooltipData, tooltipLeft, tooltipTop, tooltipOpen, showTooltip, hideTooltip } = useTooltip<
		string | number
	>();
	const { containerRef, TooltipInPortal } = useTooltipInPortal({
		scroll: true
	});

	// STYLING SETUP
	const { backgroundColor, dataColorPrimaryGray } = useDataVizColors();

	// DATA SETUP
	const getXValues = useCallback(
		(datum: T) =>
			getXValueFunction ? getXValueFunction(datum) : ((datum[xValue as keyof T] as unknown) as number),
		[getXValueFunction, xValue]
	);
	const getYValues = useCallback(
		(datum: T) =>
			getYValueFunction ? getYValueFunction(datum) : ((datum[yValue as keyof T] as unknown) as number),
		[getYValueFunction, yValue]
	);
	const getColorScaleValues = useCallback(
		(datum: T) =>
			getColorScaleValueFunction
				? getColorScaleValueFunction(datum)
				: ((datum[colorScaleValue as keyof T] as unknown) as number),
		[getColorScaleValueFunction, colorScaleValue]
	);

	const [xMin, xMax] = useMemo(() => {
		// If values are provided, don't bother calculating the extent
		if (
			xAxis?.extrema?.isOverrideDistributionExtrema &&
			xAxis?.extrema?.min !== undefined &&
			xAxis?.extrema?.max !== undefined
		)
			return [xAxis.extrema.min, xAxis.extrema.max];

		const [xMinDefault, xMaxDefault] = getExtent<number>(
			xAxis?.extrema?.min !== undefined ? xAxis.extrema.min : 0,
			xAxis?.extrema?.max !== undefined ? xAxis.extrema.max : 100,
			pitchData?.map((datum: T) => getXValues(datum) as number)
		);
		const xMin =
			xAxis?.extrema?.isOverrideDistributionExtrema && xAxis?.extrema?.min !== undefined
				? xAxis.extrema.min
				: xMinDefault;
		const xMax =
			xAxis?.extrema?.isOverrideDistributionExtrema && xAxis?.extrema?.max !== undefined
				? xAxis.extrema.max
				: xMaxDefault;
		return [xMin, xMax];
	}, [xAxis?.extrema, pitchData, getXValues]);

	const [yMin, yMax] = useMemo(() => {
		if (
			yAxis?.extrema?.isOverrideDistributionExtrema &&
			yAxis?.extrema?.min !== undefined &&
			yAxis?.extrema?.max !== undefined
		)
			return [yAxis.extrema.min, yAxis.extrema.max];

		const [yMinDefault, yMaxDefault] = getExtent<number>(
			yAxis?.extrema?.min !== undefined ? yAxis.extrema.min : 0,
			yAxis?.extrema?.max !== undefined ? yAxis.extrema.max : 100,
			pitchData?.map((datum: T) => getYValues(datum) as number)
		);
		const yMin =
			yAxis?.extrema?.isOverrideDistributionExtrema && yAxis?.extrema?.min !== undefined
				? yAxis.extrema.min
				: yMinDefault;
		const yMax =
			yAxis?.extrema?.isOverrideDistributionExtrema && yAxis?.extrema?.max !== undefined
				? yAxis.extrema.max
				: yMaxDefault;
		return [yMin, yMax];
	}, [pitchData, yAxis?.extrema, getYValues]);

	// SIZING SETUP
	const {
		width: WIDTH,
		height: HEIGHT,
		margins: MARGIN,
		innerWidth: INNER_WIDTH,
		innerHeight: INNER_HEIGHT
	} = useMemo(() => {
		return getSameScalePlotDimensions(
			xMin,
			xMax,
			yMin,
			yMax,
			margins ?? { top: 20, right: 20, bottom: 20, left: 20 },
			size
		);
	}, [xMin, xMax, yMin, yMax, margins, size]);

	// AXES SETUP
	const xScale = scaleLinear<number>({
		// Swap xMin and xMax to change from umpire perspective to pitcher perspective
		domain: [xMax, xMin],
		range: [0, INNER_WIDTH],
		nice: true
	});
	const yScale = scaleLinear<number>({
		domain: [yMin, yMax],
		range: [INNER_HEIGHT, 0],
		nice: true
	});

	// HEXBIN SETUP
	const hexbinGenerator = hexbin()
		.radius(hexbinRadius)
		.extent([
			[0, 0],
			[INNER_WIDTH, INNER_HEIGHT]
		]);

	const hexbinDataParsed: Array<THexbinData<T>> | undefined = useMemo(() => {
		// The type returned by this function is an array of arrays that have key value pairs on them which is why we're not including a type here
		// Array type is Array<Array<number>> with three key value pairs on the arrays within the main array: { length: number, x: number, y: number }
		// The individual arrays are all points within the hexagon, x, y is the center of the hexagon, length is the length of the array
		const hexbinData = pitchData
			? hexbinGenerator(pitchData.map(datum => [xScale(getXValues(datum)), yScale(getYValues(datum))]))
			: undefined;
		const parsedHexbinData = hexbinData?.map(hexagon => {
			const pitchesInHexagon = pitchData?.filter((pitch: T) => {
				return hexagon.some(
					(hexagonPitch: [number, number]) =>
						hexagonPitch[0] === xScale(getXValues(pitch)) && hexagonPitch[1] === yScale(getYValues(pitch))
				);
			});
			let colorScaleValueToUse: number | undefined = hexagon.length;
			if (colorScaleValue || getColorScaleValueFunction) {
				// Calculate mean color scale value
				const valueTotal = pitchesInHexagon?.reduce(
					(total: number, currentPitch: T) => total + getColorScaleValues(currentPitch),
					0
				);
				colorScaleValueToUse =
					valueTotal !== undefined ? valueTotal / (pitchesInHexagon?.length ?? 0) : undefined;
			}
			return {
				center: { x: hexagon.x, y: hexagon.y },
				includedPitchData: pitchesInHexagon,
				colorScaleValueToUse: colorScaleValueToUse,
				hoverValue: getHoverValue ? getHoverValue(pitchesInHexagon) : round10(colorScaleValueToUse, -3)
			};
		});
		return parsedHexbinData;
	}, [
		pitchData,
		colorScaleValue,
		getColorScaleValueFunction,
		getColorScaleValues,
		getHoverValue,
		getXValues,
		getYValues,
		hexbinGenerator,
		xScale,
		yScale
	]);

	// COLORSCALE SETUP
	const colorScale = useMemo(() => {
		if (customColorScaleFunction) {
			return customColorScaleFunction;
		}

		// Default if there's no data
		let minValue = colorScaleProps?.minValue ?? 0;
		let maxValue = colorScaleProps?.maxValue
			? colorScaleProps?.maxValue
			: hexbinDataParsed
			? Math.max(...hexbinDataParsed.map(hex => hex.includedPitchData?.length ?? 1))
			: 1;

		if (
			pitchData &&
			!colorScaleProps?.isOverrideDistributionExtrema &&
			(colorScaleValue || getColorScaleValueFunction)
		) {
			const colorScaleValues = pitchData.map((pitch: T) => getColorScaleValues(pitch));
			minValue = Math.min(...colorScaleValues);
			maxValue = Math.max(...colorScaleValues);
		}

		const colorScale = scaleSequential<string>()
			.interpolator(interpolateMagma)
			.domain([maxValue, minValue]);

		return colorScale;
	}, [
		colorScaleProps?.minValue,
		colorScaleProps?.maxValue,
		colorScaleProps?.isOverrideDistributionExtrema,
		hexbinDataParsed,
		pitchData,
		colorScaleValue,
		getColorScaleValueFunction,
		getColorScaleValues,
		customColorScaleFunction
	]);

	// INTERACTIVITY
	const handleHexbinHover = useCallback(
		(event: React.MouseEvent<SVGElement>, hoveredHexbinValue?: string | number) => {
			// Get coordinates of mouse in pixels
			const { x, y } = localPoint(event) || { x: null, y: null };
			if (x && hoveredHexbinValue !== undefined) {
				// Convert x mouse location from pixels to an x value
				const xMouseValue = xScale.invert(x - MARGIN.left);
				if (xMouseValue >= xMin && xMouseValue <= xMax) {
					showTooltip({
						tooltipLeft: x,
						tooltipTop: y,
						tooltipData: hoveredHexbinValue
					});
				}
			}
		},
		[xMax, xMin, xScale, MARGIN.left, showTooltip]
	);

	return (
		<>
			<svg width={WIDTH} height={HEIGHT} ref={containerRef}>
				<rect x={0} y={0} width={WIDTH} height={HEIGHT} fill={backgroundColorProp ?? backgroundColor} />
				<Group left={MARGIN.left} top={MARGIN.top}>
					{plotType === "hexbin" &&
						hexbinDataParsed?.map(hexagon => (
							<Group key={`hexbin-${hexagon.center.x}-${hexagon.center.y}`}>
								<path
									key={`hexbin-path-${hexagon.center.x}-${hexagon.center.y}`}
									d={hexbinGenerator.hexagon()}
									transform={"translate(" + hexagon.center.x + "," + hexagon.center.y + ")"}
									opacity={1}
									stroke={backgroundColor}
									fill={
										hexagon.colorScaleValueToUse !== undefined
											? colorScale(hexagon.colorScaleValueToUse)
											: undefined
									}
									fillOpacity={DEFAULT_OPACITY}
									strokeWidth={0.5}
								/>
							</Group>
						))}
					{plotType === "scatter" &&
						pitchData?.map((pitch: T, index: number) => (
							<Circle
								key={`point-${index}`}
								className="dot"
								cx={xScale(getXValues(pitch))}
								cy={yScale(getYValues(pitch))}
								r={2}
								fill={colorScale(getColorScaleValues(pitch))}
								opacity={DEFAULT_OPACITY}
							/>
						))}
					{showStrikeZone && (
						<rect
							key="strikezone-overlay"
							x={xScale(PLATE_HALF)}
							y={yScale(SZ_TOP)}
							width={xScale(-PLATE_HALF) - xScale(PLATE_HALF)}
							height={yScale(SZ_BOTTOM) - yScale(SZ_TOP)}
							stroke={dataColorPrimaryGray}
							strokeWidth={2}
							fill="transparent"
						/>
					)}
					{showHomeplate && (
						<path
							key="homeplate-overlay"
							d={HOME_PLATE_PATH_FT(xScale, yScale)}
							stroke={dataColorPrimaryGray}
							strokeWidth={0.5}
							opacity={1}
							fill="transparent"
						></path>
					)}
					{/* rendering a transparent version of the hexbins to handle mouse interactivity - this one should always be rendered last */}
					{plotType === "hexbin" &&
						hexbinDataParsed?.map(hexagon => (
							<Group
								key={`hexbin-overlay-${hexagon.center.x}-${hexagon.center.y}`}
								onMouseMove={event => {
									handleHexbinHover(event, hexagon.hoverValue);
								}}
								onMouseLeave={() => {
									hideTooltip();
								}}
							>
								<path
									key={`hexbin-overlay-path-${hexagon.center.x}-${hexagon.center.y}`}
									d={hexbinGenerator.hexagon()}
									transform={"translate(" + hexagon.center.x + "," + hexagon.center.y + ")"}
									stroke="transparent"
									fill="transparent"
									strokeWidth={0.5}
								/>
							</Group>
						))}
				</Group>
			</svg>
			{tooltipOpen && (
				<TooltipInPortal
					// set this to random so it correctly updates with parent bounds
					key={Math.random()}
					top={tooltipTop}
					left={tooltipLeft}
					style={{ ...defaultStyles, backgroundColor: backgroundColor }}
				>
					<div>{tooltipData}</div>
				</TooltipInPortal>
			)}
		</>
	);
};

export default PitchMetricHexbinPlot;
