import React, { useMemo, useEffect, useState, useCallback } from "react";
import {
	Box,
	Flex,
	HStack,
	IconButton,
	ButtonGroup,
	Menu,
	MenuButton,
	MenuDivider,
	MenuItemOption,
	MenuList,
	MenuOptionGroup,
	Portal,
	RangeSlider,
	RangeSliderTrack,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	Spacer,
	Text,
	Tooltip,
	VStack,
	useToast,
	SystemStyleObject
} from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import CloseIcon from "_react/shared/legacy/ui/icons/Clear";
import { isDefaultFilters, DEFAULT_STATS_TABLE_FILTERS } from "_react/shared/ui/data/tables/shared/Filters";
import { getMinAndMaxSeason, getSeasonFilters, updateFilters } from "_react/shared/_helpers/stats";
import {
	GAME_TYPE_OVERALL,
	GAME_TYPE_POSTSEASON,
	GAME_TYPE_REGULAR_SEASON,
	GAME_TYPE_SPRING_TRAINING,
	THROWS_L,
	THROWS_OVERALL,
	THROWS_R
} from "_react/shared/data_models/baseline_hit_probs/_constants";
import { IPlayerSeasonXwoba, IPlayerSeasonXwobaByTeam } from "_react/shared/data_models/baseline_hit_probs/_types";
import { VALID_PRO_LEVELS } from "_react/shared/data_models/stats/_constants";
import { IStatsPlayerBattingByTeamLkLevel } from "_react/shared/data_models/stats/_types";
import FilterAlt from "_react/shared/ui/icons/FilterAlt";
import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";
import { ASC, DESC } from "_react/shared/ui/presentation/components/Table/_constants";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";

import {
	NUM_DISPLAY_SEASONS,
	POSITION_PLAYER_PERFORMANCE_PARENT_COLUMNS,
	getPositionPlayerPerformanceColumns
} from "_react/shared/ui/data/tables/PositionPlayerPerformanceTable/_constants";
import {
	aggregateCombinedPositionPlayerPerformanceDataRows,
	appendGenericPlayerSeasonXwobaData,
	createStatsPlayerBattingByTeamRecord,
	createStatsPlayerBattingRecord,
	dedupePlayerStatsPlayerBattingBySource,
	dedupePlayerStatsPlayerBattingByTeamAndSource,
	getLevelsFromRow
} from "_react/shared/ui/data/tables/PositionPlayerPerformanceTable/_helpers";
import PositionPlayerPerformanceTableMachine, {
	FETCHING_AVERAGE_AGES,
	FETCHING_PLAYER_SEASON_XWOBA,
	FETCHING_PLAYER_SEASON_XWOBA_BYTEAM,
	FETCHING_POSITION_PLAYER_PERFORMANCE_PLAYER,
	FETCHING_STATS_PLAYER_BATTING,
	FETCHING_STATS_PLAYER_BATTING_BYTEAM,
	SET_AVERAGE_AGES,
	SET_FILTERS,
	SET_PLAYER_ID,
	SET_PLAYER_SEASON_XWOBA,
	SET_PLAYER_SEASON_XWOBA_BYTEAM,
	SET_POSITION_PLAYER_PERFORMANCE_PLAYER,
	SET_STATS_PLAYER_BATTING,
	SET_STATS_PLAYER_BATTING_BYTEAM,
	TPositionPlayerPerformanceTableContext
} from "_react/shared/ui/data/tables/PositionPlayerPerformanceTable/_machine";
import {
	ICombinedPositionPlayerPerformanceData,
	TPositionPlayerPerformanceRow,
	TPositionPlayerPerformanceTableData
} from "_react/shared/ui/data/tables/PositionPlayerPerformanceTable/_types";

type TPositionPlayerPerformanceTableStyle = {
	container?: SystemStyleObject;
	tableContainer?: SystemStyleObject;
};

type TPositionPlayerPerformanceTableProps = {
	title?: string;
	playerId?: number;
	data?: TPositionPlayerPerformanceTableData;
	columns?: Array<string>;
	shouldFetchData?: boolean;
	isShowFilters?: boolean;
	tableProps?: TTableProps<TPositionPlayerPerformanceRow, keyof TPositionPlayerPerformanceRow>;
	style?: TPositionPlayerPerformanceTableStyle;
};

const PositionPlayerPerformanceTable = ({
	title,
	playerId: playerIdProp,
	data,
	columns,
	shouldFetchData = true,
	isShowFilters = true,
	tableProps,
	style
}: TPositionPlayerPerformanceTableProps) => {
	const [showSeasonRangeTooltip, setShowSeasonRangeTooltip] = useState(false);

	const toast = useToast();

	const [current, send] = useMachine(
		PositionPlayerPerformanceTableMachine(playerIdProp, data, shouldFetchData, toast)
	);

	const isFetchingPlayerSeasonXwoba = current.matches(FETCHING_PLAYER_SEASON_XWOBA);
	const isFetchingPlayerSeasonXwobaByTeam = current.matches(FETCHING_PLAYER_SEASON_XWOBA_BYTEAM);
	const isFetchingStatsPlayerBatting = current.matches(FETCHING_STATS_PLAYER_BATTING);
	const isFetchingStatsPlayerBattingByTeam = current.matches(FETCHING_STATS_PLAYER_BATTING_BYTEAM);
	const isFetchingAverageAges = current.matches(FETCHING_AVERAGE_AGES);
	const isFetchingPositionPlayerPerformancePlayer = current.matches(FETCHING_POSITION_PLAYER_PERFORMANCE_PLAYER);

	const isLoading =
		isFetchingPlayerSeasonXwoba ||
		isFetchingPlayerSeasonXwobaByTeam ||
		isFetchingStatsPlayerBatting ||
		isFetchingStatsPlayerBattingByTeam ||
		isFetchingAverageAges ||
		isFetchingPositionPlayerPerformancePlayer;

	const context = current.context as TPositionPlayerPerformanceTableContext;
	const {
		playerId,
		playerSeasonXwoba,
		playerSeasonXwobaByTeam,
		statsPlayerBatting,
		statsPlayerBattingByTeam,
		averageAges,
		positionPlayerPerformancePlayer,
		filters
	} = context;

	// Update machine context when props change
	useEffect(() => {
		if (playerIdProp !== playerId) send({ type: SET_PLAYER_ID, value: playerIdProp });
	}, [send, playerIdProp, playerId, shouldFetchData]);

	useEffect(() => {
		if (data?.playerSeasonXwoba !== playerSeasonXwoba && shouldFetchData === false)
			send({ type: SET_PLAYER_SEASON_XWOBA, value: data?.playerSeasonXwoba });
	}, [send, data?.playerSeasonXwoba, playerSeasonXwoba, shouldFetchData]);

	useEffect(() => {
		if (data?.playerSeasonXwobaByTeam !== playerSeasonXwobaByTeam && shouldFetchData === false)
			send({ type: SET_PLAYER_SEASON_XWOBA_BYTEAM, value: data?.playerSeasonXwobaByTeam });
	}, [send, data?.playerSeasonXwobaByTeam, playerSeasonXwobaByTeam, shouldFetchData]);

	useEffect(() => {
		if (data?.statsPlayerBatting !== statsPlayerBatting && shouldFetchData === false)
			send({ type: SET_STATS_PLAYER_BATTING, value: data?.statsPlayerBatting });
	}, [send, data?.statsPlayerBatting, statsPlayerBatting, shouldFetchData]);

	useEffect(() => {
		if (data?.statsPlayerBattingByTeam !== statsPlayerBattingByTeam && shouldFetchData === false)
			send({ type: SET_STATS_PLAYER_BATTING_BYTEAM, value: data?.statsPlayerBattingByTeam });
	}, [send, data?.statsPlayerBattingByTeam, statsPlayerBattingByTeam, shouldFetchData]);

	useEffect(() => {
		if (data?.averageAges !== averageAges && shouldFetchData === false)
			send({ type: SET_AVERAGE_AGES, value: data?.averageAges });
	}, [send, data?.averageAges, averageAges, shouldFetchData]);

	useEffect(() => {
		if (data?.positionPlayerPerformancePlayer !== positionPlayerPerformancePlayer && shouldFetchData === false)
			send({ type: SET_POSITION_PLAYER_PERFORMANCE_PLAYER, value: data?.positionPlayerPerformancePlayer });
	}, [send, data?.positionPlayerPerformancePlayer, positionPlayerPerformancePlayer, shouldFetchData]);

	//
	// PREPROCESSING statsPlayerBatting and playerSeasonXwoba
	//

	const statsPlayerBattingRecord: Record<string, ICombinedPositionPlayerPerformanceData> = useMemo(() => {
		if (!statsPlayerBatting || !positionPlayerPerformancePlayer) return {};
		// Part 1: Dedupe Stats Player Batting
		const dedupedStatsPlayerBatting = dedupePlayerStatsPlayerBattingBySource(statsPlayerBatting);
		// Part 2: Aggregate Stats Player Batting -> Combined Position Player Performance Data
		// using the game types from baseline_hit_probs to create a record mapping
		// season-gameType to ICombinedPositionPlayerPerformanceData

		// Additionally, calculate player ages
		return createStatsPlayerBattingRecord(dedupedStatsPlayerBatting, positionPlayerPerformancePlayer.birthDate);
	}, [statsPlayerBatting, positionPlayerPerformancePlayer]);

	const combinedPositionPlayerPerformanceData:
		| Array<ICombinedPositionPlayerPerformanceData>
		| undefined = useMemo(() => {
		// Part 3: Iterate over player season xwOBA, and append entries to the above record
		const combinedPositionPlayerPerformanceRecord = appendGenericPlayerSeasonXwobaData<IPlayerSeasonXwoba>(
			playerSeasonXwoba ?? [],
			(x: IPlayerSeasonXwoba) => `${x.season}-${x.gameType}`,
			statsPlayerBattingRecord
		);
		return Object.values(combinedPositionPlayerPerformanceRecord);
	}, [statsPlayerBattingRecord, playerSeasonXwoba]);

	//
	// PREPROCESSING statsPlayerBattingByTeam and playerSeasonXwobaByTeam
	//
	const statsPlayerBattingByTeamRecord: Record<string, ICombinedPositionPlayerPerformanceData> = useMemo(() => {
		if (!statsPlayerBattingByTeam || !positionPlayerPerformancePlayer || !averageAges) return {};
		// Part 1: Dedupe Stats Player Batting By Team
		const dedupedStatsPlayerBattingByTeam = dedupePlayerStatsPlayerBattingByTeamAndSource(statsPlayerBattingByTeam);
		// Part 2: Aggregate Stats Player Batting By Team -> Combined Position Player Performance Data
		// using the game types from baseline_hit_probs, player age/rel age, and combined team id
		// to create a record mapping season-teamId-gameType to ICombinedPositionPlayerPerformanceData

		// Additionally, calculate player ages, relative ages
		return createStatsPlayerBattingByTeamRecord(
			dedupedStatsPlayerBattingByTeam,
			positionPlayerPerformancePlayer.birthDate,
			averageAges
		);
	}, [statsPlayerBattingByTeam, positionPlayerPerformancePlayer, averageAges]);

	const combinedPositionPlayerPerformanceDataByTeam:
		| Array<ICombinedPositionPlayerPerformanceData>
		| undefined = useMemo(() => {
		// Part 3: Iterate over player season xwOBA by team, and append entries to the above record
		const combinedPositionPlayerPerformanceByTeamRecord = appendGenericPlayerSeasonXwobaData<
			IPlayerSeasonXwobaByTeam
		>(
			playerSeasonXwobaByTeam ?? [],
			(x: IPlayerSeasonXwobaByTeam) => `${x.season}-${x.teamId}-${x.gameType}`,
			statsPlayerBattingByTeamRecord
		);
		return Object.values(combinedPositionPlayerPerformanceByTeamRecord);
	}, [statsPlayerBattingByTeamRecord, playerSeasonXwobaByTeam]);

	//
	// Season filter options
	//

	// Get list of seasons using byTeam data so that the min/max seasons have corresponding entries in the table,
	// as a season must have at least one associated team to be displayed
	const [minSeason, maxSeason] = useMemo(
		() =>
			getMinAndMaxSeason<ICombinedPositionPlayerPerformanceData>(
				combinedPositionPlayerPerformanceDataByTeam ?? []
			),
		[combinedPositionPlayerPerformanceDataByTeam]
	);
	const seasonFilters: { minSeason: number; maxSeason: number } = useMemo(
		() => getSeasonFilters(filters.minSeason, filters.maxSeason, maxSeason, NUM_DISPLAY_SEASONS),
		[filters.minSeason, filters.maxSeason, maxSeason]
	);

	//
	// Level filter options
	//

	// Compute the level filter options
	const levelFilterOptions: Array<IStatsPlayerBattingByTeamLkLevel> = useMemo(
		() =>
			combinedPositionPlayerPerformanceDataByTeam
				.reduce((acc: Array<IStatsPlayerBattingByTeamLkLevel>, c: ICombinedPositionPlayerPerformanceData) => {
					const levelRel: IStatsPlayerBattingByTeamLkLevel | undefined = c.team?.levelRel;
					if (
						levelRel &&
						levelRel.value &&
						!acc.some(val => val.value === levelRel.value) &&
						VALID_PRO_LEVELS.includes(levelRel.value)
					)
						acc.push(levelRel);
					return acc;
				}, [])
				?.sort(
					(a: IStatsPlayerBattingByTeamLkLevel, b: IStatsPlayerBattingByTeamLkLevel) =>
						(a.sortOrder ?? Number.MAX_SAFE_INTEGER) - (b.sortOrder ?? Number.MAX_SAFE_INTEGER)
				) ?? [],
		[combinedPositionPlayerPerformanceDataByTeam]
	);

	// Once the level filter options are computed for the first time, send them to the machine
	useEffect(() => {
		if (filters.levels === undefined && levelFilterOptions.length > 0) {
			const newFilters = {
				...filters,
				levels: levelFilterOptions.map((option: IStatsPlayerBattingByTeamLkLevel) => option.value)
			};
			send({ type: SET_FILTERS, value: newFilters });
		}
	}, [filters, send, levelFilterOptions]);

	//
	// Filter data for table
	//

	// Combine game type and throws filters into "OVR" when possible
	const gameTypeFilters: Array<string> = useMemo(() => {
		return filters.gameTypes.includes(GAME_TYPE_REGULAR_SEASON) &&
			filters.gameTypes.includes(GAME_TYPE_POSTSEASON) &&
			!filters.gameTypes.includes(GAME_TYPE_SPRING_TRAINING)
			? [GAME_TYPE_OVERALL]
			: filters.gameTypes;
	}, [filters.gameTypes]);
	const throwsFilter: string = useMemo(() => (filters.throws.length === 2 ? THROWS_OVERALL : filters.throws?.[0]), [
		filters.throws
	]);

	// Get columns based on handedness filter
	const positionPlayerPerformanceColumns = useMemo(() => getPositionPlayerPerformanceColumns(throwsFilter), [
		throwsFilter
	]);

	// Filter columns based on prop
	const filteredColumns = useMemo(() => {
		if (!columns) return positionPlayerPerformanceColumns;
		return positionPlayerPerformanceColumns.filter(
			(col: TColumn<TPositionPlayerPerformanceRow, keyof TPositionPlayerPerformanceRow>) =>
				columns.includes(col.value)
		);
	}, [columns, positionPlayerPerformanceColumns]);

	const filteredCombinedPositionPlayerPerformanceData:
		| Array<ICombinedPositionPlayerPerformanceData>
		| undefined = useMemo(() => {
		if (isLoading || !throwsFilter) return undefined;
		return combinedPositionPlayerPerformanceData.filter(
			(c: ICombinedPositionPlayerPerformanceData) =>
				c.season <= seasonFilters.maxSeason &&
				c.season >= seasonFilters.minSeason &&
				gameTypeFilters.includes(c.gameType)
		);
	}, [isLoading, seasonFilters, gameTypeFilters, throwsFilter, combinedPositionPlayerPerformanceData]);

	// Check for default filters
	const defaultFiltersSet: boolean = useMemo(() => {
		const availableLevels = levelFilterOptions.map((option: IStatsPlayerBattingByTeamLkLevel) => option.value);

		return isDefaultFilters(filters, undefined, availableLevels, maxSeason, NUM_DISPLAY_SEASONS);
	}, [filters, levelFilterOptions, maxSeason]);

	const resetFilters = useCallback(() => {
		send({ type: SET_FILTERS, value: DEFAULT_STATS_TABLE_FILTERS });
	}, [send]);

	//
	// Combine data for table
	//

	const combinedTableData: Array<TPositionPlayerPerformanceRow> | undefined = useMemo(() => {
		if (!filteredCombinedPositionPlayerPerformanceData) return undefined;
		const combinedData: Array<TPositionPlayerPerformanceRow> = [];
		const uniqueSeasons = [
			...new Set(
				filteredCombinedPositionPlayerPerformanceData.map(
					(c: ICombinedPositionPlayerPerformanceData) => c.season
				)
			)
		];
		uniqueSeasons.forEach((season: number) => {
			// Create an object with the overall combined position player performance data and all related
			// position player performance data by team.

			// For certain game type combinations, we need to aggregate the data into a single row
			const playerPerformanceData: Array<ICombinedPositionPlayerPerformanceData> = filteredCombinedPositionPlayerPerformanceData.filter(
				(c: ICombinedPositionPlayerPerformanceData) => c.season === season
			);
			const playerPerformanceDataByTeam: Array<ICombinedPositionPlayerPerformanceData> = combinedPositionPlayerPerformanceDataByTeam.filter(
				(c: ICombinedPositionPlayerPerformanceData) =>
					c.season === season && gameTypeFilters.includes(c.gameType)
			);
			// Only one top-level row is returned, so just use those stats
			if (playerPerformanceData.length === 1) {
				let combinedParentData = playerPerformanceData[0];
				let combinedChildData:
					| Array<TPositionPlayerPerformanceRow>
					| undefined = playerPerformanceDataByTeam.map((c: ICombinedPositionPlayerPerformanceData) => {
					return { combinedPositionPlayerPerformanceData: c };
				});
				// If there is only one child row, then we want to display it as the overall row
				if (playerPerformanceDataByTeam && playerPerformanceDataByTeam.length === 1) {
					combinedParentData = playerPerformanceDataByTeam[0];
					combinedChildData = undefined;
				}
				// If there are no child rows, then the overall row must be invalid, and we should not push data.
				// Therefore, only push the data if the length of the byTeam is non-zero
				if (playerPerformanceDataByTeam && playerPerformanceDataByTeam.length > 0)
					combinedData.push({
						combinedPositionPlayerPerformanceData: combinedParentData,
						childData: combinedChildData
					});
			}
			// Multiple top-level rows are returned, aggregate all of the data
			else if (playerPerformanceData?.length) {
				// Aggregate position player performance data across different game types into a single top-level row
				let combinedParentData = playerPerformanceData.reduce(
					(prev: ICombinedPositionPlayerPerformanceData, curr: ICombinedPositionPlayerPerformanceData) => {
						return aggregateCombinedPositionPlayerPerformanceDataRows(prev, curr);
					}
				);
				// Aggregate team level position player performance data different game types into singular team-level rows
				let combinedChildData:
					| Array<TPositionPlayerPerformanceRow>
					| undefined = playerPerformanceDataByTeam?.reduce(
					(
						combinedDataByTeam: Array<TPositionPlayerPerformanceRow>,
						dataByTeam: ICombinedPositionPlayerPerformanceData
					) => {
						if (!combinedDataByTeam.length) return [{ combinedPositionPlayerPerformanceData: dataByTeam }];
						const combinedDataMatchingTeamIndex = combinedDataByTeam.findIndex(
							(combinedData: TPositionPlayerPerformanceRow) =>
								"teamId" in combinedData.combinedPositionPlayerPerformanceData &&
								combinedData.combinedPositionPlayerPerformanceData.teamId === dataByTeam.teamId
						);
						if (combinedDataMatchingTeamIndex === -1)
							combinedDataByTeam.push({ combinedPositionPlayerPerformanceData: dataByTeam });
						else {
							const combinedDataMatchingTeamData =
								combinedDataByTeam[combinedDataMatchingTeamIndex].combinedPositionPlayerPerformanceData;
							combinedDataByTeam[combinedDataMatchingTeamIndex] = {
								combinedPositionPlayerPerformanceData: aggregateCombinedPositionPlayerPerformanceDataRows(
									combinedDataMatchingTeamData,
									dataByTeam
								)
							};
						}
						return combinedDataByTeam;
					},
					[]
				);
				// If there are no child rows, then the overall row must be invalid, and we should not push data.
				// Therefore, only push the data if the length of the byTeam is non-zero
				if (!combinedChildData || combinedChildData.length === 0) return;
				// If there is only one child row, then we want to display it as the overall row
				if (combinedChildData && combinedChildData.length === 1) {
					combinedParentData = combinedChildData[0].combinedPositionPlayerPerformanceData;
					combinedChildData = undefined;
				}
				combinedData.push({
					combinedPositionPlayerPerformanceData: combinedParentData,
					childData: combinedChildData
				});
			}
		});
		// Apply level filters
		return combinedData.reduce((acc: TPositionPlayerPerformanceRow[], row: TPositionPlayerPerformanceRow) => {
			const uniqueLevels: Array<string> = getLevelsFromRow(row);
			const shouldDisplayEntireRow = uniqueLevels.every(level => filters.levels?.includes(level));

			// If all of the levels associated with the row meet the level filters, push the entire row and return early
			if (shouldDisplayEntireRow) {
				acc.push(row);
				return acc;
			}

			// Otherwise, filter the child data and create a new parent row.
			const filteredChildData = row.childData?.filter((c: TPositionPlayerPerformanceRow) =>
				filters.levels?.includes(c.combinedPositionPlayerPerformanceData?.team?.level ?? "")
			);
			// If exactly one item in child data, that becomes parent row
			if (filteredChildData && filteredChildData?.length === 1)
				acc.push({
					combinedPositionPlayerPerformanceData: filteredChildData[0].combinedPositionPlayerPerformanceData,
					childData: undefined
				});
			// Otherwise, if there multiple items in child data, we must aggregate the child data to create a new parent row
			else if (filteredChildData && filteredChildData?.length > 1) {
				const newParentRow: ICombinedPositionPlayerPerformanceData = filteredChildData.reduce(
					(
						prev: ICombinedPositionPlayerPerformanceData,
						curr: TPositionPlayerPerformanceRow,
						index: number
					) => {
						if (index === 0) return prev;
						return aggregateCombinedPositionPlayerPerformanceDataRows(
							prev,
							curr.combinedPositionPlayerPerformanceData
						);
					},
					filteredChildData[0].combinedPositionPlayerPerformanceData
				);
				// Remove team, level, and rel. age data from new parent row before pushing
				delete newParentRow.team;
				delete newParentRow.relAge;
				acc.push({
					combinedPositionPlayerPerformanceData: newParentRow,
					childData: filteredChildData
				});
			}
			return acc;
		}, []);
	}, [
		filters.levels,
		gameTypeFilters,
		filteredCombinedPositionPlayerPerformanceData,
		combinedPositionPlayerPerformanceDataByTeam
	]);

	// Filtering
	const handleGameTypeSelect = (value: string) => {
		const newFilters = {
			...filters,
			gameTypes: updateFilters(filters.gameTypes, value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handleThrowsSelect = (value: string) => {
		const newFilters = {
			...filters,
			throws: updateFilters(filters.throws, value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	const handleLevelSelect = (value: string) => {
		const newFilters = {
			...filters,
			levels: updateFilters(filters.levels ?? [], value)
		};
		send({ type: SET_FILTERS, value: newFilters });
	};

	return (
		<VStack alignItems="start" sx={style?.container}>
			<HStack w="100%" justify="space-between">
				<HStack gap={1}>
					{title && (
						<Box fontFamily="heading" fontSize="md" fontWeight="bold">
							{title}
						</Box>
					)}
				</HStack>
				{isShowFilters && (
					<Menu closeOnSelect={false} placement="left-start">
						<ButtonGroup
							isAttached
							variant={defaultFiltersSet ? "outline" : "solid"}
							colorScheme={defaultFiltersSet ? undefined : "blue"}
						>
							{!defaultFiltersSet && (
								<IconButton
									aria-label="Close"
									icon={<CloseIcon fill="white" />}
									onClick={resetFilters}
								/>
							)}
							<MenuButton
								as={IconButton}
								aria-label="Options"
								icon={<FilterAlt color={defaultFiltersSet ? "gray.500" : "white"} boxSize={5} />}
							>
								MenuItem
							</MenuButton>
						</ButtonGroup>
						<Portal>
							<MenuList minWidth="240px" maxHeight="md" overflow="scroll">
								<MenuOptionGroup title="Pitcher Throws" type="checkbox" value={filters.throws}>
									<MenuItemOption value={THROWS_L} onClick={() => handleThrowsSelect(THROWS_L)}>
										Left
									</MenuItemOption>
									<MenuItemOption value={THROWS_R} onClick={() => handleThrowsSelect(THROWS_R)}>
										Right
									</MenuItemOption>
								</MenuOptionGroup>
								<MenuDivider />
								<MenuOptionGroup title="Game Type" type="checkbox" value={filters.gameTypes}>
									<MenuItemOption
										value={GAME_TYPE_SPRING_TRAINING}
										onClick={() => handleGameTypeSelect(GAME_TYPE_SPRING_TRAINING)}
									>
										Spring Training
									</MenuItemOption>
									<MenuItemOption
										value={GAME_TYPE_REGULAR_SEASON}
										onClick={() => handleGameTypeSelect(GAME_TYPE_REGULAR_SEASON)}
									>
										Regular Season
									</MenuItemOption>
									<MenuItemOption
										value={GAME_TYPE_POSTSEASON}
										onClick={() => handleGameTypeSelect(GAME_TYPE_POSTSEASON)}
									>
										Postseason
									</MenuItemOption>
								</MenuOptionGroup>
								<MenuOptionGroup title="Seasons">
									<VStack paddingLeft={4} paddingRight={4} sx={{ alignItems: "leading" }}>
										{minSeason === maxSeason && (
											<Tooltip hasArrow placement="top" label="Only one season of data exists">
												<HStack>
													<OutlineInfo color="gray.500" />
													<Text>{minSeason}</Text>
												</HStack>
											</Tooltip>
										)}
										{minSeason !== maxSeason && (
											<VStack>
												<RangeSlider
													value={[seasonFilters.minSeason, seasonFilters.maxSeason]}
													min={minSeason}
													max={maxSeason}
													step={1}
													onChange={(seasons: number[]) => {
														send({
															type: SET_FILTERS,
															value: {
																...filters,
																minSeason: seasons[0],
																maxSeason: seasons[1]
															}
														});
													}}
													onMouseEnter={() => setShowSeasonRangeTooltip(true)}
													onMouseLeave={() => setShowSeasonRangeTooltip(false)}
												>
													<RangeSliderTrack>
														<RangeSliderFilledTrack bg="black" />
													</RangeSliderTrack>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.minSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={0} />
													</Tooltip>
													<Tooltip
														hasArrow
														placement="top"
														isOpen={showSeasonRangeTooltip}
														label={seasonFilters.maxSeason}
													>
														<RangeSliderThumb bg="gray.500" boxSize={3} index={1} />
													</Tooltip>
												</RangeSlider>
												<Flex sx={{ width: "100%" }}>
													<Text fontSize="sm">{minSeason}</Text>
													<Spacer />
													<Text fontSize="sm">{maxSeason}</Text>
												</Flex>
											</VStack>
										)}
									</VStack>
								</MenuOptionGroup>
								<MenuOptionGroup
									title="Levels"
									type="checkbox"
									value={filters.levels ?? VALID_PRO_LEVELS}
								>
									{levelFilterOptions.map((option: IStatsPlayerBattingByTeamLkLevel) => (
										<MenuItemOption
											value={option.value}
											onClick={() => handleLevelSelect(option.value)}
										>
											<TeamLevelBadge level={option.value} />
										</MenuItemOption>
									))}
								</MenuOptionGroup>
							</MenuList>
						</Portal>
					</Menu>
				)}
			</HStack>
			<Box sx={style?.tableContainer}>
				<Table<TPositionPlayerPerformanceRow, keyof TPositionPlayerPerformanceRow>
					columns={filteredColumns}
					parentColumns={POSITION_PLAYER_PERFORMANCE_PARENT_COLUMNS}
					data={combinedTableData}
					emptyDataDisplayText={"No Position Player Performance Data Found"}
					isLoadingData={isLoading || (!shouldFetchData && data?.isLoading)}
					isExpandableRows
					getCustomRowKeyFunction={(row: TPositionPlayerPerformanceRow) => {
						return `${row.combinedPositionPlayerPerformanceData.season}`;
					}}
					defaultSortColumns={[
						{
							columnValue: "season",
							sortDirection: ASC
						},
						{
							columnValue: "pitches",
							sortDirection: DESC
						},
						{
							columnValue: "pitchType",
							sortDirection: ASC
						},
						{
							columnValue: "level",
							sortDirection: DESC
						}
					]}
					style={{ th: { textTransform: "none" }, parentTh: { textTransform: "none" } }}
					{...tableProps}
				/>
			</Box>
		</VStack>
	);
};

export default PositionPlayerPerformanceTable;
