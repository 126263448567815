import React, { useState, useEffect, useCallback } from "react";
import {
	IconButton,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverArrow,
	PopoverCloseButton,
	PopoverHeader,
	PopoverBody,
	useDisclosure
} from "@chakra-ui/react";

import axios from "_redux/_utils/_axios";
import { sortBy } from "utils/helpers";
import { agent } from "_react/shared/_types/mesa/agent";
import { agency } from "_react/shared/_types/mesa/agency";
import { BASE_URL } from "utils/redux_constants";
import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";
import { TPlayerClassification, PRO } from "utils/tsutils";

import { AgentOrAgencyPopupInfo } from "_react/playerpage/header/AgentOrAgencyField/PopupInfo";
import {
	TPlayerType,
	TPlayerInfoType,
	INFO_ROWS,
	invalidAgentOrAgency
} from "_react/playerpage/header/AgentOrAgencyField/_helpers";

type AgentAgencyPopoverProps = {
	playerType: TPlayerType;
	infoKey: "agent" | "agency";
	infoId: string | number;
	org?: string;
	playerClassification: TPlayerClassification;
};

const AgentAgencyPopover = ({ playerType, infoKey, infoId, org, playerClassification }: AgentAgencyPopoverProps) => {
	const { onOpen, onClose, isOpen } = useDisclosure();

	// All of this is legacy logic from `_react/playerpage/header/AgentOrAgencyField/Popup.tsx`
	// Keep until we think it's worth refactoring
	const [infoObject, setInfoObject] = useState<agent | agency | null>(null);
	const [isFetchingInfoObj, setIsFetchingInfoObj] = useState<boolean>(false);
	const [isFailedInfoObj, setIsFailedInfoObj] = useState<boolean>(false);
	const [players, setPlayers] = useState<TPlayerInfoType[]>([]);
	const [infoPlayers, setInfoPlayers] = useState<{ text: string; active: boolean; link?: string }[]>([]);
	const [isFetchingPlayers, setIsFetchingPlayers] = useState<boolean>(false);
	const [isFailedPlayers, setIsFailedPlayers] = useState<boolean>(false);

	const fetchInfoObject = useCallback(() => {
		if (invalidAgentOrAgency(infoId)) return;
		setIsFailedInfoObj(false);
		setIsFetchingInfoObj(true);
		axios
			.get(
				playerClassification === PRO && infoKey === "agent"
					? `${BASE_URL}/agent?ebisAgentId=${infoId}`
					: `${BASE_URL}/${infoKey}/${infoId}`
			)
			.then(response => (response.data[0] ? setInfoObject(response.data[0]) : setIsFailedInfoObj(true)))
			.catch(() => setIsFailedInfoObj(true))
			.finally(() => setIsFetchingInfoObj(false));
	}, [setIsFailedInfoObj, setIsFetchingInfoObj, setInfoObject, infoKey, infoId, playerClassification]);
	useEffect(() => {
		if (isOpen && !isFetchingInfoObj && !isFailedInfoObj && infoObject == null) {
			fetchInfoObject();
		}
		if (!isOpen) {
			setIsFailedInfoObj(false);
		}
	}, [isOpen, isFetchingInfoObj, isFailedInfoObj, infoObject, fetchInfoObject, setIsFailedInfoObj]);

	const fetchAgentOrAgencyPlayers = useCallback(() => {
		if (invalidAgentOrAgency(infoId)) return;
		setIsFailedPlayers(false);
		setIsFetchingPlayers(true);
		axios
			.get(
				infoKey === "agent"
					? `${BASE_URL}/agent/${infoId}/player?agentType=${playerType}`
					: `${BASE_URL}/agency/${infoId}/player?agencyType=${playerType}`
			)
			.then(response => {
				const updatedPlayers = [];
				const playersByType: { [key: string]: TPlayerInfoType[] } = response.data;
				for (const playerList of Object.values(playersByType)) {
					updatedPlayers.push(...playerList);
				}
				setPlayers(updatedPlayers);
			})
			.catch(() => setIsFailedPlayers(true))
			.finally(() => setIsFetchingPlayers(false));
	}, [infoId, infoKey, playerType]);
	useEffect(() => {
		if (isOpen && !isFetchingPlayers && !isFailedPlayers && !players.length) {
			fetchAgentOrAgencyPlayers();
		}
		if (!isOpen) {
			setIsFailedPlayers(false);
		}
	}, [isOpen, fetchAgentOrAgencyPlayers, setIsFailedPlayers, isFetchingPlayers, isFailedPlayers, players]);

	useEffect(() => {
		if (players.length) {
			const sortedPlayers = sortBy(players, [
				(p: TPlayerInfoType) => (p.lastName ? p.lastName.toUpperCase() : null),
				(p: TPlayerInfoType) => (p.firstName ? p.firstName.toUpperCase() : null)
			]);
			setInfoPlayers(
				sortedPlayers.map((player: TPlayerInfoType) => ({
					text: `${player.firstName || ""} ${player.lastName || ""}${
						player.eligibleYear != null ? ` (${player.eligibleYear})` : ""
					}`,
					active: player.active != null ? player.active === 1 : true,
					link: player.id != null ? `/player?id=${player.id}` : undefined
				}))
			);
		}
	}, [players]);

	const infoKeyCapitalized = infoKey.charAt(0).toUpperCase() + infoKey.slice(1);

	return (
		<Popover placement="left" isOpen={isOpen} onOpen={onOpen} onClose={onClose} isLazy lazyBehavior="keepMounted">
			<PopoverTrigger>
				<IconButton
					aria-label={`Show ${infoKeyCapitalized} Information`}
					variant="ghost"
					h="fit-content"
					minWidth="fit-content"
					icon={<OutlineInfo />}
				/>
			</PopoverTrigger>
			<PopoverContent w="96">
				<PopoverArrow />
				<PopoverCloseButton />
				<PopoverHeader textAlign="left">{infoKeyCapitalized} Information</PopoverHeader>
				<PopoverBody fontSize="xs">
					{/* Legacy popup content component */}
					<AgentOrAgencyPopupInfo
						isFailedPlayers={isFailedPlayers}
						isFetchingPlayers={isFetchingPlayers}
						isFailedInfoObj={isFailedInfoObj}
						isFetchingInfoObj={isFetchingInfoObj}
						infoId={infoId}
						infoKey={infoKey}
						infoObject={infoObject}
						infoPlayers={infoPlayers}
						infoRows={INFO_ROWS[infoKey]}
						org={org}
					/>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

export default AgentAgencyPopover;
