import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";

import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import {
	THROWS_L,
	THROWS_R,
	GAME_TYPE_POSTSEASON,
	GAME_TYPE_REGULAR_SEASON,
	PLAYING_LEVEL_PRO,
	BATS_OVERALL
} from "_react/shared/data_models/hitter_grades/_constants";
import {
	IPlayerSeasonHitterGrades,
	IPlayerSeasonHitterGradesByTeamApiResponse
} from "_react/shared/data_models/hitter_grades/_types";
import {
	fetchPlayerSeasonHitterGrades,
	fetchPlayerSeasonHitterGradesByTeam
} from "_react/shared/data_models/hitter_grades/_network";
import { TPositionPlayerFoundationalSkillsTableData } from "_react/shared/ui/data/tables/PositionPlayerFoundationalSkillsTable/_types";

export const PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE = "playerSeasonHitterGrades";
export const PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE = "playerSeasonHitterGradesByTeam";

export type TPositionPlayerFoundationalSkillsTableCancelSource = {
	[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TPositionPlayerFoundationalSkillsTableFilters = {
	gameTypes: Array<string>;
	throws: Array<string>;
	levels?: Array<string>;
	maxSeason?: number;
	minSeason?: number;
};

export type TPositionPlayerFoundationalSkillsTableContext = {
	playerId?: number;
	lastPlayerId?: number; // Not currently used
	shouldFetchData?: boolean;
	filters: TPositionPlayerFoundationalSkillsTableFilters;
	playerSeasonHitterGrades?: Array<IPlayerSeasonHitterGrades> | null;
	playerSeasonHitterGradesByTeam?: Array<IPlayerSeasonHitterGradesByTeamApiResponse> | null;
	cancelSources: TPositionPlayerFoundationalSkillsTableCancelSource;
	toast?: CreateToastFnReturn;
};

interface IPositionPlayerFoundationalSkillsTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches all hitter grades by player season
				playerSeasonHitterGrades: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all hitter grades by player season team
				playerSeasonHitterGradesByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_PLAYER_SEASON_HITTER_GRADES = { initialized: { playerSeasonHitterGrades: "fetching" } };
export const FETCHING_PLAYER_SEASON_HITTER_GRADES_BYTEAM = {
	initialized: { playerSeasonHitterGradesByTeam: "fetching" }
};

const FETCH_PLAYER_SEASON_HITTER_GRADES_DONE = "done.invoke.fetchPlayerSeasonHitterGrades:invocation[0]";
const FETCH_PLAYER_SEASON_HITTER_GRADES_BYTEAM_DONE = "done.invoke.fetchPlayerSeasonHitterGradesByTeam:invocation[0]";
const FETCH_PLAYER_SEASON_HITTER_GRADES_ERROR = "error.platform.fetchPlayerSeasonHitterGrades:invocation[0]";
const FETCH_PLAYER_SEASON_HITTER_GRADES_BYTEAM_ERROR =
	"error.platform.fetchPlayerSeasonHitterGradesByTeam:invocation[0]";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PLAYER_SEASON_HITTER_GRADES = "SET_PLAYER_SEASON_HITTER_GRADES";
export const SET_PLAYER_SEASON_HITTER_GRADES_BYTEAM = "SET_PLAYER_SEASON_HITTER_GRADES_BYTEAM";
export const SET_FILTERS = "SET_FILTERS";

type TFetchPlayerSeasonHitterGradesEvent = {
	type: typeof FETCH_PLAYER_SEASON_HITTER_GRADES_DONE;
	data: Array<IPlayerSeasonHitterGrades> | undefined;
};
type TFetchPlayerSeasonHitterGradesByTeamEvent = {
	type: typeof FETCH_PLAYER_SEASON_HITTER_GRADES_BYTEAM_DONE;
	data: Array<IPlayerSeasonHitterGradesByTeamApiResponse> | undefined;
};

type TFetchPlayerSeasonHitterGradesErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_HITTER_GRADES_ERROR;
	data?: AxiosError | Error;
};
type TFetchPlayerSeasonHitterGradesByTeamErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_HITTER_GRADES_BYTEAM_ERROR;
	data?: AxiosError | Error;
};

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value: number | undefined };
type TSetPlayerSeasonHitterGradesEvent = {
	type: typeof SET_PLAYER_SEASON_HITTER_GRADES;
	value?: Array<IPlayerSeasonHitterGrades>;
};
type TSetPlayerSeasonHitterGradesByTeamEvent = {
	type: typeof SET_PLAYER_SEASON_HITTER_GRADES_BYTEAM;
	value?: Array<IPlayerSeasonHitterGradesByTeamApiResponse>;
};
type TSetFiltersEvent = {
	type: typeof SET_FILTERS;
	value: TPositionPlayerFoundationalSkillsTableFilters;
};

type TPositionPlayerFoundationalSkillsTableEvent =
	| TFetchPlayerSeasonHitterGradesEvent
	| TFetchPlayerSeasonHitterGradesByTeamEvent
	| TFetchPlayerSeasonHitterGradesErrorEvent
	| TFetchPlayerSeasonHitterGradesByTeamErrorEvent
	| TSetPlayerIdEvent
	| TSetPlayerSeasonHitterGradesEvent
	| TSetPlayerSeasonHitterGradesByTeamEvent
	| TSetFiltersEvent;

export type TPositionPlayerFoundationalSkillsTableSend = Interpreter<
	TPositionPlayerFoundationalSkillsTableContext,
	IPositionPlayerFoundationalSkillsTableStateSchema,
	TPositionPlayerFoundationalSkillsTableEvent
>["send"];

const PositionPlayerFoundationalSkillsTableMachine = (
	playerIdProp?: number,
	data?: TPositionPlayerFoundationalSkillsTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<
		TPositionPlayerFoundationalSkillsTableContext,
		IPositionPlayerFoundationalSkillsTableStateSchema,
		TPositionPlayerFoundationalSkillsTableEvent
	>(
		{
			id: "positionPlayerFoundationalSkillsTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchDataProp,
				filters: {
					gameTypes: [GAME_TYPE_REGULAR_SEASON, GAME_TYPE_POSTSEASON],
					throws: [THROWS_L, THROWS_R],
					levels: undefined
				},
				playerSeasonHitterGrades: data?.playerSeasonHitterGrades,
				playerSeasonHitterGradesByTeam: data?.playerSeasonHitterGradesByTeam,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						SET_PLAYER_ID: {
							actions: [
								"setPlayerId",
								"clearPlayerSeasonHitterGrades",
								"clearPlayerSeasonHitterGradesByTeam"
							],
							cond: "shouldSetPlayerId"
						},
						SET_PLAYER_SEASON_HITTER_GRADES: { actions: "setPlayerSeasonHitterGrades" },
						SET_PLAYER_SEASON_HITTER_GRADES_BYTEAM: { actions: "setPlayerSeasonHitterGradesByTeam" },
						SET_FILTERS: { actions: "setFilters" }
					},
					states: {
						playerSeasonHitterGrades: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonHitterGrades",
												cond: "shouldFetchPlayerSeasonHitterGrades"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonHitterGrades",
									entry: ["refreshPlayerSeasonHitterGradesCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonHitterGrades",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonHitterGradesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonHitterGradesErrored"
										}
									}
								}
							}
						},
						playerSeasonHitterGradesByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonHitterGradesByTeam",
												cond: "shouldFetchPlayerSeasonHitterGradesByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonHitterGradesByTeam",
									entry: ["refreshPlayerSeasonHitterGradesByTeamCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonHitterGradesByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonHitterGradesByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonHitterGradesByTeamErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (
					context: TPositionPlayerFoundationalSkillsTableContext,
					event: TPositionPlayerFoundationalSkillsTableEvent
				) => event.type === SET_PLAYER_ID && context.playerId !== event.value,
				shouldFetchPlayerSeasonHitterGrades: (
					context: TPositionPlayerFoundationalSkillsTableContext,
					_event: TPositionPlayerFoundationalSkillsTableEvent
				) =>
					context.playerSeasonHitterGrades === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPlayerSeasonHitterGradesByTeam: (
					context: TPositionPlayerFoundationalSkillsTableContext,
					_event: TPositionPlayerFoundationalSkillsTableEvent
				) =>
					context.playerSeasonHitterGradesByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true
			},
			actions: {
				clearPlayerSeasonHitterGrades: assign<
					TPositionPlayerFoundationalSkillsTableContext,
					TPositionPlayerFoundationalSkillsTableEvent
				>({
					playerSeasonHitterGrades: (
						_context: TPositionPlayerFoundationalSkillsTableContext,
						_event: TPositionPlayerFoundationalSkillsTableEvent
					) => undefined,
					cancelSources: (
						context: TPositionPlayerFoundationalSkillsTableContext,
						_event: TPositionPlayerFoundationalSkillsTableEvent
					) => {
						const { cancelSources } = context;
						cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				clearPlayerSeasonHitterGradesByTeam: assign<
					TPositionPlayerFoundationalSkillsTableContext,
					TPositionPlayerFoundationalSkillsTableEvent
				>({
					playerSeasonHitterGradesByTeam: (
						_context: TPositionPlayerFoundationalSkillsTableContext,
						_event: TPositionPlayerFoundationalSkillsTableEvent
					) => undefined,
					cancelSources: (
						context: TPositionPlayerFoundationalSkillsTableContext,
						_event: TPositionPlayerFoundationalSkillsTableEvent
					) => {
						const { cancelSources } = context;
						cancelSources[PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				// Set Context Actions
				setPlayerId: assign<
					TPositionPlayerFoundationalSkillsTableContext,
					TPositionPlayerFoundationalSkillsTableEvent
				>({
					playerId: (
						context: TPositionPlayerFoundationalSkillsTableContext,
						event: TPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					},
					cancelSources: (
						context: TPositionPlayerFoundationalSkillsTableContext,
						_event: TPositionPlayerFoundationalSkillsTableEvent
					) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				setPlayerSeasonHitterGrades: assign<
					TPositionPlayerFoundationalSkillsTableContext,
					TPositionPlayerFoundationalSkillsTableEvent
				>({
					playerSeasonHitterGrades: (
						context: TPositionPlayerFoundationalSkillsTableContext,
						event: TPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_HITTER_GRADES) return context.playerSeasonHitterGrades;
						return event.value;
					},
					cancelSources: (
						context: TPositionPlayerFoundationalSkillsTableContext,
						event: TPositionPlayerFoundationalSkillsTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_HITTER_GRADES) return cancelSources;
						cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setPlayerSeasonHitterGradesByTeam: assign<
					TPositionPlayerFoundationalSkillsTableContext,
					TPositionPlayerFoundationalSkillsTableEvent
				>({
					playerSeasonHitterGradesByTeam: (
						context: TPositionPlayerFoundationalSkillsTableContext,
						event: TPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_HITTER_GRADES_BYTEAM)
							return context.playerSeasonHitterGradesByTeam;
						return event.value;
					},
					cancelSources: (
						context: TPositionPlayerFoundationalSkillsTableContext,
						event: TPositionPlayerFoundationalSkillsTableEvent
					) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_HITTER_GRADES_BYTEAM) return cancelSources;
						cancelSources[PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setFilters: assign<
					TPositionPlayerFoundationalSkillsTableContext,
					TPositionPlayerFoundationalSkillsTableEvent
				>({
					filters: (
						context: TPositionPlayerFoundationalSkillsTableContext,
						event: TPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return event.value;
					}
				}),
				// Cancel Source Actions
				refreshPlayerSeasonHitterGradesCancelSource: assign<
					TPositionPlayerFoundationalSkillsTableContext,
					TPositionPlayerFoundationalSkillsTableEvent
				>({
					cancelSources: (
						context: TPositionPlayerFoundationalSkillsTableContext,
						_event: TPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonHitterGradesByTeamCancelSource: assign<
					TPositionPlayerFoundationalSkillsTableContext,
					TPositionPlayerFoundationalSkillsTableEvent
				>({
					cancelSources: (
						context: TPositionPlayerFoundationalSkillsTableContext,
						_event: TPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchPlayerSeasonHitterGradesSuccess: assign<
					TPositionPlayerFoundationalSkillsTableContext,
					TPositionPlayerFoundationalSkillsTableEvent
				>({
					playerSeasonHitterGrades: (
						context: TPositionPlayerFoundationalSkillsTableContext,
						event: TPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_HITTER_GRADES_DONE)
							return context.playerSeasonHitterGrades;
						return event.data;
					}
				}),
				handleFetchPlayerSeasonHitterGradesByTeamSuccess: assign<
					TPositionPlayerFoundationalSkillsTableContext,
					TPositionPlayerFoundationalSkillsTableEvent
				>({
					playerSeasonHitterGradesByTeam: (
						context: TPositionPlayerFoundationalSkillsTableContext,
						event: TPositionPlayerFoundationalSkillsTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_HITTER_GRADES_BYTEAM_DONE)
							return context.playerSeasonHitterGradesByTeam;
						return event.data;
					}
				}),
				// Fetch Error Actions
				handleFetchPlayerSeasonHitterGradesErrored: (
					context: TPositionPlayerFoundationalSkillsTableContext,
					event: TPositionPlayerFoundationalSkillsTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_HITTER_GRADES_ERROR ? event.data : undefined,
						context.toast,
						"Foundational Skills - Hitter Grades",
						"Error fetching hitter grades by player and season."
					);
				},
				handleFetchPlayerSeasonHitterGradesByTeamErrored: (
					context: TPositionPlayerFoundationalSkillsTableContext,
					event: TPositionPlayerFoundationalSkillsTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_HITTER_GRADES_BYTEAM_ERROR ? event.data : undefined,
						context.toast,
						"Foundational Skills - Hitter Grades by Team",
						"Error fetching hitter grades by player, season, and team"
					);
				}
			},
			services: {
				fetchPlayerSeasonHitterGrades: (
					context: TPositionPlayerFoundationalSkillsTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonHitterGrades(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_PRO,
								bats: BATS_OVERALL,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_HITTER_GRADES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonHitterGradesByTeam: (
					context: TPositionPlayerFoundationalSkillsTableContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonHitterGradesByTeam(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_PRO,
								bats: BATS_OVERALL,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_HITTER_GRADES_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PositionPlayerFoundationalSkillsTableMachine;
