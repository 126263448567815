import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { isEqual } from "lodash";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import { TReplacementLevelActive } from "_react/shared/data_models/phred/_types";
import { TSimpleProjections } from "_react/shared/data_models/projections/_types";
import { extractFromEventDataArray } from "_react/shared/_helpers/xstate";

import { fetchSimilarProjectionsPlayers } from "_react/shared/data_models/projections/_network";
import { fetchReplacementLevelActive } from "_react/shared/data_models/phred/_network";

import { TSimilarPlayersFilters } from "_react/shared/ui/data/tables/SimilarPlayersProjectionTable/_types";

export type TSimilarPlayersProjectionTableContext = {
	filters?: TSimilarPlayersFilters;
	lastFilters?: TSimilarPlayersFilters; // Not currently used
	season?: number;
	lastSeason?: number; // Not currently used
	ros?: number;
	lastRos?: number; // Not currently used
	shouldFetchData?: boolean;
	simpleProjections?: Array<TSimpleProjections>;
	replacementLevel?: TReplacementLevelActive | null;
	cancelSource?: CancelTokenSource | null;
	toast?: CreateToastFnReturn;
};

interface ISimilarPlayersProjectionTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches all similar players data
				similarPlayers: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches the replacement levels
				replacementLevel: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_SIMILAR_PLAYERS = { initialized: { similarPlayers: "fetching" } };
export const FETCHING_REPLACEMENT_LEVEL = { initialized: { replacementLevel: "fetching" } };

const FETCH_SIMILAR_PLAYERS_DONE = "done.invoke.fetchSimilarPlayers:invocation[0]";
const FETCH_REPLACEMENT_LEVEL_DONE = "done.invoke.fetchReplacementLevel:invocation[0]";
const FETCH_SIMILAR_PLAYERS_ERROR = "error.platform.fetchSimilarPlayers:invocation[0]";
const FETCH_REPLACEMENT_LEVEL_ERROR = "error.platform.fetchReplacementLevel:invocation[0]";
export const SET_FILTERS = "SET_FILTERS";
export const SET_SIMPLE_PROJECTIONS = "SET_SIMPLE_PROJECTIONS";
export const SET_SEASON = "SET_SEASON";
export const SET_ROS = "SET_ROS";

type TFetchSurplusValuesEvent = {
	type: typeof FETCH_SIMILAR_PLAYERS_DONE;
	data: Array<TSimpleProjections> | undefined;
};
type TFetchReplacementLevelEvent = {
	type: typeof FETCH_REPLACEMENT_LEVEL_DONE;
	data: Array<TReplacementLevelActive> | undefined;
};

type TFetchSurplusValuesErrorEvent = {
	type: typeof FETCH_SIMILAR_PLAYERS_ERROR;
	data?: AxiosError | Error;
};
type TFetchReplacementLevelErrorEvent = {
	type: typeof FETCH_REPLACEMENT_LEVEL_ERROR;
	data?: AxiosError | Error;
};

type TSetFiltersEvent = { type: typeof SET_FILTERS; value: TSimilarPlayersFilters };
type TSetSimpleProjectionsEvent = { type: typeof SET_SIMPLE_PROJECTIONS; value?: Array<TSimpleProjections> };
type TSetSeasonEvent = { type: typeof SET_SEASON; value?: number };
type TSetRosEvent = { type: typeof SET_ROS; value?: number };

type TSimilarPlayersProjectionTableEvent =
	| TFetchSurplusValuesEvent
	| TFetchReplacementLevelEvent
	| TFetchSurplusValuesErrorEvent
	| TFetchReplacementLevelErrorEvent
	| TSetFiltersEvent
	| TSetSimpleProjectionsEvent
	| TSetSeasonEvent
	| TSetRosEvent;

export type TSimilarPlayersProjectionTableSend = Interpreter<
	TSimilarPlayersProjectionTableContext,
	ISimilarPlayersProjectionTableStateSchema,
	TSimilarPlayersProjectionTableEvent
>["send"];

const SimilarPlayersProjectionTableMachine = (
	filtersProp?: TSimilarPlayersFilters,
	seasonProp?: number,
	rosProp?: number,
	data?: Array<TSimpleProjections>,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<
		TSimilarPlayersProjectionTableContext,
		ISimilarPlayersProjectionTableStateSchema,
		TSimilarPlayersProjectionTableEvent
	>(
		{
			id: "similarPlayersProjectionsTable",
			initial: "initializing",
			context: {
				filters: filtersProp,
				lastFilters: undefined,
				season: seasonProp,
				lastSeason: seasonProp,
				ros: rosProp,
				lastRos: rosProp,
				shouldFetchData: shouldFetchDataProp,
				simpleProjections: data,
				replacementLevel: undefined,
				cancelSource: undefined,
				toast: toastProp
			},
			states: {
				initializing: {
					always: { target: "initialized" }
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_SIMPLE_PROJECTIONS]: { actions: "setSimpleProjections" },
						[SET_FILTERS]: {
							actions: ["setFilters", "clearSimpleProjections"],
							cond: "shouldSetFilters"
						},
						[SET_SEASON]: {
							actions: ["setSeason", "clearSimpleProjections"],
							cond: "shouldSetSeason"
						},
						[SET_ROS]: {
							actions: ["setRos", "clearSimpleProjections"],
							cond: "shouldSetRos"
						}
					},
					states: {
						similarPlayers: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchSimilarPlayers",
												cond: "shouldFetchSimilarPlayers"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchSimilarPlayers",
									entry: ["refreshCancelSource"],
									invoke: {
										src: "fetchSimilarPlayers",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchSimilarPlayersSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchSimilarPlayersErrored"
										}
									}
								}
							}
						},
						replacementLevel: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchReplacementLevel",
												cond: "shouldFetchReplacementLevel"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchReplacementLevel",
									invoke: {
										src: "fetchReplacementLevel",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchReplacementLevelSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchReplacementLevelErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetFilters: (
					context: TSimilarPlayersProjectionTableContext,
					event: TSimilarPlayersProjectionTableEvent
				) => event.type === SET_FILTERS && !isEqual(context.filters, event.value),
				shouldSetSeason: (
					context: TSimilarPlayersProjectionTableContext,
					event: TSimilarPlayersProjectionTableEvent
				) => event.type === SET_SEASON && context.season !== event.value,
				shouldSetRos: (
					context: TSimilarPlayersProjectionTableContext,
					event: TSimilarPlayersProjectionTableEvent
				) => event.type === SET_ROS && context.ros !== event.value,
				shouldFetchSimilarPlayers: (
					context: TSimilarPlayersProjectionTableContext,
					_event: TSimilarPlayersProjectionTableEvent
				) =>
					context.simpleProjections === undefined &&
					context.filters !== undefined &&
					context.season !== undefined &&
					context.ros !== undefined &&
					context.shouldFetchData === true,
				shouldFetchReplacementLevel: (
					context: TSimilarPlayersProjectionTableContext,
					_event: TSimilarPlayersProjectionTableEvent
				) => context.replacementLevel === undefined && context.shouldFetchData === true
			},
			actions: {
				clearSimpleProjections: assign<
					TSimilarPlayersProjectionTableContext,
					TSimilarPlayersProjectionTableEvent
				>({
					simpleProjections: (
						_context: TSimilarPlayersProjectionTableContext,
						_event: TSimilarPlayersProjectionTableEvent
					) => undefined,
					cancelSource: (
						context: TSimilarPlayersProjectionTableContext,
						_event: TSimilarPlayersProjectionTableEvent
					) => {
						context.cancelSource?.cancel();
						return undefined;
					}
				}),
				setFilters: assign<TSimilarPlayersProjectionTableContext, TSimilarPlayersProjectionTableEvent>({
					filters: (
						context: TSimilarPlayersProjectionTableContext,
						event: TSimilarPlayersProjectionTableEvent
					) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return { ...context.filters, ...event.value };
					}
				}),
				setSimpleProjections: assign<
					TSimilarPlayersProjectionTableContext,
					TSimilarPlayersProjectionTableEvent
				>({
					simpleProjections: (
						context: TSimilarPlayersProjectionTableContext,
						event: TSimilarPlayersProjectionTableEvent
					) => {
						if (event.type !== SET_SIMPLE_PROJECTIONS) return context.simpleProjections;
						return event.value;
					},
					cancelSource: (
						context: TSimilarPlayersProjectionTableContext,
						_event: TSimilarPlayersProjectionTableEvent
					) => {
						context.cancelSource?.cancel();
						return undefined;
					}
				}),
				setSeason: assign<TSimilarPlayersProjectionTableContext, TSimilarPlayersProjectionTableEvent>({
					season: (
						context: TSimilarPlayersProjectionTableContext,
						event: TSimilarPlayersProjectionTableEvent
					) => {
						if (event.type !== SET_SEASON) return context.season;
						return event.value;
					}
				}),
				setRos: assign<TSimilarPlayersProjectionTableContext, TSimilarPlayersProjectionTableEvent>({
					ros: (
						context: TSimilarPlayersProjectionTableContext,
						event: TSimilarPlayersProjectionTableEvent
					) => {
						if (event.type !== SET_ROS) return context.ros;
						return event.value;
					}
				}),
				refreshCancelSource: assign<TSimilarPlayersProjectionTableContext, TSimilarPlayersProjectionTableEvent>(
					{
						cancelSource: (
							context: TSimilarPlayersProjectionTableContext,
							_event: TSimilarPlayersProjectionTableEvent
						) => {
							context.cancelSource?.cancel();
							return getCancelSource();
						}
					}
				),
				handleFetchSimilarPlayersSuccess: assign<
					TSimilarPlayersProjectionTableContext,
					TSimilarPlayersProjectionTableEvent
				>({
					simpleProjections: (
						context: TSimilarPlayersProjectionTableContext,
						event: TSimilarPlayersProjectionTableEvent
					) => {
						if (event.type !== FETCH_SIMILAR_PLAYERS_DONE) return context.simpleProjections;
						// This is a safety net, in theory this should not happen but it does sometimes
						// Refetch the data if the seasons do not match
						if (event.data?.length && event.data[0].season !== context.season) return undefined;
						return event.data;
					}
				}),
				handleFetchReplacementLevelSuccess: assign<
					TSimilarPlayersProjectionTableContext,
					TSimilarPlayersProjectionTableEvent
				>({
					replacementLevel: (
						context: TSimilarPlayersProjectionTableContext,
						event: TSimilarPlayersProjectionTableEvent
					) => {
						if (event.type !== FETCH_REPLACEMENT_LEVEL_DONE) return context.replacementLevel;
						return extractFromEventDataArray<TSimilarPlayersProjectionTableEvent>(event);
					}
				}),
				handleFetchSimilarPlayersErrored: (
					context: TSimilarPlayersProjectionTableContext,
					event: TSimilarPlayersProjectionTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_SIMILAR_PLAYERS_ERROR ? event.data : undefined,
						context.toast,
						"Similar Players",
						"Error fetching similar players."
					);
				},
				handleFetchReplacementLevelErrored: (
					context: TSimilarPlayersProjectionTableContext,
					event: TSimilarPlayersProjectionTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_REPLACEMENT_LEVEL_ERROR ? event.data : undefined,
						context.toast,
						"Replacement Level",
						"Error fetching replacement level data."
					);
				}
			},
			services: {
				fetchSimilarPlayers: (context: TSimilarPlayersProjectionTableContext, _event: AnyEventObject) => {
					const { filters, season, ros } = context;
					if (filters === undefined || season === undefined || ros === undefined)
						return Promise.resolve(null);
					const fetchFunc = () =>
						fetchSimilarProjectionsPlayers(
							{
								playerId: filters.playerId,
								season: season,
								ros: ros === 1,
								projectedPositionGroup: filters.positionGroup,
								isSameOrg: filters?.isSameOrg,
								isPhillies: filters?.isPhillies,
								isFortyMan: filters?.isFortyMan,
								isProspect: filters?.isProspect,
								numberSimilarPlayers: filters?.numberSimilarPlayers,
								isUseCache: true
							},
							context.cancelSource?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchReplacementLevel: (_context: TSimilarPlayersProjectionTableContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchReplacementLevelActive({
							sort: "date",
							isSortDescending: true,
							limit: 1
						});
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default SimilarPlayersProjectionTableMachine;
