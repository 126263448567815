import dayjs from "dayjs";

import { IIntlProspectValue } from "_react/shared/data_models/phred/_types";

export const formatDateRange = (intlProspectValue: IIntlProspectValue | undefined) => {
	if (intlProspectValue === undefined) return undefined;
	const startDate = dayjs(intlProspectValue.reportRangeStartDate);
	const endDate = dayjs(intlProspectValue.reportRangeEndDate);

	return `${startDate.format("M/YYYY")} - ${endDate.format("M/YYYY")}`;
};
