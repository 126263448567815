import React from "react";
import { SimpleGrid, GridItem } from "@chakra-ui/react";

import { useBreakpointValue } from "_react/shared/_helpers/chakra";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { getPositionGroup } from "_react/shared/_helpers/position_groups";
import Section from "_react/shared/ui/presentation/components/Section/Section";
import SimilarPlayersProModelTable from "_react/shared/ui/data/tables/SimilarPlayersProModelTable/SimilarPlayersProModelTable";
import ProModelStatCard from "_react/shared/ui/data/cards/ProModelStatCard/ProModelStatCard";
import { PHILLIES_TEAM_ID } from "_react/shared/_constants/ids";

import { SectionStyleObject } from "_react/playerpage/pro/shared/_styles";
import {
	SIMILAR_PRO_MODEL_COLUMNS_MOBILE,
	SIMILAR_PRO_MODEL_COLUMNS_SUBSET,
	SIMILAR_PRO_MODEL_COLUMNS
} from "_react/playerpage/pro/shared/_constants";
import { POSITION_GROUP_1B_DH } from "_react/shared/_constants/position_groups";

type TProModelSectionProps = {
	player: TPlayerPageCombinedPlayer;
};

const ProModelSection = ({ player }: TProModelSectionProps) => {
	const positionGroup = getPositionGroup(player);

	const similarPlayersTableColumns = useBreakpointValue({
		base: SIMILAR_PRO_MODEL_COLUMNS_MOBILE,
		xl: SIMILAR_PRO_MODEL_COLUMNS_SUBSET,
		"2xl": SIMILAR_PRO_MODEL_COLUMNS
	});

	// Only need margin top when there is a table with a title next to the StatCard
	const statCardStyling = { marginTop: { "2xl": 10 } };

	return (
		<>
			{player.proProfile?.mlsCurrent != null && player.proProfile.mlsCurrent <= 1 && (
				<Section label="Pro Model" placement="start" style={SectionStyleObject}>
					<SimpleGrid
						columns={3}
						minChildWidth={{
							md: "sm"
						}}
						spacing={6}
					>
						<GridItem>
							<ProModelStatCard playerId={player.id} style={statCardStyling} />
						</GridItem>
						<GridItem colSpan={2}>
							<SimilarPlayersProModelTable
								title={`Similar Players (${
									positionGroup === POSITION_GROUP_1B_DH ? "1B/DH" : positionGroup
								})`}
								filters={{
									playerId: player.id,
									positionGroup: positionGroup
								}}
								toggleOptions={{
									isLeague: true,
									isSameOrg: true,
									isPhillies: player?.team?.parentTeam?.id !== PHILLIES_TEAM_ID
								}}
								columns={similarPlayersTableColumns}
							/>
						</GridItem>
					</SimpleGrid>
				</Section>
			)}
		</>
	);
};

export default ProModelSection;
