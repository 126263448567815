import React, { FC } from "react";
import { SystemStyleObject } from "@chakra-ui/react";
import { Style } from "utils/tsutils";

export type IconProps = {
	className?: string;
	fill?: string;
	style?: Style;
	onClick?: React.MouseEventHandler<SVGSVGElement>;
	onMouseEnter?(event: React.MouseEvent<SVGSVGElement, MouseEvent>): void;
	onMouseLeave?(event: React.MouseEvent<SVGSVGElement, MouseEvent>): void;
	children?: React.ReactNode;
};

// https://chakra-ui.com/docs/components/icon/props
export type ChakraIconProps = {
	viewBox?: string;
	boxSize?: string | number;
	color?: string;
	focusable?: boolean;
	role?: string;
	sx?: SystemStyleObject;
	children?: React.ReactNode;
};

export const FILL = "#5f6368";

export const IconSVG: FC<IconProps> = ({ children, className, style = {}, onClick, onMouseEnter, onMouseLeave }) => {
	return (
		<svg
			aria-hidden="true"
			className={className}
			focusable="false"
			height={style.height ? style.height : "24px"}
			id={className}
			onClick={e => (onClick ? onClick(e) : null)}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			role="presentation"
			style={{
				color: style.color ? style.color : !className ? "white" : "",
				fill: style.color ? style.color : !className ? "white" : "",
				cursor: onClick ? "pointer" : "",
				...style
			}}
			viewBox="0 0 24 24"
			width={style.width ? style.width : "24px"}
		>
			{children}
		</svg>
	);
};
