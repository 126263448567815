import { createMachine, assign, AnyEventObject, StateFrom } from "xstate";
import { CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { fetchIntlProspectValue } from "_react/shared/data_models/phred/_network";
import { IIntlProspectValue } from "_react/shared/data_models/phred/_types";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";

const INTL_PROSPECT_VALUES_CANCEL_SOURCE = "intlProspectValues";

export type TPlayerPageIntlCancelSource = {
	[INTL_PROSPECT_VALUES_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TPlayerPageIntlContext = {
	playerId?: number;
	lastPlayerId?: number; // Not currently used
	projectionsSeasonData?: [number, number, boolean];
	currentSeasonData?: { currentSeason: number | undefined; isFetching: boolean | undefined };
	shouldFetchData?: boolean;
	intlProspectValues?: Array<IIntlProspectValue>;
	cancelSources: TPlayerPageIntlCancelSource;
	toast?: CreateToastFnReturn;
};

export const FETCHING_INTL_PROSPECT_VALUES = { initialized: { intlProspectValues: "fetching" } };

const FETCH_INTL_PROSPECT_VALUES_DONE = "done.invoke.fetchingIntlProspectValues:invocation[0]";
type TFetchIntlProspectValuesEvent = { type: typeof FETCH_INTL_PROSPECT_VALUES_DONE; data?: Array<IIntlProspectValue> };

export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PROJECTIONS_SEASON_DATA = "SET_PROJECTIONS_SEASON_DATA";
export const SET_CURRENT_SEASON_DATA = "SET_CURRENT_SEASON_DATA";
type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value?: number };
type TSetProjectionsSeasonDataEvent = { type: typeof SET_PROJECTIONS_SEASON_DATA; value?: [number, number, boolean] };
type TSetCurrentSeasonData = {
	type: typeof SET_CURRENT_SEASON_DATA;
	value?: { currentSeason: number | undefined; isFetching: boolean | undefined };
};

export type TPlayerPageIntlEvent =
	| TFetchIntlProspectValuesEvent
	| TSetPlayerIdEvent
	| TSetProjectionsSeasonDataEvent
	| TSetCurrentSeasonData;

const PlayerPageIntlMachine = (
	playerIdProp?: number,
	projectionsSeasonDataProp?: [number, number, boolean],
	currentSeasonDataProp?: { currentSeason: number | undefined; isFetching: boolean | undefined },
	shouldFetchData = true,
	toastProp?: CreateToastFnReturn
) =>
	createMachine<TPlayerPageIntlContext, TPlayerPageIntlEvent>(
		{
			id: "playerPageIntl",
			predictableActionArguments: true,
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				projectionsSeasonData: projectionsSeasonDataProp,
				currentSeasonData: currentSeasonDataProp,
				shouldFetchData: shouldFetchData,
				intlProspectValues: undefined,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					initial: "idle",
					states: {
						idle: {
							always: "#playerPageIntl.initialized"
						}
					}
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PLAYER_ID]: {
							actions: ["setPlayerId", "clearIntlProspectValues"],
							cond: "shouldSetPlayerId"
						},
						[SET_PROJECTIONS_SEASON_DATA]: { actions: "setProjectionsSeasonData" },
						[SET_CURRENT_SEASON_DATA]: { actions: "setCurrentSeasonData" }
					},
					states: {
						intlProspectValues: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingIntlProspectValues",
												cond: "shouldFetchIntlProspectValues"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingIntlProspectValues",
									entry: ["refreshIntlProspectValuesCancelSource"],
									invoke: {
										src: "fetchIntlProspectValues",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchIntlProspectValuesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchIntlProspectValuesErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (context: TPlayerPageIntlContext, event: TPlayerPageIntlEvent) =>
					event.type === SET_PLAYER_ID && context.playerId !== event.value,
				shouldFetchIntlProspectValues: (context: TPlayerPageIntlContext, _event: TPlayerPageIntlEvent) =>
					context.intlProspectValues === undefined && shouldFetchData && context.playerId !== undefined
			},
			actions: {
				clearIntlProspectValues: assign<TPlayerPageIntlContext, TPlayerPageIntlEvent>({
					intlProspectValues: (_context: TPlayerPageIntlContext, _event: TPlayerPageIntlEvent) => undefined,
					cancelSources: (context: TPlayerPageIntlContext, _event: TPlayerPageIntlEvent) => {
						const { cancelSources } = context;
						cancelSources[INTL_PROSPECT_VALUES_CANCEL_SOURCE]?.cancel();
						cancelSources[INTL_PROSPECT_VALUES_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setPlayerId: assign<TPlayerPageIntlContext, TPlayerPageIntlEvent>({
					playerId: (context: TPlayerPageIntlContext, event: TPlayerPageIntlEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					}
				}),
				setProjectionsSeasonData: assign<TPlayerPageIntlContext, TPlayerPageIntlEvent>({
					projectionsSeasonData: (context: TPlayerPageIntlContext, event: TPlayerPageIntlEvent) => {
						if (event.type !== SET_PROJECTIONS_SEASON_DATA) return context.projectionsSeasonData;
						return event.value;
					}
				}),
				setCurrentSeasonData: assign<TPlayerPageIntlContext, TPlayerPageIntlEvent>({
					currentSeasonData: (context: TPlayerPageIntlContext, event: TPlayerPageIntlEvent) => {
						if (event.type !== SET_CURRENT_SEASON_DATA) return context.currentSeasonData;
						return event.value;
					}
				}),
				// Cancel Source Actions
				refreshIntlProspectValuesCancelSource: assign<TPlayerPageIntlContext, TPlayerPageIntlEvent>({
					cancelSources: (context: TPlayerPageIntlContext, _event: TPlayerPageIntlEvent) => {
						if (context.cancelSources[INTL_PROSPECT_VALUES_CANCEL_SOURCE] != null)
							context.cancelSources[INTL_PROSPECT_VALUES_CANCEL_SOURCE].cancel();
						context.cancelSources[INTL_PROSPECT_VALUES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchIntlProspectValuesSuccess: assign<TPlayerPageIntlContext, TPlayerPageIntlEvent>({
					intlProspectValues: (context: TPlayerPageIntlContext, event: TPlayerPageIntlEvent) => {
						if (event.type !== FETCH_INTL_PROSPECT_VALUES_DONE) return context.intlProspectValues;
						return event.data;
					}
				}),
				// Fetch Errored Actions
				handleFetchIntlProspectValuesErrored: (
					context: TPlayerPageIntlContext,
					_event: TPlayerPageIntlEvent
				) => {
					if (context.toast)
						context.toast({
							...DEFAULT_TOAST_ERROR_PROPS,
							title: "Surplus Value",
							description: "Error fetching surplus value data."
						});
				}
			},
			services: {
				fetchIntlProspectValues: (context: TPlayerPageIntlContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchIntlProspectValue(
							{
								playerId: context.playerId,
								isUseCache: true
							},
							context.cancelSources[INTL_PROSPECT_VALUES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export type TPlayerPageIntlState = StateFrom<typeof PlayerPageIntlMachine>;

export default PlayerPageIntlMachine;
