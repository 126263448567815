import React, { useState, useEffect } from "react";
import { Box, SystemStyleObject, Td } from "@chakra-ui/react";

import {
	getRowValue,
	getColorGradientStyle,
	getSortedData
} from "_react/shared/ui/presentation/components/Table/_helpers";
import { TColumn, TSortColumn, ITableT } from "_react/shared/ui/presentation/components/Table/_types";
import {
	DefaultTdStyle,
	DefaultTrEvenStyle,
	DefaultTrOddStyle,
	DefaultTrHighlightedStyle,
	DefaultTr
} from "_react/shared/ui/presentation/components/Table/_styles";
import { VALUE_TYPE_NUMBER } from "_react/shared/ui/presentation/components/Table/_constants";

type TTableRowProps<T, K extends keyof T> = {
	index: number;
	data: T;
	columns?: Array<TColumn<T, K>>;
	sortColumns?: Array<TSortColumn<K>>;
	highlightIndex?: number;
	onRowClickFunction?: (obj: T, index: number) => void;
	isExpandableRows?: boolean;
	isExpanded: boolean;
	setIsExpanded: (setIsExpanded: (prevState: Array<string>) => Array<string>) => void;
	getCustomRowKeyFunction?: (obj: T) => string;
	style?: {
		row?: SystemStyleObject;
	};
};

const TableRow = <T extends ITableT<T>, K extends keyof T>({
	index,
	data,
	columns = [],
	sortColumns = [],
	highlightIndex,
	onRowClickFunction,
	isExpandableRows = false,
	isExpanded = false,
	setIsExpanded,
	getCustomRowKeyFunction,
	style = {}
}: TTableRowProps<T, K>): JSX.Element => {
	const [sortedChildData, setSortedChildData] = useState<Array<T>>(data?.childData ?? []);

	// Sort the child data to match the parent data
	useEffect(() => {
		const newSortedData = getSortedData(data?.childData, columns, sortColumns);
		setSortedChildData(newSortedData);
	}, [data, columns, sortColumns]);

	return (
		<>
			<DefaultTr
				onClick={() => (onRowClickFunction ? onRowClickFunction(data, index) : null)}
				sx={{
					...(index === highlightIndex
						? DefaultTrHighlightedStyle
						: index % 2 === 0
						? DefaultTrEvenStyle
						: DefaultTrOddStyle),
					cursor: onRowClickFunction ? "pointer" : "inherit",
					...style.row
				}}
			>
				{columns.map((column: TColumn<T, K>, columnIndex: number) => {
					const colorGradientStyle = getColorGradientStyle(data, column);
					const cursorStyle = column.onClickFunction ? { cursor: "pointer" } : {};
					const isExpandable =
						isExpandableRows && data?.childData && data.childData?.length > 0 && columnIndex === 0;
					const columnStyle = column.getColumnStyleFunction
						? column.getColumnStyleFunction(data)
						: column.style;
					return (
						<Td
							key={`td-${index}-${String(column.value)}`}
							sx={{
								...DefaultTdStyle(column.valueType),
								...cursorStyle,
								...columnStyle?.td,
								...colorGradientStyle
							}}
							onClick={() => {
								column.onClickFunction ? column.onClickFunction(data, index) : undefined;
								if (isExpandable) {
									const key = getCustomRowKeyFunction ? getCustomRowKeyFunction(data) : `${index}`;
									setIsExpanded(prev => {
										const rowIndex = prev.indexOf(key);
										if (rowIndex > -1) {
											const temp = Array.from(prev);
											temp.splice(rowIndex, 1);
											return temp;
										} else return [...prev, key];
									});
								}
							}}
						>
							<>
								{isExpandableRows && columnIndex === 0 && (
									<Box
										as="span"
										float="left"
										marginRight={1}
										opacity={isExpandable ? undefined : 0}
										cursor={isExpandable ? "pointer" : undefined}
										color={isExpanded ? "gray.300" : "gray.500"}
									>
										{isExpanded ? String.fromCharCode(9660) : String.fromCharCode(9654)}
									</Box>
								)}
								{getRowValue(data, column)}
							</>
						</Td>
					);
				})}
			</DefaultTr>
			{isExpanded &&
				sortedChildData.map((data: T, childIndex: number) => (
					<DefaultTr
						key={`tr-${index}-${childIndex}`}
						borderBottom={childIndex === sortedChildData.length - 1 ? "1px solid" : undefined}
						borderBottomColor={childIndex === sortedChildData.length - 1 ? "gray.300" : undefined}
						sx={
							childIndex === highlightIndex
								? DefaultTrHighlightedStyle
								: childIndex % 2 !== index % 2
								? DefaultTrEvenStyle
								: DefaultTrOddStyle
						}
					>
						{columns.map((column: TColumn<T, K>) => {
							const colorGradientStyle = getColorGradientStyle(data, column);
							const columnStyle = column.getColumnStyleFunction
								? column.getColumnStyleFunction(data)
								: column.style;
							return (
								<Td
									key={`td-${index}-${childIndex}-${String(column.value)}`}
									sx={{
										...DefaultTdStyle(column.valueType),
										...columnStyle?.td,
										...colorGradientStyle
									}}
								>
									<Box marginLeft={column.valueType === VALUE_TYPE_NUMBER ? "0" : "7"}>
										{getRowValue(data, column)}
									</Box>
								</Td>
							);
						})}
					</DefaultTr>
				))}
		</>
	);
};

export default TableRow;
