import React from "react";
import { VStack } from "@chakra-ui/react";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { TPlayingLevel } from "_react/shared/data_models/hitter_grades/_types";
import Section from "_react/shared/ui/presentation/components/Section/Section";
import ArsenalLocationTiles, {
	TArsenalLocationTilesSecondaryData
} from "_react/shared/ui/data/other/ArsenalLocationTiles/ArsenalLocationTiles";
import { TOOLTIP_LOCATION } from "_react/shared/_constants/tooltips";

import { SectionStyleObject } from "_react/playerpage/pro/shared/_styles";

type TLocationsSectionProps = {
	player: TPlayerPageCombinedPlayer;
	seasonFilter: number;
	batsFilter: string;
	throwsFilter?: string;
	playingLevel: TPlayingLevel;
	useCountSplits: boolean;
	shouldFetchArsenalLocationTilesSecondaryData?: boolean;
	arsenalLocationTilesSecondaryData?: TArsenalLocationTilesSecondaryData;
};

const LocationsSection = ({
	player,
	seasonFilter,
	batsFilter,
	throwsFilter,
	playingLevel,
	useCountSplits,
	shouldFetchArsenalLocationTilesSecondaryData,
	arsenalLocationTilesSecondaryData
}: TLocationsSectionProps) => {
	return (
		<Section label="Locations" placement="start" tooltipLabel={TOOLTIP_LOCATION} style={SectionStyleObject}>
			<VStack align="start">
				<ArsenalLocationTiles
					playerId={player.id}
					playingLevel={playingLevel}
					useCountSplits={useCountSplits}
					batsFilter={batsFilter}
					throwsFilter={throwsFilter}
					seasonFilter={seasonFilter}
					shouldFetchSecondaryData={shouldFetchArsenalLocationTilesSecondaryData}
					secondaryData={arsenalLocationTilesSecondaryData}
				/>
			</VStack>
		</Section>
	);
};

export default LocationsSection;
