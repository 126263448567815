import { chakra, Text } from "@chakra-ui/react";

import { TSectionStyle } from "_react/shared/ui/presentation/components/Section/_types";

// Style Objects

export const SectionStyleObject = {
	container: { overflowX: "scroll" }
} as TSectionStyle;

// Chakra Styling

export const KeepText = chakra(Text, {
	baseStyle: {
		color: "success",
		fontWeight: "bold"
	}
});

export const DeleteText = chakra(Text, {
	baseStyle: {
		color: "error",
		fontWeight: "bold"
	}
});

// Style Constants

export const FILLED_CIRCLE_COLOR = "maroon";
export const EMPTY_CIRCLE_COLOR = "gray.200";
