import React from "react";

import { useChakraColorSchemeGroup } from "_react/shared/theme/_helpers";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import Video from "_react/playerpage/video/Video";
import { INTL } from "_react/playerpage/_constants";

type TVideoTabProps = {
	player: TPlayerPageCombinedPlayer;
};

const VideoTab = ({ player }: TVideoTabProps) => {
	const chakraColorSchemeGroup = useChakraColorSchemeGroup();
	// Override to not mess with existing colors
	chakraColorSchemeGroup.primary = chakraColorSchemeGroup.secondary;

	return <Video player={player} colorScheme={chakraColorSchemeGroup} viewClassification={INTL} />;
};

export default VideoTab;
