import React, { useContext } from "react";
import { useSelector } from "@xstate/react";
import { SimpleGrid, GridItem } from "@chakra-ui/react";

import { MODEL_NAME_V2, PLAYER_TYPE_TO_POSITION_GROUP } from "_react/shared/data_models/phred/_constants";
import {
	POSITION_GROUPS_PITCHER,
	POSITION_GROUP_POSITION_PLAYER,
	POSITION_GROUP_PITCHER
} from "_react/shared/_constants/position_groups";
import { getPositionGroup } from "_react/shared/_helpers/position_groups";
import Section from "_react/shared/ui/presentation/components/Section/Section";
import { TAmaProspectValueSimilarPlayersPlayerType } from "_react/shared/data_models/phred/_network";
import { IAmaProspectValue } from "_react/shared/data_models/phred/_types";
import AmaProspectValuesStatCard from "_react/shared/ui/data/cards/AmaProspectValuesStatCard/AmaProspectValuesStatCard";

import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { TPlayerPageAmaState, FETCHING_AMA_PROSPECT_VALUES } from "_react/playerpage/ama/_machine";
import { PlayerPageAmaContext } from "_react/playerpage/ama/PlayerPageAmaProvider";
import { SectionStyleObject } from "_react/playerpage/ama/shared/_styles";
import AmaSurplusValueSimilarPlayersTable from "_react/shared/ui/data/tables/AmaSurplusValueSimilarPlayersTable/AmaSurplusValueSimilarPlayersTable";
import { TPositionGroup } from "_react/shared/_types/position_groups";

type TAmaProspectValueSectionProps = {
	player: TPlayerPageCombinedPlayer;
	playerType: string;
	year: number;
};

const AmaProspectValueSection = ({ player, playerType, year }: TAmaProspectValueSectionProps) => {
	const playerPageAmaContext = useContext(PlayerPageAmaContext);

	const amaProspectValues: Array<IAmaProspectValue> | null | undefined = useSelector(
		playerPageAmaContext.playerPageAmaService,
		(state: TPlayerPageAmaState) => state.context.amaProspectValues
	);
	const isFetchingAmaProspectValues: boolean = useSelector(
		playerPageAmaContext.playerPageAmaService,
		(state: TPlayerPageAmaState) => state.matches(FETCHING_AMA_PROSPECT_VALUES)
	);

	const currentDraftYear: number | undefined = useSelector(
		playerPageAmaContext.playerPageAmaService,
		(state: TPlayerPageAmaState) => state.context.currentDraftYear
	);

	// Only need margin top when there is a table with a title next to the StatCard
	const statCardStyling = { marginTop: { "2xl": 10 } };

	const positionGroup = getPositionGroup(player);
	const positionGroupToFilterBy = PLAYER_TYPE_TO_POSITION_GROUP[
		playerType as TAmaProspectValueSimilarPlayersPlayerType
	].includes(positionGroup)
		? positionGroup
		: undefined;
	const positionSelect = positionGroupToFilterBy
		? undefined
		: {
				showSelect: Boolean(positionGroup),
				positionGroup: POSITION_GROUPS_PITCHER.includes(positionGroup)
					? POSITION_GROUP_POSITION_PLAYER
					: (POSITION_GROUP_PITCHER as TPositionGroup)
		  };

	return (
		<Section label="Prospect Value" style={SectionStyleObject}>
			<SimpleGrid
				columns={3}
				minChildWidth={{
					md: "sm"
				}}
				spacing={6}
			>
				<GridItem>
					<AmaProspectValuesStatCard
						playerId={player.id}
						playerType={playerType}
						teamLevel={player?.amaProfile?.team?.level ?? player?.team?.level}
						year={year}
						data={{ amaProspectValues: amaProspectValues, isLoading: isFetchingAmaProspectValues }}
						shouldFetchData={false}
						style={statCardStyling}
					/>
				</GridItem>
				<GridItem colSpan={2}>
					<AmaSurplusValueSimilarPlayersTable
						title={`Similar Players`}
						playerId={player.id}
						playerType={playerType as TAmaProspectValueSimilarPlayersPlayerType}
						modelName={MODEL_NAME_V2}
						r4YearPlayer={year}
						positionGroup={positionGroupToFilterBy}
						currentDraftYear={currentDraftYear}
						positionSelect={positionSelect}
					/>
				</GridItem>
			</SimpleGrid>
		</Section>
	);
};

export default AmaProspectValueSection;
