import { CancelToken } from "axios";

import { fetchResource } from "_react/shared/_helpers/axios";
import {
	IRAAPitcher,
	IRAAPitcherByTeam,
	IRAAPositionPlayer,
	IRAAPositionPlayerByTeam
} from "_react/shared/data_models/raa/_types";

// RAA Pitcher

export type TRAAPitcherGetQueryParams = {
	playerId?: number;
	season?: number;
	playingLevel?: string;
	gameType?: string;
	role?: string;

	// Lots more params available, add as needed

	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const fetchPlayerSeasonRAAPitcher = (queryParams: TRAAPitcherGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TRAAPitcherGetQueryParams, Array<IRAAPitcher>>(
		"/raa_pitcher/player_season",
		queryParams,
		cancelToken
	);

export type TRAAPitcherByTeamGetQueryParams = TRAAPitcherGetQueryParams & {
	teamId?: number;
};

export const fetchPlayerSeasonRAAPitcherByTeam = (
	queryParams: TRAAPitcherByTeamGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TRAAPitcherByTeamGetQueryParams, Array<IRAAPitcherByTeam>>(
		"/raa_pitcher_byteam/player_season",
		queryParams,
		cancelToken
	);

export type TRAARatePitcherGetQueryParams = TRAAPitcherGetQueryParams;

export const fetchPlayerSeasonRAARatePitcher = (queryParams: TRAAPitcherGetQueryParams, cancelToken?: CancelToken) =>
	fetchResource<TRAAPitcherGetQueryParams, Array<IRAAPitcher>>(
		"/raa_rate_pitcher/player_season",
		queryParams,
		cancelToken
	);

export type TRAARatePitcherByTeamGetQueryParams = TRAAPitcherByTeamGetQueryParams;

export const fetchPlayerSeasonRAARatePitcherByTeam = (
	queryParams: TRAAPitcherByTeamGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TRAAPitcherByTeamGetQueryParams, Array<IRAAPitcherByTeam>>(
		"/raa_rate_pitcher_byteam/player_season",
		queryParams,
		cancelToken
	);

// RAA Position Player

export type TRAAPositionPlayerGetQueryParams = {
	playerId?: number;
	season?: number;
	playingLevel?: string;
	gameType?: string;

	// Lots more params available, add as needed

	limit?: number;
	offset?: number;
	sort?: string;
	isSortDescending?: boolean;
	isUseCache?: boolean;
};

export const fetchPlayerSeasonRAAPositionPlayer = (
	queryParams: TRAAPositionPlayerGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TRAAPositionPlayerGetQueryParams, Array<IRAAPositionPlayer>>(
		"/raa_position_player/player_season",
		queryParams,
		cancelToken
	);

export type TRAAPositionPlayerByTeamGetQueryParams = TRAAPositionPlayerGetQueryParams & {
	teamId?: number;
};

export const fetchPlayerSeasonRAAPositionPlayerByTeam = (
	queryParams: TRAAPositionPlayerByTeamGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TRAAPositionPlayerByTeamGetQueryParams, Array<IRAAPositionPlayerByTeam>>(
		"/raa_position_player_byteam/player_season",
		queryParams,
		cancelToken
	);

export type TRAARatePositionPlayerGetQueryParams = TRAAPositionPlayerGetQueryParams;

export const fetchPlayerSeasonRAARatePositionPlayer = (
	queryParams: TRAAPositionPlayerGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TRAAPositionPlayerGetQueryParams, Array<IRAAPositionPlayer>>(
		"/raa_rate_position_player/player_season",
		queryParams,
		cancelToken
	);

export type TRAARatePositionPlayerByTeamGetQueryParams = TRAAPositionPlayerByTeamGetQueryParams;

export const fetchPlayerSeasonRAARatePositionPlayerByTeam = (
	queryParams: TRAAPositionPlayerByTeamGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TRAAPositionPlayerByTeamGetQueryParams, Array<IRAAPositionPlayerByTeam>>(
		"/raa_rate_position_player_byteam/player_season",
		queryParams,
		cancelToken
	);
