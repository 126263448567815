import React from "react";
import { Icon } from "@chakra-ui/react";
import { ChakraIconProps } from "_react/shared/legacy/ui/icons";

export default function ExternalLinkIcon(props: ChakraIconProps) {
	return (
		<Icon
			viewBox="0 0 48.5546875 44.236328125"
			{...props}
			sx={{ strokeWidth: props.sx?.strokeWidth ?? 4, stroke: props.sx?.stroke ?? props.sx?.color ?? "black" }}
		>
			<path
				fill="currentColor"
				transform="scale(1,-1) translate(0,-44.236328125)"
				d="	 M 11.0,3.330078125     L 37.5546875,3.330078125     C 41.142578125,3.330078125 43.076171875,5.28515625 43.076171875,8.8515625     L 43.076171875,35.384765625     C 43.076171875,38.951171875 41.142578125,40.90625 37.5546875,40.90625     L 11.0,40.90625     C 7.390625,40.90625 5.478515625,38.994140625 5.478515625,35.384765625     L 5.478515625,8.8515625     C 5.478515625,5.2421875 7.390625,3.330078125 11.0,3.330078125     Z     M 11.04296875,4.3828125     C 8.078125,4.3828125 6.53125,5.9296875 6.53125,8.89453125     L 6.53125,35.341796875     C 6.53125,38.328125 8.078125,39.853515625 11.04296875,39.853515625     L 37.490234375,39.853515625     C 40.369140625,39.853515625 42.0234375,38.328125 42.0234375,35.341796875     L 42.0234375,8.89453125     C 42.0234375,5.9296875 40.369140625,4.3828125 37.490234375,4.3828125     Z     M 31.775390625,17.208984375     C 32.0546875,17.208984375 32.26953125,17.423828125 32.26953125,17.767578125     L 32.26953125,29.6484375     C 32.26953125,29.970703125 32.0546875,30.185546875 31.775390625,30.185546875     L 19.873046875,30.185546875     C 19.529296875,30.185546875 19.314453125,29.9921875 19.314453125,29.712890625     C 19.314453125,29.390625 19.529296875,29.17578125 19.873046875,29.17578125     L 30.55078125,29.17578125     L 28.509765625,27.177734375     L 16.478515625,15.146484375     C 16.37109375,15.0390625 16.306640625,14.91015625 16.306640625,14.73828125     C 16.306640625,14.416015625 16.5,14.201171875 16.822265625,14.201171875     C 17.037109375,14.201171875 17.166015625,14.287109375 17.2734375,14.39453125     L 29.283203125,26.3828125     L 31.23828125,28.423828125     L 31.23828125,17.767578125     C 31.23828125,17.423828125 31.453125,17.208984375 31.775390625,17.208984375     Z "
			/>
		</Icon>
	);
}
