import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import {
	PLAYING_LEVEL_PRO,
	MODEL_EV_LA,
	THROWS_OVERALL,
	GAME_TYPE_REGULAR_SEASON,
	GAME_TYPE_POSTSEASON,
	BATS_R,
	BATS_L
} from "_react/shared/data_models/baseline_hit_probs/_constants";
import { IPlayerSeasonXwoba, IPlayerSeasonXwobaByTeam } from "_react/shared/data_models/baseline_hit_probs/_types";
import {
	fetchPlayerSeasonXwoba,
	fetchPlayerSeasonXwobaByTeam
} from "_react/shared/data_models/baseline_hit_probs/_network";
import {
	IPitchOutcomeProbabilitiesPa,
	IPitchOutcomeProbabilitiesPaByTeam
} from "_react/shared/data_models/pitch_outcome_probabilities/_types";
import {
	fetchPitchOutcomeProbabilitiesPa,
	fetchPitchOutcomeProbabilitiesPaByTeam
} from "_react/shared/data_models/pitch_outcome_probabilities/_network";
import { SOURCE_GUMBO, SOURCE_STATSAPI, VALID_GAME_TYPES } from "_react/shared/data_models/stats/_constants";
import { IStatsPlayerPitching, IStatsPlayerPitchingByTeam } from "_react/shared/data_models/stats/_types";
import { fetchStatsPlayerPitching, fetchStatsPlayerPitchingByTeam } from "_react/shared/data_models/stats/_network";

import { TPitcherPaOutcomesTableData } from "_react/shared/ui/data/tables/PitcherPaOutcomesTable/_types";

export const PLAYER_SEASON_XWOBA_CANCEL_SOURCE = "playerSeasonXwoba";
export const PLAYER_SEASON_XWOBA_BYTEAM_CANCEL_SOURCE = "playerSeasonXwobaByTeam";
export const STATS_PLAYER_PITCHING_CANCEL_SOURCE = "statsPlayerPitching";
export const STATS_PLAYER_PITCHING_BYTEAM_CANCEL_SOURCE = "statsPlayerPitchingByTeam";
export const PLAYER_SEASON_POP_PA_CANCEL_SOURCE = "playerSeasonPopPa";
export const PLAYER_SEASON_POP_PA_BYTEAM_CANCEL_SOURCE = "playerSeasonPopPaByTeam";

export type TPitcherPaOutcomesTableCancelSource = {
	[PLAYER_SEASON_XWOBA_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_XWOBA_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
	[STATS_PLAYER_PITCHING_CANCEL_SOURCE]?: CancelTokenSource;
	[STATS_PLAYER_PITCHING_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_POP_PA_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_POP_PA_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TPitcherPaOutcomesTableFilters = {
	gameTypes: Array<string>;
	bats: Array<string>;
	levels?: Array<string>;
	maxSeason?: number;
	minSeason?: number;
};

export type TPitcherPaOutcomesTableContext = {
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	filters: TPitcherPaOutcomesTableFilters;
	playerSeasonXwoba?: Array<IPlayerSeasonXwoba> | null;
	playerSeasonXwobaByTeam?: Array<IPlayerSeasonXwobaByTeam> | null;
	statsPlayerPitching?: Array<IStatsPlayerPitching> | null;
	statsPlayerPitchingByTeam?: Array<IStatsPlayerPitchingByTeam> | null;
	playerSeasonPopPa?: Array<IPitchOutcomeProbabilitiesPa> | null;
	playerSeasonPopPaByTeam?: Array<IPitchOutcomeProbabilitiesPaByTeam> | null;
	cancelSources: TPitcherPaOutcomesTableCancelSource;
	toast?: CreateToastFnReturn;
};

interface IPitcherPaOutcomesTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId prop changes
				contextRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches all arsenal scores by player season
				playerSeasonXwoba: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all arsenal scores by player season team
				playerSeasonXwobaByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all stats player pitching
				statsPlayerPitching: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all stats player pitching by team
				statsPlayerPitchingByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all PoP PA by player season
				playerSeasonPopPa: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all PoP PA by player season team
				playerSeasonPopPaByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_PLAYER_SEASON_XWOBA = { initialized: { playerSeasonXwoba: "fetching" } };
export const FETCHING_PLAYER_SEASON_XWOBA_BYTEAM = {
	initialized: { playerSeasonXwobaByTeam: "fetching" }
};
export const FETCHING_STATS_PLAYER_PITCHING = { initialized: { statsPlayerPitching: "fetching" } };
export const FETCHING_STATS_PLAYER_PITCHING_BYTEAM = {
	initialized: { statsPlayerPitchingByTeam: "fetching" }
};
export const FETCHING_PLAYER_SEASON_POP_PA = { initialized: { playerSeasonPopPa: "fetching" } };
export const FETCHING_PLAYER_SEASON_POP_PA_BYTEAM = {
	initialized: { playerSeasonPopPaByTeam: "fetching" }
};

const FETCH_PLAYER_SEASON_XWOBA_DONE = "done.invoke.fetchPlayerSeasonXwoba:invocation[0]";
const FETCH_PLAYER_SEASON_XWOBA_BYTEAM_DONE = "done.invoke.fetchPlayerSeasonXwobaByTeam:invocation[0]";
const FETCH_STATS_PLAYER_PITCHING_DONE = "done.invoke.fetchStatsPlayerPitching:invocation[0]";
const FETCH_STATS_PLAYER_PITCHING_BYTEAM_DONE = "done.invoke.fetchStatsPlayerPitchingByTeam:invocation[0]";
const FETCH_PLAYER_SEASON_POP_PA_DONE = "done.invoke.fetchPlayerSeasonPopPa:invocation[0]";
const FETCH_PLAYER_SEASON_POP_PA_BYTEAM_DONE = "done.invoke.fetchPlayerSeasonPopPaByTeam:invocation[0]";

const FETCH_PLAYER_SEASON_XWOBA_ERROR = "error.platform.fetchPlayerSeasonXwoba:invocation[0]";
const FETCH_PLAYER_SEASON_XWOBA_BYTEAM_ERROR = "error.platform.fetchPlayerSeasonXwobaByTeam:invocation[0]";
const FETCH_STATS_PLAYER_PITCHING_ERROR = "error.platform.fetchStatsPlayerPitching:invocation[0]";
const FETCH_STATS_PLAYER_PITCHING_BYTEAM_ERROR = "error.platform.fetchStatsPlayerPitchingByTeam:invocation[0]";
const FETCH_PLAYER_SEASON_POP_PA_ERROR = "error.platform.fetchPlayerSeasonPopPa:invocation[0]";
const FETCH_PLAYER_SEASON_POP_PA_BYTEAM_ERROR = "error.platform.fetchPlayerSeasonPopPaByTeam:invocation[0]";

export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PLAYER_SEASON_XWOBA = "SET_PLAYER_SEASON_XWOBA";
export const SET_PLAYER_SEASON_XWOBA_BYTEAM = "SET_PLAYER_SEASON_XWOBA_BYTEAM";
export const SET_STATS_PLAYER_PITCHING = "SET_STATS_PLAYER_PITCHING";
export const SET_STATS_PLAYER_PITCHING_BYTEAM = "SET_STATS_PLAYER_PITCHING_BYTEAM";
export const SET_PLAYER_SEASON_POP_PA = "SET_PLAYER_SEASON_POP_PA";
export const SET_PLAYER_SEASON_POP_PA_BYTEAM = "SET_PLAYER_SEASON_POP_PA_BYTEAM";
export const SET_FILTERS = "SET_FILTERS";

type TFetchPlayerSeasonXwobaEvent = {
	type: typeof FETCH_PLAYER_SEASON_XWOBA_DONE;
	data: Array<IPlayerSeasonXwoba> | undefined;
};
type TFetchPlayerSeasonXwobaByTeamEvent = {
	type: typeof FETCH_PLAYER_SEASON_XWOBA_BYTEAM_DONE;
	data: Array<IPlayerSeasonXwobaByTeam> | undefined;
};
type TFetchStatsPlayerPitchingEvent = {
	type: typeof FETCH_STATS_PLAYER_PITCHING_DONE;
	data: Array<IStatsPlayerPitching> | undefined;
};
type TFetchStatsPlayerPitchingByTeamEvent = {
	type: typeof FETCH_STATS_PLAYER_PITCHING_BYTEAM_DONE;
	data: Array<IStatsPlayerPitchingByTeam> | undefined;
};
type TFetchPlayerSeasonPopPaEvent = {
	type: typeof FETCH_PLAYER_SEASON_POP_PA_DONE;
	data: Array<IPitchOutcomeProbabilitiesPa> | undefined;
};
type TFetchPlayerSeasonPopPaByTeamEvent = {
	type: typeof FETCH_PLAYER_SEASON_POP_PA_BYTEAM_DONE;
	data: Array<IPitchOutcomeProbabilitiesPaByTeam> | undefined;
};

type TFetchPlayerSeasonXwobaErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_XWOBA_ERROR;
	data?: AxiosError | Error;
};
type TFetchPlayerSeasonXwobaByTeamErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_XWOBA_BYTEAM_ERROR;
	data?: AxiosError | Error;
};
type TFetchStatsPlayerPitchingErrorEvent = {
	type: typeof FETCH_STATS_PLAYER_PITCHING_ERROR;
	data?: AxiosError | Error;
};
type TFetchStatsPlayerPitchingByTeamErrorEvent = {
	type: typeof FETCH_STATS_PLAYER_PITCHING_BYTEAM_ERROR;
	data?: AxiosError | Error;
};
type TFetchPlayerSeasonPopPaErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_POP_PA_ERROR;
	data?: AxiosError | Error;
};
type TFetchPlayerSeasonPopPaByTeamErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_POP_PA_BYTEAM_ERROR;
	data?: AxiosError | Error;
};

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value: number | undefined };
type TSetPlayerSeasonXwobaEvent = {
	type: typeof SET_PLAYER_SEASON_XWOBA;
	value?: Array<IPlayerSeasonXwoba> | null;
};
type TSetPlayerSeasonXwobaByTeamEvent = {
	type: typeof SET_PLAYER_SEASON_XWOBA_BYTEAM;
	value?: Array<IPlayerSeasonXwobaByTeam> | null;
};
type TSetStatsPlayerPitchingEvent = {
	type: typeof SET_STATS_PLAYER_PITCHING;
	value?: Array<IStatsPlayerPitching> | null;
};
type TSetStatsPlayerPitchingByTeamEvent = {
	type: typeof SET_STATS_PLAYER_PITCHING_BYTEAM;
	value?: Array<IStatsPlayerPitchingByTeam> | null;
};
type TSetPlayerSeasonPopPaEvent = {
	type: typeof SET_PLAYER_SEASON_POP_PA;
	value?: Array<IPitchOutcomeProbabilitiesPa> | null;
};
type TSetPlayerSeasonPopPaByTeamEvent = {
	type: typeof SET_PLAYER_SEASON_POP_PA_BYTEAM;
	value?: Array<IPitchOutcomeProbabilitiesPaByTeam> | null;
};
type TSetFiltersEvent = {
	type: typeof SET_FILTERS;
	value: TPitcherPaOutcomesTableFilters;
};

type TPitcherPaOutcomesTableEvent =
	| TFetchPlayerSeasonXwobaEvent
	| TFetchPlayerSeasonXwobaByTeamEvent
	| TFetchStatsPlayerPitchingEvent
	| TFetchStatsPlayerPitchingByTeamEvent
	| TFetchPlayerSeasonPopPaEvent
	| TFetchPlayerSeasonPopPaByTeamEvent
	| TFetchPlayerSeasonXwobaErrorEvent
	| TFetchPlayerSeasonXwobaByTeamErrorEvent
	| TFetchStatsPlayerPitchingErrorEvent
	| TFetchStatsPlayerPitchingByTeamErrorEvent
	| TFetchPlayerSeasonPopPaErrorEvent
	| TFetchPlayerSeasonPopPaByTeamErrorEvent
	| TSetPlayerIdEvent
	| TSetPlayerSeasonXwobaEvent
	| TSetPlayerSeasonXwobaByTeamEvent
	| TSetStatsPlayerPitchingEvent
	| TSetStatsPlayerPitchingByTeamEvent
	| TSetPlayerSeasonPopPaEvent
	| TSetPlayerSeasonPopPaByTeamEvent
	| TSetFiltersEvent;

export type TPitcherPaOutcomesTableSend = Interpreter<
	TPitcherPaOutcomesTableContext,
	IPitcherPaOutcomesTableStateSchema,
	TPitcherPaOutcomesTableEvent
>["send"];

const PitcherPaOutcomesTableMachine = (
	playerIdProp?: number,
	data?: TPitcherPaOutcomesTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TPitcherPaOutcomesTableContext, IPitcherPaOutcomesTableStateSchema, TPitcherPaOutcomesTableEvent>(
		{
			id: "pitcherPaOutcomesTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchDataProp,
				filters: {
					gameTypes: [GAME_TYPE_REGULAR_SEASON, GAME_TYPE_POSTSEASON],
					bats: [BATS_L, BATS_R],
					levels: undefined
				},
				playerSeasonXwoba: data?.playerSeasonXwoba,
				playerSeasonXwobaByTeam: data?.playerSeasonXwobaByTeam,
				statsPlayerPitching: data?.statsPlayerPitching,
				statsPlayerPitchingByTeam: data?.statsPlayerPitchingByTeam,
				playerSeasonPopPa: data?.playerSeasonPopPa,
				playerSeasonPopPaByTeam: data?.playerSeasonPopPaByTeam,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						SET_PLAYER_ID: { actions: "setPlayerId" },
						SET_PLAYER_SEASON_XWOBA: { actions: "setPlayerSeasonXwoba" },
						SET_PLAYER_SEASON_XWOBA_BYTEAM: { actions: "setPlayerSeasonXwobaByTeam" },
						SET_STATS_PLAYER_PITCHING: { actions: "setStatsPlayerPitching" },
						SET_STATS_PLAYER_PITCHING_BYTEAM: { actions: "setStatsPlayerPitchingByTeam" },
						SET_PLAYER_SEASON_POP_PA: { actions: "setPlayerSeasonPopPa" },
						SET_PLAYER_SEASON_POP_PA_BYTEAM: { actions: "setPlayerSeasonPopPaByTeam" },
						SET_FILTERS: { actions: "setFilters" }
					},
					states: {
						contextRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						playerSeasonXwoba: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonXwoba",
												cond: "shouldFetchPlayerSeasonXwoba"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonXwoba",
									entry: ["refreshPlayerSeasonXwobaCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonXwoba",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonXwobaSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonXwobaErrored"
										}
									}
								}
							}
						},
						playerSeasonXwobaByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonXwobaByTeam",
												cond: "shouldFetchPlayerSeasonXwobaByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonXwobaByTeam",
									entry: ["refreshPlayerSeasonXwobaByTeamCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonXwobaByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonXwobaByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonXwobaByTeamErrored"
										}
									}
								}
							}
						},
						statsPlayerPitching: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchStatsPlayerPitching",
												cond: "shouldFetchStatsPlayerPitching"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchStatsPlayerPitching",
									entry: ["refreshStatsPlayerPitchingCancelSource"],
									invoke: {
										src: "fetchStatsPlayerPitching",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchStatsPlayerPitchingSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchStatsPlayerPitchingErrored"
										}
									}
								}
							}
						},
						statsPlayerPitchingByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchStatsPlayerPitchingByTeam",
												cond: "shouldFetchStatsPlayerPitchingByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchStatsPlayerPitchingByTeam",
									entry: ["refreshStatsPlayerPitchingByTeamCancelSource"],
									invoke: {
										src: "fetchStatsPlayerPitchingByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchStatsPlayerPitchingByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchStatsPlayerPitchingByTeamErrored"
										}
									}
								}
							}
						},
						playerSeasonPopPa: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonPopPa",
												cond: "shouldFetchPlayerSeasonPopPa"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonPopPa",
									entry: ["refreshPlayerSeasonPopPaCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonPopPa",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonPopPaSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonPopPaErrored"
										}
									}
								}
							}
						},
						playerSeasonPopPaByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPlayerSeasonPopPaByTeam",
												cond: "shouldFetchPlayerSeasonPopPaByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPlayerSeasonPopPaByTeam",
									entry: ["refreshPlayerSeasonPopPaByTeamCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonPopPaByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonPopPaByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonPopPaByTeamErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (context: TPitcherPaOutcomesTableContext, _event: TPitcherPaOutcomesTableEvent) =>
					context.playerId !== context.lastPlayerId,
				shouldFetchPlayerSeasonXwoba: (
					context: TPitcherPaOutcomesTableContext,
					_event: TPitcherPaOutcomesTableEvent
				) =>
					context.playerSeasonXwoba === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPlayerSeasonXwobaByTeam: (
					context: TPitcherPaOutcomesTableContext,
					_event: TPitcherPaOutcomesTableEvent
				) =>
					context.playerSeasonXwobaByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchStatsPlayerPitching: (
					context: TPitcherPaOutcomesTableContext,
					_event: TPitcherPaOutcomesTableEvent
				) =>
					context.statsPlayerPitching === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchStatsPlayerPitchingByTeam: (
					context: TPitcherPaOutcomesTableContext,
					_event: TPitcherPaOutcomesTableEvent
				) =>
					context.statsPlayerPitchingByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPlayerSeasonPopPa: (
					context: TPitcherPaOutcomesTableContext,
					_event: TPitcherPaOutcomesTableEvent
				) =>
					context.playerSeasonPopPa === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPlayerSeasonPopPaByTeam: (
					context: TPitcherPaOutcomesTableContext,
					_event: TPitcherPaOutcomesTableEvent
				) =>
					context.playerSeasonPopPaByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true
			},
			actions: {
				clearContext: assign<TPitcherPaOutcomesTableContext, TPitcherPaOutcomesTableEvent>({
					lastPlayerId: (context: TPitcherPaOutcomesTableContext, _event: TPitcherPaOutcomesTableEvent) =>
						context.playerId,
					playerSeasonXwoba: (
						_context: TPitcherPaOutcomesTableContext,
						_event: TPitcherPaOutcomesTableEvent
					) => undefined,
					playerSeasonXwobaByTeam: (
						_context: TPitcherPaOutcomesTableContext,
						_event: TPitcherPaOutcomesTableEvent
					) => undefined,
					statsPlayerPitching: (
						_context: TPitcherPaOutcomesTableContext,
						_event: TPitcherPaOutcomesTableEvent
					) => undefined,
					statsPlayerPitchingByTeam: (
						_context: TPitcherPaOutcomesTableContext,
						_event: TPitcherPaOutcomesTableEvent
					) => undefined,
					playerSeasonPopPa: (
						_context: TPitcherPaOutcomesTableContext,
						_event: TPitcherPaOutcomesTableEvent
					) => undefined,
					playerSeasonPopPaByTeam: (
						_context: TPitcherPaOutcomesTableContext,
						_event: TPitcherPaOutcomesTableEvent
					) => undefined,
					cancelSources: (context: TPitcherPaOutcomesTableContext, _event: TPitcherPaOutcomesTableEvent) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				// Set Context Actions
				setPlayerId: assign<TPitcherPaOutcomesTableContext, TPitcherPaOutcomesTableEvent>({
					playerId: (context: TPitcherPaOutcomesTableContext, event: TPitcherPaOutcomesTableEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					},
					cancelSources: (context: TPitcherPaOutcomesTableContext, _event: TPitcherPaOutcomesTableEvent) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				setPlayerSeasonXwoba: assign<TPitcherPaOutcomesTableContext, TPitcherPaOutcomesTableEvent>({
					playerSeasonXwoba: (
						context: TPitcherPaOutcomesTableContext,
						event: TPitcherPaOutcomesTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_XWOBA) return context.playerSeasonXwoba;
						return event.value;
					},
					cancelSources: (context: TPitcherPaOutcomesTableContext, event: TPitcherPaOutcomesTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_XWOBA) return cancelSources;
						cancelSources[PLAYER_SEASON_XWOBA_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_XWOBA_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setPlayerSeasonXwobaByTeam: assign<TPitcherPaOutcomesTableContext, TPitcherPaOutcomesTableEvent>({
					playerSeasonXwobaByTeam: (
						context: TPitcherPaOutcomesTableContext,
						event: TPitcherPaOutcomesTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_XWOBA_BYTEAM) return context.playerSeasonXwobaByTeam;
						return event.value;
					},
					cancelSources: (context: TPitcherPaOutcomesTableContext, event: TPitcherPaOutcomesTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_XWOBA_BYTEAM) return cancelSources;
						cancelSources[PLAYER_SEASON_XWOBA_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_XWOBA_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setStatsPlayerPitching: assign<TPitcherPaOutcomesTableContext, TPitcherPaOutcomesTableEvent>({
					statsPlayerPitching: (
						context: TPitcherPaOutcomesTableContext,
						event: TPitcherPaOutcomesTableEvent
					) => {
						if (event.type !== SET_STATS_PLAYER_PITCHING) return context.statsPlayerPitching;
						return event.value;
					},
					cancelSources: (context: TPitcherPaOutcomesTableContext, event: TPitcherPaOutcomesTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_STATS_PLAYER_PITCHING) return cancelSources;
						cancelSources[STATS_PLAYER_PITCHING_CANCEL_SOURCE]?.cancel();
						cancelSources[STATS_PLAYER_PITCHING_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setStatsPlayerPitchingByTeam: assign<TPitcherPaOutcomesTableContext, TPitcherPaOutcomesTableEvent>({
					statsPlayerPitchingByTeam: (
						context: TPitcherPaOutcomesTableContext,
						event: TPitcherPaOutcomesTableEvent
					) => {
						if (event.type !== SET_STATS_PLAYER_PITCHING_BYTEAM) return context.statsPlayerPitchingByTeam;
						return event.value;
					},
					cancelSources: (context: TPitcherPaOutcomesTableContext, event: TPitcherPaOutcomesTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_STATS_PLAYER_PITCHING_BYTEAM) return cancelSources;
						cancelSources[STATS_PLAYER_PITCHING_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[STATS_PLAYER_PITCHING_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setPlayerSeasonPopPa: assign<TPitcherPaOutcomesTableContext, TPitcherPaOutcomesTableEvent>({
					playerSeasonPopPa: (
						context: TPitcherPaOutcomesTableContext,
						event: TPitcherPaOutcomesTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_POP_PA) return context.playerSeasonPopPa;
						return event.value;
					},
					cancelSources: (context: TPitcherPaOutcomesTableContext, event: TPitcherPaOutcomesTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_POP_PA) return cancelSources;
						cancelSources[PLAYER_SEASON_POP_PA_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_POP_PA_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setPlayerSeasonPopPaByTeam: assign<TPitcherPaOutcomesTableContext, TPitcherPaOutcomesTableEvent>({
					playerSeasonPopPaByTeam: (
						context: TPitcherPaOutcomesTableContext,
						event: TPitcherPaOutcomesTableEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_POP_PA_BYTEAM) return context.playerSeasonPopPaByTeam;
						return event.value;
					},
					cancelSources: (context: TPitcherPaOutcomesTableContext, event: TPitcherPaOutcomesTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_PLAYER_SEASON_POP_PA_BYTEAM) return cancelSources;
						cancelSources[PLAYER_SEASON_POP_PA_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_POP_PA_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setFilters: assign<TPitcherPaOutcomesTableContext, TPitcherPaOutcomesTableEvent>({
					filters: (context: TPitcherPaOutcomesTableContext, event: TPitcherPaOutcomesTableEvent) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return event.value;
					}
				}),
				// Cancel Source Actions
				refreshPlayerSeasonXwobaCancelSource: assign<
					TPitcherPaOutcomesTableContext,
					TPitcherPaOutcomesTableEvent
				>({
					cancelSources: (context: TPitcherPaOutcomesTableContext, _event: TPitcherPaOutcomesTableEvent) => {
						if (context.cancelSources[PLAYER_SEASON_XWOBA_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_XWOBA_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_XWOBA_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonXwobaByTeamCancelSource: assign<
					TPitcherPaOutcomesTableContext,
					TPitcherPaOutcomesTableEvent
				>({
					cancelSources: (context: TPitcherPaOutcomesTableContext, _event: TPitcherPaOutcomesTableEvent) => {
						if (context.cancelSources[PLAYER_SEASON_XWOBA_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_XWOBA_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_XWOBA_BYTEAM_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshStatsPlayerPitchingCancelSource: assign<
					TPitcherPaOutcomesTableContext,
					TPitcherPaOutcomesTableEvent
				>({
					cancelSources: (context: TPitcherPaOutcomesTableContext, _event: TPitcherPaOutcomesTableEvent) => {
						if (context.cancelSources[STATS_PLAYER_PITCHING_CANCEL_SOURCE] != null)
							context.cancelSources[STATS_PLAYER_PITCHING_CANCEL_SOURCE].cancel();
						context.cancelSources[STATS_PLAYER_PITCHING_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshStatsPlayerPitchingByTeamCancelSource: assign<
					TPitcherPaOutcomesTableContext,
					TPitcherPaOutcomesTableEvent
				>({
					cancelSources: (context: TPitcherPaOutcomesTableContext, _event: TPitcherPaOutcomesTableEvent) => {
						if (context.cancelSources[STATS_PLAYER_PITCHING_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[STATS_PLAYER_PITCHING_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[STATS_PLAYER_PITCHING_BYTEAM_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonPopPaCancelSource: assign<
					TPitcherPaOutcomesTableContext,
					TPitcherPaOutcomesTableEvent
				>({
					cancelSources: (context: TPitcherPaOutcomesTableContext, _event: TPitcherPaOutcomesTableEvent) => {
						if (context.cancelSources[PLAYER_SEASON_POP_PA_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_POP_PA_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_POP_PA_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonPopPaByTeamCancelSource: assign<
					TPitcherPaOutcomesTableContext,
					TPitcherPaOutcomesTableEvent
				>({
					cancelSources: (context: TPitcherPaOutcomesTableContext, _event: TPitcherPaOutcomesTableEvent) => {
						if (context.cancelSources[PLAYER_SEASON_POP_PA_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_POP_PA_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_POP_PA_BYTEAM_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchPlayerSeasonXwobaSuccess: assign<
					TPitcherPaOutcomesTableContext,
					TPitcherPaOutcomesTableEvent
				>({
					playerSeasonXwoba: (
						context: TPitcherPaOutcomesTableContext,
						event: TPitcherPaOutcomesTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_XWOBA_DONE) return context.playerSeasonXwoba;
						return event.data;
					}
				}),
				handleFetchPlayerSeasonXwobaByTeamSuccess: assign<
					TPitcherPaOutcomesTableContext,
					TPitcherPaOutcomesTableEvent
				>({
					playerSeasonXwobaByTeam: (
						context: TPitcherPaOutcomesTableContext,
						event: TPitcherPaOutcomesTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_XWOBA_BYTEAM_DONE)
							return context.playerSeasonXwobaByTeam;
						return event.data;
					}
				}),
				handleFetchStatsPlayerPitchingSuccess: assign<
					TPitcherPaOutcomesTableContext,
					TPitcherPaOutcomesTableEvent
				>({
					statsPlayerPitching: (
						context: TPitcherPaOutcomesTableContext,
						event: TPitcherPaOutcomesTableEvent
					) => {
						if (event.type !== FETCH_STATS_PLAYER_PITCHING_DONE) return context.statsPlayerPitching;
						return event.data;
					}
				}),
				handleFetchStatsPlayerPitchingByTeamSuccess: assign<
					TPitcherPaOutcomesTableContext,
					TPitcherPaOutcomesTableEvent
				>({
					statsPlayerPitchingByTeam: (
						context: TPitcherPaOutcomesTableContext,
						event: TPitcherPaOutcomesTableEvent
					) => {
						if (event.type !== FETCH_STATS_PLAYER_PITCHING_BYTEAM_DONE)
							return context.statsPlayerPitchingByTeam;
						return event.data;
					}
				}),
				handleFetchPlayerSeasonPopPaSuccess: assign<
					TPitcherPaOutcomesTableContext,
					TPitcherPaOutcomesTableEvent
				>({
					playerSeasonPopPa: (
						context: TPitcherPaOutcomesTableContext,
						event: TPitcherPaOutcomesTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_POP_PA_DONE) return context.playerSeasonPopPa;
						return event.data;
					}
				}),
				handleFetchPlayerSeasonPopPaByTeamSuccess: assign<
					TPitcherPaOutcomesTableContext,
					TPitcherPaOutcomesTableEvent
				>({
					playerSeasonPopPaByTeam: (
						context: TPitcherPaOutcomesTableContext,
						event: TPitcherPaOutcomesTableEvent
					) => {
						if (event.type !== FETCH_PLAYER_SEASON_POP_PA_BYTEAM_DONE)
							return context.playerSeasonPopPaByTeam;
						return event.data;
					}
				}),
				// Fetch Error Actions
				handleFetchPlayerSeasonXwobaErrored: (
					context: TPitcherPaOutcomesTableContext,
					event: TPitcherPaOutcomesTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_XWOBA_ERROR ? event.data : undefined,
						context.toast,
						"PA Outcomes - xwOBA",
						"Error fetching xwOBA by player and season."
					);
				},
				handleFetchPlayerSeasonXwobaByTeamErrored: (
					context: TPitcherPaOutcomesTableContext,
					event: TPitcherPaOutcomesTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_XWOBA_BYTEAM_ERROR ? event.data : undefined,
						context.toast,
						"PA Outcomes - xwOBA by Team",
						"Error fetching xwOBA by player, season, and team"
					);
				},
				handleFetchStatsPlayerPitchingErrored: (
					context: TPitcherPaOutcomesTableContext,
					event: TPitcherPaOutcomesTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_STATS_PLAYER_PITCHING_ERROR ? event.data : undefined,
						context.toast,
						"PA Outcomes - Stats Pitching",
						"Error fetching stats pitching by player and season."
					);
				},
				handleFetchStatsPlayerPitchingByTeamErrored: (
					context: TPitcherPaOutcomesTableContext,
					event: TPitcherPaOutcomesTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_STATS_PLAYER_PITCHING_BYTEAM_ERROR ? event.data : undefined,
						context.toast,
						"PA Outcomes - Stats Pitching by Team",
						"Error fetching stats pitching by player, season, and team"
					);
				},
				handleFetchPlayerSeasonPopPaErrored: (
					context: TPitcherPaOutcomesTableContext,
					event: TPitcherPaOutcomesTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_POP_PA_ERROR ? event.data : undefined,
						context.toast,
						"PA Outcomes - PoP PA",
						"Error fetching PoP PA by player and season."
					);
				},
				handleFetchPlayerSeasonPopPaByTeamErrored: (
					context: TPitcherPaOutcomesTableContext,
					event: TPitcherPaOutcomesTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_POP_PA_BYTEAM_ERROR ? event.data : undefined,
						context.toast,
						"PA Outcomes - PoP PA by Team",
						"Error fetching PoP PA by player, season, and team"
					);
				}
			},
			services: {
				fetchPlayerSeasonXwoba: (context: TPitcherPaOutcomesTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonXwoba(
							{
								playerId: playerId,
								isHitter: false,
								playingLevel: PLAYING_LEVEL_PRO,
								model: MODEL_EV_LA,
								throws: THROWS_OVERALL,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_XWOBA_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonXwobaByTeam: (context: TPitcherPaOutcomesTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonXwobaByTeam(
							{
								playerId: playerId,
								isHitter: false,
								playingLevel: PLAYING_LEVEL_PRO,
								model: MODEL_EV_LA,
								throws: THROWS_OVERALL,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_XWOBA_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchStatsPlayerPitching: (context: TPitcherPaOutcomesTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchStatsPlayerPitching(
							{
								playerId: playerId,
								"source[in]": [SOURCE_GUMBO, SOURCE_STATSAPI].join(","),
								"gameType[in]": [VALID_GAME_TYPES].join(","),
								isUseCache: true
							},
							context.cancelSources[STATS_PLAYER_PITCHING_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchStatsPlayerPitchingByTeam: (context: TPitcherPaOutcomesTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchStatsPlayerPitchingByTeam(
							{
								playerId: playerId,
								"source[in]": [SOURCE_GUMBO, SOURCE_STATSAPI].join(","),
								"gameType[in]": [VALID_GAME_TYPES].join(","),
								isUseCache: true
							},
							context.cancelSources[STATS_PLAYER_PITCHING_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonPopPa: (context: TPitcherPaOutcomesTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPitchOutcomeProbabilitiesPa(
							{
								pitcherId: playerId,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_POP_PA_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonPopPaByTeam: (context: TPitcherPaOutcomesTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPitchOutcomeProbabilitiesPaByTeam(
							{
								pitcherId: playerId,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_POP_PA_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PitcherPaOutcomesTableMachine;
