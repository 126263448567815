import React, { useMemo } from "react";
import { v4 } from "uuid";

import { $TSFixMe } from "utils/tsutils";
import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { Button } from "_react/shared/legacy/ui/Button";
import { IconButton } from "_react/shared/legacy/ui/IconButton";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import Add from "_react/shared/legacy/ui/icons/Add";
import Close from "_react/shared/legacy/ui/icons/Close";

import { FormComponent } from "_react/shared/forms/FormComponent";
import {
	TFormSection,
	TDocumentBase,
	TFormValueNested,
	INCREMENT,
	HIDE,
	DISABLED,
	AS_LABEL
} from "_react/shared/forms/_types";
import {
	buildComponentColumArray,
	buildComponentRowArray,
	buildComponentCollection
} from "_react/shared/forms/_helpers";
import {
	FormSectionContainer,
	FormColumnContainer,
	ICON_BUTTON_STYLE,
	BUTTON_STYLE,
	ADD_ICON_STYLE,
	DELETE_ICON_STYLE,
	FormCollectionContainer,
	AddButtonTitle,
	FormCollectionDeleteRowContainer,
	FormColumnRowContainer,
	FormRowContainer
} from "_react/shared/forms/_styles";
import { useFormContext } from "_react/shared/forms/_context";

interface FormSectionProps {
	colorScheme?: ColorSchemeGroup;
	section: TFormSection;
}

export function FormSection<T extends TDocumentBase>({ colorScheme = defaultColorScheme, section }: FormSectionProps) {
	const { document, submitUpdate, plugin, readOnly } = useFormContext<T>();

	const columns = useMemo(
		() => (section.isCollection || section.isRowBased ? [] : buildComponentColumArray(section.components)),
		[section.components, section.isCollection, section.isRowBased]
	);
	const rows = useMemo(
		() => (section.isCollection || !section.isRowBased ? [] : buildComponentRowArray(section.components)),
		[section.components, section.isCollection, section.isRowBased]
	);
	const collectionRows = useMemo(() => (section.isCollection ? buildComponentCollection(section) : []), [section]);

	// Callback to delete a collection row
	const deleteCollectionRow = (index: number) => {
		const collection = [...(document[section.key!] as TFormValueNested[])];
		collection.splice(index, 1);
		submitUpdate(section.key!, collection, document);
	};

	// Callback to add a collection row
	const addCollectionRow = () => {
		const collection: TFormValueNested[] = [...((document[section.key!] as TFormValueNested[]) ?? [])];
		const newRow = {} as TFormValueNested;
		if (plugin.dontCreateUUIDWhenCreatingCollectionItem !== true) {
			newRow.id = v4();
		}
		for (let i = 0; i < section.components.length; i++) {
			const component = section.components[i];
			if (component.default === INCREMENT) {
				const newValue =
					collection.length > 0 ? (collection[collection.length - 1][component.key] as number) + 1 : 1;
				newRow[component.key] = newValue;
			}
		}
		collection.push(newRow);
		submitUpdate(section.key!, collection, document);
	};

	// Filter out hidden fields

	// Traditional Section Contents
	let renderedContent = section.isRowBased ? (
		<>
			{rows.map(componentRow => (
				<FormRowContainer
					flexBasis={"100%"}
					items={componentRow.components.length}
					key={componentRow.rowNumber}
				>
					{componentRow.components
						.filter(component => {
							return plugin.getFieldState == null || plugin.getFieldState(component, document) !== HIDE;
						})
						.map((component, idx) => {
							let disabled = false;
							if (plugin.getFieldState != null) {
								const fieldState = plugin.getFieldState(component, document);
								if (fieldState === DISABLED) disabled = true;
								if (fieldState === AS_LABEL) {
									component = { ...component, type: "label" };
								}
							}
							return (
								<FormComponent<T>
									key={component.key}
									component={component}
									evenRow={idx % 2 === 0}
									disabled={disabled}
									isRowBased={true}
									width={component.width}
								/>
							);
						})}
				</FormRowContainer>
			))}
		</>
	) : (
		<>
			{columns.map(componentColumn => (
				<FormColumnContainer
					flexBasis={`${100 / columns.length}%`}
					items={componentColumn.components.length}
					key={componentColumn.columnNumber}
				>
					{componentColumn.components
						.filter(component => {
							return plugin.getFieldState == null || plugin.getFieldState(component, document) !== HIDE;
						})
						.map((component, idx) => {
							let disabled = false;
							if (plugin.getFieldState != null) {
								const fieldState = plugin.getFieldState(component, document);
								if (fieldState === DISABLED) disabled = true;
								if (fieldState === AS_LABEL) {
									component = { ...component, type: "label" };
								}
							}
							return (
								<FormComponent<T>
									key={component.key}
									component={component}
									evenRow={idx % 2 === 0}
									disabled={disabled}
								/>
							);
						})}
				</FormColumnContainer>
			))}
		</>
	);

	if (section.isCollection) {
		// Section is a collection of objects
		// Extract collection array from document
		const collectionArray: T[] = ((document[section.key as string] as $TSFixMe) as T[]) ?? []; // This can probably not be casted as tsfixme first, I think I fixed that issue
		if (collectionArray) {
			// Render the section as a collection
			renderedContent = (
				<FormCollectionContainer>
					{collectionArray.map(
						// Iterate over each item in the collection
						(_, idx) => (
							<FormSectionContainer key={idx}>
								<FormColumnRowContainer>
									{collectionRows.map(columnSet => {
										// const rowWidth = columnSet.components.map(columnSet => columnSet.components.length > 0 ? columnSet.components[0].width ?? 1 : 0).reduce((sum, width) => sum + width, 0);
										return (
											<FormSectionContainer key={`${columnSet.rowNumber}-${idx}`}>
												{columnSet.components.map(
													// For each collection, iterate over each field (columns, as this is effectively a table)
													(componentColumn, idxx) => {
														const columnComponents = componentColumn.components.filter(
															component => {
																if (plugin.getFieldState != null) {
																	const fieldState = plugin.getFieldState(
																		component,
																		document
																	);
																	if (fieldState === HIDE) return null;
																}
																return component;
															}
														);
														if (columnComponents.length === 0) return null;
														// columnComponents.length
														const flex =
															columnComponents.length > 0
																? columnComponents[0].width ?? 1
																: 0;
														return (
															<FormColumnContainer
																flex={flex}
																items={idx + 1}
																key={idxx}
																isCollection={true}
															>
																{columnComponents.map((component, idxxx) => {
																	let disabled = false;
																	if (plugin.getFieldState != null) {
																		const fieldState = plugin.getFieldState(
																			component,
																			document
																		);
																		if (fieldState === DISABLED) disabled = true;
																		if (fieldState === AS_LABEL) {
																			component = { ...component, type: "label" };
																		}
																	}
																	const componentKey = `${section.key}.${idx}.${component.key}`;
																	return (
																		<FormComponent<T>
																			key={component.key}
																			component={{
																				...component,
																				key: componentKey
																			}}
																			evenRow={idxxx % 2 === 0}
																			disabled={disabled}
																		/>
																	);
																})}
															</FormColumnContainer>
														);
													}
												)}
											</FormSectionContainer>
										);
									})}
								</FormColumnRowContainer>

								{readOnly !== true && (
									<FormCollectionDeleteRowContainer evenRow={idx % 2 === 0}>
										<IconButton
											colorScheme={colorScheme.primary}
											onClick={() => deleteCollectionRow(idx)}
											style={ICON_BUTTON_STYLE}
										>
											<Close fill={colorScheme.primary.text} style={DELETE_ICON_STYLE} />
										</IconButton>
									</FormCollectionDeleteRowContainer>
								)}
							</FormSectionContainer>
						)
					)}
					{readOnly !== true && (
						<Button
							title={
								<AddButtonTitle>
									<Add fill={colorScheme.primary.text} style={ADD_ICON_STYLE} /> Add
								</AddButtonTitle>
							}
							colorScheme={colorScheme.primary}
							onClick={() => addCollectionRow()}
							style={BUTTON_STYLE}
						/>
					)}
				</FormCollectionContainer>
			);
		} else {
			// Error: No Collection Items
		}
	}
	return (
		<Card colorScheme={colorScheme.primary} overflow="show">
			{section.heading != null && <ColoredCardHeader colorScheme={colorScheme.primary} text={section.heading} />}
			<FormSectionContainer flexDirection={section.isCollection || section.isRowBased ? "column" : "row"}>
				{renderedContent}
			</FormSectionContainer>
		</Card>
	);
}
