import React, { useEffect } from "react";
import { IconButton, Tooltip, useToast } from "@chakra-ui/react";

import { DEFAULT_TOAST_WARNING_PROPS } from "_react/shared/_constants/toast";
import OutlineEdit from "_react/shared/ui/icons/OutlineEdit";
import Save from "_react/shared/ui/icons/Save";

type TEditBioButtonProps = {
	redirectToBioTab: () => void;
	setBioDisplayOnly: (bioDisplayOnly: boolean) => void;
	isBioSaving: boolean;
	submitBioUpdate: () => Promise<void>;
	bioDisplayOnly: boolean;
};

const EditBioButton = ({
	redirectToBioTab,
	setBioDisplayOnly,
	isBioSaving,
	submitBioUpdate,
	bioDisplayOnly
}: TEditBioButtonProps) => {
	const toast = useToast();

	const buttonTitle = bioDisplayOnly ? "Edit Bio" : "Save Bio";
	const buttonIcon = bioDisplayOnly ? <OutlineEdit boxSize={4} /> : <Save boxSize={5} />;

	const onClick = () => {
		redirectToBioTab();
		toast.closeAll();
		if (!bioDisplayOnly && !isBioSaving) {
			submitBioUpdate().then(() => {
				setBioDisplayOnly(true);
			});
		} else {
			setBioDisplayOnly(false);
			toast({
				...DEFAULT_TOAST_WARNING_PROPS,
				duration: null,
				title: "Editing Bio",
				description: "Save before leaving the page"
			});
		}
	};

	useEffect(() => {
		return () => {
			toast.closeAll();
		};
	}, [toast]);

	return (
		<Tooltip hasArrow placement="top" label={buttonTitle}>
			<IconButton
				colorScheme="gray"
				aria-label={buttonTitle}
				borderRadius="full"
				marginRight="2"
				icon={buttonIcon}
				onClick={onClick}
				size="sm"
				isDisabled={isBioSaving}
				isLoading={isBioSaving}
			/>
		</Tooltip>
	);
};

export default EditBioButton;
