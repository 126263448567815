import { CancelToken } from "axios";
import { deleteResource, fetchResource, postResource, putResource } from "_react/shared/_helpers/axios";
import {
	IManualPitchTypeMapBySeason,
	IManualPitchTypeMapBySeasonApiResponse
} from "_react/shared/data_models/manual_pitch_type_map_byseason/_types";

//
// Manual Pitch Type Map By Season
//

// GET

export type TManualPitchTypeMapBySeasonGetQueryParams = {
	playerId?: number;
	season?: number;
	pitchTypeFrom?: string;
	sort?: string;
	isSortDescending?: boolean;
};

export const fetchManualPitchTypeMapsBySeason = (
	queryParams: TManualPitchTypeMapBySeasonGetQueryParams,
	cancelToken?: CancelToken
) =>
	fetchResource<TManualPitchTypeMapBySeasonGetQueryParams, Array<IManualPitchTypeMapBySeasonApiResponse>>(
		"/manual_pitch_type_map_byseason",
		queryParams,
		cancelToken
	);

// POST & PUT

export type TManualPitchTypeMapBySeasonPostPutBody = Pick<
	IManualPitchTypeMapBySeason,
	"playerId" | "season" | "pitchTypeFrom" | "pitchTypeTo"
> &
	Partial<IManualPitchTypeMapBySeason>;

export const postManualPitchTypeMapBySeason = (
	body: TManualPitchTypeMapBySeasonPostPutBody,
	cancelToken?: CancelToken
) => {
	return postResource<TManualPitchTypeMapBySeasonPostPutBody, Array<IManualPitchTypeMapBySeason>>(
		"/manual_pitch_type_map_byseason",
		body,
		undefined,
		cancelToken
	);
};

export const putManualPitchTypeMapBySeason = (
	body: TManualPitchTypeMapBySeasonPostPutBody,
	cancelToken?: CancelToken
) => {
	return putResource<TManualPitchTypeMapBySeasonPostPutBody, Array<IManualPitchTypeMapBySeason>>(
		"/manual_pitch_type_map_byseason",
		body,
		undefined,
		cancelToken
	);
};

// DELETE

export type TManualPitchTypeMapBySeasonDeleteQueryParams = Pick<
	IManualPitchTypeMapBySeason,
	"playerId" | "season" | "pitchTypeFrom"
>;

export const deleteManualPitchTypeMapBySeason = (
	queryParams: TManualPitchTypeMapBySeasonDeleteQueryParams,
	cancelToken?: CancelToken
) =>
	deleteResource<TManualPitchTypeMapBySeasonDeleteQueryParams, Array<IManualPitchTypeMapBySeason>>(
		"/manual_pitch_type_map_byseason",
		queryParams,
		cancelToken
	);
