import { TPositionPlayerValueRow } from "_react/shared/ui/data/tables/PositionPlayerValueTable/_types";

// Used to get the level to display for a row
export const getLevelsFromRow = (row: TPositionPlayerValueRow): Array<string> => {
	// Child Rows or rows with no nested data
	if ("teamBam" in row.combinedPositionPlayerValueData)
		return row.combinedPositionPlayerValueData.teamBam?.level
			? [row.combinedPositionPlayerValueData.teamBam?.level]
			: [];
	// Parent Rows with nested data
	if (row.childData && row.childData.length > 1) {
		return [
			...new Set(
				row.childData.reduce((acc: Array<string>, childRow: TPositionPlayerValueRow) => {
					if (
						"teamBam" in childRow.combinedPositionPlayerValueData &&
						childRow.combinedPositionPlayerValueData.teamBam?.level
					)
						acc.push(childRow.combinedPositionPlayerValueData.teamBam?.level);
					return acc;
				}, [])
			)
		];
	}
	return [];
};
