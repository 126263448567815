import React from "react";
import { MultiValue, SingleValue } from "react-select";

import { PERFORMANCE_TIER, SKILL_TIER } from "_react/playerplan/metrics/_constants";
import { DEFAULT_SECONDARY_GOAL_FORM, PERCENTAGE_FORMAT, STRENGTH } from "_react/playerplan/shared/_constants";
import { createGoalStatement } from "_react/playerplan/shared/_helpers";
import { TGoalForm, TLkPlayerPlanMetricSubtype, TLkPlayerPlanMetricType } from "_react/playerplan/shared/_types";
import {
	AddGoalTable,
	AddGoalTd,
	AddGoalTr,
	FlexCenteredDiv,
	GoalContents,
	MaxWidthSelectStyle,
	NoteTextFieldStyle
} from "_react/playerplan/shared/_styles";
import MetricSelect, { TMetricOption } from "_react/shared/selects/MetricSelect";
import LkSelect from "_react/inputs/lks/LkSelect";
import { TOption } from "_react/inputs/_types";
import { TChangeEvent, TextField } from "_react/shared/legacy/ui/TextField";
import { Tooltip } from "_react/shared/legacy/ui/Tooltip";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import Info from "_react/shared/legacy/ui/icons/Info";

export type TGoalFormSectionProps = {
	addGoalForm: TGoalForm;
	secondary: boolean;
	setAddGoalForm: (value: TGoalForm) => void;
	goalType: string | undefined;
	colorSchemeGroup?: ColorSchemeGroup;
	hasSecondaryGoal?: boolean;
	primaryMetricType?: TLkPlayerPlanMetricType;
	primaryMetricSubtype?: TLkPlayerPlanMetricSubtype | null;
};

export const GoalFormSection = ({
	addGoalForm,
	secondary,
	setAddGoalForm,
	goalType,
	colorSchemeGroup = defaultColorScheme,
	hasSecondaryGoal = false,
	primaryMetricType,
	primaryMetricSubtype
}: TGoalFormSectionProps) => {
	return !secondary ? (
		<GoalContents>
			<AddGoalTable>
				<colgroup>
					<col />
					<col />
					<col />
				</colgroup>
				<tbody>
					<AddGoalTr>
						<AddGoalTd colSpan={3}>
							Tier 1:{" "}
							{!addGoalForm.metric
								? "No Metric Selected"
								: createGoalStatement(
										addGoalForm.metricLabel ?? undefined,
										addGoalForm.metricSubtypeLabel ?? undefined,
										undefined,
										addGoalForm.directionLabel ?? undefined,
										addGoalForm.target ? parseFloat(addGoalForm.target) : undefined,
										addGoalForm.metricFormat,
										false,
										undefined,
										goalType === STRENGTH
								  )}
						</AddGoalTd>
					</AddGoalTr>
					<AddGoalTr>
						<AddGoalTd>
							<MetricSelect
								isClearable={false}
								metricTypes={addGoalForm.metricType ? [addGoalForm.metricType] : undefined}
								metricSubtypes={addGoalForm.metricSubtype ? [addGoalForm.metricSubtype] : undefined}
								metricTiers={[SKILL_TIER]}
								handleSelect={(opt: TMetricOption) => {
									setAddGoalForm({
										...addGoalForm,
										metric: opt?.value,
										isQualitative: opt?.isQualitative,
										metricLabel: opt?.rawLabel,
										metricFormat: opt?.format
									});
								}}
								value={addGoalForm.metric ? [addGoalForm.metric] : null}
							/>
						</AddGoalTd>
						<AddGoalTd>
							<LkSelect<string>
								lkName={"lk_player_plan_target_direction"}
								onChange={(opt: MultiValue<TOption<string>> | SingleValue<TOption<string>> | null) =>
									setAddGoalForm({
										...addGoalForm,
										direction: (opt as TOption<string>)?.value,
										directionLabel: (opt as TOption<string>)?.label
									})
								}
								valueOnlyValue={addGoalForm.direction ?? null}
								isClearable
								placeholder={"Select direction..."}
								styles={MaxWidthSelectStyle}
							/>
						</AddGoalTd>
						<AddGoalTd>
							<FlexCenteredDiv>
								<TextField
									fullWidth
									type={"number"}
									onChange={(e: TChangeEvent) =>
										setAddGoalForm({
											...addGoalForm,
											target: e.target.value === "" ? null : e.target.value
										})
									}
									value={addGoalForm.target}
									placeholder={"Enter target value..."}
									style={NoteTextFieldStyle}
								/>
								{addGoalForm.metricFormat === PERCENTAGE_FORMAT && (
									<Tooltip
										placement="left"
										title={"Must be a number between 0 and 1"}
										colorScheme={colorSchemeGroup.primary}
									>
										<Info />
									</Tooltip>
								)}
							</FlexCenteredDiv>
						</AddGoalTd>
					</AddGoalTr>
					<AddGoalTr>
						<AddGoalTd colSpan={3}>
							<TextField
								fullWidth
								onChange={(e: TChangeEvent) =>
									setAddGoalForm({ ...addGoalForm, description: e.target.value })
								}
								value={addGoalForm.description}
								placeholder={"Enter description..."}
								style={NoteTextFieldStyle}
							/>
						</AddGoalTd>
					</AddGoalTr>
				</tbody>
			</AddGoalTable>
		</GoalContents>
	) : (
		<GoalContents>
			{hasSecondaryGoal && (
				<>
					<AddGoalTable>
						<colgroup>
							<col />
							<col />
							<col />
						</colgroup>
						<tbody>
							<AddGoalTr>
								<AddGoalTd colSpan={3}>
									Tier 2:{" "}
									{!addGoalForm.secondaryGoal?.metric
										? "No Metric Selected"
										: createGoalStatement(
												addGoalForm.secondaryGoal?.metricLabel ?? undefined,
												addGoalForm.metricSubtypeLabel ?? undefined,
												undefined,
												addGoalForm.secondaryGoal?.directionLabel ?? undefined,
												addGoalForm.secondaryGoal?.target
													? parseFloat(addGoalForm.secondaryGoal?.target)
													: undefined,
												addGoalForm.secondaryGoal?.metricFormat,
												false,
												undefined,
												goalType === STRENGTH
										  )}
								</AddGoalTd>
							</AddGoalTr>
							<AddGoalTr>
								<AddGoalTd>
									<MetricSelect
										isClearable={false}
										metricTypes={
											primaryMetricType
												? [primaryMetricType.value]
												: addGoalForm.metricType
												? [addGoalForm.metricType]
												: undefined
										}
										metricSubtypes={
											primaryMetricSubtype
												? [primaryMetricSubtype.value]
												: addGoalForm.metricSubtype
												? [addGoalForm.metricSubtype]
												: undefined
										}
										metricTiers={[PERFORMANCE_TIER]}
										handleSelect={(opt: TMetricOption) => {
											setAddGoalForm({
												...addGoalForm,
												secondaryGoal: {
													direction: addGoalForm.secondaryGoal?.direction || null,
													directionLabel: addGoalForm.secondaryGoal?.directionLabel || null,
													target: addGoalForm.secondaryGoal?.target || null,
													description: addGoalForm.secondaryGoal?.description || null,
													metric: opt?.value,
													isQualitative: opt?.isQualitative,
													metricLabel: opt?.rawLabel,
													metricFormat: opt?.format,
													season: addGoalForm.secondaryGoal?.season || null,
													startDate: addGoalForm.secondaryGoal?.startDate || null,
													endDate: addGoalForm.secondaryGoal?.endDate || null
												}
											});
										}}
										value={
											addGoalForm.secondaryGoal?.metric
												? [addGoalForm.secondaryGoal?.metric]
												: null
										}
									/>
								</AddGoalTd>
								<AddGoalTd>
									<LkSelect<string>
										lkName={"lk_player_plan_target_direction"}
										onChange={(
											opt: MultiValue<TOption<string>> | SingleValue<TOption<string>> | null
										) =>
											setAddGoalForm({
												...addGoalForm,
												secondaryGoal: {
													direction: (opt as TOption<string>)?.value,
													directionLabel: (opt as TOption<string>)?.label,
													metric: addGoalForm.secondaryGoal?.metric || null,
													isQualitative:
														addGoalForm.secondaryGoal?.isQualitative ||
														DEFAULT_SECONDARY_GOAL_FORM.isQualitative,
													metricLabel: addGoalForm.secondaryGoal?.metricLabel || null,
													metricFormat: addGoalForm.secondaryGoal?.metricFormat || null,
													target: addGoalForm.secondaryGoal?.target || null,
													description: addGoalForm.secondaryGoal?.description || null,
													season: addGoalForm.secondaryGoal?.season || null,
													startDate: addGoalForm.secondaryGoal?.startDate || null,
													endDate: addGoalForm.secondaryGoal?.endDate || null
												}
											})
										}
										valueOnlyValue={addGoalForm.secondaryGoal?.direction ?? undefined}
										isClearable
										styles={MaxWidthSelectStyle}
										placeholder={"Select direction..."}
									/>
								</AddGoalTd>
								<AddGoalTd>
									<FlexCenteredDiv>
										<TextField
											fullWidth
											type={"number"}
											onChange={(e: TChangeEvent) =>
												setAddGoalForm({
													...addGoalForm,
													secondaryGoal: {
														direction: addGoalForm.secondaryGoal?.direction || null,
														directionLabel:
															addGoalForm.secondaryGoal?.directionLabel || null,
														metric: addGoalForm.secondaryGoal?.metric || null,
														metricLabel: addGoalForm.secondaryGoal?.metricLabel || null,
														metricFormat: addGoalForm.secondaryGoal?.metricFormat || null,
														description: addGoalForm.secondaryGoal?.description || null,
														isQualitative:
															addGoalForm.secondaryGoal?.isQualitative ||
															DEFAULT_SECONDARY_GOAL_FORM.isQualitative,
														target: e.target.value === "" ? null : e.target.value,
														season: addGoalForm.secondaryGoal?.season || null,
														startDate: addGoalForm.secondaryGoal?.startDate || null,
														endDate: addGoalForm.secondaryGoal?.endDate || null
													}
												})
											}
											value={addGoalForm.secondaryGoal?.target}
											placeholder={"Enter target value..."}
											style={NoteTextFieldStyle}
										/>
										{addGoalForm.secondaryGoal?.metricFormat === PERCENTAGE_FORMAT && (
											<Tooltip
												placement="left"
												title={"Must be a number between 0 and 1"}
												colorScheme={colorSchemeGroup.primary}
											>
												<Info />
											</Tooltip>
										)}
									</FlexCenteredDiv>
								</AddGoalTd>
							</AddGoalTr>
							<AddGoalTr>
								<AddGoalTd colSpan={3}>
									<TextField
										fullWidth
										onChange={(e: TChangeEvent) =>
											setAddGoalForm({
												...addGoalForm,
												secondaryGoal: {
													direction: addGoalForm.secondaryGoal?.direction || null,
													directionLabel: addGoalForm.secondaryGoal?.directionLabel || null,
													metric: addGoalForm.secondaryGoal?.metric || null,
													isQualitative:
														addGoalForm.secondaryGoal?.isQualitative ||
														DEFAULT_SECONDARY_GOAL_FORM.isQualitative,
													metricLabel: addGoalForm.secondaryGoal?.metricLabel || null,
													metricFormat: addGoalForm.secondaryGoal?.metricFormat || null,
													target: addGoalForm.secondaryGoal?.target || null,
													description: e.target.value,
													season: addGoalForm.secondaryGoal?.season || null,
													startDate: addGoalForm.secondaryGoal?.startDate || null,
													endDate: addGoalForm.secondaryGoal?.endDate || null
												}
											})
										}
										value={addGoalForm.secondaryGoal?.description ?? undefined}
										placeholder={"Enter description..."}
										style={NoteTextFieldStyle}
									/>
								</AddGoalTd>
							</AddGoalTr>
						</tbody>
					</AddGoalTable>
				</>
			)}
		</GoalContents>
	);
};
