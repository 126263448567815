import React, { createContext, useState, useCallback, useMemo, useEffect, useContext } from "react";
import { useSnackbar } from "notistack";
import { useToast } from "@chakra-ui/react";
import dayjs from "dayjs";

import axios from "_redux/_utils/_axios";
import { CancelToken } from "axios";
import { $TSFixMe } from "utils/tsutils";
import { LOADING_TITLE, PLAYER_PAGE } from "utils/constants";
import { convertDates } from "utils/helpers";
import { useQueryString } from "utils/url_helpers";
import { useUserId, useIsGroupMember } from "_react/app/AppContents";
import { useDocumentTitle } from "_react/_hooks";
import { player_ama } from "_react/shared/_types/phil_data/player_ama";
import { player_intl } from "_react/shared/_types/phil_data/player_intl";
import { player_pro } from "_react/shared/_types/mesa/player_pro";
import { $TSFixMeEval } from "_react/evals/shared/_types";
import { TRockyNote } from "_react/shared/data_models/notes/_types";
import { usePersistentErrorSnackbar } from "_react/shared/_helpers/snackbar";
import { DEFAULT_TOAST_ERROR_PROPS } from "_react/shared/_constants/toast";
import { PlayerPageContext as NewPlayerPageContext } from "_react/playerpage/PlayerPageProvider";
import { SET_PLAYER, SET_PLAYER_LEGACY } from "_react/playerpage/_machine";

import {
	createAmaBioDocument,
	parseAmaBioDocument,
	createIntlBioDocument,
	parseIntlBioDocument
} from "_react/playerpage/bio/_helpers";

import {
	ROSEN_USER_ID,
	AMA_ADMIN_IAM_GROUP,
	INTL_SCOUTING_IAM_GROUP,
	RND_IAM_GROUP
} from "_react/playerpage/bio/_constants";
import { sortEvaluations } from "_react/playerpage/shared_js/evals/_helpers";
import { MAJOR, MINORS } from "_react/playerpage/_constants";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { TAmaBioDocument, TIntlBioDocument } from "_react/playerpage/bio/_types";
import { IManualPitchTypeMapBySeason } from "_react/shared/data_models/manual_pitch_type_map_byseason/_types";

// TODO: Remove this when we move over to combined player completely
type TPlayer = player_ama | player_intl | player_pro | $TSFixMe;

interface IPlayerPageContext {
	player: TPlayer | null;
	playerCombined: TPlayerPageCombinedPlayer | null;
	evals: $TSFixMeEval[] | null;
	notes: TRockyNote[] | null;
	schedules: { [index: string]: Array<$TSFixMe> } | null;
	viewClassification: string | null;
	canEditAmaBio: boolean;
	canEditIntlBio: boolean;
	bioDisplayOnly: boolean;
	isBioSaving: boolean;
	isNotesLoading: boolean;
	isNotesFailed: boolean;
	getSchedule: Function;
	getEvals(philId: number | string): Promise<$TSFixMeEval[] | void>;
	getNotes(playerId: number, cancelToken?: CancelToken): Promise<TRockyNote[] | void>;
	updateNotes: (newNotes: TRockyNote[]) => void;
	setDocumentTitle: Function;
	setViewClassification: (key: string, isReplaceState?: boolean) => void;
	setPlayer: Function;
	setPlayerCombined: Function;
	setBioDisplayOnly: (bioDisplayOnly: boolean) => void;
	setIsBioSaving: Function;
	setIsNotesLoading: Function;
	setIsNotesFailed: Function;
	getPlayerCombined(philId: number | string): Promise<TPlayerPageCombinedPlayer | void>;
	updatePlayer: Function;
	updatePlayerCombined: Function;
	amaBioDocument: TAmaBioDocument | null;
	setAmaBioDocument: (newDocument: TAmaBioDocument) => void;
	intlBioDocument: TIntlBioDocument | null;
	setIntlBioDocument: (newDocument: TIntlBioDocument) => void;
	missingIntlAgent: boolean;
	unsavedChanges: boolean;
	submitAmaBioUpdate: () => Promise<void>;
	submitIntlBioUpdate: () => Promise<void>;
	manualPitchTypeMapsBySeason: IManualPitchTypeMapBySeason[] | null;
	setManualPitchTypeMapsBySeason: (manualPitchTypeMapBySeason: IManualPitchTypeMapBySeason[] | null) => void;
}

const PlayerPageContext = createContext<IPlayerPageContext>({
	player: null,
	playerCombined: null,
	evals: null,
	notes: null,
	schedules: null,
	viewClassification: null,
	isNotesLoading: false,
	isNotesFailed: false,
	canEditAmaBio: false,
	canEditIntlBio: false,
	bioDisplayOnly: true,
	isBioSaving: false,
	getSchedule: () => Promise.resolve(),
	getEvals: () => Promise.resolve(),
	getNotes: () => Promise.resolve(),
	updateNotes: () => null,
	setDocumentTitle: () => null,
	setViewClassification: () => null,
	setPlayer: () => null,
	setPlayerCombined: () => null,
	setBioDisplayOnly: () => null,
	setIsBioSaving: () => null,
	setIsNotesLoading: () => null,
	setIsNotesFailed: () => null,
	getPlayerCombined: () => Promise.resolve(),
	updatePlayer: () => null,
	updatePlayerCombined: () => null,
	amaBioDocument: null,
	setAmaBioDocument: () => null,
	intlBioDocument: null,
	setIntlBioDocument: () => null,
	missingIntlAgent: false,
	unsavedChanges: false,
	submitAmaBioUpdate: () => Promise.resolve(),
	submitIntlBioUpdate: () => Promise.resolve(),
	manualPitchTypeMapsBySeason: null,
	setManualPitchTypeMapsBySeason: () => null
});

const PlayerPageProvider = (props: $TSFixMe) => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const toast = useToast();
	const [, setDocumentTitle] = useDocumentTitle(LOADING_TITLE);
	const [level, setLevel] = useQueryString("level");
	const [player, setPlayer] = useState<TPlayer | null>(null);
	const [playerCombined, setPlayerCombined] = useState<TPlayerPageCombinedPlayer | null>(null);
	const [viewClassification, setViewClassification] = useQueryString("viewClassification");
	const [schedules, setSchedules] = useState<{ [index: string]: Array<$TSFixMe> } | null>(null);
	const [evals, setEvals] = useState<$TSFixMeEval[] | null>(null);
	const [notes, setNotes] = useState<TRockyNote[] | null>(null);
	const [bioDisplayOnly, setBioDisplayOnly] = useState<boolean>(true);
	const [isBioSaving, setIsBioSaving] = useState<boolean>(false);
	const [isNotesLoading, setIsNotesLoading] = useState<boolean>(false);
	const [isNotesFailed, setIsNotesFailed] = useState<boolean>(false);
	const [amaBioDocument, setAmaBioDocumentInternal] = useState<TAmaBioDocument>(createAmaBioDocument(playerCombined));
	const [intlBioDocument, setIntlBioDocumentInternal] = useState<TIntlBioDocument>(
		createIntlBioDocument(playerCombined)
	);
	const [unsavedChanges, setUnsavedChanges] = useState(false);
	const setAmaBioDocument = useCallback(
		(newDocument: TAmaBioDocument) => {
			setAmaBioDocumentInternal(newDocument);
			setUnsavedChanges(parseAmaBioDocument(playerCombined, newDocument) != null);
		},
		[setAmaBioDocumentInternal, playerCombined]
	);
	const setIntlBioDocument = useCallback(
		(newDocument: TIntlBioDocument) => {
			setIntlBioDocumentInternal(newDocument);
			setUnsavedChanges(parseIntlBioDocument(playerCombined, newDocument) != null);
		},
		[setIntlBioDocumentInternal, playerCombined]
	);
	const [manualPitchTypeMapsBySeason, setManualPitchTypeMapsBySeason] = useState<
		IManualPitchTypeMapBySeason[] | null
	>(null);

	const userId = useUserId();

	// A player's assigned scout can edit their Ama Bio
	// Everyone in R&D + Ama Bio Admins can edit all Ama Bios
	// Rosen can edit all Ama Bios
	const canEditAmaBio =
		playerCombined?.scoutAssigned?.userId === userId ||
		userId === ROSEN_USER_ID ||
		useIsGroupMember([RND_IAM_GROUP, AMA_ADMIN_IAM_GROUP]);

	// A player's assigned scout can edit their Intl Bio
	// Everyone in R&D + Intl Scouts can edit all Intl Bios
	// Rosen can edit all Intl Bios
	const canEditIntlBio =
		playerCombined?.scoutAssigned?.userId === userId ||
		userId === ROSEN_USER_ID ||
		useIsGroupMember([RND_IAM_GROUP, INTL_SCOUTING_IAM_GROUP]);

	const missingIntlAgent = !bioDisplayOnly && !intlBioDocument?.hasOwnProperty("agentId"); // Checks whether INTL Agent is present

	useEffect(() => {
		if (playerCombined) {
			setDocumentTitle(
				`${PLAYER_PAGE} - ${playerCombined.firstName ?? playerCombined.firstNameLegal} ${
					playerCombined.lastName
				}`
			);
			const approvedContractMj = playerCombined.proProfile?.contractsMj?.find(
				(c: $TSFixMe) => c.status === "Approved"
			);
			const approvedContractMn = playerCombined.proProfile?.contractsMn?.find(
				(c: $TSFixMe) => c.status === "Approved"
			);
			if (level !== MAJOR && !approvedContractMj && approvedContractMn) setLevel(MINORS, true);
			if (!viewClassification) setViewClassification(playerCombined.playerClassification?.toLowerCase(), true);
			if (playerCombined.amaProfile) setAmaBioDocumentInternal(createAmaBioDocument(playerCombined));
			if (playerCombined.intlProfile) setIntlBioDocumentInternal(createIntlBioDocument(playerCombined));
		}
	}, [playerCombined, level, setDocumentTitle, setLevel, setViewClassification, viewClassification]);

	const getPlayerCombined = useCallback(
		async (philId: number | string): Promise<TPlayer> => {
			if (
				playerCombined &&
				(playerCombined.playerProId?.toString() === philId ||
					playerCombined.playerIntlId?.toString() === philId ||
					playerCombined.playerAmaId?.toString() === philId)
			) {
				return Promise.resolve(playerCombined);
			} else {
				try {
					setPlayerCombined(null);
					setPlayer(null);
					setEvals(null);
					setNotes(null);
					setSchedules(null);
					const response = await axios.get(`/player/${philId}?modelType=combined`);
					setPlayerCombined(response.data);
					setDocumentTitle(
						`${PLAYER_PAGE} - ${response.data.firstName ?? response.data.firstNameLegal} ${
							response.data.lastName
						}`
					);
					const approvedContractMj = response.data.proProfile?.contractsMj?.find(
						(c: $TSFixMe) => c.status === "Approved"
					);
					const approvedContractMn = response.data.proProfile?.contractsMn?.find(
						(c: $TSFixMe) => c.status === "Approved"
					);
					if (level !== MAJOR && !approvedContractMj && approvedContractMn) setLevel(MINORS, true);
					if (!viewClassification)
						setViewClassification(response.data.playerClassification?.toLowerCase(), true);
					if (response.data.amaProfile) setAmaBioDocumentInternal(createAmaBioDocument(response.data));
					if (response.data.intlProfile) setIntlBioDocumentInternal(createIntlBioDocument(response.data));
					return response.data;
				} catch (error) {
					console.log("Error:");
					console.log(error);
					return Promise.reject();
				}
			}
		},
		[level, playerCombined, setDocumentTitle, setLevel, viewClassification, setViewClassification]
	);

	const getSchedule = useCallback(
		async (teamId: number): Promise<$TSFixMe> => {
			if (schedules && schedules.hasOwnProperty(teamId)) {
				return Promise.resolve(schedules[teamId]);
			} else {
				setSchedules(null);
				try {
					const response = await axios.get(
						`/schedule/game/filter?teams=${teamId}&fromDate=${dayjs().format("YYYY-MM-DD")}`
					);
					setSchedules({
						...schedules,
						[teamId]: response.data.sort((a: $TSFixMe, b: $TSFixMe) => {
							const aCompare = a.datetime ?? a.date;
							const bCompare = b.datetime ?? b.date;
							if (aCompare > bCompare) return 1;
							else if (aCompare < bCompare) return -1;
							return 0;
						})
					});
					return response.data;
				} catch (error) {
					console.log("Error:");
					console.log(error);
					return Promise.reject();
				}
			}
		},
		[schedules]
	);

	const getEvals = useCallback(
		async (philId: number | string): Promise<$TSFixMeEval[]> => {
			setEvals(null);
			try {
				const response = await axios.get(`/evals?philId=${philId}&combinedPlayerEvals=True`);
				const responseFmt = response.data.map((e: $TSFixMe) =>
					convertDates(e, ["seen_date", "publish_date", "eval_create_date"])
				);
				setEvals(sortEvaluations(responseFmt));
				return response.data;
			} catch (error) {
				enqueueSnackbar("Failed fetching reports", { variant: "error" });
				console.log("Error:");
				console.log(error);
				setEvals(null);
				return Promise.reject();
			}
		},
		[enqueueSnackbar]
	);

	const getNotes = useCallback(
		async (playerId: number, cancelToken?: CancelToken): Promise<TRockyNote[]> => {
			setNotes(null);
			setIsNotesFailed(false);
			setIsNotesLoading(true);
			try {
				const response = await axios.get(`/notes?playerId=${playerId}`, { cancelToken });
				// TODO: Add formatting to response
				setNotes(response.data);
				setIsNotesLoading(false);
				return response.data;
			} catch (error) {
				setIsNotesLoading(false);
				setIsNotesFailed(true);
				enqueueSnackbar("Failed fetching notes", { variant: "error" });
				console.log("Error:");
				console.log(error);
				setNotes(null);
				return Promise.reject();
			}
		},
		[enqueueSnackbar]
	);

	const updateNotes = useCallback((newNotes: TRockyNote[]) => {
		setNotes(newNotes);
	}, []);

	const enqueueErrorSnackbar = usePersistentErrorSnackbar();

	const playerPageContext = useContext(NewPlayerPageContext);

	const providerValue = useMemo(() => {
		const updatePlayer = (updatedPlayer: TPlayer) => {
			setPlayer({ ...player, ...updatedPlayer });

			// When a bio is edited, propagate that to the new context
			playerPageContext.playerPageService.send({
				type: SET_PLAYER_LEGACY,
				value: { ...player, ...updatedPlayer }
			});
		};
		const updatePlayerCombined = (updatedPlayerCombined: TPlayerPageCombinedPlayer) => {
			setPlayerCombined({ ...playerCombined, ...updatedPlayerCombined });

			// When a bio is edited, propagate that to the new context
			playerPageContext.playerPageService.send({
				type: SET_PLAYER,
				value: { ...playerCombined, ...updatedPlayerCombined }
			});
		};

		const submitAmaBioUpdate = () => {
			const newPlayer = parseAmaBioDocument(playerCombined, amaBioDocument);
			if (newPlayer == null) return Promise.resolve();
			setIsBioSaving(true);
			toast({
				status: "warning",
				isClosable: false,
				position: "bottom-left",
				title: "Saving..."
			});

			return axios
				.put(`player/ama?nocache`, newPlayer)
				.then(response => {
					const newPlayerData = response.data.playerCombined as TPlayerPageCombinedPlayer;
					toast.closeAll();
					toast({
						status: "success",
						isClosable: true,
						duration: 2000,
						position: "bottom-left",
						title: "Saved"
					});
					updatePlayerCombined(newPlayerData);
					setAmaBioDocumentInternal(createAmaBioDocument(newPlayerData));
					setIsBioSaving(false);
					setUnsavedChanges(false);
				})
				.catch(() => {
					toast.closeAll();
					toast({
						...DEFAULT_TOAST_ERROR_PROPS,
						title: "Error Updating Player Bio",
						description: "Please Refresh the Page."
					});
					setIsBioSaving(false);
				});
		};

		const submitIntlBioUpdate = () => {
			const newPlayer = parseIntlBioDocument(playerCombined, intlBioDocument);
			if (newPlayer == null) return Promise.resolve();
			setIsBioSaving(true);
			const key = enqueueSnackbar("Saving...", { variant: "warning", persist: true });
			return axios
				.put(`player/intl?nocache`, newPlayer)
				.then(response => {
					const newPlayerData = response.data.playerCombined as TPlayerPageCombinedPlayer;
					closeSnackbar(key);
					updatePlayerCombined(newPlayerData);
					setIntlBioDocumentInternal(createIntlBioDocument(newPlayerData));
					setIsBioSaving(false);
					setUnsavedChanges(false);
				})
				.catch(() => {
					closeSnackbar(key);
					enqueueErrorSnackbar(`Error Updating Player Bio. Please Refresh the Page`);
					setIsBioSaving(false);
				});
		};

		return {
			player,
			playerCombined,
			evals,
			notes,
			schedules,
			viewClassification,
			canEditAmaBio,
			canEditIntlBio,
			bioDisplayOnly,
			isBioSaving,
			isNotesLoading,
			isNotesFailed,
			getSchedule,
			getEvals,
			getNotes,
			updateNotes,
			setDocumentTitle,
			setViewClassification,
			setPlayer,
			setPlayerCombined,
			setBioDisplayOnly,
			setIsBioSaving,
			setIsNotesLoading,
			setIsNotesFailed,
			getPlayerCombined,
			updatePlayer,
			updatePlayerCombined,
			submitAmaBioUpdate,
			amaBioDocument,
			setAmaBioDocument,
			submitIntlBioUpdate,
			intlBioDocument,
			setIntlBioDocument,
			missingIntlAgent,
			unsavedChanges,
			manualPitchTypeMapsBySeason,
			setManualPitchTypeMapsBySeason
		};
	}, [
		player,
		playerCombined,
		evals,
		notes,
		schedules,
		viewClassification,
		canEditAmaBio,
		canEditIntlBio,
		bioDisplayOnly,
		isBioSaving,
		isNotesLoading,
		isNotesFailed,
		getSchedule,
		getEvals,
		getNotes,
		updateNotes,
		getPlayerCombined,
		setDocumentTitle,
		setViewClassification,
		setBioDisplayOnly,
		setIsBioSaving,
		setIsNotesLoading,
		setIsNotesFailed,
		amaBioDocument,
		setAmaBioDocument,
		intlBioDocument,
		setIntlBioDocument,
		missingIntlAgent,
		unsavedChanges,
		manualPitchTypeMapsBySeason,
		closeSnackbar,
		enqueueErrorSnackbar,
		enqueueSnackbar,
		toast,
		playerPageContext.playerPageService
	]);

	return <PlayerPageContext.Provider value={providerValue}>{props.children}</PlayerPageContext.Provider>;
};

export { PlayerPageContext, PlayerPageProvider };
