export const HEADING_FONT_FAMILIES = `Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"`;
export const BODY_FONT_FAMILIES = HEADING_FONT_FAMILIES;
export const MONO_FONT_FAMILIES = `"Space Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace`;

const typography = {
	// Added our preferences to the Chakra defaults
	// Header & Body = Inter, Mono = Space Mono
	fonts: {
		heading: HEADING_FONT_FAMILIES,
		body: BODY_FONT_FAMILIES,
		mono: MONO_FONT_FAMILIES
	}
};

export default typography;
