import React, { useEffect, useMemo, useCallback, useState } from "react";
import { useMachine } from "@xstate/react";
import {
	useToast,
	VStack,
	HStack,
	Box,
	SystemStyleObject,
	IconButton,
	ButtonGroup,
	Menu,
	MenuButton,
	MenuList,
	MenuOptionGroup,
	MenuItemOption,
	Portal,
	RangeSlider,
	RangeSliderTrack,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	Tooltip,
	Spacer,
	Text,
	Flex
} from "@chakra-ui/react";

import CloseIcon from "_react/shared/legacy/ui/icons/Clear";
import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";
import FilterAlt from "_react/shared/ui/icons/FilterAlt";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import { TTableProps, TColumn } from "_react/shared/ui/presentation/components/Table/_types";
import { ASC, DESC } from "_react/shared/ui/presentation/components/Table/_constants";
import { IRAAPitcherByTeam, IRAAPitcherLkLevel } from "_react/shared/data_models/raa/_types";
import ToggleButton from "_react/shared/ui/presentation/components/ToggleButton/ToggleButton";
import { isDefaultFilters } from "_react/shared/ui/data/tables/shared/Filters";
import { getSeasonFilters, getMinAndMaxSeason, updateFilters } from "_react/shared/_helpers/stats";
import { VALID_PRO_LEVELS } from "_react/shared/data_models/stats/_constants";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";

import PitcherValueTableMachine, {
	TPitcherValueTableContext,
	FETCHING_PITCHER_VALUE,
	FETCHING_PITCHER_VALUE_BYTEAM,
	FETCHING_RATE_PITCHER_VALUE,
	FETCHING_RATE_PITCHER_VALUE_BYTEAM,
	SET_PITCHER_VALUE,
	SET_PITCHER_VALUE_BYTEAM,
	SET_RATE_PITCHER_VALUE,
	SET_RATE_PITCHER_VALUE_BYTEAM,
	SET_PLAYER_ID,
	SET_IS_EXPANDED_VALUES,
	SET_FILTERS,
	SET_DATA_SOURCE
} from "_react/shared/ui/data/tables/PitcherValueTable/_machine";

import {
	DEFAULT_NUM_SEASONS,
	PITCHER_VALUE_COLUMNS,
	PARENT_COLUMNS
} from "_react/shared/ui/data/tables/PitcherValueTable/_constants";
import {
	TPitcherValueTableData,
	TPitcherValueRow,
	TPitcherValueCombined,
	TPitcherValueTableDataDataSource,
	RAW,
	RATE
} from "_react/shared/ui/data/tables/PitcherValueTable/_types";
import { aggregateRows } from "_react/shared/ui/data/tables/PitcherValueTable/_helpers";

type TPitcherValueTableStyle = {
	container?: SystemStyleObject;
	tableContainer?: SystemStyleObject;
};

type TPitcherValueTableProps = {
	title?: string;
	playerId?: number;
	data?: TPitcherValueTableData;
	shouldFetchData?: boolean;
	isShowFilters?: boolean;
	tableProps?: TTableProps<TPitcherValueRow, keyof TPitcherValueRow>;
	style?: TPitcherValueTableStyle;
};

const PitcherValueTable = ({
	title,
	playerId,
	data,
	shouldFetchData = true,
	isShowFilters = true,
	tableProps,
	style
}: TPitcherValueTableProps) => {
	const toast = useToast();
	const [showSeasonRangeTooltip, setShowSeasonRangeTooltip] = useState(false);

	const [current, send] = useMachine(PitcherValueTableMachine(playerId, data, shouldFetchData, toast));
	const context = current.context;
	const {
		pitcherValue: rawPitcherValue,
		pitcherValueByTeam: rawPitcherValueByTeam,
		ratePitcherValue,
		ratePitcherValueByTeam,
		filters,
		dataSource,
		isExpandedValues
	} = context as TPitcherValueTableContext;

	// Select data source
	const selectedPitcherValue = dataSource === RAW ? rawPitcherValue : ratePitcherValue;
	const selectedPitcherValueByTeam = dataSource === RAW ? rawPitcherValueByTeam : ratePitcherValueByTeam;

	const isFetchingPitcherValue = current.matches(FETCHING_PITCHER_VALUE);
	const isFetchingPitcherValueByTeam = current.matches(FETCHING_PITCHER_VALUE_BYTEAM);
	const isFetchingRatePitcherValue = current.matches(FETCHING_RATE_PITCHER_VALUE);
	const isFetchingRatePitcherValueByTeam = current.matches(FETCHING_RATE_PITCHER_VALUE_BYTEAM);
	const isLoading = shouldFetchData
		? isFetchingPitcherValue ||
		  isFetchingPitcherValueByTeam ||
		  isFetchingRatePitcherValue ||
		  isFetchingRatePitcherValueByTeam
		: data?.isLoading;

	// Update machine context when props change
	useEffect(() => {
		send({ type: SET_PLAYER_ID, data: playerId });
	}, [playerId, send]);

	useEffect(() => {
		if (data?.pitcherValue !== rawPitcherValue && !shouldFetchData) {
			send({ type: SET_PITCHER_VALUE, data: data?.pitcherValue ?? undefined });
		}
	}, [data?.pitcherValue, shouldFetchData, send, rawPitcherValue]);

	useEffect(() => {
		if (data?.pitcherValueByTeam !== rawPitcherValueByTeam && !shouldFetchData) {
			send({ type: SET_PITCHER_VALUE_BYTEAM, data: data?.pitcherValueByTeam ?? undefined });
		}
	}, [data?.pitcherValueByTeam, shouldFetchData, send, rawPitcherValueByTeam]);

	useEffect(() => {
		if (data?.ratePitcherValue !== ratePitcherValue && !shouldFetchData) {
			send({ type: SET_RATE_PITCHER_VALUE, data: data?.ratePitcherValue ?? undefined });
		}
	}, [data?.ratePitcherValue, shouldFetchData, send, ratePitcherValue]);

	useEffect(() => {
		if (data?.ratePitcherValueByTeam !== ratePitcherValueByTeam && !shouldFetchData) {
			send({ type: SET_RATE_PITCHER_VALUE_BYTEAM, data: data?.ratePitcherValueByTeam ?? undefined });
		}
	}, [data?.ratePitcherValueByTeam, shouldFetchData, send, ratePitcherValueByTeam]);

	// Get max and min season
	const [minSeason, maxSeason] = useMemo(
		() => getMinAndMaxSeason<TPitcherValueCombined>(selectedPitcherValue ?? []),
		[selectedPitcherValue]
	);

	//
	// Season filter options
	//
	const seasonFilters: { minSeason: number; maxSeason: number } = useMemo(
		() => getSeasonFilters(filters.minSeason, filters.maxSeason, maxSeason, DEFAULT_NUM_SEASONS),
		[filters.minSeason, filters.maxSeason, maxSeason]
	);

	//
	// Level filter options
	//

	// Compute the level filter options
	const levelFilterOptions: Array<IRAAPitcherLkLevel> = useMemo(
		() =>
			selectedPitcherValueByTeam
				?.reduce((acc: Array<IRAAPitcherLkLevel>, curr: IRAAPitcherByTeam) => {
					const levelRel: IRAAPitcherLkLevel | undefined = curr.teamBam?.levelRel;
					if (
						levelRel &&
						levelRel.value &&
						!acc.some(val => val.value === levelRel.value) &&
						VALID_PRO_LEVELS.includes(levelRel.value)
					)
						acc.push(levelRel);
					return acc;
				}, [])
				?.sort(
					(a: IRAAPitcherLkLevel, b: IRAAPitcherLkLevel) =>
						(a.sortOrder ?? Number.MAX_SAFE_INTEGER) - (b.sortOrder ?? Number.MAX_SAFE_INTEGER)
				) ?? [],
		[selectedPitcherValueByTeam]
	);

	// Once the level filter options are computed for the first time, send them to the machine
	useEffect(() => {
		if (filters.levels === undefined && levelFilterOptions.length > 0) {
			const newFilters = {
				...filters,
				levels: levelFilterOptions.map((option: IRAAPitcherLkLevel) => option.value)
			};
			send({ type: SET_FILTERS, data: newFilters });
		}
	}, [filters, send, levelFilterOptions]);

	// Check for default filters
	const defaultFiltersSet: boolean = useMemo(() => {
		const availableLevels = levelFilterOptions.map((option: IRAAPitcherLkLevel) => option.value);

		return isDefaultFilters(filters, undefined, availableLevels, maxSeason, DEFAULT_NUM_SEASONS);
	}, [filters, levelFilterOptions, maxSeason]);

	const resetFilters = useCallback(() => {
		send({ type: SET_FILTERS, data: getSeasonFilters(undefined, undefined, maxSeason, DEFAULT_NUM_SEASONS) });
	}, [send, maxSeason]);

	const handleLevelSelect = (value: string) => {
		const newFilters = {
			...filters,
			levels: updateFilters(filters.levels ?? [], value)
		};
		send({ type: SET_FILTERS, data: newFilters });
	};

	// Table local state management
	const setisExpandedValues = (value: boolean) => {
		send({ type: SET_IS_EXPANDED_VALUES, data: value });
	};

	const setDataSource = (value: TPitcherValueTableDataDataSource) => {
		send({ type: SET_DATA_SOURCE, data: value });
	};

	// Group value by season
	const pitcherValueRows = useMemo(
		() =>
			aggregateRows(
				selectedPitcherValue,
				selectedPitcherValueByTeam,
				seasonFilters.minSeason,
				seasonFilters.maxSeason,
				filters
			),
		[selectedPitcherValue, selectedPitcherValueByTeam, seasonFilters.minSeason, seasonFilters.maxSeason, filters]
	);

	// Display the correct columns based on isExpandedValues
	const filteredColumns = useMemo(() => PITCHER_VALUE_COLUMNS(isExpandedValues), [isExpandedValues]);

	return (
		<VStack alignItems="start" sx={style?.container}>
			<HStack w="100%" justify="space-between">
				<HStack gap={1}>
					{title && (
						<Box fontFamily="heading" fontSize="md" fontWeight="bold">
							{title}
						</Box>
					)}
				</HStack>
				<HStack gap={1}>
					<ToggleButton<TPitcherValueTableDataDataSource>
						toggleOptions={[
							{ value: RATE, label: "Rate-Based" },
							{ value: RAW, label: "Raw" }
						]}
						value={dataSource}
						onSelect={setDataSource}
						isDisabled={isLoading}
					/>
					<ToggleButton<boolean>
						toggleOptions={[
							{ value: false, label: "Total RAA" },
							{ value: true, label: "Component RAA" }
						]}
						value={isExpandedValues}
						onSelect={setisExpandedValues}
						isDisabled={isLoading}
					/>
					{isShowFilters && (
						<Menu closeOnSelect={false} placement="left-start">
							<ButtonGroup
								isAttached
								variant={defaultFiltersSet ? "outline" : "solid"}
								colorScheme={defaultFiltersSet ? undefined : "blue"}
							>
								{!defaultFiltersSet && (
									<IconButton
										aria-label="Close"
										icon={<CloseIcon fill="white" />}
										onClick={resetFilters}
									/>
								)}
								<MenuButton
									as={IconButton}
									aria-label="Options"
									icon={<FilterAlt color={defaultFiltersSet ? "gray.500" : "white"} boxSize={5} />}
								>
									MenuItem
								</MenuButton>
							</ButtonGroup>
							<Portal>
								<MenuList minWidth="240px" maxHeight="md" overflow="scroll">
									<MenuOptionGroup title="Seasons">
										<VStack paddingLeft={4} paddingRight={4} sx={{ alignItems: "leading" }}>
											{minSeason === maxSeason && (
												<Tooltip
													hasArrow
													placement="top"
													label="Only one season of data exists"
												>
													<HStack>
														<OutlineInfo color="gray.500" />
														<Text>{minSeason}</Text>
													</HStack>
												</Tooltip>
											)}
											{minSeason !== maxSeason && (
												<VStack>
													<RangeSlider
														value={[seasonFilters.minSeason, seasonFilters.maxSeason]}
														min={minSeason}
														max={maxSeason}
														step={1}
														onChange={(seasons: number[]) => {
															send({
																type: SET_FILTERS,
																data: {
																	...filters,
																	minSeason: seasons[0],
																	maxSeason: seasons[1]
																}
															});
														}}
														onMouseEnter={() => setShowSeasonRangeTooltip(true)}
														onMouseLeave={() => setShowSeasonRangeTooltip(false)}
													>
														<RangeSliderTrack>
															<RangeSliderFilledTrack bg="black" />
														</RangeSliderTrack>
														<Tooltip
															hasArrow
															placement="top"
															isOpen={showSeasonRangeTooltip}
															label={seasonFilters.minSeason}
														>
															<RangeSliderThumb bg="gray.500" boxSize={3} index={0} />
														</Tooltip>
														<Tooltip
															hasArrow
															placement="top"
															isOpen={showSeasonRangeTooltip}
															label={seasonFilters.maxSeason}
														>
															<RangeSliderThumb bg="gray.500" boxSize={3} index={1} />
														</Tooltip>
													</RangeSlider>
													<Flex sx={{ width: "100%" }}>
														<Text fontSize="sm">{minSeason}</Text>
														<Spacer />
														<Text fontSize="sm">{maxSeason}</Text>
													</Flex>
												</VStack>
											)}
										</VStack>
									</MenuOptionGroup>
									<MenuOptionGroup
										title="Levels"
										type="checkbox"
										value={filters.levels ?? VALID_PRO_LEVELS}
									>
										{levelFilterOptions.map((option: IRAAPitcherLkLevel) => (
											<MenuItemOption
												value={option.value}
												onClick={() => handleLevelSelect(option.value)}
											>
												<TeamLevelBadge level={option.value} />
											</MenuItemOption>
										))}
									</MenuOptionGroup>
								</MenuList>
							</Portal>
						</Menu>
					)}
				</HStack>
			</HStack>
			<Box sx={style?.tableContainer}>
				<Table<TPitcherValueRow, keyof TPitcherValueRow>
					headerTooltipPlacement="bottom"
					columns={filteredColumns as Array<TColumn<TPitcherValueRow, keyof TPitcherValueRow>>}
					parentColumns={isExpandedValues ? PARENT_COLUMNS : undefined}
					data={pitcherValueRows}
					isLoadingData={isLoading}
					isExpandableRows
					emptyDataDisplayText={"No Pitcher Value"}
					defaultSortColumns={[
						{
							columnValue: "season",
							sortDirection: ASC
						},
						{
							columnValue: "level",
							sortDirection: DESC
						}
					]}
					getRowStyleFunction={(obj: TPitcherValueRow, index: number, data: Array<TPitcherValueRow>) => {
						if (
							index < data.length - 1 &&
							obj.pitcherValue.season !== data[index + 1].pitcherValue.season
						) {
							return {
								borderBottom: "1px solid !important",
								borderBottomColor: "gray.300 !important"
							};
						}
						return {};
					}}
					style={{ th: { textTransform: "none" }, parentTh: { textTransform: "none" } }}
					{...tableProps}
				/>
			</Box>
		</VStack>
	);
};

export default PitcherValueTable;
