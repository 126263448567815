import React from "react";
import { Circle, HStack, Text, Square } from "@chakra-ui/react";

import { PITCH_TYPES, PITCH_GROUP_OVERALL } from "_react/shared/_constants/pitch_types";
import Star from "_react/shared/ui/icons/Star";
import ThresholdIcon, {
	TGradeThresholdIconProps
} from "_react/shared/ui/presentation/components/GradeThresholdIcon/GradeThresholdIcon";
import {
	ICON_CIRCLE,
	ICON_SQUARE,
	ICON_STAR
} from "_react/shared/ui/presentation/components/PitchTypeLabel/_constants";
import {
	TPitchTypeLabelIconShape,
	TPitchTypeLabelStyle
} from "_react/shared/ui/presentation/components/PitchTypeLabel/_types";
import PitchTypeRetagIcon from "../PitchTypeRetagIcon/PitchTypeRetagIcon";

type TPitchTypeLabelProps = {
	label: string;
	abbreviation: string;
	shape: TPitchTypeLabelIconShape;
	thresholdIcon?: TGradeThresholdIconProps;
	isBold?: boolean;
	style?: TPitchTypeLabelStyle;
	pitchTypeRetagTooltip?: string | null;
};

const PitchTypeLabel = ({
	label,
	abbreviation,
	shape,
	thresholdIcon,
	isBold = false,
	style,
	pitchTypeRetagTooltip
}: TPitchTypeLabelProps) => {
	const shapeColor =
		(PITCH_TYPES as Array<string>).includes(abbreviation) || abbreviation === PITCH_GROUP_OVERALL
			? `color${abbreviation}`
			: `colorUnknownPitchType`;
	return (
		<HStack spacing={"2"} sx={style?.container}>
			{shape === ICON_CIRCLE && <Circle size="4" bg={shapeColor} sx={style?.icon}></Circle>}
			{shape === ICON_SQUARE && <Square size="4" bg={shapeColor} sx={style?.icon}></Square>}
			{shape === ICON_STAR && <Star boxSize="4" color={shapeColor} sx={style?.icon}></Star>}
			<HStack spacing={"1"}>
				{isBold ? (
					<Text fontWeight="bold" fontSize="md" sx={style?.label}>
						{label}
					</Text>
				) : (
					<Text fontSize="xs" sx={style?.label}>
						{label}
					</Text>
				)}
				{thresholdIcon && (
					<ThresholdIcon
						shape={thresholdIcon.shape}
						tooltipLabel={thresholdIcon.tooltipLabel}
						style={style?.thresholdIcon}
					></ThresholdIcon>
				)}
				{pitchTypeRetagTooltip && <PitchTypeRetagIcon tooltipLabel={pitchTypeRetagTooltip} />}
			</HStack>
		</HStack>
	);
};

export default PitchTypeLabel;
