import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import { IRAAPitcher, IRAAPitcherByTeam } from "_react/shared/data_models/raa/_types";
import { PLAYING_LEVEL_PRO, GAME_TYPE_REGULAR_SEASON } from "_react/shared/data_models/raa/_constants";
import {
	fetchPlayerSeasonRAAPitcher,
	fetchPlayerSeasonRAAPitcherByTeam,
	fetchPlayerSeasonRAARatePitcher,
	fetchPlayerSeasonRAARatePitcherByTeam
} from "_react/shared/data_models/raa/_network";
import {
	TPitcherValueTableData,
	TPitcherValueTableDataDataSource,
	RATE
} from "_react/shared/ui/data/tables/PitcherValueTable/_types";

export const PLAYER_SEASON_RAA_PITCHER_CANCEL_SOURCE = "playerSeasonRAAPitcher";
export const PLAYER_SEASON_RAA_PITCHER_BYTEAM_CANCEL_SOURCE = "playerSeasonRAAPitcherByTeam";
export const PLAYER_SEASON_RAA_RATE_PITCHER_CANCEL_SOURCE = "playerSeasonRAARatePitcher";
export const PLAYER_SEASON_RAA_RATE_PITCHER_BYTEAM_CANCEL_SOURCE = "playerSeasonRAARatePitcherByTeam";

export type TPitcherValueTableCancelSource = {
	[PLAYER_SEASON_RAA_PITCHER_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_RAA_PITCHER_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_RAA_RATE_PITCHER_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_RAA_RATE_PITCHER_BYTEAM_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TPitcherValueTableFilters = {
	levels?: Array<string>;
	maxSeason?: number;
	minSeason?: number;
};

export type TPitcherValueTableContext = {
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	filters: TPitcherValueTableFilters;
	pitcherValue?: Array<IRAAPitcher> | null;
	pitcherValueByTeam?: Array<IRAAPitcherByTeam> | null;
	ratePitcherValue?: Array<IRAAPitcher> | null;
	ratePitcherValueByTeam?: Array<IRAAPitcherByTeam> | null;
	dataSource: TPitcherValueTableDataDataSource;
	cancelSources: TPitcherValueTableCancelSource;
	isExpandedValues: boolean;
	toast?: CreateToastFnReturn;
};

interface IPitcherValueTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches all pitcher value by player season
				pitcherValue: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all pitcher value by player season team
				pitcherValueByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all rate pitcher value by player season
				ratePitcherValue: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches all rate pitcher value by player season team
				ratePitcherValueByTeam: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_PITCHER_VALUE = { initialized: { pitcherValue: "fetching" } };
export const FETCHING_PITCHER_VALUE_BYTEAM = { initialized: { pitcherValueByTeam: "fetching" } };
export const FETCHING_RATE_PITCHER_VALUE = { initialized: { ratePitcherValue: "fetching" } };
export const FETCHING_RATE_PITCHER_VALUE_BYTEAM = { initialized: { ratePitcherValueByTeam: "fetching" } };

const FETCH_PITCHER_VALUE_DONE = "done.invoke.fetchPitcherValue:invocation[0]";
const FETCH_PITCHER_VALUE_BYTEAM_DONE = "done.invoke.fetchPitcherValueByTeam:invocation[0]";
const FETCH_RATE_PITCHER_VALUE_DONE = "done.invoke.fetchRatePitcherValue:invocation[0]";
const FETCH_RATE_PITCHER_VALUE_BYTEAM_DONE = "done.invoke.fetchRatePitcherValueByTeam:invocation[0]";

const FETCH_PITCHER_VALUE_ERROR = "error.platform.fetchPitcherValue:invocation[0]";
const FETCH_PITCHER_VALUE_BYTEAM_ERROR = "error.platform.fetchPitcherValueByTeam:invocation[0]";
const FETCH_RATE_PITCHER_VALUE_ERROR = "error.platform.fetchRatePitcherValue:invocation[0]";
const FETCH_RATE_PITCHER_VALUE_BYTEAM_ERROR = "error.platform.fetchRatePitcherValueByTeam:invocation[0]";

export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PITCHER_VALUE = "SET_PITCHER_VALUE";
export const SET_PITCHER_VALUE_BYTEAM = "SET_PITCHER_VALUE_BYTEAM";
export const SET_RATE_PITCHER_VALUE = "SET_RATE_PITCHER_VALUE";
export const SET_RATE_PITCHER_VALUE_BYTEAM = "SET_RATE_PITCHER_VALUE_BYTEAM";
export const SET_IS_EXPANDED_VALUES = "SET_IS_EXPANDED_VALUES";
export const SET_FILTERS = "SET_FILTERS";
export const SET_DATA_SOURCE = "SET_DATA_SOURCE";

type TFetchPitcherValueEvent = {
	type: typeof FETCH_PITCHER_VALUE_DONE;
	data: Array<IRAAPitcher> | undefined;
};
type TFetchPitcherValueByTeamEvent = {
	type: typeof FETCH_PITCHER_VALUE_BYTEAM_DONE;
	data: Array<IRAAPitcherByTeam> | undefined;
};
type TFetchRatePitcherValueEvent = {
	type: typeof FETCH_RATE_PITCHER_VALUE_DONE;
	data: Array<IRAAPitcher> | undefined;
};
type TFetchRatePitcherValueByTeamEvent = {
	type: typeof FETCH_RATE_PITCHER_VALUE_BYTEAM_DONE;
	data: Array<IRAAPitcherByTeam> | undefined;
};
type TFetchPitcherValueErrorEvent = {
	type: typeof FETCH_PITCHER_VALUE_ERROR;
	data?: AxiosError | Error;
};
type TFetchPitcherValueByTeamErrorEvent = {
	type: typeof FETCH_PITCHER_VALUE_BYTEAM_ERROR;
	data?: AxiosError | Error;
};
type TFetchRatePitcherValueErrorEvent = {
	type: typeof FETCH_RATE_PITCHER_VALUE_ERROR;
	data?: AxiosError | Error;
};
type TFetchRatePitcherValueByTeamErrorEvent = {
	type: typeof FETCH_RATE_PITCHER_VALUE_BYTEAM_ERROR;
	data?: AxiosError | Error;
};
type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; data: number | undefined };
type TSetPitcherValueEvent = {
	type: typeof SET_PITCHER_VALUE;
	data?: Array<IRAAPitcher>;
};
type TSetPitcherValueByTeamEvent = {
	type: typeof SET_PITCHER_VALUE_BYTEAM;
	data?: Array<IRAAPitcherByTeam>;
};
type TSetRatePitcherValueEvent = {
	type: typeof SET_RATE_PITCHER_VALUE;
	data?: Array<IRAAPitcher>;
};
type TSetRatePitcherValueByTeamEvent = {
	type: typeof SET_RATE_PITCHER_VALUE_BYTEAM;
	data?: Array<IRAAPitcherByTeam>;
};

type TSetFiltersEvent = {
	type: typeof SET_FILTERS;
	data: TPitcherValueTableFilters;
};

type TSetIsExpandedValuesEvent = {
	type: typeof SET_IS_EXPANDED_VALUES;
	data: boolean;
};

type TSetDataSourceEvent = {
	type: typeof SET_DATA_SOURCE;
	data: TPitcherValueTableDataDataSource;
};

type TPitcherValueTableEvent =
	| TFetchPitcherValueEvent
	| TFetchPitcherValueByTeamEvent
	| TFetchRatePitcherValueEvent
	| TFetchRatePitcherValueByTeamEvent
	| TFetchPitcherValueErrorEvent
	| TFetchPitcherValueByTeamErrorEvent
	| TFetchRatePitcherValueErrorEvent
	| TFetchRatePitcherValueByTeamErrorEvent
	| TSetPlayerIdEvent
	| TSetPitcherValueEvent
	| TSetPitcherValueByTeamEvent
	| TSetRatePitcherValueEvent
	| TSetRatePitcherValueByTeamEvent
	| TSetFiltersEvent
	| TSetIsExpandedValuesEvent
	| TSetDataSourceEvent;

export type TPitcherValueTableSend = Interpreter<
	TPitcherValueTableContext,
	IPitcherValueTableStateSchema,
	TPitcherValueTableEvent
>["send"];

const PitcherValueTableMachine = (
	playerIdProp?: number,
	data?: TPitcherValueTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TPitcherValueTableContext, IPitcherValueTableStateSchema, TPitcherValueTableEvent>(
		{
			id: "pitcherValueTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: undefined,
				shouldFetchData: shouldFetchDataProp,
				filters: {
					levels: undefined,
					maxSeason: undefined,
					minSeason: undefined
				},
				pitcherValue: data?.pitcherValue,
				pitcherValueByTeam: data?.pitcherValueByTeam,
				ratePitcherValue: data?.ratePitcherValue,
				ratePitcherValueByTeam: data?.ratePitcherValueByTeam,
				dataSource: RATE,
				cancelSources: {},
				toast: toastProp,
				isExpandedValues: false
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						SET_PLAYER_ID: {
							actions: ["setPlayerId", "clearPitcherValue"],
							cond: "shouldSetPlayerId"
						},
						SET_PITCHER_VALUE: { actions: "setPitcherValue" },
						SET_PITCHER_VALUE_BYTEAM: { actions: "setPitcherValueByTeam" },
						SET_RATE_PITCHER_VALUE: { actions: "setRatePitcherValue" },
						SET_RATE_PITCHER_VALUE_BYTEAM: { actions: "setRatePitcherValueByTeam" },
						SET_IS_EXPANDED_VALUES: { actions: "setIsExpandedValues" },
						SET_FILTERS: { actions: "setFilters" },
						SET_DATA_SOURCE: { actions: "setDataSource" }
					},
					states: {
						pitcherValue: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPitcherValue",
												cond: "shouldFetchPitcherValue"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPitcherValue",
									entry: ["refreshPitcherValueCancelSource"],
									invoke: {
										src: "fetchPitcherValue",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPitcherValueSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPitcherValueErrored"
										}
									}
								}
							}
						},
						pitcherValueByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchPitcherValueByTeam",
												cond: "shouldFetchPitcherValueByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchPitcherValueByTeam",
									entry: ["refreshPitcherValueByTeamCancelSource"],
									invoke: {
										src: "fetchPitcherValueByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPitcherValueByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPitcherValueByTeamErrored"
										}
									}
								}
							}
						},
						ratePitcherValue: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchRatePitcherValue",
												cond: "shouldFetchRatePitcherValue"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchRatePitcherValue",
									entry: ["refreshRatePitcherValueCancelSource"],
									invoke: {
										src: "fetchRatePitcherValue",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchRatePitcherValueSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchRatePitcherValueErrored"
										}
									}
								}
							}
						},
						ratePitcherValueByTeam: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchRatePitcherValueByTeam",
												cond: "shouldFetchRatePitcherValueByTeam"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchRatePitcherValueByTeam",
									entry: ["refreshRatePitcherValueByTeamCancelSource"],
									invoke: {
										src: "fetchRatePitcherValueByTeam",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchRatePitcherValueByTeamSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchRatePitcherValueByTeamErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (context: TPitcherValueTableContext, event: TPitcherValueTableEvent) =>
					event.type === SET_PLAYER_ID && context.playerId !== event.data,
				shouldFetchPitcherValue: (context: TPitcherValueTableContext, _event: TPitcherValueTableEvent) =>
					context.pitcherValue === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchPitcherValueByTeam: (context: TPitcherValueTableContext, _event: TPitcherValueTableEvent) =>
					context.pitcherValueByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchRatePitcherValue: (context: TPitcherValueTableContext, _event: TPitcherValueTableEvent) =>
					context.ratePitcherValue === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true,
				shouldFetchRatePitcherValueByTeam: (
					context: TPitcherValueTableContext,
					_event: TPitcherValueTableEvent
				) =>
					context.ratePitcherValueByTeam === undefined &&
					context.playerId !== undefined &&
					context.shouldFetchData === true
			},
			actions: {
				clearPitcherValue: assign<TPitcherValueTableContext, TPitcherValueTableEvent>({
					pitcherValue: (_context: TPitcherValueTableContext, _event: TPitcherValueTableEvent) => undefined,
					pitcherValueByTeam: (_context: TPitcherValueTableContext, _event: TPitcherValueTableEvent) =>
						undefined,
					ratePitcherValue: (_context: TPitcherValueTableContext, _event: TPitcherValueTableEvent) =>
						undefined,
					ratePitcherValueByTeam: (_context: TPitcherValueTableContext, _event: TPitcherValueTableEvent) =>
						undefined,
					cancelSources: (context: TPitcherValueTableContext, _event: TPitcherValueTableEvent) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				// Set Context Actions
				setPlayerId: assign<TPitcherValueTableContext, TPitcherValueTableEvent>({
					playerId: (context: TPitcherValueTableContext, event: TPitcherValueTableEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					},
					cancelSources: (context: TPitcherValueTableContext, _event: TPitcherValueTableEvent) => {
						Object.entries(context.cancelSources).forEach((cancelSource: [string, CancelTokenSource]) =>
							cancelSource[1]?.cancel()
						);
						return {};
					}
				}),
				setPitcherValue: assign<TPitcherValueTableContext, TPitcherValueTableEvent>({
					pitcherValue: (context: TPitcherValueTableContext, event: TPitcherValueTableEvent) => {
						if (event.type !== SET_PITCHER_VALUE) return context.pitcherValue;
						return event.data;
					},
					cancelSources: (context: TPitcherValueTableContext, event: TPitcherValueTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_PITCHER_VALUE) return cancelSources;
						cancelSources[PLAYER_SEASON_RAA_PITCHER_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_RAA_PITCHER_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setPitcherValueByTeam: assign<TPitcherValueTableContext, TPitcherValueTableEvent>({
					pitcherValueByTeam: (context: TPitcherValueTableContext, event: TPitcherValueTableEvent) => {
						if (event.type !== SET_PITCHER_VALUE_BYTEAM) return context.pitcherValueByTeam;
						return event.data;
					},
					cancelSources: (context: TPitcherValueTableContext, event: TPitcherValueTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_PITCHER_VALUE_BYTEAM) return cancelSources;
						cancelSources[PLAYER_SEASON_RAA_PITCHER_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_RAA_PITCHER_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setRatePitcherValue: assign<TPitcherValueTableContext, TPitcherValueTableEvent>({
					ratePitcherValue: (context: TPitcherValueTableContext, event: TPitcherValueTableEvent) => {
						if (event.type !== SET_RATE_PITCHER_VALUE) return context.ratePitcherValue;
						return event.data;
					},
					cancelSources: (context: TPitcherValueTableContext, event: TPitcherValueTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_RATE_PITCHER_VALUE) return cancelSources;
						cancelSources[PLAYER_SEASON_RAA_RATE_PITCHER_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_RAA_RATE_PITCHER_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setRatePitcherValueByTeam: assign<TPitcherValueTableContext, TPitcherValueTableEvent>({
					ratePitcherValueByTeam: (context: TPitcherValueTableContext, event: TPitcherValueTableEvent) => {
						if (event.type !== SET_RATE_PITCHER_VALUE_BYTEAM) return context.ratePitcherValueByTeam;
						return event.data;
					},
					cancelSources: (context: TPitcherValueTableContext, event: TPitcherValueTableEvent) => {
						const { cancelSources } = context;
						if (event.type !== SET_RATE_PITCHER_VALUE_BYTEAM) return cancelSources;
						cancelSources[PLAYER_SEASON_RAA_RATE_PITCHER_BYTEAM_CANCEL_SOURCE]?.cancel();
						cancelSources[PLAYER_SEASON_RAA_RATE_PITCHER_BYTEAM_CANCEL_SOURCE] = undefined;
						return cancelSources;
					}
				}),
				setFilters: assign<TPitcherValueTableContext, TPitcherValueTableEvent>({
					filters: (context: TPitcherValueTableContext, event: TPitcherValueTableEvent) => {
						if (event.type !== SET_FILTERS) return context.filters;
						return event.data;
					}
				}),
				setIsExpandedValues: assign<TPitcherValueTableContext, TPitcherValueTableEvent>({
					isExpandedValues: (context: TPitcherValueTableContext, event: TPitcherValueTableEvent) => {
						if (event.type !== SET_IS_EXPANDED_VALUES) return context.isExpandedValues;
						return event.data;
					}
				}),
				setDataSource: assign<TPitcherValueTableContext, TPitcherValueTableEvent>({
					dataSource: (context: TPitcherValueTableContext, event: TPitcherValueTableEvent) => {
						if (event.type !== SET_DATA_SOURCE) return context.dataSource;
						return event.data;
					}
				}),
				// Cancel Source Actions
				refreshPitcherValueCancelSource: assign<TPitcherValueTableContext, TPitcherValueTableEvent>({
					cancelSources: (context: TPitcherValueTableContext, _event: TPitcherValueTableEvent) => {
						if (context.cancelSources[PLAYER_SEASON_RAA_PITCHER_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_RAA_PITCHER_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_RAA_PITCHER_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPitcherValueByTeamCancelSource: assign<TPitcherValueTableContext, TPitcherValueTableEvent>({
					cancelSources: (context: TPitcherValueTableContext, _event: TPitcherValueTableEvent) => {
						if (context.cancelSources[PLAYER_SEASON_RAA_PITCHER_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_RAA_PITCHER_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_RAA_PITCHER_BYTEAM_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshRatePitcherValueCancelSource: assign<TPitcherValueTableContext, TPitcherValueTableEvent>({
					cancelSources: (context: TPitcherValueTableContext, _event: TPitcherValueTableEvent) => {
						if (context.cancelSources[PLAYER_SEASON_RAA_RATE_PITCHER_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_RAA_RATE_PITCHER_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_RAA_RATE_PITCHER_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshRatePitcherValueByTeamCancelSource: assign<TPitcherValueTableContext, TPitcherValueTableEvent>({
					cancelSources: (context: TPitcherValueTableContext, _event: TPitcherValueTableEvent) => {
						if (context.cancelSources[PLAYER_SEASON_RAA_RATE_PITCHER_BYTEAM_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_RAA_RATE_PITCHER_BYTEAM_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_RAA_RATE_PITCHER_BYTEAM_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchPitcherValueSuccess: assign<TPitcherValueTableContext, TPitcherValueTableEvent>({
					pitcherValue: (context: TPitcherValueTableContext, event: TPitcherValueTableEvent) => {
						if (event.type !== FETCH_PITCHER_VALUE_DONE) return context.pitcherValue;
						return event.data;
					}
				}),
				handleFetchPitcherValueByTeamSuccess: assign<TPitcherValueTableContext, TPitcherValueTableEvent>({
					pitcherValueByTeam: (context: TPitcherValueTableContext, event: TPitcherValueTableEvent) => {
						if (event.type !== FETCH_PITCHER_VALUE_BYTEAM_DONE) return context.pitcherValueByTeam;
						return event.data;
					}
				}),
				handleFetchRatePitcherValueSuccess: assign<TPitcherValueTableContext, TPitcherValueTableEvent>({
					ratePitcherValue: (context: TPitcherValueTableContext, event: TPitcherValueTableEvent) => {
						if (event.type !== FETCH_RATE_PITCHER_VALUE_DONE) return context.ratePitcherValue;
						return event.data;
					}
				}),
				handleFetchRatePitcherValueByTeamSuccess: assign<TPitcherValueTableContext, TPitcherValueTableEvent>({
					ratePitcherValueByTeam: (context: TPitcherValueTableContext, event: TPitcherValueTableEvent) => {
						if (event.type !== FETCH_RATE_PITCHER_VALUE_BYTEAM_DONE) return context.ratePitcherValueByTeam;
						return event.data;
					}
				}),
				// Fetch Error Actions
				handleFetchPitcherValueErrored: (
					context: TPitcherValueTableContext,
					event: TPitcherValueTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PITCHER_VALUE_ERROR ? event.data : undefined,
						context.toast,
						"Pitcher Value - Player Season RAA Pitcher",
						"Error fetching pitcher value by player and season."
					);
				},
				handleFetchPitcherValueByTeamErrored: (
					context: TPitcherValueTableContext,
					event: TPitcherValueTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PITCHER_VALUE_BYTEAM_ERROR ? event.data : undefined,
						context.toast,
						"Pitcher Value - Player Season RAA Pitcher By Team",
						"Error fetching pitcher value by team and season."
					);
				},
				handleFetchRatePitcherValueErrored: (
					context: TPitcherValueTableContext,
					event: TPitcherValueTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_RATE_PITCHER_VALUE_ERROR ? event.data : undefined,
						context.toast,
						"Pitcher Value - Player Season RAA Rate Pitcher",
						"Error fetching rate pitcher value by player and season."
					);
				},
				handleFetchRatePitcherValueByTeamErrored: (
					context: TPitcherValueTableContext,
					event: TPitcherValueTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_RATE_PITCHER_VALUE_BYTEAM_ERROR ? event.data : undefined,
						context.toast,
						"Pitcher Value - Player Season RAA Rate Pitcher By Team",
						"Error fetching rate pitcher value by team and season."
					);
				}
			},
			services: {
				fetchPitcherValue: (context: TPitcherValueTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonRAAPitcher(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_PRO,
								gameType: GAME_TYPE_REGULAR_SEASON,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_RAA_PITCHER_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPitcherValueByTeam: (context: TPitcherValueTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonRAAPitcherByTeam(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_PRO,
								gameType: GAME_TYPE_REGULAR_SEASON,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_RAA_PITCHER_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchRatePitcherValue: (context: TPitcherValueTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonRAARatePitcher(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_PRO,
								gameType: GAME_TYPE_REGULAR_SEASON,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_RAA_RATE_PITCHER_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchRatePitcherValueByTeam: (context: TPitcherValueTableContext, _event: AnyEventObject) => {
					const { playerId } = context;
					if (!playerId) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchPlayerSeasonRAARatePitcherByTeam(
							{
								playerId: playerId,
								playingLevel: PLAYING_LEVEL_PRO,
								gameType: GAME_TYPE_REGULAR_SEASON,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_RAA_RATE_PITCHER_BYTEAM_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PitcherValueTableMachine;
