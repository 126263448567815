import { Machine, assign, AnyEventObject, Interpreter } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";
import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";
import { DEFAULT_TOAST_SUCCESS_PROPS } from "_react/shared/_constants/toast";
import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import {
	deleteManualPitchTypeMapBySeason,
	fetchManualPitchTypeMapsBySeason,
	postManualPitchTypeMapBySeason,
	putManualPitchTypeMapBySeason,
	TManualPitchTypeMapBySeasonDeleteQueryParams
} from "_react/shared/data_models/manual_pitch_type_map_byseason/_network";
import {
	IManualPitchTypeMapBySeason,
	IManualPitchTypeMapBySeasonApiResponse
} from "_react/shared/data_models/manual_pitch_type_map_byseason/_types";
import { TManualPitchTypeMapBySeasonData } from "_react/shared/ui/data/modals/ManualPitchTypeMapBySeasonModal/ManualPitchTypeMapBySeasonModal";

const MANUAL_PITCH_TYPE_MAP_BYSEASON_CANCEL_SOURCE = "manualPitchTypeMapBySeason";

export type TManualPitchTypeMapBySeasonCancelSource = {
	[MANUAL_PITCH_TYPE_MAP_BYSEASON_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TManualPitchTypeMapsBySeasonContext = {
	playerId: number;
	manualPitchTypeMapsBySeason?: Array<IManualPitchTypeMapBySeasonApiResponse> | null;
	cancelSources: TManualPitchTypeMapBySeasonCancelSource;
	toast?: CreateToastFnReturn;
};

interface IManualPitchTypeMapsBySeasonStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches the manual pitch type map by season data
				manualPitchTypeMapsBySeason: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
						posting: {};
						updating: {};
						deleting: {};
					};
				};
			};
		};
	};
}

export const SET_MANUAL_PITCH_TYPE_MAPS_BYSEASON = "SET_MANUAL_PITCH_TYPE_MAPS_BYSEASON";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const POST_MANUAL_PITCH_TYPE_MAP_BYSEASON = "POST_MANUAL_PITCH_TYPE_MAP_BYSEASON";
export const UPDATE_MANUAL_PITCH_TYPE_MAP_BYSEASON = "UPDATE_MANUAL_PITCH_TYPE_MAP_BYSEASON";
export const DELETE_MANUAL_PITCH_TYPE_MAP_BYSEASON = "DELETE_MANUAL_PITCH_TYPE_MAP_BYSEASON";

export const FETCHING_MANUAL_PITCH_TYPE_MAPS_BYSEASON = { initialized: { manualPitchTypeMapsBySeason: "fetching" } };
export const POSTING_MANUAL_PITCH_TYPE_MAP_BYSEASON = { initialized: { manualPitchTypeMapsBySeason: "posting" } };
export const UPDATING_MANUAL_PITCH_TYPE_MAP_BYSEASON = { initialized: { manualPitchTypeMapsBySeason: "updating" } };
export const DELETING_MANUAL_PITCH_TYPE_MAP_BYSEASON = { initialized: { manualPitchTypeMapsBySeason: "deleting" } };

const FETCH_MANUAL_PITCH_TYPE_MAPS_BYSEASON_DONE = "done.invoke.fetchingManualPitchTypeMapsBySeason:invocation[0]";
const POST_MANUAL_PITCH_TYPE_MAP_BYSEASON_DONE = "done.invoke.postingManualPitchTypeMapBySeason:invocation[0]";
const UPDATE_MANUAL_PITCH_TYPE_MAP_BYSEASON_DONE = "done.invoke.updatingManualPitchTypeMapBySeason:invocation[0]";
const DELETE_MANUAL_PITCH_TYPE_MAP_BYSEASON_DONE = "done.invoke.deletingManualPitchTypeMapBySeason:invocation[0]";

const FETCH_MANUAL_PITCH_TYPE_MAPS_BYSEASON_ERROR = "error.platform.fetchingManualPitchTypeMapsBySeason:invocation[0]";
const POST_MANUAL_PITCH_TYPE_MAP_BYSEASON_ERROR = "error.platform.postingManualPitchTypeMapBySeason:invocation[0]";
const UPDATE_MANUAL_PITCH_TYPE_MAP_BYSEASON_ERROR = "error.platform.updatingManualPitchTypeMapBySeason:invocation[0]";
const DELETE_MANUAL_PITCH_TYPE_MAP_BYSEASON_ERROR = "error.platform.deletingManualPitchTypeMapBySeason:invocation[0]";

type TSetManualPitchTypeMapsBySeasonEvent = {
	type: typeof SET_MANUAL_PITCH_TYPE_MAPS_BYSEASON;
	data: IManualPitchTypeMapBySeasonApiResponse[] | null | undefined;
};
type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number;
};
type TPostManualPitchTypeMapBySeasonEvent = {
	type: typeof POST_MANUAL_PITCH_TYPE_MAP_BYSEASON;
	data?: IManualPitchTypeMapBySeason[] | undefined;
	callback?: () => void;
};
type TUpdateManualPitchTypeMapBySeasonEvent = {
	type: typeof UPDATE_MANUAL_PITCH_TYPE_MAP_BYSEASON;
	data?: IManualPitchTypeMapBySeason | undefined;
	callback?: () => void;
};
type TDeleteManualPitchTypeMapBySeasonEvent = {
	type: typeof DELETE_MANUAL_PITCH_TYPE_MAP_BYSEASON;
	value: TManualPitchTypeMapBySeasonDeleteQueryParams;
};
type TFetchManualPitchTypeMapsBySeasonEventDone = {
	type: typeof FETCH_MANUAL_PITCH_TYPE_MAPS_BYSEASON_DONE;
	data?: IManualPitchTypeMapBySeasonApiResponse[] | undefined;
};
type TPostManualPitchTypeMapBySeasonEventDone = {
	type: typeof POST_MANUAL_PITCH_TYPE_MAP_BYSEASON_DONE;
	data?: IManualPitchTypeMapBySeason | undefined;
};
type TUpdateManualPitchTypeMapBySeasonEventDone = {
	type: typeof UPDATE_MANUAL_PITCH_TYPE_MAP_BYSEASON_DONE;
	data: IManualPitchTypeMapBySeason | undefined;
};
type TDeleteManualPitchTypeMapBySeasonEventDone = {
	type: typeof DELETE_MANUAL_PITCH_TYPE_MAP_BYSEASON_DONE;
};
type TFetchManualPitchTypeMapsBySeasonEventError = {
	type: typeof FETCH_MANUAL_PITCH_TYPE_MAPS_BYSEASON_ERROR;
	data?: AxiosError | Error;
};
type TPostManualPitchTypeMapBySeasonEventError = {
	type: typeof POST_MANUAL_PITCH_TYPE_MAP_BYSEASON_ERROR;
	data?: AxiosError | Error;
};
type TUpdateManualPitchTypeMapBySeasonEventError = {
	type: typeof UPDATE_MANUAL_PITCH_TYPE_MAP_BYSEASON_ERROR;
	data?: AxiosError | Error;
};
type TDeleteManualPitchTypeMapBySeasonEventError = {
	type: typeof DELETE_MANUAL_PITCH_TYPE_MAP_BYSEASON_ERROR;
	data?: AxiosError | Error;
};

type TManualPitchTypeMapBySeasonEvent =
	| TSetManualPitchTypeMapsBySeasonEvent
	| TSetPlayerIdEvent
	| TPostManualPitchTypeMapBySeasonEvent
	| TUpdateManualPitchTypeMapBySeasonEvent
	| TDeleteManualPitchTypeMapBySeasonEvent
	| TFetchManualPitchTypeMapsBySeasonEventDone
	| TPostManualPitchTypeMapBySeasonEventDone
	| TUpdateManualPitchTypeMapBySeasonEventDone
	| TDeleteManualPitchTypeMapBySeasonEventDone
	| TFetchManualPitchTypeMapsBySeasonEventError
	| TPostManualPitchTypeMapBySeasonEventError
	| TUpdateManualPitchTypeMapBySeasonEventError
	| TDeleteManualPitchTypeMapBySeasonEventError;

export type TManualPitchTypeMapBySeasonSend = Interpreter<
	TManualPitchTypeMapsBySeasonContext,
	IManualPitchTypeMapsBySeasonStateSchema,
	TManualPitchTypeMapBySeasonEvent
>["send"];

const ManualPitchTypeMapBySeasonMachine = (
	playerIdProp: number,
	data?: TManualPitchTypeMapBySeasonData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<
		TManualPitchTypeMapsBySeasonContext,
		IManualPitchTypeMapsBySeasonStateSchema,
		TManualPitchTypeMapBySeasonEvent
	>(
		{
			id: "ManualPitchTypeMapsBySeason",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				manualPitchTypeMapsBySeason: data?.manualPitchTypeMapsBySeason,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: "initialized"
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_MANUAL_PITCH_TYPE_MAPS_BYSEASON]: { actions: "setManualPitchTypeMapsBySeason" },
						[SET_PLAYER_ID]: {
							actions: ["setPlayerId", "clearManualPitchTypeMapsBySeason"],
							cond: "shouldSetPlayerId"
						}
					},
					states: {
						manualPitchTypeMapsBySeason: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									on: {
										[UPDATE_MANUAL_PITCH_TYPE_MAP_BYSEASON]: {
											target: "#updatingManualPitchTypeMapBySeason",
											cond: "shouldUpdateManualPitchTypeMapBySeason"
										},

										[POST_MANUAL_PITCH_TYPE_MAP_BYSEASON]: {
											target: "#postingManualPitchTypeMapBySeason"
										},
										[DELETE_MANUAL_PITCH_TYPE_MAP_BYSEASON]: {
											target: "#deletingManualPitchTypeMapBySeason"
										}
									},
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingManualPitchTypeMapsBySeason",
												cond: "shouldFetchManualPitchTypeMapsBySeason"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingManualPitchTypeMapsBySeason",
									entry: ["refreshManualPitchTypeMapBySeasonCancelSource"],
									invoke: {
										src: "fetchManualPitchTypeMapsBySeason",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchManualPitchTypeMapsBySeasonSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchManualPitchTypeMapsBySeasonErrored"
										}
									}
								},
								posting: {
									id: "postingManualPitchTypeMapBySeason",
									entry: ["refreshManualPitchTypeMapBySeasonCancelSource"],
									invoke: {
										src: "postManualPitchTypeMapBySeason",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handlePostManualPitchTypeMapBySeasonSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handlePostManualPitchTypeMapBySeasonErrored"
										}
									}
								},
								updating: {
									id: "updatingManualPitchTypeMapBySeason",
									entry: ["refreshManualPitchTypeMapBySeasonCancelSource"],
									invoke: {
										src: "updateManualPitchTypeMapBySeason",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleUpdateManualPitchTypeMapBySeasonSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleUpdateManualPitchTypeMapBySeasonErrored"
										}
									}
								},
								deleting: {
									id: "deletingManualPitchTypeMapBySeason",
									entry: ["refreshManualPitchTypeMapBySeasonCancelSource"],
									invoke: {
										src: "deleteManualPitchTypeMapBySeason",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleDeleteManualPitchTypeMapBySeasonSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleDeleteManualPitchTypeMapBySeasonErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (
					context: TManualPitchTypeMapsBySeasonContext,
					event: TManualPitchTypeMapBySeasonEvent
				) => event.type === SET_PLAYER_ID && context.playerId !== event.data,
				shouldFetchManualPitchTypeMapsBySeason: (
					context: TManualPitchTypeMapsBySeasonContext,
					_event: TManualPitchTypeMapBySeasonEvent
				) => context.manualPitchTypeMapsBySeason === undefined && context.playerId !== undefined,
				shouldUpdateManualPitchTypeMapBySeason: (
					context: TManualPitchTypeMapsBySeasonContext,
					_event: TManualPitchTypeMapBySeasonEvent
				) => context.manualPitchTypeMapsBySeason != null
			},
			actions: {
				setManualPitchTypeMapsBySeason: assign<
					TManualPitchTypeMapsBySeasonContext,
					TManualPitchTypeMapBySeasonEvent
				>({
					manualPitchTypeMapsBySeason: (
						context: TManualPitchTypeMapsBySeasonContext,
						event: TManualPitchTypeMapBySeasonEvent
					) => {
						if (event.type !== SET_MANUAL_PITCH_TYPE_MAPS_BYSEASON)
							return context.manualPitchTypeMapsBySeason;
						return event.data;
					},
					cancelSources: (
						context: TManualPitchTypeMapsBySeasonContext,
						event: TManualPitchTypeMapBySeasonEvent
					) => {
						if (event.type !== SET_MANUAL_PITCH_TYPE_MAPS_BYSEASON) return context.cancelSources;
						if (context.cancelSources[MANUAL_PITCH_TYPE_MAP_BYSEASON_CANCEL_SOURCE] != null)
							context.cancelSources[MANUAL_PITCH_TYPE_MAP_BYSEASON_CANCEL_SOURCE].cancel();
						delete context.cancelSources[MANUAL_PITCH_TYPE_MAP_BYSEASON_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerId: assign<TManualPitchTypeMapsBySeasonContext, TManualPitchTypeMapBySeasonEvent>({
					playerId: (
						context: TManualPitchTypeMapsBySeasonContext,
						event: TManualPitchTypeMapBySeasonEvent
					) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				// Clear Actions
				clearManualPitchTypeMapsBySeason: assign<
					TManualPitchTypeMapsBySeasonContext,
					TManualPitchTypeMapBySeasonEvent
				>({
					manualPitchTypeMapsBySeason: (
						_context: TManualPitchTypeMapsBySeasonContext,
						_event: TManualPitchTypeMapBySeasonEvent
					) => undefined,
					cancelSources: (
						context: TManualPitchTypeMapsBySeasonContext,
						_event: TManualPitchTypeMapBySeasonEvent
					) => {
						if (context.cancelSources[MANUAL_PITCH_TYPE_MAP_BYSEASON_CANCEL_SOURCE] != null) {
							context.cancelSources[MANUAL_PITCH_TYPE_MAP_BYSEASON_CANCEL_SOURCE].cancel();
							delete context.cancelSources[MANUAL_PITCH_TYPE_MAP_BYSEASON_CANCEL_SOURCE];
						}
						return context.cancelSources;
					}
				}),
				// Cancel Source Actions
				refreshManualPitchTypeMapBySeasonCancelSource: assign<
					TManualPitchTypeMapsBySeasonContext,
					TManualPitchTypeMapBySeasonEvent
				>({
					cancelSources: (
						context: TManualPitchTypeMapsBySeasonContext,
						_event: TManualPitchTypeMapBySeasonEvent
					) => {
						if (context.cancelSources[MANUAL_PITCH_TYPE_MAP_BYSEASON_CANCEL_SOURCE] != null)
							context.cancelSources[MANUAL_PITCH_TYPE_MAP_BYSEASON_CANCEL_SOURCE].cancel();
						context.cancelSources[MANUAL_PITCH_TYPE_MAP_BYSEASON_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchManualPitchTypeMapsBySeasonSuccess: assign<
					TManualPitchTypeMapsBySeasonContext,
					TManualPitchTypeMapBySeasonEvent
				>({
					manualPitchTypeMapsBySeason: (
						context: TManualPitchTypeMapsBySeasonContext,
						event: TManualPitchTypeMapBySeasonEvent
					) => {
						if (event.type !== FETCH_MANUAL_PITCH_TYPE_MAPS_BYSEASON_DONE)
							return context.manualPitchTypeMapsBySeason;
						return event.data;
					}
				}),
				// Handle Post success
				handlePostManualPitchTypeMapBySeasonSuccess: assign<
					TManualPitchTypeMapsBySeasonContext,
					TManualPitchTypeMapBySeasonEvent
				>({
					manualPitchTypeMapsBySeason: (
						context: TManualPitchTypeMapsBySeasonContext,
						event: TManualPitchTypeMapBySeasonEvent
					) => {
						if (event.type !== POST_MANUAL_PITCH_TYPE_MAP_BYSEASON_DONE) {
							return context.manualPitchTypeMapsBySeason;
						}
						if (
							event.data != null &&
							Array.isArray(event.data) &&
							context.manualPitchTypeMapsBySeason != null
						) {
							if (context.toast)
								context.toast({
									...DEFAULT_TOAST_SUCCESS_PROPS,
									title: "Manual Pitch Type Map By Season",
									description: "Pitch type retag added. Changes will be available in a few hours."
								});
							const updatedList = [...event.data, ...context.manualPitchTypeMapsBySeason];

							// Sort by season
							updatedList.sort((a, b) => b.season - a.season);

							return updatedList;
						}
						return context.manualPitchTypeMapsBySeason;
					}
				}),
				handleUpdateManualPitchTypeMapBySeasonSuccess: assign<
					TManualPitchTypeMapsBySeasonContext,
					TManualPitchTypeMapBySeasonEvent
				>({
					manualPitchTypeMapsBySeason: (
						context: TManualPitchTypeMapsBySeasonContext,
						event: TManualPitchTypeMapBySeasonEvent
					) => {
						if (event.type !== UPDATE_MANUAL_PITCH_TYPE_MAP_BYSEASON_DONE)
							return context.manualPitchTypeMapsBySeason;

						if (context.toast)
							context.toast({
								title: "Manual Pitch Type Map By Season",
								description: `Pitch type retag updated. Changes will be available in a few hours.`,
								...DEFAULT_TOAST_SUCCESS_PROPS
							});
					}
				}),
				handleDeleteManualPitchTypeMapBySeasonSuccess: assign<
					TManualPitchTypeMapsBySeasonContext,
					TManualPitchTypeMapBySeasonEvent
				>({
					manualPitchTypeMapsBySeason: (
						context: TManualPitchTypeMapsBySeasonContext,
						event: TManualPitchTypeMapBySeasonEvent
					) => {
						if (event.type !== DELETE_MANUAL_PITCH_TYPE_MAP_BYSEASON_DONE)
							return context.manualPitchTypeMapsBySeason;

						if (context.toast)
							context.toast({
								title: "Manual Pitch Type Map By Season",
								description: `Pitch type retag deleted. Changes will be available in a few hours.`,
								...DEFAULT_TOAST_SUCCESS_PROPS
							});
					}
				}),
				// Errored Actions
				handleFetchManualPitchTypeMapsBySeasonErrored: (
					context: TManualPitchTypeMapsBySeasonContext,
					event: TManualPitchTypeMapBySeasonEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_MANUAL_PITCH_TYPE_MAPS_BYSEASON_ERROR ? event.data : undefined,
						context.toast,
						"Manual Pitch Type Map By Season",
						"Error fetching pitch type retag data."
					);
				},
				handlePostManualPitchTypeMapBySeasonErrored: (
					context: TManualPitchTypeMapsBySeasonContext,
					event: TManualPitchTypeMapBySeasonEvent
				) => {
					displayAxiosErrorToast(
						event.type === POST_MANUAL_PITCH_TYPE_MAP_BYSEASON_ERROR ? event.data : undefined,
						context.toast,
						"Manual Pitch Type Map By Season",
						"Error adding pitch type retag."
					);
				},
				handleUpdateManualPitchTypeMapBySeasonErrored: (
					context: TManualPitchTypeMapsBySeasonContext,
					event: TManualPitchTypeMapBySeasonEvent
				) => {
					displayAxiosErrorToast(
						event.type === UPDATE_MANUAL_PITCH_TYPE_MAP_BYSEASON_ERROR ? event.data : undefined,
						context.toast,
						"Manual Pitch Type Map By Season",
						"Error updating pitch type retag."
					);
				},
				handleDeleteManualPitchTypeMapBySeasonErrored: (
					context: TManualPitchTypeMapsBySeasonContext,
					event: TManualPitchTypeMapBySeasonEvent
				) => {
					displayAxiosErrorToast(
						event.type === DELETE_MANUAL_PITCH_TYPE_MAP_BYSEASON_ERROR ? event.data : undefined,
						context.toast,
						"Manual Pitch Type Map By Season",
						"Error deleting pitch type retag."
					);
				}
			},
			services: {
				fetchManualPitchTypeMapsBySeason: (
					context: TManualPitchTypeMapsBySeasonContext,
					_event: AnyEventObject
				) => {
					const { playerId } = context;
					if (playerId === undefined) return Promise.resolve(undefined);
					const fetchFunc = () =>
						fetchManualPitchTypeMapsBySeason(
							{
								playerId: playerId,
								sort: "season",
								isSortDescending: true
							},
							context.cancelSources[MANUAL_PITCH_TYPE_MAP_BYSEASON_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				postManualPitchTypeMapBySeason: (
					context: TManualPitchTypeMapsBySeasonContext,
					event: AnyEventObject
				) => {
					if (event.type !== POST_MANUAL_PITCH_TYPE_MAP_BYSEASON) {
						return new Promise((_resolve, reject) => reject());
					}
					const postFunc = () => {
						const promises = event.data.map((item: IManualPitchTypeMapBySeason) =>
							postManualPitchTypeMapBySeason(
								item,
								context.cancelSources[MANUAL_PITCH_TYPE_MAP_BYSEASON_CANCEL_SOURCE]?.token
							)
						);
						return Promise.all(promises).then(
							(manualPitchTypeMapBySeason: IManualPitchTypeMapBySeason[] | undefined) => {
								if (event.callback) event.callback();

								return manualPitchTypeMapBySeason;
							}
						);
					};
					return promiseWRetry(postFunc);
				},
				updateManualPitchTypeMapBySeason: (
					context: TManualPitchTypeMapsBySeasonContext,
					event: AnyEventObject
				) => {
					if (context.manualPitchTypeMapsBySeason == null)
						return Promise.resolve(context.manualPitchTypeMapsBySeason);

					return putManualPitchTypeMapBySeason(
						event.data,
						context.cancelSources[MANUAL_PITCH_TYPE_MAP_BYSEASON_CANCEL_SOURCE]?.token
					).then((manualPitchTypeMapBySeason: IManualPitchTypeMapBySeason[] | undefined) => {
						if (event.callback) event.callback();

						return manualPitchTypeMapBySeason;
					});
				},
				deleteManualPitchTypeMapBySeason: (
					context: TManualPitchTypeMapsBySeasonContext,
					event: AnyEventObject
				) => {
					if (event.type !== DELETE_MANUAL_PITCH_TYPE_MAP_BYSEASON) {
						return new Promise((_resolve, reject) => reject());
					}
					const deleteFunc = () =>
						deleteManualPitchTypeMapBySeason(
							event.value,
							context.cancelSources[MANUAL_PITCH_TYPE_MAP_BYSEASON_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(deleteFunc);
				}
			}
		}
	);

export default ManualPitchTypeMapBySeasonMachine;
