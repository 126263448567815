import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import { fetchAllSurplusValues } from "_react/shared/data_models/surplus_value/_network";
import { TAllSurplusValues } from "_react/shared/data_models/surplus_value/_types";
import { getCancelSource } from "utils/url_helpers";
import { extractFromEventDataArray } from "_react/shared/_helpers/xstate";

import { TSurplusValueStatCardData } from "_react/shared/ui/data/cards/SurplusValueStatCard/SurplusValueStatCard";

export type TSurplusValueStatCardContext = {
	playerId?: number;
	lastPlayerId?: number;
	shouldFetchData?: boolean;
	surplusValues?: TAllSurplusValues | null;
	cancelSources: Record<string, CancelTokenSource>;
	toast?: CreateToastFnReturn;
};

interface ISurplusValueStatCardStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId prop changes
				playerIdRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches all SV data
				surplusValues: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_SURPLUS_VALUES = "SET_SURPLUS_VALUES";
const SURPLUS_VALUES_CANCEL_SOURCE = "surplusValues";
export const FETCHING_SURPLUS_VALUES = { initialized: { surplusValues: "fetching" } };

const FETCH_SURPLUS_VALUES_DONE = "done.invoke.fetchingSurplusValues:invocation[0]";
const FETCH_SURPLUS_VALUES_ERROR = "error.platform.fetchingSurplusValues:invocation[0]";

type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; data: number | undefined };
type TSetSurplusValuesEvent = { type: typeof SET_SURPLUS_VALUES; data: TAllSurplusValues | null | undefined };
type TFetchSurplusValuesEvent = { type: typeof FETCH_SURPLUS_VALUES_DONE; data?: Array<TAllSurplusValues> };
type TFetchSurplusValuesErrorEvent = { type: typeof FETCH_SURPLUS_VALUES_ERROR; data?: AxiosError | Error };

type TSurplusValueStatCardEvent =
	| TSetPlayerIdEvent
	| TSetSurplusValuesEvent
	| TFetchSurplusValuesEvent
	| TFetchSurplusValuesErrorEvent;

export type TSurplusValuesStatCardSend = Interpreter<
	TSurplusValueStatCardContext,
	ISurplusValueStatCardStateSchema,
	TSurplusValueStatCardEvent
>["send"];

const SurplusValueStatCardMachine = (
	playerIdProp?: number,
	shouldFetchData = true,
	data?: TSurplusValueStatCardData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TSurplusValueStatCardContext, ISurplusValueStatCardStateSchema, TSurplusValueStatCardEvent>(
		{
			id: "surplusValueStatCard",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				shouldFetchData: shouldFetchData,
				surplusValues: data?.surplusValues,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: { target: "initialized" }
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_PLAYER_ID]: { actions: "setPlayerId" },
						[SET_SURPLUS_VALUES]: { actions: "setSurplusValues" }
					},
					states: {
						playerIdRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						surplusValues: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingSurplusValues",
												cond: "shouldFetchSurplusValues"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingSurplusValues",
									entry: ["refreshSurplusValuesCancelSource"],
									invoke: {
										src: "fetchSurplusValues",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchSurplusValuesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchSurplusValuesErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (context: TSurplusValueStatCardContext, _event: TSurplusValueStatCardEvent) =>
					context.playerId !== context.lastPlayerId,
				shouldFetchSurplusValues: (context: TSurplusValueStatCardContext, _event: TSurplusValueStatCardEvent) =>
					context.surplusValues === undefined && shouldFetchData && context.playerId !== undefined
			},
			actions: {
				setPlayerId: assign<TSurplusValueStatCardContext, TSurplusValueStatCardEvent>({
					playerId: (context: TSurplusValueStatCardContext, event: TSurplusValueStatCardEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				setSurplusValues: assign<TSurplusValueStatCardContext, TSurplusValueStatCardEvent>({
					surplusValues: (context: TSurplusValueStatCardContext, event: TSurplusValueStatCardEvent) => {
						if (event.type !== SET_SURPLUS_VALUES) return context.surplusValues;
						return event.data;
					},
					cancelSources: (context: TSurplusValueStatCardContext, event: TSurplusValueStatCardEvent) => {
						if (event.type !== SET_SURPLUS_VALUES) return context.cancelSources;
						if (context.cancelSources[SURPLUS_VALUES_CANCEL_SOURCE] != null)
							context.cancelSources[SURPLUS_VALUES_CANCEL_SOURCE].cancel();
						delete context.cancelSources[SURPLUS_VALUES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				clearContext: assign<TSurplusValueStatCardContext, TSurplusValueStatCardEvent>({
					lastPlayerId: (context: TSurplusValueStatCardContext, _event: TSurplusValueStatCardEvent) =>
						context.playerId,
					surplusValues: (_context: TSurplusValueStatCardContext, _event: TSurplusValueStatCardEvent) =>
						undefined,
					cancelSources: (context: TSurplusValueStatCardContext, _event: TSurplusValueStatCardEvent) => {
						Object.values(context.cancelSources).forEach((tokenSource: CancelTokenSource) =>
							tokenSource.cancel()
						);
						return {};
					}
				}),
				// Cancel Source Actions
				refreshSurplusValuesCancelSource: assign<TSurplusValueStatCardContext, TSurplusValueStatCardEvent>({
					cancelSources: (context: TSurplusValueStatCardContext, _event: TSurplusValueStatCardEvent) => {
						if (context.cancelSources[SURPLUS_VALUES_CANCEL_SOURCE] != null)
							context.cancelSources[SURPLUS_VALUES_CANCEL_SOURCE].cancel();
						context.cancelSources[SURPLUS_VALUES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchSurplusValuesSuccess: assign<TSurplusValueStatCardContext, TSurplusValueStatCardEvent>({
					surplusValues: (context: TSurplusValueStatCardContext, event: TSurplusValueStatCardEvent) => {
						if (event.type !== FETCH_SURPLUS_VALUES_DONE) return context.surplusValues;
						return extractFromEventDataArray<TSurplusValueStatCardEvent>(event);
					}
				}),
				// Fetch Errored Actions
				handleFetchSurplusValuesErrored: (
					context: TSurplusValueStatCardContext,
					event: TSurplusValueStatCardEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_SURPLUS_VALUES_ERROR ? event.data : undefined,
						context.toast,
						"Surplus Value",
						"Error fetching surplus value data."
					);
				}
			},
			services: {
				fetchSurplusValues: (context: TSurplusValueStatCardContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchAllSurplusValues(
							{
								playerId: context.playerId,
								isUseCache: true
							},
							context.cancelSources[SURPLUS_VALUES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default SurplusValueStatCardMachine;
