import { round10 } from "_react/shared/_helpers/numbers";
import { TPitcherValueRow } from "_react/shared/ui/data/tables/PitcherValueTable/_types";
import { IRAAPitcher, IRAAPitcherByTeam } from "_react/shared/data_models/raa/_types";
import { TPitcherValueTableFilters } from "_react/shared/ui/data/tables/PitcherValueTable/_machine";

export const convertToDecimal = (input: number) => {
	return round10(input, -1);
};

// Used to get the level to display for a row
export const getLevelsFromRow = (row: TPitcherValueRow): Array<string> => {
	// Child Rows or rows with no nested data
	if ("teamBam" in row.pitcherValue) return row.pitcherValue.teamBam?.level ? [row.pitcherValue.teamBam?.level] : [];
	// Parent Rows with nested data
	if (row.childData && row.childData.length > 1) {
		return [
			...new Set(
				row.childData.reduce((acc: Array<string>, childRow: TPitcherValueRow) => {
					if ("teamBam" in childRow.pitcherValue && childRow.pitcherValue.teamBam?.level)
						acc.push(childRow.pitcherValue.teamBam?.level);
					return acc;
				}, [])
			)
		];
	}
	return [];
};

// Row Aggregation
export const aggregateRows = (
	filteredPitcherValueData: Array<IRAAPitcher> | null | undefined,
	filteredPitcherValueByTeamData: Array<IRAAPitcherByTeam> | null | undefined,
	minSeason: number,
	maxSeason: number,
	filters: TPitcherValueTableFilters
): Array<TPitcherValueRow> => {
	// Holder for seasons that were affected by the level filter
	//  AKA: Child rows were filtered out, so the total row should be removed and child rows promoted
	const seasonsAffectedByLevelFilter: Set<number> = new Set();

	let newPitcherValueRows: Array<TPitcherValueRow> = [];

	// Iterate over total rows
	filteredPitcherValueData?.forEach((value: IRAAPitcher) => {
		// Filter for Season
		const season = value.season;
		if (!season || season < minSeason || season > maxSeason) return;
		// Find existing row
		const existingRow = newPitcherValueRows.find((row: TPitcherValueRow) => row.pitcherValue.season === season);

		if (existingRow) {
			// Append to existing row for specified role
			existingRow.pitcherValue[value.role] = value;
		} else {
			// Create new row
			newPitcherValueRows.push({
				pitcherValue: {
					...value,
					[value.role]: value
				}
			});
		}
	});

	// Iterate over by team rows
	filteredPitcherValueByTeamData?.forEach((value: IRAAPitcherByTeam) => {
		// Filter for Season
		const season = value.season;
		if (!season || season < minSeason || season > maxSeason) return;
		// Filter for Level
		const level = value.teamBam?.level;
		if (level && filters.levels) {
			if (!filters.levels.includes(level)) {
				seasonsAffectedByLevelFilter.add(season);
				return;
			}
		}

		// Find existing row
		const existingRow = newPitcherValueRows.find((row: TPitcherValueRow) => row.pitcherValue.season === season);

		if (existingRow) {
			// Find nested row
			const existingNestedRow = existingRow.childData?.find(
				(row: TPitcherValueRow) => row.pitcherValue.teamId === value.teamId
			);

			if (existingNestedRow) {
				// Append to existing row for specified role
				existingNestedRow.pitcherValue[value.role] = value;
			} else {
				// Create new row
				existingRow.childData = [
					...(existingRow.childData ?? []),
					{
						pitcherValue: {
							...value,
							[value.role]: value
						}
					}
				];
			}
		}
	});

	// If level filters are set, and if any seasons were affected by the level filters, remove the aggregated row for that season and promote the child rows
	let promotedRows: Array<TPitcherValueRow> = [];
	seasonsAffectedByLevelFilter.forEach((season: number) => {
		newPitcherValueRows = newPitcherValueRows.filter((row: TPitcherValueRow) => {
			if (row.pitcherValue.season === season) {
				// Mark row to be promoted
				promotedRows = [...promotedRows, row];
				// Filter out row
				return false;
			}
			return true;
		});
	});
	promotedRows.forEach((row: TPitcherValueRow) => {
		// Iterate over child rows and promote them
		row.childData?.forEach((row: TPitcherValueRow) => {
			// Promote child row
			newPitcherValueRows.push(row);
		});
	});

	// Map to structure the data for the table
	return newPitcherValueRows.map((row: TPitcherValueRow) => ({
		...row,
		pitcherValue: (row.childData?.length ?? 0) === 1 ? row.childData![0].pitcherValue : row.pitcherValue,
		childData: (row.childData?.length ?? 0) > 1 ? row.childData : undefined
	}));
};
