import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	Box,
	ButtonGroup,
	Flex,
	HStack,
	IconButton,
	Menu,
	MenuButton,
	MenuItemOption,
	MenuList,
	MenuOptionGroup,
	Portal,
	RangeSlider,
	RangeSliderFilledTrack,
	RangeSliderThumb,
	RangeSliderTrack,
	Spacer,
	SystemStyleObject,
	Text,
	Tooltip,
	useToast,
	VStack
} from "@chakra-ui/react";
import { useMachine } from "@xstate/react";

import { getMinAndMaxSeason, getSeasonFilters, updateFilters } from "_react/shared/_helpers/stats";
import {
	IRAAPositionPlayer,
	IRAAPositionPlayerByTeam,
	IRAAPositionPlayerLkLevel
} from "_react/shared/data_models/raa/_types";
import { VALID_PRO_LEVELS } from "_react/shared/data_models/stats/_constants";
import CloseIcon from "_react/shared/legacy/ui/icons/Clear";
import { isDefaultFilters } from "_react/shared/ui/data/tables/shared/Filters";
import FilterAlt from "_react/shared/ui/icons/FilterAlt";
import OutlineInfo from "_react/shared/ui/icons/OutlineInfo";
import { ASC, DESC } from "_react/shared/ui/presentation/components/Table/_constants";
import { TColumn, TTableProps } from "_react/shared/ui/presentation/components/Table/_types";
import Table from "_react/shared/ui/presentation/components/Table/Table";
import TeamLevelBadge from "_react/shared/ui/presentation/components/TeamLevelBadge/TeamLevelBadge";
import ToggleButton from "_react/shared/ui/presentation/components/ToggleButton/ToggleButton";

import {
	NUM_DISPLAY_SEASONS,
	POSITION_PLAYER_VALUE_COLUMNS
} from "_react/shared/ui/data/tables/PositionPlayerValueTable/_constants";
import { getLevelsFromRow } from "_react/shared/ui/data/tables/PositionPlayerValueTable/_helpers";
import PositionPlayerValueTableMachine, {
	FETCHING_PLAYER_SEASON_RAA_POSITION_PLAYER,
	FETCHING_PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM,
	SET_IS_COMPONENT_RAA,
	SET_FILTERS,
	SET_PLAYER_ID,
	SET_PLAYER_SEASON_RAA_POSITION_PLAYER,
	SET_PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM,
	TPositionPlayerValueTableContext,
	SET_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER,
	SET_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM,
	SET_DATA_SOURCE,
	FETCHING_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER,
	FETCHING_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM
} from "_react/shared/ui/data/tables/PositionPlayerValueTable/_machine";
import {
	RAW,
	RATE,
	TPositionPlayerValueRow,
	TPositionPlayerValueTableData,
	TPositionPlayerValueTableDataSource
} from "_react/shared/ui/data/tables/PositionPlayerValueTable/_types";

type TPositionPlayerValueTableStyle = {
	container?: SystemStyleObject;
	tableContainer?: SystemStyleObject;
};

type TPositionPlayerPerformanceTableProps = {
	title?: string;
	playerId?: number;
	data?: TPositionPlayerValueTableData;
	columns?: Array<string>;
	shouldFetchData?: boolean;
	isShowFilters?: boolean;
	tableProps?: TTableProps<TPositionPlayerValueRow, keyof TPositionPlayerValueRow>;
	style?: TPositionPlayerValueTableStyle;
};

const PositionPlayerValueTable = ({
	title,
	playerId: playerIdProp,
	data,
	columns,
	shouldFetchData = true,
	isShowFilters = true,
	tableProps,
	style
}: TPositionPlayerPerformanceTableProps) => {
	const [showSeasonRangeTooltip, setShowSeasonRangeTooltip] = useState(false);

	const toast = useToast();

	const [current, send] = useMachine(PositionPlayerValueTableMachine(playerIdProp, data, shouldFetchData, toast));

	const isFetchingPlayerSeasonRaaPositionPlayer = current.matches(FETCHING_PLAYER_SEASON_RAA_POSITION_PLAYER);
	const isFetchingPlayerSeasonRaaPositionPlayerByTeam = current.matches(
		FETCHING_PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM
	);
	const isFetchingPlayerSeasonRaaRatePositionPlayer = current.matches(
		FETCHING_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER
	);
	const isFetchingPlayerSeasonRaaRatePositionPlayerByTeam = current.matches(
		FETCHING_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM
	);

	const isLoading =
		isFetchingPlayerSeasonRaaPositionPlayer ||
		isFetchingPlayerSeasonRaaPositionPlayerByTeam ||
		isFetchingPlayerSeasonRaaRatePositionPlayer ||
		isFetchingPlayerSeasonRaaRatePositionPlayerByTeam;

	const context = current.context as TPositionPlayerValueTableContext;
	const {
		playerId,
		playerSeasonRaaPositionPlayer,
		playerSeasonRaaPositionPlayerByTeam,
		playerSeasonRaaRatePositionPlayer,
		playerSeasonRaaRatePositionPlayerByTeam,
		filters,
		dataSource,
		isComponentRaa
	} = context;

	// Update machine context when props change
	useEffect(() => {
		if (playerIdProp !== playerId) send({ type: SET_PLAYER_ID, data: playerIdProp });
	}, [send, playerIdProp, playerId, shouldFetchData]);

	useEffect(() => {
		if (data?.playerSeasonRaaPositionPlayer !== playerSeasonRaaPositionPlayer && shouldFetchData === false)
			send({ type: SET_PLAYER_SEASON_RAA_POSITION_PLAYER, data: data?.playerSeasonRaaPositionPlayer });
	}, [send, data?.playerSeasonRaaPositionPlayer, playerSeasonRaaPositionPlayer, shouldFetchData]);

	useEffect(() => {
		if (
			data?.playerSeasonRaaPositionPlayerByTeam !== playerSeasonRaaPositionPlayerByTeam &&
			shouldFetchData === false
		)
			send({
				type: SET_PLAYER_SEASON_RAA_POSITION_PLAYER_BYTEAM,
				data: data?.playerSeasonRaaPositionPlayerByTeam
			});
	}, [send, data?.playerSeasonRaaPositionPlayerByTeam, playerSeasonRaaPositionPlayerByTeam, shouldFetchData]);

	useEffect(() => {
		if (data?.playerSeasonRaaRatePositionPlayer !== playerSeasonRaaRatePositionPlayer && shouldFetchData === false)
			send({ type: SET_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER, data: data?.playerSeasonRaaRatePositionPlayer });
	}, [send, data?.playerSeasonRaaRatePositionPlayer, playerSeasonRaaRatePositionPlayer, shouldFetchData]);

	useEffect(() => {
		if (
			data?.playerSeasonRaaRatePositionPlayerByTeam !== playerSeasonRaaRatePositionPlayerByTeam &&
			shouldFetchData === false
		)
			send({
				type: SET_PLAYER_SEASON_RAA_RATE_POSITION_PLAYER_BYTEAM,
				data: data?.playerSeasonRaaRatePositionPlayerByTeam
			});
	}, [send, data?.playerSeasonRaaRatePositionPlayerByTeam, playerSeasonRaaRatePositionPlayerByTeam, shouldFetchData]);

	// Select data source
	const selectedPositionPlayerValue =
		dataSource === RAW ? playerSeasonRaaPositionPlayer : playerSeasonRaaRatePositionPlayer;
	const selectedPositionPlayerValueByTeam =
		dataSource === RAW ? playerSeasonRaaPositionPlayerByTeam : playerSeasonRaaRatePositionPlayerByTeam;

	// Get max and min season
	const [minSeason, maxSeason] = useMemo(
		() => getMinAndMaxSeason<IRAAPositionPlayer>(selectedPositionPlayerValue ?? []),
		[selectedPositionPlayerValue]
	);

	//
	// Season filter options
	//
	const seasonFilters: { minSeason: number; maxSeason: number } = useMemo(
		() => getSeasonFilters(filters.minSeason, filters.maxSeason, maxSeason, NUM_DISPLAY_SEASONS),
		[filters.minSeason, filters.maxSeason, maxSeason]
	);

	//
	// Level filter options
	//

	// Compute the level filter options
	const levelFilterOptions: Array<IRAAPositionPlayerLkLevel> = useMemo(
		() =>
			selectedPositionPlayerValueByTeam
				?.reduce((acc: Array<IRAAPositionPlayerLkLevel>, curr: IRAAPositionPlayerByTeam) => {
					const levelRel: IRAAPositionPlayerLkLevel | undefined = curr.teamBam?.levelRel;
					if (
						levelRel &&
						levelRel.value &&
						!acc.some(val => val.value === levelRel.value) &&
						VALID_PRO_LEVELS.includes(levelRel.value)
					)
						acc.push(levelRel);
					return acc;
				}, [])
				?.sort(
					(a: IRAAPositionPlayerLkLevel, b: IRAAPositionPlayerLkLevel) =>
						(a.sortOrder ?? Number.MAX_SAFE_INTEGER) - (b.sortOrder ?? Number.MAX_SAFE_INTEGER)
				) ?? [],
		[selectedPositionPlayerValueByTeam]
	);

	// Once the level filter options are computed for the first time, send them to the machine
	useEffect(() => {
		if (filters.levels === undefined && levelFilterOptions.length > 0) {
			const newFilters = {
				...filters,
				levels: levelFilterOptions.map((option: IRAAPositionPlayerLkLevel) => option.value)
			};
			send({ type: SET_FILTERS, data: newFilters });
		}
	}, [filters, send, levelFilterOptions]);

	//
	// Apply season filter to aggregated data
	//

	const filteredPlayerSeasonRaaPositionPlayerData: Array<IRAAPositionPlayer> | undefined | null = useMemo(() => {
		if (isLoading) return undefined;
		if (!selectedPositionPlayerValue) return selectedPositionPlayerValue;
		return selectedPositionPlayerValue.filter(
			(raa: IRAAPositionPlayer) => raa.season <= seasonFilters.maxSeason && raa.season >= seasonFilters.minSeason
		);
	}, [isLoading, seasonFilters, selectedPositionPlayerValue]);

	// Check for default filters
	const defaultFiltersSet: boolean = useMemo(() => {
		const availableLevels = levelFilterOptions.map((option: IRAAPositionPlayerLkLevel) => option.value);

		return isDefaultFilters(filters, undefined, availableLevels, maxSeason, NUM_DISPLAY_SEASONS);
	}, [filters, levelFilterOptions, maxSeason]);

	const resetFilters = useCallback(() => {
		send({ type: SET_FILTERS, data: { levels: undefined } });
	}, [send]);

	//
	// Combine data for table
	//

	const combinedTableData: Array<TPositionPlayerValueRow> | undefined = useMemo(() => {
		if (!filteredPlayerSeasonRaaPositionPlayerData) return undefined;
		const combinedData: Array<TPositionPlayerValueRow> = [];
		const uniqueSeasons = [
			...new Set(filteredPlayerSeasonRaaPositionPlayerData.map((raa: IRAAPositionPlayer) => raa.season))
		];
		uniqueSeasons.forEach((season: number) => {
			// Create an object with the overall player season arsenal grades and all related player season team arsenal grades
			// For certain game type combinations, we need to aggregate the data into a single row
			const raa = filteredPlayerSeasonRaaPositionPlayerData?.filter(
				(raa: IRAAPositionPlayer) => raa.season === season
			);
			const raaByTeam = selectedPositionPlayerValueByTeam?.filter(
				(raaByTeam: IRAAPositionPlayerByTeam) => raaByTeam.season === season
			);
			// If we found a row matching the season, use those stats.
			// We only expect to have one row per season.
			if (raa?.length === 1) {
				let combinedPositionPlayerValueData = raa[0];
				let combinedChildData = raaByTeam?.map((raaByTeam: IRAAPositionPlayerByTeam) => {
					return { combinedPositionPlayerValueData: raaByTeam };
				});
				// If there is only one child row, then we want to display it as the overall row
				if (raaByTeam && raaByTeam.length === 1) {
					combinedPositionPlayerValueData = raaByTeam[0];
					combinedChildData = undefined;
				}
				combinedData.push({
					combinedPositionPlayerValueData: combinedPositionPlayerValueData,
					childData: combinedChildData
				});
			}
		});
		// Apply level filters
		return combinedData.reduce((acc: TPositionPlayerValueRow[], row: TPositionPlayerValueRow) => {
			const uniqueLevels: Array<string> = getLevelsFromRow(row);
			const shouldDisplayEntireRow = uniqueLevels.every(level => filters.levels?.includes(level));

			// If all of the levels associated with the row meet the level filters, push the entire row and return early
			if (shouldDisplayEntireRow) {
				acc.push(row);
				return acc;
			}

			// Otherwise, filter the child data and turn it into multiple parent rows with no child data.
			const filteredChildData = row.childData?.filter((c: TPositionPlayerValueRow) =>
				filters.levels?.includes(c.combinedPositionPlayerValueData?.teamBam?.level ?? "")
			);
			filteredChildData?.forEach((datum: TPositionPlayerValueRow) => {
				acc.push({
					combinedPositionPlayerValueData: datum.combinedPositionPlayerValueData,
					childData: undefined
				});
			});
			return acc;
		}, []);
	}, [filteredPlayerSeasonRaaPositionPlayerData, selectedPositionPlayerValueByTeam, filters.levels]);

	// Filtering
	const handleLevelSelect = (value: string) => {
		const newFilters = {
			...filters,
			levels: updateFilters(filters.levels ?? [], value)
		};
		send({ type: SET_FILTERS, data: newFilters });
	};

	// Table local state management
	const setIsComponentRaa = (value: boolean) => {
		send({ type: SET_IS_COMPONENT_RAA, data: value });
	};

	const setDataSource = (value: TPositionPlayerValueTableDataSource) => {
		send({ type: SET_DATA_SOURCE, data: value });
	};

	// Filter columns based on prop
	const filteredColumns = useMemo(() => {
		if (!columns) return POSITION_PLAYER_VALUE_COLUMNS(isComponentRaa);
		return POSITION_PLAYER_VALUE_COLUMNS(
			isComponentRaa
		).filter((col: TColumn<TPositionPlayerValueRow, keyof TPositionPlayerValueRow>) => columns.includes(col.value));
	}, [columns, isComponentRaa]);

	return (
		<VStack alignItems="start" sx={style?.container}>
			<HStack w="100%" justify="space-between">
				<HStack gap={1}>
					{title && (
						<Box fontFamily="heading" fontSize="md" fontWeight="bold">
							{title}
						</Box>
					)}
				</HStack>
				<HStack gap={1}>
					<ToggleButton<TPositionPlayerValueTableDataSource>
						toggleOptions={[
							{ value: RATE, label: "Rate-Based" },
							{ value: RAW, label: "Raw" }
						]}
						value={dataSource}
						onSelect={setDataSource}
						isDisabled={isLoading}
					/>
					<ToggleButton<boolean>
						toggleOptions={[
							{ value: false, label: "Total RAA" },
							{ value: true, label: "Component RAA" }
						]}
						value={isComponentRaa}
						onSelect={setIsComponentRaa}
						isDisabled={isLoading}
					/>
					{isShowFilters && (
						<Menu closeOnSelect={false} placement="left-start">
							<ButtonGroup
								isAttached
								variant={defaultFiltersSet ? "outline" : "solid"}
								colorScheme={defaultFiltersSet ? undefined : "blue"}
							>
								{!defaultFiltersSet && (
									<IconButton
										aria-label="Close"
										icon={<CloseIcon fill="white" />}
										onClick={resetFilters}
									/>
								)}
								<MenuButton
									as={IconButton}
									aria-label="Options"
									icon={<FilterAlt color={defaultFiltersSet ? "gray.500" : "white"} boxSize={5} />}
								>
									MenuItem
								</MenuButton>
							</ButtonGroup>
							<Portal>
								<MenuList minWidth="240px" maxHeight="md" overflow="scroll">
									<MenuOptionGroup title="Seasons">
										<VStack paddingLeft={4} paddingRight={4} sx={{ alignItems: "leading" }}>
											{minSeason === maxSeason && (
												<Tooltip
													hasArrow
													placement="top"
													label="Only one season of data exists"
												>
													<HStack>
														<OutlineInfo color="gray.500" />
														<Text>{minSeason}</Text>
													</HStack>
												</Tooltip>
											)}
											{minSeason !== maxSeason && (
												<VStack>
													<RangeSlider
														value={[seasonFilters.minSeason, seasonFilters.maxSeason]}
														min={minSeason}
														max={maxSeason}
														step={1}
														onChange={(seasons: number[]) => {
															send({
																type: SET_FILTERS,
																data: {
																	...filters,
																	minSeason: seasons[0],
																	maxSeason: seasons[1]
																}
															});
														}}
														onMouseEnter={() => setShowSeasonRangeTooltip(true)}
														onMouseLeave={() => setShowSeasonRangeTooltip(false)}
													>
														<RangeSliderTrack>
															<RangeSliderFilledTrack bg="black" />
														</RangeSliderTrack>
														<Tooltip
															hasArrow
															placement="top"
															isOpen={showSeasonRangeTooltip}
															label={seasonFilters.minSeason}
														>
															<RangeSliderThumb bg="gray.500" boxSize={3} index={0} />
														</Tooltip>
														<Tooltip
															hasArrow
															placement="top"
															isOpen={showSeasonRangeTooltip}
															label={seasonFilters.maxSeason}
														>
															<RangeSliderThumb bg="gray.500" boxSize={3} index={1} />
														</Tooltip>
													</RangeSlider>
													<Flex sx={{ width: "100%" }}>
														<Text fontSize="sm">{minSeason}</Text>
														<Spacer />
														<Text fontSize="sm">{maxSeason}</Text>
													</Flex>
												</VStack>
											)}
										</VStack>
									</MenuOptionGroup>
									<MenuOptionGroup
										title="Levels"
										type="checkbox"
										value={filters.levels ?? VALID_PRO_LEVELS}
									>
										{levelFilterOptions.map((option: IRAAPositionPlayerLkLevel) => (
											<MenuItemOption
												value={option.value}
												key={option.value}
												onClick={() => handleLevelSelect(option.value)}
											>
												<TeamLevelBadge level={option.value} />
											</MenuItemOption>
										))}
									</MenuOptionGroup>
								</MenuList>
							</Portal>
						</Menu>
					)}
				</HStack>
			</HStack>
			<Box sx={style?.tableContainer}>
				<Table<TPositionPlayerValueRow, keyof TPositionPlayerValueRow>
					headerTooltipPlacement="bottom"
					columns={filteredColumns}
					data={combinedTableData}
					emptyDataDisplayText={"No Position Player Value Data Found"}
					isLoadingData={isLoading || (!shouldFetchData && data?.isLoading)}
					isExpandableRows
					getCustomRowKeyFunction={(row: TPositionPlayerValueRow) => {
						return `${row.combinedPositionPlayerValueData.season}-${getLevelsFromRow(row).toString()}`;
					}}
					defaultSortColumns={[
						{
							columnValue: "season",
							sortDirection: ASC
						},
						{
							columnValue: "level",
							sortDirection: DESC
						}
					]}
					getRowStyleFunction={(
						obj: TPositionPlayerValueRow,
						index: number,
						data: Array<TPositionPlayerValueRow>
					) => {
						if (
							index < data.length - 1 &&
							obj.combinedPositionPlayerValueData.season !==
								data[index + 1].combinedPositionPlayerValueData.season
						) {
							return {
								borderBottom: "1px solid !important",
								borderBottomColor: "gray.300 !important"
							};
						}
						return {};
					}}
					style={{ th: { textTransform: "none" }, parentTh: { textTransform: "none" } }}
					{...tableProps}
				/>
			</Box>
		</VStack>
	);
};

export default PositionPlayerValueTable;
