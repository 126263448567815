import React, { useState, useEffect, useCallback, useMemo, CSSProperties } from "react";
import { useMachine } from "@xstate/react";
import { useSnackbar } from "notistack";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import dayjs from "dayjs";

import { useFetch } from "utils/url_helpers";
import { useUserId } from "_react/app/AppContents";
import { getAgeFromBirthDate } from "utils/helpers";
import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { ColorScheme, ColorSchemeGroup, defaultColorScheme, RED_SCHEME } from "_react/shared/legacy/ui/Colors";
import { Button } from "_react/shared/legacy/ui/Button";
import { TextField, TChangeEvent } from "_react/shared/legacy/ui/TextField";
import { DropdownSelect } from "_react/shared/legacy/ui/DropdownSelect";
import { PlayerSearch, TPlayerSearchOption } from "_react/shared/searches/PlayerSearch";
import { Checkbox } from "_react/shared/legacy/ui/Checkbox";
import { Tooltip } from "_react/shared/legacy/ui/Tooltip";
import Modal from "_react/shared/legacy/ui/Modal";
import CircularProgress from "_react/shared/legacy/ui/CircularProgress";
import Add from "_react/shared/legacy/ui/icons/Add";
import AttachFile from "_react/shared/legacy/ui/icons/AttachFile";
import Assignment from "_react/shared/legacy/ui/icons/Assignment";
import Close from "_react/shared/legacy/ui/icons/Close";
import Delete from "_react/shared/legacy/ui/icons/Delete";
import Info from "_react/shared/legacy/ui/icons/Info";

import PlayerPlanMachine, {
	ADDING_NOTE_STATE,
	ADDING_TARGET_STATE,
	FETCHING_ATTACHMENT_STATE,
	FETCHING_ATTACHMENT_ERROR_STATE,
	ADDING_ATTACHMENT_STATE,
	DELETING_ATTACHMENT_STATE,
	OPEN_MODAL_STATE,
	ADD_GOAL,
	UPDATE_GOAL,
	ADD_GOAL_DRILL,
	DELETE_GOAL_DRILL,
	ADD_NOTE,
	ADD_TARGET,
	ADD_ATTACHMENT,
	DELETE_ATTACHMENT,
	OPEN_MODAL,
	CLOSE_MODAL,
	SET_ADD_GOAL_FORM,
	SET_ATTACHMENT_FORM,
	SET_ATTACHMENTS_IS_CONFIRM_DELETE,
	SELECT_PLAYER_ID,
	SELECT_PHIL_ID,
	ON_DRAG_END,
	ADDING_GOAL_STATE,
	IDLE_NOTES_STATE,
	IDLE_GOALS_STATE,
	IDLE_TARGET_STATE,
	ADD_FOCUS_AREA,
	IDLE_FOCUS_AREAS_AND_DRILLS_STATE,
	ADDING_FOCUS_AREA_STATE,
	DELETE_FOCUS_AREA,
	SET_CIRCULAR_PROGRESS_FOCUS_AREA_ID,
	ADDING_GOAL_DRILL_STATE,
	SET_CIRCULAR_PROGRESS_GOAL_DRILL_ID,
	SET_CIRCULAR_PROGRESS_ATTACHMENT_URI,
	ADD_SECONDARY_GOAL,
	ADDING_SECONDARY_GOAL_STATE,
	ADDING_SECONDARY_DRAFT_GOAL_STATE
} from "_react/playerplan/shared/_machine";
import {
	FlexDiv,
	PlayerHeader,
	ModalContainer,
	ModalContents,
	NotesModalContents,
	BioDiv,
	BioDataContainer,
	BioDataSection,
	BioDataLabels,
	ParentGoalsDiv,
	StrengthsDiv,
	PHPGoalsDiv,
	GoalSectionLabel,
	ShowCompletedSpan,
	NoGoalsDiv,
	AttachmentsLabel,
	AttachmentInputDiv,
	OrSpan,
	CardStyle,
	CircularProgressStyle,
	SecondaryButtonStyle,
	CloseStyle,
	PlayerSearchStyle,
	ColoredCardHeaderStyle,
	MetricTypeStyle,
	GoalStatusStyle,
	DropDownButtonStyle,
	AddAttachmentButtonStyle,
	UrlTextFieldStyle,
	TooltipStyle,
	InfoStyle,
	GreenColorScheme,
	PlayerPlanFooter,
	FloatRightMarginDiv,
	LeftButtonStyle,
	FlexSpaceBetweenPaddedDiv,
	AttachmentDeleteMargin,
	AttachmentsCircularProgressStyle,
	ModalStyle,
	PlayerNameDiv,
	CardHeaderContentsDiv,
	DropdownSelectContainerDiv,
	DropdownSelectsContainerDiv,
	TooltipButtonsDiv,
	TooltipRightStyle,
	TooltipLeftStyle,
	CheckboxLeftInlineStyle,
	PaddedDiv,
	SmallCircularProgressRightStyle,
	FloatRightDiv,
	GoalSeasonStyle
} from "_react/playerplan/shared/_styles";
import {
	PLAYER_PLAN_IAM_GROUPS,
	PLAYER_PLAN_FO_IAM_GROUP,
	PLAYER_PLAN_FOP_IAM_GROUP,
	PLAYER_PLAN_COORDINATOR_IAM_GROUP,
	PLAYER_PLAN_ANALYST_IAM_GROUP,
	PLAYER_PLAN_READ_ONLY_IAM_GROUP,
	DRAFT,
	IN_PROGRESS,
	COMPLETE,
	CANCELED,
	NOTES_MODAL_TYPE,
	ATTACHMENTS_MODAL_TYPE,
	ADD_GOAL_MODAL_TYPE,
	APPROVE_GOAL_MODAL_TYPE,
	EDIT_GOAL_MODAL_TYPE,
	DECLINE_GOAL_MODAL_TYPE,
	METRIC_TYPE_OPTIONS,
	GOAL_STATUS_FILTER_OPTIONS,
	GOAL_STATUS_FILTER_ALL,
	GOAL_STATUS_FILTER_IN_PROGRESS,
	DEFAULT_ADD_GOAL_FORM,
	PLAYER_PLAN_GOAL_APPROVAL_GROUP,
	PLAYER_PLAN_SUBCOMMITTEE_GROUP,
	DISABLED_OPACITY,
	STRENGTH
} from "_react/playerplan/shared/_constants";
import {
	getGoalSeasonFilterOptions,
	getGoalSeasons,
	getPlayerName,
	isSecondaryGoalValid,
	prepareSecondaryGoal,
	sortGoals
} from "_react/playerplan/shared/_helpers";
import {
	TIamGroup,
	TPlayerPlanGoal,
	TPlayerPlanAttachment,
	TGoalForm,
	TGoalDrillForm,
	TNoteForm,
	TTargetForm,
	TAttachmentForm
} from "_react/playerplan/shared/_types";
import { PlayerPlanGoal, TPlayerPlanGoalStyle } from "_react/playerplan/shared/PlayerPlanGoal";
import { PlayerPlanGoalDraft, TPlayerPlanGoalDraftStyle } from "_react/playerplan/shared/PlayerPlanGoalDraft";
import { CollapsibleGoalSection, TCollapsibleGoalSectionStyle } from "_react/playerplan/shared/CollapsibleGoalSection";
import { AddGoalModal, TAddGoalModalStyle } from "_react/playerplan/shared/AddGoalModal";
import { GoalContentWrapper, TGoalContentWrapperStyle } from "_react/playerplan/shared/GoalContentWrapper";
import { FOCUS_AREA_TIER, PHP_TIER } from "_react/playerplan/metrics/_constants";
import { DraftModalContents, TDraftModalContentsStyle } from "_react/playerplan/shared/DraftModalContents";
import { FixedHeightDiv } from "_react/playerplan/metrics/_styles";
import { PlayerPlanNotes, TPlayerPlanNotesStyle } from "_react/playerplan/shared/PlayerPlanNotes";
import { GoalFormSection } from "_react/playerplan/shared/GoalFormSection";

type TPlayerPlanStyle = {
	card?: CSSProperties;
	cardHeader?: CSSProperties;
	checkbox?: CSSProperties;
	addGoalModalStyle?: TAddGoalModalStyle;
	collapsibleGoalSectionStyle?: TCollapsibleGoalSectionStyle;
	draftModalContentsStyle?: TDraftModalContentsStyle;
	goalContentWrapperStyle?: TGoalContentWrapperStyle;
	playerPlanGoalDraftStyle?: TPlayerPlanGoalDraftStyle;
	playerPlanGoalStyle?: TPlayerPlanGoalStyle;
	playerPlanNotesStyle?: TPlayerPlanNotesStyle;
	submitAttachmentButtonColorScheme?: ColorScheme;
};

export type TPlayerPlanProps = {
	playerId?: number | null;
	selectPlayer?: (playerId: number | null) => void;
	isShowPlayerSearch?: boolean;
	isShowDraftGoals?: boolean;
	isShowBioDiv?: boolean;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TPlayerPlanStyle;
};

export const PlayerPlan = ({
	playerId: playerIdProp = null,
	selectPlayer = () => null,
	isShowPlayerSearch = false,
	isShowDraftGoals = false,
	isShowBioDiv = true,
	colorSchemeGroup = defaultColorScheme,
	style
}: TPlayerPlanProps) => {
	const { enqueueSnackbar } = useSnackbar();

	// IAM Permissions
	const userId = useUserId();
	const { responseData: iamGroups } = useFetch(
		`iam/user/groups?noRelated=true&groupIds=${PLAYER_PLAN_IAM_GROUPS.join(",")}`
	);
	const iamGroupIds = iamGroups ? iamGroups.map((g: TIamGroup) => g.id) : [];

	const isFo = iamGroupIds.find((id: number) => id === PLAYER_PLAN_FO_IAM_GROUP) != null;
	const isCoordinator =
		iamGroupIds.find(
			(id: number) => id === PLAYER_PLAN_COORDINATOR_IAM_GROUP || id === PLAYER_PLAN_FOP_IAM_GROUP
		) != null;
	const isAnalyst = iamGroupIds.find((id: number) => id === PLAYER_PLAN_ANALYST_IAM_GROUP) != null;

	// New Permissions Groups
	const isGroup1 = iamGroupIds.find((id: number) => id === PLAYER_PLAN_GOAL_APPROVAL_GROUP) != null;
	const isGroup2 = iamGroupIds.find((id: number) => id === PLAYER_PLAN_SUBCOMMITTEE_GROUP) != null;
	const isGroup3 = isCoordinator || isAnalyst || isFo;

	const isReadOnly = iamGroupIds.find((id: number) => id === PLAYER_PLAN_READ_ONLY_IAM_GROUP) != null;

	const canAddTierTwo = isGroup1 || isGroup2;
	const canAddTierTwoStrength = isGroup1 || isGroup2 || isGroup3;

	// Non-XState State
	// TODO move these to be managed using XState
	const [metricTypeFilter, setMetricTypeFilter] = useState<string>();
	const [goalStatusFilter, setGoalStatusFilter] = useState<string>(GOAL_STATUS_FILTER_ALL);
	const [goalSeasonFilter, setGoalSeasonFilter] = useState<string>(`${dayjs().year()}`);
	const [isShowCompletedGoals, setIsShowCompletedGoals] = useState<boolean>(false);
	const [selectedGoalId, setSelectedGoalId] = useState<number | undefined>();
	const [isSecondaryGoalModalOpen, setIsSecondaryGoalModalOpen] = useState<boolean>(false);
	const [modalPrimaryGoal, setModalPrimaryGoal] = useState<TPlayerPlanGoal | null>(null);
	const [isConfirmingSecondarySave, setIsConfirmingSecondarySave] = useState<boolean>(false);

	// XState Machine
	const [current, send] = useMachine(PlayerPlanMachine(playerIdProp, enqueueSnackbar));

	const isAddingGoal = current.matches(ADDING_GOAL_STATE);
	const isAddingSecondaryGoal = current.matches(ADDING_SECONDARY_GOAL_STATE);
	const isAddingSecondaryGoalDraft = current.matches(ADDING_SECONDARY_DRAFT_GOAL_STATE);
	const isAddingFocusArea = current.matches(ADDING_FOCUS_AREA_STATE);
	const isAddingGoalDrill = current.matches(ADDING_GOAL_DRILL_STATE);

	const isGoalsIdle = current.matches(IDLE_GOALS_STATE);
	const isNotesIdle = current.matches(IDLE_NOTES_STATE);
	const isTargetIdle = current.matches(IDLE_TARGET_STATE);
	const isFocusAreasAndDrillsIdle = current.matches(IDLE_FOCUS_AREAS_AND_DRILLS_STATE);
	const isGoalActionsDisabled =
		!(isGoalsIdle && isTargetIdle && isFocusAreasAndDrillsIdle && isNotesIdle) ||
		isAddingGoal ||
		isAddingSecondaryGoal ||
		isAddingSecondaryGoalDraft;

	const isAddingNote = current.matches(ADDING_NOTE_STATE);
	const isAddingTarget = current.matches(ADDING_TARGET_STATE);
	const isFetchingAttachment = current.matches(FETCHING_ATTACHMENT_STATE);
	const isFetchingAttachmentError = current.matches(FETCHING_ATTACHMENT_ERROR_STATE);
	const isAddingAttachment = current.matches(ADDING_ATTACHMENT_STATE);
	const isDeletingAttachment = current.matches(DELETING_ATTACHMENT_STATE);
	const isModalOpen =
		current.matches(OPEN_MODAL_STATE) || isAddingAttachment || isDeletingAttachment || isAddingSecondaryGoalDraft;

	const {
		playerId,
		player,
		goals,
		notes,
		attachments,
		transactions,
		modalType,
		modalGoal,
		addGoalForm,
		attachmentForm,
		attachmentsIsConfirmDelete,
		circularProgressAttachmentUri,
		circularProgressFocusAreaId,
		circularProgressGoalDrillId
	} = current.context;

	// goal season filter options
	const goalSeasonFilterOptions = getGoalSeasonFilterOptions();

	// Goals to display in the completed goals section
	const completedGoals = goals?.filter(
		(goal: TPlayerPlanGoal) =>
			goal.status.value === COMPLETE &&
			goal.parentGoalId === null &&
			goal.primaryGoalId === null &&
			goal.playerPlanMetric.metricTierLk !== PHP_TIER
	);

	// Filter out goals that don't meet metric type and status filters
	const filteredGoals = goals?.filter((goal: TPlayerPlanGoal) => {
		const passedMetricTypeFilter = goal.playerPlanMetric.metricType.value === metricTypeFilter || !metricTypeFilter;
		const passedSeasonFilter =
			!goalSeasonFilter ||
			getGoalSeasons(goal)?.includes(+goalSeasonFilter) ||
			goal.playerPlanMetric.metricTierLk === PHP_TIER;
		const passedGoalStatusFilter =
			goalStatusFilter === GOAL_STATUS_FILTER_ALL
				? true
				: goalStatusFilter === GOAL_STATUS_FILTER_IN_PROGRESS
				? goal.status.value === IN_PROGRESS
				: false;
		return passedMetricTypeFilter && passedGoalStatusFilter && passedSeasonFilter;
	});

	const strengths = filteredGoals?.filter(
		(goal: TPlayerPlanGoal) => goal.isStrength && goal.status.value === IN_PROGRESS && goal.primaryGoalId === null
	);
	const phpGoals = filteredGoals?.filter(
		(goal: TPlayerPlanGoal) => goal.playerPlanMetric.metricTierLk === PHP_TIER && goal.status.value === IN_PROGRESS
	);

	// Filter out strenghts and PHP goals
	const developmentGoals = useMemo(
		() =>
			filteredGoals
				?.filter((goal: TPlayerPlanGoal) => {
					// Filter out goals that are strengths
					if (goal.isStrength) return false;
					// Filter out PHP Goals
					if (goal.playerPlanMetric.metricTierLk === PHP_TIER) return false;
					// Filter out child goals
					if (goal.parentGoalId !== null) return false;
					// Filter out Tier 2 goals
					if (goal.primaryGoalId !== null) return false;
					return true;
				})
				?.sort(sortGoals),
		[filteredGoals]
	);

	// Conditionally display draft goals
	const processedDevelopmentGoals = useMemo(
		() =>
			developmentGoals?.filter((goal: TPlayerPlanGoal) =>
				goal.createUserId === userId ||
				goal.lastChangeUserId === userId ||
				(isShowDraftGoals && (isGroup1 || isGroup2))
					? [DRAFT, IN_PROGRESS].includes(goal.status.value)
					: goal.status.value === IN_PROGRESS
			),
		[developmentGoals, isShowDraftGoals, userId, isGroup1, isGroup2]
	);

	// Which goals are selectable?
	const selectableGoals = useMemo(
		() => [...(strengths || []), ...(phpGoals || []), ...(processedDevelopmentGoals || [])],
		[strengths, phpGoals, processedDevelopmentGoals]
	);

	const selectedGoal: TPlayerPlanGoal | undefined = selectableGoals.find(
		(goal: TPlayerPlanGoal) => selectedGoalId && goal.id === selectedGoalId
	);

	const secondaryGoal: TPlayerPlanGoal | undefined = goals?.find(
		(goal: TPlayerPlanGoal) => selectedGoalId && goal.primaryGoalId === selectedGoalId
	);

	const focusAreas: TPlayerPlanGoal[] | undefined = goals?.filter(
		(goal: TPlayerPlanGoal) =>
			selectedGoalId &&
			goal.parentGoalId === selectedGoalId &&
			goal.playerPlanMetric.metricTierLk === FOCUS_AREA_TIER
	);

	// Edge case with completed goals but no selectable goals
	// Turn on the completed goals button by default
	useEffect(() => {
		if (completedGoals?.length && !selectableGoals.length) setIsShowCompletedGoals(true);
	}, [completedGoals, selectableGoals, setIsShowCompletedGoals]);

	// Util Functions for Machine Events
	const addGoal = (usesTarget = true) => {
		send({ type: ADD_GOAL, usesTarget: usesTarget });
	};

	const addSecondaryGoal = (usesTarget = true) => {
		send({ type: ADD_SECONDARY_GOAL, usesTarget: usesTarget });
	};

	const updateGoal = (goal: TPlayerPlanGoal, updateApprovalDate?: boolean) => {
		send({ type: UPDATE_GOAL, value: goal, updateApprovalDate });
	};

	const addFocusArea = (focusAreaForm: TGoalForm) => {
		send({ type: ADD_FOCUS_AREA, value: focusAreaForm });
	};

	const deleteFocusArea = (goal: TPlayerPlanGoal, deleteGoalDrillIds: Array<number>) => {
		send({ type: DELETE_FOCUS_AREA, value: goal, deleteGoalDrillIds });
	};

	const addGoalDrill = (goalDrillForm: TGoalDrillForm) => {
		send({ type: ADD_GOAL_DRILL, value: goalDrillForm });
	};

	const deleteGoalDrill = (goalDrillId: number) => {
		send({ type: DELETE_GOAL_DRILL, value: goalDrillId });
	};

	const addNote = (noteForm: TNoteForm) => {
		send({ type: ADD_NOTE, value: noteForm });
	};

	const addTarget = (targetForm: TTargetForm) => {
		send({ type: ADD_TARGET, value: targetForm });
	};

	const addAttachment = (attachmentForm: TAttachmentForm) => {
		send({ type: ADD_ATTACHMENT, value: attachmentForm });
	};

	const deleteAttachment = (attachment: TPlayerPlanAttachment) => {
		send({ type: DELETE_ATTACHMENT, value: attachment });
	};

	const openModal = (modalType: string, goal?: TPlayerPlanGoal | null) => {
		send({ type: OPEN_MODAL, value: modalType, goal: goal ?? null });
	};

	const closeModal = () => {
		send({ type: CLOSE_MODAL });
		send({ type: SET_ADD_GOAL_FORM, value: DEFAULT_ADD_GOAL_FORM });
	};

	const setAddGoalForm = (value: TGoalForm) => {
		send({ type: SET_ADD_GOAL_FORM, value });
	};

	const setAttachmentForm = (form: TAttachmentForm) => {
		send({ type: SET_ATTACHMENT_FORM, value: form });
	};

	const setAttachmentsIsConfirmDelete = (gcsUri: string, isConfirmDelete: boolean) => {
		send({ type: SET_ATTACHMENTS_IS_CONFIRM_DELETE, value: gcsUri, isConfirmDelete });
	};

	const setCircularProgressAttachmentUri = (gcsUri: string) => {
		send({ type: SET_CIRCULAR_PROGRESS_ATTACHMENT_URI, value: gcsUri });
	};

	const setCircularProgressFocusAreaId = (focusAreaId: number) => {
		send({ type: SET_CIRCULAR_PROGRESS_FOCUS_AREA_ID, value: focusAreaId });
	};

	const setCircularProgressGoalDrillId = (goalDrillId: number) => {
		send({ type: SET_CIRCULAR_PROGRESS_GOAL_DRILL_ID, value: goalDrillId });
	};

	const selectPlayerId = useCallback(
		(playerId: number | null) => {
			send({ type: SELECT_PLAYER_ID, value: playerId });
			selectPlayer(playerId);
		},
		[send, selectPlayer]
	);

	const selectPhilId = useCallback(
		(philId: number | null, playerClassification: string) => {
			send({ type: SELECT_PHIL_ID, value: philId, playerClassification: playerClassification });
		},
		[send]
	);

	const onDragEnd = (result: DropResult) => {
		const sourceGoalId = parseInt(result.draggableId);
		const destinationGoalId =
			processedDevelopmentGoals && result?.destination?.index !== undefined
				? processedDevelopmentGoals[result.destination.index].id
				: null;
		if (sourceGoalId && destinationGoalId)
			send({ type: ON_DRAG_END, value: sourceGoalId, destination: destinationGoalId });
	};

	// Handle changing selected player when playerId prop is changed
	useEffect(() => {
		if (playerIdProp !== playerId) {
			if (playerIdProp) selectPlayerId(playerIdProp);
			else selectPlayer(playerId);

			setSelectedGoalId(undefined);
		}
	}, [playerId, playerIdProp, selectPlayerId, selectPlayer, setSelectedGoalId]);

	return (
		<>
			<Card overflow={"visible"} style={{ ...CardStyle, ...style?.card }}>
				<ColoredCardHeader
					colorScheme={colorSchemeGroup.secondary}
					style={{ ...ColoredCardHeaderStyle, ...style?.cardHeader }}
				>
					<CardHeaderContentsDiv>
						<>
							{isShowPlayerSearch ? (
								player ? (
									<PlayerHeader>
										<PlayerNameDiv>{getPlayerName(player)}</PlayerNameDiv>
										<Close
											fill={"white"}
											style={CloseStyle}
											onClick={() => {
												selectPlayerId(null);
											}}
										/>
									</PlayerHeader>
								) : playerId ? (
									<PlayerNameDiv>{"Loading..."}</PlayerNameDiv>
								) : (
									<PlayerNameDiv>
										<PlayerSearch
											isStyledSelectStyles={true}
											onSelect={(p: TPlayerSearchOption) =>
												selectPhilId(p.philId ?? null, p.playerClassification ?? "PRO")
											}
											playerClassification="PRO"
											orgId={52953}
											style={PlayerSearchStyle}
										/>
									</PlayerNameDiv>
								)
							) : playerId ? (
								isGoalActionsDisabled || !player ? (
									<PlayerNameDiv>{"Loading..."}</PlayerNameDiv>
								) : (
									<PlayerNameDiv>{getPlayerName(player)}</PlayerNameDiv>
								)
							) : (
								<PlayerNameDiv>{"Select a Player..."}</PlayerNameDiv>
							)}
						</>
						<DropdownSelectsContainerDiv>
							<DropdownSelectContainerDiv>
								<DropdownSelect
									onSelect={(value: string) => setGoalStatusFilter(value ?? "")}
									options={GOAL_STATUS_FILTER_OPTIONS}
									value={goalStatusFilter}
									buttonTitle={"Goal Status"}
									colorScheme={colorSchemeGroup.secondary}
									style={GoalStatusStyle}
									buttonStyle={DropDownButtonStyle}
								/>
							</DropdownSelectContainerDiv>
							<DropdownSelectContainerDiv>
								<DropdownSelect
									onSelect={(value: string) => setGoalSeasonFilter(value)}
									options={goalSeasonFilterOptions}
									value={goalSeasonFilter ?? ""}
									buttonTitle={"Season"}
									colorScheme={colorSchemeGroup.secondary}
									style={GoalSeasonStyle}
									buttonStyle={DropDownButtonStyle}
								/>
							</DropdownSelectContainerDiv>
							<DropdownSelectContainerDiv>
								<DropdownSelect
									onSelect={(value: string) => setMetricTypeFilter(value)}
									options={METRIC_TYPE_OPTIONS}
									value={metricTypeFilter ?? ""}
									buttonTitle={"Department"}
									colorScheme={colorSchemeGroup.secondary}
									style={MetricTypeStyle}
									buttonStyle={DropDownButtonStyle}
								/>
							</DropdownSelectContainerDiv>
						</DropdownSelectsContainerDiv>
						<TooltipButtonsDiv>
							{!isReadOnly && (
								<Tooltip
									title="Add Goal"
									style={TooltipLeftStyle}
									colorScheme={colorSchemeGroup.primary}
								>
									<Button
										onClick={() => openModal(ADD_GOAL_MODAL_TYPE)}
										colorScheme={colorSchemeGroup.primary}
										title={<Add fill="white"></Add>}
										disabled={isGoalActionsDisabled || !playerId}
										style={SecondaryButtonStyle}
										disabledOpacity={DISABLED_OPACITY}
									/>
								</Tooltip>
							)}
							<Tooltip
								title={"Show Player Notes"}
								style={TooltipStyle}
								colorScheme={colorSchemeGroup.primary}
							>
								<Button
									onClick={() => openModal(NOTES_MODAL_TYPE)}
									colorScheme={colorSchemeGroup.primary}
									title={<Assignment fill="white"></Assignment>}
									disabled={isGoalActionsDisabled || !playerId}
									style={SecondaryButtonStyle}
									disabledOpacity={DISABLED_OPACITY}
								/>
							</Tooltip>
							<Tooltip
								title={
									"Show Attachments?" +
									(isFetchingAttachment
										? " (...)"
										: isFetchingAttachmentError || attachments === null
										? ""
										: ` (${attachments.length})`)
								}
								style={TooltipRightStyle}
								colorScheme={colorSchemeGroup.primary}
							>
								<Button
									onClick={() => openModal(ATTACHMENTS_MODAL_TYPE)}
									colorScheme={colorSchemeGroup.primary}
									title={<AttachFile fill="white"></AttachFile>}
									disabled={isGoalActionsDisabled || attachments == null || !playerId}
									disabledOpacity={DISABLED_OPACITY}
									style={SecondaryButtonStyle}
								/>
							</Tooltip>
						</TooltipButtonsDiv>
					</CardHeaderContentsDiv>
				</ColoredCardHeader>
				{player && isShowBioDiv && (
					<BioDiv backgroundColor={colorSchemeGroup.secondary.color} color={colorSchemeGroup.secondary.text}>
						<BioDataContainer>
							<BioDataLabels>
								<div>Team:</div>
								<div>Position:</div>
							</BioDataLabels>
							<BioDataSection>
								<div>{player?.proProfile?.teamName ?? ""}</div>
								<div>{player?.position ?? ""}</div>
							</BioDataSection>
						</BioDataContainer>
						<BioDataContainer>
							<BioDataLabels>
								<div>Age:</div>
								<div>Ht/Wt:</div>
							</BioDataLabels>
							<BioDataSection>
								<div>{getAgeFromBirthDate(player?.birthDate)}</div>
								<div>
									{`${
										player?.height
											? `${Math.floor(player.height / 12)}' ${player.height % 12}"`
											: ""
									} / ${player?.weight ?? ""}`}
								</div>
							</BioDataSection>
						</BioDataContainer>
						<BioDataContainer>
							<BioDataLabels>
								<div>R5 Eligible:</div>
								<div>MiLB FA:</div>
							</BioDataLabels>
							<BioDataSection>
								<div>{player?.proProfile?.isR5Selectable ? "Yes" : "No "}</div>
								<div>{player?.proProfile?.mnFaYear ?? ""}</div>
							</BioDataSection>
						</BioDataContainer>
					</BioDiv>
				)}
				{isGoalActionsDisabled && <CircularProgress center={false} style={CircularProgressStyle} />}
				{!isGoalActionsDisabled && selectableGoals?.length === 0 && completedGoals?.length === 0 && (
					<NoGoalsDiv>No Goals for Player</NoGoalsDiv>
				)}
				{
					<>
						{(Boolean(selectableGoals?.length) || Boolean(completedGoals?.length)) &&
							!isGoalActionsDisabled && (
								<>
									<FlexDiv>
										<ParentGoalsDiv borderColor={colorSchemeGroup.secondary.color}>
											<GoalSectionLabel
												backgroundColor={colorSchemeGroup.primary.color}
												color={colorSchemeGroup.primary.text}
											>
												Development Goals
											</GoalSectionLabel>
											{!isGoalActionsDisabled && processedDevelopmentGoals && (
												<DragDropContext onDragEnd={onDragEnd}>
													<Droppable droppableId={`${playerId}`}>
														{provided => (
															<div ref={provided.innerRef} {...provided.droppableProps}>
																{processedDevelopmentGoals.map(
																	(goal: TPlayerPlanGoal, index: number) => (
																		<Draggable
																			key={goal.id}
																			draggableId={`${goal.id}`}
																			index={index}
																			isDragDisabled={!(isGroup1 || isGroup2)}
																		>
																			{provided => {
																				return goal.status.value === DRAFT ? (
																					<PlayerPlanGoalDraft
																						key={goal.id}
																						goal={goal}
																						isGoalActionsDisabled={
																							isGoalActionsDisabled
																						}
																						isGroup1={isGroup1}
																						isGroup2={isGroup2}
																						updateGoal={updateGoal}
																						openModal={openModal}
																						provided={provided}
																						colorSchemeGroup={
																							colorSchemeGroup
																						}
																						style={
																							style?.playerPlanGoalDraftStyle
																						}
																					/>
																				) : (
																					<PlayerPlanGoal
																						key={goal.id}
																						goal={goal}
																						isGroup1={isGroup1}
																						isGroup2={isGroup2}
																						isGoalActionsDisabled={
																							isGoalActionsDisabled
																						}
																						updateGoal={updateGoal}
																						setSelectedGoalId={
																							setSelectedGoalId
																						}
																						selectedGoalId={selectedGoalId}
																						selectedSeason={parseInt(
																							goalSeasonFilter
																						)}
																						provided={provided}
																						colorSchemeGroup={
																							colorSchemeGroup
																						}
																						style={
																							style?.playerPlanGoalStyle
																						}
																					/>
																				);
																			}}
																		</Draggable>
																	)
																)}
																{provided.placeholder}
															</div>
														)}
													</Droppable>
												</DragDropContext>
											)}
										</ParentGoalsDiv>
										<PHPGoalsDiv borderColor={colorSchemeGroup.secondary.color}>
											<GoalSectionLabel
												backgroundColor={colorSchemeGroup.primary.color}
												color={colorSchemeGroup.primary.text}
											>
												PHP
											</GoalSectionLabel>
											{!isGoalActionsDisabled &&
												phpGoals?.map((goal: TPlayerPlanGoal) => (
													<PlayerPlanGoal
														key={goal.id}
														goal={goal}
														isGoalActionsDisabled={isGoalActionsDisabled}
														updateGoal={updateGoal}
														setSelectedGoalId={setSelectedGoalId}
														selectedGoalId={selectedGoalId}
														colorSchemeGroup={colorSchemeGroup}
														style={style?.playerPlanGoalStyle}
													/>
												))}
										</PHPGoalsDiv>
										<StrengthsDiv borderColor={colorSchemeGroup.secondary.color}>
											<GoalSectionLabel
												backgroundColor={colorSchemeGroup.primary.color}
												color={colorSchemeGroup.primary.text}
											>
												Strengths
												{// If there are only completed goals, hide button and turn on by default
												// If there are only selectable goals, hide button and turn off by default
												completedGoals?.length && selectableGoals?.length ? (
													<ShowCompletedSpan>
														<Tooltip
															title={"Show Completed Goals?"}
															style={TooltipStyle}
															colorScheme={colorSchemeGroup.primary}
														>
															<Info fill="white" style={InfoStyle} />
														</Tooltip>
														<Checkbox
															style={{
																...CheckboxLeftInlineStyle,
																...style?.checkbox
															}}
															checked={isShowCompletedGoals}
															onChange={() =>
																setIsShowCompletedGoals(!isShowCompletedGoals)
															}
															colorScheme={colorSchemeGroup.tertiary}
														/>
													</ShowCompletedSpan>
												) : (
													undefined
												)}
											</GoalSectionLabel>
											{!isGoalActionsDisabled &&
												strengths?.map((goal: TPlayerPlanGoal) => (
													<PlayerPlanGoal
														key={goal.id}
														goal={goal}
														isGoalActionsDisabled={isGoalActionsDisabled}
														updateGoal={updateGoal}
														setSelectedGoalId={setSelectedGoalId}
														selectedGoalId={selectedGoalId}
														colorSchemeGroup={colorSchemeGroup}
														style={style?.playerPlanGoalStyle}
													/>
												))}
											{!isGoalActionsDisabled && completedGoals && isShowCompletedGoals && (
												<CollapsibleGoalSection
													title={"Completed Goals"}
													goals={completedGoals}
													isGoalActionsDisabled={isGoalActionsDisabled}
													updateGoal={updateGoal}
													isDisabled={true}
													colorSchemeGroup={colorSchemeGroup}
													style={style?.collapsibleGoalSectionStyle}
												/>
											)}
										</StrengthsDiv>
									</FlexDiv>
									{!selectedGoal && (
										<PlayerPlanFooter
											backgroundColor={colorSchemeGroup.primary.color}
										></PlayerPlanFooter>
									)}
								</>
							)}
						{selectedGoal && userId != null && (
							<>
								<GoalSectionLabel
									backgroundColor={colorSchemeGroup.primary.color}
									color={colorSchemeGroup.primary.text}
								></GoalSectionLabel>
								<GoalContentWrapper
									userId={userId}
									goal={selectedGoal}
									secondaryGoal={secondaryGoal}
									focusAreas={focusAreas}
									addFocusArea={addFocusArea}
									deleteFocusArea={deleteFocusArea}
									isAddingFocusArea={isAddingFocusArea}
									circularProgressFocusAreaId={circularProgressFocusAreaId}
									setCircularProgressFocusAreaId={setCircularProgressFocusAreaId}
									circularProgressGoalDrillId={circularProgressGoalDrillId}
									setCircularProgressGoalDrillId={setCircularProgressGoalDrillId}
									isAddingGoalDrill={isAddingGoalDrill}
									transactions={transactions}
									isGoalActionsDisabled={isGoalActionsDisabled}
									updateGoal={updateGoal}
									isAddingNote={isAddingNote}
									isGroup1={isGroup1}
									isGroup2={isGroup2}
									isReadOnly={isReadOnly}
									addGoalDrill={addGoalDrill}
									deleteGoalDrill={deleteGoalDrill}
									notes={notes}
									addNote={addNote}
									addTarget={addTarget}
									isAddingTarget={isAddingTarget}
									colorSchemeGroup={colorSchemeGroup}
									style={style?.goalContentWrapperStyle}
									setIsSecondaryGoalModalOpen={setIsSecondaryGoalModalOpen}
									setModalPrimaryGoal={setModalPrimaryGoal}
									canAddTierTwo={canAddTierTwo}
									canAddTierTwoStrength={canAddTierTwoStrength}
								/>
							</>
						)}
					</>
				}
				<Modal style={ModalStyle} isOpen={isModalOpen} onRequestClose={() => closeModal()} showX>
					<ModalContainer>
						{modalType === NOTES_MODAL_TYPE && userId != null && (
							<NotesModalContents>
								<PlayerPlanNotes
									userId={userId}
									goals={goals}
									isReadOnly={isReadOnly}
									isAddingNote={isAddingNote}
									playerId={playerId ?? undefined}
									notes={notes}
									addNote={addNote}
									isShowGoalStatement={true}
									colorSchemeGroup={colorSchemeGroup}
									style={style?.playerPlanNotesStyle}
								/>
							</NotesModalContents>
						)}
						{modalType === ATTACHMENTS_MODAL_TYPE && (
							<>
								<ModalContents>
									<AttachmentsLabel>{attachments?.length ? "" : "No "}Attachments</AttachmentsLabel>
									{attachments
										?.sort((a: TPlayerPlanAttachment, b: TPlayerPlanAttachment) =>
											dayjs(a.createDate).diff(dayjs(b.createDate))
										)
										?.map((attachment: TPlayerPlanAttachment) => (
											<FlexSpaceBetweenPaddedDiv key={attachment.gcsUri}>
												<FixedHeightDiv height="35px">
													<a
														href={
															attachment.gcsUri.startsWith("url-")
																? attachment.gcsUri.slice(4)
																: `/gcsredirect?uri=${attachment.gcsUri}`
														}
														target="_blank"
														rel="noopener noreferrer"
														style={{ marginRight: "10px" }}
													>
														{attachment.fileName}
													</a>
													{` (${dayjs(attachment.createDate).format("M/D/YY")})`}
												</FixedHeightDiv>
												{(userId === attachment.createUserId ||
													isGroup3 ||
													isGroup2 ||
													isGroup1) &&
													(attachment.gcsUri === circularProgressAttachmentUri ? (
														<CircularProgress
															center={false}
															style={AttachmentsCircularProgressStyle}
														/>
													) : attachmentsIsConfirmDelete[attachment.gcsUri] ? (
														<FloatRightMarginDiv>
															<Button
																style={LeftButtonStyle}
																onClick={() => {
																	setCircularProgressAttachmentUri(attachment.gcsUri);
																	deleteAttachment(attachment);
																}}
																disabled={isDeletingAttachment}
																title="Confirm"
																colorScheme={colorSchemeGroup.secondary}
																disabledOpacity={DISABLED_OPACITY}
															/>
															<Button
																disabled={false}
																onClick={() =>
																	setAttachmentsIsConfirmDelete(
																		attachment.gcsUri,
																		false
																	)
																}
																title="Cancel"
																colorScheme={colorSchemeGroup.primary}
															/>
														</FloatRightMarginDiv>
													) : (
														<Delete
															fill={colorSchemeGroup.secondary.color}
															style={AttachmentDeleteMargin}
															onClick={() =>
																setAttachmentsIsConfirmDelete(attachment.gcsUri, true)
															}
														/>
													))}
											</FlexSpaceBetweenPaddedDiv>
										))}
									{isAddingAttachment && <div>Adding attachment...</div>}
								</ModalContents>
								{!isReadOnly && (
									<>
										<AttachmentInputDiv>
											<AttachmentsLabel>Add an Attachment</AttachmentsLabel>
											<input
												id="file-input"
												type="file"
												onChange={e =>
													setAttachmentForm({
														...attachmentForm,
														playerId: playerId,
														file: e?.target?.files?.length ? e.target.files[0] : null,
														url: e?.target?.files?.length ? undefined : attachmentForm.url,
														urlName: e?.target?.files?.length
															? undefined
															: attachmentForm.urlName
													})
												}
											/>
											<OrSpan>OR</OrSpan>
											<TextField
												fullWidth
												onChange={(e: TChangeEvent) => {
													setAttachmentForm({
														...attachmentForm,
														playerId: playerId,
														url: e.target.value
													});
												}}
												value={attachmentForm.url}
												placeholder={"Copy URL here"}
												style={UrlTextFieldStyle}
											/>
											<TextField
												fullWidth
												onChange={(e: TChangeEvent) => {
													setAttachmentForm({
														...attachmentForm,
														playerId: playerId,
														urlName: e.target.value
													});
												}}
												value={attachmentForm.urlName}
												placeholder={"Enter attachment name for URL here"}
												style={UrlTextFieldStyle}
											/>
										</AttachmentInputDiv>
										<Button
											onClick={() => addAttachment(attachmentForm)}
											colorScheme={
												style?.submitAttachmentButtonColorScheme ?? colorSchemeGroup.secondary
											}
											disabled={
												attachmentForm.playerId !== playerId ||
												(attachmentForm.file == null &&
													(!attachmentForm.url || !attachmentForm.urlName)) ||
												isAddingAttachment
											}
											title={`Add ${attachmentForm.url ? "URL" : "File"}`}
											style={AddAttachmentButtonStyle}
											disabledOpacity={DISABLED_OPACITY}
										/>
									</>
								)}
							</>
						)}
						{modalType === ADD_GOAL_MODAL_TYPE && (
							<AddGoalModal
								addGoal={addGoal}
								addGoalForm={addGoalForm}
								setAddGoalForm={setAddGoalForm}
								canCreateAllGoalTypes={isGroup1 || isGroup2 || isGroup3}
								colorSchemeGroup={colorSchemeGroup}
								style={style?.addGoalModalStyle}
							/>
						)}
						{modalType === APPROVE_GOAL_MODAL_TYPE &&
							(modalGoal && goals ? (
								<>
									<DraftModalContents
										goals={goals}
										modalGoal={modalGoal}
										isEditGoalModal={false}
										addFocusArea={addFocusArea}
										isAddingFocusArea={isAddingFocusArea}
										addGoalDrill={addGoalDrill}
										isAddingGoalDrill={isAddingGoalDrill}
										deleteFocusArea={deleteFocusArea}
										circularProgressFocusAreaId={circularProgressFocusAreaId}
										setCircularProgressFocusAreaId={setCircularProgressFocusAreaId}
										circularProgressGoalDrillId={circularProgressGoalDrillId}
										setCircularProgressGoalDrillId={setCircularProgressGoalDrillId}
										deleteGoalDrill={deleteGoalDrill}
										isGoalActionsDisabled={isGoalActionsDisabled}
										addTarget={addTarget}
										isAddingTarget={isAddingTarget}
										isGroup1={isGroup1}
										isGroup2={isGroup2}
										colorSchemeGroup={colorSchemeGroup}
										style={style?.draftModalContentsStyle}
										isEditable={false}
									/>
									<Button
										onClick={() => {
											if (modalGoal) {
												const newStatusValue = IN_PROGRESS;
												updateGoal(
													{
														...modalGoal,
														status: {
															...modalGoal.status,
															value: newStatusValue
														}
													},
													true
												);
											}
											closeModal();
										}}
										colorScheme={GreenColorScheme}
										title={"Approve Goal?"}
									/>
								</>
							) : (
								<>Error selecting goal.</>
							))}
						{modalType === EDIT_GOAL_MODAL_TYPE &&
							(modalGoal && goals ? (
								<DraftModalContents
									goals={goals}
									modalGoal={modalGoal}
									isEditGoalModal={true}
									addFocusArea={addFocusArea}
									isAddingFocusArea={isAddingFocusArea}
									addGoalDrill={addGoalDrill}
									isAddingGoalDrill={isAddingGoalDrill}
									deleteFocusArea={deleteFocusArea}
									circularProgressFocusAreaId={circularProgressFocusAreaId}
									setCircularProgressFocusAreaId={setCircularProgressFocusAreaId}
									circularProgressGoalDrillId={circularProgressGoalDrillId}
									setCircularProgressGoalDrillId={setCircularProgressGoalDrillId}
									deleteGoalDrill={deleteGoalDrill}
									isGoalActionsDisabled={isGoalActionsDisabled}
									addTarget={addTarget}
									isAddingTarget={isAddingTarget}
									isGroup1={isGroup1}
									isGroup2={isGroup2}
									colorSchemeGroup={colorSchemeGroup}
									style={style?.draftModalContentsStyle}
									setIsSecondaryGoalModalOpen={setIsSecondaryGoalModalOpen}
									setModalPrimaryGoal={setModalPrimaryGoal}
								/>
							) : (
								<>Error selecting goal.</>
							))}
						{modalType === DECLINE_GOAL_MODAL_TYPE &&
							(modalGoal && goals ? (
								<>
									<DraftModalContents
										goals={goals}
										modalGoal={modalGoal}
										isEditGoalModal={false}
										addFocusArea={addFocusArea}
										isAddingFocusArea={isAddingFocusArea}
										addGoalDrill={addGoalDrill}
										isAddingGoalDrill={isAddingGoalDrill}
										deleteFocusArea={deleteFocusArea}
										circularProgressFocusAreaId={circularProgressFocusAreaId}
										setCircularProgressFocusAreaId={setCircularProgressFocusAreaId}
										circularProgressGoalDrillId={circularProgressGoalDrillId}
										setCircularProgressGoalDrillId={setCircularProgressGoalDrillId}
										deleteGoalDrill={deleteGoalDrill}
										isGoalActionsDisabled={isGoalActionsDisabled}
										addTarget={addTarget}
										isAddingTarget={isAddingTarget}
										isGroup1={isGroup1}
										isGroup2={isGroup2}
										colorSchemeGroup={colorSchemeGroup}
										style={style?.draftModalContentsStyle}
										isEditable={false}
									/>
									<Button
										onClick={() => {
											if (modalGoal) {
												updateGoal({
													...modalGoal,
													status: {
														...modalGoal.status,
														value: CANCELED
													}
												});
											}
											closeModal();
										}}
										colorScheme={RED_SCHEME}
										title={"Decline Goal"}
									/>
								</>
							) : (
								<>Error selecting goal.</>
							))}
					</ModalContainer>
				</Modal>
			</Card>
			<Modal
				isOpen={isSecondaryGoalModalOpen}
				onRequestClose={() => {
					setAddGoalForm(DEFAULT_ADD_GOAL_FORM);
					setIsSecondaryGoalModalOpen(false);
					setModalPrimaryGoal(null);
				}}
				onAfterOpen={() => {
					setAddGoalForm(DEFAULT_ADD_GOAL_FORM);
				}}
				showX
			>
				{modalPrimaryGoal && modalPrimaryGoal.playerPlanMetric.metricTierLk !== PHP_TIER ? (
					<>
						<GoalFormSection
							addGoalForm={addGoalForm}
							secondary={true}
							setAddGoalForm={setAddGoalForm}
							goalType={modalPrimaryGoal.isStrength ? STRENGTH : "Other"}
							colorSchemeGroup={colorSchemeGroup}
							hasSecondaryGoal={true}
							primaryMetricType={modalPrimaryGoal.playerPlanMetric.metricType}
							primaryMetricSubtype={modalPrimaryGoal.playerPlanMetric.metricSubtype}
						/>
						<PaddedDiv>
							{isAddingSecondaryGoal || isAddingSecondaryGoalDraft ? (
								<CircularProgress center={false} style={SmallCircularProgressRightStyle} />
							) : isConfirmingSecondarySave ? (
								<FloatRightDiv>
									<Button
										style={LeftButtonStyle}
										disabled={isGoalActionsDisabled || !isSecondaryGoalValid(addGoalForm)}
										onClick={() => {
											setIsConfirmingSecondarySave(false);
											prepareSecondaryGoal(setAddGoalForm, modalPrimaryGoal, addGoalForm);
											addSecondaryGoal(
												modalPrimaryGoal.playerPlanMetric.metricTierLk !== PHP_TIER
											);
											setAddGoalForm(DEFAULT_ADD_GOAL_FORM);
											setIsSecondaryGoalModalOpen(false);
											setModalPrimaryGoal(null);
										}}
										title="Confirm"
										colorScheme={colorSchemeGroup.secondary}
										disabledOpacity={DISABLED_OPACITY}
									/>
									<Button
										disabled={false}
										onClick={() => setIsConfirmingSecondarySave(false)}
										title="Cancel"
										colorScheme={colorSchemeGroup.primary}
									/>
								</FloatRightDiv>
							) : (
								<FloatRightDiv>
									<Button
										disabled={false}
										onClick={() => setIsConfirmingSecondarySave(true)}
										title="Save"
										colorScheme={colorSchemeGroup.secondary}
									/>
								</FloatRightDiv>
							)}
						</PaddedDiv>
					</>
				) : (
					undefined
				)}
			</Modal>
		</>
	);
};
