import { Machine, assign, Interpreter, DoneInvokeEvent } from "xstate";

import { TEnqueueSnackbar } from "_react/shared/_helpers/snackbar";
import { transaction } from "_react/shared/_types/mesa/transaction";
import { IPlayerBasic } from "_react/shared/data_models/player/_types";

import { ALL_STATUSES, DEFAULT_ADD_GOAL_FORM, FETCH_STATUSES } from "_react/playerplan/shared/_constants";
import {
	fetchPlayerData,
	fetchGoalData,
	fetchNoteData,
	fetchAttachmentData,
	fetchTransactionData,
	fetchPlayerId,
	addGoal,
	updateGoal,
	rerankGoal,
	addGoalDrill,
	deleteGoalDrill,
	addNote,
	addTarget,
	addGoalSplits,
	addAttachment,
	deleteAttachment
} from "_react/playerplan/shared/_network";
import {
	TPlayerPlanGoal,
	TPlayerPlanTarget,
	TPlayerPlanNote,
	TPlayerPlanGoalDrill,
	TPlayerPlanGoalSplit,
	TPlayerPlanAttachment,
	TGoalForm,
	TGoalDrillForm,
	TNoteForm,
	TTargetForm,
	TSplitsForm,
	TAttachmentForm,
	TNoteFilters,
	TPlayerPlanTargetPostResponse
} from "_react/playerplan/shared/_types";
import { isOnlySecondaryGoalValid, isPrimaryGoalValid, isSecondaryGoalValid } from "_react/playerplan/shared/_helpers";

export type TPlayerPlanContext = {
	playerId: number | null;
	lastGoalPlayerId: number | null;
	lastNotePlayerId: number | null;
	lastAttachmentPlayerId: number | null;
	player: IPlayerBasic | null;
	goals: Array<TPlayerPlanGoal> | null;
	notes: Array<TPlayerPlanNote> | null;
	attachments: Array<TPlayerPlanAttachment> | null;
	attachmentsIsConfirmDelete: Record<string, boolean>;
	transactions: Array<transaction> | null;
	modalType: string | null;
	modalGoal: TPlayerPlanGoal | null;
	addGoalForm: TGoalForm;
	circularProgressFocusAreaId: number | null;
	circularProgressGoalDrillId: number | null;
	circularProgressAttachmentUri: string | null;
	attachmentForm: TAttachmentForm;
	enqueueSnackbar: TEnqueueSnackbar;
};

interface IPlayerPlanStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				playerData: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				goalData: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
						updating: {};
						reranking: {};
					};
				};
				noteData: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				attachmentData: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				transactionData: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				playerId: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				modal: {
					states: {
						errored: {};
						modalOpen: {};
						modalNotOpen: {};
						addingGoal: {};
						addingSecondaryGoal: {};
						addingSecondaryGoalDraft: {};
						addingAttachment: {};
						deletingAttachment: {};
					};
				};
				focusAreasAndDrills: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						addingFocusArea: {};
						deletingFocusArea: {};
						addingGoalDrill: {};
						deletingGoalDrill: {};
					};
				};
				addNote: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						adding: {};
					};
				};
				addTarget: {
					initial: "idle";
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						adding: {};
					};
				};
				addGoalSplits: {
					initial: "idle";
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						adding: {};
					};
				};
			};
		};
	};
}

export const IDLE_GOALS_STATE = { initialized: { goalData: "idle" } };
export const IDLE_TARGET_STATE = { initialized: { addTarget: "idle" } };
export const IDLE_NOTES_STATE = { initialized: { noteData: "idle" } };
export const IDLE_FOCUS_AREAS_AND_DRILLS_STATE = { initialized: { focusAreasAndDrills: "idle" } };

export const FETCHING_GOALS_STATE = { initialized: { goalData: "fetching" } };
export const FETCHING_ATTACHMENT_STATE = { initialized: { attachmentData: "fetching" } };
export const FETCHING_ATTACHMENT_ERROR_STATE = { initialized: { attachmentData: { idle: "errored" } } };
export const ADDING_GOAL_STATE = { initialized: { modal: "addingGoal" } };
export const ADDING_SECONDARY_GOAL_STATE = { initialized: { modal: "addingSecondaryGoal" } };
export const ADDING_SECONDARY_DRAFT_GOAL_STATE = { initialized: { modal: "addingSecondaryGoalDraft" } };
export const ADDING_FOCUS_AREA_STATE = { initialized: { focusAreasAndDrills: "addingFocusArea" } };
export const ADDING_GOAL_DRILL_STATE = { initialized: { focusAreasAndDrills: "addingGoalDrill" } };
export const UPDATING_GOAL_STATE = { initialized: { goalData: "updating" } };
export const RERANKING_GOAL_STATE = { initialized: { goalData: "reranking" } };
export const ADDING_NOTE_STATE = { initialized: { addNote: "adding" } };
export const ADDING_TARGET_STATE = { initialized: { addTarget: "adding" } };
export const OPEN_MODAL_STATE = { initialized: { modal: "modalOpen" } };
export const ADDING_ATTACHMENT_STATE = { initialized: { modal: "addingAttachment" } };
export const DELETING_ATTACHMENT_STATE = { initialized: { modal: "deletingAttachment" } };

export const ADD_GOAL = "ADD_GOAL";
export const ADD_SECONDARY_GOAL = "ADD_SECONDARY_GOAL";
export const UPDATE_GOAL = "UPDATE_GOAL";
export const ADD_FOCUS_AREA = "ADD_FOCUS_AREA";
export const DELETE_FOCUS_AREA = "DELETE_FOCUS_AREA";
export const SET_CIRCULAR_PROGRESS_FOCUS_AREA_ID = "SET_CIRCULAR_PROGRESS_FOCUS_AREA_ID";
export const SET_CIRCULAR_PROGRESS_GOAL_DRILL_ID = "SET_CIRCULAR_PROGRESS_GOAL_DRILL_ID";
export const SET_CIRCULAR_PROGRESS_ATTACHMENT_URI = "SET_CIRCULAR_PROGRESS_ATTACHMENT_URI";
export const ADD_GOAL_DRILL = "ADD_GOAL_DRILL";
export const DELETE_GOAL_DRILL = "DELETE_GOAL_DRILL";
export const ADD_NOTE = "ADD_NOTE";
export const ADD_TARGET = "ADD_TARGET";
export const ADD_GOAL_SPLITS = "ADD_GOAL_SPLITS";
export const ADD_ATTACHMENT = "ADD_ATTACHMENT";
export const DELETE_ATTACHMENT = "DELETE_ATTACHMENT";
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SET_ADD_GOAL_FORM = "SET_ADD_GOAL_FORM";
export const SET_ATTACHMENT_FORM = "SET_ATTACHMENT_FORM";
export const SET_ATTACHMENTS_IS_CONFIRM_DELETE = "SET_ATTACHMENTS_IS_CONFIRM_DELETE";
export const SELECT_PLAYER_ID = "SELECT_PLAYER_ID";
export const SELECT_PHIL_ID = "SELECT_PHIL_ID";
export const ON_DRAG_END = "ON_DRAG_END";

type TAddGoalEvent = { type: typeof ADD_GOAL; usesTarget: boolean };
type TAddSecondaryGoalEvent = { type: typeof ADD_SECONDARY_GOAL; usesTarget: boolean };
type TUpdateGoalEvent = {
	type: typeof UPDATE_GOAL;
	value: TPlayerPlanGoal;
	updateApprovalDate?: boolean;
};
type TAddFocusAreaEvent = { type: typeof ADD_FOCUS_AREA; value: TGoalForm };
type TDeleteFocusAreaEvent = {
	type: typeof DELETE_FOCUS_AREA;
	value: TPlayerPlanGoal;
	deleteGoalDrillIds: Array<number>;
};
type TSetCircularProgressFocusAreaIdEvent = { type: typeof SET_CIRCULAR_PROGRESS_FOCUS_AREA_ID; value: number };
type TSetCircularProgressGoalDrillIdEvent = { type: typeof SET_CIRCULAR_PROGRESS_GOAL_DRILL_ID; value: number };
type TSetCircularProgressAttachmentUriEvent = { type: typeof SET_CIRCULAR_PROGRESS_ATTACHMENT_URI; value: string };
type TAddGoalDrillEvent = { type: typeof ADD_GOAL_DRILL; value: TGoalDrillForm };
type TDeleteGoalDrillEvent = { type: typeof DELETE_GOAL_DRILL; value: number };
type TAddNoteEvent = { type: typeof ADD_NOTE; value: TNoteForm };
type TAddTargetEvent = { type: typeof ADD_TARGET; value: TTargetForm };
type TAddSplitsEvent = { type: typeof ADD_GOAL_SPLITS; value: TSplitsForm };
type TAddAttachmentEvent = { type: typeof ADD_ATTACHMENT; value: TAttachmentForm };
type TDeleteAttachmentEvent = { type: typeof DELETE_ATTACHMENT; value: TPlayerPlanAttachment };
type TOpenModalEvent = { type: typeof OPEN_MODAL; value: string; goal: TPlayerPlanGoal | null };
type TCloseModalEvent = { type: typeof CLOSE_MODAL };
type TSetAddGoalFormEvent = { type: typeof SET_ADD_GOAL_FORM; value: TGoalForm };
type TSetAttachmentFormEvent = { type: typeof SET_ATTACHMENT_FORM; value: TAttachmentForm };
type TSetAttachmentsIsConfirmDeleteEvent = {
	type: typeof SET_ATTACHMENTS_IS_CONFIRM_DELETE;
	value: string;
	isConfirmDelete: boolean;
};
type TSelectPlayerIdEvent = { type: typeof SELECT_PLAYER_ID; value: number | null };
type TSelectPhilIdEvent = { type: typeof SELECT_PHIL_ID; value: number | null; playerClassification: string };
type TOnDragEndEvent = { type: typeof ON_DRAG_END; value: number; destination: number };

type TPlayerPlanEvent =
	| TAddGoalEvent
	| TAddSecondaryGoalEvent
	| TUpdateGoalEvent
	| TAddFocusAreaEvent
	| TDeleteFocusAreaEvent
	| TSetCircularProgressFocusAreaIdEvent
	| TSetCircularProgressGoalDrillIdEvent
	| TSetCircularProgressAttachmentUriEvent
	| TAddGoalDrillEvent
	| TDeleteGoalDrillEvent
	| TAddNoteEvent
	| TAddTargetEvent
	| TAddSplitsEvent
	| TAddAttachmentEvent
	| TDeleteAttachmentEvent
	| TOpenModalEvent
	| TCloseModalEvent
	| TSetAddGoalFormEvent
	| TSetAttachmentFormEvent
	| TSetAttachmentsIsConfirmDeleteEvent
	| TSelectPlayerIdEvent
	| TSelectPhilIdEvent
	| TOnDragEndEvent;

export type TPlayerPlanSend = Interpreter<TPlayerPlanContext, IPlayerPlanStateSchema, TPlayerPlanEvent>["send"];

const setAddGoalFormAction = assign<TPlayerPlanContext, TSetAddGoalFormEvent>({
	addGoalForm: (_context, event) => event.value
});

const setAttachmentFormAction = assign<TPlayerPlanContext, TSetAttachmentFormEvent>({
	attachmentForm: (_context, event) => event.value
});

const setAttachmentsIsConfirmDeleteAction = assign<TPlayerPlanContext, TSetAttachmentsIsConfirmDeleteEvent>({
	attachmentsIsConfirmDelete: (context, event) => {
		const { attachmentsIsConfirmDelete } = context;
		if (!event.isConfirmDelete && attachmentsIsConfirmDelete[event.value])
			delete attachmentsIsConfirmDelete[event.value];
		else if (event.isConfirmDelete) attachmentsIsConfirmDelete[event.value] = event.isConfirmDelete;
		return attachmentsIsConfirmDelete;
	}
});

const selectPlayerIdAction = assign<TPlayerPlanContext, TSelectPlayerIdEvent>({
	playerId: (_context, event) => event.value,
	player: (context: TPlayerPlanContext, event: TSelectPlayerIdEvent) => {
		if (!event.value) return null;
		return context.player;
	}
});

const setModalType = assign<TPlayerPlanContext, TOpenModalEvent>({
	modalType: (_context: TPlayerPlanContext, event: TOpenModalEvent) => event.value,
	modalGoal: (_context: TPlayerPlanContext, event: TOpenModalEvent) => event.goal
});

const setCircularProgressFocusAreaIdAction = assign<TPlayerPlanContext, TSetCircularProgressFocusAreaIdEvent>({
	circularProgressFocusAreaId: (_context, event) => event.value
});

const setCircularProgressGoalDrillIdAction = assign<TPlayerPlanContext, TSetCircularProgressGoalDrillIdEvent>({
	circularProgressGoalDrillId: (_context, event) => event.value
});

const setCircularProgressAttachmentUriAction = assign<TPlayerPlanContext, TSetCircularProgressAttachmentUriEvent>({
	circularProgressAttachmentUri: (_context, event) => event.value
});

const doneFetchPlayerDataAction = assign<TPlayerPlanContext, DoneInvokeEvent<IPlayerBasic>>({
	player: (_context: TPlayerPlanContext, event: DoneInvokeEvent<IPlayerBasic>) => event.data
});

const doneFetchGoalDataAction = assign<TPlayerPlanContext, DoneInvokeEvent<Array<TPlayerPlanGoal>>>({
	lastGoalPlayerId: (context, event) => (event.data?.length ? event.data[0].playerId : context.playerId),
	goals: (_context, event) => event.data
});

const doneFetchNoteDataAction = assign<TPlayerPlanContext, DoneInvokeEvent<Array<TPlayerPlanNote>>>({
	lastNotePlayerId: (context, event) =>
		event.data?.length ? event.data[0].playerPlanGoal?.playerId ?? event.data[0].playerId : context.playerId,
	notes: (_context, event) => event.data
});

const doneFetchAttachmentDataAction = assign<TPlayerPlanContext, DoneInvokeEvent<Array<TPlayerPlanAttachment>>>({
	attachments: (_context, event) => event.data,
	attachmentsIsConfirmDelete: (_context, _event) => ({}),
	lastAttachmentPlayerId: (context, event) => (event.data?.length ? event.data[0].playerId : context.playerId)
});

const doneFetchTransactionDataAction = assign<TPlayerPlanContext, DoneInvokeEvent<Array<transaction>>>({
	transactions: (_context, event) => event.data
});

const doneAddGoalAction = assign<TPlayerPlanContext, DoneInvokeEvent<Array<TPlayerPlanGoal>>>({
	goals: (context, event) => {
		const { goals } = context;
		if (!goals) return event.data;
		goals.push(...event.data);
		return goals;
	}
});

const doneAddAttachmentAction = assign<TPlayerPlanContext, DoneInvokeEvent<TPlayerPlanAttachment>>({
	attachments: (context, event) => {
		const { attachments } = context;
		if (!attachments) return [event.data];
		attachments.push(event.data);
		return attachments;
	},
	attachmentForm: (context, _event) => {
		const { attachmentForm } = context;
		return { ...attachmentForm, url: undefined, urlName: undefined };
	}
});

const doneDeleteAttachmentAction = assign<TPlayerPlanContext, DoneInvokeEvent<TPlayerPlanAttachment>>({
	attachments: (context, event) => {
		const { attachments } = context;
		if (!attachments) return [];
		return [
			...attachments.filter(
				(attachment: TPlayerPlanAttachment) =>
					attachment.playerId !== event.data.playerId || attachment.gcsUri !== event.data.gcsUri
			)
		];
	},
	attachmentsIsConfirmDelete: (context: TPlayerPlanContext, event: DoneInvokeEvent<TPlayerPlanAttachment>) => {
		const { attachmentsIsConfirmDelete } = context;
		if (!event.data || !attachmentsIsConfirmDelete[event.data.gcsUri]) return attachmentsIsConfirmDelete;
		delete attachmentsIsConfirmDelete[event.data.gcsUri];
		return attachmentsIsConfirmDelete;
	},
	circularProgressAttachmentUri: () => {
		return null;
	}
});

const doneUpdateGoalAction = assign<TPlayerPlanContext, DoneInvokeEvent<TPlayerPlanGoal>>({
	goals: (context, event) => {
		const index = context.goals?.findIndex((g: TPlayerPlanGoal) => g.id === event.data.id);
		if (index !== undefined && index > -1 && context.goals?.length) {
			const newGoal = {
				...context.goals[index],
				...event.data
			};
			context.goals[index] = newGoal;
		}
		return context.goals ? [...context.goals] : context.goals;
	},
	circularProgressFocusAreaId: (context, event) => {
		if (context.circularProgressFocusAreaId === event.data.id) return null;
		return context.circularProgressFocusAreaId;
	}
});

const doneDeleteFocusAreaAction = assign<TPlayerPlanContext, DoneInvokeEvent<TPlayerPlanGoal>>({
	goals: (context, event) => {
		const focusAreaIndex = context.goals?.findIndex((g: TPlayerPlanGoal) => g.id === event.data.id);
		if (focusAreaIndex !== undefined && focusAreaIndex > -1 && context.goals?.length) {
			// delete all non-deleted supporting actions for this FA
			context.goals[focusAreaIndex].playerPlanDrills.forEach((goalDrill: TPlayerPlanGoalDrill, index: number) => {
				if (!goalDrill.isDeleted && context.goals?.length) {
					context.goals[focusAreaIndex].playerPlanDrills[index].isDeleted = true;
					context.goals[focusAreaIndex].playerPlanDrills[index].lastChangeDate = new Date().toString();
				}
			});

			const newGoal = {
				...context.goals[focusAreaIndex],
				...event.data
			};
			context.goals[focusAreaIndex] = newGoal;
		}
		return context.goals ? [...context.goals] : context.goals;
	},
	circularProgressFocusAreaId: (context, event) => {
		if (context.circularProgressFocusAreaId === event.data.id) return null;
		return context.circularProgressFocusAreaId;
	}
});

const doneRerankGoalAction = assign<TPlayerPlanContext, DoneInvokeEvent<Array<TPlayerPlanGoal>>>({
	goals: (context, event) => {
		event.data.forEach((rerankedGoal: TPlayerPlanGoal) => {
			const index = context.goals?.findIndex((g: TPlayerPlanGoal) => g.id === rerankedGoal.id);
			if (index !== undefined && index > -1 && context.goals?.length) {
				const newGoal = {
					...context.goals[index],
					rank: rerankedGoal.rank
				};
				context.goals[index] = newGoal;
			}
		});
		return context.goals ? [...context.goals] : context.goals;
	}
});

const doneAddGoalDrillAction = assign<TPlayerPlanContext, DoneInvokeEvent<TPlayerPlanGoalDrill>>({
	goals: (context, event) => {
		const index = context.goals?.findIndex((g: TPlayerPlanGoal) => g.id === event.data.playerPlanGoalId);
		if (index !== undefined && index > -1 && context.goals?.length) {
			context.goals[index].playerPlanDrills = [...context.goals[index].playerPlanDrills, event.data];
		}
		return context.goals ? [...context.goals] : context.goals;
	}
});

const doneDeleteGoalDrillAction = assign<TPlayerPlanContext, DoneInvokeEvent<TPlayerPlanGoalDrill>>({
	goals: (context, event) => {
		const index = context.goals?.findIndex((g: TPlayerPlanGoal) => g.id === event.data.playerPlanGoalId);
		if (index !== undefined && index > -1 && context.goals?.length) {
			const drillIndex = context.goals[index].playerPlanDrills.findIndex(
				(d: TPlayerPlanGoalDrill) => d.id === event.data.id
			);
			if (drillIndex > -1) {
				context.goals[index].playerPlanDrills[drillIndex].isDeleted = true;
				context.goals[index].playerPlanDrills[drillIndex].lastChangeDate = new Date().toString();
			}
		}
		return context.goals ? [...context.goals] : context.goals;
	},
	circularProgressGoalDrillId: (_context, _event) => {
		return null;
	}
});

const doneAddNoteAction = assign<TPlayerPlanContext, DoneInvokeEvent<TPlayerPlanNote>>({
	goals: (context, event) => {
		const index = context.goals?.findIndex((g: TPlayerPlanGoal) => g.id === event.data.playerPlanGoalId);
		if (index !== undefined && index > -1 && context.goals?.length) {
			context.goals[index].playerPlanNotes = [event.data, ...context.goals[index].playerPlanNotes];
		}
		return context.goals ? [...context.goals] : context.goals;
	},
	notes: (context, event) => {
		return context.notes ? [event.data, ...context.notes] : [event.data];
	}
});

const doneAddTargetAction = assign<TPlayerPlanContext, DoneInvokeEvent<TPlayerPlanTargetPostResponse>>({
	goals: (context, event) => {
		const index = context.goals?.findIndex((g: TPlayerPlanGoal) => g.id === event.data.playerPlanGoalId);
		if (index !== undefined && index > -1 && context.goals?.length) {
			context.goals[index].playerPlanTargets.forEach((target: TPlayerPlanTarget) => {
				if (target.season === event.data.season) target.isActive = false;
			});
			if (event.data.secondaryGoalTarget) {
				const secondaryGoalIndex = context.goals.findIndex(
					(g: TPlayerPlanGoal) => g.id === event.data.secondaryGoalTarget?.playerPlanGoalId
				);
				if (secondaryGoalIndex !== undefined && secondaryGoalIndex > -1) {
					context.goals[secondaryGoalIndex].playerPlanTargets.forEach((target: TPlayerPlanTarget) => {
						if (target.season === event.data.secondaryGoalTarget?.season) target.isActive = false;
					});
					context.goals[secondaryGoalIndex].playerPlanTargets = [
						...context.goals[secondaryGoalIndex].playerPlanTargets,
						event.data.secondaryGoalTarget
					];
				}
				delete event.data.secondaryGoalTarget;
			}
			context.goals[index].playerPlanTargets = [...context.goals[index].playerPlanTargets, event.data];
		}
		return context.goals ? [...context.goals] : context.goals;
	}
});

const doneAddSplitsAction = assign<TPlayerPlanContext, DoneInvokeEvent<Array<TPlayerPlanGoalSplit>>>({
	goals: (context, event) => {
		if (event.data != null) {
			const index = context.goals?.findIndex((g: TPlayerPlanGoal) => g.id === event.data[0].playerPlanGoalId);
			if (index !== undefined && index > -1 && context.goals?.length) {
				context.goals[index].playerPlanSplits = event.data;
			}
		}
		return context.goals ? [...context.goals] : context.goals;
	}
});

const doneFetchPlayerIdAction = assign<TPlayerPlanContext, DoneInvokeEvent<number>>({
	playerId: (context, event) => event.data
});

const shouldAddGoal = (context: TPlayerPlanContext, event: TAddGoalEvent) => {
	const { addGoalForm, playerId, enqueueSnackbar } = context;

	// Check for playerId
	if (!playerId) {
		enqueueSnackbar("Could not add goal - missing player id.  Try again or contact an administrator", {
			variant: "error"
		});
		return false;
	}

	// If no target data required, only check metric
	else if (!event.usesTarget) {
		if (addGoalForm.metric) return true;
		enqueueSnackbar("You must enter a metric.", { variant: "error" });
		return false;
	}

	// Otherwise, validate primary goal
	else if (!isPrimaryGoalValid(addGoalForm, !event.usesTarget)) {
		enqueueSnackbar("Could not add goal - Tier 1 Goal Invalid.  Try again or contact an administrator", {
			variant: "error"
		});
		return false;
	}

	// Validate secondary goal, if it exists.
	else if (addGoalForm.secondaryGoal && !isSecondaryGoalValid(addGoalForm)) {
		enqueueSnackbar("Could not add goal - Tier 2 Goal Invalid.  Try again or contact an administrator", {
			variant: "error"
		});
		return false;
	}

	return true;
};

const shouldAddSecondaryGoal = (context: TPlayerPlanContext, event: TAddSecondaryGoalEvent) => {
	const { addGoalForm, playerId, enqueueSnackbar } = context;

	// Check for playerId
	if (!playerId) {
		enqueueSnackbar("Could not add goal - missing player id.  Try again or contact an administrator", {
			variant: "error"
		});
		return false;
	}

	// If no target data required, only check metric
	else if (!event.usesTarget) {
		if (addGoalForm.metric) return true;
		enqueueSnackbar("You must enter a metric.", { variant: "error" });
		return false;
	}

	// Otherwise, validate the secondary goal at the primary level
	else if (!isOnlySecondaryGoalValid(addGoalForm, !event.usesTarget)) {
		enqueueSnackbar("Could not add goal - Tier 2 Goal Invalid.  Try again or contact an administrator", {
			variant: "error"
		});
		return false;
	}

	return true;
};

const shouldAddAttachment = (context: TPlayerPlanContext, event: TAddAttachmentEvent) => {
	const { enqueueSnackbar } = context;
	if (event.value.file || event.value.url?.startsWith("http")) return true;
	enqueueSnackbar("URL must start with http or https", { variant: "error" });
	return false;
};

const PlayerPlanMachine = (playerIdProp: number | null, enqueueSnackbar: TEnqueueSnackbar) =>
	Machine<TPlayerPlanContext, IPlayerPlanStateSchema, TPlayerPlanEvent>(
		{
			id: "player_plan",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				lastGoalPlayerId: null,
				lastNotePlayerId: null,
				lastAttachmentPlayerId: null,
				player: null,
				goals: null,
				notes: null,
				attachments: null,
				transactions: null,
				modalType: null,
				modalGoal: null,
				addGoalForm: DEFAULT_ADD_GOAL_FORM,
				attachmentForm: {
					playerId: null,
					file: null
				},
				attachmentsIsConfirmDelete: {},
				circularProgressAttachmentUri: null,
				circularProgressFocusAreaId: null,
				circularProgressGoalDrillId: null,
				enqueueSnackbar
			},
			states: {
				initializing: {
					always: { target: "initialized" }
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_ADD_GOAL_FORM]: { actions: setAddGoalFormAction },
						[SELECT_PLAYER_ID]: { actions: selectPlayerIdAction },
						[SET_ATTACHMENT_FORM]: { actions: setAttachmentFormAction },
						[SET_ATTACHMENTS_IS_CONFIRM_DELETE]: { actions: setAttachmentsIsConfirmDeleteAction },
						[SET_CIRCULAR_PROGRESS_ATTACHMENT_URI]: { actions: setCircularProgressAttachmentUriAction }
					},
					states: {
						playerData: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: { target: "#fetchingPlayerData", cond: "shouldFetchPlayerData" },
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingPlayerData",
									invoke: {
										src: "fetchPlayerData",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: doneFetchPlayerDataAction
										},
										onError: "idle.errored"
									}
								}
							}
						},
						goalData: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									on: {
										[UPDATE_GOAL]: { target: "updating" },
										[ON_DRAG_END]: { target: "reranking" }
									},
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: { target: "#fetchingGoalData", cond: "shouldFetchGoalData" },
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingGoalData",
									invoke: {
										src: "fetchGoalData",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: doneFetchGoalDataAction
										},
										onError: "idle.errored"
									}
								},
								updating: {
									invoke: {
										src: "updateGoal",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: doneUpdateGoalAction
										},
										onError: {
											target: "idle.errored",
											actions: "enqueueUpdateGoalError"
										}
									}
								},
								reranking: {
									invoke: {
										src: "rerankGoal",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: doneRerankGoalAction
										},
										onError: "idle.errored"
									}
								}
							}
						},
						noteData: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: { target: "#fetchingNoteData", cond: "shouldFetchNoteData" },
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingNoteData",
									invoke: {
										src: "fetchNoteData",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: doneFetchNoteDataAction
										},
										onError: "idle.errored"
									}
								}
							}
						},
						attachmentData: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingAttachmentData",
												cond: "shouldFetchAttachmentData"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingAttachmentData",
									invoke: {
										src: "fetchAttachmentData",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: doneFetchAttachmentDataAction
										},
										onError: "idle.errored"
									}
								}
							}
						},
						transactionData: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingTransactionData",
												cond: "shouldFetchTransactionData"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingTransactionData",
									invoke: {
										src: "fetchTransactionData",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: doneFetchTransactionDataAction
										},
										onError: "idle.errored"
									}
								}
							}
						},
						playerId: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									on: {
										[SELECT_PHIL_ID]: { target: "fetching" }
									},
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									invoke: {
										src: "fetchPlayerId",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: doneFetchPlayerIdAction
										},
										onError: "idle.errored"
									}
								}
							}
						},
						modal: {
							initial: "modalNotOpen",
							states: {
								errored: {
									id: "erroredNode"
								},
								modalOpen: {
									on: {
										[CLOSE_MODAL]: { target: "modalNotOpen" },
										[ADD_GOAL]: { target: "addingGoal", cond: shouldAddGoal },
										[ADD_ATTACHMENT]: { target: "addingAttachment", cond: shouldAddAttachment },
										[DELETE_ATTACHMENT]: { target: "deletingAttachment" },
										[ADD_SECONDARY_GOAL]: {
											target: "addingSecondaryGoalDraft",
											cond: shouldAddSecondaryGoal
										}
									}
								},
								modalNotOpen: {
									on: {
										[OPEN_MODAL]: { actions: setModalType, target: "modalOpen" },
										[ADD_SECONDARY_GOAL]: {
											target: "addingSecondaryGoal",
											cond: shouldAddSecondaryGoal
										}
									}
								},
								addingGoal: {
									invoke: {
										src: "addGoal",
										onDone: {
											target: "modalNotOpen",
											actions: doneAddGoalAction
										},
										onError: "errored"
									}
								},
								addingSecondaryGoal: {
									invoke: {
										src: "addGoal",
										onDone: {
											target: "modalNotOpen",
											actions: doneAddGoalAction
										},
										onError: "errored"
									}
								},
								addingSecondaryGoalDraft: {
									invoke: {
										src: "addGoal",
										onDone: {
											target: "modalOpen",
											actions: doneAddGoalAction
										},
										onError: "errored"
									}
								},
								addingAttachment: {
									invoke: {
										src: "addAttachment",
										onDone: {
											target: "modalOpen",
											actions: doneAddAttachmentAction
										},
										onError: "errored"
									}
								},
								deletingAttachment: {
									invoke: {
										src: "deleteAttachment",
										onDone: {
											target: "modalOpen",
											actions: doneDeleteAttachmentAction
										},
										onError: {
											target: "modalOpen",
											actions: [
												"enqueueDeleteAttachmentError",
												"setCircularProgressAttachmentUriErrored"
											]
										}
									}
								}
							}
						},
						focusAreasAndDrills: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									on: {
										[ADD_FOCUS_AREA]: { target: "addingFocusArea" },
										[DELETE_FOCUS_AREA]: { target: "deletingFocusArea" },
										[ADD_GOAL_DRILL]: { target: "addingGoalDrill" },
										[DELETE_GOAL_DRILL]: { target: "deletingGoalDrill" },
										[SET_CIRCULAR_PROGRESS_FOCUS_AREA_ID]: {
											actions: setCircularProgressFocusAreaIdAction
										},
										[SET_CIRCULAR_PROGRESS_GOAL_DRILL_ID]: {
											actions: setCircularProgressGoalDrillIdAction
										}
									},
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								addingFocusArea: {
									invoke: {
										src: "addFocusArea",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: doneAddGoalAction // Focus Areas and goals use the same API request
										},
										onError: {
											target: "idle.errored",
											actions: "enqueueAddFocusAreaError"
										}
									}
								},
								deletingFocusArea: {
									invoke: {
										src: "deleteFocusArea",
										onDone: {
											target: "idle.notErrored.postFetch",
											// Deleting a Focus Area involves setting its status to CANCELED
											actions: doneDeleteFocusAreaAction
										},
										onError: {
											target: "idle.errored",
											actions: [
												"setCircularProgressFocusAreaIdErrored",
												"enqueueDeleteFocusAreaError"
											]
										}
									}
								},
								addingGoalDrill: {
									invoke: {
										src: "addGoalDrill",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: doneAddGoalDrillAction
										},
										onError: {
											target: "idle.errored",
											actions: "enqueueAddGoalDrillError"
										}
									}
								},
								deletingGoalDrill: {
									invoke: {
										src: "deleteGoalDrill",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: doneDeleteGoalDrillAction
										},
										onError: {
											target: "idle.errored",
											actions: [
												"setCircularProgressGoalDrillIdErrored",
												"enqueueDeleteGoalDrillError"
											]
										}
									}
								}
							}
						},
						addNote: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									on: {
										[ADD_NOTE]: { target: "adding" }
									},
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								adding: {
									invoke: {
										src: "addNote",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: doneAddNoteAction
										},
										onError: "idle.errored"
									}
								}
							}
						},
						addTarget: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									on: {
										[ADD_TARGET]: { target: "adding" }
									},
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								adding: {
									invoke: {
										src: "addTarget",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: doneAddTargetAction
										},
										onError: {
											target: "idle.errored",
											actions: "enqueueAddTargetError"
										}
									}
								}
							}
						},
						addGoalSplits: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									on: {
										[ADD_GOAL_SPLITS]: { target: "adding" }
									},
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								adding: {
									invoke: {
										src: "addGoalSplits",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: doneAddSplitsAction
										},
										onError: "idle.errored"
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldFetchPlayerData: (context, _event) => {
					const { playerId, player } = context;
					if (playerId && playerId !== player?.id) return true;
					return false;
				},
				shouldFetchGoalData: (context, _event) => {
					const { playerId, lastGoalPlayerId } = context;
					if (playerId !== lastGoalPlayerId) return true;
					return false;
				},
				shouldFetchNoteData: (context, _event) => {
					const { playerId, lastNotePlayerId } = context;
					if (playerId !== lastNotePlayerId) return true;
					return false;
				},
				shouldFetchAttachmentData: (context, _event) => {
					const { playerId, lastAttachmentPlayerId } = context;
					if (playerId !== lastAttachmentPlayerId) return true;
					return false;
				},
				shouldFetchTransactionData: (context, _event) => {
					const { player, transactions } = context;
					if (player && !transactions) return true;
					else if (player && transactions && player.ebisId !== transactions[0]?.ebis_id) return true;
					return false;
				}
			},
			actions: {
				setCircularProgressAttachmentUriErrored: assign({
					circularProgressAttachmentUri: (_context, _event) => null
				}),
				setCircularProgressFocusAreaIdErrored: assign({
					circularProgressFocusAreaId: (_context, _event) => null
				}),
				setCircularProgressGoalDrillIdErrored: assign({
					circularProgressGoalDrillId: (_context, _event) => null
				}),
				enqueueDeleteAttachmentError: (context, _event) => {
					context.enqueueSnackbar(
						"Error occurred while deleting attachment for this player. Try again or reach out to an administrator.",
						{ variant: "error" }
					);
				},
				enqueueUpdateGoalError: (context, _event) => {
					context.enqueueSnackbar(
						"Error occurred while changing goal status. Try again or reach out to an administrator.",
						{ variant: "error" }
					);
				},
				enqueueAddTargetError: (context, _event) => {
					context.enqueueSnackbar(
						"Error occurred while adding adjustment for this player. Try again or reach out to an administrator.",
						{ variant: "error" }
					);
				},
				enqueueAddGoalDrillError: (context, _event) => {
					context.enqueueSnackbar(
						"Error occurred while adding Supporting Action for this player. Try again or reach out to an administrator.",
						{ variant: "error" }
					);
				},
				enqueueDeleteGoalDrillError: (context, _event) => {
					context.enqueueSnackbar(
						"Error occurred while deleting Supporting Action for this player. Try again or reach out to an administrator.",
						{ variant: "error" }
					);
				},
				enqueueAddFocusAreaError: (context, _event) => {
					context.enqueueSnackbar(
						"Error occurred while adding Focus Area for this player. Try again or reach out to an administrator.",
						{ variant: "error" }
					);
				},
				enqueueDeleteFocusAreaError: (context, _event) => {
					context.enqueueSnackbar(
						"Error occurred while deleting Focus Area for this player. Try again or reach out to an administrator.",
						{ variant: "error" }
					);
				}
			},
			services: {
				fetchPlayerData: (context, _event) => {
					const { playerId } = context;
					if (playerId) {
						return fetchPlayerData(playerId);
					}
					return Promise.resolve(null);
				},
				fetchGoalData: (context, _event) => {
					const { playerId } = context;
					if (playerId) {
						const response = fetchGoalData(playerId, true, FETCH_STATUSES, ALL_STATUSES);
						return response;
					}
					return Promise.resolve(null);
				},
				fetchNoteData: (context, _event) => {
					const { playerId } = context;
					if (playerId) {
						const response = fetchNoteData({ playerId: playerId } as TNoteFilters);
						return response;
					}
					return Promise.resolve(null);
				},
				fetchAttachmentData: (context, _event) => {
					const { playerId } = context;
					if (playerId) {
						const response = fetchAttachmentData(playerId);
						return response;
					}
					return Promise.resolve(null);
				},
				fetchTransactionData: (context, _event) => {
					const { player } = context;
					if (player?.ebisId) {
						const response = fetchTransactionData(player.ebisId);
						return response;
					}
					return Promise.resolve(null);
				},
				fetchPlayerId: (_context, event) => {
					if (event.type === SELECT_PHIL_ID && event.value && event.playerClassification) {
						const response = fetchPlayerId(event.value, event.playerClassification);
						return response;
					}
					return Promise.resolve(null);
				},
				addGoal: (context, event) => {
					const { addGoalForm, playerId } = context;
					if (event.type !== ADD_GOAL && event.type !== ADD_SECONDARY_GOAL) return Promise.reject();
					return addGoal(playerId, addGoalForm, event.usesTarget);
				},
				// Special case of adding goal
				addFocusArea: (context, event) => {
					const { playerId } = context;
					if (event.type !== ADD_FOCUS_AREA) return Promise.reject();
					return addGoal(playerId, event.value, false);
				},
				// Special case of updating goal
				deleteFocusArea: (_context, event) => {
					if (event.type !== DELETE_FOCUS_AREA) return Promise.reject();
					return updateGoal(event.value, undefined, event.deleteGoalDrillIds);
				},
				updateGoal: (_context, event) => {
					if (event.type !== UPDATE_GOAL) return Promise.reject();
					return updateGoal(event.value, event?.updateApprovalDate);
				},
				rerankGoal: (_context, event) => {
					if (event.type !== ON_DRAG_END) return Promise.reject();
					return rerankGoal(event.value, event.destination);
				},
				addGoalDrill: (_context, event) => {
					if (event.type !== ADD_GOAL_DRILL) return Promise.reject();
					return addGoalDrill(event.value);
				},
				deleteGoalDrill: (_context, event) => {
					if (event.type !== DELETE_GOAL_DRILL) return Promise.reject();
					return deleteGoalDrill(event.value);
				},
				addNote: (_context, event) => {
					if (event.type !== ADD_NOTE) return Promise.reject();
					return addNote(event.value);
				},
				addTarget: (_context, event) => {
					if (event.type !== ADD_TARGET) return Promise.reject();
					return addTarget(event.value);
				},
				addGoalSplits: (_context, event) => {
					if (event.type !== ADD_GOAL_SPLITS) return Promise.reject();
					return addGoalSplits(event.value);
				},
				addAttachment: (_context, event) => {
					if (event.type !== ADD_ATTACHMENT) return Promise.reject();
					return addAttachment(event.value);
				},
				deleteAttachment: (_context, event) => {
					if (event.type !== DELETE_ATTACHMENT) return Promise.reject();
					return deleteAttachment(event.value);
				}
			}
		}
	);

export default PlayerPlanMachine;
