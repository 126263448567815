import { TOption } from "_react/inputs";
import { ICommandLeaderBoardPitcher } from "_react/shared/_types/schema/command_chart";
import { decimalToPercent } from "utils/helpers";

import { getPitcherName } from "_react/command/_helpers";

// Zone Labels
export const UL = "UL";
export const UR = "UR";
export const DL = "DL";
export const DR = "DR";
export const MM = "MM";

// Zone section keys
export const DOWN_LEFT = "down_left";
export const DOWN_RIGHT = "down_right";
export const MEATBALL = "meatball";
export const OOZ_DOWN = "ooz_down";
export const OOZ_DOWN_LEFT = "ooz_down_left";
export const OOZ_DOWN_RIGHT = "ooz_down_right";
export const OOZ_UP = "ooz_up";
export const OOZ_UP_LEFT = "ooz_up_left";
export const OOZ_UP_RIGHT = "ooz_up_right";
export const UP_LEFT = "up_left";
export const UP_RIGHT = "up_right";

// Strike zone and zone section constants (in ft.)
export const PLATE_WIDTH = 17 / 12.0;
export const PLATE_HALF = PLATE_WIDTH / 2.0;
export const SZ_TOP = 3.428479;
export const SZ_BOTTOM = 1.5477075;
export const SZ_HALF = (SZ_TOP - SZ_BOTTOM) / 2.0 + SZ_BOTTOM;
export const MEATBALL_EDGE = 0.3649949577;
export const MEATBALL_TOP = SZ_HALF + MEATBALL_EDGE;
export const MEATBALL_BOTTOM = SZ_HALF - MEATBALL_EDGE;

// Pitch Type Selector Options
export const PITCH_TYPE_OPTIONS: TOption<string>[] = [
	{ value: "FF", label: "Four-seam" },
	{ value: "SI", label: "Sinker" },
	{ value: "CT", label: "Cutter" },
	{ value: "CB", label: "Curveball" },
	{ value: "SL", label: "Slider" },
	{ value: "SW", label: "Sweeper" },
	{ value: "SV", label: "Slurve" },
	{ value: "CH", label: "Changeup" },
	{ value: "SP", label: "Splitter" }
];

// Constants used to parse density data returned by pitcher metrics/report API routes
// Zone sections to display
export const ZONE_SECTION_KEYS: Array<typeof DL | typeof DR | typeof UL | typeof UR | typeof MM> = [DL, DR, UL, UR, MM];
// Batter contexts to display
export const BATTER_CONTEXT_KEYS: Array<"allBatters" | "RHBatters" | "LHBatters"> = [
	"allBatters",
	"RHBatters",
	"LHBatters"
];
export const BATTER_TITLES: { allBatters: string; RHBatters: string; LHBatters: string } = {
	allBatters: "All Batters",
	RHBatters: "RHB",
	LHBatters: "LHB"
};
// Pitch type combinations to display
export const PITCH_TYPE_CONTEXT_KEYS: Array<"FF" | "SI/2S" | "SL/CT" | "CB" | "CH/SP"> = [
	"FF",
	"SI/2S",
	"SL/CT",
	"CB",
	"CH/SP"
];

// Leaderboard Constants
export const INCH_MISS_COLUMNS = [
	{ title: "Name", name: "pitcherName", getCellValue: (row: ICommandLeaderBoardPitcher) => getPitcherName(row) },
	{ title: "Season", name: "season" },
	{ title: "Pitch Type", name: "pitchType" },
	{ title: "Number of Pitches", name: "numPitches" },
	{
		title: "Avg Miss (In.)",
		name: "avgInchMiss",
		getCellValue: (row: ICommandLeaderBoardPitcher) => row.avgInchMiss?.toFixed(2)
	}
];

export const SOLID_COLUMNS = [
	{ title: "Name", name: "pitcherName", getCellValue: (row: ICommandLeaderBoardPitcher) => getPitcherName(row) },
	{ title: "Season", name: "season" },
	{ title: "Pitch Type", name: "pitchType" },
	{ title: "Number of Pitches", name: "numPitches" },
	{
		title: "Solid Execution %",
		name: "solidPct",
		getCellValue: (row: ICommandLeaderBoardPitcher) => decimalToPercent(row.solidPct, 0, true)
	}
];

export const BIG_MISS_COLUMNS = [
	{ title: "Name", name: "pitcherName", getCellValue: (row: ICommandLeaderBoardPitcher) => getPitcherName(row) },
	{ title: "Season", name: "season" },
	{ title: "Pitch Type", name: "pitchType" },
	{ title: "Number of Pitches", name: "numPitches" },
	{
		title: "Big Miss %",
		name: "bigMissPct",
		getCellValue: (row: ICommandLeaderBoardPitcher) => decimalToPercent(row.bigMissPct, 0, true)
	}
];

export const STUFF_COLUMNS = [
	{ title: "Name", name: "pitcherName", getCellValue: (row: ICommandLeaderBoardPitcher) => getPitcherName(row) },
	{ title: "Season", name: "season" },
	{ title: "Pitch Type", name: "pitchType" },
	{ title: "Number of Pitches", name: "numPitches" },
	{
		title: "Stuff",
		name: "avgStuffGrade",
		getCellValue: (row: ICommandLeaderBoardPitcher) => row.avgStuffGrade?.toFixed(0)
	}
];
