import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { promiseWRetry } from "utils/helpers";
import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import {
	COUNT_SPLIT_OVERALL,
	GAME_TYPE_OVERALL,
	PITCH_TYPE_OVERALL,
	PLAYING_LEVEL_AMA,
	PLAYING_LEVEL_INTL
} from "_react/shared/data_models/arsenal_scores/_constants";
import {
	fetchArsenalScoresThreshold,
	fetchPlayerSeasonArsenalScores
} from "_react/shared/data_models/arsenal_scores/_network";
import {
	IArsenalScoresThresholdApiResponse,
	IPlayerSeasonArsenalScoresSchema
} from "_react/shared/data_models/arsenal_scores/_types";
import { TPlayingLevel } from "_react/shared/data_models/hitter_grades/_types";
import { fetchPlayerMetricRollingAverages } from "_react/shared/data_models/metric/_network";
import { getCancelSource } from "utils/url_helpers";
import { IMetricRollingAverages, TMetricGroup } from "_react/shared/data_models/metric/_types";
import { BATS_OVERALL } from "_react/shared/data_models/seasonal_grades/_constants";

import {
	TPitcherMetricOverTimePrimaryPlotData,
	TPitcherMetricOverTimeSecondaryPlotData
} from "_react/shared/ui/data/plots/PitcherMetricOverTimePlot/PitcherMetricOverTimePlot";

const ROLLING_AVERAGES_CANCEL_SOURCE = "rollingAverages";
const ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE = "arsenalScoresThreshold";
const PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE = "playerSeasonArsenalScores";

export type TPitcherMetricOverTimePlotCancelSource = {
	[ROLLING_AVERAGES_CANCEL_SOURCE]?: CancelTokenSource;
	[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE]?: CancelTokenSource;
	[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE]?: CancelTokenSource;
};

export type TRollingAverages = Record<string, Array<IMetricRollingAverages> | null>;

export type TPitcherMetricOverTimePlotContext = {
	batsFilter: string;
	throwsFilter?: string;
	seasonFilter: number;
	lastSeasonFilter: number;
	playerId?: number;
	lastPlayerId?: number;
	playingLevel?: TPlayingLevel;
	lastPlayingLevel?: string;
	metricGroup?: TMetricGroup;
	lastMetricGroup?: TMetricGroup;
	shouldFetchPrimaryData?: boolean;
	shouldFetchSecondaryData?: boolean;
	rollingAverages: TRollingAverages;
	playerSeasonArsenalScores?: Array<IPlayerSeasonArsenalScoresSchema>;
	arsenalScoresThreshold?: Array<IArsenalScoresThresholdApiResponse> | null;
	cancelSources: TPitcherMetricOverTimePlotCancelSource;
	toast?: CreateToastFnReturn;
};

interface IPitcherMetricOverTimePlotStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Refreshes the context when the playerId prop changes
				playerIdRefresh: {
					states: {
						idle: {};
						clearing: {};
					};
				};
				// Fetches rolling averages data for a player and metric group
				rollingAverages: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches arsenal scores thresholds data
				arsenalScoresThreshold: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
				// Fetches player's seasonal arsenal scores
				playerSeasonArsenalScores: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const SET_ROLLING_AVERAGES = "SET_ROLLING_AVERAGES";
export const SET_ARSENAL_SCORES_THRESHOLD = "SET_ARSENAL_SCORES_THRESHOLD";
export const SET_PLAYER_SEASON_ARSENAL_SCORES = "SET_PLAYER_SEASON_ARSENAL_SCORES";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_METRIC_GROUP = "SET_METRIC_GROUP";
export const SET_BATS_FILTER = "SET_BATS_FILTER";
export const SET_THROWS_FILTER = "SET_THROWS_FILTER";
export const SET_SEASON_FILTER = "SET_SEASON_FILTER";
export const FETCHING_ROLLING_AVERAGES = {
	initialized: { rollingAverages: "fetching" }
};
export const FETCHING_ARSENAL_SCORES_THRESHOLD = { initialized: { arsenalScoresThreshold: "fetching" } };
export const FETCHING_PLAYER_SEASON_ARSENAL_SCORES = {
	initialized: { playerSeasonArsenalScores: "fetching" }
};

const FETCH_ROLLING_AVERAGES_DONE = "done.invoke.fetchingRollingAverages:invocation[0]";
const FETCH_ARSENAL_SCORES_THRESHOLD_DONE = "done.invoke.fetchingArsenalScoresThreshold:invocation[0]";
const FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE = "done.invoke.fetchingPlayerSeasonArsenalScores:invocation[0]";

const FETCH_ROLLING_AVERAGES_ERROR = "error.platform.fetchingRollingAverages:invocation[0]";
const FETCH_ARSENAL_SCORES_THRESHOLD_ERROR = "error.platform.fetchingArsenalScoresThreshold:invocation[0]";
const FETCH_PLAYER_SEASON_ARSENAL_SCORES_ERROR = "error.platform.fetchingPlayerSeasonArsenalScores:invocation[0]";

type TRollingAveragesEvent = {
	type: typeof SET_ROLLING_AVERAGES;
	data: TRollingAverages;
};
type TSetArsenalScoresThresholdEvent = {
	type: typeof SET_ARSENAL_SCORES_THRESHOLD;
	data: Array<IArsenalScoresThresholdApiResponse> | null | undefined;
};
type TSetPlayerSeasonArsenalScoresEvent = {
	type: typeof SET_PLAYER_SEASON_ARSENAL_SCORES;
	data: Array<IPlayerSeasonArsenalScoresSchema> | undefined;
};
type TSetPlayerIdEvent = {
	type: typeof SET_PLAYER_ID;
	data: number | undefined;
};
type TSetMetricGroupEvent = {
	type: typeof SET_METRIC_GROUP;
	data: TMetricGroup | undefined;
};
type TSetBatsFilterEvent = {
	type: typeof SET_BATS_FILTER;
	data: string;
};
type TSetThrowsFilterEvent = {
	type: typeof SET_THROWS_FILTER;
	data: string | undefined;
};
type TSetSeasonFilterEvent = {
	type: typeof SET_SEASON_FILTER;
	data: number;
};
type TFetchRollingAveragesEvent = {
	type: typeof FETCH_ROLLING_AVERAGES_DONE;
	data?: Array<IMetricRollingAverages>;
};
type TFetchArsenalScoresThresholdEvent = {
	type: typeof FETCH_ARSENAL_SCORES_THRESHOLD_DONE;
	data?: Array<IArsenalScoresThresholdApiResponse>;
};
type TFetchPlayerSeasonArsenalScoresEvent = {
	type: typeof FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE;
	data?: Array<IPlayerSeasonArsenalScoresSchema>;
};
type TFetchRollingAveragesErrorEvent = {
	type: typeof FETCH_ROLLING_AVERAGES_ERROR;
	data?: AxiosError | Error;
};
type TFetchArsenalScoresThresholdErrorEvent = {
	type: typeof FETCH_ARSENAL_SCORES_THRESHOLD_ERROR;
	data?: AxiosError | Error;
};
type TFetchPlayerSeasonArsenalScoresErrorEvent = {
	type: typeof FETCH_PLAYER_SEASON_ARSENAL_SCORES_ERROR;
	data?: AxiosError | Error;
};

type TPitcherMetricOverTimePlotEvent =
	| TRollingAveragesEvent
	| TSetArsenalScoresThresholdEvent
	| TSetPlayerSeasonArsenalScoresEvent
	| TSetPlayerIdEvent
	| TSetMetricGroupEvent
	| TSetBatsFilterEvent
	| TSetThrowsFilterEvent
	| TSetSeasonFilterEvent
	| TFetchRollingAveragesEvent
	| TFetchArsenalScoresThresholdEvent
	| TFetchPlayerSeasonArsenalScoresEvent
	| TFetchRollingAveragesErrorEvent
	| TFetchArsenalScoresThresholdErrorEvent
	| TFetchPlayerSeasonArsenalScoresErrorEvent;

export type TPitcherMetricOverTimePlotSend = Interpreter<
	TPitcherMetricOverTimePlotContext,
	IPitcherMetricOverTimePlotStateSchema,
	TPitcherMetricOverTimePlotEvent
>["send"];

const PitcherMetricOverTimePlotMachine = (
	batsFilterProp: string,
	throwsFilterProp: string | undefined,
	seasonFilterProp: number,
	playerIdProp?: number,
	playingLevelProp?: TPlayingLevel,
	metricGroupProp?: TMetricGroup,
	shouldFetchPrimaryData = true,
	primaryData?: TPitcherMetricOverTimePrimaryPlotData,
	shouldFetchSecondaryData = true,
	secondaryData?: TPitcherMetricOverTimeSecondaryPlotData,
	toastProp?: CreateToastFnReturn
) =>
	Machine<TPitcherMetricOverTimePlotContext, IPitcherMetricOverTimePlotStateSchema, TPitcherMetricOverTimePlotEvent>(
		{
			id: "PitcherMetricOverTimePlot",
			initial: "initializing",
			context: {
				batsFilter: batsFilterProp,
				throwsFilter: throwsFilterProp,
				seasonFilter: seasonFilterProp,
				lastSeasonFilter: seasonFilterProp,
				playerId: playerIdProp,
				lastPlayerId: playerIdProp,
				playingLevel: playingLevelProp,
				lastPlayingLevel: playingLevelProp,
				metricGroup: metricGroupProp,
				lastMetricGroup: metricGroupProp,
				shouldFetchPrimaryData: shouldFetchPrimaryData,
				rollingAverages: primaryData?.rollingAverages ?? {},
				playerSeasonArsenalScores: secondaryData?.playerSeasonArsenalScores,
				shouldFetchSecondaryData: shouldFetchSecondaryData,
				arsenalScoresThreshold: secondaryData?.arsenalScoresThreshold,
				cancelSources: {},
				toast: toastProp
			},
			states: {
				initializing: {
					always: {
						target: "initialized"
					}
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_ROLLING_AVERAGES]: { actions: "setRollingAverages" },
						[SET_ARSENAL_SCORES_THRESHOLD]: { actions: "setArsenalScoresThreshold" },
						[SET_PLAYER_SEASON_ARSENAL_SCORES]: { actions: "setPlayerSeasonArsenalScores" },
						[SET_PLAYER_ID]: { actions: "setPlayerId" },
						[SET_METRIC_GROUP]: { actions: "setMetricGroup" },
						[SET_BATS_FILTER]: { actions: "setBatsFilter" },
						[SET_THROWS_FILTER]: { actions: "setThrowsFilter" },
						[SET_SEASON_FILTER]: { actions: "setSeasonFilter" }
					},
					states: {
						playerIdRefresh: {
							initial: "idle",
							states: {
								idle: {
									always: { target: "clearing", cond: "shouldClearContext" }
								},
								clearing: {
									always: { target: "idle", actions: "clearContext" }
								}
							}
						},
						rollingAverages: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingRollingAverages",
												cond: "shouldFetchRollingAverages"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingRollingAverages",
									entry: ["refreshRollingAveragesCancelSource"],
									invoke: {
										src: "fetchRollingAverages",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchRollingAveragesSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchRollingAveragesErrored"
										}
									}
								}
							}
						},
						arsenalScoresThreshold: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingArsenalScoresThreshold",
												cond: "shouldFetchArsenalScoresThreshold"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingArsenalScoresThreshold",
									entry: ["refreshArsenalScoresThresholdCancelSource"],
									invoke: {
										src: "fetchArsenalScoresThreshold",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchArsenalScoresThresholdSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchArsenalScoresThresholdErrored"
										}
									}
								}
							}
						},
						playerSeasonArsenalScores: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchingPlayerSeasonArsenalScores",
												cond: "shouldFetchPlayerSeasonArsenalScores"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchingPlayerSeasonArsenalScores",
									entry: ["refreshPlayerSeasonArsenalScoresCancelSource"],
									invoke: {
										src: "fetchPlayerSeasonArsenalScores",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchPlayerSeasonArsenalScoresSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchPlayerSeasonArsenalScoresErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldClearContext: (
					context: TPitcherMetricOverTimePlotContext,
					_event: TPitcherMetricOverTimePlotEvent
				) =>
					context.playerId !== context.lastPlayerId ||
					context.playingLevel !== context.lastPlayingLevel ||
					context.metricGroup !== context.lastMetricGroup ||
					context.seasonFilter !== context.lastSeasonFilter,
				shouldFetchRollingAverages: (
					context: TPitcherMetricOverTimePlotContext,
					_event: TPitcherMetricOverTimePlotEvent
				) => {
					const { rollingAverages, batsFilter, throwsFilter, playerId } = context;
					return (
						rollingAverages[`${batsFilter}-${throwsFilter}`] === undefined &&
						shouldFetchPrimaryData &&
						playerId !== undefined
					);
				},
				shouldFetchArsenalScoresThreshold: (
					context: TPitcherMetricOverTimePlotContext,
					_event: TPitcherMetricOverTimePlotEvent
				) =>
					context.arsenalScoresThreshold === undefined &&
					(context.playingLevel === PLAYING_LEVEL_AMA || context.playingLevel === PLAYING_LEVEL_INTL) &&
					shouldFetchSecondaryData,
				shouldFetchPlayerSeasonArsenalScores: (
					context: TPitcherMetricOverTimePlotContext,
					_event: TPitcherMetricOverTimePlotEvent
				) => {
					const { playerSeasonArsenalScores, playerId, playingLevel } = context;
					return (
						playerSeasonArsenalScores === undefined &&
						shouldFetchSecondaryData &&
						playerId !== undefined &&
						(playingLevel === PLAYING_LEVEL_AMA || playingLevel === PLAYING_LEVEL_INTL)
					);
				}
			},
			actions: {
				setRollingAverages: assign<TPitcherMetricOverTimePlotContext, TPitcherMetricOverTimePlotEvent>({
					rollingAverages: (
						context: TPitcherMetricOverTimePlotContext,
						event: TPitcherMetricOverTimePlotEvent
					) => {
						if (event.type !== SET_ROLLING_AVERAGES) return context.rollingAverages;
						return event.data;
					},
					cancelSources: (
						context: TPitcherMetricOverTimePlotContext,
						event: TPitcherMetricOverTimePlotEvent
					) => {
						if (event.type !== SET_ROLLING_AVERAGES) return context.cancelSources;
						if (context.cancelSources[ROLLING_AVERAGES_CANCEL_SOURCE] != null)
							context.cancelSources[ROLLING_AVERAGES_CANCEL_SOURCE].cancel();
						delete context.cancelSources[ROLLING_AVERAGES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setArsenalScoresThreshold: assign<TPitcherMetricOverTimePlotContext, TPitcherMetricOverTimePlotEvent>({
					arsenalScoresThreshold: (
						context: TPitcherMetricOverTimePlotContext,
						event: TPitcherMetricOverTimePlotEvent
					) => {
						if (event.type !== SET_ARSENAL_SCORES_THRESHOLD) return context.arsenalScoresThreshold;
						return event.data;
					},
					cancelSources: (
						context: TPitcherMetricOverTimePlotContext,
						event: TPitcherMetricOverTimePlotEvent
					) => {
						if (event.type !== SET_ARSENAL_SCORES_THRESHOLD) return context.cancelSources;
						if (context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE].cancel();
						delete context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerSeasonArsenalScores: assign<
					TPitcherMetricOverTimePlotContext,
					TPitcherMetricOverTimePlotEvent
				>({
					playerSeasonArsenalScores: (
						context: TPitcherMetricOverTimePlotContext,
						event: TPitcherMetricOverTimePlotEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES) return context.playerSeasonArsenalScores;
						return event.data;
					},
					cancelSources: (
						context: TPitcherMetricOverTimePlotContext,
						event: TPitcherMetricOverTimePlotEvent
					) => {
						if (event.type !== SET_PLAYER_SEASON_ARSENAL_SCORES) return context.cancelSources;
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE].cancel();
						delete context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE];
						return context.cancelSources;
					}
				}),
				setPlayerId: assign<TPitcherMetricOverTimePlotContext, TPitcherMetricOverTimePlotEvent>({
					playerId: (context: TPitcherMetricOverTimePlotContext, event: TPitcherMetricOverTimePlotEvent) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.data;
					}
				}),
				setMetricGroup: assign<TPitcherMetricOverTimePlotContext, TPitcherMetricOverTimePlotEvent>({
					metricGroup: (
						context: TPitcherMetricOverTimePlotContext,
						event: TPitcherMetricOverTimePlotEvent
					) => {
						if (event.type !== SET_METRIC_GROUP) return context.metricGroup;
						return event.data;
					}
				}),
				setBatsFilter: assign<TPitcherMetricOverTimePlotContext, TPitcherMetricOverTimePlotEvent>({
					batsFilter: (
						context: TPitcherMetricOverTimePlotContext,
						event: TPitcherMetricOverTimePlotEvent
					) => {
						if (event.type !== SET_BATS_FILTER) return context.batsFilter;
						return event.data;
					}
				}),
				setThrowsFilter: assign<TPitcherMetricOverTimePlotContext, TPitcherMetricOverTimePlotEvent>({
					throwsFilter: (
						context: TPitcherMetricOverTimePlotContext,
						event: TPitcherMetricOverTimePlotEvent
					) => {
						if (event.type !== SET_THROWS_FILTER) return context.throwsFilter;
						return event.data;
					}
				}),
				setSeasonFilter: assign<TPitcherMetricOverTimePlotContext, TPitcherMetricOverTimePlotEvent>({
					seasonFilter: (
						context: TPitcherMetricOverTimePlotContext,
						event: TPitcherMetricOverTimePlotEvent
					) => {
						if (event.type !== SET_SEASON_FILTER) return context.seasonFilter;
						return event.data;
					}
				}),
				clearContext: assign<TPitcherMetricOverTimePlotContext, TPitcherMetricOverTimePlotEvent>({
					lastPlayerId: (
						context: TPitcherMetricOverTimePlotContext,
						_event: TPitcherMetricOverTimePlotEvent
					) => context.playerId,
					lastMetricGroup: (
						context: TPitcherMetricOverTimePlotContext,
						_event: TPitcherMetricOverTimePlotEvent
					) => context.metricGroup,
					lastSeasonFilter: (
						context: TPitcherMetricOverTimePlotContext,
						_event: TPitcherMetricOverTimePlotEvent
					) => context.seasonFilter,
					rollingAverages: (
						_context: TPitcherMetricOverTimePlotContext,
						_event: TPitcherMetricOverTimePlotEvent
					) => {
						return {};
					},
					playerSeasonArsenalScores: (
						_context: TPitcherMetricOverTimePlotContext,
						_event: TPitcherMetricOverTimePlotEvent
					) => {
						return undefined;
					},
					cancelSources: (
						context: TPitcherMetricOverTimePlotContext,
						_event: TPitcherMetricOverTimePlotEvent
					) => {
						Object.values(context.cancelSources).forEach((tokenSource: CancelTokenSource) =>
							tokenSource.cancel()
						);
						return {};
					}
				}),
				// Cancel Source Actions
				refreshRollingAveragesCancelSource: assign<
					TPitcherMetricOverTimePlotContext,
					TPitcherMetricOverTimePlotEvent
				>({
					cancelSources: (
						context: TPitcherMetricOverTimePlotContext,
						_event: TPitcherMetricOverTimePlotEvent
					) => {
						if (context.cancelSources[ROLLING_AVERAGES_CANCEL_SOURCE] != null)
							context.cancelSources[ROLLING_AVERAGES_CANCEL_SOURCE].cancel();
						context.cancelSources[ROLLING_AVERAGES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshArsenalScoresThresholdCancelSource: assign<
					TPitcherMetricOverTimePlotContext,
					TPitcherMetricOverTimePlotEvent
				>({
					cancelSources: (
						context: TPitcherMetricOverTimePlotContext,
						_event: TPitcherMetricOverTimePlotEvent
					) => {
						if (context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] != null)
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE].cancel();
						context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				refreshPlayerSeasonArsenalScoresCancelSource: assign<
					TPitcherMetricOverTimePlotContext,
					TPitcherMetricOverTimePlotEvent
				>({
					cancelSources: (
						context: TPitcherMetricOverTimePlotContext,
						_event: TPitcherMetricOverTimePlotEvent
					) => {
						if (context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] != null)
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE].cancel();
						context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE] = getCancelSource();
						return context.cancelSources;
					}
				}),
				// Fetch Success Actions
				handleFetchRollingAveragesSuccess: assign<
					TPitcherMetricOverTimePlotContext,
					TPitcherMetricOverTimePlotEvent
				>({
					rollingAverages: (
						context: TPitcherMetricOverTimePlotContext,
						event: TPitcherMetricOverTimePlotEvent
					) => {
						const { rollingAverages, batsFilter, throwsFilter } = context;
						if (event.type !== FETCH_ROLLING_AVERAGES_DONE || event.data === undefined)
							return rollingAverages;
						// Use the bats filter of the returned data to make sure we have the correct data
						// otherwise default to the context's bats filter
						const bats = event.data?.length ? event.data[0].requestArgs.bats ?? BATS_OVERALL : batsFilter;
						// Repeat the process with the throws filter
						const throws = event.data?.length
							? event.data[0].requestArgs.throws ?? throwsFilter
							: throwsFilter;
						return {
							...rollingAverages,
							[`${bats}-${throws}`]: event.data
						};
					}
				}),
				handleFetchArsenalScoresThresholdSuccess: assign<
					TPitcherMetricOverTimePlotContext,
					TPitcherMetricOverTimePlotEvent
				>({
					arsenalScoresThreshold: (
						context: TPitcherMetricOverTimePlotContext,
						event: TPitcherMetricOverTimePlotEvent
					) => {
						if (event.type !== FETCH_ARSENAL_SCORES_THRESHOLD_DONE) return context.arsenalScoresThreshold;
						return event.data;
					}
				}),
				handleFetchPlayerSeasonArsenalScoresSuccess: assign<
					TPitcherMetricOverTimePlotContext,
					TPitcherMetricOverTimePlotEvent
				>({
					playerSeasonArsenalScores: (
						context: TPitcherMetricOverTimePlotContext,
						event: TPitcherMetricOverTimePlotEvent
					) => {
						const { playerSeasonArsenalScores } = context;
						if (event.type !== FETCH_PLAYER_SEASON_ARSENAL_SCORES_DONE) return playerSeasonArsenalScores;
						return event.data;
					}
				}),
				// Fetch Errored Actions
				handleFetchRollingAveragesErrored: (
					context: TPitcherMetricOverTimePlotContext,
					event: TPitcherMetricOverTimePlotEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_ROLLING_AVERAGES_ERROR ? event.data : undefined,
						context.toast,
						"Rolling Averages",
						"Error fetching rolling averages data."
					);
				},
				handleFetchArsenalScoresThresholdErrored: (
					context: TPitcherMetricOverTimePlotContext,
					event: TPitcherMetricOverTimePlotEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_ARSENAL_SCORES_THRESHOLD_ERROR ? event.data : undefined,
						context.toast,
						"Arsenal Scores Thresholds",
						"Error fetching arsenal scores thresholds data."
					);
				},
				handleFetchPlayerSeasonArsenalScoresErrored: (
					context: TPitcherMetricOverTimePlotContext,
					event: TPitcherMetricOverTimePlotEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_PLAYER_SEASON_ARSENAL_SCORES_ERROR ? event.data : undefined,
						context.toast,
						"Seasonal Arsenal Scores Pitch Type",
						"Error fetching seasonal arsenal scores pitch type."
					);
				}
			},
			services: {
				fetchRollingAverages: (context: TPitcherMetricOverTimePlotContext, _event: AnyEventObject) => {
					const {
						playerId,
						playingLevel,
						metricGroup,
						batsFilter,
						throwsFilter,
						seasonFilter,
						rollingAverages
					} = context;
					const existingData = rollingAverages[`${batsFilter}-${throwsFilter}`];
					if (existingData) return Promise.resolve(existingData);
					if (!playerId || !metricGroup) return Promise.resolve(undefined);
					const fetchFunc = () =>
						fetchPlayerMetricRollingAverages(
							{
								playerId: playerId,
								playingLevel: playingLevel,
								// BATS_OVERALL is equivalent to having no `bats` filter
								bats: batsFilter !== BATS_OVERALL ? batsFilter : undefined,
								throws: throwsFilter,
								metricGroup: metricGroup,
								season: seasonFilter,
								isUseCache: true
							},
							context.cancelSources[ROLLING_AVERAGES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchArsenalScoresThreshold: (context: TPitcherMetricOverTimePlotContext, _event: AnyEventObject) => {
					const fetchFunc = () =>
						fetchArsenalScoresThreshold(
							context.cancelSources[ARSENAL_SCORES_THRESHOLD_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				},
				fetchPlayerSeasonArsenalScores: (
					context: TPitcherMetricOverTimePlotContext,
					_event: AnyEventObject
				) => {
					const { playerId, playingLevel, seasonFilter } = context;
					if (!playerId || !playingLevel) return Promise.resolve(undefined);
					const fetchFunc = () =>
						fetchPlayerSeasonArsenalScores(
							{
								playerId: playerId,
								playingLevel: playingLevel,
								season: seasonFilter,
								gameType: GAME_TYPE_OVERALL,
								"pitchType[neq]": PITCH_TYPE_OVERALL,
								countSplit: COUNT_SPLIT_OVERALL,
								isUseCache: true
							},
							context.cancelSources[PLAYER_SEASON_ARSENAL_SCORES_CANCEL_SOURCE]?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default PitcherMetricOverTimePlotMachine;
