import React, { FC } from "react";

import { useToggle } from "_react/_hooks";
import { Button } from "_react/shared/legacy/ui/Button";
import ArrowLeft from "_react/shared/legacy/ui/icons/ArrowLeft";
import ArrowRight from "_react/shared/legacy/ui/icons/ArrowRight";
import { isEmpty } from "utils/helpers";
import { $TSFixMe } from "utils/tsutils";
import {
	TAgentRowsType,
	TAgencyRowsType,
	NO_INFO_DISPLAY,
	FAILED_INFO_DISPLAY
} from "_react/playerpage/header/AgentOrAgencyField/_helpers";
import {
	ActiveOnly,
	ActiveOnlyToggle,
	ActiveOnlyToggleButton,
	ArrowLeftStyle,
	ArrowRightStyle,
	ContactInfo,
	ContactInfoColumn,
	DisplayNoPlayers,
	DisplayPlayerFailure,
	IncludeInactive,
	LoadingContactInfo,
	LoadingPlayer,
	NoContactInfo,
	PlayerList,
	PlayerListHeader,
	PlayerListTableDiv,
	PlayerListTableRow,
	PopupInfo,
	PopupInfoPadding,
	PopupRowStyle
} from "_react/playerpage/header/AgentOrAgencyField/_styles";
import { getOrgColorSchemeGroup } from "_react/playerpage/_helpers";

type AgentOrAgencyPopupInfoProps = {
	infoObject: $TSFixMe;
	infoRows: TAgentRowsType | TAgencyRowsType;
	infoPlayers: { text: string; active: boolean; link?: string }[];
	infoKey: "agent" | "agency";
	infoId: string | number;
	isFetchingPlayers?: boolean;
	isFailedPlayers?: boolean;
	isFetchingInfoObj?: boolean;
	isFailedInfoObj?: boolean;
	org?: string;
};

export const AgentOrAgencyPopupInfo: FC<AgentOrAgencyPopupInfoProps> = ({
	infoObject,
	infoRows,
	infoPlayers: infoPlayersRaw,
	infoKey,
	infoId,
	isFetchingPlayers = false,
	isFailedPlayers = false,
	isFetchingInfoObj = false,
	isFailedInfoObj = false,
	org
}) => {
	const [activeOnly, toggleActiveOnly] = useToggle(true);
	let infoPlayers = infoPlayersRaw;
	if (activeOnly && infoPlayers != null) {
		infoPlayers = infoPlayers.filter(p => p.active);
	}
	const isNoContactInfo = isEmpty(infoObject);
	const isDisplayInfoFailure = isNoContactInfo && isFailedInfoObj && !isFetchingInfoObj;
	const isDisplayNoInfo = isNoContactInfo && !isFetchingInfoObj && !isFailedInfoObj;
	const isNoPlayers = !infoPlayers.length;
	const isDisplayPlayerFailure = isNoPlayers && isFailedPlayers && !isFetchingPlayers;
	const isDisplayNoPlayers = isNoPlayers && !isFailedPlayers && !isFetchingPlayers && !isDisplayNoInfo;
	const filteredInfoRows = !isNoContactInfo ? infoRows.filter(({ key }) => !isEmpty(infoObject[key])) : [];
	const colorSchemeGroup = getOrgColorSchemeGroup(org);

	// eventually will want a shared/ui toggle and replace this with that
	const activeOnlyToggle = (
		<ActiveOnlyToggle>
			<ActiveOnly activeOnly={activeOnly} colorSchemeGroup={colorSchemeGroup}>
				Active Only
			</ActiveOnly>
			<Button
				colorScheme={colorSchemeGroup?.primary}
				onClick={toggleActiveOnly}
				title={!activeOnly ? <ArrowLeft style={ArrowLeftStyle} /> : <ArrowRight style={ArrowRightStyle} />}
				style={ActiveOnlyToggleButton}
			/>
			<IncludeInactive activeOnly={activeOnly} colorSchemeGroup={colorSchemeGroup}>
				Include Inactive
			</IncludeInactive>
		</ActiveOnlyToggle>
	);

	return (
		<PopupInfo colorSchemeGroup={colorSchemeGroup}>
			<PopupInfoPadding>
				<ContactInfo>
					{isNoContactInfo && (
						<NoContactInfo colorSchemeGroup={colorSchemeGroup}>
							{isFetchingInfoObj && <LoadingContactInfo className="loading-item" />}
							{isDisplayInfoFailure && FAILED_INFO_DISPLAY(infoId)[infoKey]}
							{isDisplayNoInfo && NO_INFO_DISPLAY(infoId)[infoKey]}
						</NoContactInfo>
					)}
					{!isNoContactInfo && (
						<table>
							<tbody>
								{filteredInfoRows.map(({ key, label }, ind) => (
									<PopupRowStyle key={key} ind={ind}>
										<td>
											<ContactInfoColumn>{label}:</ContactInfoColumn>
										</td>
										<td>
											<ContactInfoColumn>{infoObject[key]}</ContactInfoColumn>
										</td>
									</PopupRowStyle>
								))}
							</tbody>
						</table>
					)}
				</ContactInfo>
				{(!isNoPlayers || isFetchingPlayers) && (
					<PlayerList>
						{isFetchingPlayers && <LoadingPlayer className="loading-item" />}
						{!isFetchingPlayers && (
							<React.Fragment>
								<PlayerListHeader colorSchemeGroup={colorSchemeGroup}>Players</PlayerListHeader>
								{activeOnlyToggle}
								<PlayerListTableDiv>
									<table>
										<tbody>
											{infoPlayers.map((player, ind) => (
												<PopupRowStyle key={ind} ind={ind}>
													<td>
														<PlayerListTableRow
															onClick={
																player.link
																	? () => window.open(player.link, "_blank")
																	: undefined
															}
															style={{ cursor: player.link ? "pointer" : "inherit" }}
														>
															{player.text}
														</PlayerListTableRow>
													</td>
												</PopupRowStyle>
											))}
										</tbody>
									</table>
								</PlayerListTableDiv>
							</React.Fragment>
						)}
					</PlayerList>
				)}
				{isDisplayPlayerFailure && (
					<DisplayPlayerFailure colorSchemeGroup={colorSchemeGroup}>
						Failed retrieving players
					</DisplayPlayerFailure>
				)}
				{isDisplayNoPlayers && (
					<DisplayNoPlayers colorSchemeGroup={colorSchemeGroup}>
						Found no players for this {infoKey}
					</DisplayNoPlayers>
				)}
			</PopupInfoPadding>
		</PopupInfo>
	);
};
