import { Machine, assign, Interpreter, AnyEventObject } from "xstate";
import { AxiosError, CancelTokenSource } from "axios";
import { CreateToastFnReturn } from "@chakra-ui/react";

import { displayAxiosErrorToast } from "_react/shared/_helpers/axios";
import {
	TIntlProspectValueSimilarPlayersModelName,
	TIntlProspectValueSimilarPlayersPlayerType,
	fetchIntlProspectValueSimilarPlayers
} from "_react/shared/data_models/phred/_network";
import { IIntlProspectValuePlayer } from "_react/shared/data_models/phred/_types";
import { TIntlSurplusValueSimilarPlayersTableData } from "_react/shared/ui/data/tables/IntlSurplusValueSimilarPlayersTable/_types";
import { promiseWRetry } from "utils/helpers";
import { getCancelSource } from "utils/url_helpers";

export type TIntlSurplusValueSimilarPlayersTableContext = {
	playerId?: number;
	playerType?: TIntlProspectValueSimilarPlayersPlayerType;
	modelName?: TIntlProspectValueSimilarPlayersModelName;
	positionGroup?: string;
	shouldFetchData?: boolean;
	similarPlayers?: Array<IIntlProspectValuePlayer>;
	cancelSource?: CancelTokenSource | null;
	toast?: CreateToastFnReturn;
};

interface IIntlSurplusValueSimilarPlayersTableStateSchema {
	states: {
		initializing: {};
		initialized: {
			states: {
				// Fetches all similar players data
				similarPlayers: {
					states: {
						idle: {
							states: {
								errored: {};
								notErrored: {
									states: {
										preFetch: {};
										postFetch: {};
									};
								};
							};
						};
						fetching: {};
					};
				};
			};
		};
	};
}

export const FETCHING_SIMILAR_PLAYERS = { initialized: { similarPlayers: "fetching" } };
export const FETCH_SIMILAR_PLAYERS_DONE = "done.invoke.fetchSimilarPlayers:invocation[0]";
export const FETCH_SIMILAR_PLAYERS_ERROR = "error.platform.fetchSimilarPlayers:invocation[0]";
export const SET_SIMILAR_PLAYERS = "SET_SIMILAR_PLAYERS";
export const SET_PLAYER_ID = "SET_PLAYER_ID";
export const SET_PLAYER_TYPE = "SET_PLAYER_TYPE";
export const SET_MODEL_NAME = "SET_MODEL_NAME";
export const SET_POSITION_GROUP = "SET_POSITION_GROUP";

type TFetchSimilarPlayersEvent = {
	type: typeof FETCH_SIMILAR_PLAYERS_DONE;
	data: Array<IIntlProspectValuePlayer> | undefined;
};
type TFetchSimilarPlayersErrorEvent = {
	type: typeof FETCH_SIMILAR_PLAYERS_ERROR;
	data?: AxiosError | Error;
};
type TSetSimilarPlayersEvent = { type: typeof SET_SIMILAR_PLAYERS; value?: Array<IIntlProspectValuePlayer> };
type TSetPlayerIdEvent = { type: typeof SET_PLAYER_ID; value?: number };
type TSetPlayerTypeEvent = { type: typeof SET_PLAYER_TYPE; value?: TIntlProspectValueSimilarPlayersPlayerType };
type TSetModelNameEvent = { type: typeof SET_MODEL_NAME; value?: TIntlProspectValueSimilarPlayersModelName };
type TSetPositionGroupEvent = { type: typeof SET_POSITION_GROUP; value?: string };

type TIntlSurplusValueSimilarPlayersTableEvent =
	| TFetchSimilarPlayersEvent
	| TSetSimilarPlayersEvent
	| TSetPlayerIdEvent
	| TSetPlayerTypeEvent
	| TSetModelNameEvent
	| TSetPositionGroupEvent
	| TFetchSimilarPlayersErrorEvent;

export type TIntlSurplusValueSimilarPlayersTableSend = Interpreter<
	TIntlSurplusValueSimilarPlayersTableContext,
	IIntlSurplusValueSimilarPlayersTableStateSchema,
	TIntlSurplusValueSimilarPlayersTableEvent
>["send"];

const IntlSurplusValueSimilarPlayersTableMachine = (
	playerIdProp?: number,
	playerTypeProp?: TIntlProspectValueSimilarPlayersPlayerType,
	modelNameProp?: TIntlProspectValueSimilarPlayersModelName,
	positionGroupProp?: string,
	data?: TIntlSurplusValueSimilarPlayersTableData,
	shouldFetchDataProp = true,
	toastProp?: CreateToastFnReturn
) =>
	Machine<
		TIntlSurplusValueSimilarPlayersTableContext,
		IIntlSurplusValueSimilarPlayersTableStateSchema,
		TIntlSurplusValueSimilarPlayersTableEvent
	>(
		{
			id: "intlSurplusValueSimilarPlayersTable",
			initial: "initializing",
			context: {
				playerId: playerIdProp,
				playerType: playerTypeProp,
				modelName: modelNameProp,
				positionGroup: positionGroupProp,
				shouldFetchData: shouldFetchDataProp,
				similarPlayers: data?.similarPlayers,
				cancelSource: undefined,
				toast: toastProp
			},
			states: {
				initializing: {
					always: { target: "initialized" }
				},
				initialized: {
					type: "parallel",
					on: {
						[SET_SIMILAR_PLAYERS]: { actions: "setSimilarPlayers" },
						[SET_PLAYER_ID]: {
							actions: ["setPlayerId", "clearSimilarPlayers"],
							cond: "shouldSetPlayerId"
						},
						[SET_PLAYER_TYPE]: {
							actions: ["setPlayerType", "clearSimilarPlayers"],
							cond: "shouldSetPlayerType"
						},
						[SET_MODEL_NAME]: {
							actions: ["setModelName", "clearSimilarPlayers"],
							cond: "shouldSetModelName"
						},
						[SET_POSITION_GROUP]: {
							actions: ["setPositionGroup", "clearSimilarPlayers"],
							cond: "shouldSetPositionGroup"
						}
					},
					states: {
						similarPlayers: {
							initial: "idle",
							states: {
								idle: {
									initial: "notErrored",
									states: {
										errored: {
											id: "erroredNode"
										},
										notErrored: {
											initial: "preFetch",
											always: {
												target: "#fetchSimilarPlayers",
												cond: "shouldFetchSimilarPlayers"
											},
											states: {
												preFetch: {},
												postFetch: {}
											}
										}
									}
								},
								fetching: {
									id: "fetchSimilarPlayers",
									entry: ["refreshCancelSource"],
									invoke: {
										src: "fetchSimilarPlayers",
										onDone: {
											target: "idle.notErrored.postFetch",
											actions: "handleFetchSimilarPlayersSuccess"
										},
										onError: {
											target: "idle.errored",
											actions: "handleFetchSimilarPlayersErrored"
										}
									}
								}
							}
						}
					}
				}
			}
		},
		{
			guards: {
				shouldSetPlayerId: (
					context: TIntlSurplusValueSimilarPlayersTableContext,
					event: TIntlSurplusValueSimilarPlayersTableEvent
				) => event.type === SET_PLAYER_ID && context.playerId !== event.value,
				shouldSetPlayerType: (
					context: TIntlSurplusValueSimilarPlayersTableContext,
					event: TIntlSurplusValueSimilarPlayersTableEvent
				) => event.type === SET_PLAYER_TYPE && context.playerType !== event.value,
				shouldSetModelName: (
					context: TIntlSurplusValueSimilarPlayersTableContext,
					event: TIntlSurplusValueSimilarPlayersTableEvent
				) => event.type === SET_MODEL_NAME && context.modelName !== event.value,
				shouldSetPositionGroup: (
					context: TIntlSurplusValueSimilarPlayersTableContext,
					event: TIntlSurplusValueSimilarPlayersTableEvent
				) => event.type === SET_POSITION_GROUP && context.positionGroup !== event.value,
				shouldFetchSimilarPlayers: (
					context: TIntlSurplusValueSimilarPlayersTableContext,
					_event: TIntlSurplusValueSimilarPlayersTableEvent
				) => {
					return (
						context.similarPlayers === undefined &&
						context.playerId !== undefined &&
						context.playerType !== undefined &&
						context.positionGroup !== undefined &&
						context.modelName !== undefined &&
						context.shouldFetchData === true
					);
				}
			},
			actions: {
				clearSimilarPlayers: assign<
					TIntlSurplusValueSimilarPlayersTableContext,
					TIntlSurplusValueSimilarPlayersTableEvent
				>({
					similarPlayers: (
						_context: TIntlSurplusValueSimilarPlayersTableContext,
						_event: TIntlSurplusValueSimilarPlayersTableEvent
					) => undefined,
					cancelSource: (
						context: TIntlSurplusValueSimilarPlayersTableContext,
						_event: TIntlSurplusValueSimilarPlayersTableEvent
					) => {
						context.cancelSource?.cancel();
						return undefined;
					}
				}),
				setSimilarPlayers: assign<
					TIntlSurplusValueSimilarPlayersTableContext,
					TIntlSurplusValueSimilarPlayersTableEvent
				>({
					similarPlayers: (
						context: TIntlSurplusValueSimilarPlayersTableContext,
						event: TIntlSurplusValueSimilarPlayersTableEvent
					) => {
						if (event.type !== SET_SIMILAR_PLAYERS) return context.similarPlayers;
						return event.value;
					},
					cancelSource: (
						context: TIntlSurplusValueSimilarPlayersTableContext,
						_event: TIntlSurplusValueSimilarPlayersTableEvent
					) => {
						context.cancelSource?.cancel();
						return undefined;
					}
				}),
				setPlayerId: assign<
					TIntlSurplusValueSimilarPlayersTableContext,
					TIntlSurplusValueSimilarPlayersTableEvent
				>({
					playerId: (
						context: TIntlSurplusValueSimilarPlayersTableContext,
						event: TIntlSurplusValueSimilarPlayersTableEvent
					) => {
						if (event.type !== SET_PLAYER_ID) return context.playerId;
						return event.value;
					}
				}),
				setPlayerType: assign<
					TIntlSurplusValueSimilarPlayersTableContext,
					TIntlSurplusValueSimilarPlayersTableEvent
				>({
					playerType: (
						context: TIntlSurplusValueSimilarPlayersTableContext,
						event: TIntlSurplusValueSimilarPlayersTableEvent
					) => {
						if (event.type !== SET_PLAYER_TYPE) return context.playerType;
						return event.value;
					}
				}),
				setModelName: assign<
					TIntlSurplusValueSimilarPlayersTableContext,
					TIntlSurplusValueSimilarPlayersTableEvent
				>({
					modelName: (
						context: TIntlSurplusValueSimilarPlayersTableContext,
						event: TIntlSurplusValueSimilarPlayersTableEvent
					) => {
						if (event.type !== SET_MODEL_NAME) return context.modelName;
						return event.value;
					}
				}),
				setPositionGroup: assign<
					TIntlSurplusValueSimilarPlayersTableContext,
					TIntlSurplusValueSimilarPlayersTableEvent
				>({
					positionGroup: (
						context: TIntlSurplusValueSimilarPlayersTableContext,
						event: TIntlSurplusValueSimilarPlayersTableEvent
					) => {
						if (event.type !== SET_POSITION_GROUP) return context.positionGroup;
						return event.value;
					}
				}),
				refreshCancelSource: assign<
					TIntlSurplusValueSimilarPlayersTableContext,
					TIntlSurplusValueSimilarPlayersTableEvent
				>({
					cancelSource: (
						context: TIntlSurplusValueSimilarPlayersTableContext,
						_event: TIntlSurplusValueSimilarPlayersTableEvent
					) => {
						context.cancelSource?.cancel();
						return getCancelSource();
					}
				}),
				handleFetchSimilarPlayersSuccess: assign<
					TIntlSurplusValueSimilarPlayersTableContext,
					TIntlSurplusValueSimilarPlayersTableEvent
				>({
					similarPlayers: (
						context: TIntlSurplusValueSimilarPlayersTableContext,
						event: TIntlSurplusValueSimilarPlayersTableEvent
					) => {
						if (event.type !== FETCH_SIMILAR_PLAYERS_DONE) return context.similarPlayers;
						return event.data?.sort(
							(a: IIntlProspectValuePlayer, b: IIntlProspectValuePlayer) =>
								(a.xsurplusRankBytype ?? 0) - (b.xsurplusRankBytype ?? 0)
						);
					}
				}),
				handleFetchSimilarPlayersErrored: (
					context: TIntlSurplusValueSimilarPlayersTableContext,
					event: TIntlSurplusValueSimilarPlayersTableEvent
				) => {
					displayAxiosErrorToast(
						event.type === FETCH_SIMILAR_PLAYERS_ERROR ? event.data : undefined,
						context.toast,
						"Similar Players",
						"Error fetching similar players."
					);
				}
			},
			services: {
				fetchSimilarPlayers: (context: TIntlSurplusValueSimilarPlayersTableContext, _event: AnyEventObject) => {
					const { playerId, playerType, positionGroup } = context;
					if (playerId === undefined || playerType === undefined) return Promise.resolve(null);
					const fetchFunc = () =>
						fetchIntlProspectValueSimilarPlayers(
							{
								playerId: playerId,
								playerType: playerType,
								positionGroup: positionGroup,
								isUseCache: true
							},
							context.cancelSource?.token
						);
					return promiseWRetry(fetchFunc);
				}
			}
		}
	);

export default IntlSurplusValueSimilarPlayersTableMachine;
