import React, { FunctionComponent, useState, useMemo, CSSProperties } from "react";
import dayjs from "dayjs";

import { useIsGroupMember } from "_react/app/AppContents";
import { sortEvalsSeenDate } from "_react/evals/list/_helpers";
import { EvalList, TEvalListStyle } from "_react/evals/list/EvalList";
import { PRO_EVAL_ADMIN_IAM_GROUP } from "_react/evals/shared/_constants";
import { $TSFixMeEval } from "_react/evals/shared/_types";
import { Button } from "_react/shared/legacy/ui/Button";
import { Card } from "_react/shared/legacy/ui/Card";
import { ColoredCardHeader } from "_react/shared/legacy/ui/ColoredCardHeader";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import EvalTable from "_react/playerpage/shared_js/evals/EvalTable";

type TEvaluationTableStyle = {
	card?: CSSProperties;
	cardHeader?: CSSProperties;
	evalList?: TEvalListStyle;
};

type EvaluationsTableProps = {
	colorScheme?: ColorSchemeGroup;
	evaluations?: $TSFixMeEval[] | null;
	playerId?: number | null;
	style?: TEvaluationTableStyle;
};

export const EvaluationsTable: FunctionComponent<EvaluationsTableProps> = ({
	colorScheme = defaultColorScheme,
	evaluations,
	playerId,
	style
}) => {
	const [isPrintView, setIsPrintView] = useState<boolean>(false);
	const isProEvalAdmin = useIsGroupMember(PRO_EVAL_ADMIN_IAM_GROUP);

	const evalIds: Array<string> = useMemo(() => (evaluations ? evaluations.map((e: $TSFixMeEval) => e.eval_id) : []), [
		evaluations
	]);

	return (
		<Card style={style?.card}>
			<ColoredCardHeader colorScheme={colorScheme.secondary} text="Evaluations" style={style?.cardHeader}>
				<Button
					onClick={() => setIsPrintView(!isPrintView)}
					colorScheme={colorScheme.primary}
					title={isPrintView ? "Hide Print View" : "Show Print View"}
				/>
			</ColoredCardHeader>
			{(evaluations == null || (evaluations.length && evaluations[0].player_id !== playerId)) && (
				<div className="loading-container">
					<div className="loading-item" style={{ height: "45px" }} />
					<div className="loading-item" style={{ height: "45px" }} />
					<div className="loading-item" style={{ height: "45px" }} />
					<div className="loading-item" style={{ height: "45px" }} />
				</div>
			)}
			{evaluations != null && (!evaluations.length || evaluations[0].player_id === playerId) && !isPrintView && (
				<EvalTable
					isProEvalAdmin={isProEvalAdmin}
					evaluations={evaluations.map((e: $TSFixMeEval) => ({
						...e,
						seen_date: dayjs(e.seen_date)
					}))}
				/>
			)}
			{evaluations != null && (!evaluations.length || evaluations[0].player_id === playerId) && (
				<EvalList
					key={evaluations.length}
					evalIds={evalIds}
					isPrintOnly={!isPrintView}
					isShowTitle={true}
					isPlayerCentric={true}
					sortEvals={sortEvalsSeenDate}
					title={document.title}
					colorSchemeGroup={colorScheme}
					style={style?.evalList}
				/>
			)}
		</Card>
	);
};
