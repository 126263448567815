import React, { useMemo } from "react";
import { Box, Stack, VStack, HStack, Text, SystemStyleObject } from "@chakra-ui/react";
import EntityHeaderLoading from "./EntityHeaderLoading";

export type TStartContent = {
	primaryContent: React.ReactNode;
	secondaryContent: React.ReactNode;
};

export type TMiddleContent = {
	primaryContent: React.ReactNode;
	secondaryContent: React.ReactNode;
};

export type TEntityHeaderStyle = {
	container?: SystemStyleObject;
	// TODO expand on this so you can customize as needed
};

export type EntityHeaderProps = {
	image: React.ReactNode;
	startContent: TStartContent;
	middleContent: TMiddleContent;
	endContent: React.ReactNode;
	isLoading?: boolean;
	style?: TEntityHeaderStyle;
};

const EntityHeader = ({
	image,
	startContent,
	middleContent,
	endContent,
	isLoading = false,
	style
}: EntityHeaderProps) => {
	const middleContentCombined = useMemo(
		() => (
			<Stack
				alignItems="center"
				justifyContent="center"
				color="gray.200"
				direction={{
					base: "column",
					md: "row",
					lg: "column",
					xl: "row"
				}}
				fontSize={{
					base: "xs",
					md: "md",
					lg: "xs",
					xl: "md"
				}}
				gap={{
					base: 0,
					md: 2,
					lg: 0,
					xl: 2
				}}
			>
				<HStack>{middleContent.primaryContent}</HStack>
				<HStack>{middleContent.secondaryContent}</HStack>
			</Stack>
		),
		[middleContent]
	);

	return (
		<>
			{isLoading && <EntityHeaderLoading />}
			{!isLoading && (
				<Stack
					bg="gray.800"
					color="white"
					gap="0"
					textAlign="center"
					alignItems="center"
					direction={{ base: "column", md: "row" }}
					justifyContent={{ base: "center", md: "space-between" }}
					height={{ md: 16 }}
					padding={{ md: 2 }}
					paddingLeft={{ md: 14 }}
					sx={style?.container}
				>
					{/* The left portion of the header */}
					<Stack direction={{ base: "column", md: "row" }} position={{ lg: "relative" }} top={{ lg: 2 }}>
						<Box position="relative" marginTop={{ base: 6, md: 0 }} top={{ md: 2 }}>
							{image}
						</Box>
						<VStack paddingLeft="3" align={{ base: "center", md: "start" }} gap="0">
							<Text fontSize="xl" fontWeight="bold">
								{startContent.primaryContent}
							</Text>
							<Box display={{ lg: "none" }}>{middleContentCombined}</Box>
							<HStack
								fontSize="sm"
								color="gray.200"
								marginTop={{ base: "1 !important", md: "0 !important" }}
							>
								{startContent.secondaryContent}
							</HStack>
						</VStack>
					</Stack>
					{/* The middle portion of the header */}
					<Box display={{ base: "none", lg: "block" }}>{middleContentCombined}</Box>
					{/* The right portion of the header */}
					<HStack
						marginTop={{ base: "6 !important", md: "0 !important" }}
						marginBottom={{ base: "4 !important", md: "0 !important" }}
						marginRight={{ md: 12 }}
					>
						{endContent}
					</HStack>
				</Stack>
			)}
		</>
	);
};

export default EntityHeader;
