import React from "react";
import { Box } from "@chakra-ui/react";

import { ContentPaddingTopBreakpointValue } from "_react/playerpage/_styles";
import { useChakraColorSchemeGroup } from "_react/shared/theme/_helpers";
import { Injuries } from "_react/playerpage/injuries/Injuries";
import { TPlayerPageCombinedPlayer } from "_react/playerpage/_types";
import { INTL } from "_react/playerpage/_constants";

type TInjuriesTabProps = {
	player: TPlayerPageCombinedPlayer;
};

const InjuriesTab = ({ player }: TInjuriesTabProps) => {
	const chakraColorSchemeGroup = useChakraColorSchemeGroup();

	return (
		<Box position="relative" top={"-8"}>
			<Box paddingTop={ContentPaddingTopBreakpointValue}>
				<Injuries colorSchemeGroup={chakraColorSchemeGroup} player={player} viewClassification={INTL} />
			</Box>
		</Box>
	);
};

export default InjuriesTab;
