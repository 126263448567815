import {
	IRAAPositionPlayer,
	IRAAPositionPlayerByTeam,
	IRAAPositionPlayerTeamBam
} from "_react/shared/data_models/raa/_types";

export type ICombinedPositionPlayerValueData = {
	season: number;
	teamBam?: IRAAPositionPlayerTeamBam;
	pa: number | null;
	battingRaa: number | null;
	battingRaaVl: number | null;
	battingRaaVr: number | null;
	baserunningRaa: number | null;
	stolenBaseRaa: number | null;
	totalFieldingRaa: number | null;
	rangeRaa: number | null;
	receivingRaa: number | null;
	damageRaa: number | null;
	framingRaa: number | null;
	blockingRaa: number | null;
	sbThrowingRaa: number | null;
	posAdj: number | null;
	totalRar: number | null;
};

export type TPositionPlayerValueRow = {
	combinedPositionPlayerValueData: ICombinedPositionPlayerValueData;
	childData?: Array<TPositionPlayerValueRow>;
};

export type TPositionPlayerValueTableData = {
	playerSeasonRaaPositionPlayer?: Array<IRAAPositionPlayer>;
	playerSeasonRaaPositionPlayerByTeam?: Array<IRAAPositionPlayerByTeam>;
	playerSeasonRaaRatePositionPlayer?: Array<IRAAPositionPlayer>;
	playerSeasonRaaRatePositionPlayerByTeam?: Array<IRAAPositionPlayerByTeam>;
	isLoading?: boolean;
};

export const RATE = "RATE";
export const RAW = "RAW";
export type TPositionPlayerValueTableDataSource = typeof RATE | typeof RAW;
