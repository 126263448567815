import React, { CSSProperties } from "react";
import dayjs from "dayjs";
import { DraggableProvided } from "react-beautiful-dnd";

import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { Checkbox } from "_react/shared/legacy/ui/Checkbox";
import { Tooltip } from "_react/shared/legacy/ui/Tooltip";
import PriorityHigh from "_react/shared/legacy/ui/icons/PriorityHigh";
import Warning from "_react/shared/legacy/ui/icons/Warning";
import CalendarToday from "_react/shared/legacy/ui/icons/CalendarToday";

import { COMPLETE, CANCELED } from "_react/playerplan/shared/_constants";
import {
	GoalContainer,
	GoalHeader,
	HeaderStatusCheckboxDiv,
	GoalSectionLeftDiv,
	GoalSectionRightDiv,
	MetricTypeDiv,
	CheckboxRightStyle,
	WarningStyle
} from "_react/playerplan/shared/_styles";
import { TPlayerPlanGoal } from "_react/playerplan/shared/_types";
import {
	createGoalStatement,
	getGoalSeasons,
	getGoalSeasonsStatement,
	getSelectedSeasonActiveTarget,
	getMostRecentActiveTarget
} from "_react/playerplan/shared/_helpers";
import { PHP_TIER } from "_react/playerplan/metrics/_constants";

export type TPlayerPlanGoalStyle = {
	checkbox?: CSSProperties;
};

export type TPlayerPlanGoalProps = {
	goal: TPlayerPlanGoal;
	isGroup1?: boolean;
	isGroup2?: boolean;
	isGoalActionsDisabled?: boolean;
	updateGoal: (goal: TPlayerPlanGoal, updateApprovalDate?: boolean) => void;
	setSelectedGoalId?: Function;
	selectedGoalId?: number;
	selectedSeason?: number;
	provided?: DraggableProvided;
	isDisabled?: boolean;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TPlayerPlanGoalStyle;
};

export const PlayerPlanGoal = ({
	goal,
	isGroup1 = false,
	isGroup2 = false,
	isGoalActionsDisabled = false,
	updateGoal,
	setSelectedGoalId,
	selectedGoalId,
	selectedSeason,
	provided,
	isDisabled = false,
	colorSchemeGroup = defaultColorScheme,
	style
}: TPlayerPlanGoalProps) => {
	const isPHP = goal.playerPlanMetric.metricTierLk === PHP_TIER;
	const isStrength = goal.isStrength;
	const today = new Date();
	const year = today.getFullYear();
	const activeTarget =
		getSelectedSeasonActiveTarget(year, goal.playerPlanTargets) ??
		getMostRecentActiveTarget(goal.playerPlanTargets);
	const hasCustomDates = activeTarget?.startDate || activeTarget?.endDate;
	// If the active target is in the current season and includes custom dates, ensure the current date is within the custom date range
	const isOutsideDateRange = Boolean(
		activeTarget?.season &&
			activeTarget.season === year &&
			((activeTarget.endDate && selectedSeason === year ? dayjs().isAfter(dayjs(activeTarget.endDate)) : false) ||
				(activeTarget.startDate && selectedSeason === year
					? dayjs().isBefore(dayjs(activeTarget.startDate))
					: false))
	);
	const goalSeasons: number[] | null = getGoalSeasons(goal);
	const minGoalSeason: number | null = goalSeasons?.length ? Math.min(...goalSeasons) : null;
	const goalSeasonsStatement: string = getGoalSeasonsStatement(goalSeasons);

	return (
		<GoalContainer>
			<div ref={provided?.innerRef} {...provided?.draggableProps} {...provided?.dragHandleProps}>
				<GoalHeader
					isSelected={selectedGoalId === goal.id}
					isStrength={goal.isStrength}
					isPHP={isPHP}
					isLowPriority={goal.isInactive || isDisabled}
					isForNextYear={!!(minGoalSeason && minGoalSeason > year)}
					isOutsideDateRange={isOutsideDateRange}
					onClick={() => {
						if (setSelectedGoalId && !goal.isInactive && !isDisabled) setSelectedGoalId(goal.id);
					}}
				>
					{!(goal.isStrength || isPHP) && (
						<GoalSectionLeftDiv>
							<MetricTypeDiv>{goal.playerPlanMetric.metricType.abbreviation}</MetricTypeDiv>
							{goal.isPriority && (
								<Tooltip
									title="Priority Goal"
									colorScheme={colorSchemeGroup.primary}
									placement={"right"}
								>
									<PriorityHigh fill={colorSchemeGroup.secondary.color} />
								</Tooltip>
							)}
						</GoalSectionLeftDiv>
					)}
					<div
						style={{
							width: goal.isStrength || isPHP ? "100%" : undefined,
							textAlign: goal.isStrength || isPHP ? "center" : undefined
						}}
					>
						{createGoalStatement(
							goal.playerPlanMetric?.label,
							goal.playerPlanMetric?.metricSubtype?.label,
							goal.playerPlanSplits,
							activeTarget?.direction?.label,
							activeTarget?.value,
							goal.playerPlanMetric?.format,
							isPHP,
							goal.playerPlanMetric?.metricType.abbreviation,
							isStrength,
							isStrength || goal?.status.value === COMPLETE ? ` - ${goalSeasonsStatement}` : undefined
						)}
					</div>
					{!goal.isStrength && !isPHP && goal.status.value !== COMPLETE && (
						<GoalSectionRightDiv>
							{!goal.isStrength && !isPHP && (
								<>
									{goal.isNeedsReview && (
										<Tooltip
											title="Needs Review"
											colorScheme={colorSchemeGroup.primary}
											placement={"left"}
										>
											<Warning fill={"#8a8a00"} style={WarningStyle} />
										</Tooltip>
									)}
									{!goal.isInactive &&
										(hasCustomDates ? (
											<Tooltip
												title={`${
													activeTarget.startDate
														? `${activeTarget.endDate ? "" : "Start "}${dayjs(
																activeTarget.startDate
														  ).format("MM/DD/YYYY")}`
														: ""
												}${activeTarget.startDate && activeTarget.endDate ? "-" : ""}${
													activeTarget.endDate
														? `${activeTarget.startDate ? "" : "End "}${dayjs(
																activeTarget.endDate
														  ).format("MM/DD/YYYY")}`
														: ""
												}`}
												placement="left"
											>
												<div style={{ display: "flex", alignItems: "center" }}>
													<div>{`${goalSeasonsStatement}`}</div>
													<CalendarToday
														style={{ color: "grey", marginLeft: "2px", height: "15px" }}
													/>
												</div>
											</Tooltip>
										) : (
											`${goalSeasonsStatement}`
										))}
									{goal.isInactive && (
										<HeaderStatusCheckboxDiv>
											<Tooltip
												title="Inactive Goal?"
												colorScheme={colorSchemeGroup.primary}
												placement={"left"}
											>
												<Checkbox
													style={{ ...CheckboxRightStyle, ...style?.checkbox }}
													checked={goal.isInactive}
													disabled={
														isGoalActionsDisabled ||
														!(isGroup1 || isGroup2) ||
														goal.status.value === COMPLETE ||
														goal.status.value === CANCELED
													}
													onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
														updateGoal({ ...goal, isInactive: Boolean(e) })
													}
													colorScheme={colorSchemeGroup.tertiary}
												/>
												{goalSeasonsStatement} Inactive
											</Tooltip>
										</HeaderStatusCheckboxDiv>
									)}
								</>
							)}
						</GoalSectionRightDiv>
					)}
				</GoalHeader>
			</div>
		</GoalContainer>
	);
};
