import { chakra, Box, Table, Thead, Tr, Th, Td } from "@chakra-ui/react";

import { TValueType } from "_react/shared/ui/presentation/components/Table/_types";
import { VALUE_TYPE_NUMBER } from "_react/shared/ui/presentation/components/Table/_constants";

// Chakra Styling

export const DefaultTable = chakra(Table, {
	baseStyle: {
		width: "100%",
		borderCollapse: "collapse",
		border: "1px solid",
		borderColor: "gray.300"
	}
});

export const DefaultThead = chakra(Thead, {
	baseStyle: {
		border: "1px solid black"
	}
});

export const DefaultTrHighlightedStyle = {
	backgroundColor: "highlight"
};

export const DefaultTrEvenStyle = {
	backgroundColor: "white"
};

export const DefaultTrOddStyle = {
	backgroundColor: "gray.100"
};

export const DefaultTr = chakra(Tr, {
	baseStyle: {
		borderTop: "1px solid transparent",
		"&:hover": {
			backgroundColor: "rgba(0, 43, 92, 0.125) !important"
		}
	}
});

export const NestedContentTr = chakra(DefaultTr, {
	baseStyle: {
		border: "none"
	}
});

export const NestedContentTd = chakra(Td, {
	baseStyle: {
		border: "1px solid",
		borderColor: "gray.500",
		borderTop: "1px solid",
		borderTopColor: "gray.300",
		padding: 2
	}
});

const TOP_HEADER_BACKGROUND_COLOR = "black";

const SHARED_HEADER_STYLES = {
	color: "white",
	cursor: "pointer",
	padding: "4px 15px",
	"&:hover": {
		backgroundColor: "gray.500"
	}
};

export const DefaultParentTh = chakra(Th, {
	baseStyle: {
		...SHARED_HEADER_STYLES,
		backgroundColor: TOP_HEADER_BACKGROUND_COLOR,
		borderRight: "1px solid",
		borderRightColor: "gray.300",
		borderBottom: "1px solid",
		borderBottomColor: "gray.800"
	}
});

export const DefaultThStyle = (includesParentHeader: boolean) => ({
	...SHARED_HEADER_STYLES,
	backgroundColor: includesParentHeader ? "gray.700" : TOP_HEADER_BACKGROUND_COLOR,
	borderTop: includesParentHeader ? "1px solid" : "1px solid",
	borderTopColor: includesParentHeader ? "gray.800" : "black",
	borderBottom: includesParentHeader ? "1px solid" : "1px solid",
	borderBottomColor: includesParentHeader ? "gray.800" : "black",
	borderRight: includesParentHeader ? "1px solid" : undefined,
	borderRightColor: includesParentHeader ? "gray.300" : undefined
});

export const PageBoxActiveStyle = {
	color: "white",
	backgroundColor: "gray.500",
	border: "1px solid black",
	borderRadius: "5px",
	"&:hover": {
		backgroundColor: "gray.400",
		border: "1px solid",
		borderColor: "gray.500"
	}
};

export const PageBox = chakra(Box, {
	baseStyle: {
		cursor: "pointer",
		margin: "3px",
		padding: "3px 9px",
		fontSize: "0.9em",
		borderRadius: "3px",
		"&:hover": {
			backgroundColor: "gainsboro"
		}
	}
});

export const BoxDisabledStyle = {
	cursor: "not-allowed",
	color: "lightgrey",
	"&:hover": {
		backgroundColor: undefined
	}
};

export const PreviousBox = chakra(Box, {
	baseStyle: {
		cursor: "pointer",
		marginRight: "6px",
		padding: "3px 6px",
		borderRadius: "3px",
		fontSize: "0.9em",
		"&:hover": {
			backgroundColor: "gainsboro"
		}
	}
});

export const NextBox = chakra(Box, {
	baseStyle: {
		cursor: "pointer",
		marginLeft: "6px",
		padding: "3px 6px",
		borderRadius: "3px",
		fontSize: "0.9em",
		"&:hover": {
			backgroundColor: "gainsboro"
		}
	}
});

// Standalone Style Objects

export const DisplayNoneStyle = {
	display: "none"
};

export const DefaultThTooltipStyle = {
	width: "max-content",
	left: "0%",
	whiteSpace: "pre-line",
	zIndex: 999
};

export const DefaultTdStyle = (valueType?: TValueType) => {
	return {
		textAlign: valueType === VALUE_TYPE_NUMBER ? "right" : "left",
		fontFamily: valueType === VALUE_TYPE_NUMBER ? "mono" : "body",
		":not(:last-child)": {
			borderRight: "1px solid",
			borderRightColor: "gray.300"
		},
		borderBottom: "none",
		fontSize: "0.9em",
		padding: "4px 15px 4px 15px",
		whiteSpace: "pre"
	};
};

export const ShowResultsTextFieldStyle = {
	width: "40px",
	margin: "0 4px",
	backgroundColor: "white",
	color: "black",
	fontSize: "0.9em"
};
