import React, { Ref, useRef, useEffect, CSSProperties } from "react";
import { useMachine } from "@xstate/react";
import { useReactToPrint } from "react-to-print";

import { EVAL_LIST } from "utils/constants";
import { useDocumentTitle } from "_react/_hooks";
import { ColorSchemeGroup, defaultColorScheme } from "_react/shared/legacy/ui/Colors";
import { TextField, TChangeEvent } from "_react/shared/legacy/ui/TextField";
import { Checkbox } from "_react/shared/legacy/ui/Checkbox";
import CircularProgress from "_react/shared/legacy/ui/CircularProgress";
import Print from "_react/shared/legacy/ui/icons/Print";

import EvalListMachine, {
	FETCHING_STATE,
	FETCHING_PLAYERS_STATE,
	TOGGLE_SHOW_SUMMARIES,
	SET_EVAL_IDS
} from "_react/evals/list/_evalListMachine";
import {
	PRO_HIT,
	PRO_PITCH,
	AMA_HIT,
	AMA_PITCH,
	INTL_HIT,
	INTL_PITCH,
	AMA,
	PRO,
	INTL
} from "_react/evals/list/_constants";
import {
	TEval,
	TEvalProHit,
	TEvalProPitch,
	TEvalAmaHit,
	TEvalAmaPitch,
	TEvalIntlHit,
	TEvalIntlPitch,
	TPlayerEvals,
	TEvalStyle
} from "_react/evals/list/_types";
import {
	EvalListPrintContainer,
	PageHeaderContainer,
	CircularProgressContainer,
	ListTitleContainer,
	ListTitleTextField,
	CheckboxContainer,
	EvalListContainer,
	PrintStyle,
	PageStyle,
	HideStyle,
	CheckboxStyle
} from "_react/evals/list/_styles";
import { EvalProHit } from "_react/evals/list/eval/EvalProHit";
import { EvalProPitch } from "_react/evals/list/eval/EvalProPitch";
import { EvalAmaHit } from "_react/evals/list/eval/EvalAmaHit";
import { EvalAmaPitch } from "_react/evals/list/eval/EvalAmaPitch";
import { EvalIntlHit } from "_react/evals/list/eval/EvalIntlHit";
import { EvalIntlPitch } from "_react/evals/list/eval/EvalIntlPitch";
import { PlayerEvalPro } from "_react/evals/list/player/PlayerEvalPro";
import { PlayerEvalAma } from "_react/evals/list/player/PlayerEvalAma";
import { PlayerEvalIntl } from "_react/evals/list/player/PlayerEvalIntl";

export type TEvalListStyle = {
	checkbox?: CSSProperties;
	eval?: TEvalStyle;
};

export type TEvalListProps = {
	id?: string;
	evalIds: Array<string>;
	title?: string;
	isPrintOnly?: boolean;
	isShowTitle?: boolean;
	isPlayerCentric?: boolean;
	sortEvals?: (a: TEval, b: TEval) => number;
	colorSchemeGroup?: ColorSchemeGroup;
	style?: TEvalListStyle;
};

export const EvalList = React.forwardRef(
	(
		{
			id,
			evalIds,
			title = EVAL_LIST,
			isPrintOnly = false,
			isShowTitle = false,
			isPlayerCentric = false,
			sortEvals = (a: TEval, b: TEval) => {
				const aIndex = evalIds.findIndex((id: string) => id === a.eval_id);
				const bIndex = evalIds.findIndex((id: string) => id === b.eval_id);
				if (aIndex < bIndex) return -1;
				else if (aIndex > bIndex) return 1;
				else return 0;
			},
			colorSchemeGroup = defaultColorScheme,
			style
		}: TEvalListProps,
		printRef: Ref<HTMLDivElement>
	) => {
		const [documentTitle, setDocumentTitle] = useDocumentTitle(title);
		const [current, send] = useMachine(EvalListMachine({ evalIdsProp: evalIds }));

		const isFetching = current.matches(FETCHING_STATE);
		const isFetchingPlayers = current.matches(FETCHING_PLAYERS_STATE);

		const context = current.context;
		const { evals, players, playersEvals, isShowSummaries } = context;

		const toggleShowSummaries = () => {
			send({ type: TOGGLE_SHOW_SUMMARIES });
		};

		// A parent component can pass a printRef OR have this component handle printing
		const internalPrintRef = useRef<HTMLDivElement | null>(null);
		const handlePrint = useReactToPrint({
			content: () => internalPrintRef.current
		});

		// Sorts player centric list as Pro -> Intl -> Ama
		const sortPlayerEvals = (a: TPlayerEvals, b: TPlayerEvals) => {
			if (a.playerClassification > b.playerClassification) return -1;
			else if (a.playerClassification < b.playerClassification) return 1;
			else return 0;
		};

		// If new evalIds are passed update them in the machine
		useEffect(() => {
			if (evalIds !== context.evalIds) send({ type: SET_EVAL_IDS, value: evalIds });
		}, [send, evalIds, context.evalIds]);

		return (
			<EvalListPrintContainer id={id} style={isPrintOnly ? HideStyle : undefined}>
				{isShowTitle && !printRef && (
					<PageHeaderContainer>
						<ListTitleContainer>
							<TextField
								fullWidth={true}
								onChange={(e: TChangeEvent) => setDocumentTitle(e.target.value)}
								value={documentTitle}
								style={ListTitleTextField}
							/>
						</ListTitleContainer>
						<Print onClick={handlePrint} fill="black" style={PrintStyle} />
						<CheckboxContainer>
							<Checkbox
								checked={isShowSummaries}
								onChange={() => toggleShowSummaries()}
								style={{ ...CheckboxStyle, ...style?.checkbox }}
								colorScheme={colorSchemeGroup.tertiary}
							/>
							Show Summaries
						</CheckboxContainer>
					</PageHeaderContainer>
				)}
				{(isFetching || isFetchingPlayers) && (
					<CircularProgressContainer>
						<CircularProgress />
					</CircularProgressContainer>
				)}
				{!(isFetching || isFetchingPlayers) && (
					<EvalListContainer ref={printRef ?? internalPrintRef}>
						<PageStyle />
						{evals &&
							!isPlayerCentric &&
							evals.sort(sortEvals).map((e: TEval) => (
								<React.Fragment key={e.eval_id}>
									{e.eval_type === PRO_HIT && (
										<EvalProHit
											evaluation={e as TEvalProHit}
											player={players?.pro ? players.pro[e.phil_id] : null}
											isShowSummaries={isShowSummaries}
											colorSchemeGroup={colorSchemeGroup}
											style={style?.eval}
										/>
									)}
									{e.eval_type === PRO_PITCH && (
										<EvalProPitch
											evaluation={e as TEvalProPitch}
											player={players?.pro ? players.pro[e.phil_id] : null}
											isShowSummaries={isShowSummaries}
											colorSchemeGroup={colorSchemeGroup}
											style={style?.eval}
										/>
									)}
									{e.eval_type === AMA_HIT && (
										<EvalAmaHit
											evaluation={e as TEvalAmaHit}
											player={players?.ama ? players.ama[e.phil_id] : null}
											isShowSummaries={isShowSummaries}
											colorSchemeGroup={colorSchemeGroup}
											style={style?.eval}
										/>
									)}
									{e.eval_type === AMA_PITCH && (
										<EvalAmaPitch
											evaluation={e as TEvalAmaPitch}
											player={players?.ama ? players.ama[e.phil_id] : null}
											isShowSummaries={isShowSummaries}
											colorSchemeGroup={colorSchemeGroup}
											style={style?.eval}
										/>
									)}
									{e.eval_type === INTL_HIT && (
										<EvalIntlHit
											evaluation={e as TEvalIntlHit}
											player={
												players?.intl
													? players.intl[e.phil_id] ??
													  (players?.pro ? players?.pro[e.phil_id] ?? null : null)
													: null
											}
											isShowSummaries={isShowSummaries}
											colorSchemeGroup={colorSchemeGroup}
											style={style?.eval}
										/>
									)}
									{e.eval_type === INTL_PITCH && (
										<EvalIntlPitch
											evaluation={e as TEvalIntlPitch}
											player={players?.intl ? players.intl[e.phil_id] : null}
											isShowSummaries={isShowSummaries}
											colorSchemeGroup={colorSchemeGroup}
											style={style?.eval}
										/>
									)}
								</React.Fragment>
							))}
						{playersEvals &&
							isPlayerCentric &&
							playersEvals.sort(sortPlayerEvals).map((p: TPlayerEvals) => (
								<React.Fragment key={`${p.playerClassification}-${p.player.phil_id}`}>
									{p.playerClassification === PRO && (
										<PlayerEvalPro
											evals={p.evals.sort(sortEvals) as Array<TEvalProHit | TEvalProPitch>}
											player={p.player}
											isShowSummaries={isShowSummaries}
											colorSchemeGroup={colorSchemeGroup}
											style={style?.eval}
										/>
									)}
									{p.playerClassification === AMA && (
										<PlayerEvalAma
											evals={p.evals.sort(sortEvals) as Array<TEvalAmaHit | TEvalAmaPitch>}
											player={p.player}
											isShowSummaries={isShowSummaries}
											colorSchemeGroup={colorSchemeGroup}
											style={style?.eval}
										/>
									)}
									{p.playerClassification === INTL && (
										<PlayerEvalIntl
											evals={p.evals.sort(sortEvals) as Array<TEvalIntlHit | TEvalIntlPitch>}
											player={p.player}
											isShowSummaries={isShowSummaries}
											colorSchemeGroup={colorSchemeGroup}
											style={style?.eval}
										/>
									)}
								</React.Fragment>
							))}
					</EvalListContainer>
				)}
			</EvalListPrintContainer>
		);
	}
);

EvalList.displayName = "Eval List";
