import React, { FC } from "react";

import { useChakraColorSchemeGroup } from "_react/shared/theme/_helpers";
import IntlBio from "_react/playerpage/bio/IntlBio";
import { TIntlBioDocument } from "_react/playerpage/bio/_types";

type TBioTabProps = {
	bioDisplayOnly: boolean;
	intlBioDocument: TIntlBioDocument | null;
	setIntlBioDocument: (newDocument: TIntlBioDocument) => void;
};

const BioTab: FC<TBioTabProps> = ({ bioDisplayOnly, intlBioDocument, setIntlBioDocument }) => {
	const chakraColorSchemeGroup = useChakraColorSchemeGroup();
	// Override to not mess with existing colors
	chakraColorSchemeGroup.primary = chakraColorSchemeGroup.secondary;

	return (
		<IntlBio
			colorScheme={chakraColorSchemeGroup}
			bioDisplayOnly={bioDisplayOnly}
			intlBioDocument={intlBioDocument}
			setIntlBioDocument={setIntlBioDocument}
		/>
	);
};

export default BioTab;
