import { IRAAPitcher, IRAAPitcherByTeam, IRAAPitcherTeamBam } from "_react/shared/data_models/raa/_types";

export type ICombinedPitcherValueSchema = IRAAPitcher | IRAAPitcherByTeam;

export type TPitcherValueRow = {
	pitcherValue: TPitcherValueCombined;
	childData?: Array<TPitcherValueRow>;
};

export type TPitcherValueRowComponents = {
	pitchingRaa: number | null;
	rangeRaa: number | null;
	damageRaa: number | null;
	pitcherSbRaa: number | null;
	totalRaa: number | null;
};

export type TPitcherValueCombined = {
	season: number;
	teamBam?: IRAAPitcherTeamBam;
	teamId?: number;
	playingLevel: string;
	gameType: string;
	sp?: ICombinedPitcherValueSchema;
	rp?: ICombinedPitcherValueSchema;
};

export type TPitcherValueTableData = {
	pitcherValue?: Array<IRAAPitcher> | null;
	pitcherValueByTeam?: Array<IRAAPitcherByTeam> | null;
	ratePitcherValue?: Array<IRAAPitcher> | null;
	ratePitcherValueByTeam?: Array<IRAAPitcherByTeam> | null;
	isLoading?: boolean;
};

export const RATE = "RATE";
export const RAW = "RAW";
export type TPitcherValueTableDataDataSource = typeof RATE | typeof RAW;
